import { useHomeownerByContact } from '../../../hooks/useHomeownerByContact';

export function useHasValidAddress(): boolean | undefined {
  const { data: homeowner, isLoading } = useHomeownerByContact();

  const validAddress =
    Boolean(homeowner?.Address?.addressLine1) ||
    Boolean(homeowner?.Address?.addressLine2);

  return isLoading ? undefined : validAddress;
}
