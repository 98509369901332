import styled from '@emotion/styled';
import { LoadingIndicator as LoadingIndicatorC } from '../../../components/loading-indicator/loading-indicator.style';
import { WithIsMobile } from '../../../types';

const Container = styled.div<WithIsMobile>`
  position: relative;
  flex: 1;
  display: flex;
  margin: 0 auto;
  min-width: ${({ isMobile }) => (isMobile ? 'auto' : '100%')};
  max-width: ${({ isMobile }) => (isMobile ? 'fit-content' : '100%')};
`;

const LoadingIndicator = styled(LoadingIndicatorC)`
  align-self: center;
`;

const ChatBox = styled('div')`
  flex: 1;
`;

const AgentLinkText = styled.p<WithIsMobile>`
  position: absolute;
  padding-left: ${({ isMobile }) => (isMobile ? '64px' : '127px')};
  margin-top: ${({ isMobile }) => (isMobile ? '55px' : '35px')};
  text-decoration: underline;
  color: #000;
  width: 100%;

  &:hover {
    color: #000;
    cursor: pointer;
    text-decoration: underline;
  }
`;

const Styled = {
  ChatBox,
  Container,
  LoadingIndicator,
  AgentLinkText,
};

export default Styled;
