import { FC, useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { useIsMobileView } from '../../../hooks/useIsMobileView';
import { svgStringToDataUrl } from '../../../services/svgStringToDataUrl';

export const HeadlineHomeIcon: FC = () => {
  const theme = useTheme();
  const isMobile = useIsMobileView();
  const color = isMobile ? theme.colors.branding.main : theme.colors.white;

  const imgSrc = useMemo(() => svgStringToDataUrl(icon, color), [color]);

  return <img src={imgSrc} />;
};

const icon = `<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 26.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 53.1 47.2" style="enable-background:new 0 0 53.1 47.2;" xml:space="preserve">
<style type="text/css">

</style>
<g id="Icon" transform="translate(-175.75 -376.501)">
	<path id="Path_934" class="st4" d="M199.4,409c-4.1,0-7.4,3.3-7.4,7.4c0,0.8,0.7,1.5,1.5,1.5c0,0,0,0,0,0c0.8,0,1.5-0.7,1.5-1.5
		c0-2.4,2-4.4,4.4-4.4h5.9c2.4,0,4.4,2,4.4,4.4c0,0.8,0.7,1.5,1.5,1.5c0,0,0,0,0,0c0.8,0,1.5-0.7,1.5-1.5c0-4.1-3.3-7.4-7.4-7.4
		H199.4z M196.4,400.1c0,3.3,2.6,5.9,5.9,5.9c3.3,0,5.9-2.6,5.9-5.9c0-3.3-2.6-5.9-5.9-5.9C199,394.2,196.4,396.8,196.4,400.1z
		 M205.3,400.1c0,1.6-1.3,2.9-3,3c-1.6,0-2.9-1.3-3-3c0-1.6,1.3-2.9,3-3C203.9,397.2,205.3,398.5,205.3,400.1z M228.9,400.1
		c0-0.4-0.2-0.8-0.5-1.1l-5.4-4.8c0,0,0,0,0,0v-13.3c0-0.8-0.7-1.5-1.5-1.5h-8.8c-0.8,0-1.5,0.7-1.5,1.5v2.9l-7.9-6.9
		c-0.3-0.2-0.6-0.4-1-0.4c-0.4,0-0.7,0.1-1,0.4L176.2,399c-0.3,0.3-0.5,0.7-0.5,1.1c0,0.8,0.7,1.5,1.5,1.5c0,0,0,0,0,0
		c0.4,0,0.7-0.1,1-0.4l3.5-3v18.2c0,4.1,3.3,7.4,7.4,7.4h26.6c4.1,0,7.4-3.3,7.4-7.4v-18.2l3.4,3c0.3,0.2,0.6,0.4,1,0.4
		C228.2,401.6,228.9,400.9,228.9,400.1z M214.1,382.4h5.9v9.2l-5.9-5.2V382.4z M220,395.7v20.7c0,2.4-2,4.4-4.4,4.4H189
		c-2.4,0-4.4-2-4.4-4.4v-20.7c0,0,0-0.1,0-0.1l17.7-15.6L220,395.7C220,395.6,220,395.6,220,395.7z" style="fill: #1F4D8C;"/>
</g>
</svg>`;
