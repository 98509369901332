import styled from '@emotion/styled';
import { WithIsMobile } from '../../../types';

export const LoginModalContentWrapper = styled.div<WithIsMobile>`
  padding: 50px 20px;
  width: ${({ isMobile }) => (isMobile ? '100%' : '500px')};
`;

export const LoginModalTitle = styled.p`
  font-size: 3.2rem;
  text-align: center;
`;

export const LoginFormWrapper = styled.div`
  padding: 40px 0;
`;

export const ErrorMessage = styled('p')(({ theme }) => ({
  margin: 0,
  padding: 0,
  fontSize: '0.9rem',
  color: theme.colors.red,
}));
