import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';

import { useLoginUser } from '../../../providers/Authentication/auth';
import { useUsername } from '../../../providers/Authentication/useUsername';
import { useHomeownerByContact } from '../../../hooks/useHomeownerByContact';
import { useTokenData } from '../../../providers/Authentication/useTokenData';
import { ErrorMessageExtractor } from '../../../services/ErrorMessageExtractor';
import { useAccountDetails } from '../../../providers/Authentication/useAccountDetails';

type Props = {
  onSignIn: () => void;
  onError: Dispatch<SetStateAction<string | null>>;
};

type useSignInType = {
  onSubmit: (props: onSubmitType) => Promise<void>;
};

type onSubmitType = {
  password: string;
};

function useSignIn({ onSignIn, onError }: Props): useSignInType {
  const username = useUsername();
  const { t } = useTranslation();
  const { data: tokenData } = useTokenData();
  const { data: accountDetails } = useAccountDetails();
  const { data: homeownerDetail } = useHomeownerByContact();
  const loginUser = useLoginUser();

  async function onSubmit({ password }: onSubmitType) {
    if (!homeownerDetail) return;

    try {
      await loginUser.mutateAsync({
        username,
        password,
        attributes: {
          email: homeownerDetail?.Communication?.emailPrimary,
          accountId: String(tokenData?.accountIdRaw ?? ''),
          contactId: String(tokenData?.contactId ?? ''),
          agentId: !tokenData?.agentId ? '' : String(tokenData.agentId),
          sourceCognitoClientAppId: String(
            (accountDetails &&
              accountDetails?.clientConfig?.aws?.sourceCognitoClientAppId) ??
              '',
          ),
        },
      });

      onSignIn();
    } catch (error) {
      const msg = ErrorMessageExtractor.extract(error);
      onError(msg || t('errors.somethingWentWrong'));
    }
  }
  return {
    onSubmit,
  };
}

export default useSignIn;
