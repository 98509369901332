import styled from '@emotion/styled';

import { Text } from '../RegistrationSteps.style';

export const Email = styled('span')(({ theme }) => ({
  fontSize: '1.8rem',
  textAlign: 'center',
  fontWeight: theme.fontWeight.bold,
}));

export const Cta = styled(Text)(({ theme }) => ({
  marginTop: 0,
  cursor: 'pointer',
  width: 'fit-content',
  marginLeft: '0.2rem',
  textDecoration: 'underline',
  color: theme.colors.branding.main,
}));

export const Note = styled('span')(({ theme }) => ({
  fontSize: '1.2rem',
  fontStyle: 'italic',
  marginTop: '0.5rem',
  color: theme.colors.grey.dark,
}));
