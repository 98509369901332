import { MoveIcon } from '../icons/MoveIcon';
import { NotificationCategory } from '../types';
import { MyHomeIcon } from '../icons/MyHomeIcon';
import { routerPaths } from '../../../constants';
import { MaintenanceIcon } from '../icons/MaintenanceIcon';
import { AgentImage } from '../../../components/V2/AgentImage/AgentImage';
import { TransactionCompleteIcon } from '../icons/TransactionCompleteIcon';

const NOTIFICATION_CONFIG_BY_CATEGORY = Object.freeze({
  [NotificationCategory.Move]: {
    ICON: <MoveIcon />,
    LANDING_PAGE: routerPaths.myJourney.myMove,
  },
  [NotificationCategory.Home]: {
    ICON: <MyHomeIcon />,
    LANDING_PAGE: routerPaths.myHome.myHome,
  },
  [NotificationCategory.Maintenance]: {
    ICON: <MaintenanceIcon />,
    LANDING_PAGE: routerPaths.myJourney.myMaintenance,
  },
  [NotificationCategory.Transaction]: {
    ICON: <TransactionCompleteIcon />,
    LANDING_PAGE: routerPaths.myJourney.myTransactions,
  },
  [NotificationCategory.Chat]: {
    ICON: <AgentImage className="agentImage" />,
    LANDING_PAGE: '',
  },
});

export function useNotificationConfig(category: NotificationCategory) {
  return NOTIFICATION_CONFIG_BY_CATEGORY[category];
}
