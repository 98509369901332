import React from 'react';
import styled from '@emotion/styled';
import {
  GetRailProps,
  GetHandleProps,
  GetTrackProps,
  SliderItem,
} from 'react-compound-slider';
import { Layer } from '../../../../../../types';

const railOuterStyle = {
  position: 'absolute',
  left: 0,
  right: 0,
  height: 42,
  transform: 'translate(0%, -50%)',
  borderRadius: 7,
  cursor: 'pointer',
};

const railInnerStyle = {
  position: 'absolute',
  left: 0,
  right: 0,
  height: 2,
  transform: 'translate(0%, -50%)',
  pointerEvents: 'none',
  backgroundColor: '#CFCFCF',
} as React.CSSProperties;

interface SliderRailProps {
  getRailProps: GetRailProps;
}

export const SliderRail: React.FC<SliderRailProps> = ({ getRailProps }) => {
  return (
    <>
      <div style={railOuterStyle} {...getRailProps()} />
      <div style={railInnerStyle} />
    </>
  );
};

interface StyledHandleProps {
  percent: number;
  disabled: boolean;
}

const StyledHandle = styled.div<StyledHandleProps>`
  left: ${(props) => `${props.percent}%`};
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: ${Layer.Default + 1};
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.disabled ? '#666' : 'rgba(255,255,255,1)'};
  border: 1px solid rgb(230, 230, 230);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface StyledHandleDotProps {
  brandColor: string;
}

const StyledHandleDot: React.FC<StyledHandleDotProps> = styled.div<StyledHandleDotProps>`
  width: 12px;
  height: 12px;
  background: ${(props) => props.brandColor};
  opacity: 1;
  border-radius: 50%;
`;

// const StyledHandleDash: React.FC = styled.div`
//   position: absolute;
//   border-left: 1px dashed rgba(151, 150, 150, 1);
//   width: 1px;
//   bottom: 100%;
//   left: calc(50% - 1px);
//   height: 140%;
//   z-index: -1;
// `;

interface HandleProps {
  domain: number[];
  brandColor: string;
  handle: SliderItem;
  getHandleProps: GetHandleProps;
  disabled?: boolean;
}

export const Handle: React.FC<HandleProps> = ({
  domain: [min, max],
  handle: { id, value, percent },
  disabled = false,
  getHandleProps,
  brandColor,
}) => {
  return (
    <>
      <StyledHandle
        percent={percent}
        disabled={disabled}
        role="slider"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        {...getHandleProps(id)}
      >
        <StyledHandleDot brandColor={brandColor} />
        {/* <StyledHandleDash /> */}
      </StyledHandle>
    </>
  );
};

interface TrackProps {
  source: SliderItem;
  target: SliderItem;
  getTrackProps: GetTrackProps;
  disabled?: boolean;
  brandColor: string;
}

export const Track: React.FC<TrackProps> = ({
  source,
  target,
  getTrackProps,
  disabled = false,
  brandColor,
}) => {
  return (
    <div
      style={{
        position: 'absolute',
        transform: 'translate(0%, -50%)',
        height: 8,
        backgroundColor: disabled ? '#999' : brandColor,
        cursor: 'pointer',
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {...getTrackProps()}
    />
  );
};
