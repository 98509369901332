import { useMemo } from 'react';
import { areaTypes } from '../constants/area-types';
import { IHomeownerDetail, SearchArea } from '../types';
import { useAgentDetails } from './query/useAgentDetails';
import { useHomeownerByContact } from './useHomeownerByContact';
import { useHasValidAddress } from '../pages/MyHome/hooks/useHasValidAddress';
import { extractOfficeWithValidAddress } from '../utils/extractOfficeWithValidAddress';
import { MarketDetails, useMarket } from './useMarket';

type HomeAreaMeta = {
  area?: SearchArea;
  isLoading: boolean;
  isError: boolean;
  error?: Error | null;
  data?: MarketDetails | null;
};

const getHomeMarketAddress = (
  homeownerDetail: IHomeownerDetail,
  hasValidAddress: boolean | undefined,
): SearchArea | null => {
  if (!homeownerDetail || !hasValidAddress) {
    return null;
  }

  return {
    areaType: areaTypes.LOCALITY,
    areaId: homeownerDetail.Address?.locality || null,
    countrySubd: homeownerDetail.Address?.countrySubd,
  };
};

export function useHomeownerMarket(): HomeAreaMeta {
  const {
    data: agent,
    isLoading: isLoadingAgent,
    isError: isErrorAgent,
    error: errorAgent,
  } = useAgentDetails();

  const {
    data: homeownerDetail,
    isLoading: isLoadingHomeowner,
    isError: isErrorHomeowner,
    error: errorHomeowner,
  } = useHomeownerByContact();

  const hasValidAddress = useHasValidAddress();

  const userArea = useMemo(() => {
    if (!homeownerDetail) return;

    const address = getHomeMarketAddress(homeownerDetail, hasValidAddress);

    if (!address?.areaId) return;

    return {
      areaType: address?.areaType,
      areaId: address?.areaId?.toLowerCase(),
      countrySubd: address?.countrySubd,
    };
  }, [homeownerDetail, hasValidAddress]);

  const agentOfficeArea = useMemo(() => {
    if (isLoadingAgent || !agent) return;

    const office = extractOfficeWithValidAddress(agent.Office);
    if (!office?.Address) return;

    return {
      areaType: 'locality',
      areaId: office.Address?.locality || '',
      countrySubd: office.Address?.countrySubd || '',
    };
  }, [agent, isLoadingAgent]);

  const isLoading = isLoadingAgent || isLoadingHomeowner;

  const isError = isErrorAgent && isErrorHomeowner;
  const error = !isError ? null : errorHomeowner || errorAgent;

  const { marketDetail } = useMarket(userArea);
  const { marketDetail: agentOfficeMarketDetails } = useMarket(agentOfficeArea);

  const data = !marketDetail ? agentOfficeMarketDetails : marketDetail;
  const area = !marketDetail ? agentOfficeArea : userArea;

  return {
    error,
    isError,
    isLoading,
    area: isLoading ? undefined : area,
    data: isLoading ? undefined : data,
  };
}
