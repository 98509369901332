import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const IconEl = styled('div')<{ blackView?: boolean; disabled?: boolean }>(
  ({ theme, blackView, disabled }) => ({
    width: '2.8rem',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: theme.fontSize.ulRem,
    color: blackView ? theme.colors.black : theme.colors.branding.main,
    filter: disabled ? `grayscale(100)` : 'none',
  }),
);

const IconLabel = styled('span')(({ theme }) => ({
  textTransform: 'uppercase',
  fontSize: theme.fontSize.xsRem,
  fontWeight: theme.fontWeight.bold,
}));

const IconSpinner = styled(FontAwesomeIcon)({
  width: 'auto',
});
export default {
  IconEl,
  IconLabel,
  IconSpinner,
};
