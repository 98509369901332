import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Layer as ZLayer } from '../../../../types';

type Props = {
  total?: number;
  description: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export function CarouselDescription({
  total,
  onClick,
  description,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const formattedDescription =
    description && `${description?.slice(0, 115)}...`;

  const { pathname } = useLocation();
  const showLink = pathname !== '/saved-homes';

  return (
    <>
      <Description>
        {formattedDescription}
        {showLink && (
          <ViewDetailsLink
            onClick={(ev) => {
              ev.stopPropagation();

              onClick?.(ev);
            }}
          >
            {t('listingDetails.contactCard.contactInfo.viewFullDetails', {
              total,
            })}
          </ViewDetailsLink>
        )}
      </Description>
      <Layer />
    </>
  );
}

const Description = styled.div`
  top: 50%;
  left: 4rem;
  right: 4rem;
  margin: auto;
  position: absolute;
  border-radius: '0.275rem';
  transform: translateY(-50%);
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  z-index: ${ZLayer.Default + 2};
`;

const ViewDetailsLink = styled.div`
  cursor: pointer;
  text-decoration: underline;
`;

const Layer = styled.div`
  inset: 0;
  opacity: 0.5;
  background: black;
  position: absolute;
`;
