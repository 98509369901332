type ImgOptions = {
  width?: number;
  height?: number;
};

export class ImageProxy {
  static optimize(src?: string, options?: ImgOptions): string {
    if (!src || typeof src !== 'string') return '';

    const params = ImageProxy.getParamsString(options);

    return `https://img.kvcore.com/cdn-cgi/image/fit=contain,format=auto,${params}/${src}`;
  }

  private static getParamsString(options?: ImgOptions): string {
    let params = '';

    if (!options) return params;

    if (options?.width) {
      params += `width=${options.width}`;
    }

    if (options?.height) {
      params += `height=${options.height}`;
    }

    return params;
  }
}
