import { useCallback, useMemo, useRef } from 'react';

import 'swiper/css';
import { SwiperRef } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

import nextIconSrc from '../../icons/next';
import {
  Next,
  Prev,
  Slide,
  Slider,
  SliderWrapper,
} from './ListingImageCarousel.style';
import { TCarouselItem } from '../../types';
import prevIconSrc from '../../icons/previous';
import { Layer, ListingDetails } from '../../../../types';
import { ListingImage } from './ListingImage/ListingImage';
import { MapItem } from './MapCarouselItem/MapCarouselItem';
import { ImageProxy } from '../../../../services/ImageProxy';
import { useListingSession } from '../../providers/ListingSessionProvider';
import { svgStringToDataUrl } from '../../../../services/svgStringToDataUrl';

interface Props {
  withFooter?: boolean;
  withListingRemarksSlide?: boolean;
  setViewedSessionActive?: () => void;
  onImageChange?: (item: ListingDetails) => void;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const optimizationProps = { width: 620, height: 532 };

export function ListingImageCarousel({
  onClick,
  onImageChange,
  withFooter = false,
  withListingRemarksSlide = true,
}: Props): JSX.Element {
  const { setSessionStarted, listing } = useListingSession();

  const {
    Media,
    ListingSummary: { listingRemarks },
  } = listing;

  const isMapCarousel = !Media?.length;
  const onlyMap = isMapCarousel && !listingRemarks;

  const slides: TCarouselItem[] = useMemo(() => {
    if (isMapCarousel) {
      const mapSlides: TCarouselItem[] = [
        {
          onClick,
          hasLayer: false,
        },
      ];

      if (withListingRemarksSlide) {
        mapSlides.push({
          onClick,
          hasLayer: true,
        });
      }
      return mapSlides;
    }

    const imageList: TCarouselItem[] = Media.slice(0, 3).map((src) => ({
      src: ImageProxy.optimize(src, optimizationProps),
    }));

    if (withListingRemarksSlide) {
      imageList.push({
        total: Media.length,
        description: listingRemarks,
        hasDescriptionSection: true,
        src: ImageProxy.optimize(Media[0], optimizationProps),
      });
    }

    return imageList;
  }, [Media, isMapCarousel, listingRemarks, onClick, withListingRemarksSlide]);

  const sliderRef = useRef<SwiperRef>(null);

  const NextIcon = svgStringToDataUrl(nextIconSrc);
  const PrevIcon = svgStringToDataUrl(prevIconSrc);

  const onNext = useCallback(
    (e) => {
      e.stopPropagation();
      setSessionStarted();
      onImageChange?.(listing);
      sliderRef.current?.swiper?.slideNext();
    },
    [listing, onImageChange, setSessionStarted],
  );

  const onPrev = useCallback(
    (e) => {
      e.stopPropagation();
      setSessionStarted();
      onImageChange?.(listing);
      sliderRef.current?.swiper?.slidePrev();
    },
    [listing, onImageChange, setSessionStarted],
  );

  if (onlyMap) {
    return <MapItem />;
  }

  return (
    <SliderWrapper>
      <Slider
        ref={sliderRef}
        slidesPerView={1}
        withFooter={withFooter}
        pagination={{
          clickable: true,
          dynamicBullets: true,
          dynamicMainBullets: 3,
        }}
        modules={[Navigation, Pagination]}
      >
        {slides.map((slide, key) => (
          <Slide key={key}>
            {isMapCarousel ? (
              <MapItem {...slide} />
            ) : (
              <ListingImage image={slide} onClick={onClick} />
            )}
          </Slide>
        ))}
        {slides.length > 1 && (
          <>
            <Prev
              style={{ zIndex: Layer.Default + 1 }}
              src={PrevIcon}
              onClick={onPrev}
            />
            <Next
              style={{ zIndex: Layer.Default + 1 }}
              src={NextIcon}
              onClick={onNext}
            />
          </>
        )}
      </Slider>
    </SliderWrapper>
  );
}
