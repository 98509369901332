import { useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

export const useNavActive = (navGroupPaths) => {
  const location = useLocation();

  const [isActiveNavGroup, setIsActiveNavGroup] = useState(false);

  useEffect(() => {
    const matchPathName = () => {
      const navGroup = Object.values(navGroupPaths);

      // If we can match off of raw location data, we can just stop there and determine
      // that the route should be active
      if (navGroup.includes(location.pathname)) {
        return true;
      }

      // If we cannot find a match with location.pathname, we iterate through
      // the navGroup and use matchPath to get the raw path (aka /example-route/:id)
      // to see if it matches any paths in the navGroup.
      const matchingRoutes = navGroup.map((path) => {
        return matchPath(location.pathname, {
          path,
          exact: true,
          strict: true,
        });
      });

      // Getting rid of all null objects.
      const filteredRoutes = matchingRoutes.filter((route) => route !== null);

      return filteredRoutes && filteredRoutes.length > 0;
    };

    setIsActiveNavGroup(navGroupPaths && matchPathName());
  }, [location.pathname, navGroupPaths]);

  return {
    isActiveNavGroup,
  };
};
