export default `<svg width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_5880_677929)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M34.5 4C18.7599 4 6 16.7599 6 32.5C6 48.2401 18.7599 61 34.5 61H61.3398C62.2567 61 63 60.2567 63 59.3398V32.5C63 16.7599 50.2401 4 34.5 4ZM54.0976 45.4731C56.5636 41.7554 58 37.2954 58 32.5C58 19.5213 47.4787 9 34.5 9C21.5213 9 11 19.5213 11 32.5C11 45.4787 21.5213 56 34.5 56C39.4641 56 44.0687 54.4608 47.8626 51.8337C48.1351 51.645 48.4815 51.5945 48.7933 51.7071L55.9176 54.2794C56.1505 54.3635 56.3792 54.1452 56.3062 53.9087L53.9681 46.341C53.8774 46.0474 53.9278 45.7292 54.0976 45.4731Z" fill="#004467"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M48.7933 51.7071C48.4815 51.5945 48.1351 51.645 47.8626 51.8337C44.0687 54.4608 39.4641 56 34.5 56C21.5213 56 11 45.4787 11 32.5C11 19.5213 21.5213 9 34.5 9C47.4787 9 58 19.5213 58 32.5C58 37.2954 56.5636 41.7554 54.0976 45.4731C53.9278 45.7292 53.8774 46.0474 53.9681 46.341L56.3062 53.9087C56.3792 54.1452 56.1505 54.3635 55.9176 54.2794L48.7933 51.7071ZM34.5 58C20.4167 58 9 46.5833 9 32.5C9 18.4167 20.4167 7 34.5 7C48.5833 7 60 18.4167 60 32.5C60 37.5358 58.5382 42.236 56.0155 46.1925L58.217 53.3183C58.7773 55.1319 57.0237 56.8052 55.2384 56.1606L48.5868 53.7589C44.5501 56.4387 39.7046 58 34.5 58Z" fill="white"/>
</g>
<defs>
<filter id="filter0_d_5880_677929" x="0" y="0" width="69" height="69" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="2"/>
<feGaussianBlur stdDeviation="3"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5880_677929"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5880_677929" result="shape"/>
</filter>
</defs>
</svg>
`;
