import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import api from '../../api';
import { AUTH_KEY } from './const';
import { useSignOut } from './auth';
import { useKey } from './KeyProvider';
import { useTokenData } from './useTokenData';
import { setHopApiAuthToken } from '../../api/hop';
import { useHolpTokens } from './HolpTokensProvider';
import { useAccountDetails } from './useAccountDetails';
import { useConfigureAmplify } from './useConfigureAmplify';
import { BrowserStorage } from '../../services/BrowserStorage';
import { MigrationConfig } from '../../services/MigrationConfig';
import { useCHDisabledFlow } from '../../hooks/useCHDisabledFlow';

interface CognitoAuthContextStruct {
  isLoading: boolean;
  isLoggedIn: boolean;
  signOut: () => Promise<unknown>;
  loadUser: () => Promise<null | undefined>;
}

const localStorage = new BrowserStorage();

const CognitoAuthContext = React.createContext<
  CognitoAuthContextStruct | undefined
>(undefined);

export const AuthProvider: FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<undefined | null | string>(undefined);

  const hasInternalAuth = MigrationConfig.get().isMigrated;

  const { isNew } = useKey();

  const { data: tokenData } = useTokenData();
  const { token: unauthToken } = useHolpTokens();
  const { data: accountDetails } = useAccountDetails();
  const authSignOut = useSignOut();

  const isConfigured = useConfigureAmplify(accountDetails);

  const loadUser = useCallback(async () => {
    if (!accountDetails?.accountId) return null;

    const token = localStorage.getItem(AUTH_KEY) as string;
    if (!token) return null;

    api.setAuthHeaders({
      loadCognitoToken: async () => token,
    });
    setHopApiAuthToken(token);

    setUser(token);

    return;
  }, [accountDetails?.accountId]);

  const resetUser = useCallback(() => {
    setUser(null);
  }, []);

  const signOut = useCallback(async () => {
    setIsLoading(true);

    try {
      const result = await authSignOut.mutateAsync();
      api.setAuthHeaders({
        loadCognitoToken: null,
      });
      setHopApiAuthToken(unauthToken);
      resetUser();
      setIsLoading(false);

      return result;
    } catch (e) {
      setIsLoading(false);
      throw e;
    }
  }, [authSignOut, resetUser, unauthToken]);

  useCHDisabledFlow(signOut);

  useEffect(() => {
    async function onMount() {
      setIsLoading(true);

      if (isNew) {
        signOut();
        setIsLoading(false);
        return;
      }

      const token = localStorage.getItem(AUTH_KEY) as string;
      if (!token) {
        setIsLoading(false);
        return null;
      }

      api.setAuthHeaders({
        loadCognitoToken: async () => token,
      });
      setHopApiAuthToken(token);

      setUser(token);

      setIsLoading(false);
    }

    onMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenData?.contactId, isConfigured, hasInternalAuth]);

  const prevHasInternalAuthRef = useRef<boolean | undefined>();

  useEffect(() => {
    if (
      hasInternalAuth !== undefined &&
      prevHasInternalAuthRef.current !== undefined &&
      prevHasInternalAuthRef.current !== hasInternalAuth &&
      user
    ) {
      signOut();
    }

    prevHasInternalAuthRef.current = hasInternalAuth;
  }, [hasInternalAuth, signOut, user]);

  const value = useMemo(
    () => ({
      isLoading,
      isLoggedIn: Boolean(user),
      signOut,
      loadUser,
    }),
    [isLoading, user, signOut, loadUser],
  );

  return (
    <CognitoAuthContext.Provider value={value}>
      {children}
    </CognitoAuthContext.Provider>
  );
};

export const useAuth = (): CognitoAuthContextStruct => {
  const context = React.useContext(CognitoAuthContext);

  if (context === undefined) {
    throw new Error(
      'useCognitoAuth cannot be used outside CognitoAuthProvider!',
    );
  }

  return context;
};
