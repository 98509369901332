const ClosedEyeIcon = (): JSX.Element => {
  return (
    <svg
      id="Group_9386"
      data-name="Group 9386"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="18.779"
      height="12"
      viewBox="0 0 18.779 12"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_3457"
            data-name="Rectangle 3457"
            width="18.779"
            height="12"
            fill="#989797"
          />
        </clipPath>
      </defs>
      <g id="Group_9385" data-name="Group 9385" clipPath="url(#clip-path)">
        <path
          id="Path_3461"
          data-name="Path 3461"
          d="M9.379,11.994A8.32,8.32,0,0,1,5.211,10.83,15.562,15.562,0,0,1,1.146,7.477c-.385-.435-.73-.9-1.1-1.357a.148.148,0,0,1-.009-.213A16.548,16.548,0,0,1,5.333,1.121,8.735,8.735,0,0,1,8.663.039,7.706,7.706,0,0,1,12.48.651a13.713,13.713,0,0,1,4.243,2.916,16.978,16.978,0,0,1,1.993,2.286.222.222,0,0,1,0,.312,16.769,16.769,0,0,1-4.867,4.513,9.01,9.01,0,0,1-3.861,1.312c-.2.018-.4,0-.607,0m7.942-5.949c-.058-.08-.079-.111-.1-.14a16.241,16.241,0,0,0-3.613-3.281A8.514,8.514,0,0,0,10.365,1.3a6.444,6.444,0,0,0-3.719.542A12.634,12.634,0,0,0,2.869,4.508c-.48.475-.928.981-1.393,1.475.068.086.107.144.154.193.6.62,1.171,1.262,1.8,1.851a11.825,11.825,0,0,0,2.96,2.038A6.655,6.655,0,0,0,12.738,9.9,13.178,13.178,0,0,0,15.7,7.73c.56-.539,1.08-1.118,1.623-1.685"
          transform="translate(0 0)"
          fill="#989797"
        />
        <path
          id="Path_3462"
          data-name="Path 3462"
          d="M170.9,78.695a3.139,3.139,0,1,1-3.131-3.143,3.137,3.137,0,0,1,3.131,3.143m-3.135-1.93a1.936,1.936,0,0,0-1.929,1.894,1.961,1.961,0,0,0,1.905,1.968,1.931,1.931,0,1,0,.024-3.862"
          transform="translate(-158.364 -72.681)"
          fill="#989797"
        />
      </g>
    </svg>
  );
};

export default ClosedEyeIcon;
