import styled from '@emotion/styled';

interface StepProps {
  isActive: boolean;
}

export const Wrapper = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'end',
  alignItems: 'center',
});

export const StepsContainer = styled('div')({
  margin: '1rem auto',
  width: 'fit-content',
});

export const Step = styled('span')<StepProps>(({ isActive, theme }) => ({
  width: '1rem',
  height: '1rem',
  marginRight: '0.5rem',
  borderRadius: '0.5rem',
  display: 'inline-block',
  border: isActive ? 'none' : `0.1rem solid ${theme.colors.grey.main}`,
  backgroundColor: isActive ? theme.colors.branding.main : theme.colors.white,
}));
