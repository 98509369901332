import { useMutation, UseMutationResult } from 'react-query';

import {
  Meta,
  Address,
  DeepPartial,
  IHomeownerDetail,
  OptInPreference,
} from '../types';
import api from '../api';
import { apiRoutes } from '../constants';
import { useHomeownerByContactOptimistic } from './useHomeownerByContact';

const EMPTY_ADDRESS_OBJECT = Object.freeze({
  country: null,
  postal1: null,
  postal2: null,
  postal3: null,
  locality: null,
  fullAddress: null,
  countrySubd: null,
  subdivision: null,
  addressLine1: null,
  addressLine2: null,
  countrySecondarySubd: null,
});

type HDWithoutConflicting = Omit<
  IHomeownerDetail,
  'Address' | 'myPropertyUuid'
>;
type IHomeownerDetailExtended = HDWithoutConflicting & {
  resetMaintenance: boolean;
  Address?: Address | null;
  myPropertyUuid?: string | null;
};

function updateHomeownerDetailsData(
  payload: DeepPartial<IHomeownerDetailExtended>,
) {
  return (homeownerDetail?: IHomeownerDetail): IHomeownerDetail => {
    if (!homeownerDetail) {
      return {} as IHomeownerDetail;
    }

    const updated = JSON.parse(JSON.stringify(homeownerDetail));

    updated.Communication.phoneMobile =
      payload?.Communication?.phoneMobile ??
      homeownerDetail.Communication.phoneMobile;

    updated.AgentCoverage =
      payload?.AgentCoverage ?? homeownerDetail.AgentCoverage;

    updated.Communication.textOptIn =
      payload?.Communication?.textOptIn ??
      homeownerDetail.Communication.textOptIn;

    updated.Name.firstName =
      payload?.Name?.firstName ?? homeownerDetail.Name.firstName;
    updated.Name.lastName =
      payload?.Name?.lastName ?? homeownerDetail.Name.lastName;

    updated.OptInPreferences = (payload?.OptInPreferences ||
      homeownerDetail?.OptInPreferences) as OptInPreference[];

    if (payload?.Address !== undefined) {
      updated.Address =
        payload?.Address === null
          ? EMPTY_ADDRESS_OBJECT
          : (payload?.Address as Address);
    }

    if (payload.myPropertyUuid !== undefined) {
      updated.myPropertyUuid = payload.myPropertyUuid;
    }

    updated.Meta = (payload?.Meta ?? homeownerDetail?.Meta) as Meta;

    return updated;
  };
}

interface THomeownerPayload {
  payload: DeepPartial<IHomeownerDetailExtended>;
}

async function patchHomeownerDetails({
  payload,
}: THomeownerPayload): Promise<IHomeownerDetail> {
  const response = await api.patch(apiRoutes.homeowner.patch(), payload);
  return response.data;
}

export function useUpdateHomeownerDetails(): UseMutationResult<
  IHomeownerDetail,
  Error,
  THomeownerPayload
> {
  const homeownerOptimistic = useHomeownerByContactOptimistic();

  return useMutation('mutateHomeownerDetail', patchHomeownerDetails, {
    onMutate: async ({ payload }: THomeownerPayload) => {
      const prevHomeownerDetails = homeownerOptimistic.update(
        updateHomeownerDetailsData(payload),
      );

      return { prevHomeownerDetails };
    },
    onSuccess: ({ AgentCoverage }) => {
      homeownerOptimistic.update(
        updateHomeownerDetailsData({ AgentCoverage: { ...AgentCoverage } }),
      );
    },
    onError: (err, updatedHomeownerDetail, context) => {
      homeownerOptimistic.revert(
        context?.prevHomeownerDetails as IHomeownerDetail | undefined,
      );
    },
  });
}
