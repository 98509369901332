import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { useAuthentication } from '../../../../../providers/Authentication/AuthenticationProvider';
import { useAuthModal } from '../../../../../providers/AuthModalProvider';
import { Icon, IconType } from '../../../../icon';
import {
  AccordionPanel,
  NavigationItem,
  NavigationItemContainer,
  NavigationItemIconWrap,
  NavigationItemLabel,
} from '../../SideMenu.style';

import PanelComponent from '../PanelComponent/PanelComponent';
import HomeInfo from './HomeInfo/HomeInfo';
import LoginInfo from './LoginInfo/LoginInfo';
import NotificationSettings from './NotificationSettings/NotificationSettings';
import PersonalInfo from './PersonalInfo/PersonalInfo';

type Props = {
  panelOpen: boolean;
  handleActiveAccordion: (prev: (item: string) => string) => void;
};

function MyProfile({ panelOpen, handleActiveAccordion }: Props): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();
  const { onOpenModal } = useAuthModal();
  const { isAuthenticated } = useAuthentication();

  const [childPanelOpen, setChildPanelOpen] = useState('');

  const setChildPanelOpenInfo = useCallback((childName: string) => {
    setChildPanelOpen(childName);
  }, []);

  const handleClick = () => {
    if (isAuthenticated) {
      setChildPanelOpen('');
      handleActiveAccordion((prev) => {
        if (prev === 'myProfile') {
          return '';
        }
        return 'myProfile';
      });
    } else {
      onOpenModal();
    }
  };

  return (
    <NavigationItemContainer>
      <NavigationItem
        hasBorderBottom={Boolean(panelOpen)}
        onClick={handleClick}
        data-qa="my-profile"
      >
        <NavigationItemLabel isBold={Boolean(panelOpen)}>
          {t('sideMenu.new.myProfile.label')}
        </NavigationItemLabel>
        <NavigationItemIconWrap>
          <Icon
            type={panelOpen ? IconType.AngleUp : IconType.userCircle}
            style={{
              color: theme.colors.branding.main,
            }}
          />
        </NavigationItemIconWrap>
      </NavigationItem>

      <AccordionPanel show={panelOpen}>
        <PanelComponent
          setChildPanelOpenInfo={setChildPanelOpenInfo}
          childPanelOpen={childPanelOpen}
          label="sideMenu.new.myProfile.loginInfo"
          PanelContent={
            <LoginInfo
              label="sideMenu.new.myProfile.loginInfo"
              childPanelOpen={childPanelOpen}
              setChildPanelOpenInfo={setChildPanelOpenInfo}
            />
          }
        />
        <PanelComponent
          setChildPanelOpenInfo={setChildPanelOpenInfo}
          childPanelOpen={childPanelOpen}
          label="sideMenu.new.myProfile.personalInfo.name"
          PanelContent={
            <PersonalInfo
              label="sideMenu.new.myProfile.personalInfo.name"
              childPanelOpen={childPanelOpen}
              setChildPanelOpenInfo={setChildPanelOpenInfo}
            />
          }
        />
        <PanelComponent
          setChildPanelOpenInfo={setChildPanelOpenInfo}
          childPanelOpen={childPanelOpen}
          label="sideMenu.new.myProfile.homeInfo"
          PanelContent={
            <HomeInfo setChildPanelOpenInfo={setChildPanelOpenInfo} />
          }
        />
        <PanelComponent
          setChildPanelOpenInfo={setChildPanelOpenInfo}
          childPanelOpen={childPanelOpen}
          label="sideMenu.new.myProfile.notifications"
          PanelContent={
            <NotificationSettings
              setChildPanelOpenInfo={setChildPanelOpenInfo}
            />
          }
        />
      </AccordionPanel>
    </NavigationItemContainer>
  );
}

export default MyProfile;
