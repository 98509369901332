import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import bg from './bg';
import ChatIcon from '../../../components/V2/Icons/ChatIcon';
import UnreadMessagesCount from '../ChatBubble/UnreadMessagesCount';
import { useAgentDetails } from '../../../hooks/query/useAgentDetails';
import { useContactAgent } from '../../../providers/ContactAgentProvider';
import { svgStringToDataUrl } from '../../../services/svgStringToDataUrl';
import {
  AgentImg,
  SecondRow,
  ProvidedText,
  AgentWrapper,
  AgentNameText,
  AgentImgWrapper,
  AgentChatWrapper,
  ProviderTextWrapper,
  Container,
} from './CornerChat.style';
import { Layer } from '../../../types';

type Props = {
  handleMouseOut: () => void;
  showUnreadMessages?: boolean;
};

export function ChatWidget({
  handleMouseOut,
  showUnreadMessages = false,
}: Props) {
  const { onChat, isLoading: isChatLoading } = useContactAgent();
  const { data, isLoading: isAgentLoading } = useAgentDetails();

  const theme = useTheme();
  const { t } = useTranslation();

  const agentLogo = data?.Bio.agentPhoto ? data?.Bio.agentPhoto : '';
  const agentName = `${data?.Name.firstName} ${data?.Name.lastName}`;
  const imgSrc = svgStringToDataUrl(bg, theme?.colors.branding.main);

  if (isChatLoading || isAgentLoading) {
    return <></>;
  }
  return (
    <Container onClick={onChat}>
      {showUnreadMessages && <UnreadMessagesCount />}
      <ContentBox onMouseOut={handleMouseOut} />
      <AgentChatWrapper imgPath={imgSrc} />
      <AgentImgWrapper>
        <AgentImg src={agentLogo} alt="agent-image" />
      </AgentImgWrapper>

      <ProviderTextWrapper>
        <ProvidedText>{t('agentWidget.provided')}</ProvidedText>
      </ProviderTextWrapper>

      <AgentWrapper>
        <AgentNameText>{agentName}</AgentNameText>
      </AgentWrapper>

      <SecondRow>
        {t('agentWidget.question')}
        <ChatIcon />
      </SecondRow>
    </Container>
  );
}

const ContentBox = styled.div`
  height: 107px;
  width: 273px;
  background-size: 273px 107px;
  z-index: ${Layer.Default + 1};
  overflow: hidden;
  position: fixed;
  bottom: 1.4rem;
  right: 0rem;
`;
