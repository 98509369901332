import styled from '@emotion/styled';

import { useContactAgent } from '../../../providers/ContactAgentProvider';
import LoadingIndicator from '../../../components/loading-indicator';
import { ChatBubble } from '../ChatBubble/ChatBubble';

function MobileChatBubble(): JSX.Element {
  const { isLoading: isChatLoading } = useContactAgent();

  if (isChatLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Container>
      <ChatBubble size="4rem" showUnreadCount />
    </Container>
  );
}

export default MobileChatBubble;

export const Container = styled('div')({
  width: '4rem',
  right: '3rem',
  height: '4rem',
  bottom: '1rem',
  position: 'fixed',
  overflow: 'visible',
  borderRadius: '50%',
  borderBottomRightRadius: 0,
});
