import { ComponentType } from 'react';

export function lazyWithRetry(
  fn: Promise<{
    default: ComponentType<unknown>;
  }>,
  retriesLeft = 3,
  interval = 1000,
): Promise<{ default: ComponentType<unknown> }> {
  return new Promise((resolve, reject) => {
    fn.then(resolve).catch((error) => {
      setTimeout(() => {
        if (retriesLeft === 1) {
          reject(error);
          return;
        }

        lazyWithRetry(fn, retriesLeft - 1, interval).then(resolve, reject);
      }, interval);
    });
  });
}
