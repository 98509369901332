import styled from '@emotion/styled';
import { Layer } from '../../../../types';

export const PageContainer = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex: 1 1 100%;
  position: relative;
  padding-bottom: ${({ isMobile }) => (isMobile ? '7.5rem' : '1rem')};
  flex-direction: column;
  color: ${({ theme }) => theme.colors.font.primary};
  font-family: ${({ theme }) => theme.fontFamily.primary};
  z-index: ${Layer.Default};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex: 1 1 100%;
  position: relative;
  flex-direction: column;
`;
