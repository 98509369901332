import styled from '@emotion/styled';

export const Button = styled('button')(({ theme }) => ({
  height: '5rem',
  backgroundColor: theme.colors.branding.main,
  color: theme.colors.white,
  textTransform: 'uppercase',
  fontSize: '1.1rem',
  border: 'none',
  borderRadius: '100px',
  lineHeight: '1.4rem',
  padding: '0 2.5rem',
  marginTop: '1.2rem',
  ':hover': {
    backgroundColor: theme.colors.black,
  },
}));
