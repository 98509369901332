type ApiRoutesConfig = {
  notifications: {
    get: string;
    getCount: string;
    getUnread: string;
    markAllAsRead: string;
    delete: (id: number) => string;
    markAsRead: (id: number) => string;
  };
};

export const apiRoutes: ApiRoutesConfig = {
  notifications: {
    get: '/notification',
    getCount: '/notification/count',
    getUnread: '/notification/unread',
    delete: (id) => `/notification/${id}`,
    markAllAsRead: '/notification/markAllAsRead',
    markAsRead: (id) => `/notification/${id}/read`,
  },
} as const;
