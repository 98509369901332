import { useCallback } from 'react';

import {
  useQueryClient,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  InfiniteData,
} from 'react-query';

import { hopApi } from '../../../api/hop';
import { NotificationsWithMeta } from '../types';
import { apiRoutes } from '../../../constants/apiRoutes';

export const UNREAD_NOTIFICATION_KEY = 'unreadNotification';

export async function fetchUnreadNotifications(
  page: number,
): Promise<NotificationsWithMeta> {
  const response = await hopApi.get<NotificationsWithMeta>(
    apiRoutes.notifications.getUnread,
    {
      raw: true,
      params: {
        page,
      },
    },
  );

  return response.data;
}

export function useUnreadNotifications(): UseInfiniteQueryResult<NotificationsWithMeta> {
  return useInfiniteQuery(
    [UNREAD_NOTIFICATION_KEY],
    async ({ pageParam = 1 }) => fetchUnreadNotifications(Number(pageParam)),
    {
      staleTime: Infinity,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      getNextPageParam: ({ meta }) => {
        if (!meta?.next_page_url) return undefined;

        return meta.current_page + 1;
      },
    },
  );
}

export function useInvalidateUnreadNotifications(): () => void {
  const queryClient = useQueryClient();

  return useCallback(
    () =>
      queryClient.invalidateQueries(UNREAD_NOTIFICATION_KEY, {
        refetchActive: true,
        refetchInactive: true,
      }),
    [queryClient],
  );
}

export function useUnreadNotificationsOptimistic() {
  const queryClient = useQueryClient();

  const update = (
    updaterFn: (
      oldData: InfiniteData<NotificationsWithMeta>,
    ) => InfiniteData<NotificationsWithMeta>,
  ) => {
    queryClient.cancelQueries(UNREAD_NOTIFICATION_KEY);

    const prev = queryClient.getQueryData(
      UNREAD_NOTIFICATION_KEY,
    ) as InfiniteData<NotificationsWithMeta>;

    queryClient.setQueryData(UNREAD_NOTIFICATION_KEY, () => {
      if (!prev.pages.length) return prev;

      return updaterFn(prev);
    });

    return prev;
  };

  const revert = (value: InfiniteData<NotificationsWithMeta>) => {
    queryClient.setQueryData(UNREAD_NOTIFICATION_KEY, value);
  };

  return { update, revert };
}
