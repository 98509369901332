import { useQuery, useQueryClient, UseQueryResult } from 'react-query';

import api from '../../../api';
import { useMlsIds } from '../../useMlsIds';
import { apiRoutes } from '../../../constants';
import { TViewedListing } from '../../../types';
import { useHomeownerByContact } from '../../useHomeownerByContact';

type InvalidateFn = () => void;

export const SAVED_LISTINGS_RAW_KEY = 'savedListingsRaw';

const fetchSavedListingsRaw = async (
  mlsIds: string[],
): Promise<TViewedListing[] | null> => {
  const response = await api.get(apiRoutes.savedListings.get());

  return (
    response.data.ViewedListing?.filter(
      (l: TViewedListing) =>
        l.saveInd !== undefined && mlsIds.includes(l.mlsId.toString()),
    ) ?? []
  );
};

export const useSavedListingsRaw = (): UseQueryResult<TViewedListing[]> => {
  const mlsIds = useMlsIds();

  const { data: homeownerDetails } = useHomeownerByContact();

  const homeownerUuid = homeownerDetails?.homeownerUuid || '';

  const query = useQuery(
    [SAVED_LISTINGS_RAW_KEY, homeownerUuid],
    () => fetchSavedListingsRaw(mlsIds),
    {
      staleTime: 1000 * 10,
      enabled: Boolean(homeownerUuid) && Boolean(mlsIds?.length),
      refetchOnWindowFocus: true,
    },
  );

  return {
    ...query,
    isLoading: query.isLoading || !mlsIds?.length || !homeownerUuid,
  } as UseQueryResult<TViewedListing[]>;
};

export function useInvalidateSavedListingsRaw(): InvalidateFn {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries(SAVED_LISTINGS_RAW_KEY);
  };
}

export const useSavedListingsOptimistic = () => {
  const queryClient = useQueryClient();
  const { data: homeownerDetails } = useHomeownerByContact();
  const homeownerUuid = homeownerDetails?.homeownerUuid || '';

  const KEY = [SAVED_LISTINGS_RAW_KEY, homeownerUuid];

  const update = (
    updaterFn: (oldData: TViewedListing[]) => TViewedListing[],
  ) => {
    queryClient.cancelQueries(KEY);

    const prevRawListings = queryClient.getQueryData<TViewedListing[]>(KEY);

    queryClient.setQueryData(KEY, () => {
      if (!prevRawListings) return prevRawListings;

      return updaterFn(prevRawListings || []);
    });
  };

  const revert = (prev?: TViewedListing[]) => {
    queryClient.setQueryData(KEY, prev);
  };

  return { update, revert };
};
