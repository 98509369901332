import React from 'react';
import { useTranslation } from 'react-i18next';

import S from './DownloadApp.styles';
import { Button } from '../../components/Button/Button.styles';
import { useBrandingAndAgent } from '../../hooks/useBrandingAndAgent';
import { useDownloadAppUrl } from '../../hooks/useDownloadAppUrl';

export const DownloadApp: React.FC = () => {
  const { t } = useTranslation();
  const proxyUrl = useDownloadAppUrl();
  const { data } = useBrandingAndAgent();

  if (!proxyUrl) return null;

  return (
    <S.Container>
      <Button
        style={{ marginTop: 0 }}
        onClick={() => window.location.replace(proxyUrl)}
      >
        {t('mobileAppDeepLink.downloadApp', {
          agentName: data?.agentName,
        })}
      </Button>
    </S.Container>
  );
};
