import styled from '@emotion/styled';
import { CSSProperties, useEffect, useState } from 'react';

import Dialog from '../../UI/Dialog/Dialog';
import { YieldSignIcon } from './YieldSignIcon';
import { Button } from '../../UI/Button';

type Props = {
  showWhen: boolean;
  title: string;
  description: string;
  confirmButtonText: string;
  onClose?: () => Promise<void>;
  onConfirm?: (e: React.MouseEvent) => Promise<void>;
  closeButtonText?: string;
  customStyles?: CSSProperties;
  children?: JSX.Element;
};

const EngagementPrompt = ({
  showWhen,
  onClose,
  onConfirm,
  title,
  description,
  closeButtonText,
  confirmButtonText,
  customStyles,
  children,
}: Props) => {
  const [isOpen, setIsOpen] = useState(showWhen);

  useEffect(() => {
    if (showWhen !== true) return;
    setIsOpen(true);
  }, [showWhen]);

  return (
    <ModifiedDialog
      open={isOpen}
      onClose={async () => {
        try {
          await onClose?.();
          setIsOpen(false);
        } catch (err) {
          return;
        }
      }}
      style={customStyles}
    >
      <Container>
        <IconWrapper>
          <YieldSignIcon />
        </IconWrapper>
        <InfoWrapper>
          <Title>{title}</Title>
          <Text>{description}</Text>
          {children}
          <ActionWrap>
            {closeButtonText && (
              <CloseButton
                shape="square"
                size="small"
                variant="text"
                onClick={async () => {
                  try {
                    await onClose?.();
                    setIsOpen(false);
                  } catch (err) {
                    return;
                  }
                }}
              >
                {closeButtonText}
              </CloseButton>
            )}
            <ConfirmButton
              shape="rounded"
              size="small"
              onClick={async (e) => {
                try {
                  await onConfirm?.(e);
                  setIsOpen(false);
                } catch (err) {
                  return;
                }
              }}
            >
              {confirmButtonText}
            </ConfirmButton>
          </ActionWrap>
        </InfoWrapper>
      </Container>
    </ModifiedDialog>
  );
};
const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  gap: 2.7rem;
`;

const IconWrapper = styled.div`
  display: flex;
  padding: 2rem 2.2rem 0rem 2.2rem;
  @media all and (max-width: 768px) {
    margin-top: 3.9rem;
  }
`;

const InfoWrapper = styled.div`
  width: 50rem;
  margin: 0 auto;
  @media all and (max-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;

const Title = styled.h2`
  text-align: center;
  font-size: 2.4rem;
  margin-bottom: 0;
  @media all and (max-width: 768px) {
    font-size: 1.6rem;
  }
`;
const Text = styled.p`
  width: 50rem;
  height: 3.8rem;
  text-align: center;
  margin: 0 auto;
  margin-top: 1.1rem;
  font-size: 1.6rem;
  line-height: normal;
  padding: 0 2rem;
  @media all and (max-width: 768px) {
    width: 100%;
    display: flex;
    font-size: 1.2rem;
    justify-content: center;
  }
`;

const ActionWrap = styled.div`
  display: flex;
  margin-top: 2.1rem;
  text-align: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1.7rem;
  @media all and (max-width: 768px) {
    flex-direction: column-reverse;
    margin-top: 3rem;
  }
`;

const ConfirmButton = styled(Button)`
  padding: 0.8rem 3rem;
  font-size: 1.4rem;
`;

const CloseButton = styled(Button)`
  text-decoration: underline;
  :hover {
    background-color: transparent;
    text-decoration: underline;
  }
`;

const ModifiedDialog = styled(Dialog)`
  .MuiPaper-root[role='dialog'] {
    border-radius: 0.5rem;
    max-width: unset;
    max-height: unset;
    overflow: hidden;
    scroll-behavior: none;
    width: 65rem;
    min-height: 37.4rem;
    padding: 1.9rem 0 6rem 0;
    & > div {
      padding: 0;
      height: 100%;
    }

    @media all and (max-width: 768px) {
      width: 336px;
      min-height: 420px;
      padding: 1.9rem 1.8rem 2.7rem 1.8rem;
      margin: 0;
    }

    .MuiIconButton-root {
      position: absolute;
      top: 0;
      right: 2rem;
      @media all and (max-width: 768px) {
        right: 0rem;
      }
    }
  }
`;
export default EngagementPrompt;
