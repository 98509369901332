import { useLayoutEffect, useState } from 'react';

export const useWindowWidth = (): number => {
  const [windowWidth, setWindowWidth] = useState(0);

  useLayoutEffect(() => {
    function updateWindowWidth() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', updateWindowWidth);
    updateWindowWidth();

    return () => window.removeEventListener('resize', updateWindowWidth);
  }, []);

  return windowWidth;
};

export const useWindowHeigth = (): number => {
  const [windowHeight, setWindowHeight] = useState(0);

  useLayoutEffect(() => {
    function updateWindowHeight() {
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener('resize', updateWindowHeight);
    updateWindowHeight();

    return () => window.removeEventListener('resize', updateWindowHeight);
  }, []);

  return windowHeight;
};
