export function RemoveNotificationIcon(): JSX.Element {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3135_349248)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.67946 13.9688C8.83002 14.3207 7.91943 14.5012 7 14.5C6.08058 14.5012 5.16998 14.3206 4.32055 13.9688C3.47112 13.6169 2.69959 13.1007 2.0503 12.4497C1.39931 11.8004 0.883055 11.0289 0.531195 10.1795C0.179336 9.33002 -0.00118543 8.41943 5.85779e-06 7.5C-0.00116625 6.58058 0.179364 5.66998 0.531222 4.82055C0.88308 3.97112 1.39933 3.19959 2.0503 2.5503C2.69959 1.89933 3.47112 1.38308 4.32055 1.03122C5.16998 0.679364 6.08058 0.498834 7 0.500006C7.91943 0.498815 8.83002 0.679336 9.67946 1.0312C10.5289 1.38305 11.3004 1.89931 11.9497 2.5503C12.6007 3.19959 13.1169 3.97112 13.4688 4.82055C13.8206 5.66998 14.0012 6.58058 14 7.5C14.0012 8.41943 13.8207 9.33002 13.4688 10.1795C13.1169 11.0289 12.6007 11.8004 11.9497 12.4497C11.3004 13.1007 10.5289 13.6169 9.67946 13.9688ZM4.28033 3.71967C3.98744 3.42678 3.51256 3.42678 3.21967 3.71967C2.92678 4.01256 2.92678 4.48744 3.21967 4.78033L5.93934 7.5L3.21967 10.2197C2.92678 10.5126 2.92678 10.9874 3.21967 11.2803C3.51256 11.5732 3.98744 11.5732 4.28033 11.2803L7 8.56066L9.71967 11.2803C10.0126 11.5732 10.4874 11.5732 10.7803 11.2803C11.0732 10.9874 11.0732 10.5126 10.7803 10.2197L8.06066 7.5L10.7803 4.78033C11.0732 4.48744 11.0732 4.01256 10.7803 3.71967C10.4874 3.42678 10.0126 3.42678 9.71967 3.71967L7 6.43934L4.28033 3.71967Z"
          fill="#888888"
        />
      </g>
      <defs>
        <clipPath id="clip0_3135_349248">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
