import React, { useMemo } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Title,
  Tooltip,
  Legend,
);

interface Focus {
  color: string;
  index: number;
}
interface Props {
  data: number[];
  range?: number[];
  focus?: Focus;
  xAxisBorder?: boolean;
}
export const RangeBarChart: React.FC<Props> = ({
  data,
  range,
  focus,
  xAxisBorder,
}) => {
  const theme = useTheme();

  const options = useMemo(() => {
    const xAxisBorderConfig = {
      ticks: {
        display: false,
        padding: 0,
        backdropPadding: 0,
      },
      grid: {
        display: false,
        drawBorder: true,
      },
    };

    const xAxisNoBorderConfig = {
      display: false,
      grid: {
        display: false,
      },
    };

    return {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 0,
          left: 0,
          right: 0,
          bottom: xAxisBorder ? -10 : 0,
        },
      },
      scales: {
        x: xAxisBorder ? xAxisBorderConfig : xAxisNoBorderConfig,
        y: {
          title: {
            color: theme.colors.grey.dark,
            display: true,
            text: 'VOLUME',
            padding: 0,
          },
          ticks: {
            display: false,
          },
          grid: {
            display: false,
            drawBorder: true,
            tickBorderDash: [3, 3],
          },
        },
      },
      plugins: {
        tooltip: {
          enabled: false,
        },
        legend: {
          display: false,
        },
      },
    };
  }, [theme.colors.grey.dark, xAxisBorder]);

  const backgroundColor = useMemo(() => {
    if (focus) {
      return data.map((_, i) =>
        i === focus.index ? focus.color : theme.colors.grey.main,
      );
    }

    if (range) {
      return data.map((_, i) =>
        i >= range[0] && i <= range[1]
          ? theme.colors.branding.main
          : theme.colors.grey.main,
      );
    }

    return data.map(() => theme.colors.branding.main);
  }, [data, focus, range, theme.colors.branding.main, theme.colors.grey.main]);

  const chartData = useMemo(
    () => ({
      labels: data.map(() => ''),
      datasets: [
        {
          label: '',
          data,
          backgroundColor,
        },
      ],
    }),
    [data, backgroundColor],
  );

  return (
    <Container>
      <Bar data={chartData} options={options} />
    </Container>
  );
};

const Container = styled.div`
  height: 90px;
`;
