import React from 'react';
import styled from '@emotion/styled';
import { useIsEmbedded } from '../../../../hooks/query/useIsEmbeded';
import { useIsMobileView } from '../../../../hooks/useIsMobileView';
import { useBanner } from '../../../../providers/BannerProvider';

import HousesSvg from './houses.svg?url';
import { Button as BaseButton } from '../../../UI/Button';
import { Layer } from '../../../../types';

const Container = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  background: '#F4F3F4',
  transition: 'all .2s',
  zIndex: Layer.Default + 2,
  height: 80,
  flexShrink: 0,
  padding: '7px 2.5%',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  '& svg': {
    height: 25,
    position: 'absolute',
    bottom: 5,
    right: 0,
    '@media all and (max-width: 450px)': {
      right: -10,
    },
  },
}));

const LogoWrapper = styled('div')({
  background: '#fff',
  width: 32,
  height: 32,
  flexShrink: 0,
  borderRadius: 4,
  marginRight: 12,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0px 2px 3px #00000029',
});

const Image = styled('img')({
  width: '95%',
});

const TextWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  color: theme.colors.branding.main,
  paddingRight: 10,
}));

const CompanyName = styled('span')({
  fontSize: 14,
  marginBottom: 2,
  fontWeight: 600,
});

const Text = styled('span')({
  fontWeight: 300,
  fontSize: 10,
});

const Button = styled(BaseButton)({
  borderRadius: 100,
  fontSize: 11,
  width: 60,
  height: 36,
  marginLeft: 'auto',
});

const StyledSkinnyBannerIcon = styled.img`
  height: 25px;
  position: absolute;
  bottom: 5px;
  right: 10rem;
`;

export const MobileBanner: React.FC = () => {
  const { show, companyName, companyLogo, goToStore } = useBanner();
  const isMobileView = useIsMobileView();
  const isEmbedded = useIsEmbedded();

  if (!isMobileView || !show || isEmbedded) return null;

  return (
    <Container>
      {companyLogo && (
        <LogoWrapper>
          <Image src={companyLogo} />
        </LogoWrapper>
      )}
      <TextWrapper>
        <CompanyName>{companyName}</CompanyName>
        <Text>Open in the {companyName} app</Text>
      </TextWrapper>
      <StyledSkinnyBannerIcon src={HousesSvg} />
      <Button onClick={goToStore}>Open</Button>
    </Container>
  );
};
