import { useCallback } from 'react';
import { useQuery, useQueryClient, UseQueryResult } from 'react-query';

import api from '../../api';
import apiRoutes from '../../constants/api-routes';
import { useHomeownerByContact } from '../useHomeownerByContact';
import { MigrationConfig } from '../../services/MigrationConfig';

interface HomeownerCognitoIdentity {
  homeownerUuid: string;
  contactId: number;
  activeProfile: boolean;
  CognitoProfile: CognitoProfile;
  Agent: Agent;
}

interface Agent {
  agentId: string;
  firstName: string;
  lastName: string;
  profilePhoto: string;
}

interface CognitoProfile {
  userName: string;
}

type InvalidateFn = () => void;

const KEY_PREFIX = 'cognito-identities';

async function fetchCognitoIdentities(
  email: string,
): Promise<HomeownerCognitoIdentity[]> {
  const response = await api.get(apiRoutes.verifyUser.findByEmail(email));

  return response.data ?? [];
}

export function useCognitoIdentities(): UseQueryResult<
  HomeownerCognitoIdentity[] | undefined
> {
  const { data: homeownerDetail } = useHomeownerByContact();
  const email = homeownerDetail?.Communication?.emailPrimary || '';

  const hasInternalAuth = MigrationConfig.get().isMigrated;

  return useQuery([KEY_PREFIX, email], () => fetchCognitoIdentities(email), {
    retry: 2,
    staleTime: Infinity,
    refetchOnMount: false,
    enabled: Boolean(email) && hasInternalAuth === false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
}

export function useInvalidateCognitoIdentities(): InvalidateFn {
  const queryClient = useQueryClient();
  const { data: homeownerDetail } = useHomeownerByContact();
  const email = homeownerDetail?.Communication?.emailPrimary || '';

  return useCallback(() => {
    queryClient.invalidateQueries([KEY_PREFIX, email]);
  }, [email, queryClient]);
}
