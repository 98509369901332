import { ListingDetails } from '../../../../../types';

export const UUID = 'propertyType';

export function filter(
  listing: ListingDetails,
  filter: string,
  filters: Record<string, any>, // eslint-disable-line
): boolean {
  if (!filter || filters.sale?.type === 'rent') return true;

  if (!listing?.propTypeId) return false;

  return filter.split(',').includes(String(listing.propTypeId));
}
