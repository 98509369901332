import { useMemo } from 'react';
import { Pencil } from '../components/Pencil';

type optionalProps = {
  endAdornment?: JSX.Element;
};

export function useOptionalProps(hasInitialValue: boolean): optionalProps {
  return useMemo(
    () =>
      hasInitialValue
        ? {
            endAdornment: <Pencil />,
          }
        : {},
    [hasInitialValue],
  );
}
