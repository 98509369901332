import { FC, useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { svgStringToDataUrl } from '../../../services/svgStringToDataUrl';

export const SavedHomesIcon: FC = () => {
  const theme = useTheme();

  const imgSrc = useMemo(
    () => svgStringToDataUrl(icon, theme.colors.branding.main, '#1F4D8C'),
    [theme],
  );

  return <img src={imgSrc} />;
};

const icon = `<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 26.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 85.7 77.6" style="enable-background:new 0 0 85.7 77.6;" xml:space="preserve">
<g id="Group_4122" transform="translate(0.408 0.452)">
	<path id="Path_1116" d="M84.7,38c0-0.7-0.3-1.3-0.8-1.8l-8.6-7.6c0,0,0,0,0,0V7.4c0-1.3-1.1-2.3-2.3-2.3c0,0,0,0,0,0
		H58.8c-1.3,0-2.3,1.1-2.3,2.3c0,0,0,0,0,0V12L43.9,1c-0.4-0.4-1-0.6-1.6-0.6c-0.6,0-1.1,0.2-1.6,0.6L0.9,36.2
		c-0.5,0.4-0.8,1.1-0.8,1.8c0,1.3,1,2.3,2.3,2.3c0,0,0,0,0,0c0.6,0,1.1-0.2,1.6-0.6l5.5-4.8v28.9c0,6.5,5.3,11.7,11.7,11.7h42.3
		c6.5,0,11.7-5.3,11.7-11.7V34.9l5.5,4.8c0.4,0.4,1,0.6,1.6,0.6C83.6,40.3,84.7,39.3,84.7,38C84.7,38,84.7,38,84.7,38z M61.2,9.8
		h9.4v14.7l-9.4-8.3L61.2,9.8z M70.6,30.9v32.9c0,3.9-3.2,7-7,7H21.2c-3.9,0-7-3.2-7-7V30.9c0-0.1,0-0.1,0-0.2L42.4,5.9l28.2,24.9
		C70.6,30.8,70.6,30.9,70.6,30.9L70.6,30.9z M34.3,33.3c-4.7,0.2-8.4,4.1-8.4,8.8c0,2.3,0.9,4.6,2.6,6.2l12.4,12.6
		c0.8,0.8,2.1,0.8,2.9,0c0,0,0,0,0,0l12.4-12.6c1.6-1.7,2.6-3.9,2.6-6.2c0-4.7-3.7-8.6-8.4-8.8c-2.2,0-5,1-8,4.1
		C39.3,34.3,36.6,33.3,34.3,33.3z M34.4,38c1.2,0,2.4,0.5,3.3,1.4l4.6,4.7l4.6-4.7c0.9-0.9,2.1-1.4,3.3-1.4c2.2,0.1,3.9,1.9,3.8,4
		c0,1.1-0.4,2.2-1.2,3L42.4,55.6L31.8,45c-0.8-0.8-1.2-1.9-1.2-3C30.6,39.8,32.3,38.1,34.4,38L34.4,38z" style="fill: #1F4D8C;"/>
</g>
</svg>`;
