import React, { FC, useMemo } from 'react';

import {
  filter,
  UUID as SaleUUID,
} from '../components/FiltersDrawer/SaleTypeFilter';
import { PriceScale, SaleParam } from '../../../types';
import { usePrices, usePriceScale } from '../hooks/usePrices';
import {
  defaultSaleFilter,
  defaultWithoutComingSoonSaleFilter,
} from '../components/FiltersDrawer/SaleTypeFilter/SaleTypeFilter';
import { useIsWithComingSoon } from '../hooks/useIsWithComingSoon';
import { useSearchParams } from '../../../providers/SearchParamsProvider';
import { useSearchListingSnapshot } from '../hooks/useSearchListingSnapshot';
import { useSearchFilteredListings } from './SearchFilteredListingsProvider';

interface SearchListingsMeta {
  length: number;
  range: [number, number];
  percentage: number;
  priceScale: PriceScale;
  avgDom: number;
}

const SearchListingsMeta = React.createContext<SearchListingsMeta | undefined>(
  undefined,
);

export const SearchListingsMetaProvider: FC = ({ children }) => {
  const { get } = useSearchParams();
  const { data: listingsRaw } = useSearchListingSnapshot();
  const { listings } = useSearchFilteredListings();

  const isWithComingSoon = useIsWithComingSoon(listingsRaw ?? null);

  const defaultFilter = isWithComingSoon
    ? defaultSaleFilter
    : defaultWithoutComingSoonSaleFilter;

  const parameterSale = (get(SaleUUID) as SaleParam) || defaultFilter;

  const saleTypeListings = useMemo(
    () => listingsRaw?.filter((listing) => filter(listing, parameterSale)),
    [listingsRaw, parameterSale.type, parameterSale.option], // eslint-disable-line
  );

  const prices = usePrices(listings);
  const priceScale = usePriceScale(prices);

  const saleTypeListingPrices = usePrices(saleTypeListings);
  const range: [number, number] = useMemo(
    () =>
      !saleTypeListingPrices.length
        ? [0, 0]
        : [
            Math.min(...saleTypeListingPrices),
            Math.max(...saleTypeListingPrices),
          ],
    [saleTypeListingPrices],
  );

  const length = listings.length ?? 0;
  const percentage = (100 / (saleTypeListings?.length ?? 1)) * length;

  const avgDom = useMemo(
    () =>
      listings.reduce(
        (sum, l) => sum + (l?.ListingSummary?.daysOnMarket ?? 0),
        0,
      ) / (listings.length || 1),
    [listings],
  );

  const value = useMemo(
    () => ({
      length,
      range,
      percentage,
      priceScale,
      avgDom,
    }),
    [length, percentage, priceScale, range, avgDom],
  );

  return (
    <SearchListingsMeta.Provider value={value}>
      {children}
    </SearchListingsMeta.Provider>
  );
};

export function useSearchListingsMeta(): SearchListingsMeta {
  const context = React.useContext(SearchListingsMeta);

  if (context === undefined) {
    throw new Error(
      'useSearchListingsMeta cannot be used outside SearchListingsMetaProvider!',
    );
  }

  return context;
}
