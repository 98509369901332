import styled from '@emotion/styled';
import { Layer } from '../../../../types';

type WithIcon = {
  hasIcon?: boolean;
};

type WithSelected = {
  isSelected: boolean;
};

export const Container = styled('div')<{
  maxHeight?: number;
  isOpen?: boolean;
}>(({ maxHeight, isOpen }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxHeight: maxHeight || 'auto',
  overflowY: 'scroll',
  zIndex: isOpen ? Layer.Enhanced : Layer.Default + 1,
}));

export const Option = styled('div')<WithIcon>(({ theme, hasIcon }) => ({
  // padding: hasIcon ? '1.2rem' : '1.2rem 1.2rem 1.2rem 3.7rem',
  padding: hasIcon ? '1.2rem' : '1.2rem',
  borderTop: `1px solid ${theme.colors.grey.main}`,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
}));

export const IconWrapper = styled('div')<WithSelected>(
  ({ theme, isSelected }) => ({
    fontSize: theme.fontSize.mdRem,
    marginRight: '0.5rem',
    width: '2rem',
    color: isSelected ? theme.colorPrimary : theme.colors.black,
  }),
);

export const OptionLabel = styled('div')<WithSelected>(
  ({ theme, isSelected }) => ({
    fontSize: theme.fontSize.smRem,
    fontWeight: isSelected ? theme.fontWeight.bold : theme.fontWeight.regular,
    color: isSelected ? theme.colorPrimary : theme.colors.black,
  }),
);
