import styled from '@emotion/styled';

function NumericBadgeIcon(): JSX.Element {
  return (
    <svg
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_3421_3624)">
        <rect
          x="3"
          y="2"
          rx="9"
          width="20"
          height="20"
          fill="#FF0000"
          shapeRendering="crispEdges"
        />
        <rect
          x="3"
          y="2"
          rx="9"
          width="20"
          height="20"
          fill="url(#paint0_linear_3421_3624)"
          fillOpacity="0.2"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          x="0"
          y="0"
          width="25"
          height="25"
          id="filter0_d_3421_3624"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            type="matrix"
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3421_3624"
          />
          <feBlend
            mode="normal"
            result="shape"
            in="SourceGraphic"
            in2="effect1_dropShadow_3421_3624"
          />
        </filter>
        <linearGradient
          y1="2"
          x1="9.48"
          x2="14.52"
          y2="21.08"
          id="paint0_linear_3421_3624"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function ChatNumericIcon({ count }: { count?: number }): JSX.Element {
  if (!count) return <></>;
  return (
    <Container>
      <NumericBadgeIcon />
      <Number>{count}</Number>
    </Container>
  );
}

const Container = styled('div')({
  position: 'relative',
});

const Number = styled('div')(({ theme }) => ({
  top: '7px',
  left: '7px',
  fontSize: '1.1rem',
  position: 'absolute',
  lineHeight: '1.1rem',
  color: theme.colors.white,
  fontWeight: theme.fontWeight.bold,
}));
