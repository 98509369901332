import React from 'react';
import PropTypes from 'prop-types';

import * as Sentry from '@sentry/react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };

    this.resetErrorBoundary = this.resetErrorBoundary.bind(this);
  }

  // static getDerivedStateFromError(error) {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    window.onerror = (error) => {
      if (this.isIrrelevantError(error)) return;

      Sentry.captureException(error);
      this.setState({ hasError: true });
    };
  }

  // componentDidCatch(error, info) {
  componentDidCatch(error) {
    if (this.isIrrelevantError(error)) return;

    Sentry.captureException(error);
    this.setState({ hasError: true });
  }

  resetErrorBoundary() {
    this.setState({ hasError: false });
  }

  isIrrelevantError(error) {
    return error?.toString()?.includes('ResizeObserver');
  }

  render() {
    const { hasError } = this.state;
    const { fallback, children } = this.props;

    if (hasError) {
      return React.Children.map(fallback, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            resetErrorBoundary: this.resetErrorBoundary,
          });
        }
        return child;
      });
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  fallback: PropTypes.node.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

ErrorBoundary.defaultProps = {
  children: null,
};

export default ErrorBoundary;
