import { useTheme } from '@emotion/react';

import {
  INavGroupPaths,
  NavGroupSearchPath,
  NavGroupJourneyPath,
  NavGroupFavoritesPath,
  NavGroupeNotificationsPath,
} from '../../../types';
import { useNavActive } from '../../../utils/useNavActive';

type Props = {
  width?: string;
  height?: string;
  navGroupPaths?:
    | INavGroupPaths
    | NavGroupSearchPath
    | NavGroupJourneyPath
    | NavGroupFavoritesPath
    | NavGroupeNotificationsPath;
};

export function HomeMobileNavIcon({
  width,
  height,
  navGroupPaths,
}: Props): JSX.Element {
  const theme = useTheme();
  const { isActiveNavGroup } = useNavActive(navGroupPaths);

  const svgWidth = width || '28';
  const svgHeight = height || '28';

  const backgroundColor = isActiveNavGroup
    ? theme.colors.branding.main
    : theme.colors.white;

  const outlineColor = isActiveNavGroup
    ? theme.colors.branding.main
    : theme.colors.grey.dark2;

  const iconPropList: Record<string, string> = {};
  iconPropList['aria-label'] = 'Search';

  return (
    <svg
      fill="none"
      width={svgWidth}
      height={svgHeight}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${svgWidth} ${svgHeight}`}
    >
      <path
        fill="white"
        d="M3.29028 2.89802H8.14865V6.87383L14.3951 1.57275L25.5 10.8496V22.1373C25.5 23.9545 24.0268 25.4276 22.2096 25.4276H6.58061C4.76341 25.4276 3.29028 23.9545 3.29028 22.1373V2.89802Z"
      />
      <path
        fill={backgroundColor}
        fillOpacity="0.1"
        d="M3.29028 2.89802H8.14865V6.87383L14.3951 1.57275L25.5 10.8496V22.1373C25.5 23.9545 24.0268 25.4276 22.2096 25.4276H6.58061C4.76341 25.4276 3.29028 23.9545 3.29028 22.1373V2.89802Z"
      />
      <path
        fill={outlineColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.80443 11.1091V22.2657C4.80443 23.584 5.88196 24.6564 7.20655 24.6564H21.6195C22.9441 24.6564 24.0216 23.584 24.0216 22.2657C24.0216 11.0903 24.0312 11.0747 24.0324 11.0562L14.413 2.60937L4.79368 11.0562C4.79489 11.0747 4.80443 11.0903 4.80443 11.1091ZM8.00725 3.93677H4.8044V8.92129L8.00725 6.10874V3.93677ZM-6.5611e-05 13.5001C-6.5611e-05 13.2793 0.0916142 13.0594 0.271374 12.902L3.20551 10.3253C3.20541 10.3206 3.20292 10.3167 3.20292 10.3121V3.13996C3.20292 2.69949 3.56106 2.34306 4.00365 2.34306H8.80793C9.25052 2.34306 9.60866 2.69949 9.60866 3.13996V4.70253L13.8836 0.948485C14.0345 0.816187 14.2237 0.750038 14.413 0.750038C14.6022 0.750038 14.7914 0.816187 14.9424 0.948485L28.5546 12.902C28.7343 13.0594 28.826 13.2793 28.826 13.5001C28.826 13.9446 28.4635 14.2962 28.0228 14.2962C27.8348 14.2962 27.6464 14.2308 27.4958 14.0973L25.623 12.4528V22.2655C25.623 24.4632 23.8269 26.25 21.6195 26.25H7.20651C4.99904 26.25 3.20295 24.4632 3.20295 22.2655V12.4528L1.33016 14.0973C1.17847 14.2312 0.989233 14.2965 0.800787 14.2965C0.364065 14.2965 -2.71762e-05 13.9453 -2.71762e-05 13.5001L-6.5611e-05 13.5001Z"
      />
    </svg>
  );
}
