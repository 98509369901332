import { useCallback } from 'react';
import styled from '@emotion/styled';
import { WithListingDetails } from '../../types';
import { useListingDateLabel } from '../../hooks';
import { useListingSession } from '../../providers/ListingSessionProvider';
import { Label, TileActionsBar } from '../../components/common.style';
import { ListingFeatures } from '../../components/ListingFeatures/ListingFeatures';
import { ListingImageCarousel } from '../../components/ListingImageCarousel/ListingImageCarousel';

type Props = WithListingDetails & {
  preventOpen?: boolean;
};

export function ListingTilePreview({
  listingDetail,
  preventOpen = false,
}: Props) {
  const listingLabel = useListingDateLabel(listingDetail);
  const { onOpenListingPage, setSessionStarted } = useListingSession();

  const onOpen = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (preventOpen) {
        return;
      }

      setSessionStarted();
      onOpenListingPage(e, true);
    },
    [onOpenListingPage, preventOpen, setSessionStarted],
  );

  return (
    <Container>
      <TileActionsBar>
        <Label data-qa="listing-label">{listingLabel}</Label>
        <ListingFeatures listing={listingDetail} />
      </TileActionsBar>
      <ListingImageCarousel onClick={onOpen} />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  height: 176px;
`;
