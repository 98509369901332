import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';

import { INavGroupPaths } from '../types';
import { IconType } from '../components/icon';
import { routerPaths, routes } from '../constants';

type NavItem = {
  to: string;
  label: string;
  iconType: IconDefinition;
  navGroupPaths: INavGroupPaths;
};

export const useMyHomeNavItem = (): NavItem => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      to: routes.myHome(),
      label: t('navigation.myHome'),
      navGroupPaths: {
        ...routerPaths.myHome,
        marketDetail: routerPaths.nextHome.marketDetail,
        maintenance: routerPaths.myJourney.myMaintenance,
      },
      iconType: IconType.houseUser,
    }),
    [t],
  );
};
