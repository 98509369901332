import { RefObject, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useScrollToTop(refToTop: RefObject<HTMLInputElement>): null {
  const location = useLocation();
  const pathname = location.pathname;
  const locationState = location.state as { scrollToTop: boolean };

  useEffect(() => {
    refToTop?.current && refToTop.current.scrollIntoView();
  }, [pathname, refToTop, locationState?.scrollToTop]);

  return null;
}

export default useScrollToTop;
