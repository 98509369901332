import { useEffect } from 'react';
import * as Amplitude from '@amplitude/analytics-browser';
import { pageViewTrackingPlugin } from '@amplitude/plugin-page-view-tracking-browser';

import { Environment } from '../../services/Environment';
import { useAgentDetails } from '../../hooks/query/useAgentDetails';
import { useHomeownerByContact } from '../../hooks/useHomeownerByContact';
import { useTokenData } from '../../providers/Authentication/useTokenData';

enum AMPLITUDE_API_KEY {
  PROD = '120cda9b65adc4a99da00866053041eb',
  QA = '6c5f0cf84d04a068898fce3cb4b53da5',
}

const APP_VERSION = import.meta.env.VITE_RELEASE || '0.0.0';

const { add, init, identify, Identify, setUserId } = Amplitude;

const identifyObj = new Identify();

export default function useAmplitudeInit(): void {
  const { data: homeowner } = useHomeownerByContact();
  const { data: tokenData } = useTokenData();
  const { data: agentData } = useAgentDetails();

  const isProd = Environment.isProd();
  const isStaging = Environment.isStaging();

  const API_KEY =
    isProd || isStaging ? AMPLITUDE_API_KEY.PROD : AMPLITUDE_API_KEY.QA;

  const agentFullName = `${agentData?.Name.firstName} ${agentData?.Name.lastName}`;

  useEffect(() => {
    add(pageViewTrackingPlugin(Amplitude, { trackHistoryChanges: 'all' }));

    init(API_KEY, undefined, {
      appVersion: APP_VERSION,
    });

    identifyObj.set('version', APP_VERSION);
  }, [API_KEY]);

  useEffect(() => {
    if (!homeowner?.homeownerUuid) return;

    setUserId(homeowner?.Communication.emailPrimary);
  }, [homeowner?.homeownerUuid, homeowner?.Communication?.emailPrimary]);

  useEffect(() => {
    if (!tokenData?.accountId) return;

    identifyObj.set('accountName', String(tokenData.accountId));

    identify(identifyObj);
  }, [tokenData?.accountId]);

  useEffect(() => {
    if (!tokenData?.agentId) return;

    identifyObj.set('agentId', String(tokenData.agentId));

    identify(identifyObj);
  }, [tokenData?.agentId]);

  useEffect(() => {
    if (!agentData?.Name?.firstName) return;

    identifyObj.set('agentName', String(agentFullName));

    identify(identifyObj);
  }, [agentData?.Name?.firstName, agentFullName]);
}
