import * as yup from 'yup';
import i18n from '../i18n';

export const phoneValidationSchema = () =>
  yup
    .string()
    .matches(
      /^[2-9 | s]/gm,
      i18n.t('sideMenu.new.myProfile.personalInfo.invalidPhone'),
    )
    .test(
      'has-10-digits',
      i18n.t('sideMenu.new.myProfile.personalInfo.phoneLength'),
      (value) => {
        if (!value) return true;
        const phoneLength = value.replaceAll(' ', '').length;

        if (phoneLength === 0) return true;
        return phoneLength === 10;
      },
    );
