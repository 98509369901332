import dayjs from 'dayjs';

// export const formatDate = (dateStr) => moment(dateStr).format('MM/DD/YY');

export const formatShortMonthYear = (dateStr) =>
  dayjs(dateStr).format('MMM YY');

export const formatLongMonthYear = (dateStr) =>
  dayjs(dateStr).format('MMMM YYYY');

export const formatLongDate = (dateStr) => dayjs(dateStr).format('MM/DD/YYYY');

export const formatFullMonthDateYear = (dateStr) =>
  dayjs(dateStr).format('MMMM DD, YYYY');

export const formatLongYearMonthDay = (dateStr) =>
  dayjs(dateStr).format('YYYY-MM-DD');

export const formatTime = (dateStr) => dayjs(dateStr).format('hh:mm a');

// export const FormatForBackend = (date) => moment(date).toISOString();

// export const NowForBackend = () => FormatForBackend(Date.now());

export const calcDaysSinceToday = (date) => {
  const today = new Date();
  const dayInPast = new Date(date);

  const difference = dayInPast - today;
  const dayInMs = 1000 * 60 * 60 * 24;

  const differenceInDays = Math.round(difference / dayInMs);
  const absoluteNumber = Math.abs(differenceInDays);

  return absoluteNumber;
};

export const formatWrittenDate = (date) => {
  return dayjs(date).format('MMMM D');
};

export const formatDate = (date) => {
  if (!date) return null;

  return dayjs(date).format('M/D/YYYY');
};
