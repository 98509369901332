import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { FEATURES, useHasFeatureFlag } from '../../../hooks/useFeatureFlags';
import { useHomeownerByContact } from '../../../hooks/useHomeownerByContact';
import { useTokenData } from '../../../providers/Authentication/useTokenData';

type NpsScriptProps = {
  name?: string;
  email?: string;
  accountId?: number;
};

export const NPSHead: FC = () => {
  const { data: tokenData } = useTokenData();
  const { data: homeownerDetail } = useHomeownerByContact();
  const hasNpsFF = useHasFeatureFlag(FEATURES.HopWebNps);

  const name = `${encodeURIComponent(
    homeownerDetail?.Name?.firstName ?? '',
  )} ${encodeURIComponent(homeownerDetail?.Name?.lastName || '')}`;

  if (
    !name ||
    !homeownerDetail?.Communication?.emailPrimary ||
    !tokenData?.accountId ||
    !hasNpsFF
  ) {
    return <></>;
  }

  return (
    <Helmet>
      <script>
        {npsScript({
          name,
          email: homeownerDetail.Communication.emailPrimary,
          accountId: tokenData.accountId,
        })}
      </script>
    </Helmet>
  );
};

export function npsScript({ accountId, name, email }: NpsScriptProps) {
  return `!function(e,t,r,n){if(!e[n]){for(var a=e[n]=[],i=["survey","reset","config","init","set","get","event","identify","track","page","screen","group","alias"],s=0;s<i.length;s++){var c=i[s];a[c]=a[c]||function(e){return function(){var t=Array.prototype.slice.call(arguments);a.push([e,t])}}(c)}a.SNIPPET_VERSION="1.0.1";var o=t.createElement("script");o.type="text/javascript",o.async=!0,o.src="https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/"+r+"/"+n+".js";var p=t.getElementsByTagName("script")[0];p.parentNode.insertBefore(o,p)}}(window,document,"4R1nH721T4PsJNwE","delighted");

  delighted.survey({
    email: "${email}", 
    name: "${name}",
    properties: {                      
      questionProductName: "CORE Home",
      accountId: ${accountId},
    }
  });`;
}
