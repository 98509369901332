import { useParams } from 'react-router';
import { UseQueryResult } from 'react-query';

import { useListingDetail } from './useListingDetail';
import { ListingDetails, TListingDetailsParams } from '../../types';
import { useSearchParams } from '../../providers/SearchParamsProvider';

export function useListingDetailsByParams(): UseQueryResult<ListingDetails> {
  const { get } = useSearchParams();
  const { mlsId, mlsListingId } = useParams<TListingDetailsParams>();

  const mlsIdParam = get('mlsId');
  const mlsListingIdParam = get('mlsListingId');

  return useListingDetail(
    mlsId || mlsIdParam,
    mlsListingId || mlsListingIdParam,
  );
}
