import { useCallback } from 'react';

import { UseQueryResult, useQuery, useQueryClient } from 'react-query';

import { hopApi } from '../../../api/hop';
import { NotificationsCount } from '../types';
import { REFETCH_INTERVAL } from './constants';
import { apiRoutes } from '../../../constants/apiRoutes';
import { useInvalidateAllNotifications } from './useAllNotifications';
import { useInvalidateUnreadNotifications } from './useUnreadNotifications';

export const NOTIFICATION_COUNT_KEY = 'notificationCount';

async function fetchNotificationsCount(
  onSuccess: (data: NotificationsCount) => void,
): Promise<NotificationsCount> {
  const response = await hopApi.get(apiRoutes.notifications.getCount);

  onSuccess?.(response.data);

  return response.data;
}

let prevData: NotificationsCount | undefined;

export function useNotificationsCount(): UseQueryResult<NotificationsCount> {
  const invalidateAll = useInvalidateAllNotifications();
  const invalidateUnread = useInvalidateUnreadNotifications();

  const onSuccess = (newData: NotificationsCount) => {
    if (!prevData) {
      prevData = newData;
      return;
    }

    const isUnreadEqual = prevData.unread === newData.unread;
    if (!isUnreadEqual) {
      invalidateUnread();
    }

    const isTotalEqual = prevData.total === newData.total;
    if (!isTotalEqual) {
      invalidateAll();
    }

    prevData = newData;
  };

  return useQuery(
    NOTIFICATION_COUNT_KEY,
    () => fetchNotificationsCount(onSuccess),
    {
      staleTime: REFETCH_INTERVAL,
      refetchInterval: REFETCH_INTERVAL,
    },
  );
}

export function useInvalidateNotificationsCount(): () => void {
  const queryClient = useQueryClient();

  return useCallback(
    () => queryClient.invalidateQueries(NOTIFICATION_COUNT_KEY),
    [queryClient],
  );
}

export function useNotificationsCountOptimistic() {
  const queryClient = useQueryClient();

  const update = (
    updaterFn: (old: NotificationsCount | undefined) => NotificationsCount,
  ) => {
    queryClient.cancelQueries([NOTIFICATION_COUNT_KEY]);

    const prev = queryClient.getQueryData<NotificationsCount>(
      NOTIFICATION_COUNT_KEY,
    );
    queryClient.setQueryData(NOTIFICATION_COUNT_KEY, (old: unknown) => {
      if (!old) return old;

      return updaterFn(old as NotificationsCount);
    });

    return prev;
  };

  const revert = (value: NotificationsCount | undefined) => {
    queryClient.setQueryData(NOTIFICATION_COUNT_KEY, value);
  };

  return { update, revert };
}
