import { useMemo } from 'react';

import qs from 'qs';
import { useQuery, useQueries } from 'react-query';

import {
  TPolygon,
  TSaleType,
  MlsDetails,
  ListingDetails,
  ListingSummary,
  ListingSnapshotQuery,
} from '../../../types';
import api from '../../../api';
import { useMlsIdMap } from './useMlsIdMap';
import { apiRoutes } from '../../../constants';
import { calcDaysSinceToday } from '../../../utils/date-helper';
import { isComingSoonUrl } from '../../../utils/isComingSoonUrl';
import { useMlsDetails } from '../../../hooks/query/useMlsDetails';
import { polygonParamsToPayload } from '../../../services/polygonParamsToPayload';

interface FetchParams {
  query: string;
  page?: number;
  resultsPerPage?: number;
  mlsIdsMap: { [x: string]: boolean };
}

interface PaginationMeta {
  nextPage: string | null;
  totalCount: number;
  pageSize: number;
  pageCount: number;
  pageNumber: number;
}
interface ListingDetailsWithPagination extends PaginationMeta {
  Listing: ListingDetails[];
}

const propClasses = [
  'Residential Lease',
  'Commercial Lease',
  'ResidentialLease',
  'CommercialLease',
  '',
];

const calculateDom = (summary: ListingSummary): number => {
  if (summary.listingStatus !== 'Active') {
    return typeof summary.daysOnMarket === 'number' ? summary.daysOnMarket : 0;
  }

  return calcDaysSinceToday(summary.listingDate);
};

const fetchListingSnapshotPage = async ({
  query,
  page = 1,
  resultsPerPage = 500,
  mlsIdsMap,
}: FetchParams): Promise<ListingDetailsWithPagination | null> => {
  if (!query) return null;

  const baseUrl = apiRoutes.listingSnapshot.get(query);
  const pagination = `page=${page}&resultsPerPage=${resultsPerPage}`;

  const response = await api.get(`${baseUrl}&${pagination}`);

  if (!response.data) return null;

  response.data.Listing = response.data.Listing.filter(
    (listing: ListingDetails) => {
      return (
        listing.MlsInfo &&
        listing.MlsInfo.mlsId &&
        mlsIdsMap[String(listing.MlsInfo.mlsId)]
      );
    },
  );
  return {
    ...response.data,
    Listing:
      response.data.Listing?.map((l: ListingDetails) => ({
        ...l,
        primaryThumbnail: l.Media?.length ? l.Media[0] : l.primaryThumbnail,
        ListingSummary: {
          ...l.ListingSummary,
          daysOnMarket: calculateDom(l.ListingSummary),
        },
      })) ?? [],
  };
};

interface Options {
  polygonId?: string;
  propTypeIds: string[];
  polygon?: TPolygon[];
  filterBy: string;
  areaId?: string;
  areaType?: string;
  countrySubd?: string | null;
  userCoordinates?: { longitude: string; latitude: string };
  maxResults?: number;
  enabled?: boolean;
}

type TBuildQuery = Options & { mlsData?: MlsDetails[]; saleType: TSaleType };

const buildQuery = ({
  polygonId,
  propTypeIds,
  mlsData,
  polygon,
  filterBy,
  saleType,
  maxResults,
  areaId,
  areaType,
  countrySubd,
}: TBuildQuery) => {
  if (!mlsData || !filterBy) return '';

  let queryString = `filterBy=${filterBy}&onMarketInd=${
    saleType !== 'sold'
  }&propTypeId=${propTypeIds.join(',')}`;

  const mlsIds = mlsData
    .filter((mls) => !(saleType === 'sold' && mls.mlsRules?.hideSoldData))
    .map((item) => item.mlsId)
    .join(',');

  queryString += `&mlsId=${mlsIds}`;

  if (filterBy === 'polygon') {
    const polygonPayload = polygonParamsToPayload(polygon);
    const polygonQuery = qs.stringify(
      { polygon: polygonPayload?.payload },
      {
        arrayFormat: 'indices',
      },
    );

    return queryString + `&${polygonQuery}`;
  }

  if (maxResults) {
    queryString += `&maxResults=${maxResults}`;
  }

  if (filterBy === 'area') {
    return (
      queryString +
      `&areaId=${areaId}&areaType=${areaType}&countrySubd=${countrySubd}`
    );
  }

  return queryString + `&polygonId=${polygonId}`;
};

function createUniqueList(listings: ListingDetails[]): ListingDetails[] {
  const map = new Map();

  listings.forEach((listing) =>
    map.set(`${listing.MlsInfo.mlsId}-${listing.mlsListingId}`, listing),
  );

  return Array.from(map.values());
}

interface ListingsInParallel {
  isLoading: boolean;
  isFetching: boolean;
  noOfLoaded: number;
  total: number;
  data: ListingDetails[];
}

type InParallelOptions = {
  enabled?: boolean;
};

function useListingsInParalel(
  query: string,
  options?: InParallelOptions,
): ListingsInParallel {
  const PAGE_SIZE = 500;

  const mlsIdsMap = useMlsIdMap();

  const lsFirstPage = useQuery(
    ['listingSnapshotTotal', query],
    () => fetchListingSnapshotPage({ query, page: 1, mlsIdsMap }),
    {
      enabled: options?.enabled ?? undefined,
      staleTime: Infinity,
      refetchInterval: Infinity,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    },
  );

  const queriesMap = useMemo(() => {
    const total = lsFirstPage.data?.totalCount ?? 0;
    const noOfPages = Math.ceil(total / PAGE_SIZE);

    if (noOfPages < 2) return [];

    const pages = Array.from(Array(noOfPages - 1).keys()).map((i) => i + 2);

    return pages?.map((page) => ({
      queryKey: ['listingSnapshot', query, page],
      queryFn: () => fetchListingSnapshotPage({ query, page, mlsIdsMap }),
      enabled: !!pages?.length,
      staleTime: Infinity,
      refetchInterval: Infinity,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    }));
  }, [lsFirstPage.data?.totalCount, mlsIdsMap, query]);

  const queries = useQueries(queriesMap);

  const isLoadingParallel = useMemo(
    () => queries.some((q) => q.isLoading),
    [queries],
  );

  const isFetchingParallel = useMemo(
    () => queries.some((q) => q.isFetching),
    [queries],
  );

  const noOfLoaded = useMemo(() => {
    return queries.reduce(
      (sum, query) => sum + (query.isLoading ? 0 : query.data?.pageSize ?? 0),
      0,
    );
  }, [queries]);

  const dataParallel = useMemo(
    () =>
      queries?.reduce(
        (data, query) => [...data, ...(query?.data?.Listing ?? [])],
        [] as ListingDetails[],
      ) ?? [],
    [isLoadingParallel, queriesMap], // eslint-disable-line
  );

  return useMemo(() => {
    return {
      isLoading: lsFirstPage.isLoading || isLoadingParallel,
      isFetching: lsFirstPage.isFetching || isFetchingParallel,
      noOfLoaded: (lsFirstPage?.data?.pageSize ?? 0) + noOfLoaded,
      total: lsFirstPage?.data?.totalCount ?? 0,
      data: createUniqueList([
        ...(lsFirstPage?.data?.Listing ?? []),
        ...dataParallel,
      ]),
    };
  }, [
    lsFirstPage.isLoading,
    lsFirstPage.isFetching,
    lsFirstPage?.data?.pageSize,
    lsFirstPage?.data?.totalCount,
    lsFirstPage?.data?.Listing,
    isLoadingParallel,
    isFetchingParallel,
    noOfLoaded,
    dataParallel,
  ]);
}

export function useListingSnapshot(
  options: Options,
  saleType: TSaleType,
): ListingSnapshotQuery {
  const {
    polygonId,
    propTypeIds,
    polygon,
    filterBy,
    userCoordinates,
    enabled,
    areaId,
    areaType,
    countrySubd,
    maxResults,
  } = options;

  const {
    isLoading: mlsIdsLoading,
    data: mlsIdsData,
    isFetching: mlsIsFetching,
  } = useMlsDetails();

  const query = buildQuery({
    polygonId,
    propTypeIds,
    mlsData: mlsIdsData,
    filterBy,
    polygon,
    userCoordinates,
    saleType,
    areaId,
    areaType,
    countrySubd,
    maxResults,
  });

  const { isLoading, isFetching, noOfLoaded, data, total } =
    useListingsInParalel(query, {
      enabled: enabled && Boolean(mlsIdsData?.length),
    });

  const isLoadingCombined = isLoading || mlsIdsLoading;
  const isFetchingCombined = isFetching || mlsIsFetching;

  const listingsData = useMemo(() => {
    return (data ?? [])
      .filter((listing) => {
        const listingPropClass = listing?.ListingSummary?.propClass;
        if (!listingPropClass || saleType === 'rent') return true;

        return !propClasses?.includes(listingPropClass);
      })
      .map((item) => ({
        ...item,
        Media: item.Media.filter((url) => !isComingSoonUrl(url)),
      }));
  }, [data, saleType]);

  return useMemo(
    () => ({
      isLoading: isLoadingCombined,
      isFetching: isFetchingCombined,
      noOfLoaded,
      data: listingsData,
      error: undefined,
      isError: false,
      total,
    }),
    [listingsData, isFetchingCombined, isLoadingCombined, noOfLoaded, total],
  );
}
