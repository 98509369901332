export enum ConversationTypes {
  Chat = 'chat',
  Call = 'call',
  Email = 'email',
  AskQuestion = 'email',
  SharedApp = 'shared_app',
  SavedSearch = 'saved_search',
  FavoritedListing = 'favorited_listing',
}

export enum TrackPageTypes {
  chat = 'P_Chat',
  login = 'P_Login',
  myAgents = 'P_MyAgents',
  menuHelp = 'P_MenuHelp',
  favorites = 'P_Favorites',
  marketAll = 'P_MarketAll',
  menuProfile = 'P_MenuProfile',
  savedSearch = 'P_SavedSearch',
  agentDetail = 'P_AgentDetails',
  mySearchMap = 'P_MySearch_Map',
  passwordReset = 'P_PasswordReset',
  marketSingle = 'P_MarketSingle',
  mySearchList = 'P_MySearch_List',
  passwordEntry = 'P_PasswordEntry',
  mySearchStart = 'P_MySearch_Start',
  myHomeAddress = 'P_MyHome_Address',
  registerStart = 'P_Register_Start',
  listingDetails = 'P_ListingDetails',
  forgotPassword = 'P_ForgotPassword',
  myHomeNoAddress = 'P_MyHome_NoAddress',
  registerAddHome = 'P_Register_AddHome',
}

export enum MainOrigin {
  mainNav = 'Nav_Main',
  subNav = 'Nav_Sub',
}

export enum SubOrigin {
  myTransactions = 'Transaction',
  myMove = 'Move',
  marketTrends = 'Market',
  maintenance = 'Maintenance',
  listings = 'SearchListings',
  notifications = 'Notifications',
  menu = 'Menu',
  myHome = 'HomeValue',
  favorites = 'Favorites',
}
