import { Image } from '../../common.style';
import { TCarouselItem } from '../../../types';
import { ImageWithDescription } from './ImageWithDescription';

export function ListingImage({
  image,
  onClick,
}: {
  image: TCarouselItem;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}) {
  return image.hasDescriptionSection ? (
    <ImageWithDescription data={image} onClick={onClick} />
  ) : (
    <Image src={image.src} onClick={onClick} />
  );
}
