import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import Dialog from '../../UI/Dialog/Dialog';
import SignInForm from '../SignIn/SignInForm';
import RegistrationSteps from '../RegistrationSteps';
import useHasProfile from '../../../hooks/query/useHasProfile';
import { useIsMobileView } from '../../../hooks/useIsMobileView';
import ForgotPasswordForm from '../ForgotPassword/ForgotPasswordForm';
import useForgotPassword from '../ForgotPassword/hooks/useForgotPassword';
import { useInvalidateCompleteUserInfo } from '../../../hooks/query/useCompleteUserInfo';
import { useInvalidateCognitoIdentities } from '../../../hooks/query/useCognitoIdentities';

interface AuthModalProps {
  isOpen: boolean;
  onLogIn: () => void;
  onClose: () => void;
}

export const AuthModal: React.FC<AuthModalProps> = ({
  isOpen,
  onLogIn,
  onClose,
}) => {
  const [isForgotPass, setIsForgotPass] = useState(false);

  const isMobile = useIsMobileView();
  const hasProfile = useHasProfile();
  const { sendVerificationCode } = useForgotPassword();
  const invalidateCognitoIdentities = useInvalidateCognitoIdentities();
  const invalidateCompleteUserInfo = useInvalidateCompleteUserInfo();

  const handleForgotPass = () => {
    if (!isForgotPass) {
      sendVerificationCode();
    }
    setIsForgotPass(!isForgotPass);
  };

  useEffect(() => {
    if (isOpen) return;

    invalidateCompleteUserInfo();
    invalidateCognitoIdentities();
  }, [invalidateCognitoIdentities, invalidateCompleteUserInfo, isOpen]);

  const onCloseCb = useCallback(() => {
    setIsForgotPass(false);
    onClose();
  }, [onClose]);

  const authModalContentRenderer = () => {
    if (!hasProfile) return <RegistrationSteps />;

    if (isForgotPass) {
      return <ForgotPasswordForm onBack={handleForgotPass} />;
    }

    return <SignInForm onSignIn={onLogIn} onForgotPass={handleForgotPass} />;
  };

  return (
    <ModifiedDialog
      open={isOpen}
      hideX={!hasProfile}
      onClose={onCloseCb}
      isMobile={isMobile}
    >
      {authModalContentRenderer()}
    </ModifiedDialog>
  );
};

const ModifiedDialog = styled(Dialog)<{ isMobile: boolean }>`
  overflow-y: auto;

  width: ${({ isMobile }) => (isMobile ? '95%' : 'fit-content')};

  margin-left: auto;
  margin-right: auto;

  .MuiPaper-root[role='dialog'] {
    border-radius: 1rem;
    max-width: unset;
    overflow-x: hidden;
    margin: 1rem;
    width: 50rem;
    & > div {
      padding: 0;
    }
    @media all and (max-width: 340px) {
      margin: 0;
      width: 100%;
    }
  }

  .MuiPaper-root[role='dialog']:has(div[role='forgot-pass-form']) {
    max-height: 75rem;
    height: fit-content;

    @media all and (max-width: 768px) {
      max-height: 77rem;
      height: 100%;
      margin-top: 8rem;
    }
    @media all and (max-width: 400px) {
      max-height: 60rem;
      margin-top: 8rem;
    }
  }
`;
