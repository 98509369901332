import { useMemo } from 'react';

import { FeatureFlags } from '../types';
import { useAgentFeatureFlags } from './query/useAgentFeatureFlags';
import { useTokenData } from '../providers/Authentication/useTokenData';
import { useAccountFeatureFlags } from './query/useAccountFeatureFlags';
import { useAccountDetails } from '../providers/Authentication/useAccountDetails';

interface CombinedFeatureFlags {
  isLoading: boolean;
  featureFlags: FeatureFlags;
}

export function useCombinedFeatureFlags(): CombinedFeatureFlags {
  const { data: tokenData, isLoading: isTokeDataLoading } = useTokenData();
  const { data: accountDetails, isLoading: isAccountDetailsLoading } =
    useAccountDetails();

  const { data: agentFF, isLoading: isAgentFFLoading } = useAgentFeatureFlags(
    tokenData?.agentId,
  );
  const { data: accountFF, isLoading: isAccountFFLoading } =
    useAccountFeatureFlags(accountDetails?.accountId || '');

  const featureFlags = useMemo(() => {
    const agentFFList = Object.keys(agentFF ?? {});
    const accountFFList = Object.keys(accountFF ?? {});
    let features = {};

    if (!agentFF || !accountFF) return features;

    const longer =
      agentFFList.length >= accountFFList.length ? agentFFList : accountFFList;

    longer.forEach((ff) => {
      const currentFF = agentFF[ff]?.enabledInd ? agentFF[ff] : accountFF[ff];
      features = {
        ...features,
        [ff]: currentFF,
      };
    });

    return features;
  }, [accountFF, agentFF]);

  return useMemo(
    () => ({
      featureFlags,
      isLoading:
        isTokeDataLoading ||
        isAccountDetailsLoading ||
        isAgentFFLoading ||
        isAccountFFLoading,
    }),
    [
      featureFlags,
      isAccountDetailsLoading,
      isAccountFFLoading,
      isAgentFFLoading,
      isTokeDataLoading,
    ],
  );
}
