import { styled, Typography, Button as MuiButton } from '@mui/material';

import Dialog, { DialogTitle, IDialogProps } from '../Dialog';
import { Button } from '../../Button';
import AttentionSvg from './AttentionIcon.svg?url';

interface Props {
  text?: string;
  confirmText?: string;
  cancelText?: string;
  isLoading?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export function ConfirmDialog(props: IDialogProps & Props) {
  const {
    text,
    title,
    confirmText = 'Continue',
    cancelText = 'Cancel',
    isLoading,
    onConfirm,
    onCancel,
    ...otherProps
  } = props;

  return (
    <StyledDialog {...otherProps}>
      <Content>
        <img src={AttentionSvg} />
        <div>
          <DialogTitle>{title}</DialogTitle>
          {text && (
            <Typography variant="body2" align="center">
              {text}
            </Typography>
          )}
        </div>
        <ButtonsWrapper>
          <Button shape="rounded" onClick={onCancel} loading={isLoading}>
            {cancelText}
          </Button>
          <MuiButton variant="text" onClick={onConfirm} disabled={isLoading}>
            <Underline>{confirmText}</Underline>
          </MuiButton>
        </ButtonsWrapper>
      </Content>
    </StyledDialog>
  );
}

const StyledDialog = styled(Dialog)({
  '& .MuiPaper-root': {
    width: '90vw',
    maxWidth: 650,
  },
  padding: 20,
});

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 40,
  paddingTop: 40,
  [theme.breakpoints.up(768)]: {
    gap: 26,
    paddingTop: 0,
  },
}));

const ButtonsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%',
  gap: 17,
  maxWidth: 400,
  margin: '0 auto',
  '& button': {
    width: 'auto',
    flex: 1,
  },
  [theme.breakpoints.up(380)]: {
    flexDirection: 'row',
  },
}));

const Underline = styled('span')({
  textDecoration: 'underline',
  textTransform: 'none',
  fontSize: '1.4rem',
});
