import { FC, useMemo } from 'react';

import { Wizard } from '../Wizard/Wizard';
import { VerifyAccount } from './VerifyAccount/VerifyAccount';
import ProfileCreationStep from './ProfileCreationStep/ProfileCreationStep';
import { FEATURES, useHasFeatureFlag } from '../../../hooks/useFeatureFlags';
import PasswordCreationStep from './PasswordCreationStep/PasswordCreationStep';
import { PropertyInformationStep } from './PropertyInformationStep/PropertyInformationStep';

const RegistrationSteps: FC = () => {
  const hasEmailValidation = useHasFeatureFlag(
    FEATURES.HopWebUseEmailValidation,
  );

  const components = useMemo(() => {
    const ValidationStep = (hasEmailValidation ? VerifyAccount : null) as FC;
    return [
      PasswordCreationStep,
      ValidationStep,
      ProfileCreationStep,
      PropertyInformationStep,
    ].filter((step) => !!step);
  }, [hasEmailValidation]);

  return <Wizard components={components} />;
};

export default RegistrationSteps;
