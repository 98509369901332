import styled from '@emotion/styled';
import { useAgentDetails } from '../../../hooks/query/useAgentDetails';
import { ImageProxy } from '../../../services/ImageProxy';

type Props = {
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLImageElement>) => void | null;
};

const defaultCallback = () => null;

export const AgentImage = ({
  className = '',
  onClick = defaultCallback,
}: Props): JSX.Element => {
  const { data: agentDetails } = useAgentDetails();
  const imgSrc = ImageProxy.optimize(agentDetails?.Bio?.agentPhoto);

  if (!agentDetails) {
    return <></>;
  }

  return <Image src={imgSrc} className={className} onClick={onClick} />;
};

const Image = styled.img`
  width: 3.8rem;
  height: 3.8rem;
  object-fit: cover;
  border-radius: 100%;
`;
