import { HOP_API_VERSION, TPolygon } from '../types';

type TPayload = {
  version: HOP_API_VERSION;
  payload: unknown;
};

export function polygonParamsToPayload(
  polygonParams?: TPolygon[],
): TPayload | null {
  const coordinates =
    polygonParams?.map((polygon) => {
      return [
        Number(polygon.coordinates?.[0]),
        Number(polygon.coordinates?.[1]),
      ];
    }) || [];

  return {
    version: HOP_API_VERSION.V2,
    payload: {
      geometry: !coordinates?.length
        ? null
        : {
            type: 'polygon',
            bbox: [coordinates?.[0], coordinates?.[1]],
            coordinates: !coordinates?.length ? [] : [coordinates],
          },
    },
  };
}
