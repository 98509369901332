import { useCallback } from 'react';
import { useQuery, useQueryClient, UseQueryResult } from 'react-query';
import api from '../../../api';
import { apiRoutes } from '../../../constants';
import { MortgageDetail } from '../../../types';
import { useHomeownerByContact } from '../../useHomeownerByContact';

const MORTGAGE_DETAIL_KEY = 'mortgageDetails';

const fetchMortgageDetail = async (
  propertyUuid?: string,
): Promise<MortgageDetail[]> => {
  const response = await api.get(apiRoutes.mortgage.get(propertyUuid));

  return response?.data;
};

export function useMortgageDetails(): UseQueryResult<MortgageDetail[]> {
  const { data: homeownerDetail } = useHomeownerByContact();
  const propertyUuid = homeownerDetail?.myPropertyUuid;
  return useQuery(
    [MORTGAGE_DETAIL_KEY, propertyUuid],
    () => fetchMortgageDetail(propertyUuid),
    {
      enabled: Boolean(propertyUuid),
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    },
  );
}

type InvalidateFn = () => void;

export function useInvalidateMortgageDetails(): InvalidateFn {
  const queryClient = useQueryClient();

  return useCallback(
    () => queryClient.invalidateQueries(MORTGAGE_DETAIL_KEY),
    [queryClient],
  );
}
