import { useMemo, useCallback, useRef } from 'react';

import { useRecoilState, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

import { NavIcon } from './NavIcon';
import appNavAtom from '../../../../recoil/appNav';
import { IconType } from '../../../../components/icon';
import NotificationsNavItem from './NotificationsNavItem';
import { IconNavLink } from '../../components/IconNavLink';
import { routerPaths, routes } from '../../../../constants';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { useMyHomeNavItem } from '../../../../utils/useMyHomeNavItem';
import { MainOrigin, SubOrigin, useAnalytics } from '../../../analytics';
import { FEATURES, useHasFeatureFlag } from '../../../../hooks/useFeatureFlags';
import { useSavedListings } from '../../../../hooks/query/saveListing/useSavedListings';
import notificationsPanelAtom from '../../../NotificationPanel/state/notificationsPanelAtom';
import notificationOptionsAttom from '../../../NotificationPanel/state/notificationOptionsAttom';

export function NavItemList(): JSX.Element {
  const { t } = useTranslation();
  const { trackPageSwitch } = useAnalytics();
  const notificationsRef = useRef<HTMLDivElement>(null);

  const [isNotificationsPanelOpen, setIsNotificationsPanelOpen] =
    useRecoilState(notificationsPanelAtom);

  const hasNotificationsFlag = useHasFeatureFlag(
    FEATURES.HopWebNotificationsPanel,
  );

  const myHomeNavProps = useMyHomeNavItem();
  const { data: savedListings } = useSavedListings();

  const setOpenDropdownId = useSetRecoilState(notificationOptionsAttom);
  const [isSubNavHidden, setIsSubNavHidden] = useRecoilState(appNavAtom);

  const hasSavedHomes = useMemo(
    () => Boolean(savedListings?.length),
    [savedListings?.length],
  );

  const searchNavGroupPaths = isNotificationsPanelOpen
    ? {}
    : {
        ...routerPaths.search,
        move: routerPaths.myJourney.myMove,
        listings: routerPaths.nextHome.listing,
        listing: routerPaths.nextHome.listingDetail,
        transactions: routerPaths.myJourney.myTransactions,
        favorites: isSubNavHidden ? {} : routerPaths.savedHomes,
      };

  const myHomeNavGroupPaths = isNotificationsPanelOpen
    ? {}
    : myHomeNavProps.navGroupPaths;

  const hideNotificationsPanel = useCallback(() => {
    setOpenDropdownId(undefined);
    setIsNotificationsPanelOpen(null);
  }, [setIsNotificationsPanelOpen, setOpenDropdownId]);

  useOutsideClick(isNotificationsPanelOpen, hideNotificationsPanel);

  const hideSubNav = useCallback(
    () => setIsSubNavHidden(true),
    [setIsSubNavHidden],
  );

  const showSubNav = useCallback(
    () => setIsSubNavHidden(false),
    [setIsSubNavHidden],
  );

  const onNotificationClick = useCallback(() => {
    if (isNotificationsPanelOpen) {
      setOpenDropdownId(undefined);
      setIsNotificationsPanelOpen(null);
      return;
    }

    setIsNotificationsPanelOpen(notificationsRef);
  }, [
    isNotificationsPanelOpen,
    setIsNotificationsPanelOpen,
    setOpenDropdownId,
  ]);

  return (
    <>
      {myHomeNavProps && (
        <IconNavLink
          onClick={() => {
            trackPageSwitch(MainOrigin.mainNav, SubOrigin.myHome);
            showSubNav();
          }}
          to={myHomeNavProps.to}
          dataQa="my-home-nav-item"
          label={myHomeNavProps.label}
          navGroupPaths={myHomeNavGroupPaths}
        >
          <NavIcon
            title={myHomeNavProps.label}
            type={myHomeNavProps.iconType}
            navGroupPaths={myHomeNavGroupPaths}
          />
        </IconNavLink>
      )}

      <IconNavLink
        to={routes.search()}
        onClick={() => {
          trackPageSwitch(MainOrigin.mainNav, SubOrigin.listings);
          showSubNav();
        }}
        dataQa="search-nav-item"
        label={t('navigation.search')}
        navGroupPaths={searchNavGroupPaths}
      >
        <NavIcon
          type={IconType.Search}
          title={t('navigation.search')}
          navGroupPaths={searchNavGroupPaths}
        />
      </IconNavLink>

      {hasSavedHomes && (
        <IconNavLink
          onClick={() => {
            trackPageSwitch(MainOrigin.mainNav, SubOrigin.favorites);
            hideSubNav();
          }}
          to={routes.savedHomes()}
          dataQa="my-favorite-nav-item"
          navGroupPaths={
            isSubNavHidden && !isNotificationsPanelOpen
              ? { favorites: routerPaths.savedHomes }
              : {}
          }
        >
          <NavIcon
            type={IconType.HeartOutlined}
            title={t('navigation.myFavorites')}
            navGroupPaths={
              isSubNavHidden && !isNotificationsPanelOpen
                ? { favorites: routerPaths.savedHomes }
                : {}
            }
          />
        </IconNavLink>
      )}
      {hasNotificationsFlag && (
        <NotificationsNavItem
          onClick={() => {
            trackPageSwitch(MainOrigin.mainNav, SubOrigin.notifications);
            onNotificationClick();
          }}
          notificationsRef={notificationsRef}
          isPanelOpen={Boolean(isNotificationsPanelOpen)}
        />
      )}
    </>
  );
}
