import { useCallback } from 'react';

import { useHistory } from 'react-router-dom';
import { useSavedListingsRaw } from '../hooks/query/saveListing/useSavedListingsRaw';

import listingFallbackImg from '/img/listing-no-media.png?url';

export const useListingHelper = () => {
  const { data: savedListings, isLoading } = useSavedListingsRaw();

  const history = useHistory();

  const findFavoriteListing = useCallback(
    (listingDetail) => {
      if (!savedListings || !listingDetail) return;

      return savedListings?.find(
        (l) =>
          l.mlsId === listingDetail.MlsInfo.mlsId &&
          l.mlsListingId === listingDetail.mlsListingId,
      );
    },
    [savedListings],
  );

  const getListingComment = (listingDetail) => {
    return listingDetail?.listingComments;
  };

  const isListingFavorite = useCallback(
    (listingDetail) => {
      return !!findFavoriteListing(listingDetail);
    },
    [findFavoriteListing],
  );

  const isListingRemoved = useCallback(
    (listingDetail) => {
      return !findFavoriteListing(listingDetail);
    },
    [findFavoriteListing],
  );

  const removeHiddenListings = useCallback(
    (listings) => {
      return listings.filter((l) => !isListingRemoved(l));
    },
    [isListingRemoved],
  );

  const getImage = useCallback((listing) => {
    if (!listing || !listing.Media || listing.Media.length === 0) {
      return listingFallbackImg;
    }

    return listing.Media[0];
  }, []);

  return {
    isLoading,
    isListingFavorite,
    isListingRemoved,
    removeHiddenListings,
    getImage,
    getListingComment,
    findFavoriteListing,
  };
};
