import { InfiniteData, useMutation } from 'react-query';

import {
  useInvalidateNotificationsCount,
  useNotificationsCountOptimistic,
} from './useNotificationsCount';
import { hopApi } from '../../../api/hop';
import { apiRoutes } from '../../../constants/apiRoutes';
import { NotificationsCount, NotificationsWithMeta } from '../types';
import { useAllNotificationsOptimistic } from './useAllNotifications';
import { useUnreadNotificationsOptimistic } from './useUnreadNotifications';
import { toastActions } from '../../../components/V2/ToastComponent/ToastComponent';

function updateNotificationsData(notificationId?: number) {
  return (prev: InfiniteData<NotificationsWithMeta>) => {
    const updatePages = prev?.pages.map((page) => ({
      ...page,
      data: page.data.map((notification) => {
        if (notification.id === notificationId) {
          return {
            ...notification,
            isRead: true,
          };
        }

        return notification;
      }),
    }));

    return {
      ...prev,
      pages: updatePages,
    };
  };
}

function updateUnreadNotificationsData(notificationId?: number) {
  return (prev: InfiniteData<NotificationsWithMeta>) => {
    const updatedPages = prev?.pages.map((page) => ({
      ...page,
      data: page.data.filter(
        (notification) => notificationId !== notification.id,
      ),
    }));

    return {
      ...prev,
      pages: updatedPages,
    };
  };
}

function updateCount(prev?: NotificationsCount): NotificationsCount {
  return {
    total: prev?.total ?? 0,
    unread: prev?.unread ? prev.unread - 1 : 0,
  };
}

async function markAsRead(id?: number): Promise<void> {
  if (!id) return;
  await hopApi.post(apiRoutes.notifications.markAsRead(id));
}

export function useMarkAsRead() {
  const invalidateMotificationsCount = useInvalidateNotificationsCount();

  const allNotificationsOptimistic = useAllNotificationsOptimistic();
  const notificationsCountOptimistic = useNotificationsCountOptimistic();
  const unreadNotificationsoptimistic = useUnreadNotificationsOptimistic();

  return useMutation(markAsRead, {
    onMutate: async (id: number) => {
      const prevAllNotifications = allNotificationsOptimistic.update(
        updateNotificationsData(id),
      );

      const prevUnreadNotifications = unreadNotificationsoptimistic.update(
        updateUnreadNotificationsData(id),
      );

      const prevCount = notificationsCountOptimistic.update(updateCount);

      return { prevAllNotifications, prevUnreadNotifications, prevCount };
    },
    onError: (err: Error, _new, context) => {
      allNotificationsOptimistic.revert(
        context?.prevAllNotifications as InfiniteData<NotificationsWithMeta>,
      );

      unreadNotificationsoptimistic.revert(
        context?.prevUnreadNotifications as InfiniteData<NotificationsWithMeta>,
      );

      notificationsCountOptimistic.revert(context?.prevCount);

      toastActions.error(err.message);
    },
    onSuccess: () => {
      invalidateMotificationsCount();
    },
  });
}
