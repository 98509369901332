export function NewIcon(): JSX.Element {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_3172_497598)">
        <rect
          x="3"
          y="2"
          width="25"
          height="25"
          rx="12.5"
          fill="#43E135"
          shapeRendering="crispEdges"
        />
        <rect
          x="3"
          y="2"
          width="25"
          height="25"
          rx="12.5"
          fill="url(#paint0_linear_3172_497598)"
          fillOpacity="0.2"
          shapeRendering="crispEdges"
        />
        <path
          d="M10.2013 17H11.4343V10.997H10.1563V14.885L7.37529 10.997H6.06129V17H7.33929V12.968L10.2013 17ZM12.6179 17H16.8659V15.893H13.8959V14.498H16.8029V13.4H13.8959V12.095H16.8659V10.997H12.6179V17ZM22.455 17H23.814L25.524 10.997H24.084L23.04 15.335L21.906 10.997H20.907L19.764 15.335L18.711 10.997H17.28L18.999 17H20.358L21.402 12.878L22.455 17Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3172_497598"
          x="0"
          y="0"
          width="31"
          height="31"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3172_497598"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3172_497598"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3172_497598"
          x1="12"
          y1="2"
          x2="19"
          y2="28.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
      </defs>
    </svg>
  );
}
