import { useCallback, useEffect, useMemo, useState } from 'react';

import { useRecoilState } from 'recoil';
import styled from '@emotion/styled';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  List,
  IconWrapper,
  NavLinkGrey,
  QuestionMark,
  LinkContainer,
  SideMenuContainer,
} from './SideMenuOld.style';
import {
  Divider,
  NavigationItemLabel,
  NavigationItemIconWrap,
  NavigationItemContainer,
} from './SideMenu.style';
import { Icon, IconType } from '../../icon';
import { routerPaths } from '../../../constants';
import sideMenuAtom from '../../../recoil/sideMenu';
import NavListItem from './components/old/NavListItem';
import MyProfile from './components/MyProfile/MyProfile';
import { SavedHomesIcon } from '../Icons/SavedHomesIcon';
import { useAgentHelper } from '../../../utils/useAgentHelper';
import { useIsMobileView } from '../../../hooks/useIsMobileView';
import { HamburgerBanner } from '../layout/Banner/HamburgerBanner';
import { useTrackPage, TrackPageTypes } from '../../../features/analytics';
import { useSavedListings } from '../../../hooks/query/saveListing/useSavedListings';
import { useAuthorization } from '../../../providers/Authentication/useAuthorization';
import { useAuthentication } from '../../../providers/Authentication/AuthenticationProvider';
import {
  useIsOperatingUnderRemax,
  REMAX_SUPER_ACCOUNT_URL_PARAM,
} from '../../../hooks/useIsOperatingUnderRemax';
import { useHomeownerMarket } from '../../../hooks/useHomeownerMarket';

const SideMenu = (): JSX.Element => {
  const isMobile = useIsMobileView();

  const { website } = useAgentHelper();
  const { area: homeownerArea } = useHomeownerMarket();

  const [isOpen, setIsOpen] = useRecoilState(sideMenuAtom);
  const closeMenu = useCallback(() => setIsOpen(false), [setIsOpen]);
  const { pathname } = useLocation();

  const [activeAccordion, setActiveAccordion] = useState('');

  const { t } = useTranslation();
  const history = useHistory();
  const { isAuthenticated } = useAuthentication();
  const { gatedOnOpenLoginModal } = useAuthorization();

  const faqRoute = useIsOperatingUnderRemax()
    ? `${routerPaths.faq}?${REMAX_SUPER_ACCOUNT_URL_PARAM}`
    : routerPaths.faq;

  useEffect(() => {
    closeMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useTrackPage(isMobile ? TrackPageTypes.menuProfile : undefined);

  const handleActiveAccordion = useCallback(
    (accordion: (prev: string) => string | string) => {
      setActiveAccordion(accordion);
    },
    [],
  );

  const getGatedCb = (route: string) => {
    return (e: React.MouseEvent<HTMLElement>) =>
      gatedOnOpenLoginModal(e, () => {
        history.push(route);
        closeMenu();
      });
  };

  const navigateOnClick = (route: string) => {
    return (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();

      history.push(route);
      closeMenu();
    };
  };

  const { data: savedListings } = useSavedListings();

  const hasSavedHomes = useMemo(
    () => Boolean(savedListings?.length),
    [savedListings],
  );

  useEffect(() => {
    if (!isAuthenticated || !isOpen) {
      setActiveAccordion('');
    }
  }, [isAuthenticated, isOpen]);

  return (
    <SideMenuContainer
      classes={{
        paper: 'side-menu-paper',
      }}
      open={isOpen}
      onClose={closeMenu}
      isMobile={isMobile}
      disableEnforceFocus
      disableRestoreFocus
    >
      <CloseIconWrapper onClick={closeMenu}>
        <Icon type={IconType.x} />
      </CloseIconWrapper>
      <LinkContainer>
        <List>
          <MyProfile
            panelOpen={activeAccordion === 'myProfile'}
            handleActiveAccordion={handleActiveAccordion}
          />

          {homeownerArea && (
            <NavListItem
              label="sideMenu.allMarkets"
              onClick={navigateOnClick(routerPaths.myHome.myMarkets)}
              Icon={<Icon type={IconType.houseUser} />}
              dataQa="all-markets"
            />
          )}

          {hasSavedHomes && (
            <NavListItem
              label="sideMenu.savedHomes"
              onClick={navigateOnClick(routerPaths.savedHomes)}
              Icon={
                <IconWrapper>
                  <SavedHomesIcon />
                </IconWrapper>
              }
              dataQa="saved-homes"
            />
          )}

          <NavListItem
            label="sideMenu.faq"
            onClick={navigateOnClick(faqRoute)}
            Icon={<QuestionMark>?</QuestionMark>}
            dataQa="faq"
          />

          <NavigationItemContainer>
            <Divider />
            <NavLinkGrey
              data-qa="terms-of-use"
              href={`${website}/terms.php`}
              target="_blank"
            >
              <NavigationItemLabel>
                {t('sideMenu.termsOfUse')}
              </NavigationItemLabel>
              <NavigationItemIconWrap isGray>
                <Icon type={IconType.Locked} />
              </NavigationItemIconWrap>
            </NavLinkGrey>

            <NavLinkGrey
              data-qa="privacy-policy"
              href={`${website}/privacy.php`}
              target="_blank"
            >
              <NavigationItemLabel>
                {t('privacyPolicyLicensingAgreement.privacyPolicy')}
              </NavigationItemLabel>

              <NavigationItemIconWrap isGray>
                <Icon type={IconType.TermsOfUse} />
              </NavigationItemIconWrap>
            </NavLinkGrey>
            <Divider />
          </NavigationItemContainer>
          {!isAuthenticated ? null : (
            <NavListItem
              grayLink
              label="sideMenu.logOut"
              onClick={getGatedCb(routerPaths.auth.logout)}
              dataQa="logout"
            />
          )}
        </List>
      </LinkContainer>

      <HamburgerBanner />
    </SideMenuContainer>
  );
};

export default SideMenu;

const CloseIconWrapper = styled.div`
  cursor: pointer;
  font-size: 2.8rem;
  > * {
    color: ${({ theme }) => theme.colors.branding.main};
  }
  position: absolute;
  top: 8rem;
  left: 1rem;
`;
