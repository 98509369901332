import { useMutation, UseMutationResult } from 'react-query';
import { useDomainWithSubdomain } from '../../../../hooks/useDomain';
import { hopApi } from '../../../../api/hop';
import { apiRoutes } from '../../../../constants';

interface SendAppLinkParams {
  phone?: string;
  email?: string;
}

interface Response {
  status?: string;
}

export function useSendAppLink(): UseMutationResult<
  void,
  Error,
  SendAppLinkParams
> {
  const website = useDomainWithSubdomain();

  async function sendAppLink({
    phone,
    email,
  }: SendAppLinkParams): Promise<void> {
    await hopApi.post<Response>(apiRoutes.sendInvite.post(), {
      phone,
      email,
      website,
    });
  }

  return useMutation(sendAppLink);
}
