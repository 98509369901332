import React, { useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';

import * as Dropdown from '../../../../../components/V2/Dropdown';
import { TDropdownOption } from '../../../../../types';
import { useOutsideClick } from '../../../../../hooks/useOutsideClick';

const DropdownContainer = styled('div')(() => ({
  width: '40%',
}));

interface Ranges {
  min: number;
  max: number;
}

interface Props {
  dataQa?: string;
  options: TDropdownOption[];
  selected?: number | null;
  type: 'Min' | 'Max';
  onChange: (val: number | null, options?: TDropdownOption[]) => void;
}

export const useMinMaxOptionsGenerator = (
  ranges: Ranges,
  increment: number,
  type: 'Min' | 'Max',
  reverse?: boolean,
): TDropdownOption[] => {
  return React.useMemo(() => {
    const values: (number | null)[] = [];
    const { min, max } = ranges;

    const label = `No ${type}`;

    let value = min;

    while (value <= max) {
      values.push(value);
      value += increment;
    }

    const optsArr = values.map((val) => ({
      label: String(val),
      value: String(val),
    }));

    if (reverse) optsArr.reverse();

    if (type === 'Max') {
      optsArr.push({ label, value: '' });
    } else {
      optsArr.unshift({ label, value: '' });
    }
    return optsArr;
  }, [type, increment, ranges, reverse]);
};

export const MinMaxDropdown: React.FC<Props> = ({
  dataQa,
  onChange,
  type,
  options,
  selected,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const label = `No ${type}`;

  const [open, setOpen] = useState<React.RefObject<HTMLDivElement> | null>(
    null,
  );

  const onDropdownClose = () => {
    setOpen(null);
  };

  useOutsideClick(open, onDropdownClose);

  const onSelect = (option: TDropdownOption) => {
    onDropdownClose();
    const value = option.value.length ? parseInt(option.value) : null;
    onChange(value);
  };

  const onToggle = () => {
    if (open) {
      setOpen(null);
      return;
    }

    setOpen(ref);
  };

  const selectedOption = useMemo(
    () =>
      options.find(
        (option: TDropdownOption) => String(option.value) === String(selected),
      ),
    [options, selected],
  );

  return (
    <DropdownContainer ref={ref} data-qa={dataQa}>
      <Dropdown.Button
        isOpen={!!open}
        toggleOpen={onToggle}
        content={
          <Dropdown.Content>
            <Dropdown.ContentItem
              dataQa={`${dataQa}-value`}
              label={selectedOption?.value ? `${type}: ` : ''}
              selectedValue={{
                label: selectedOption?.label || label,
                isBold: true,
              }}
            />
          </Dropdown.Content>
        }
      >
        <Dropdown.Options
          dataQa={`${dataQa}-options`}
          options={options}
          selectedOption={selectedOption}
          setSelectedOption={onSelect}
          maxHeight={200}
        />
      </Dropdown.Button>
    </DropdownContainer>
  );
};
