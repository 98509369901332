import { useMemo, useState, useEffect, useCallback } from 'react';

import * as yup from 'yup';
import { useTheme } from '@emotion/react';
import { Formik, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';

import {
  Text,
  Button,
  ImgIcon,
  Container,
  ModalFooter,
  ErrorMessage,
  FormContainerColumn,
} from '../RegistrationSteps.style';
import ProfileIcon from './icons/profileIcon';
import { useWizard } from '../../Wizard/Wizard';
import { LastName } from './components/LastName';
import { FirstName } from './components/FirstName';
import { PhoneNumber } from './components/PhoneNumber';
import ModalHeader from '../../Modal/header/ModalHeader';
import { Separator } from '../../Separator/Separator.style';
import { useWindowWidth } from '../../../../hooks/useWindowSize';
import { useIsMobileView } from '../../../../hooks/useIsMobileView';
import { svgStringToDataUrl } from '../../../../services/svgStringToDataUrl';
import { useHomeownerByContact } from '../../../../hooks/useHomeownerByContact';
import { useUpdateHomeownerDetails } from '../../../../hooks/useUpdateHomeownerDetails';

import { phoneValidationSchema } from '../../../../utils/formValidations';

interface FormValues {
  lastName: string;
  firstName: string;
  phoneNumber: string;
}

function ProfileCreationStep(): JSX.Element {
  const theme = useTheme();
  const { t } = useTranslation();
  const { nextStep } = useWizard();
  const isMobile = useIsMobileView();
  const screenSize = useWindowWidth();
  const { data: homeownerDetail, isLoading: isHomeownerLoading } =
    useHomeownerByContact();

  const [errorMessage, setErrorMessage] = useState('');

  const {
    isError,
    isSuccess,
    isLoading,
    mutate: onUpdate,
    error: editError,
  } = useUpdateHomeownerDetails();

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      firstName: yup.string().min(2).required(t('common.invalid')),
      lastName: yup.string().min(2),
      phoneNumber: phoneValidationSchema(),
    });
  }, [t]);

  const imgSrc = useMemo(
    () => svgStringToDataUrl(ProfileIcon, theme.colors.branding.main),
    [theme],
  );

  const onSubmit = useCallback(
    async (
      { firstName, lastName, phoneNumber }: FormValues,
      { resetForm }: FormikValues,
    ) => {
      setErrorMessage('');

      await onUpdate(
        {
          payload: {
            Name: {
              firstName,
              lastName,
            },
            Communication: {
              phoneMobile: String(phoneNumber) || '',
            },
          },
        },
        { onSuccess: () => resetForm() },
      );
    },
    [onUpdate],
  );

  useEffect(
    function onEditFinished() {
      if (isLoading) return;

      if (isSuccess) {
        setErrorMessage('');
        nextStep();
        return;
      }

      if (isError) {
        setErrorMessage(editError?.message);
      }
    },
    [editError, isError, isLoading, isSuccess, nextStep],
  );

  const initialValues = useMemo(() => {
    if (isHomeownerLoading) {
      return {
        lastName: '',
        firstName: '',
        phoneNumber: '',
      };
    }

    const communication = homeownerDetail?.Communication;
    const firstName = homeownerDetail?.Name?.firstName || '';
    const lastName = homeownerDetail?.Name?.lastName || '';

    const phoneNumber = communication?.phoneMobile || '';

    return {
      lastName,
      firstName,
      phoneNumber: phoneNumber !== '0' ? phoneNumber : '',
    };
  }, [
    isHomeownerLoading,
    homeownerDetail?.Communication,
    homeownerDetail?.Name?.firstName,
    homeownerDetail?.Name?.lastName,
  ]);

  return (
    <Container isMobile={isMobile}>
      <ModalHeader
        icon={<ImgIcon src={imgSrc} />}
        title={t('auth.profileCreation.title')}
      />
      <Text>{t('auth.profileCreation.subtitle')}</Text>
      <Formik
        enableReinitialize
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {(props) => (
          <FormContainerColumn onSubmit={props.handleSubmit}>
            <FirstName hasInitialValue={Boolean(initialValues.firstName)} />
            <Separator
              marginTop="3rem"
              marginBottom="3rem"
              width={screenSize < 577 ? '30rem' : '35rem'}
            />
            <LastName hasInitialValue={Boolean(initialValues.lastName)} />
            <PhoneNumber hasInitialValue={Boolean(initialValues.phoneNumber)} />
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            <ModalFooter marginTop="7.5rem">
              <Button
                shape="square"
                type="submit"
                disabled={
                  !props.values.firstName || isLoading || isHomeownerLoading
                }
              >
                {t('auth.nextButton')}
              </Button>
            </ModalFooter>
          </FormContainerColumn>
        )}
      </Formik>
    </Container>
  );
}

export default ProfileCreationStep;
