import styled from '@emotion/styled';

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const ImageWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '38%',
  '&:not(:nth-of-type(2))': {
    width: '33%',
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    top: '48%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.colors.branding.main,
    height: '90%',
    width: '80%',
    borderRadius: 10,
  },
}));

const Image = styled('img')({
  display: 'block',
  width: '100%',
  position: 'relative',
});

export default { Container, ImageWrapper, Image };
