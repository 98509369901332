import styled from '@emotion/styled';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

import {
  INavGroupPaths,
  NavGroupSearchPath,
  NavGroupJourneyPath,
  NavGroupFavoritesPath,
} from '../../../../types';
import { Icon } from '../../../../components/icon';
import { useNavActive } from '../../../../utils/useNavActive';
import { colorOpacityApplier } from '../../../../services/colorOpacityApplier';
import { CSSProperties } from 'react';

interface NavIconProps {
  title?: string;
  active?: boolean;
  style?: CSSProperties;
  type?: IconDefinition;
  children?: JSX.Element;
  navGroupPaths?:
    | INavGroupPaths
    | NavGroupSearchPath
    | NavGroupJourneyPath
    | NavGroupFavoritesPath;
}

export function NavIcon({
  type,
  style,
  active,
  children,
  title = '',
  navGroupPaths,
}: NavIconProps): JSX.Element {
  const { isActiveNavGroup } = useNavActive(navGroupPaths);

  return (
    <Container active={active || isActiveNavGroup} className="nav-icon">
      {children}
      {type && (
        <Icon
          style={{
            width: '2.1rem',
            height: '2.1rem',
            ...style,
          }}
          type={type}
          title={title}
        />
      )}
    </Container>
  );
}

interface WithActive {
  active: boolean;
}

const Container = styled('div')<WithActive>(({ active, theme }) => ({
  display: 'flex',
  width: '3.2rem',
  height: '3.2rem',
  fontSize: '2.1rem',
  borderRadius: '50%',
  alignItems: 'center',
  marginRight: '0.8rem',
  justifyContent: 'center',
  backgroundColor: active
    ? theme.colors.branding.main
    : colorOpacityApplier(theme.colors.branding.main, '0.1'),
}));
