import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../UI/Button';

type Props = {
  sectionVisible: boolean;
  submitDisabled: boolean;
  handleCancel?: () => void;
};

function ActionButtons({
  sectionVisible,
  submitDisabled,
  handleCancel,
}: Props): JSX.Element | null {
  const { t } = useTranslation();
  return sectionVisible ? (
    <Container>
      {handleCancel && (
        <CancelBtn onClick={handleCancel}>{t('common.cancel')}</CancelBtn>
      )}

      <Button
        shape="square"
        size="small"
        disabled={submitDisabled}
        style={{
          marginLeft: 'auto',
          marginTop: '1rem',
        }}
        type="submit"
      >
        {t('auth.submit')}
      </Button>
    </Container>
  ) : null;
}

export default ActionButtons;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CancelBtn = styled.div`
  display: flex;
  align-items: flex-end;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 1rem;
  font-size: 1.4rem;
  color: #989797;
  text-underline-offset: 0.2rem;
`;
