import qs from 'qs';
import { Filter, ListingDetails } from '../../../types';

interface ISearchFiltered {
  applyFilters: (
    rawListings: ListingDetails[],
    params: qs.ParsedQs,
    onFinish: (result: ListingDetails[]) => void,
  ) => void;
  subscribe: (filterUuid: string, filterFunc: Filter) => void;
  unsubscribe: (filterUuid: string) => void;
}

export class SearchFilterService implements ISearchFiltered {
  private filters: Record<string, Filter> = {};

  subscribe(filterUuid: string, filterFunc: Filter): void {
    if (!filterUuid || typeof filterFunc !== 'function') return;

    this.filters = {
      ...this.filters,
      [filterUuid]: filterFunc,
    };
  }

  unsubscribe(filterUuid: string): void {
    if (!filterUuid || !this.filters[filterUuid]) return;

    delete this.filters[filterUuid];
  }

  applyFilters = (
    rawListings: ListingDetails[],
    params: qs.ParsedQs,
  ): { perfectMatches: ListingDetails[]; closeMatches: ListingDetails[] } => {
    const filters = Object.keys(this.filters);

    const perfectMatches: ListingDetails[] = [];
    const closeMatches: ListingDetails[] = [];

    rawListings.forEach((listing) => {
      let matches = 0;

      const isPerfectMatch = filters.every((filter) => {
        const isMatch = this.filters[filter](listing, params[filter], params);

        if (isMatch) {
          matches += 1;
        }

        return isMatch;
      });

      if (isPerfectMatch) {
        perfectMatches.push(listing);
        return;
      }

      if (matches === filters.length - 1) {
        closeMatches.push(listing);
      }
    });

    return { perfectMatches, closeMatches };
  };

  applyFiltersAndOverride = (
    rawListings: ListingDetails[],
    params: qs.ParsedQs,
    filterMap: Record<string, Filter>,
  ) => {
    const filters = {
      ...this.filters,
      ...filterMap,
    };

    const filtersList = Object.keys(filters);

    return rawListings.filter((listing) =>
      filtersList.every((filter) =>
        filters[filter](listing, params[filter], params),
      ),
    );
  };
}
