import React, { Suspense, lazy, useCallback } from 'react';
import styled from '@emotion/styled';

import S from './ChatModal.styles';
import { Chat } from '../Chat/Chat';
import Dialog from '../../../components/UI/Dialog/Dialog';
import { useChatWarning } from '../../../providers/ChatWarnignProvider';
import { useChatConversation } from '../../../hooks/query/useChatConversation';
import useBreakpoint, { BreakPoints } from '../../../utils/useBreakpoint';

const EngagementPrompt = lazy(
  () => import('../../../components/V2/EngagementPrompt/EngagementPrompt'),
);

interface ChatModalProps {
  isOpen?: boolean;
  onClose: () => void;
}

export const ChatModal: React.FC<ChatModalProps> = ({ isOpen, onClose }) => {
  const { data: conversation } = useChatConversation();
  const { breakpoint } = useBreakpoint();

  const { message, showWarning, setShowWarning, setMessage } = useChatWarning();

  const onCloseCb = useCallback(() => {
    if (message) {
      setShowWarning(true);
      return;
    }

    onClose();
  }, [message, onClose, setShowWarning]);

  const onConfirm = useCallback(
    async () => setShowWarning(false),
    [setShowWarning],
  );
  const onCancel = useCallback(async () => {
    setMessage('');
    setShowWarning(false);
    onClose();
  }, [onClose, setMessage, setShowWarning]);

  const isMobile = breakpoint <= BreakPoints.md;

  return (
    <ModifiedDialog open={!!isOpen} onClose={onCloseCb} isMobile={isMobile}>
      <S.Container>
        <Chat
          conversationId={conversation?.chatConversationId}
          onLinkClick={onClose}
        />
      </S.Container>

      <Suspense fallback={<></>}>
        <EngagementPrompt
          onClose={onCancel}
          showWhen={showWarning}
          onConfirm={onConfirm}
          closeButtonText="Close"
          confirmButtonText="Send chat"
          title="Forgot to hit send?"
          description="Send your message before you go."
        />
      </Suspense>
    </ModifiedDialog>
  );
};

const ModifiedDialog = styled(Dialog)<{ isMobile: boolean }>(
  ({ isMobile }) => ({
    marginTop: '5rem',

    ".MuiPaper-root[role='dialog']": {
      borderRadius: isMobile ? '' : '1rem',
      margin: isMobile ? 0 : '',
      position: isMobile ? 'absolute' : 'static',
      left: isMobile ? 0 : '',
      right: isMobile ? 0 : '',
      top: isMobile ? '0' : '',
      bottom: isMobile ? '0' : '',
      width: isMobile ? '100%' : '',
      maxHeight: isMobile ? '100vh' : '',
      maxWidth: isMobile ? '100vh' : '',

      "& div[data-qa='chatbox']": {
        height: isMobile ? '76vh' : '',
        marginTop: isMobile ? '2rem' : '',
      },
    },
  }),
);
