import React, { useCallback, useContext, useRef, useState } from 'react';

import { AuthModal } from '../components/V2/AuthModal/AuthModal';

interface AuthModalContextStructure {
  onOpenModal: (onSuccess?: () => undefined) => void;
  onCloseModal: () => void;
}

const AuthModalContext = React.createContext<
  AuthModalContextStructure | undefined
>(undefined);

export const useAuthModal = (): AuthModalContextStructure => {
  const context = useContext(AuthModalContext);

  if (!context) {
    throw new Error(
      'useAuthModalContext cannot be used without AuthModalContext',
    );
  }

  return context;
};

export const AuthModalProvider: React.FC = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onSuccess = useRef(() => undefined);

  const onOpenModal = useCallback((callback?: () => undefined) => {
    setIsModalOpen(true);
    onSuccess.current = callback ?? (() => undefined);
  }, []);

  const onCloseModal = useCallback(() => {
    setIsModalOpen(false);
    onSuccess.current = () => undefined;
  }, []);

  const onLogIn = useCallback(() => {
    onSuccess.current?.();
    onCloseModal();
  }, [onCloseModal]);

  const value = React.useMemo(
    () => ({ onOpenModal, onCloseModal }),
    [onOpenModal, onCloseModal],
  );

  return (
    <AuthModalContext.Provider value={value}>
      <AuthModal
        isOpen={isModalOpen}
        onLogIn={onLogIn}
        onClose={onCloseModal}
      />

      {children}
    </AuthModalContext.Provider>
  );
};
