import styled from '@emotion/styled';

const Row = styled.div`
  display: flex;
  width: 100%;
  margin: 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding: ${(props) => (props.$nopadding ? '' : '12px 14px')};
  cursor: pointer;
`;

const Text = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${(props) => (props.color ? props.color : '')};
  margin-right: 1rem;
  font-size: ${({ theme }) => theme?.fontSize?.lgRem};
  line-height: 1.9rem;
`;

const StaticIcon = styled.div`
  text-align: right;
  font-size: 2rem;
  color: ${(props) => (props.color ? props.color : '')};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 1.9rem;
`;

const StyledIcon = styled(StaticIcon)`
  transition: margin-right 0.3s;
  transition-timing-function: ease-out;
`;

export { Row, Text, StyledIcon, StaticIcon };
