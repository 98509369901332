import { UseMutationResult, useMutation } from 'react-query';

import api from '../../../api';
import {
  useSavedListingsOptimistic,
  useInvalidateSavedListingsRaw,
} from './useSavedListingsRaw';
import { apiRoutes } from '../../../constants';
import { formatFavoriteListingPayload } from './utils';
import { useInvalidateSavedListings } from './useSavedListings';
import { FavoriteParams, TViewedListing } from '../../../types';
import { useInvalidateListingDetail } from '../useListingDetail';
import { listingFavoriteStatuses } from '../../../constants/listing-favorite-statuses';

async function updateFavoriteStatus({
  listingDetail,
  listingComments,
}: FavoriteParams): Promise<void> {
  const payload = formatFavoriteListingPayload({
    listingComments,
    listingDetail,
    status: listingFavoriteStatuses.saved,
  });

  await api.post(apiRoutes.favoriteListing.post(), payload);
}

function updateSavedListing(updateListing: FavoriteParams) {
  return (old: TViewedListing[]) => {
    const isFavorite = old.some((item) => {
      return item.mlsListingId == updateListing.listingDetail?.mlsListingId;
    });

    if (!isFavorite) {
      const favouredListing = formatFavoriteListingPayload({
        listingComments: updateListing.listingComments,
        listingDetail: updateListing.listingDetail,
        status: listingFavoriteStatuses.saved,
      }) as TViewedListing;

      return [...(old || []), favouredListing];
    }

    return old.map((item) => {
      if (item.mlsListingId == updateListing.listingDetail?.mlsListingId) {
        item.listingComments = updateListing.listingComments || '';
      }
      return item;
    });
  };
}

export function useSaveListing(): UseMutationResult<
  void,
  Error,
  FavoriteParams
> {
  const invalidateLD = useInvalidateListingDetail();
  const invalidateSL = useInvalidateSavedListings();
  const invalidateSLR = useInvalidateSavedListingsRaw();

  const savedListingsOptimistic = useSavedListingsOptimistic();

  return useMutation(updateFavoriteStatus, {
    onMutate: (updateListing) => {
      const prevSavedListings = savedListingsOptimistic.update(
        updateSavedListing(updateListing),
      );

      return { prevSavedListings };
    },
    onError: (err, updatedListing, context) => {
      savedListingsOptimistic.revert(context?.prevSavedListings || []);
    },
    onSettled: () => {
      invalidateSLR();
      invalidateSL();
      invalidateLD();
    },
  });
}
