import styled from '@emotion/styled';
import { FC, ChangeEvent, useMemo } from 'react';
import { FormikValues, useFormikContext } from 'formik';

import { Input } from '../../../Input/Input';

interface CodeInputProps {
  codeLength: number;
}

export const CodeInput: FC<CodeInputProps> = ({ codeLength }) => {
  const { values, handleChange, handleSubmit } =
    useFormikContext<FormikValues>();

  const inputFields = useMemo(() => {
    const inputs = [];
    for (let count = 0; count < codeLength; count++) {
      const onChange = (e: ChangeEvent<Element>) => {
        const $prevInput = document.querySelector(
          `#code-${count}`,
        ) as HTMLInputElement;
        const $nextInput = document.querySelector(
          `#code-${count + 1}`,
        ) as HTMLInputElement;

        handleChange(e);

        if ($nextInput) {
          $nextInput.focus();
          return;
        }

        const validLength = values.code.length;
        if (codeLength !== validLength) return;

        $prevInput.blur();
        handleSubmit();
      };

      inputs.push(
        <CodeCell
          key={count}
          type="text"
          id={`code-${count}`}
          onChange={onChange}
          name={`code.${count}`}
          value={values.code[count] ?? ''}
        />,
      );
    }
    return inputs;
  }, [codeLength, handleChange, handleSubmit, values.code]);

  return <>{inputFields}</>;
};

const CodeCell = styled(Input)`
  width: 4rem;
  height: 5.3rem;
  margin-top: 3rem;
  margin-right: 1.3rem;
`;
