import { useQuery, UseQueryResult } from 'react-query';

import { AgentDetails } from '../../types';
import { AgentAPI } from '../../api/hop/AgentAPI';
import { useTokenData } from '../../providers/Authentication/useTokenData';

export function useAgentDetails(): UseQueryResult<AgentDetails, Error> {
  const { data } = useTokenData();

  return useQuery(
    ['agentDetails', data?.agentId],
    () => AgentAPI.get(Number(data?.agentId)),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      enabled: Boolean(data?.agentId),
      refetchIntervalInBackground: false,
    },
  );
}
