// types
import { useEffect } from 'react';
import { TrackPageTypes } from './types';
import { useAnalytics } from './useAnalytics';

export const useTrackPage = (trackPageName?: TrackPageTypes) => {
  const { trackPage } = useAnalytics();
  useEffect(() => {
    if (trackPageName) {
      trackPage(trackPageName);
    }
  }, [trackPage, trackPageName]);
};
