import { useMemo } from 'react';
import tldjs from 'tldjs';

export function getDomain() {
  return tldjs.getDomain(window.location.hostname);
}

export function getSubdomain() {
  return tldjs.getSubdomain(window.location.hostname);
}

export function useDomain() {
  return useMemo(
    () => getDomain(),
    [window.location.hostname], //eslint-disable-line
  );
}

function stripWWW(domain) {
  const domainLC = (domain || '').toLowerCase();
  if (domainLC === 'www') return '';

  if (!domainLC.startsWith('www.')) {
    return domain;
  }

  return domain.replace('www.', '');
}

export function useDomainWithSubdomain() {
  return useMemo(
    () => {
      const subdomain = stripWWW(getSubdomain());
      const domain = stripWWW(getDomain());

      return !subdomain ? domain : `${subdomain}.${domain}`;
    },
    [window.location.hostname], //eslint-disable-line
  );
}

export function useHostname() {
  return window.location.hostname;
}

export function useHostnameWithProtocol() {
  return window.location.protocol + '//' + useHostname();
}
