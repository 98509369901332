import { useMutation } from 'react-query';
import api from '../../../api';
import { apiRoutes } from '../../../constants';
import { useInvalidateMessageCount } from '../../../hooks/query/useMessageCount';

async function read(chatConversationId: string) {
  const response = await api.post(
    apiRoutes.chat.readConversation(chatConversationId),
  );

  return response.data;
}

export function useReadConversation() {
  const invalidate = useInvalidateMessageCount();

  return useMutation(read, {
    onSettled: () => {
      setTimeout(invalidate, 1000);
    },
  });
}
