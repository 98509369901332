import { getDomain } from '../hooks/useDomain';

const brokerageBlacklist = ['exprealty.com'];

class EnvironmentImp {
  private stage?: string = undefined;

  init(stage: string) {
    this.stage = stage;
  }

  isLocal(): boolean {
    return import.meta.env.NODE_ENV === 'development';
  }

  isDev(): boolean {
    return this.getEnv() === 'dev';
  }

  isQa(): boolean {
    return this.getEnv() === 'qa';
  }

  isUAT(): boolean {
    return this.getEnv() === 'uat';
  }

  isStaging(): boolean {
    return this.getEnv() === 'staging';
  }

  isProd(): boolean {
    return this.getEnv() === 'production';
  }

  isTrackable(): boolean {
    return !this.isLocal() && this.isProd();
  }

  hasSessionRecording(): boolean {
    const brokerageDomain = getDomain();
    const isDomainBlacklisted = brokerageBlacklist.includes(brokerageDomain);

    return !this.isLocal() && !isDomainBlacklisted;
  }

  getEnv(): string {
    if (this.isLocal()) return 'local';

    return this.stage || '';
  }
}

export const Environment = new EnvironmentImp();
