import {
  FC,
  useState,
  useCallback,
  useContext,
  createContext,
  useMemo,
} from 'react';

import { Step, Wrapper, StepsContainer } from './Wizard.style';

interface WizardProps {
  startingStep?: number;
  components: FC[];
  allowStepChange?: boolean;
}

interface WizardContextStruct {
  nextStep: () => void;
  prevStep: () => void;
}

const WizardContext = createContext<WizardContextStruct | undefined>(undefined);

export const useWizard = (): WizardContextStruct => {
  const context = useContext(WizardContext);

  if (context === undefined) {
    throw new Error('useWizard cannot be used outside of WizardProvider!');
  }

  return context;
};

export const Wizard: FC<WizardProps> = ({
  components,
  startingStep,
  allowStepChange = false,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(startingStep ?? 0);

  const onStepChange = useCallback(
    (step) => {
      if (!allowStepChange) return;
      setCurrentStep(step);
    },
    [allowStepChange],
  );

  const nextStep = useCallback(() => {
    if (currentStep === components.length - 1) return;

    setCurrentStep(currentStep + 1);
  }, [currentStep, components.length]);

  const prevStep = useCallback(() => {
    if (!currentStep) return;

    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  const CurrentComponent: FC = useMemo(
    () => components[currentStep],
    [components, currentStep],
  );

  const contextValue = useMemo(
    () => ({
      nextStep,
      prevStep,
    }),
    [nextStep, prevStep],
  );

  return (
    <WizardContext.Provider value={contextValue}>
      <Wrapper>
        <CurrentComponent />
        <StepsContainer>
          {components?.map((child, index) => (
            <Step
              key={index}
              isActive={index === currentStep}
              onClick={() => onStepChange(index)}
            />
          ))}
        </StepsContainer>
      </Wrapper>
    </WizardContext.Provider>
  );
};
