import styled from '@emotion/styled';
import { colorOpacityApplier } from '../../../../../../../../services/colorOpacityApplier';

type WithSelected = {
  isSelected?: boolean;
};

type WithLight = {
  isLight?: boolean;
};

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  border: `1px solid ${theme.colors.grey.light}`,
  borderRadius: theme.borderRadius.lgRem,
  height: '4.7rem',
  marginTop: '1.9rem',
  overflow: 'hidden',
}));

const getFiledItemStyle = (
  theme: any, //eslint-disable-line
  isLight?: boolean,
  isSelected?: boolean,
) => {
  switch (true) {
    case isLight:
      return {
        backgroundColor: colorOpacityApplier(theme.colorPrimary, '0.2'),
        color: theme.colorPrimary,
      };
    case isSelected:
      return {
        backgroundColor: theme.colorPrimary,
        color: theme.colors.white,
      };
    default:
      return {
        backgroundColor: theme.colors.white,
        color: theme.colors.grey.dark2,
      };
  }
};

export const FieldItem = styled('div')<WithSelected & WithLight>(
  ({ theme, isSelected, isLight }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    fontSize: theme.fontSize.mdRem,
    fontWeight: theme.fontWeight.medium,
    ...getFiledItemStyle(theme, isLight, isSelected),
    '&:not(:first-of-type)': {
      borderLeft: `1px solid ${theme.colors.grey.main}`,
    },
    cursor: 'pointer',
  }),
);

export const FirstItem = styled(FieldItem)({
  justifyContent: 'flex-start',
  width: '25%',
  flex: '0 1 auto',
  paddingLeft: '1.9rem',
});
