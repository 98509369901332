import { useMemo } from 'react';
import useSeasons from '../pages/MyMaintenance/hooks/useSeasons';

const useHasMaintenanceInteraction = () => {
  const { data: seasons } = useSeasons();

  const hasMaintenanceInteraction = useMemo(() => {
    if (!seasons) return false;

    return seasons.some((season) => season.items.some((item) => item.checked));
  }, [seasons]);

  return hasMaintenanceInteraction;
};

export default useHasMaintenanceInteraction;
