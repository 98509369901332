import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useKvCoreCookieToken } from '../../../../providers/Authentication/useKvCoreCookieToken';
import { customAttributePrefix } from '../../../../constants/auth';
import { useRegisterUser } from '../../../../providers/Authentication/auth';
import { useUsername } from '../../../../providers/Authentication/useUsername';
import { useHomeownerByContact } from '../../../../hooks/useHomeownerByContact';
import { useTokenData } from '../../../../providers/Authentication/useTokenData';
import { ErrorMessageExtractor } from '../../../../services/ErrorMessageExtractor';

type onSubmitType = {
  password: string;
  passwordConfirmation?: string;
  onSuccess?: () => void;
  onError?: (error: string) => void;
};

type UsePasswordCreationType = (params: onSubmitType) => void;

const useSignUpUser = (): UsePasswordCreationType => {
  const { t } = useTranslation();
  const username = useUsername();
  const tokenData = useTokenData();
  const { getToken } = useKvCoreCookieToken();
  const registerUser = useRegisterUser();
  const { data: homeownerDetail } = useHomeownerByContact();

  const signUpUser = useCallback(
    async ({
      password,
      passwordConfirmation,
      onSuccess,
      onError,
    }: onSubmitType) => {
      const token = getToken();
      const email = homeownerDetail?.Communication?.emailPrimary ?? '';

      try {
        await registerUser.mutateAsync({
          email,
          username,
          password,
          passwordConfirmation,
          attributes: {
            email: homeownerDetail?.Communication?.emailPrimary || '',
            [`${customAttributePrefix}token`]: token.toString(),
            [`${customAttributePrefix}accountId`]:
              tokenData.data?.accountIdRaw?.toString() || '',
            [`${customAttributePrefix}contactId`]:
              tokenData.data?.contactId?.toString() || '',
            [`${customAttributePrefix}agentId`]:
              tokenData.data?.agentId?.toString() || '',
          },
        });

        onSuccess?.();
      } catch (err) {
        const msg = ErrorMessageExtractor.extract(err);
        onError?.(msg || t('errors.somethingWentWrong'));
      }
    },
    [
      getToken,
      registerUser,
      username,
      homeownerDetail?.Communication?.emailPrimary,
      tokenData.data?.accountIdRaw,
      tokenData.data?.contactId,
      tokenData.data?.agentId,
      t,
    ],
  );

  return signUpUser;
};

export default useSignUpUser;
