import { useMemo } from 'react';
import { ListingDetails } from '../../../../../../types';
import { getListingPriceValue } from '../../../../../../utils/getListingPriceValue';

type Props = {
  listingDetails?: ListingDetails;
};

export default function useReducedPrice({
  listingDetails,
}: Props): number | null {
  const reducedPrice = useMemo(() => {
    if (!listingDetails) return null;

    const isReduced =
      listingDetails.ListingSummary.listingMessage?.toLowerCase() === 'reduced';
    if (isReduced) {
      if (listingDetails.PriceHistory.length === 0) return null;
      const firstPrice = listingDetails.PriceHistory[0]?.priceChange || 0;
      const currentPrice = getListingPriceValue(listingDetails) || 0;
      const reducedAmount = currentPrice - firstPrice;
      return reducedAmount;
    } else {
      return null;
    }
  }, [listingDetails]);

  return reducedPrice;
}
