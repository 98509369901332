import styled from '@emotion/styled';
import { WithIsMobile } from '../../../types';

export const ForgotPassModalContentWrapper = styled.div<WithIsMobile>`
  padding: 50px 20px 10px 20px;
  width: ${({ isMobile }) => (isMobile ? '100%' : '500px')};
  height: 75rem;
`;

export const ForgotPassModalTitle = styled.p`
  font-size: 3.2rem;
  text-align: left;
  font-weight: 700;
`;

export const LinkText = styled.p`
  font-size: '12px';
  text-decoration: underline;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.branding.main};
`;

export const ResendWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 20px;
`;

export const Text = styled.p`
  font-size: 1.2rem;
`;
export const ForgotPassFormWrapper = styled.div`
  padding: 40px 0;
`;

export const ErrorMessage = styled('p')(({ theme }) => ({
  margin: 0,
  padding: 0,
  fontSize: '0.9rem',
  color: theme.colors.red,
}));

export const PasswordRulesList = styled.ul`
  padding: 24px 20px;
`;

export const ReturnTextWrapper = styled.p`
  width: 100%;
  padding-top: 18px;
  text-align: center;
`;
