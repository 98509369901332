import styled from '@emotion/styled';

interface WithDimensionParams {
  width?: string;
  height?: string;
}

export const ErrorPage = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: '2rem',
  textAlign: 'center',
  color: theme.colors.white,
  backgroundColor: theme.colors.branding.main,
}));

export const ImageWrapper = styled('div')<WithDimensionParams>(({
  width,
  height,
}) => {
  const style = {
    width: width ? width : '50%',
    height: 'auto',
    margin: '0 auto',
    '& img': {
      width: '100%',
      height: '100%',
    },
  };

  if (height) {
    style.width = 'auto';
    style.height = height;
  }

  return style;
});

export const Title = styled('p')(({ theme }) => ({
  fontSize: '4.8rem',
  marginTop: '2rem',
  [`@media (max-width: ${theme.breakpoint.md.max})`]: {
    fontSize: '3.8rem',
  },
  [`@media (max-width: ${theme.breakpoint.md.min})`]: {
    fontSize: '2rem',
  },
}));

export const Subtitle = styled('p')(({ theme }) => ({
  fontSize: '2.4rem',
  marginTop: '1.9rem',
  marginBottom: '1.5rem',
  [`@media (max-width: ${theme.breakpoint.md.max})`]: {
    fontSize: '1.4rem',
  },
  [`@media (max-width: ${theme.breakpoint.md.min})`]: {
    fontSize: '1rem',
  },
}));

export const Text = styled('p')(({ theme }) => ({
  fontSize: '1.6rem',
  [`@media (max-width: ${theme.breakpoint.md.max})`]: {
    fontSize: '1rem',
    margin: 'auto 2rem',
  },
  [`@media (max-width: ${theme.breakpoint.md.min})`]: {
    fontSize: '0.8rem',
    margin: 'auto 2rem',
  },
}));

export const ButtonWrapper = styled('div')({
  display: 'flex',
  width: 'fit-content',
  justifyContent: 'space-between',
  margin: '2rem auto',
  '& > *': {
    marginRight: '1.2rem',
  },
});

export const Note = styled('div')(({ theme }) => ({
  color: theme.colors.grey.light,
  fontSize: theme.fontSize.smRem,
  fontStyle: 'italic',
  [`@media (max-width: ${theme.breakpoint.md.max})`]: {
    fontSize: '0.8rem',
    margin: 'auto 2rem',
  },
  [`@media (max-width: ${theme.breakpoint.md.min})`]: {
    fontSize: '0.6rem',
    margin: 'auto 2rem',
  },
}));
