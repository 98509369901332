import { useMemo } from 'react';
import { useQuery, useQueryClient, UseQueryResult } from 'react-query';

import api from '../../../api';
import { apiRoutes } from '../../../constants';
import { ListingDetails } from '../../../types';
import { useSavedListingsRaw } from './useSavedListingsRaw';

type InvalidateFn = () => void;
type ListingIds = {
  mlsId: number;
  mlsListingId: string;
};

export const SAVED_LISTINGS_KEY = 'savedListings';

const fetchSavedListings = async (
  validListings?: ListingIds[],
): Promise<ListingDetails[] | null> => {
  if (!validListings?.length) return [];

  const result = await api.post(apiRoutes.listing.get(), {
    listings: validListings,
  });

  const listingsDetails: ListingDetails[] = result.data || [];

  return listingsDetails.filter((listingDetail) => {
    const isInvalid = !listingDetail?.mlsListingId;
    if (isInvalid) return false;

    const savedListing = validListings.find(
      (item) =>
        item.mlsListingId === listingDetail.mlsListingId &&
        String(item.mlsId) === String(listingDetail.MlsInfo.mlsId),
    );

    return !!savedListing;
  }) as ListingDetails[];
};

export function useInvalidateSavedListings(): InvalidateFn {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries(SAVED_LISTINGS_KEY);
  };
}

export const useSavedListings = (): UseQueryResult<ListingDetails[]> => {
  const { data: savedListings, isLoading: isLoadingSavedListings } =
    useSavedListingsRaw();

  const listings = useMemo(
    () =>
      savedListings?.map((l) => ({
        mlsId: l.mlsId,
        mlsListingId: l.mlsListingId,
      })),
    [savedListings],
  );

  const query = useQuery(
    [SAVED_LISTINGS_KEY, listings],
    () => fetchSavedListings(listings),
    {
      keepPreviousData: true,
    },
  );

  return {
    ...query,
    isLoading: query.isLoading || isLoadingSavedListings,
  } as UseQueryResult<ListingDetails[]>;
};
