import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { ListingDetails } from '../../../types';
import { calcDaysSinceToday } from '../../../utils/date-helper';
import { LISTING_STATUSES } from '../../../constants/listingStatuses';

export const useListingDateLabel = (listing: ListingDetails): string => {
  const listingStatus = listing?.ListingSummary?.listingStatus;
  const soldListingDate = listing?.SalePrice?.saleDate;
  const activeListingDate = listing?.ListingSummary?.listingDate;

  const { t } = useTranslation();

  const statusLower = listingStatus?.toLowerCase();
  if (LISTING_STATUSES.ACTIVE.includes(statusLower)) {
    return `${calcDaysSinceToday(activeListingDate)} ${t(
      'savedHomes.daysOnTheSite',
    )}`;
  }

  if (LISTING_STATUSES.SOLD.includes(statusLower)) {
    const formatedDate = dayjs(soldListingDate).format('MM/DD/YYYY');

    return soldListingDate
      ? `${t('savedHomes.sold')}: ${formatedDate}`
      : t('savedHomes.sold');
  }

  if (LISTING_STATUSES.PENDING.includes(statusLower)) {
    return t('savedHomes.pending');
  }

  if (LISTING_STATUSES.COMING_SOON.includes(statusLower)) {
    return t('savedHomes.comingSoon');
  }

  return '';
};
