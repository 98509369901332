import api from '../api';
import { apiRoutes } from '../constants';
import { Address, Coordinates, ListingDetails } from '../types';

type AddressWithLocation = Address & {
  latitude?: number;
  longitude?: number;
};

export const fetchPropertyDetailsByAddress = async (
  address?: Address,
  coordinates?: Coordinates,
): Promise<ListingDetails> => {
  if (!address) {
    throw new Error('Missing address');
  }

  const params = {
    addressLine1: address.addressLine1,
    locality: address.locality,
    countrySubd: address.countrySubd,
    postal1: address.postal1,
  } as AddressWithLocation;

  if (coordinates) {
    params.latitude = coordinates.lat;
    params.longitude = coordinates.lng;
  }

  const response = await api.get(apiRoutes.property.getByAddress(), { params });

  return response.data;
};
