import { ListingDetails } from '../../../../../types';
import { getListingPriceValue } from '../../../../../utils/getListingPriceValue';

export const UUID = 'priceRange';

export function filter(
  listing: ListingDetails,
  filter?: { min?: number; max?: number },
): boolean {
  if (!filter || (!filter.min && !filter.max)) return true;

  const price = getListingPriceValue(listing);

  if (!price) return false;

  const min = Number(filter.min) || null;
  const max = Number(filter.max) || null;

  if (min && max) {
    return price >= min && price <= max;
  }

  if (min) {
    return price >= min;
  }

  return price <= (max ?? 0);
}

export function filterWithPredictiveMax(
  listing: ListingDetails,
  filter?: { min?: number; max?: number },
): boolean {
  if (!filter || (!filter.min && !filter.max)) return true;

  const price = getListingPriceValue(listing);

  if (!price) return false;

  const min = Number(filter.min) || null;
  const max = Number(filter.max) || null;

  if (min && max) {
    return price >= min && price <= max * 1.1;
  }

  if (min) {
    return price >= min;
  }

  return price <= (max ?? 0) * 1.1;
}

export function filterWithoutRange(): boolean {
  return true;
}
