import { useCallback, useState } from 'react';

import styled from '@emotion/styled';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

import { ActiveTab } from './types';
import NotificationTab from './components/NotificationTab';
import { useMarkAllAsRead } from './hooks/useMarkAllAsRead';
import { NotificationsList } from './components/NotificationList';
import { useNotificationsCount } from './hooks/useNotificationsCount';
import notificationOptionsAttom from './state/notificationOptionsAttom';

export const NotificationsPanel = (): JSX.Element => {
  const { t } = useTranslation();

  const { mutate } = useMarkAllAsRead();
  const { data } = useNotificationsCount();

  const setOpenDropdownId = useSetRecoilState(notificationOptionsAttom);

  const hasUnread = Boolean(data?.unread);

  const [activeTab, setActiveTab] = useState(ActiveTab.All);

  const onMarkAllAsRead = useCallback(() => {
    if (!data?.unread) return;
    mutate();
  }, [mutate, data]);

  return (
    <Container onClick={() => setOpenDropdownId(undefined)}>
      <Header>
        <Title data-qa={'notifications_title'}>{t('Notifications')}</Title>
      </Header>
      <HeaderActions>
        <TabsWrapper>
          <NotificationTab
            dataQa={'notifications_all_button'}
            text={t('All')}
            hasUnread={false}
            isActive={activeTab === ActiveTab.All}
            onClick={() => setActiveTab(ActiveTab.All)}
          />
          <NotificationTab
            dataQa={'notifications_unread_button'}
            hasUnread={hasUnread}
            isActive={activeTab === ActiveTab.Unread}
            onClick={() => setActiveTab(ActiveTab.Unread)}
            text={`${t('Unread')} ${hasUnread ? `(${data?.unread})` : ''}`}
          />
        </TabsWrapper>
        {Boolean(data?.unread) && (
          <MarkAllAsRead
            data-qa={'notifications_mark_all_read'}
            onClick={onMarkAllAsRead}
            role="button"
          >
            {t('notifications.markAllAsRead')}
          </MarkAllAsRead>
        )}
      </HeaderActions>
      <NotificationsList activeTab={activeTab} />
    </Container>
  );
};

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  cursor: 'default',
  borderRadius: '3px',
  flexDirection: 'column',
  paddingBottom: '5.2rem',
  padding: '2.2rem 1.4rem',
  background: theme.colors.white,

  [`@media (min-width: ${theme.breakpoint.md.max})`]: {
    width: '38.4rem',
    overflowY: 'auto',
    paddingBottom: '2.2rem',
    border: '0.1rem solid #e0e0df',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    height:
      window.innerHeight - 80 > 665
        ? '66.5rem'
        : `${window.innerHeight - 90}px`,
  },
}));

const Header = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const HeaderActions = styled('div')({
  display: 'flex',
  margin: '1.5rem 0 3.5rem',
  justifyContent: 'space-between',
});

const TabsWrapper = styled('div')({
  display: 'flex',
});

const MarkAllAsRead = styled('span')(({ theme }) => ({
  display: 'flex',
  textAlign: 'right',
  fontSize: '1.4rem',
  lineHeight: '1.7rem',
  alignItems: 'center',
  textDecoration: 'underline',
  color: theme.colors.linkText,
}));

const Title = styled('div')(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: theme.fontWeight.bold,
}));
