import { useMemo, useState } from 'react';

import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import {
  Text,
  LinkText,
  ErrorMessage,
  ReturnTextWrapper,
  PasswordRulesList,
  ForgotPassModalTitle,
  ForgotPassFormWrapper,
  ForgotPassModalContentWrapper,
} from './ForgotPasswordForm.style';
import { Input } from '../Input/Input';
import { Button } from '../../UI/Button';
import { ResendButton } from './ResendButton';
import { passwordRegex } from '../../../constants/password';

import useForgotPassword from './hooks/useForgotPassword';
import { useIsMobileView } from '../../../hooks/useIsMobileView';
import { useTrackPage, TrackPageTypes } from '../../../features/analytics';
import { useHomeownerByContact } from '../../../hooks/useHomeownerByContact';

export type ForgotPasswordFormType = {
  email?: string;
  password?: string;
  confirmPassword?: string;
  verificationCode?: string;
};

type Props = {
  onBack: () => void;
};

function ForgotPasswordForm({ onBack }: Props): JSX.Element {
  useTrackPage(TrackPageTypes.forgotPassword);

  const { t } = useTranslation();
  const isMobile = useIsMobileView();
  const { onForgotPassword } = useForgotPassword();
  const { data: homeownerDetail } = useHomeownerByContact();

  const [formErrors, setFormErrors] = useState<string>();

  const handleSubmit = (values: ForgotPasswordFormType) => {
    onForgotPassword({
      values,
      onSuccess: () => {
        onBack();
      },
      onError: (error) => {
        setFormErrors(error);
      },
    });
  };

  const initialValues = useMemo(
    () => ({
      email: homeownerDetail?.Communication?.emailPrimary,
      verificationCode: undefined,
      password: undefined,
      confirmPassword: undefined,
    }),
    [homeownerDetail?.Communication?.emailPrimary],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .required(t('common.required'))
          .email(t('auth.formErrors.email')),
        verificationCode: Yup.string().required(t('common.required')),
        password: Yup.string()
          .required(t('common.required'))
          .matches(
            passwordRegex,
            t('auth.formErrors.passwordMustMatchCriteria'),
          ),
        confirmPassword: Yup.string()
          .required(t('common.required'))
          .oneOf(
            [Yup.ref('password'), ''],
            t('auth.formErrors.passwordsMustMatch'),
          ),
      }),
    [t],
  );

  return (
    <ForgotPassModalContentWrapper role="forgot-pass-form" isMobile={isMobile}>
      <ForgotPassModalTitle>{t('auth.forgotPassword')}</ForgotPassModalTitle>
      <Text>{t('auth.codeWasSent')}</Text>
      <ForgotPassFormWrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ values, errors, handleChange, isSubmitting }) => (
            <Form>
              <Input
                disabled
                name="email"
                type="email"
                value={values.email}
                error={errors.email}
                onChange={handleChange}
                placeholder={t('auth.fields.email')}
              />
              <Input
                type="text"
                onChange={handleChange}
                name="verificationCode"
                value={values.verificationCode}
                error={errors.verificationCode}
                placeholder={t('auth.fields.verificationCode')}
              />
              <ResendButton setFormErrors={setFormErrors} />
              <Input
                name="password"
                type="password"
                onChange={handleChange}
                value={values.password}
                error={errors.password}
                placeholder={t('auth.fields.password')}
              />
              <Input
                type="password"
                name="confirmPassword"
                onChange={handleChange}
                value={values.confirmPassword}
                error={errors.confirmPassword}
                placeholder={t('auth.fields.confirmPassword')}
              />
              <PasswordRulesList>
                <li>{t('passwordRules.0')}</li>
                <li>{t('passwordRules.1')}</li>
                <li>{t('passwordRules.2')}</li>
                <li>{t('passwordRules.3')}</li>
                <li>{t('passwordRules.4')}</li>
              </PasswordRulesList>

              <div style={{ margin: '50px 0 20px 0', textAlign: 'center' }}>
                {formErrors && <ErrorMessage>{formErrors}</ErrorMessage>}
                <Button
                  fullWidth
                  disabled={isSubmitting}
                  type="submit"
                  size="large"
                >
                  {t('auth.submit')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <ReturnTextWrapper>
          <LinkText onClick={onBack}>{t('auth.backToSignIn')}</LinkText>
        </ReturnTextWrapper>
      </ForgotPassFormWrapper>
    </ForgotPassModalContentWrapper>
  );
}

export default ForgotPasswordForm;
