import { Address, AddressAndMetaPayload } from '../types';

type GetAddressAndMetaPayload = {
  buyHome: boolean;
  ownHome: boolean;
  address?: Address | null;
  oldAddress?: string | null;
};

export const getAddressAndMetaPayload = ({
  buyHome,
  ownHome,
  address,
  oldAddress,
}: GetAddressAndMetaPayload): AddressAndMetaPayload => {
  if (!ownHome) {
    return {
      Address: null,
      Meta: {
        addressVerified: false,
        ownsPropertyInd: false,
        lookingToRentInd: !buyHome,
      },
    };
  }

  const isOldAddress = address?.fullAddress === oldAddress;

  if (isOldAddress) {
    return {
      Address: address,
      Meta: {
        lookingToRentInd: !buyHome,
        addressVerified: true,
        ownsPropertyInd: true,
      },
    };
  }

  return {
    Address: address,
    Meta: {
      lookingToRentInd: !buyHome,
      addressVerified: true,
      ownsPropertyInd: true,
    },
  };
};
