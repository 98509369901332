export default `<svg id="Group_9162" data-name="Group 9162" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="46.403" height="46.372" viewBox="0 0 46.403 46.372">
<defs>
  <clipPath id="clip-path">
    <rect id="Rectangle_3429" data-name="Rectangle 3429" width="46.403" height="46.372" fill="#1F4D8C"/>
  </clipPath>
</defs>
<g id="Group_9162-2" data-name="Group 9162" clip-path="url(#clip-path)">
  <path id="Path_3306" data-name="Path 3306" d="M33.073,42.362c-.41-.023-.993-.089-1.574-.073a1.006,1.006,0,0,0-.684.306,9.667,9.667,0,0,1-11.284,3.08,8.723,8.723,0,0,1-3.858-3,.86.86,0,0,0-.9-.4,9.177,9.177,0,0,1-7.5-2.3A9.12,9.12,0,0,1,4.089,32a1.458,1.458,0,0,0-.628-1.5,9.475,9.475,0,0,1,.173-14.773,1.064,1.064,0,0,0,.475-1.1,9.487,9.487,0,0,1,9.2-10.614c.677-.028,1.444.241,2.013,0,.549-.231.869-.968,1.336-1.438C20.6-1.39,27.559-.57,30.671,3.617a1,1,0,0,0,1.044.474,9.086,9.086,0,0,1,7.339,2.235,9.153,9.153,0,0,1,3.263,8.067,1.413,1.413,0,0,0,.6,1.452,9.465,9.465,0,0,1-.168,14.808,1.025,1.025,0,0,0-.455,1.05c.552,5.683-3.323,10.231-9.221,10.658M29.721,7.429c-.229-.391-.458-.79-.693-1.184a6.636,6.636,0,0,0-5.981-3.462,6.256,6.256,0,0,0-5.773,3.628c-.186.352-.4.689-.613,1.058-2.918-1.14-5.6-.793-7.794,1.412a6.71,6.71,0,0,0-1.375,7.757c-.4.236-.752.437-1.1.642a6.7,6.7,0,0,0,.019,11.814c.358.212.713.429,1.082.651A6.681,6.681,0,0,0,8.883,37.5c2.193,2.2,4.883,2.533,7.821,1.383,1.256,2.849,3.317,4.615,6.5,4.608s5.221-1.8,6.511-4.605c2.942,1.148,5.629.807,7.82-1.4a6.7,6.7,0,0,0,1.379-7.761c.4-.234.747-.434,1.092-.64A6.7,6.7,0,0,0,40,17.273c-.358-.213-.715-.428-1.063-.637a6.964,6.964,0,0,0-9.212-9.207" transform="translate(0 0)" fill="#1F4D8C"/>
  <path id="Path_3307" data-name="Path 3307" d="M63.65,81.64a7.2,7.2,0,0,1,.535-.688q4.181-4.154,8.373-8.3c.683-.676,1.3-.775,1.932-.332a1.306,1.306,0,0,1,.311,1.993,1.867,1.867,0,0,1-.159.182Q69.621,79.522,64.6,84.541a1.317,1.317,0,0,1-2.073,0q-2.5-2.462-4.957-4.958a1.292,1.292,0,0,1-.064-2.021,1.352,1.352,0,0,1,2.033.07c1.175,1.132,2.335,2.28,3.5,3.421.156.152.315.3.613.582" transform="translate(-43.257 -54.637)" fill="#1F4D8C"/>
</g>
</svg>`;
