import { useCallback } from 'react';

import styled from '@emotion/styled';
import { useRecoilState } from 'recoil';

import { Icon, IconType } from '../../../../components/icon';
import { onClickProps } from '../../../../utils/ui-event-helper';
import sideMenuAtom from '../../../../recoil/sideMenu/sideMenuAtom';
import { useIsMobileView } from '../../../../hooks/useIsMobileView';
import { MainOrigin, SubOrigin, useAnalytics } from '../../../analytics';

export function SideMenuCloseIcon(): JSX.Element {
  const isMobileView = useIsMobileView();
  const [isOpen, setIsOpen] = useRecoilState(sideMenuAtom);
  const openMenu = useCallback(() => setIsOpen(true), [setIsOpen]);
  const closeMenu = useCallback(() => setIsOpen(false), [setIsOpen]);
  const { trackPageSwitch } = useAnalytics();
  const handleMenuOpen = useCallback(() => {
    openMenu();
  }, [openMenu]);

  if (isOpen) {
    return (
      <MenuToggle
        show
        data-qa="close-icon-nav-item"
        {...onClickProps({
          onClick: closeMenu,
        })}
      >
        <Icon type={IconType.x} />
      </MenuToggle>
    );
  }

  return (
    <MenuToggle
      show={!isMobileView}
      data-qa="open-icon-nav-item"
      {...onClickProps({
        onClick: () => {
          trackPageSwitch(MainOrigin.mainNav, SubOrigin.menu);
          handleMenuOpen();
        },
      })}
    >
      <Icon
        style={{ position: 'static', height: '3.2rem' }}
        type={IconType.bars}
      />
    </MenuToggle>
  );
}

interface WithShow {
  show?: boolean;
}

const MenuToggle = styled('span')<WithShow>(({ theme, show }) => ({
  cursor: 'pointer',
  fontSize: '2.8rem',
  maxHeight: '3.2rem',
  visibility: show ? 'visible' : 'hidden',

  '> *': {
    color: theme.colors.branding.main,
  },
}));
