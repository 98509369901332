import qs from 'qs';

import { BASENAME } from './basename';
import { SearchFilter } from '../pages/SearchResults/hooks/useActiveFilters';

const routes = {
  downloadApp: () => `${BASENAME}app`,
  listingDetail: (mlsId, mlsListingId) => `/listing/${mlsId}/${mlsListingId}`,
  listing: (mlsId, mlsListingId) => `/listings/${mlsId}/${mlsListingId}`,
  logout: (returnUrl = null) => {
    let route = `/logout`;

    if (returnUrl) {
      route += `?returnUrl=${returnUrl}`;
    }

    return route;
  },
  myAgent: () => `/my-agent`,
  myHome: () => `/my-home`,

  myHomeMarket: () => `/my-home-market`,
  myMarkets: () => `/my-markets`,
  productValueInterstitial: () => `/product-value-interstitial`,
  marketDetail: (countrySubd, areaType, areaId) =>
    `/market/${countrySubd}/${areaType}/${areaId}`,
  root: () => `/`,
  agentDetail: () => `/agent-detail`,
  search: () => '/search',
  myJourney: () => '/my-journey',
  searchResults: ({
    areaType,
    areaId,
    countrySubd,
    sortValue,
    polygonId,
    sale,
    bedrooms,
    bathrooms,
    size,
    lotSize,
    year,
    priceRange,
    propertyType,
    userCoordinates,
    polygon,
    searchName,
  }) => {
    const path = '/search';
    if (!areaType && !areaId && !countrySubd && !sortValue) return path;

    let searchResultsPath = `${path}/result?&sort=${sortValue}&${qs.stringify({
      sale,
    })}&${SearchFilter.PropType}=${propertyType}`;

    if (userCoordinates) {
      searchResultsPath =
        searchResultsPath + `&${qs.stringify({ userCoordinates })}`;
    }

    if (searchName) {
      searchResultsPath = searchResultsPath + `&searchName=${searchName}`;
    }

    if (polygon?.length && !areaId) {
      searchResultsPath =
        searchResultsPath + `&filterBy=polygon&${qs.stringify({ polygon })}`;
    }

    if (areaType && areaId) {
      const filterBy = polygonId
        ? `&filterBy=polygonId&polygonId=${polygonId}`
        : `&filterBy=area`;

      searchResultsPath += `${filterBy}&areaType=${areaType}&areaId=${areaId}`;

      if (countrySubd) {
        searchResultsPath += `&countrySubd=${countrySubd}`;
      }
    }

    if (bedrooms && bedrooms?.value) {
      searchResultsPath = searchResultsPath + `&${qs.stringify({ bedrooms })}`;
    }

    if (bathrooms && bathrooms?.value) {
      searchResultsPath = searchResultsPath + `&${qs.stringify({ bathrooms })}`;
    }

    if (size?.min || size?.max) {
      searchResultsPath = searchResultsPath + `&${qs.stringify({ size })}`;
    }
    if (lotSize?.min || lotSize?.max) {
      searchResultsPath = searchResultsPath + `&${qs.stringify({ lotSize })}`;
    }
    if (year?.min || year?.max) {
      searchResultsPath = searchResultsPath + `&${qs.stringify({ year })}`;
    }
    if (priceRange?.min || priceRange?.max) {
      searchResultsPath =
        searchResultsPath + `&${qs.stringify({ priceRange })}`;
    }

    return searchResultsPath;
  },
  savedHomes: (mlsListingId, mlsId) => {
    const basePath = '/saved-homes';

    if (!mlsListingId && !mlsId) return basePath;
    return `${basePath}?mlsListingId=${mlsListingId}&mlsId=${mlsId}`;
  },

  mobileAppDeeplink: () => '/app',
  notifications: () => '/notifications',
};

export const routerPaths = {
  auth: {
    logout: routes.logout(),
  },
  myAgent: {
    myAgent: routes.myAgent(),
  },
  myHome: {
    myHome: routes.myHome(),
    myHomeMarket: routes.myHomeMarket(),
    myMarkets: routes.myMarkets(),
    marketReport: routes.myHomeMarket(),
  },
  nextHome: {
    listingDetail: routes.listingDetail(':mlsId', ':mlsListingId'),
    listing: routes.listing(':mlsId', ':mlsListingId'),
    marketDetail: routes.marketDetail(':countrySubd', ':areaType', ':areaId'),
  },
  search: {
    search: routes.search(),
    searchResults: '/search/result',
  },
  myJourney: {
    myMove: '/my-journey/my-move',
    myMaintenance: '/my-journey/my-maintenance',
    myTransactions: '/my-journey/my-transaction',
    movingInventory: '/my-journey/my-move/moving-inventory',
    myMoveEssentialQuestions: '/my-journey/my-move/essential-questions',
  },
  root: routes.root(),
  savedHomes: routes.savedHomes(),
  mobileAppDeeplink: routes.mobileAppDeeplink(),
  mobileAppDeeplinkDot: routes.mobileAppDeeplink() + '.',
  faq: '/frequently-asked-questions',
  faqMobile: '/faq',
  notifications: routes.notifications(),
};

export default routes;
