import {
  ModalHeaderContainer,
  ModalIconWrapper,
  ModalTitle,
} from './ModalHeader.styles';

type Props = {
  icon: JSX.Element;
  title: string;
};

function ModalHeader({ icon, title }: Props): JSX.Element {
  return (
    <ModalHeaderContainer>
      <ModalIconWrapper>{icon}</ModalIconWrapper>
      <ModalTitle>{title}</ModalTitle>
    </ModalHeaderContainer>
  );
}

export default ModalHeader;
