import { useTranslation } from 'react-i18next';
import {
  NavigationItemContainer,
  NavigationItemIconWrap,
  NavigationItemLabel,
} from '../../SideMenu.style';
import { NavLink, NavLinkGrey } from '../../SideMenuOld.style';

type Props = {
  onClick:
    | ((e: React.MouseEvent<HTMLElement>) => boolean)
    | ((e: React.MouseEvent<HTMLElement>) => void);
  label: string;
  Icon?: JSX.Element;
  grayLink?: boolean;
  dataQa?: string;
};

function NavListItem({
  onClick,
  label,
  Icon,
  grayLink,
  dataQa,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const NavLinkComponent = grayLink ? NavLinkGrey : NavLink;
  return (
    <NavigationItemContainer data-qa={dataQa}>
      <NavLinkComponent onClick={onClick}>
        <NavigationItemLabel>{t(label)}</NavigationItemLabel>

        <NavigationItemIconWrap>{Icon}</NavigationItemIconWrap>
      </NavLinkComponent>
    </NavigationItemContainer>
  );
}

export default NavListItem;
