import { useMemo } from 'react';

import { formatFullName } from './user-helper';
import { useAgentDetails } from '../hooks/query/useAgentDetails';
import { useHomeownerByContact } from '../hooks/useHomeownerByContact';

import agentFallbackAvatar from '/img/agent-no-avatar.png?url';

export const useAgentHelper = () => {
  const { data: homeownerDetail } = useHomeownerByContact();

  const { data: agentDetail } = useAgentDetails();

  const website = useMemo(() => {
    const { companyWebsite } = agentDetail?.Links ?? {};
    const { agentDomain } = homeownerDetail?.Meta ?? {};

    return companyWebsite || `http://${agentDomain}`;
  }, [agentDetail, homeownerDetail?.Meta]);

  const avatarSrc = useMemo(() => {
    return agentDetail?.Bio?.agentPhoto || agentFallbackAvatar;
  }, [agentDetail?.Bio?.agentPhoto]);

  const primaryPhone = useMemo(() => {
    if (!agentDetail) return '';

    const { AgentCommunication } = agentDetail ?? {};

    return (
      AgentCommunication?.phoneMobile || AgentCommunication?.phoneWork || ''
    );
  }, [agentDetail]);

  const { firstName, lastName, fullName } = useMemo(() => {
    if (!agentDetail) return {};

    const {
      Name: { firstName, lastName },
    } = agentDetail;

    return { firstName, lastName, fullName: formatFullName(agentDetail) };
  }, [agentDetail]);

  const chatEnabled = agentDetail?.AgentCommunication?.chatEnabled ?? '';

  return useMemo(
    () => ({
      website,
      avatarSrc,
      firstName,
      lastName,
      fullName,
      primaryPhone,
      chatEnabled,
    }),
    [
      avatarSrc,
      chatEnabled,
      firstName,
      fullName,
      lastName,
      primaryPhone,
      website,
    ],
  );
};
