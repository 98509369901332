import React, { FC, useMemo } from 'react';

import {
  ListingDetails,
  PriceScale,
  PropTypeFilter,
  TAdditionalFilter,
  TRoomPrediction,
  TRoomPredictionType,
} from '../../../types';
import { usePrices, usePriceScale } from '../hooks/usePrices';
import { useSearchListingsMeta } from './SearchListingsMetaProvider';
import * as PropType from '../components/FiltersDrawer/PropertyType';
import { propertyTypeCodes } from '../../../constants/propertyTypeCodes';
import { useSearchFilteredListings } from './SearchFilteredListingsProvider';
import * as PriceRangeFilter from '../components/FiltersDrawer/PriceRangeFilter';
import * as MinimumBedrooms from '../components/FiltersDrawer/MinimumRoomsFilter/MinimumBedrooms';
import * as MinimumBathrooms from '../components/FiltersDrawer/MinimumRoomsFilter/MinimumBathrooms';
import { getAdditionalFiltersIndicators } from '../components/FiltersDrawer/AdditionalFilters/filter';

interface PriceRangePrediction {
  diff: number;
  percentage: number;
}

type AdditionalFiltersPredictions = Record<TAdditionalFilter, number>;

interface Predictions {
  bathrooms: TRoomPrediction;
  bedrooms: TRoomPrediction;
  priceRange: PriceRangePrediction;
  propType: Record<PropTypeFilter, number>;
  rangeAgnosticPriceScale: PriceScale;
  additionalFilters: AdditionalFiltersPredictions;
}

const FilterPredictions = React.createContext<Predictions | undefined>(
  undefined,
);

export const FilterPredictionsProvider: FC = ({ children }) => {
  const { percentage, length } = useSearchListingsMeta();
  const { filterWithOverrideFilter, listings } = useSearchFilteredListings();

  const bathrooms = useMemo(
    function bathroomsMemo() {
      const predictionsLess =
        filterWithOverrideFilter({
          [MinimumBathrooms.UUID]: MinimumBathrooms.bathroomPredictionFilter,
        })?.length ?? 0;

      const numOfLess = predictionsLess - (listings?.length ?? 0);

      if (numOfLess <= 0) {
        const predictionsMore =
          filterWithOverrideFilter({
            [MinimumBathrooms.UUID]:
              MinimumBathrooms.bathroomPredictionMoreFilter,
          })?.length ?? 0;
        const numOfMore = predictionsMore - (listings?.length ?? 0);
        return {
          predictionType: 'more' as TRoomPredictionType,
          num: numOfMore < 0 ? 0 : numOfMore,
        };
      }

      return {
        predictionType: 'less' as TRoomPredictionType,
        num: numOfLess < 0 ? 0 : numOfLess,
      };
    },
    [filterWithOverrideFilter, listings?.length],
  );

  const bedrooms = useMemo(() => {
    const predictionsLess =
      filterWithOverrideFilter({
        [MinimumBedrooms.UUID]: MinimumBedrooms.bedroomPredictionFilter,
      })?.length ?? 0;

    const numOfLess = predictionsLess - (listings?.length ?? 0);

    if (numOfLess <= 0) {
      const predictionsMore =
        filterWithOverrideFilter({
          [MinimumBedrooms.UUID]: MinimumBedrooms.bedroomPredictionMoreFilter,
        })?.length ?? 0;

      const numOfMore = predictionsMore - (listings?.length ?? 0);

      return {
        predictionType: 'more' as TRoomPredictionType,
        num: numOfMore < 0 ? 0 : numOfMore,
      };
    }

    return {
      predictionType: 'less' as TRoomPredictionType,
      num: numOfLess < 0 ? 0 : numOfLess,
    };
  }, [filterWithOverrideFilter, listings?.length]);

  const priceRange = useMemo(() => {
    const predictions =
      filterWithOverrideFilter({
        [PriceRangeFilter.UUID]: PriceRangeFilter.filterWithPredictiveMax,
      })?.length ?? 0;

    const diff = predictions - (length ?? 0);

    return {
      diff,
      percentage,
    };
  }, [filterWithOverrideFilter, length, percentage]);

  const propType = useMemo(() => {
    const getListingsCount = (
      listings: ListingDetails[],
      type: PropTypeFilter,
    ): number => {
      return listings.filter(
        (listing) =>
          typeof listing?.propTypeId !== 'undefined' &&
          propertyTypeCodes[type].includes(listing.propTypeId),
      ).length;
    };

    const listings =
      filterWithOverrideFilter({
        [PropType.UUID]: () => true,
      }) ?? [];

    return {
      [PropTypeFilter.SingleFamily]: getListingsCount(
        listings,
        PropTypeFilter.SingleFamily,
      ),
      [PropTypeFilter.Condo]: getListingsCount(listings, PropTypeFilter.Condo),
      [PropTypeFilter.MultiFamily]: getListingsCount(
        listings,
        PropTypeFilter.MultiFamily,
      ),
      [PropTypeFilter.Land]: getListingsCount(listings, PropTypeFilter.Land),
      [PropTypeFilter.Commercial]: getListingsCount(
        listings,
        PropTypeFilter.Commercial,
      ),
    };
  }, [filterWithOverrideFilter]);

  const rangeAgnosticPriceScale = useRangeAgnosticPriceScale();

  const additionalFilters = useMemo(() => {
    const filters: Record<TAdditionalFilter, number> = {
      'New Construction Only': 0,
      'No HOA Fees': 0,
      View: 0,
      Pool: 0,
      Waterfront: 0,
      Furnished: 0,
      Basement: 0,
      'Golf Course': 0,
    };

    listings.forEach((listing) => {
      const features = getAdditionalFiltersIndicators(listing);

      for (const filterKey in filters) {
        if (!features[filterKey as TAdditionalFilter]) continue;

        filters[filterKey as TAdditionalFilter]++;
      }
    });

    return filters;
  }, [listings]);

  const value = useMemo(
    () => ({
      bathrooms,
      bedrooms,
      priceRange,
      propType,
      rangeAgnosticPriceScale,
      additionalFilters,
    }),
    [
      additionalFilters,
      bathrooms,
      bedrooms,
      priceRange,
      propType,
      rangeAgnosticPriceScale,
    ],
  );

  return (
    <FilterPredictions.Provider value={value}>
      {children}
    </FilterPredictions.Provider>
  );
};

export function useFilterPredictions(): Predictions {
  const context = React.useContext(FilterPredictions);

  if (context === undefined) {
    throw new Error(
      'useFilterPredictions cannot be used outside FilterPredictionsProvider!',
    );
  }

  return context;
}

function useRangeAgnosticPriceScale() {
  const { filterWithOverrideFilter } = useSearchFilteredListings();

  const rangeAgnosticListings = useMemo(
    () =>
      filterWithOverrideFilter({
        [PriceRangeFilter.UUID]: PriceRangeFilter.filterWithoutRange,
      }) ?? [],
    [filterWithOverrideFilter],
  );

  const rangeAgnosticPrices = usePrices(rangeAgnosticListings);

  return usePriceScale(rangeAgnosticPrices);
}
