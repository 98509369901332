import qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';
import React, { FC, useCallback, useMemo } from 'react';

type TParamsData = {
  param: string;
  value: any; //eslint-disable-line
};
interface SearchParamsContextStruct {
  getParams(): qs.ParsedQs;
  params: qs.ParsedQs;
  get: (param: string) => any; // eslint-disable-line
  set: (param: string, value: any) => void; // eslint-disable-line
  setMultiple: (paramsData: TParamsData[]) => void;
}

const SearchParamsContext = React.createContext<
  SearchParamsContextStruct | undefined
>(undefined);

const getParams = () =>
  qs.parse(new URLSearchParams(window.location.search).toString());

const get = (param: string) => getParams()[param];

export const SearchParamsProvider: FC = ({ children }) => {
  const history = useHistory();
  const location = useLocation();

  const set = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (param: string, value: any) => {
      const params = getParams();

      if (!value) {
        delete params?.[param];
        return history.push({
          search: qs.stringify(params),
        });
      }

      const newParamRecord = { [param]: value };
      history.push({
        search: qs.stringify(
          !params ? newParamRecord : { ...params, ...newParamRecord },
        ),
      });
    },
    [history],
  );

  const setMultiple = useCallback(
    (multipleParams: TParamsData[]) => {
      let currentParams = getParams();

      multipleParams.forEach(({ param, value }) => {
        if (!value) {
          delete currentParams?.[param];
          return;
        }

        const newParamRecord = { [param]: value };
        currentParams = !currentParams
          ? newParamRecord
          : { ...currentParams, ...newParamRecord };
      });

      history.push({
        search: qs.stringify(currentParams),
      });
    },
    [history],
  );

  const value = useMemo(
    () => ({
      set,
      get,
      getParams,
      params: getParams(),
      setMultiple,
    }),
    [set, setMultiple, location], // eslint-disable-line
  );

  return (
    <SearchParamsContext.Provider value={value}>
      {children}
    </SearchParamsContext.Provider>
  );
};

export const useSearchParams = (): SearchParamsContextStruct => {
  const context = React.useContext(SearchParamsContext);

  if (context === undefined) {
    throw new Error(
      'useSearchParams cannot be used outside SearchParamsProvider!',
    );
  }

  return context;
};
