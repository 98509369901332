import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Icon = styled(FontAwesomeIcon)`
  width: auto !important;
  outline: none;
`;

const Pill = styled.span`
  /* top: -6px;*/
  right: -4px;
  font-size: 1.75rem;
`;

export default {
  Icon,
  Pill,
};
