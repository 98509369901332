import React, { useRef, CSSProperties } from 'react';

import { Icon, IconType } from '../../icon';
import {
  ArrowWrapper,
  ContentWrapper,
  DropdownButton,
  DropdownButtonContent,
  DropdownMenu,
} from './Dropdown.style';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

interface DropdownProps {
  content: React.ReactNode;
  isOpen: boolean;
  toggleOpen: () => void;
  dropdownButtonStyle?: CSSProperties;
  isRelativePosition?: boolean;
  isDisabled?: boolean;
  dataQa?: string;
  iconType?: IconDefinition;
  iconColor?: string;
  hideArrow?: boolean;
  className?: string;
}

export const Dropdown: React.FC<DropdownProps> = ({
  content,
  isOpen,
  toggleOpen,
  children,
  dataQa = 'dropdown',
  dropdownButtonStyle = {},
  isRelativePosition = false,
  isDisabled = false,
  iconType,
  iconColor,
  hideArrow,
  className,
}) => {
  const menuRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    if (isDisabled) return;
    toggleOpen();
  };

  return (
    <DropdownButton
      data-qa={dataQa}
      className={className}
      isDisabled={isDisabled}
      isOpen={isOpen && !!children}
    >
      <DropdownButtonContent
        onClick={handleToggle}
        isOpen={isOpen && !!children}
        isDisabled={isDisabled}
        style={dropdownButtonStyle}
      >
        <ContentWrapper>{content}</ContentWrapper>
        {!hideArrow && (
          <ArrowWrapper isOpen={isOpen} customColor={iconColor}>
            <Icon type={iconType || IconType.chevronDown} />
          </ArrowWrapper>
        )}
      </DropdownButtonContent>
      {children && (
        <DropdownMenu isOpen={isOpen} isRelativePosition={isRelativePosition}>
          <div ref={menuRef}>{children}</div>
        </DropdownMenu>
      )}
    </DropdownButton>
  );
};
