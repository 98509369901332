import * as yup from 'yup';
import styled from '@emotion/styled';
import { useMemo, useState } from 'react';
import { Formik, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';

import InputField from './InputField';
import { Button } from '../../../../../UI/Button';
import PasswordRulesInfo from './PasswordRulesInfo';
import { AccordionPanel, ErrorMsg } from '../../../SideMenu.style';
import { toastActions } from '../../../../ToastComponent/ToastComponent';
import { useUpdatePassword } from '../../../../../../providers/Authentication/auth';

export const passwordRegex =
  /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;

interface FormValues {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

type Props = {
  show: boolean;
  emailAddress: string | undefined;
  setChildPanelOpenInfo: (childName: string) => void;
};

const initialValues = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};

function UpdatePasswordForm({
  show,
  setChildPanelOpenInfo,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const updatePassword = useUpdatePassword();

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      currentPassword: yup
        .string()
        .required(t('common.required'))
        .matches(passwordRegex, t('auth.formErrors.passwordMustMatchCriteria')),
      newPassword: yup
        .string()
        .required(t('common.required'))
        .matches(passwordRegex, t('auth.formErrors.passwordMustMatchCriteria')),
      confirmPassword: yup
        .string()
        .required(t('common.required'))
        .oneOf(
          [yup.ref('newPassword'), ''],
          t('auth.formErrors.passwordsMustMatch'),
        ),
    });
  }, [t]);

  const onSubmit = async (
    values: FormValues,
    { resetForm, setSubmitting }: FormikValues,
  ) => {
    const { currentPassword, newPassword, confirmPassword } = values;
    setErrorMessage('');
    try {
      setIsLoading(true);
      setSubmitting(false);

      await updatePassword.mutateAsync({
        currentPassword,
        password: newPassword,
        passwordConfirmation: confirmPassword,
      });

      toastActions.success('Password successfully changed');
      resetForm();
      setChildPanelOpenInfo('');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const message = err?.message;
      setErrorMessage(message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {(props) => {
        const { values, handleSubmit, handleChange, handleBlur, errors } =
          props;

        return (
          <form onSubmit={handleSubmit}>
            <AccordionPanel show={show}>
              <InputField
                className="sentry-mask"
                placeholder={t(
                  'sideMenu.new.myProfile.form.enterCurrentPassword',
                )}
                type={'password'}
                hasMt
                handleChange={handleChange}
                name="currentPassword"
                handleBlur={handleBlur}
                value={values.currentPassword}
                error={errors.currentPassword}
              />
              <PasswordRulesInfo />

              <InputField
                className="sentry-mask"
                placeholder={t('sideMenu.new.myProfile.form.enterNewPassword')}
                type={'password'}
                handleChange={handleChange}
                name="newPassword"
                handleBlur={handleBlur}
                value={values.newPassword}
                error={errors.newPassword}
              />
              <InputField
                className="sentry-mask"
                hasMt
                placeholder={t('sideMenu.new.myProfile.form.confirmPassword')}
                type={'password'}
                handleChange={handleChange}
                name="confirmPassword"
                handleBlur={handleBlur}
                value={values.confirmPassword}
                error={errors.confirmPassword}
              />
            </AccordionPanel>
            {errorMessage && <ErrorMsg hasMt>{errorMessage}</ErrorMsg>}
            <ButtonContainer>
              <Button
                size="small"
                shape="square"
                disabled={isLoading}
                type="submit"
              >
                {t('auth.submit')}
              </Button>
            </ButtonContainer>
          </form>
        );
      }}
    </Formik>
  );
}

const ButtonContainer = styled.div`
  width: fit-content;
  margin-left: auto;
  margin-top: 1rem;
`;
export default UpdatePasswordForm;
