import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { useTheme } from '@emotion/react';
import { useAgentDetails } from '../../../hooks/query/useAgentDetails';
import { useAnalytics } from '../../../features/analytics/useAnalytics';
import { svgStringToDataUrl } from '../../../services/svgStringToDataUrl';

import Dialog from '../../UI/Dialog/Dialog';
import S from './AskQuestionModal.styles';
import Icon from './AskAQuestionIcon';
import { ConversationTypes } from '../../../features/analytics';

import api from '../../../api';
import { apiRoutes } from '../../../constants';

interface Props {
  defaultValue?: string;
  isOpen?: boolean;
  mlsId?: number | null;
  onClose: () => void;
}

export const AskQuestionModal: React.FC<Props> = ({
  defaultValue = '',
  isOpen,
  mlsId,
  onClose,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { data: agent } = useAgentDetails();
  const { trackConversation } = useAnalytics();

  const [value, setValue] = useState(defaultValue);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const brandedIcon = svgStringToDataUrl(
    Icon,
    theme.colors.branding.main,
    '#1E4E8C',
  );

  const sendMessage = async () => {
    setLoading(true);
    setError(false);

    trackConversation(ConversationTypes.AskQuestion);
    try {
      const payload: { question: string; mlsId?: number } = { question: value };
      if (mlsId) payload.mlsId = mlsId;
      await api.post(apiRoutes.homeowner.askQuestion(), payload);
      onClose();
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };
  return (
    <ModifiedDialog open={!!isOpen} onClose={onClose}>
      <S.Content>
        <IconWrapper>
          <img src={brandedIcon} />
        </IconWrapper>
        <S.Title>{t('askQuestionModal.title')}</S.Title>
        <S.Text>{t('askQuestionModal.text')}</S.Text>
        <S.TextArea rows={5} onChange={(e) => setValue(e.target.value)}>
          {value}
        </S.TextArea>
        {error && (
          <S.Text style={{ color: 'red', marginTop: -12, marginBottom: 12 }}>
            {t('errors.somethingWentWrong')}
          </S.Text>
        )}
        <S.StyledButton
          fullWidth
          onClick={sendMessage}
          disabled={loading}
          style={{}}
        >
          {t('askQuestionModal.cta')}
        </S.StyledButton>

        <S.Policy>
          We keep your info safe & never share it. View our{' '}
          <S.Link
            target="_blank"
            href={`${agent?.Links?.companyWebsite}/terms.php`}
          >
            privacy policy
          </S.Link>{' '}
          or{' '}
          <S.Link
            target="_blank"
            href={`${agent?.Links?.companyWebsite}/privacy.php`}
          >
            terms of use
          </S.Link>
          .
        </S.Policy>
      </S.Content>
      ;
    </ModifiedDialog>
  );
};

const ModifiedDialog = styled(Dialog)`
  .MuiPaper-root[role='dialog'] {
    border-radius: 1rem;

    & > div {
      padding: 0;
    }

    @media all and (max-width: 425px) {
      margin: 0;
    }
  }
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & img {
    width: 88px;
    height: 88px;
  }
`;
