import { useTranslation } from 'react-i18next';
import React, { useCallback, useMemo } from 'react';

import S from './FavoriteIcon.style';
import { ListingDetails } from '../../../types';
import Icon, { IconType } from '../../icon/icon';
import { toastActions } from '../ToastComponent/ToastComponent';

import { useListingHelper } from '../../../utils/useListingHelper';
import { ConversationTypes, useAnalytics } from '../../../features/analytics';
import { useSaveListing } from '../../../hooks/query/saveListing/useSaveListing';
import { useUnsaveListing } from '../../../hooks/query/saveListing/useUnsaveListing';
import { useSavedListingsRaw } from '../../../hooks/query/saveListing/useSavedListingsRaw';

interface Props {
  dataQa?: string;
  label?: string;
  noLabel?: boolean;
  blackView?: boolean;
  data: ListingDetails | null;
  setViewedSessionActive?: () => void;
}

export const FavoriteIcon: React.FC<Props> = ({
  dataQa,
  data,
  label,
  noLabel,
  blackView,
  setViewedSessionActive,
}) => {
  const { t } = useTranslation();

  const { trackConversation } = useAnalytics();

  const { isListingFavorite, isLoading: isLoadingSavedListings } =
    useListingHelper();

  const {
    isRefetching,
    isLoading,
    data: savedListingsRaw,
  } = useSavedListingsRaw();

  const { mutate: onUpdateFavoriteUnsaved } = useUnsaveListing();

  const { mutate: onUpdateFavoriteSaved } = useSaveListing();

  const depsString = savedListingsRaw
    ?.map(({ mlsId, mlsListingId }) => `${mlsId}-${mlsListingId}`)
    .join('');

  const isFavorite = useMemo(
    () => isListingFavorite(data),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, isListingFavorite, depsString],
  );

  const saveFavoriteListing = useCallback(async () => {
    if (!isFavorite) trackConversation(ConversationTypes.FavoritedListing);

    if (isLoading || isRefetching) return;

    const updateFavorite = isFavorite
      ? onUpdateFavoriteUnsaved
      : onUpdateFavoriteSaved;

    const payload = {
      listingDetail: data,
    };

    updateFavorite(payload, {
      onError: () => {
        const errorMsg = isFavorite ? 'unsaveListingError' : 'saveListingError';

        toastActions.error(
          t(`listingDetails.${errorMsg}`, {
            address: data?.Address.addressLine1,
          }),
        );
      },
    });
  }, [
    t,
    data,
    isLoading,
    isFavorite,
    trackConversation,
    isRefetching,
    onUpdateFavoriteUnsaved,
    onUpdateFavoriteSaved,
  ]);

  const onClickCb = useCallback(
    function (e) {
      setViewedSessionActive && setViewedSessionActive();
      e.preventDefault();
      e.stopPropagation();
      saveFavoriteListing();
    },
    [saveFavoriteListing, setViewedSessionActive],
  );

  const labelText = label || t('favoriteProperty.save');

  const isDisabled = isLoadingSavedListings || isRefetching;

  return (
    <S.IconEl
      blackView={blackView}
      onClick={onClickCb}
      disabled={isLoadingSavedListings}
    >
      <Icon
        dataQa={`${dataQa}-favorite-icon-${isFavorite ? 'active' : ''}`}
        disabled={isDisabled}
        type={isFavorite ? IconType.Heart : IconType.HeartOutlined}
      />
      {noLabel ? null : <S.IconLabel>{labelText}</S.IconLabel>}
    </S.IconEl>
  );
};
