export default `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="66.358" height="82.233" viewBox="0 0 66.358 82.233">
  <defs>
    <filter id="Path_2089" x="0" y="0" width="66.358" height="82.233" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
  </defs>
  <g id="Group_6943" data-name="Group 6943" transform="translate(-748.437 -402.861)">
    <g id="Group_6893" data-name="Group 6893" transform="translate(749.015 329.558)">
      <g id="Group_6824" data-name="Group 6824" transform="translate(0.191 11.246)">
        <g id="Group_6703" data-name="Group 6703" transform="translate(0 0)">
          <g transform="matrix(1, 0, 0, 1, -0.77, 62.06)" filter="url(#Path_2089)">
            <path id="Path_2089-2" data-name="Path 2089" d="M24.185,64.238c-.586-.751-12.424-13.457-16.83-21A72.169,72.169,0,0,1,1.417,31,20.753,20.753,0,0,1,0,23.615a24.183,24.183,0,0,1,47.124-7.056,21.788,21.788,0,0,1,1.163,8.814,24.8,24.8,0,0,1-1.9,7.1,90.388,90.388,0,0,1-7.541,14.343C34.5,53.8,24.36,64.021,24.185,64.238M24.154,38.1A13.917,13.917,0,1,0,10.26,24.15,13.93,13.93,0,0,0,24.154,38.1" transform="translate(9 5.99)" fill="#1F4D8C"/>
          </g>
          <path id="Path_2114" data-name="Path 2114" d="M127.35,144.263a16.819,16.819,0,1,1,16.857-16.8,16.837,16.837,0,0,1-16.857,16.8" transform="translate(-94.919 -35.113)" fill="#fff"/>
        </g>
      </g>
    </g>
  </g>
</svg>`;
