import React from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

import {
  ContentWrapper,
  IconWrapper,
  SelectedValue,
  Label,
} from './ContentItem.style';
import { Icon } from '../../../icon';

interface ContentItemProps {
  dataQa?: string;
  label: string;
  icon?: IconDefinition | JSX.Element;
  IconEl?: React.ReactNode;
  selectedValue?: {
    isBold?: boolean;
    isOneLine?: boolean;
    isBrandingColor?: boolean;
    label: string | React.ReactNode | Element;
  };
  labelStyle?: React.CSSProperties;
  isDisabled?: boolean;
  className?: string;
}

export const ContentItem: React.FC<ContentItemProps> = ({
  label,
  icon,
  selectedValue,
  IconEl,
  className,
  isDisabled,
  labelStyle,
  dataQa,
}) => (
  <ContentWrapper
    key={label}
    className={className}
    isDisabled={isDisabled}
    data-qa={dataQa}
  >
    {icon && (
      <IconWrapper>
        <Icon type={icon} />
      </IconWrapper>
    )}
    {IconEl && !icon && <IconWrapper>{IconEl}</IconWrapper>}
    {label ? (
      <Label className="sort-label">{label}</Label>
    ) : (
      <span className="sort-label" style={{ height: '1.2rem' }}></span>
    )}
    {selectedValue && (
      <SelectedValue
        style={labelStyle}
        className="sort-value"
        isBold={selectedValue.isBold}
        isOneLine={selectedValue.isOneLine}
        isBrandingColor={selectedValue.isBrandingColor}
      >
        {selectedValue.label}
      </SelectedValue>
    )}
  </ContentWrapper>
);
