import React from 'react';
import { useTranslation } from 'react-i18next';

import { AgentDetails } from '../components/AgentDetails/AgentDetails';
import { DownloadApp } from './DownloadApp/DownloadApp';
import { PolicyLinks } from '../components/PolicyLinks/PolicyLinks';
import { Header } from './Header/Header';
import { MobileImages } from '../components/MobileImages/MobileImages';

import S from './AppDeeplinkMobileView.style';

export const AppDeeplinkMobileView: React.FC = () => {
  const { t } = useTranslation();
  return (
    <S.Page>
      <Header />
      <S.Main>
        <S.ImagesContainer>
          <MobileImages />
        </S.ImagesContainer>
        <S.Description> {t('mobileAppDeepLink.description')}</S.Description>
        <AgentDetails />
        <DownloadApp />
      </S.Main>
      <PolicyLinks />
    </S.Page>
  );
};
