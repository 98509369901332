import styled from '@emotion/styled';

const Scales = styled.div`
  color: ${(props) => props.theme.colors.grey.dark2};
  margin-top: 18px;
  display: flex;
  justify-content: space-between;

  & > span:first-of-type {
    margin-left: 1rem;
  }
`;

export default {
  Scales,
};
