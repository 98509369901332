import { InfiniteData, useMutation } from 'react-query';

import {
  useInvalidateNotificationsCount,
  useNotificationsCountOptimistic,
} from './useNotificationsCount';
import { hopApi } from '../../../api/hop';
import { apiRoutes } from '../../../constants/apiRoutes';
import { NotificationsCount, NotificationsWithMeta } from '../types';
import { useAllNotificationsOptimistic } from './useAllNotifications';
import { useUnreadNotificationsOptimistic } from './useUnreadNotifications';
import { toastActions } from '../../../components/V2/ToastComponent/ToastComponent';

function updateAll(
  prev: InfiniteData<NotificationsWithMeta>,
): InfiniteData<NotificationsWithMeta> {
  const updatePages = prev?.pages.map((page) => ({
    ...page,
    data: page.data.map((notification) => ({
      ...notification,
      isRead: true,
    })),
  }));

  return {
    ...prev,
    pages: updatePages,
  };
}

function updateUnread(
  prev: InfiniteData<NotificationsWithMeta>,
): InfiniteData<NotificationsWithMeta> {
  return {
    ...prev,
    pages: [{ data: [], links: [], meta: prev?.pages?.[0]?.meta }],
  };
}

function updateCount(prev?: NotificationsCount): NotificationsCount {
  return {
    total: prev?.total || 0,
    unread: 0,
  };
}

async function markAllAsRead(): Promise<void> {
  await hopApi.post(apiRoutes.notifications.markAllAsRead);
}

export function useMarkAllAsRead() {
  const invalidateNotificationsCount = useInvalidateNotificationsCount();

  const allNotificationsOptimistc = useAllNotificationsOptimistic();
  const notificationsCountOptimistic = useNotificationsCountOptimistic();
  const unreadNotificationsOptimistic = useUnreadNotificationsOptimistic();

  return useMutation(markAllAsRead, {
    onMutate: async () => {
      const prevCount = notificationsCountOptimistic.update(updateCount);
      const prevAllNotifications = allNotificationsOptimistc.update(updateAll);
      const prevUnreadNotifications =
        unreadNotificationsOptimistic.update(updateUnread);

      return { prevAllNotifications, prevUnreadNotifications, prevCount };
    },
    onError: (err: Error, _new, context) => {
      allNotificationsOptimistc.revert(
        context?.prevAllNotifications as InfiniteData<NotificationsWithMeta>,
      );

      unreadNotificationsOptimistic.revert(
        context?.prevUnreadNotifications as InfiniteData<NotificationsWithMeta>,
      );

      notificationsCountOptimistic.revert(context?.prevCount);

      toastActions.error(err.message);
    },
    onSuccess: () => {
      invalidateNotificationsCount();
    },
  });
}
