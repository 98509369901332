import { useTheme } from '@emotion/react';

export function TransactionCompleteIcon(): JSX.Element {
  const theme = useTheme();
  const brandingColor = theme.colors.branding.main;

  return (
    <svg
      width="55"
      height="55"
      fill="none"
      viewBox="0 0 55 55"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="transaction">
        <rect width="55" height="55" rx="3" fill="white" />
        <g id="varying rings">
          <g id="home rings">
            <g id="rings">
              <g id="Group 12917">
                <g id="Path 8122">
                  <path
                    fill="white"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.2308 10.6529C25.2247 10.6529 23.3047 11.4156 21.8246 12.8002L11.9851 22.0032C9.71762 24.1229 8.97074 27.3855 10.0819 30.3151C10.3279 30.9631 10.6581 31.5728 11.0634 32.1265L11.4199 32.6132V36.3913C11.4199 40.7786 14.9085 44.3478 19.1966 44.3478H35.2132C39.5014 44.3478 42.9901 40.7786 42.9901 36.3913V32.7519L43.3784 32.253C43.8324 31.6691 44.2005 31.0183 44.4725 30.3185C45.604 27.4092 44.8971 24.139 42.6721 21.9872C42.6248 21.9416 42.5768 21.8965 42.5292 21.8528L32.5825 12.7496C31.1085 11.397 29.2085 10.6529 27.2308 10.6529H27.2308ZM35.2127 48.0001H19.1961C12.9395 48.0001 7.84933 42.7922 7.84933 36.3911V33.7996C7.40962 33.1226 7.04199 32.3973 6.75328 31.6363C5.10493 27.2905 6.21296 22.451 9.57602 19.3067L19.4151 10.1041C21.5547 8.10271 24.3303 7.00008 27.2303 7.00008C30.089 7.00008 32.8355 8.07578 34.964 10.0285L44.909 19.1304C44.981 19.1964 45.053 19.264 45.1239 19.3327C48.4182 22.5182 49.4643 27.3605 47.7892 31.6684C47.4683 32.4942 47.0558 33.2747 46.5597 33.9963V36.3911C46.5597 42.7923 41.4694 48.0001 35.2127 48.0001Z"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill={brandingColor}
                    d="M27.2308 10.6529C25.2247 10.6529 23.3047 11.4156 21.8246 12.8002L11.9851 22.0032C9.71762 24.1229 8.97074 27.3855 10.0819 30.3151C10.3279 30.9631 10.6581 31.5728 11.0634 32.1265L11.4199 32.6132V36.3913C11.4199 40.7786 14.9085 44.3478 19.1966 44.3478H35.2132C39.5014 44.3478 42.9901 40.7786 42.9901 36.3913V32.7519L43.3784 32.253C43.8324 31.6691 44.2005 31.0183 44.4725 30.3185C45.604 27.4092 44.8971 24.139 42.6721 21.9872C42.6248 21.9416 42.5768 21.8965 42.5292 21.8528L32.5825 12.7496C31.1085 11.397 29.2085 10.6529 27.2308 10.6529H27.2308ZM35.2127 48.0001H19.1961C12.9395 48.0001 7.84933 42.7922 7.84933 36.3911V33.7996C7.40962 33.1226 7.04199 32.3973 6.75328 31.6363C5.10493 27.2905 6.21296 22.451 9.57602 19.3067L19.4151 10.1041C21.5547 8.10271 24.3303 7.00008 27.2303 7.00008C30.089 7.00008 32.8355 8.07578 34.964 10.0285L44.909 19.1304C44.981 19.1964 45.053 19.264 45.1239 19.3327C48.4182 22.5182 49.4643 27.3605 47.7892 31.6684C47.4683 32.4942 47.0558 33.2747 46.5597 33.9963V36.3911C46.5597 42.7923 41.4694 48.0001 35.2127 48.0001Z"
                  />
                </g>
                <g id="Path 8123">
                  <path
                    fill="white"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.2314 15.2718C26.344 15.2718 25.4946 15.6089 24.8399 16.2215L15.0156 25.4102C14.1196 26.2478 13.8246 27.5368 14.2634 28.6941C14.4595 29.211 14.785 29.6587 15.2049 29.9887L15.9012 30.5362V36.4101C15.9012 38.2767 17.3856 39.7953 19.21 39.7953H35.2007C37.0253 39.7953 38.5096 38.2767 38.5096 36.4101V30.5659L39.2644 30.021C39.7306 29.6845 40.0904 29.2144 40.3052 28.662C40.7561 27.503 40.4747 26.2004 39.5886 25.3436C39.5714 25.3268 39.5535 25.3098 39.535 25.293L29.5997 16.2001C28.9465 15.6008 28.1061 15.2718 27.2314 15.2718L27.2314 15.2718ZM35.2003 43.4423H19.2096C15.4197 43.4423 12.3364 40.2876 12.3364 36.4103V32.2456C11.734 31.6101 11.2581 30.8519 10.9404 30.0137C9.96497 27.4424 10.6207 24.579 12.6106 22.7186L22.4345 13.5303C23.7477 12.3019 25.451 11.6252 27.231 11.6252C28.9855 11.6252 30.6711 12.2853 31.9773 13.4838L41.9106 22.5749C41.9525 22.6135 41.9944 22.6529 42.0359 22.693C43.9903 24.5828 44.6104 27.4547 43.6167 30.0102C43.2682 30.9069 42.7422 31.7064 42.0736 32.3635V36.4103C42.0736 40.2876 38.9902 43.4423 35.2003 43.4423H35.2003Z"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill={brandingColor}
                    d="M27.2314 15.2718C26.344 15.2718 25.4946 15.6089 24.8399 16.2215L15.0156 25.4102C14.1196 26.2478 13.8246 27.5368 14.2634 28.6941C14.4595 29.211 14.785 29.6587 15.2049 29.9887L15.9012 30.5362V36.4101C15.9012 38.2767 17.3856 39.7953 19.21 39.7953H35.2007C37.0253 39.7953 38.5096 38.2767 38.5096 36.4101V30.5659L39.2644 30.021C39.7306 29.6845 40.0904 29.2144 40.3052 28.662C40.7561 27.503 40.4747 26.2004 39.5886 25.3436C39.5714 25.3268 39.5535 25.3098 39.535 25.293L29.5997 16.2001C28.9465 15.6008 28.1061 15.2718 27.2314 15.2718L27.2314 15.2718ZM35.2003 43.4423H19.2096C15.4197 43.4423 12.3364 40.2876 12.3364 36.4103V32.2456C11.734 31.6101 11.2581 30.8519 10.9404 30.0137C9.96497 27.4424 10.6207 24.579 12.6106 22.7186L22.4345 13.5303C23.7477 12.3019 25.451 11.6252 27.231 11.6252C28.9855 11.6252 30.6711 12.2853 31.9773 13.4838L41.9106 22.5749C41.9525 22.6135 41.9944 22.6529 42.0359 22.693C43.9903 24.5828 44.6104 27.4547 43.6167 30.0102C43.2682 30.9069 42.7422 31.7064 42.0736 32.3635V36.4103C42.0736 40.2876 38.9902 43.4423 35.2003 43.4423H35.2003Z"
                  />
                </g>
                <g id="Path 8124">
                  <path
                    fill="white"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.3563 35.2363H34.0543V28.256C34.0543 27.7205 34.2385 27.2282 34.5456 26.8419L27.2378 20.1538L19.9257 26.9929C20.2023 27.388 20.3563 27.8683 20.3563 28.3579V35.2363ZM35.2002 38.8833H19.2095C17.8763 38.8833 16.7917 37.7736 16.7917 36.4096V29.6786C16.0363 29.6078 15.3871 29.1172 15.1006 28.3817C14.7853 27.5729 14.9877 26.6707 15.6156 26.0831L25.4406 16.894C26.4513 15.9484 27.9828 15.9409 29.0024 16.8767L38.9583 25.9884L38.976 26.0056C39.5953 26.6043 39.7917 27.5145 39.477 28.3246C39.1697 29.1148 38.4445 29.6321 37.618 29.6571V36.4096C37.618 37.7736 36.5336 38.8833 35.2002 38.8833V38.8833Z"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill={brandingColor}
                    d="M20.3563 35.2363H34.0543V28.256C34.0543 27.7205 34.2385 27.2282 34.5456 26.8419L27.2378 20.1538L19.9257 26.9929C20.2023 27.388 20.3563 27.8683 20.3563 28.3579V35.2363ZM35.2002 38.8833H19.2095C17.8763 38.8833 16.7917 37.7736 16.7917 36.4096V29.6786C16.0363 29.6078 15.3871 29.1172 15.1006 28.3817C14.7853 27.5729 14.9877 26.6707 15.6156 26.0831L25.4406 16.894C26.4513 15.9484 27.9828 15.9409 29.0024 16.8767L38.9583 25.9884L38.976 26.0056C39.5953 26.6043 39.7917 27.5145 39.477 28.3246C39.1697 29.1148 38.4445 29.6321 37.618 29.6571V36.4096C37.618 37.7736 36.5336 38.8833 35.2002 38.8833V38.8833Z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
