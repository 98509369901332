import { useRecoilState } from 'recoil';
import { useMemo, useRef, useCallback } from 'react';

import {
  Nav,
  Navbar,
  IconWrap,
  Container,
  ActionIconWrap,
} from './MobileFooter.style';
import appNavAtom from '../../../recoil/appNav';
import { IconNavLink } from '../components/IconNavLink';
import { routerPaths, routes } from '../../../constants';
import { Icon, IconType } from '../../../components/icon';
import { HomeMobileNavIcon } from '../icons/HomeMobileNavIcon';
import { useIsMobileView } from '../../../hooks/useIsMobileView';
import sideMenuAtom from '../../../recoil/sideMenu/sideMenuAtom';
import { usePreloadNotifications } from '../../NotificationPanel';
import { useMyHomeNavItem } from '../../../utils/useMyHomeNavItem';
import { SearchMobileNavIcon } from '../icons/SearchMobileNavIcon';
import { MainOrigin, SubOrigin, useAnalytics } from '../../analytics';
import { FavoritesMobileNavIcon } from '../icons/FavoritesMobileNavIcon';
import { SubNavigation } from '../components/SubNavigation/SubNavigation';
import { FEATURES, useHasFeatureFlag } from '../../../hooks/useFeatureFlags';
import useDefaultSearchArea from '../../../hooks/query/useDefaultSearchArea';
import { NotificationMobileNavIcon } from '../icons/NotificationMobileNavIcon';
import { useSavedListings } from '../../../hooks/query/saveListing/useSavedListings';
import { getSearchResultsRouteParams } from '../../../utils/getSearchResultsRouteParams';

export function MobileFooterNav(): JSX.Element | null {
  usePreloadNotifications();
  const isMobile = useIsMobileView();
  const myHomeNavProps = useMyHomeNavItem();
  const { trackPageSwitch } = useAnalytics();
  const { data: savedListings } = useSavedListings();
  const hasNotificationsFlag = useHasFeatureFlag(
    FEATURES.HopWebNotificationsPanel,
  );

  const [isOpen, setIsOpen] = useRecoilState(sideMenuAtom);

  const [isSubNavHidden, setIsSubNavHidden] = useRecoilState(appNavAtom);

  const footerRef = useRef<HTMLElement>(null);

  const toggleIsOpen = () => setIsOpen(!isOpen);

  const { areaId, polygonId, countrySubd } = useDefaultSearchArea();

  const hasSavedHomes = useMemo(
    () => Boolean(savedListings?.length),
    [savedListings],
  );

  const handleMenuOpen = () => {
    toggleIsOpen();
  };

  const additionalSearchNavGroupPaths = useMemo(
    () => (isSubNavHidden ? {} : { favorites: routerPaths.savedHomes }),
    [isSubNavHidden],
  );

  const searchNavGroupPaths = useMemo(
    () => ({
      ...routerPaths.search,
      ...additionalSearchNavGroupPaths,
      move: routerPaths.myJourney.myMove,
      listings: routerPaths.nextHome.listing,
      listing: routerPaths.nextHome.listingDetail,
      transactions: routerPaths.myJourney.myTransactions,
    }),
    [additionalSearchNavGroupPaths],
  );

  const showSubNav = useCallback(() => {
    setIsSubNavHidden(false);
  }, [setIsSubNavHidden]);

  const hideSubNav = useCallback(() => {
    setIsSubNavHidden(true);
  }, [setIsSubNavHidden]);

  if (!isMobile) return null;

  return (
    <Container>
      <SubNavigation />
      <Navbar ref={footerRef}>
        <Nav>
          <ActionIconWrap
            onClick={() => {
              trackPageSwitch(MainOrigin.mainNav, SubOrigin.menu);
              handleMenuOpen();
            }}
          >
            <Icon type={IconType.ellipsisV} />
          </ActionIconWrap>
          {myHomeNavProps && (
            <IconNavLink
              onClick={() => {
                trackPageSwitch(MainOrigin.mainNav, SubOrigin.myHome);
                showSubNav();
              }}
              to={myHomeNavProps?.to}
              navGroupPaths={myHomeNavProps?.navGroupPaths}
            >
              <IconWrap isMobile={isMobile}>
                <HomeMobileNavIcon
                  navGroupPaths={myHomeNavProps?.navGroupPaths}
                />
              </IconWrap>
            </IconNavLink>
          )}
          <IconNavLink
            onClick={() => {
              trackPageSwitch(MainOrigin.mainNav, SubOrigin.listings);
              showSubNav();
            }}
            to={getSearchResultsRouteParams({
              areaId,
              polygonId,
              countrySubd,
              areaType: 'locality',
            })}
            navGroupPaths={searchNavGroupPaths}
          >
            <IconWrap isMobile={isMobile}>
              <SearchMobileNavIcon navGroupPaths={searchNavGroupPaths} />
            </IconWrap>
          </IconNavLink>
          {hasSavedHomes && (
            <IconNavLink
              onClick={() => {
                trackPageSwitch(MainOrigin.mainNav, SubOrigin.favorites);
                hideSubNav();
              }}
              to={routes.savedHomes()}
              navGroupPaths={
                isSubNavHidden ? { favorites: routerPaths.savedHomes } : {}
              }
            >
              <IconWrap isMobile={isMobile}>
                <FavoritesMobileNavIcon
                  navGroupPaths={
                    isSubNavHidden ? { favorites: routerPaths.savedHomes } : {}
                  }
                />
              </IconWrap>
            </IconNavLink>
          )}
          {hasNotificationsFlag && (
            <IconNavLink
              onClick={() =>
                trackPageSwitch(MainOrigin.mainNav, SubOrigin.notifications)
              }
              to={routes.notifications()}
              navGroupPaths={{ notifications: routerPaths.notifications }}
            >
              <IconWrap isMobile={isMobile}>
                <NotificationMobileNavIcon
                  navGroupPaths={{ notifications: routerPaths.notifications }}
                />
              </IconWrap>
            </IconNavLink>
          )}

          <IconNavLink>
            <div style={{ width: '2.8rem', height: '2.5rem' }} />
          </IconNavLink>
        </Nav>
      </Navbar>
    </Container>
  );
}
