import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import {
  useSimilarHomes,
  LessThenMinSHtrigger,
} from '../../../../pages/MyHome/providers/SimilarHomesProvider';

import { WithActive } from '../../../../types';
import { WithListingDetails } from '../../types';

import { Button as BaseButton } from '../../../../components/UI/Button';

import useListingStatusColor from '../../../../hooks/useListingStatusColor';
import { colorOpacityApplier } from '../../../../services/colorOpacityApplier';
import { useExcludeSimilarHomes } from '../../../../pages/MyHome/providers/ExcludedSimilarHomesProvider';

export function ComparisonConfirm({
  listingDetail,
}: WithListingDetails): JSX.Element {
  const { t } = useTranslation();

  const { excludedMap, addExcluded, removeExcluded } = useExcludeSimilarHomes();

  const {
    data: similarHomes,
    setHadLessThanMinReached,
    handleLessThanMinTrigger,
  } = useSimilarHomes();

  const listingStatusColor = useListingStatusColor(
    listingDetail.ListingSummary.listingStatus,
  );

  const isExcluded = excludedMap[listingDetail?.uuid];

  const onInclude = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (isExcluded) {
      removeExcluded(listingDetail?.uuid);
    }
  };

  const onExclude = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    handleLessThanMinTrigger(LessThenMinSHtrigger.EXCLUDE);

    e.stopPropagation();
    const lessThenMinReached =
      similarHomes && similarHomes?.length <= 3 && !isExcluded;

    if (lessThenMinReached) {
      setHadLessThanMinReached();
      return;
    }

    if (!isExcluded) {
      addExcluded(listingDetail?.uuid);
    }
  };

  return (
    <Container backgroundColor={listingStatusColor}>
      <Text>
        {t('myHome.similarHomes.tileSlider.comparisonConfirmMessage')}
      </Text>
      <ButtonContainer>
        <Button active={!isExcluded} onClick={onInclude}>
          {t('common.yes')}
        </Button>
        <Button active={!!isExcluded} onClick={onExclude}>
          {t('common.no')}
        </Button>
      </ButtonContainer>
    </Container>
  );
}

const Container = styled('div')<{ backgroundColor: string }>(
  ({ backgroundColor }) => ({
    width: '100%',
    top: '14.7rem',
    display: 'flex',
    position: 'absolute',
    padding: '0.72rem 1rem',
    justifyContent: 'space-between',
    borderRadius: '0 0 0.275rem 0.275rem',
    backgroundColor: colorOpacityApplier(backgroundColor, '0.85'),
  }),
);

const Text = styled('div')(({ theme }) => ({
  fontSize: '1.1rem',
  lineHeight: '1.36rem',
  color: theme.colors.white,
}));

const ButtonContainer = styled('div')({
  gap: '0.3rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
});

const Button = styled(BaseButton, {
  shouldForwardProp: (prop) => !['active'].includes(prop),
})<WithActive>(({ theme, active }) => ({
  width: '3.2rem',
  height: '1.5rem',
  minWidth: '3.2rem',
  maxWidth: '3.2rem',
  fontSize: '0.9rem',
  lineHeight: '1.45rem',
  textTransform: 'uppercase',
  color: active ? theme.colors.white : theme.colors.black,
  backgroundColor: active ? theme.colors.black : theme.colors.white,
}));
