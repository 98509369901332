import { TSimilarHomesCriteria } from '../../../types';

function formatCriteria(
  criteria: TSimilarHomesCriteria,
): TSimilarHomesCriteria {
  return {
    range: Math.floor(criteria.range || 1),
    minBeds: Math.floor(criteria.minBeds || 0),
    maxBeds: Math.floor(criteria.maxBeds || 0),
    minPropertySize: Math.floor(criteria.minPropertySize || 0),
    maxPropertySize: Math.ceil(criteria.maxPropertySize || 0),
  };
}

export function getStageCriteria(
  stage: number,
  beds: number,
  sqft: number,
): TSimilarHomesCriteria {
  if (stage === 1) {
    return formatCriteria({
      range: 1,
      minBeds: beds,
      maxBeds: beds,
      minPropertySize: sqft * 0.8,
      maxPropertySize: sqft * 1.2,
    });
  }

  const b = beds || 0;
  const minBeds = b >= 1 ? b - 1 : 0;
  const maxBeds = b >= 0 ? b + 1 : 1;

  if (stage === 2) {
    return formatCriteria({
      range: 1,
      minBeds,
      maxBeds,
      minPropertySize: sqft * 0.8,
      maxPropertySize: sqft * 1.2,
    });
  }

  if (stage === 3) {
    return formatCriteria({
      range: 1,
      minBeds,
      maxBeds,
      minPropertySize: sqft * 0.7,
      maxPropertySize: sqft * 1.3,
    });
  }

  if (stage === 4) {
    return formatCriteria({
      range: 1,
      minBeds,
      maxBeds,
      minPropertySize: sqft * 0.6,
      maxPropertySize: sqft * 1.4,
    });
  }

  if (stage === 5) {
    return formatCriteria({
      range: 2,
      minBeds,
      maxBeds,
      minPropertySize: sqft * 0.6,
      maxPropertySize: sqft * 1.4,
    });
  }

  return formatCriteria({
    range: 5,
    minBeds,
    maxBeds,
    minPropertySize: sqft * 0.6,
    maxPropertySize: sqft * 1.4,
  });
}
