import styled from '@emotion/styled';
import { Swiper, SwiperSlide } from 'swiper/react';

export const SliderWrapper = styled('div')({
  width: '100%',
  height: '17.6rem',
  position: 'relative',
});

export const Slider = styled(Swiper, {
  shouldForwardProp: (prop) => !['withFooter'].includes(prop),
})<{ withFooter?: boolean }>(({ theme, withFooter = false }) => ({
  '&.swiper': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  '.swiper-pagination-bullets.swiper-pagination-horizontal': {
    top: withFooter ? '13rem' : '16rem',
    height: '8px',
  },
  '.swiper-pagination-bullet': {
    backgroundColor: theme.colors.grey.dark8,
  },
  '.swiper-pagination-bullet-active': {
    backgroundColor: theme.colors.white,
  },
}));

export const Slide = styled(SwiperSlide)({
  width: '100%',
});

export const NavigationButton = styled('img')({
  width: '1.7rem',
  height: '1.7rem',
  position: 'absolute',
  top: 'calc(50% - 0.85rem)',
});

export const Next = styled(NavigationButton)({
  right: '0.576rem',
});

export const Prev = styled(NavigationButton)({
  left: '0.576rem',
});
