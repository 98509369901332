import { ListingDetails } from '../../../../../../types';

export const UUID = 'year';

export function filter(
  listing: ListingDetails,
  value: { min?: number; max?: number },
): boolean {
  if (!value || (!value.min && !value.max)) return true;

  const min = Number(value?.min);
  const max = Number(value?.max);

  const yearBuilt = Number(listing?.ListingSummary?.yearBuilt) ?? 0;

  if (min && max) {
    return yearBuilt >= min && yearBuilt <= max;
  }

  if (min) {
    return yearBuilt >= min;
  }

  return yearBuilt <= (max ?? 0);
}
