import { useEffect, useState } from 'react';
// import { Amplify } from '@aws-amplify/core';

import { AccountDetails } from '../../types';
import { MigrationConfig } from '../../services/MigrationConfig';

// const getAwsConfig = (accountDetails: AccountDetails) => {
//   const redirectSignIn = window.location.origin;
//   const redirectSignOut = window.location.origin;

//   const { aws, awsCognitoUserPoolId } = accountDetails.clientConfig;

//   return {
//     aws_project_region: aws.awsProjectRegion,
//     Auth: {
//       identityPoolId: aws.awsCognitoIdentityPoolId,
//       region: aws.awsCognitoRegion,
//       userPoolId: awsCognitoUserPoolId,
//       userPoolWebClientId: aws.awsUserPoolsWebClientId,
//       oauth: {
//         ...aws.oauth,
//         redirectSignIn,
//         redirectSignOut,
//       },
//     },
//   };
// };

export function useConfigureAmplify(accountDetails?: AccountDetails): boolean {
  const [configured, setConfigured] = useState(false);

  useEffect(() => {
    if (!accountDetails?.accountId || MigrationConfig.isMigrated) return;

    // const awsConfig = getAwsConfig(accountDetails);
    // Amplify.configure({
    //   ...awsConfig,
    //   authenticationFlowType: 'USER_PASSWORD_AUTH',
    // });

    setConfigured(true);
  }, [accountDetails]);

  return configured;
}
