import { useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import api from '../api';
import { apiRoutes, routes } from '../constants';
import { Area, ListingDetails, SearchArea } from '../types';
import { usePolygonDetailsByArea } from './query/usePolygonDetailsByArea';

const retryOnFail = (path: string) => {
  if (path === routes.search()) return false;

  return 2;
};

const fetchMarketDetails = async (
  area: SearchArea | null,
): Promise<MarketDetails> => {
  const response = await api.get(apiRoutes.market.getByArea(area));

  return response.data;
};

export function useMarket(area?: SearchArea): UseMarketDetailsResult {
  const history = useHistory();

  const payload = useMemo(() => {
    if (!area?.areaId) return null;
    return {
      areaType: area?.areaType,
      areaId: area?.areaId,
      countrySubd: area?.countrySubd,
    };
  }, [area?.areaId, area?.areaType, area?.countrySubd]);

  const marketQuery = useQuery<MarketDetails, Error>(
    ['marketDetails', area],
    () => fetchMarketDetails(payload),
    {
      staleTime: Infinity,
      enabled: Boolean(area?.areaId),
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      retry: retryOnFail(history.location.pathname),
    },
  );

  const isGeoArea = area?.areaType === 'geo-area';
  const polygonQuery = usePolygonDetailsByArea(payload);
  const polygonQueryByLocality = usePolygonDetailsByArea({
    ...payload,
    areaType: 'locality',
  } as SearchArea);

  const polygon = !polygonQuery.isError ? polygonQuery : polygonQueryByLocality;

  const data = useMemo(() => {
    if (!isGeoArea) return marketQuery.data;
    if (marketQuery.isLoading || polygon.isLoading) return undefined;

    return {
      ...marketQuery.data,
      Area: {
        ...marketQuery.data?.Area,
        areaName: polygon.data?.areaId ?? marketQuery.data?.Area.areaName ?? '',
      },
    } as MarketDetails;
  }, [isGeoArea, marketQuery.data, marketQuery.isLoading, polygon]);

  const isLoading = !isGeoArea
    ? marketQuery.isLoading
    : marketQuery.isLoading || polygon.isLoading;

  return useMemo(
    () => ({
      isLoading,
      error: marketQuery.error,
      marketDetail: data ?? null,
      isInvalidMarket: !data,
    }),
    [data, isLoading, marketQuery.error],
  );
}

export function useInvalidateMarketDetails(): InvalidateFn {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries('marketDetails');
  };
}

type InvalidateFn = () => void;
export interface UseMarketDetailsResult {
  isLoading: boolean;
  isInvalidMarket: boolean;
  error: Error | null;
  marketDetail: MarketDetails | null;
}

export interface MarketDetails {
  Area: Area;
  AverageListingPriceTrend: AverageListingPriceTrend[];
  HomeSalesStats: HomeSalesStats;
  ListingStats: ListingStats;
  ListingsCountTrend: ListingsCountTrend[];
  homeownerId: number;
  marketDetailId: number;
}

interface ListingStats {
  avgDaysOnMarket: number | null;
  avgDaysOnMarketChange: number | null;
  avgListPrice: number | null;
  avgListPriceChange: number | null;
  avgPricePerSqMeasure: number | null;
  avgPricePerSqMeasureChange: number | null;
  hotListings: ListingDetails[];
  length: number | null;
  listingsChange: number | null;
  listingsCount: number | null;
  listingsHighPrice: number | null;
  listingsLowPrice: number | null;
  mostExpensiveListing: ListingDetails;
  newListingsChange: number | null;
  newListingsCount: number | null;
  offMarketChange: number | null;
  highestSoldListing: ListingDetails;
  offMarketCount: number | null;
  priceReductionsChange: number | null;
  priceReductionsCount: number | null;
  recentListings: ListingDetails[];
}

interface HomeSalesStats {
  avgSalesChange: number;
  avgSalesPrice: number;
  recentSales: ListingDetails[];
  salesChange: number;
  salesCount: number;
  salesToListPriceChange: number;
  salesToListPriceRatio: string;
}

interface AverageListingPriceTrend {
  date: string; // date-string
  value: number;
}

interface ListingsCountTrend {
  date: string; // date-string
  value: number;
}
