import { routes } from '../constants';
import { propertyTypeCodes } from '../constants/propertyTypeCodes';
import { PropTypeFilter } from '../types';

type PropsType = {
  areaType?: string;
  areaId?: string;
  countrySubd?: string;
  polygonId?: string;
};

export const getSearchResultsRouteParams = ({
  areaId,
  areaType,
  countrySubd,
  polygonId,
}: PropsType) => {
  return routes.searchResults({
    areaType: areaType || null,
    areaId: areaId || null,
    countrySubd: countrySubd || null,
    sortValue: 'listDateNewest',
    polygonId: polygonId || null,
    sale: {
      type: 'sale',
      option: 'comingSoonAndActive',
    },
    bedrooms: null,
    bathrooms: null,
    size: null,
    lotSize: null,
    year: null,
    priceRange: null,
    propertyType: propertyTypeCodes[PropTypeFilter.SingleFamily].join(','),
    userCoordinates: null,
    polygon: null,
    searchName: null,
  });
};
