import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { ActiveTab } from '../types';
import { Button } from '../../../components/UI/Button';
import { Loader } from '../../../components/V2/Loaders/Loader';
import { useAllNotifications } from '../hooks/useAllNotifications';
import { NotificationItem } from './NotificationItem/NotificationItem';
import { useUnreadNotifications } from '../hooks/useUnreadNotifications';
import { usePaginatedNotifications } from '../hooks/usePaginatedNotifications';
import { ErrorMessage } from '../../../pages/MyMove/components/common.style';

type Props = {
  activeTab: string;
};

export const NotificationsList = ({ activeTab }: Props): JSX.Element => {
  const { t } = useTranslation();
  const useNotifications =
    activeTab === ActiveTab.All ? useAllNotifications : useUnreadNotifications;
  const { data, hasMore, onLoadMore, isLoading, isError, error } =
    usePaginatedNotifications(useNotifications);

  if (isLoading) return <Loader />;

  if (isError) return <ErrorMessage>{error?.message}</ErrorMessage>;

  if (!data)
    return <ErrorMessage>{t('common.somethingWentWrong')}</ErrorMessage>;

  return (
    <Container>
      {data.length ? (
        data.map((notification, index) => (
          <NotificationItem
            dataQa={`notification_${index}`}
            notification={notification}
            isLast={index === data.length - 1}
            key={`${notification.id}_${index}`}
          />
        ))
      ) : (
        <span>{t('notifications.emptyTabMessage')}</span>
      )}
      {hasMore && (
        <StyledButton onClick={onLoadMore} loading={isLoading}>
          {t('notifications.loadMore')}
        </StyledButton>
      )}
    </Container>
  );
};

const Container = styled('div')({
  gap: '2rem',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});

const StyledButton = styled(Button)(({ theme }) => ({
  width: '15.1rem',
  height: '3.7rem',
  fontSize: '1.4rem',
  lineHeight: '1.7rem',
  padding: '1rem 4rem',
  fontWeight: theme.fontWeight.semibold,
}));
