import {
  FC,
  useMemo,
  useState,
  useContext,
  createContext,
  useCallback,
  useEffect,
} from 'react';
import { BrowserStorage } from '../../../services/BrowserStorage';

interface ExcludeSH {
  excluded: string[];
  excludedMap: Record<string, string>;
  addExcluded: (uuid: string) => void;
  removeExcluded: (uuid: string) => void;
}

const STORAGE_KEY = 'excludedSimilarHomes';

const ExcludeSimilarHomesContext = createContext<ExcludeSH | undefined>(
  undefined,
);

const storage = new BrowserStorage();

export const ExcludedSimilarHomesProvider: FC = ({ children }) => {
  const [excluded, setExcluded] = useState<string[] | undefined>(undefined);

  const addExcluded = useCallback((uuid: string) => {
    setExcluded((prev) => Array.from(new Set([...(prev ?? []), uuid])));
  }, []);

  const removeExcluded = useCallback((toExclude: string) => {
    setExcluded((prev) =>
      (prev ?? []).filter((excludedUuid) => excludedUuid !== toExclude),
    );
  }, []);

  useEffect(() => {
    const excluded = storage.getItem<string[]>(STORAGE_KEY);
    setExcluded(excluded || []);
  }, []);

  useEffect(() => {
    if (!excluded) return;

    storage.setItem(STORAGE_KEY, excluded);
  }, [excluded]);

  const excludedMap = useMemo(() => {
    if (Array.isArray(excluded)) {
      return excluded?.reduce(
        (map, e) => ({ ...map, [e]: e }),
        {} as Record<string, string>,
      );
    }
    return {};
  }, [excluded]);

  const value = useMemo(
    () => ({
      excluded: excluded ?? [],
      excludedMap,
      addExcluded,
      removeExcluded,
    }),
    [excluded, excludedMap, addExcluded, removeExcluded],
  );

  return (
    <ExcludeSimilarHomesContext.Provider value={value}>
      {children}
    </ExcludeSimilarHomesContext.Provider>
  );
};

export function useExcludeSimilarHomes(): ExcludeSH {
  const context = useContext(ExcludeSimilarHomesContext);

  if (context === undefined) {
    throw new Error(
      'useExcludeSimilarHomes cannot be used outside ExcludeSimilarHomesProvider!',
    );
  }

  return context;
}
