import React from 'react';
import Styled from './privacy-policy-licensing-agreement.style';
import { useTranslation } from 'react-i18next';
import { useAgentHelper } from '../../utils/useAgentHelper';

const PrivacyPolicyLicensingAgreement = (props) => {
  const { t } = useTranslation();
  const { website } = useAgentHelper();
  return (
    <>
      <Styled.PrivacyPolicyContainer>
        <Styled.LinkContainer>
          <Styled.TC target="_blank" href={`${website}/terms.php`}>
            {t('privacyPolicyLicensingAgreement.termsAndConditions')}
          </Styled.TC>
          <Styled.PrivacyPolicy target="_blank" href={`${website}/privacy.php`}>
            {t('privacyPolicyLicensingAgreement.privacyPolicy')}
          </Styled.PrivacyPolicy>
        </Styled.LinkContainer>
      </Styled.PrivacyPolicyContainer>
    </>
  );
};

export default PrivacyPolicyLicensingAgreement;
