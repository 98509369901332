import { useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { ImgIcon } from '../RegistrationSteps.style';
import { svgStringToDataUrl } from '../../../../services/svgStringToDataUrl';

function KeyIcon(): JSX.Element {
  const theme = useTheme();

  const imgSrc = useMemo(
    () => svgStringToDataUrl(icon, theme.colors.branding.main),
    [theme],
  );

  return <ImgIcon src={imgSrc} />;
}

const icon = `<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 26.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg
      id="Group_9470"
      data-name="Group 9470"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="46.395"
      height="46.372"
      viewBox="0 0 46.395 46.372"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_3449"
            data-name="Rectangle 3449"
            width="46.395"
            height="46.372"
            fill="#1F4D8C"
          />
        </clipPath>
      </defs>
      <g id="Group_9325" data-name="Group 9325" clipPath="url(#clip-path)">
        <path
          id="Path_3451"
          data-name="Path 3451"
          d="M20.3,34.76c0,1.187,0,2.292,0,3.4a2.194,2.194,0,0,1-2.351,2.4c-1.124.022-2.249,0-3.453,0,0,1.106,0,2.166,0,3.227a2.255,2.255,0,0,1-2.544,2.577q-4.684.007-9.368,0A2.253,2.253,0,0,1,.014,43.813c0-2.639.024-5.279-.014-7.918a2.858,2.858,0,0,1,.916-2.2q6.809-6.782,13.6-13.579a1.059,1.059,0,0,0,.346-1.1,15.919,15.919,0,1,1,17.953,12.6c-1.465.185-2.967.07-4.451.125a1.346,1.346,0,0,0-.789.276c-.8.7-1.568,1.448-2.336,2.189a2,2,0,0,1-1.482.558c-1.126-.007-2.251,0-3.465,0m-2.876-2.869h.721c1.672,0,3.345.018,5.016-.017a1.451,1.451,0,0,0,.881-.365c.883-.825,1.707-1.712,2.588-2.539a1.066,1.066,0,0,1,.772-.256,16.83,16.83,0,0,0,5.051.107A12.956,12.956,0,0,0,43.295,13.528a12.9,12.9,0,0,0-14.848-10.5c-8.693,1.4-12.559,9.778-10.517,16.85a.983.983,0,0,1-.287,1.144q-7.178,7.138-14.331,14.3a1.336,1.336,0,0,0-.438,1.053c.022,2.155.01,4.311.01,6.467v.609h8.759V37.675h5.778Z"
          transform="translate(0 0)"
          fill="#1F4D8C"
        />
        <path
          id="Path_3452"
          data-name="Path 3452"
          d="M133.381,47.038a2.159,2.159,0,1,1-2.2-2.126,2.211,2.211,0,0,1,2.2,2.126"
          transform="translate(-97.85 -34.049)"
          fill="#1F4D8C"
        />
      </g>
    </svg>`;
export default KeyIcon;
