import styled from '@emotion/styled';
import { WithIsMobile } from '../../../types';

export const Container = styled.div`
  left: 0;
  right: 0;
  bottom: 0;
  position: sticky;
  @media (min-width: ${({ theme }) => theme.breakpoint.md.min}) {
    padding-bottom: 0;
  }
`;

export const Navbar = styled.nav`
  align-items: center;
  padding: 1rem 3rem 2rem;
  transform: translateY(0);
  justify-content: space-between;
  box-shadow: ${({ theme }) => theme.boxShadow.navBar};
  background-color: ${({ theme }) => theme.colors.white};

  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);

  transition: 0.5s transform ease;
  -webkit-transition: 0.5s transform ease;
  -moz-transition: 0.5s transform ease;
  -o-transition: 0.5s transform ease;

  &.collapsed {
    transform: translateY(100%);

    -ms-transform: translateY(100%);
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -o-transform: translateY(100%);
  }
`;

export const Nav = styled('ul')`
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ActionIconWrap = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  svg {
    transform: scale(2.2);
    color: ${({ theme }) => theme.colors.grey.dark15};
  }
`;

export const IconWrap = styled.div<WithIsMobile>`
  display: flex;
  width: 2.8rem;
  height: 2.5rem;
  font-size: 2.8rem;
  align-self: center;
  line-height: 2.5rem;
  justify-content: center;
  margin-bottom: ${({ isMobile }) => (isMobile ? '0' : '0.3rem')};
`;
