import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Icon, IconType } from '../../../components/icon';
import { useAgentHelper } from '../../../utils/useAgentHelper';
import { Spinner } from '../../../components/V2/Loaders/Spinner';
import { useAgentDetails } from '../../../hooks/query/useAgentDetails';
import { ExpandableSection } from '../../ListingDetails/components/ListingDetailsContent/ExpandableSection/ExpandableSection';
import { useContactAgent } from '../../../providers/ContactAgentProvider';
import { useAgentPhoneNumber } from '../../../hooks/useAgentPhoneNumber';

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: 220,
  '@media (max-width: 820px)': {
    height: 'auto',
  },
});

const Text = styled('p')({
  padding: '2rem 2.7rem',
  borderBottom: '1px solid #e0e0e0',
  fontSize: 16,
  '& span': {
    fontWeight: 700,
  },
});

const LoaderWrapper = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ContactWrapper = styled('div')({
  display: 'flex',
  padding: '2rem 2.7rem',
  gap: 8,
});

const ImageContainer = styled('div')(({ theme }) => ({
  minWidth: '4.6rem',
  minHeight: '4.6rem',
  maxWidth: '4.6rem',
  maxHeight: '4.6rem',
  borderRadius: theme.borderRadius.rounded,
  boxShadow: theme.boxShadow.main,
  border: `3px solid ${theme.colors.white}`,
  overflow: 'hidden',
  flexShrink: 0,
}));

const Image = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  alignSelf: 'center',
});

const Details = styled.div`
  display: flex;
  flex-direction: column;
  & p {
    font-size: 14px;
    & span {
      font-weight: 700;
    }
  }
`;

const IconsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  width: 80,
  justifyContent: 'space-between',
  fontSize: 17,
  color: theme.colors.branding.main,
  marginLeft: -12,
  '& span': {
    padding: 20,
    height: 16,
    position: 'relative',
    '&:before': {
      display: 'block',
      content: '" "',
      backgroundColor: '#e0e0e0',
      position: 'absolute',
      left: 0,
      top: 12,
      height: 18,
      width: 1,
    },
  },
  '& .no-border:before': {
    display: 'none',
  },
}));

export const Contact: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { avatarSrc, firstName, fullName } = useAgentHelper();
  const { onPhone, onEmail, onChat } = useContactAgent();

  const phoneNumber = useAgentPhoneNumber();
  const { data: agent, isLoading } = useAgentDetails();
  const titleStyle = { color: theme.colors.branding.main };

  const headStylesContact = {
    height: window.innerWidth <= 630 ? '63px' : '59px',
  };

  return (
    <ExpandableSection
      title={t('faq.contact.title')}
      id="contact"
      isOpen={true}
      titleStyle={titleStyle}
      headStyles={headStylesContact}
      noContentPadding
    >
      <Content>
        {!agent || isLoading || !avatarSrc || !fullName ? (
          <LoaderWrapper>
            <Spinner size={3} />
          </LoaderWrapper>
        ) : (
          <>
            <Text>
              {t('faq.contact.text', { agent: firstName })}{' '}
              <span>{t('faq.contact.boldText')}</span>
            </Text>
            <ContactWrapper>
              <ImageContainer>
                <Image src={avatarSrc} />
              </ImageContainer>
              <Details>
                <p>
                  <span>{fullName}</span>{' '}
                  {!agent.Office[0]?.officeName
                    ? null
                    : '- ' + agent.Office[0]?.officeName}
                </p>
                <IconsWrapper>
                  {phoneNumber && (
                    <Icon
                      type={IconType.phone}
                      className="no-border"
                      onClick={onPhone}
                    />
                  )}

                  <Icon type={IconType.mail} onClick={onEmail} />

                  <Icon type={IconType.chat} onClick={onChat} />
                </IconsWrapper>
              </Details>
            </ContactWrapper>
          </>
        )}
      </Content>
    </ExpandableSection>
  );
};
