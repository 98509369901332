import { useMemo } from 'react';

import { useHomeownerByContact } from '../../../../../hooks/useHomeownerByContact';

export function useHasAreasOfInterest() {
  const { data: homeownerDetail, isError, isLoading } = useHomeownerByContact();

  return useMemo(() => {
    if (isLoading || isError) return false;

    return homeownerDetail?.AreasOfInterest?.length ?? 0 > 0;
  }, [homeownerDetail?.AreasOfInterest?.length, isError, isLoading]);
}
