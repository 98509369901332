import styled from '@emotion/styled';

export const Wrapper = styled.div`
  overflow: hidden;
  position: fixed;
  bottom: 1.4rem;
  right: 1.4rem;

  @media (max-width: 768px) {
    display: none;
  }
`;
export const AgentImg = styled.img`
  overflow: none;
  object-fit: cover;
  background-color: white;
  width: 100%;
  height: 100%;
`;

export const AgentImgWrapper = styled.div`
  border-radius: 50%;
  border-bottom-right-radius: 0;
  width: 80px;
  height: 80px;
  right: 2.4rem;
  overflow: hidden;
  position: fixed;
  bottom: 2.7rem;
`;
export const Container = styled.div`
  position: fixed;
  bottom: 11rem;
  right: 10.5rem;
`;
export const AgentChatWrapper = styled.div<{ imgPath: string }>`
  height: 107px;
  width: 107px;
  background-size: 107px 107px;
  overflow: hidden;
  background-image: url(${({ imgPath }) => imgPath});
  position: fixed;
  bottom: 1.4rem;
  right: 1rem;
`;
