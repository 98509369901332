import { formatAddress } from '../../../utils/addressHelperFn';
import { useState, useCallback } from 'react';
import { Address } from '../../../types';

export const useAddressPicker = () => {
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete>();

  const getAddress = useCallback((): {
    address: google.maps.places.PlaceResult | null;
    formattedAddress: Address | null;
  } => {
    const address = autocomplete?.getPlace();

    if (!address)
      return {
        address: null,
        formattedAddress: null,
      };

    const formattedAddress = formatAddress(address);

    return {
      address,
      formattedAddress,
    };
  }, [autocomplete]);

  const onLoad = useCallback((ref: google.maps.places.Autocomplete) => {
    setAutocomplete(ref);
  }, []);

  return {
    onLoad,
    autocomplete,
    getAddress,
  };
};
