import { Buffer } from 'buffer';

export const svgStringToDataUrl = (
  svgString,
  newPrimary = '#1F4D8C',
  primaryToReplace = '#1F4D8C',
  newSecondary = '#B3C7D1',
  secondaryToReplace = '#B3C7D1',
) => {
  const regExPrimary = new RegExp(primaryToReplace, 'ig');
  const regExSecondary = new RegExp(secondaryToReplace, 'ig');

  const imageReplacedPrimary = svgString.replace(regExPrimary, newPrimary);
  const image = imageReplacedPrimary.replace(regExSecondary, newSecondary);

  return `data:image/svg+xml;base64,${Buffer.from(image).toString('base64')}`;
};
