import { useCallback, useMemo } from 'react';

import { useAuthentication } from './AuthenticationProvider';
import { useAuthModal } from '../AuthModalProvider';

type Callback = (e?: Event) => void;
interface AuthorizationInterface {
  gatedOnOpenLoginModal: (
    e: Event | React.MouseEvent<HTMLElement> | null,
    onClick: Callback,
  ) => boolean;
}

export function useAuthorization(): AuthorizationInterface {
  const { onOpenModal } = useAuthModal();

  const { isAuthenticated } = useAuthentication();

  const gatedOnOpenLoginModal = useCallback(
    (e, onClick) => {
      e?.preventDefault?.();

      if (!isAuthenticated) {
        onOpenModal(onClick);
        return false;
      }

      if (onClick) {
        onClick(e);
      }

      return true;
    },
    [isAuthenticated, onOpenModal],
  );

  return useMemo(() => ({ gatedOnOpenLoginModal }), [gatedOnOpenLoginModal]);
}
