import {
  CSSProperties,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useState,
} from 'react';

import { Address } from '../../../types';
import { Input } from '../../UI';
import { AddressPicker } from '../AddressPicker';

interface Props {
  name?: string;
  error: string;
  style?: CSSProperties;
  className?: string;
  inputStyles?: CSSProperties;
  dataQa?: string;
  inputValue: string;
  placeholder?: string;
  setAddress: Dispatch<SetStateAction<Address | null>>;
  setPlace?: Dispatch<SetStateAction<google.maps.places.PlaceResult | null>>;
  setInputValue: Dispatch<SetStateAction<string>>;
  label?: string;
  initValue?: string;
  blockScrollFor?: string[];
}

export const AddressAutocomplete: FC<Props> = ({
  error,
  style,
  label,
  inputValue,
  inputStyles,
  setPlace,
  dataQa = 'address-autocomplete',
  setAddress,
  placeholder,
  setInputValue,
  name = 'address',
  initValue = '',
  blockScrollFor = ['#root'],
}) => {
  const [isAddressSet, setIsAddressSet] = useState(false);
  const [formattedAddress, setFormattedAddress] = useState<Address | null>(
    null,
  );

  const onPlaceChanged = useCallback(
    ({ address, formattedAddress }) => {
      setPlace?.(address);
      setAddress(formattedAddress);
      setFormattedAddress(formattedAddress);
      setInputValue(formattedAddress?.fullAddress || '');
      setIsAddressSet(true);
    },
    [setAddress, setInputValue, setPlace],
  );

  const disableScrolling = (isScrollDisabled: boolean) => {
    blockScrollFor?.map((elementSelector) => {
      const blockedItemNode =
        document.querySelector<HTMLElement>(elementSelector);
      if (!blockedItemNode) return;
      blockedItemNode.style.overflowY = isScrollDisabled ? 'hidden' : 'scroll';
    });
  };

  return (
    <div style={style}>
      <AddressPicker onPlaceChanged={onPlaceChanged}>
        <Input
          onFocus={() => {
            disableScrolling(true);
          }}
          type="text"
          error={!!error}
          dataQa={dataQa}
          name={name}
          value={inputValue}
          style={{
            width: '100%',
          }}
          placeholder={placeholder}
          onBlur={() => {
            disableScrolling(false);
            if (isAddressSet) return;
            setInputValue(initValue);
          }}
          label={label}
          helperText={error}
          onChange={(e) => {
            setInputValue((prev): string => {
              if (prev === formattedAddress?.fullAddress) {
                setAddress(null);
                return '';
              }
              return e.target.value;
            });
          }}
          inputStyles={
            inputStyles || {
              fontWeight: 700,
              height: '2.5rem',
              fontSize: '1.4rem',
              backgroundColor: '#fff',
            }
          }
        />
      </AddressPicker>
    </div>
  );
};
