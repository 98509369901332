import { UseMutationResult, useMutation } from 'react-query';

import api from '../../../api';
import { apiRoutes } from '../../../constants';
import { formatFavoriteListingPayload } from './utils';
import { IHomeownerDetail, ListingDetails } from '../../../types';
import { listingFavoriteStatuses } from '../../../constants/listing-favorite-statuses';

interface FavoriteListingViewedPayload {
  homeownerDetail?: IHomeownerDetail;
  listingDetail?: ListingDetails | null;
  listingComments?: string | null;
}

async function updateFavoriteStatus({
  listingDetail,
  listingComments,
}: FavoriteListingViewedPayload): Promise<void> {
  const payload = formatFavoriteListingPayload({
    listingComments,
    listingDetail,
    status: listingFavoriteStatuses.viewed,
  });

  await api.post(apiRoutes.favoriteListing.post(), payload);
}

export function useViewedListing(): UseMutationResult<
  void,
  Error,
  FavoriteListingViewedPayload
> {
  return useMutation(updateFavoriteStatus);
}
