export const addressSuffixes = {
  ALLEE: 'ALLEE',
  ALLEY: 'ALLEY',
  ALLY: 'ALLY',
  ALY: 'ALY',
  ANEX: 'ANEX',
  ANNEX: 'ANNEX',
  ANNX: 'ANNX',
  ANX: 'ANX',
  ARC: 'ARC',
  ARCADE: 'ARCADE',
  AV: 'AV',
  AVE: 'AVE',
  AVEN: 'AVEN',
  AVENU: 'AVENU',
  AVENUE: 'AVENUE',
  AVN: 'AVN',
  AVNUE: 'AVNUE',
  BAYOO: 'BAYOO',
  BYU: 'BYU',
  BCH: 'BCH',
  BEACH: 'BEACH',
  BEND: 'BEND',
  BND: 'BND',
  BLF: 'BLF',
  BLUF: 'BLUF',
  BLUFF: 'BLUFF',
  BLUFFS: 'BLUFFS',
  BLFS: 'BLFS',
  BOT: 'BOT',
  BTM: 'BTM',
  BOTTM: 'BOTTM',
  BOTTOM: 'BOTTOM',
  BLVD: 'BLVD',
  BOUL: 'BOUL',
  BOULEVARD: 'BOULEVARD',
  BOULV: 'BOULV',
  BR: 'BR',
  BRNCH: 'BRNCH',
  BRANCH: 'BRANCH',
  BRDGE: 'BRDGE',
  BRG: 'BRG',
  BRIDGE: 'BRIDGE',
  BRK: 'BRK',
  BROOK: 'BROOK',
  BROOKS: 'BROOKS',
  BRKS: 'BRKS',
  BURG: 'BURG',
  BG: 'BG',
  BURGS: 'BURGS',
  BGS: 'BGS',
  BYP: 'BYP',
  BYPA: 'BYPA',
  BYPAS: 'BYPAS',
  BYPASS: 'BYPASS',
  BYPS: 'BYPS',
  CAMP: 'CAMP',
  CP: 'CP',
  CMP: 'CMP',
  CANYN: 'CANYN',
  CANYON: 'CANYON',
  CNYN: 'CNYN',
  CYN: 'CYN',
  CAPE: 'CAPE',
  CPE: 'CPE',
  CAUSEWAY: 'CAUSEWAY',
  CAUSWA: 'CAUSWA',
  CSWY: 'CSWY',
  CEN: 'CEN',
  CENT: 'CENT',
  CENTER: 'CENTER',
  CENTR: 'CENTR',
  CENTRE: 'CENTRE',
  CNTER: 'CNTER',
  CNTR: 'CNTR',
  CTR: 'CTR',
  CENTERS: 'CENTERS',
  CIR: 'CIR',
  CIRC: 'CIRC',
  CIRCL: 'CIRCL',
  CIRCLE: 'CIRCLE',
  CRCL: 'CRCL',
  CRCLE: 'CRCLE',
  CIRCLES: 'CIRCLES',
  CIRS: 'CIRS',
  CLF: 'CLF',
  CLIFF: 'CLIFF',
  CLFS: 'CLFS',
  CLIFFS: 'CLIFFS',
  CLB: 'CLB',
  CLUB: 'CLUB',
  COMMON: 'COMMON',
  CMN: 'CMN',
  COMMONS: 'COMMONS',
  CMNS: 'CMNS',
  COR: 'COR',
  CORNER: 'CORNER',
  CORNERS: 'CORNERS',
  CORS: 'CORS',
  COURSE: 'COURSE',
  CRSE: 'CRSE',
  COURT: 'COURT',
  CT: 'CT',
  COURTS: 'COURTS',
  CTS: 'CTS',
  COVE: 'COVE',
  CV: 'CV',
  COVES: 'COVES',
  CVS: 'CVS',
  CREEK: 'CREEK',
  CRK: 'CRK',
  CRESCENT: 'CRESCENT',
  CRES: 'CRES',
  CRSENT: 'CRSENT',
  CRSNT: 'CRSNT',
  CREST: 'CREST',
  CRST: 'CRST',
  CROSSING: 'CROSSING',
  CRSSNG: 'CRSSNG',
  XING: 'XING',
  CROSSROAD: 'CROSSROAD',
  XRD: 'XRD',
  CROSSROADS: 'CROSSROADS',
  XRDS: 'XRDS',
  CURVE: 'CURVE',
  CURV: 'CURV',
  DALE: 'DALE',
  DL: 'DL',
  DAM: 'DAM',
  DM: 'DM',
  DIV: 'DIV',
  DIVIDE: 'DIVIDE',
  DV: 'DV',
  DVD: 'DVD',
  DR: 'DR',
  DRIV: 'DRIV',
  DRIVE: 'DRIVE',
  DRV: 'DRV',
  DRIVES: 'DRIVES',
  DRS: 'DRS',
  EST: 'EST',
  ESTATE: 'ESTATE',
  ESTATES: 'ESTATES',
  ESTS: 'ESTS',
  EXP: 'EXP',
  EXPY: 'EXPY',
  EXPR: 'EXPR',
  EXPRESS: 'EXPRESS',
  EXPRESSWAY: 'EXPRESSWAY',
  EXPW: 'EXPW',
  EXT: 'EXT',
  EXTENSION: 'EXTENSION',
  EXTN: 'EXTN',
  EXTNSN: 'EXTNSN',
  EXTENSIONS: 'EXTENSIONS',
  EXTS: 'EXTS',
  FALL: 'FALL',
  FALLS: 'FALLS',
  FLS: 'FLS',
  FERRY: 'FERRY',
  FRY: 'FRY',
  FRRY: 'FRRY',
  FIELD: 'FIELD',
  FLD: 'FLD',
  FIELDS: 'FIELDS',
  FLDS: 'FLDS',
  FLAT: 'FLAT',
  FLT: 'FLT',
  FLATS: 'FLATS',
  FLTS: 'FLTS',
  FORD: 'FORD',
  FRD: 'FRD',
  FORDS: 'FORDS',
  FRDS: 'FRDS',
  FOREST: 'FOREST',
  FRST: 'FRST',
  FORESTS: 'FORESTS',
  FORGE: 'FORGE',
  FORG: 'FORG',
  FRG: 'FRG',
  FORGES: 'FORGES',
  FRGS: 'FRGS',
  FORK: 'FORK',
  FRK: 'FRK',
  FORKS: 'FORKS',
  FRKS: 'FRKS',
  FORT: 'FORT',
  FRT: 'FRT',
  FT: 'FT',
  FREEWAY: 'FREEWAY',
  FREEWY: 'FREEWY',
  FRWAY: 'FRWAY',
  FRWY: 'FRWY',
  FWY: 'FWY',
  GARDEN: 'GARDEN',
  GARDN: 'GARDN',
  GRDEN: 'GRDEN',
  GRDN: 'GRDN',
  GDN: 'GDN',
  GARDENS: 'GARDENS',
  GDNS: 'GDNS',
  GRDNS: 'GRDNS',
  GATEWAY: 'GATEWAY',
  GATEWY: 'GATEWY',
  GATWAY: 'GATWAY',
  GTWAY: 'GTWAY',
  GTWY: 'GTWY',
  GLEN: 'GLEN',
  GLN: 'GLN',
  GLENS: 'GLENS',
  GLNS: 'GLNS',
  GREEN: 'GREEN',
  GRN: 'GRN',
  GREENS: 'GREENS',
  GRNS: 'GRNS',
  GROV: 'GROV',
  GROVE: 'GROVE',
  GRV: 'GRV',
  GROVES: 'GROVES',
  GRVS: 'GRVS',
  HARB: 'HARB',
  HARBOR: 'HARBOR',
  HARBR: 'HARBR',
  HBR: 'HBR',
  HRBOR: 'HRBOR',
  HARBORS: 'HARBORS',
  HBRS: 'HBRS',
  HAVEN: 'HAVEN',
  HVN: 'HVN',
  HEIGHTS: 'HEIGHTS',
  HT: 'HT',
  HTS: 'HTS',
  HIGHWAY: 'HIGHWAY',
  HIGHWY: 'HIGHWY',
  HIWAY: 'HIWAY',
  HIW: 'HIWY',
  HWAY: 'HWAY',
  HWY: 'HWY',
  HILL: 'HILL',
  HL: 'HL',
  HILLS: 'HILLS',
  HLS: 'HLS',
  HLLW: 'HLLW',
  HOLLOW: 'HOLLOW',
  HOLLOWS: 'HOLLOWS',
  HOLW: 'HOLW',
  HOLWS: 'HOLWS',
  INLT: 'INLT',
  IS: 'IS',
  ISLAND: 'ISLAND',
  ISLND: 'ISLND',
  ISLANDS: 'ISLANDS',
  ISLNDS: 'ISLNDS',
  ISS: 'ISS',
  ISLE: 'ISLE',
  ISLES: 'ISLES',
  JCT: 'JCT',
  JCTION: 'JCTION',
  JCTN: 'JCTN',
  JUNCTION: 'JUNCTION',
  JUNCTN: 'JUNCTN',
  JUNCTON: 'JUNCTON',
  JCTNS: 'JCTNS',
  JCTS: 'JCTS',
  JUNCTIONS: 'JUNCTIONS',
  KEY: 'KEY',
  KY: 'KY',
  KEYS: 'KEYS',
  KYS: 'KYS',
  KNL: 'KNL',
  KNOL: 'KNOL',
  KNOLL: 'KNOLL',
  KNLS: 'KNLS',
  KNOLLS: 'KNOLLS',
  LK: 'LK',
  LAKE: 'LAKE',
  LKS: 'LKS',
  LAKES: 'LAKES',
  LAND: 'LAND',
  LANDING: 'LANDING',
  LNDG: 'LNDG',
  LNDNG: 'LNDNG',
  LANE: 'LANE',
  LN: 'LN',
  LGT: 'LGT',
  LIGHT: 'LIGHT',
  LIGHTS: 'LIGHTS',
  LF: 'LF',
  LOAF: 'LOAF',
  LCK: 'LCK',
  LOCK: 'LOCK',
  LCKS: 'LCKS',
  LOCKS: 'LOCKS',
  LDG: 'LDG',
  LDGE: 'LDGE',
  LODG: 'LODG',
  LODGE: 'LODGE',
  LOOP: 'LOOP',
  LOOPS: 'LOOPS',
  MALL: 'MALL',
  MNR: 'MNR',
  MANOR: 'MANOR',
  MANORS: 'MANORS',
  MNRS: 'MNRS',
  MEADOW: 'MEADOW',
  MDW: 'MDW',
  MDWS: 'MDWS',
  MEADOWS: 'MEADOWS',
  MEDOWS: 'MEDOWS',
  MEWS: 'MEWS',
  MILL: 'MILL',
  ML: 'ML',
  MILLS: 'MILLS',
  MLS: 'MLS',
  MISSION: 'MISSION',
  MISSN: 'MISSN',
  MSN: 'MSN',
  MSSN: 'MSSN',
  MOTORWAY: 'MOTORWAY',
  MOUNT: 'MOUNT',
  MNT: 'MNT',
  MT: 'MT',
  MNTAIN: 'MNTAIN',
  MNTN: 'MNTN',
  MOUNTAIN: 'MOUNTAIN',
  MOUNTIN: 'MOUNTIN',
  MTIN: 'MTIN',
  MTN: 'MTN',
  MNTNS: 'MNTNS',
  MOUNTAINS: 'MOUNTAINS',
  NCK: 'NCK',
  NECK: 'NECK',
  ORCH: 'ORCH',
  ORCHARD: 'ORCHARD',
  ORCHRD: 'ORCHRD',
  OVAL: 'OVAL',
  OVL: 'OVL',
  OVERPASS: 'OVERPASS',
  OPAS: 'OPAS',
  PARK: 'PARK',
  PRK: 'PRK',
  PARKS: 'PARKS',
  PARKWAY: 'PARKWAY',
  PARKWY: 'PARKWY',
  PKWAY: 'PKWAY',
  PKWY: 'PKWY',
  PKY: 'PKY',
  PARKWAYS: 'PARKWAYS',
  PKWYS: 'PKWYS',
  PASS: 'PASS',
  PASSAGE: 'PASSAGE',
  PSGE: 'PSGE',
  PATH: 'PATH',
  PATHS: 'PATHS',
  PIKE: 'PIKE',
  PIKES: 'PIKES',
  PINE: 'PINE',
  PNE: 'PNE',
  PINES: 'PINES',
  PNES: 'PNES',
  PL: 'PL',
  PLACE: 'PLACE',
  PLAIN: 'PLAIN',
  PLN: 'PLN',
  PLAINS: 'PLAINS',
  PLNS: 'PLNS',
  PLAZA: 'PLAZA',
  PLZ: 'PLZ',
  PLZA: 'PLZA',
  POINT: 'POINT',
  PT: 'PT',
  POINTS: 'POINTS',
  PTS: 'PTS',
  PORT: 'PORT',
  PRT: 'PRT',
  PORTS: 'PORTS',
  PRTS: 'PRTS',
  PR: 'PR',
  PRAIRIE: 'PRAIRIE',
  PRR: 'PRR',
  RAD: 'RAD',
  RADIAL: 'RADIAL',
  RADIEL: 'RADIEL',
  RADL: 'RADL',
  RAMP: 'RAMP',
  RANCH: 'RANCH',
  RANCHES: 'RANCHES',
  RNCH: 'RNCH',
  RNCHS: 'RNCHS',
  RAPID: 'RAPID',
  RPD: 'RPD',
  RAPIDS: 'RAPIDS',
  RPDS: 'RPDS',
  REST: 'REST',
  RST: 'RST',
  RDG: 'RDG',
  RDGE: 'RDGE',
  RIDGE: 'RIDGE',
  RDGS: 'RDGS',
  RIDGES: 'RIDGES',
  RIVER: 'RIVER',
  RIV: 'RIV',
  RVR: 'RVR',
  RIVR: 'RIVR',
  ROAD: 'ROAD',
  RD: 'RD',
  ROADS: 'ROADS',
  RDS: 'RDS',
  ROUTE: 'ROUTE',
  RTE: 'RTE',
  ROW: 'ROW',
  RUE: 'RUE',
  RUN: 'RUN',
  SHL: 'SHL',
  SHOAL: 'SHOAL',
  SHOALS: 'SHOALS',
  SHLS: 'SHLS',
  SHORE: 'SHORE',
  SHOAR: 'SHOAR',
  SHR: 'SHR',
  SHORES: 'SHORES',
  SHOARS: 'SHOARS',
  SHRS: 'SHRS',
  SKYWAY: 'SKYWAY',
  SKWY: 'SKWY',
  SPRING: 'SPRING',
  SPG: 'SPG',
  SPNG: 'SPNG',
  SPRNG: 'SPRNG',
  SPRINGS: 'SPRINGS',
  SPGS: 'SPGS',
  SPNGS: 'SPNGS',
  SPRNGS: 'SPRNGS',
  SPUR: 'SPUR',
  SPURS: 'SPURS',
  SQ: 'SQ',
  SQR: 'SQR',
  SQRE: 'SQRE',
  SQU: 'SQU',
  SQUARE: 'SQUARE',
  SQUARES: 'SQUARES',
  SQRS: 'SQRS',
  SQS: 'SQS',
  STATION: 'STATION',
  STA: 'STA',
  STATN: 'STATN',
  STN: 'STN',
  STRAVENUE: 'STRAVENUE',
  STRA: 'STRA',
  STRAV: 'STRAV',
  STRAVEN: 'STRAVEN',
  STRAVN: 'STRAVN',
  STRVN: 'STRVN',
  STRVNUE: 'STRVNUE',
  STREAM: 'STREAM',
  STRM: 'STRM',
  STREME: 'STREME',
  STREET: 'STREET',
  ST: 'ST',
  STRT: 'STRT',
  STR: 'STR',
  STREETS: 'STREETS',
  STS: 'STS',
  SUMMIT: 'SUMMIT',
  SMT: 'SMT',
  SUMIT: 'SUMIT',
  SUMITT: 'SUMITT',
  TERRACE: 'TERRACE',
  TER: 'TER',
  TERR: 'TERR',
  THROUGHWAY: 'THROUGHWAY',
  TRWY: 'TRWY',
  TRACE: 'TRACE',
  TRCE: 'TRCE',
  TRACES: 'TRACES',
  TRACK: 'TRACK',
  TRAK: 'TRAK',
  TRACKS: 'TRACKS',
  TRK: 'TRK',
  TRKS: 'TRKS',
  TRAFFICWAY: 'TRAFFICWAY',
  TRFY: 'TRFY',
  TRAIL: 'TRAIL',
  TRL: 'TRL',
  TRAILS: 'TRAILS',
  TRLS: 'TRLS',
  TRAILER: 'TRAILER',
  TRLR: 'TRLR',
  TRLRS: 'TRLRS',
  TUNNEL: 'TUNNEL',
  TUNEL: 'TUNEL',
  TUNL: 'TUNL',
  TUNLS: 'TUNLS',
  TUNNELS: 'TUNNELS',
  TUNNL: 'TUNNL',
  TURNPIKE: 'TURNPIKE',
  TRNPK: 'TRNPK',
  TPKE: 'TPKE',
  TURNPK: 'TURNPK',
  UNDERPASS: 'UNDERPASS',
  UPAS: 'UPAS',
  UNION: 'UNION',
  UN: 'UN',
  UNIONS: 'UNIONS',
  UNS: 'UNS',
  VALLEY: 'VALLEY',
  VLY: 'VLY',
  VALLY: 'VALLY',
  VLLY: 'VLLY',
  VALLEYS: 'VALLEYS',
  VLYS: 'VLYS',
  VIADUCT: 'VIADUCT',
  VDCT: 'VDCT',
  VIA: 'VIA',
  VIADCT: 'VIADCT',
  VIEW: 'VIEW',
  VW: 'VW',
  VIEWS: 'VIEWS',
  VWS: 'VWS',
  VILLAGE: 'VILLAGE',
  VILL: 'VILL',
  VLG: 'VLG',
  VILLAG: 'VILLAG',
  VILLG: 'VILLG',
  VILLIAGE: 'VILLIAGE',
  VILLAGES: 'VILLAGES',
  VLGS: 'VLGS',
  VILLE: 'VILLE',
  VL: 'VL',
  VISTA: 'VISTA',
  VIS: 'VIS',
  VIST: 'VIST',
  VST: 'VST',
  VSTA: 'VSTA',
  WALK: 'WALK',
  WALKS: 'WALKS',
  WALL: 'WALL',
  WAY: 'WAY',
  WY: 'WY',
  WAYS: 'WAYS',
  WELL: 'WELL',
  WL: 'WL',
  WELLS: 'WELLS',
  WLS: 'WLS',
};

export const getAddressWithoutSuffix = (address: string): string => {
  if (!address) return '';
  const splitedAddress = address.split(' ');
  const formattedAddress = splitedAddress.filter((word) => {
    return (
      word.toUpperCase() !==
      addressSuffixes[word.toUpperCase() as keyof typeof addressSuffixes]
    );
  });

  return formattedAddress.join(' ');
};
