import { useEffect, useRef, useState } from 'react';
import { Icon, IconType } from '../../../../../components/icon';
import {
  Body,
  Container,
  Head,
  IconWrapper,
  Title,
  BodyContent,
  HeadWrapper,
  Description,
} from './ExpandableSection.style';

interface ExpandableSectionProps {
  title: string;
  isOpen: boolean;
  onToggle?: () => void;
  id: string;
  forceAdjustment?: boolean | string;
  isGreyBg?: boolean;
  noContentPadding?: boolean;
  description?: string;
  titleStyle?: React.CSSProperties;
  headStyles?: React.CSSProperties;
  containerStyles?: React.CSSProperties;
}

export const ExpandableSection: React.FC<ExpandableSectionProps> = ({
  children,
  title,
  isOpen,
  forceAdjustment,
  onToggle,
  id,
  isGreyBg,
  noContentPadding,
  description,
  titleStyle = {},
  headStyles = {},
  containerStyles = {},
}) => {
  const bodyRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<number | undefined>(0);

  const clientHeight = bodyRef.current?.clientHeight;

  useEffect(() => {
    const height = bodyRef.current?.clientHeight;
    if (height !== contentHeight) setContentHeight(height);
  }, [contentHeight, clientHeight, forceAdjustment]);

  const dataQa = `section-${title.toLowerCase().replace(' ', '-')}`;
  return (
    <Container
      id={id}
      data-qa={dataQa}
      isGreyBg={isGreyBg}
      style={containerStyles}
    >
      <HeadWrapper isOpen={isOpen} style={headStyles}>
        <Head
          onClick={() => onToggle && onToggle()}
          isOpen={isOpen}
          style={headStyles}
        >
          {onToggle && (
            <IconWrapper>
              <Icon
                type={isOpen ? IconType.minusCircle : IconType.plusCircle}
              />
            </IconWrapper>
          )}
          <Title style={titleStyle}>{title}</Title>
          {description && <Description>{description}</Description>}
        </Head>
      </HeadWrapper>
      <Body isOpen={isOpen} contentHeight={contentHeight}>
        <BodyContent noContentPadding={noContentPadding} ref={bodyRef}>
          {children}
        </BodyContent>
      </Body>
    </Container>
  );
};
