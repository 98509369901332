import { UUID as LotSize } from '../components/FiltersDrawer/SizeAndYearFilters/LotSize';
import { UUID as YearBuilt } from '../components/FiltersDrawer/SizeAndYearFilters/YearBuilt';
import { UUID as Size } from '../components/FiltersDrawer/SizeAndYearFilters/SizeFilter';
import { UUID as MinimumBedrooms } from '../components/FiltersDrawer/MinimumRoomsFilter/MinimumBedrooms';
import { UUID as MinimumBathrooms } from '../components/FiltersDrawer/MinimumRoomsFilter/MinimumBathrooms';
import { UUID as PropType } from '../components/FiltersDrawer/PropertyType';
import { useSearchParams } from '../../../providers/SearchParamsProvider';

export const SearchFilter = {
  SaleType: 'saleType',
  LotSize,
  YearBuilt,
  Size,
  MinimumBedrooms,
  MinimumBathrooms,
  PropType,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useActiveFilters = (): Record<string, any> => {
  const { get } = useSearchParams();

  return Object.values(SearchFilter).reduce((result, param) => {
    const value = get(param);
    return value ? { ...result, [param]: value } : result;
  }, {});
};
