import { useCallback } from 'react';
import { useQuery, useQueryClient, UseQueryResult } from 'react-query';

import api from '../../api';
import apiRoutes from '../../constants/api-routes';
import { useHomeownerByContact } from '../useHomeownerByContact';
import { MigrationConfig } from '../../services/MigrationConfig';

type CompleteUserInfo = {
  userType: string;
  associatedAgents: AssociatedAgent[];
  homeOwners: HomeOwner[];
};

type AssociatedAgent = {
  contactId: number;
  agentId: string;
  homeownerId: string | null;
  firstName: string;
  lastName: string;
  agentPhoto: string;
  officeName: string;
  agentDomain: string;
};

type HomeOwner = {
  contactId: number;
  homeownerUuid: string;
  activeProfile: boolean;
  userName: string;
  agentId: string;
  firstName: string;
  lastName: string;
  profilePhoto: string;
};

type InvalidateFn = () => void;

const KEY_PREFIX = 'complete-user-info';

async function fetchUserInfo(email: string): Promise<CompleteUserInfo> {
  const response = await api.get(apiRoutes.auth.completeUserInfo(), {
    params: { email },
  });

  return response.data;
}

export function useCompleteUserInfo(): UseQueryResult<CompleteUserInfo> {
  const { data: homeownerDetail } = useHomeownerByContact();
  const email = homeownerDetail?.Communication?.emailPrimary || '';

  const hasInternalAuth = MigrationConfig.get().isMigrated;

  return useQuery([KEY_PREFIX, email], () => fetchUserInfo(email), {
    retry: 2,
    enabled: Boolean(email) && hasInternalAuth,
  });
}

export function useInvalidateCompleteUserInfo(): InvalidateFn {
  const queryClient = useQueryClient();
  const { data: homeownerDetail } = useHomeownerByContact();
  const email = homeownerDetail?.Communication?.emailPrimary || '';

  return useCallback(() => {
    queryClient.invalidateQueries([KEY_PREFIX, email]);
  }, [email, queryClient]);
}
