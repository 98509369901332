import styled from '@emotion/styled';

export const Container = styled.div`
  cursor: pointer;
  position: fixed;
  bottom: 11rem;
  right: 0rem;
  @media (max-width: 768px) {
    display: none;
  }
`;
export const SecondRow = styled.p`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-around;
  bottom: 2rem;
  right: 2rem;
  height: 30px;
  width: 160px;
  font-size: 1.2rem;
`;

export const AgentNameText = styled.p`
  font-size: 1.4rem;
  line-height: 1.2rem;
  font-weight: 700;
  max-width: 136px;
  color: #000;
`;

export const AgentWrapper = styled.div`
  position: fixed;
  bottom: 6rem;
  right: 0rem;
  height: 30px;
  display: table-cell;
  vertical-align: middle;
  width: 173px;
`;

export const ProvidedText = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.grey.dark2};
`;

export const ProviderTextWrapper = styled.div`
  position: fixed;
  bottom: 9.5rem;
  width: 173px;
  right: 0rem;
`;

export const AgentChatWrapper = styled.div<{ imgPath: string }>`
  height: 107px;
  width: 273px;
  background-size: 273px 107px;
  overflow: hidden;
  background-image: url(${({ imgPath }) => imgPath});
  position: fixed;
  bottom: 1.4rem;
  right: 1rem;
`;

export const AgentImg = styled.img`
  overflow: none;
  object-fit: cover;
  background-color: white;
  width: 100%;
  height: 100%;
`;

export const AgentImgWrapper = styled.div`
  border-radius: 50%;
  border-bottom-right-radius: 0;
  width: 82px;
  height: 82px;
  right: 19rem;
  overflow: hidden;
  position: fixed;
  bottom: 2.7rem;
`;
