import { useCallback, useRef } from 'react';

import styled from '@emotion/styled';
import { useRecoilState } from 'recoil';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';

import { Notification } from '../types';
import { OptionsIcon } from '../icons/OptionsIcon';
import { useMarkAsRead } from '../hooks/useMarkAsRead';
import { MarkAsReadIcon } from '../icons/MarkAsReadIcon';
import { useDeleteNotification } from '../hooks/useDeleteNotification';
import { RemoveNotificationIcon } from '../icons/RemoveNotificationIcon';
import notificationOptionsAttom from '../state/notificationOptionsAttom';

interface OptionsDropdownProps {
  isLast: boolean;
  notification: Notification;
}

export function OptionsDropdown({
  isLast,
  notification,
}: OptionsDropdownProps): JSX.Element {
  const { id, isRead } = notification;
  const tooltipId = `notification-options-${id}`;

  const { t } = useTranslation();
  const { mutate: markAsRead } = useMarkAsRead();
  const tooltipRef = useRef<HTMLDivElement>(null);
  const { mutate: deleteNotification } = useDeleteNotification();

  const [openDropdownId, setOpenDropdownId] = useRecoilState(
    notificationOptionsAttom,
  );

  const onRemove = useCallback(
    () => deleteNotification(notification),
    [deleteNotification, notification],
  );

  const onMarkAsRead = useCallback(() => {
    if (isRead) return;
    markAsRead(id);
  }, [id, isRead, markAsRead]);

  const place = isLast ? 'top-end' : 'bottom-end';

  const openDropdown = useCallback(
    (e) => {
      e.stopPropagation();
      setOpenDropdownId(tooltipId);
    },
    [setOpenDropdownId, tooltipId],
  );

  return (
    <div ref={tooltipRef} onClick={openDropdown} data-tooltip-id={tooltipId}>
      <OptionsIcon />
      <StyledTooltip
        clickable
        openOnClick
        place={place}
        id={tooltipId}
        variant="light"
        className="customStyle opacityFix"
        isOpen={openDropdownId === tooltipId}
      >
        <OptionsList>
          <Option onClick={onMarkAsRead} disabled={isRead}>
            <MarkAsReadIcon />
            <span>{t('notifications.markAsRead')}</span>
          </Option>
          <Option onClick={onRemove}>
            <RemoveNotificationIcon />
            <span>{t('notifications.remove')}</span>
          </Option>
        </OptionsList>
      </StyledTooltip>
    </div>
  );
}

const StyledTooltip = styled(Tooltip)({
  '&.customStyle': {
    padding: 0,
    display: 'flex',
    width: '23.5rem',
    height: '8.1rem',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
  },
  '&.opacityFix': {
    opacity: '1',
  },
});

const OptionsList = styled('div')({
  opacity: 1,
  gap: '0.3rem',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
});

const Option = styled('div')<{ disabled?: boolean }>(({ theme, disabled }) => ({
  gap: '0.8rem',
  display: 'flex',
  width: '22.5rem',
  fontSize: '1.4rem',
  lineHeight: '1.7rem',
  borderRadius: '0.3rem',
  justifyContent: 'start',
  padding: '0.9rem 1.1rem',
  color: theme.colors.black,
  border: '1px solid #E2E2E2',
  opacity: disabled ? '0.3' : '1',
  cursor: disabled ? 'not-allowed' : 'pointer',
}));
