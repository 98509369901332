import dayjs from 'dayjs';
import { useMemo } from 'react';
import { ListingDetails } from '../../../types';

function useFormatOpenHouseTime(listing?: ListingDetails) {
  return useMemo(() => {
    const { openHouseDate, openHouseTime, openHouseInd } =
      listing?.AdditionalDetails || {};

    if (!openHouseInd) return;

    const date = dayjs(new Date(openHouseDate || '')).format('ddd. MM/DD');

    if (!openHouseTime) {
      return date;
    }

    const [startTime, endTime] = openHouseTime.split(' - ');

    const startSuffix = startTime.includes('AM') ? 'AM' : 'PM';
    const endSuffix = endTime.includes('AM') ? 'AM' : 'PM';

    const startTimeNum = parseInt(startTime);
    const endTimeNum = parseInt(endTime);

    let timeFormatted = '';

    if (startSuffix !== endSuffix) {
      timeFormatted = `${startTimeNum}${startSuffix}-${endTimeNum}${endSuffix}`;
    }

    if (startSuffix === endSuffix) {
      timeFormatted = `${startTimeNum}-${endTimeNum}${startSuffix}`;
    }

    return `${date}, ${timeFormatted}`;
  }, [listing?.AdditionalDetails]);
}

export default useFormatOpenHouseTime;
