import { Address } from '../types';

export const getAddressPart = (
  addressParts: google.maps.GeocoderAddressComponent[],
  name: string,
  getShortName = false,
  secondaryName?: string,
): string | null => {
  const part = addressParts.find(
    (p) => p.types.includes(name) || p.types.includes(secondaryName || ''),
  );
  if (!part) {
    return null;
  }

  if (getShortName) {
    return part.short_name;
  }

  return part.long_name;
};

export const getCounty = (
  addressParts: google.maps.GeocoderAddressComponent[],
): string | null => getAddressPart(addressParts, 'administrative_area_level_2');

export const getState = (
  addressParts: google.maps.GeocoderAddressComponent[],
): string | null =>
  getAddressPart(addressParts, 'administrative_area_level_1', true);

export const getCity = (
  addressParts: google.maps.GeocoderAddressComponent[],
): string | null =>
  getAddressPart(addressParts, 'locality', false, 'sublocality') ||
  getAddressPart(addressParts, 'neighborhood', false);

const getAddressLine1 = (
  addressParts: google.maps.GeocoderAddressComponent[],
): string | null => {
  const streetNumber = getAddressPart(addressParts, 'street_number');
  const streetName = getAddressPart(addressParts, 'route', true);

  if (!streetNumber && !streetName) {
    return null;
  }

  if (!streetNumber) {
    return `${streetName}`;
  }

  if (!streetName) {
    return `${streetNumber}`;
  }

  return `${streetNumber} ${streetName}`;
};

export const getAddressLine2 = (
  addressParts: google.maps.GeocoderAddressComponent[],
): string => {
  const city = getCity(addressParts);
  const state = getState(addressParts);
  const postal1 = getAddressPart(addressParts, 'postal_code');

  const cityStr = city ? `${city},` : '';
  const stateStr = state ? `${state}` : '';
  const postal1Str = postal1 ? `${postal1}` : '';

  return `${cityStr} ${stateStr} ${postal1Str}`;
};

type AddressWithCoordinates = Address & {
  coordinates: {
    lat: number;
    lng: number;
  };
};

export const formatAddress = (
  address: google.maps.places.PlaceResult,
): AddressWithCoordinates | null => {
  const { address_components, geometry } = address;

  const coordinates = {
    lat: geometry?.location?.lat() || 0,
    lng: geometry?.location?.lng() || 0,
  };

  if (!address_components) return null;

  return {
    fullAddress: address.formatted_address || '',
    country: getAddressPart(address_components, 'country', true) || '',
    countrySubd: getState(address_components) || '',
    countrySecondarySubd: getCounty(address_components) || '',
    addressLine1: getAddressLine1(address_components) || '',
    addressLine2: getAddressLine2(address_components) || '',
    locality: getCity(address_components) || '',
    postal1: getAddressPart(address_components, 'postal_code') || '',
    postal2: '',
    postal3: '',
    subdivision: '',
    coordinates,
  };
};

export const handleAddressValidation = (
  address: google.maps.places.PlaceResult,
  input: string,
  handleErrorState: (value: { exist: boolean; msg: string }) => void,
): void => {
  const isCorrect = address.formatted_address === input;
  const errorMsg = isCorrect ? '' : 'editProfile.invalidAddress';
  handleErrorState({
    exist: !isCorrect,
    msg: errorMsg,
  });
};
