import { useMemo } from 'react';

import { OverlayView } from '@react-google-maps/api';

import { WithListingDetails } from '../../types';
import homeMarkerIcon from '../../icons/homeMarker';
import listingMarkerIcon from '../../icons/listingMarker';
import { MapContainer } from '../../components/common.style';
import { getCoordinates } from '../../../../utils/getCoordinates';
import { StaticMap } from '../../../../components/V2/StaticMap/StaticMap';
import useListingStatusColor from '../../../../hooks/useListingStatusColor';
import { useHomeownerPropertyDetails } from '../../../../hooks/query/useHomeownerPropertyDetails';

const markerStyle = {
  width: '2.2rem',
};

export function ListingMap({ listingDetail }: WithListingDetails): JSX.Element {
  const { data: homeDetails } = useHomeownerPropertyDetails();
  const listingStatusColor = useListingStatusColor(
    listingDetail.ListingSummary.listingStatus,
  );

  const markers = useMemo(
    function getMarkers() {
      const homeCoordinates = getCoordinates(homeDetails);
      const listingCoordinates = getCoordinates(listingDetail);

      const homeMarker = {
        markerStyle,
        isCentered: true,
        position: homeCoordinates,
        svgString: homeMarkerIcon,
        mapPaneName: OverlayView.MARKER_LAYER,
      };

      const listingMarker = {
        markerStyle,
        isCentered: false,
        position: listingCoordinates,
        svgString: listingMarkerIcon,
        markerColor: listingStatusColor,
        mapPaneName: OverlayView.MARKER_LAYER,
      };

      return [homeMarker, listingMarker];
    },
    [homeDetails, listingDetail, listingStatusColor],
  );
  return (
    <MapContainer>
      <StaticMap markers={markers} />
    </MapContainer>
  );
}
