import { TSaleType } from '../types';

const propTypes = {
  sale: [
    '1',
    '2',
    '3',
    '5',
    '8',
    '10',
    '13',
    '14',
    '15',
    '18',
    '19',
    '20',
    '22',
    '30',
    '31',
    '35',
    '38',
    '41',
    '43',
    '44',
    '46',
    '51',
    '53',
    '54',
  ],
  rent: ['6', '21', '27', '28', '32', '34', '42', '52'],
  sold: [
    '1',
    '2',
    '3',
    '5',
    '8',
    '10',
    '13',
    '14',
    '15',
    '18',
    '19',
    '20',
    '22',
    '30',
    '31',
    '35',
    '38',
    '41',
    '43',
    '44',
    '46',
    '51',
    '53',
    '54',
  ],
};

export const getPropTypesBasedOnSaleType = (saleType: TSaleType): string[] => {
  return propTypes[saleType];
};

export const getPropTypesBasedOnSaleTypes = (
  saleTypes: TSaleType[],
): string[] => {
  let ids = [] as string[];

  saleTypes.forEach((st) => {
    const stIds = getPropTypesBasedOnSaleType(st);
    ids = [...ids, ...stIds];
  });

  return Array.from(new Set(ids));
};

export const getSaleTypeBasedOnPropTypes = (propertyType: string[]): string => {
  return (
    Object.keys(propTypes).find(
      (saleType) =>
        propTypes[saleType as TSaleType]?.join() === propertyType?.join(),
    ) || 'sale'
  );
};
