import { useMemo, useState } from 'react';

import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import KeyIcon from './KeyIcon';
import {
  Text,
  Button,
  Container,
  ModalFooter,
  ErrorMessage,
} from '../RegistrationSteps.style';
import useSignUpUser from './useSignUpUser';
import { useWizard } from '../../Wizard/Wizard';
import ModalHeader from '../../Modal/header/ModalHeader';
import { InfoTooltip } from '../../InfoTooltip/InfoTooltip';
import { passwordRegex } from '../../../../constants/password';
import { PasswordInput, PasswordInputWrapper } from './PasswordCreation.style';

import { useTrackPage, TrackPageTypes } from '../../../../features/analytics';
import { useIsMobileView } from '../../../../hooks/useIsMobileView';

const initialValue = {
  password: '',
  confirmPassword: '',
};

function PasswordCreationStep(): JSX.Element {
  const { t } = useTranslation();
  const { nextStep } = useWizard();
  const signUpUser = useSignUpUser();
  const isMobile = useIsMobileView();

  useTrackPage(TrackPageTypes.registerStart);

  const [formError, setFormErrors] = useState('');

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('common.required'))
      .matches(passwordRegex, t('auth.formErrors.passwordMustMatchCriteria')),
    confirmPassword: Yup.string()
      .required(t('common.required'))
      .oneOf(
        [Yup.ref('password'), ''],
        t('auth.formErrors.passwordsMustMatch'),
      ),
  });

  async function handleSubmit({
    password,
    confirmPassword,
  }: {
    password: string;
    confirmPassword: string;
  }) {
    signUpUser({
      password,
      passwordConfirmation: confirmPassword,
      onError: setFormErrors,
      onSuccess: nextStep,
    });
  }

  const formattedTooltipText = useMemo(() => {
    return `${t('passwordRules.0')}\n${t('passwordRules.1')}\n${t(
      'passwordRules.2',
    )}\n${t('passwordRules.3')}\n${t('passwordRules.4')}`;
  }, [t]);

  return (
    <Container isMobile={isMobile}>
      <ModalHeader
        icon={<KeyIcon />}
        title={t('auth.createPasswordStep.passwordCreation')}
      />
      <Text>
        {t('auth.createPasswordStep.createAndConfirm')}
        <InfoTooltip fontSize={'1.6rem'} tooltipText={formattedTooltipText} />
      </Text>
      <Formik
        initialValues={initialValue}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnChange={false}
        onSubmit={handleSubmit}
        validateOnBlur
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          const passHasValue = values.password !== '';
          const passMatch = values.password === values.confirmPassword;
          const isButtonVisible = passMatch && passHasValue;

          return (
            <PasswordInputWrapper onSubmit={handleSubmit}>
              <PasswordInput
                name="password"
                marginTop="2rem"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                error={errors.password}
                className="password-creation sentry-mask"
                placeholder={t('auth.createPasswordStep.passwordPlaceholder')}
              />
              <PasswordInput
                onBlur={handleBlur}
                name="confirmPassword"
                onChange={handleChange}
                value={values.confirmPassword}
                error={errors.confirmPassword}
                className="password-creation sentry-mask"
                placeholder={t(
                  'auth.createPasswordStep.confirmPasswordPlaceholder',
                )}
              />

              {formError && <ErrorMessage>{formError}</ErrorMessage>}

              <ModalFooter marginTop="21.5rem">
                <Button
                  type="submit"
                  shape="square"
                  disabled={isSubmitting}
                  visible={isButtonVisible}
                >
                  {t('auth.next')}
                </Button>
              </ModalFooter>
            </PasswordInputWrapper>
          );
        }}
      </Formik>
    </Container>
  );
}

export default PasswordCreationStep;
