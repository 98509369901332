import { useQuery, useQueryClient, UseQueryResult } from 'react-query';

import api from '../api';
import { apiRoutes } from '../constants';
import { IHomeownerDetail } from '../types';
import { useDomainWithSubdomain } from './useDomain';
import { useTokenData } from '../providers/Authentication/useTokenData';
import { useHolpTokens } from '../providers/Authentication/HolpTokensProvider';

type THomeownerDetailByContact = {
  agentDomain: number | undefined;
};

const HOMEOWNER_BY_CONTACT_KEY = 'homeownerDetailByContact';

export const fetchHomeownerDetailByContact = async ({
  agentDomain,
}: THomeownerDetailByContact): Promise<IHomeownerDetail> => {
  const response = await api.get(apiRoutes.homeowner.getByContact(), {
    params: { domain: agentDomain },
  });
  return response.data;
};

export function useHomeownerByContact(): UseQueryResult<
  IHomeownerDetail,
  Error
> {
  const { token } = useHolpTokens();
  const { data: tokenData } = useTokenData();
  const agentDomain = useDomainWithSubdomain();

  const contactId = tokenData?.contactId;

  return useQuery(
    [HOMEOWNER_BY_CONTACT_KEY, contactId, agentDomain],
    () => fetchHomeownerDetailByContact({ agentDomain }),
    {
      enabled: Boolean(agentDomain) && Boolean(contactId) && Boolean(token),
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );
}

type InvalidateFn = () => void;

export function useInvalidateHomeownerByContact(): InvalidateFn {
  const queryClient = useQueryClient();
  const { data: tokenData } = useTokenData();
  const agentDomain = useDomainWithSubdomain();

  const contactId = tokenData?.contactId;

  return () => {
    queryClient.invalidateQueries([
      HOMEOWNER_BY_CONTACT_KEY,
      contactId,
      agentDomain,
    ]);
  };
}

export function useHomeownerByContactOptimistic() {
  const queryClient = useQueryClient();

  const { data: tokenData } = useTokenData();
  const agentDomain = useDomainWithSubdomain();

  const contactId = tokenData?.contactId;

  const KEY = [HOMEOWNER_BY_CONTACT_KEY, contactId, agentDomain];

  const update = (
    updaterFn: (old: IHomeownerDetail | undefined) => IHomeownerDetail,
  ) => {
    queryClient.cancelQueries(KEY);

    const prev = queryClient.getQueryData<IHomeownerDetail>(KEY);

    queryClient.setQueryData(KEY, () => {
      if (!prev) return prev;

      return updaterFn(prev);
    });
  };

  const revert = (prev?: IHomeownerDetail) => {
    queryClient.setQueryData(KEY, prev);
  };

  return { update, revert };
}
