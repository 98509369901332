import { InfiniteData, useMutation } from 'react-query';

import {
  Notification,
  NotificationsCount,
  NotificationsWithMeta,
} from '../types';
import {
  useInvalidateNotificationsCount,
  useNotificationsCountOptimistic,
} from './useNotificationsCount';
import { hopApi } from '../../../api/hop';
import { apiRoutes } from '../../../constants/apiRoutes';
import { useAllNotificationsOptimistic } from './useAllNotifications';
import { useUnreadNotificationsOptimistic } from './useUnreadNotifications';
import { toastActions } from '../../../components/V2/ToastComponent/ToastComponent';

function updateNotificationsData(notificationId?: number) {
  return (oldNotificationsData: InfiniteData<NotificationsWithMeta>) => {
    const updatePages = oldNotificationsData?.pages.map((page) => ({
      ...page,
      data: page.data.filter(
        (notification) => notification.id !== notificationId,
      ),
    }));

    return {
      ...oldNotificationsData,
      pages: updatePages,
    };
  };
}

function updateCount(isRead: boolean) {
  return (oldCountData?: NotificationsCount): NotificationsCount => ({
    total: oldCountData?.total ? oldCountData.total - 1 : 0,
    unread:
      !isRead && oldCountData?.unread
        ? oldCountData.unread - 1
        : oldCountData?.unread ?? 0,
  });
}

async function deleteNotification(notificationId?: number): Promise<void> {
  if (!notificationId) return;
  await hopApi.delete(apiRoutes.notifications.delete(notificationId));
}

export function useDeleteNotification() {
  const invalidateNotificationsCount = useInvalidateNotificationsCount();

  const allNotificationsOptimistic = useAllNotificationsOptimistic();
  const notificationsCountOptimistic = useNotificationsCountOptimistic();
  const unreadNotificationsOptimistic = useUnreadNotificationsOptimistic();

  return useMutation(
    (notification: Notification) => deleteNotification(notification.id),
    {
      onMutate: async (notification: Notification) => {
        const prevAllNotifications = allNotificationsOptimistic.update(
          updateNotificationsData(notification.id),
        );
        const prevUnreadNotifications = unreadNotificationsOptimistic.update(
          updateNotificationsData(notification.id),
        );
        const prevCount = notificationsCountOptimistic.update(
          updateCount(notification.isRead),
        );

        return { prevAllNotifications, prevUnreadNotifications, prevCount };
      },
      onError: (err: Error, _new, context) => {
        allNotificationsOptimistic.revert(
          context?.prevAllNotifications as InfiniteData<NotificationsWithMeta>,
        );
        unreadNotificationsOptimistic.revert(
          context?.prevUnreadNotifications as InfiniteData<NotificationsWithMeta>,
        );
        notificationsCountOptimistic.revert(context?.prevCount);

        toastActions.error(err.message);
      },
      onSuccess: () => {
        invalidateNotificationsCount();
      },
    },
  );
}
