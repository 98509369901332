import { useMemo } from 'react';
import { useMlsIds } from '../../../hooks/useMlsIds';

export const useMlsIdMap = () => {
  const mlsIds = useMlsIds();

  const mlsIdsMap = useMemo(
    () => Object.fromEntries(mlsIds.map((id) => [id, true])),
    [mlsIds],
  );

  return mlsIdsMap;
};
