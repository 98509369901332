const validCode = '123456';

function getVerificationStatus(
  verificationCode: string,
): Promise<{ verificationInd: boolean }> {
  // API call will go here when the endpoint for this is ready
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (!verificationCode) reject('Something went wrong!');

      if (verificationCode !== validCode) resolve({ verificationInd: false });

      resolve({ verificationInd: true });
    }, 1000);
  });
}

export function verifyCode(
  verificationCode: string,
): Promise<{ verificationInd: boolean }> {
  try {
    return getVerificationStatus(verificationCode);
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    throw new Error(e.message);
  }
}
