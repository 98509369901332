import { useMemo } from 'react';

import { useCompleteUserInfo } from './useCompleteUserInfo';
import { useCognitoIdentities } from './useCognitoIdentities';
import { useTokenData } from '../../providers/Authentication/useTokenData';
import { MigrationConfig } from '../../services/MigrationConfig';

const useHasProfile = (): boolean => {
  const { data: cognitoIdentities } = useCognitoIdentities();
  const { data: completeUserInfo } = useCompleteUserInfo();

  const hasInternalAuth = MigrationConfig.get().isMigrated;

  const { data: tokenData } = useTokenData();

  return useMemo(() => {
    if (!hasInternalAuth) {
      const agent = cognitoIdentities?.find(
        (identity) =>
          String(identity.contactId) === String(tokenData?.contactId),
      );

      return Boolean(agent);
    }

    return completeUserInfo?.userType === 'coreHomeUser';
  }, [
    cognitoIdentities,
    completeUserInfo?.userType,
    hasInternalAuth,
    tokenData?.contactId,
  ]);
};

export default useHasProfile;
