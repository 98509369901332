import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: ${({ theme }) => theme.fontWeight.light};
  font-size: 12px;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const Date = styled.div`
  color: ${(props) => props.theme.colorPrimary};
  padding-bottom: 11px;
`;

const Disclaimer = styled.div`
  color: ${({ theme }) => theme.colors.grey.dark};
  padding-bottom: 11px;
  text-align: center;
`;

const Logos = styled.div`
  img {
    padding: 0 10px;
  }
`;

export default {
  Container,
  Date,
  Disclaimer,
  Logos,
};
