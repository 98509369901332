export default `<svg width="107" height="107" viewBox="0 0 107 107" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2732_279183)">
<g filter="url(#filter0_d_2732_279183)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M53.5 0C23.9528 0 0 23.9528 0 53.5C0 83.0472 23.9528 107 53.5 107H270C271.657 107 273 105.657 273 104V3C273 1.34315 271.657 0 270 0H53.5ZM86.2564 75.5813C90.514 69.2778 93 61.6792 93 53.5C93 31.6847 75.3152 14 53.5 14C31.6847 14 14 31.6847 14 53.5C14 75.3152 31.6847 93 53.5 93C61.9733 93 69.8235 90.332 76.2563 85.7902C76.527 85.5991 76.8738 85.5491 77.1855 85.6617L89.8854 90.2472C90.1182 90.3312 90.347 90.113 90.2739 89.8764L86.1252 76.448C86.0345 76.1544 86.0844 75.8359 86.2564 75.5813Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M53.5 0C23.9528 0 0 23.9528 0 53.5C0 83.0472 23.9528 107 53.5 107H270C271.657 107 273 105.657 273 104V3C273 1.34315 271.657 0 270 0H53.5ZM86.2564 75.5813C90.514 69.2778 93 61.6792 93 53.5C93 31.6847 75.3152 14 53.5 14C31.6847 14 14 31.6847 14 53.5C14 75.3152 31.6847 93 53.5 93C61.9733 93 69.8235 90.332 76.2563 85.7902C76.527 85.5991 76.8738 85.5491 77.1855 85.6617L89.8854 90.2472C90.1182 90.3312 90.347 90.113 90.2739 89.8764L86.1252 76.448C86.0345 76.1544 86.0844 75.8359 86.2564 75.5813Z" fill="#1F4D8C" fill-opacity="0.1"/>
</g>
<path fill-rule="evenodd" clip-rule="evenodd" d="M88.0955 89.6009L77.1855 85.6617C76.8738 85.5491 76.527 85.5991 76.2563 85.7902C69.8235 90.332 61.9733 93 53.5 93C31.6847 93 14 75.3152 14 53.5C14 31.6847 31.6847 14 53.5 14C75.3152 14 93 31.6847 93 53.5C93 61.6792 90.514 69.2778 86.2564 75.5813C86.0844 75.8359 86.0345 76.1544 86.1252 76.448L89.7122 88.0582L89.7788 88.2741L90.1565 89.4964L90.2739 89.8764C90.347 90.113 90.1182 90.3312 89.8854 90.2472L89.5112 90.1121L88.308 89.6776L88.0955 89.6009ZM53.5 95C30.5802 95 12 76.4198 12 53.5C12 30.5802 30.5802 12 53.5 12C76.4198 12 95 30.5802 95 53.5C95 61.9217 92.4893 69.7628 88.1754 76.3086L92.1848 89.2861C92.7451 91.0996 90.9915 92.7729 89.2062 92.1283L76.9892 87.7172C70.3096 92.3109 62.216 95 53.5 95Z" fill="white"/>
<g filter="url(#filter1_d_2732_279183)">
<path d="M93 53.5C93 61.6792 90.514 69.2778 86.2564 75.5813C86.0844 75.8359 86.0345 76.1544 86.1252 76.448L90.2739 89.8764C90.347 90.113 90.1182 90.3312 89.8854 90.2472L77.1855 85.6617C76.8738 85.5491 76.527 85.5991 76.2563 85.7902C69.8235 90.332 61.9733 93 53.5 93C31.6847 93 14 75.3152 14 53.5C14 31.6847 31.6847 14 53.5 14C75.3152 14 93 31.6847 93 53.5Z" fill="#8B8B8B" fill-opacity="0.01" shape-rendering="crispEdges"/>
</g>
</g>
<defs>
<filter id="filter0_d_2732_279183" x="-10" y="-10" width="293" height="127" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2732_279183"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2732_279183" result="shape"/>
</filter>
<filter id="filter1_d_2732_279183" x="4" y="4" width="99" height="99" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2732_279183"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2732_279183" result="shape"/>
</filter>
<clipPath id="clip0_2732_279183">
<path d="M0 53.5C0 23.9528 23.9528 0 53.5 0C83.0472 0 107 23.9528 107 53.5V104C107 105.657 105.657 107 104 107H53.5C23.9528 107 0 83.0472 0 53.5Z" fill="white"/>
</clipPath>
</defs>
</svg>`;
