import { useCallback, useMemo } from 'react';
import * as Amplitude from '@amplitude/analytics-browser';

// types
import { ConversationTypes, MainOrigin, TrackPageTypes } from './types';

interface AnalyticsContextStructure {
  trackPage: (pageName: TrackPageTypes) => void;
  trackConversation: (type: ConversationTypes) => void;
  trackAd: (name: string, position?: string, destination?: string) => void;
  trackPageSwitch: (mainOrigin: MainOrigin, subOrigin: string) => void;
}

export const useAnalytics = (): AnalyticsContextStructure => {
  const trackConversation = useCallback(
    (type: ConversationTypes) => {
      window.dataLayer?.push?.({
        event: 'Conversation Engaged',
        conversation_type: `[${type}]`,
      });

      Amplitude.track('Conversation Engaged', {
        conversation_type: String(type),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [window.dataLayer, Amplitude?.track],
  );

  const trackAd = useCallback(
    function trackAd(name: string, position = '', destination = '') {
      window.dataLayer?.push?.({
        event: 'ad_clicked',
        ad_name: name,
        ad_position: position,
        ad_destination: destination,
      });

      Amplitude.track('ad_clicked', {
        ad_name: name,
        ad_position: position,
        ad_destination: destination,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [window.dataLayer],
  );

  const trackPage = useCallback(
    function (pageName: TrackPageTypes) {
      window.dataLayer?.push?.({
        event: 'hop_page_viewed',
        page_name: pageName,
      });

      Amplitude.track('hop_page_viewed', {
        page_name: pageName,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [window.dataLayer],
  );

  const trackPageSwitch = useCallback(
    function (mainOrigin: MainOrigin, subOrigin: string) {
      window.dataLayer?.push?.({
        event: mainOrigin,
        PageType: 'All',
        event_detail: subOrigin,
      });

      Amplitude.track(`${mainOrigin}_${subOrigin}`, {
        PageType: 'All',
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [window.dataLayer],
  );

  const value = useMemo(
    function memoizedAnalyticsValue() {
      return {
        trackAd,
        trackPage,
        trackConversation,
        trackPageSwitch,
      };
    },
    [trackAd, trackPage, trackConversation, trackPageSwitch],
  );

  return value;
};
