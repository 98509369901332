import { memo } from 'react';
import { WithListingDetails } from '../types';
import { ListingDetails } from '../../../types';
import { ListingTileBase } from '../components/ListingTileBase';
import { ListingSessionProvider } from '../providers/ListingSessionProvider';
import { ListingTilePreview } from './components/ListingTilePreview';
import { useMlsIdMap } from '../../../pages/SearchResults/hooks/useMlsIdMap';

type Props = WithListingDetails & {
  dataQA?: string;
  className?: string;
  preventOpenViaPreview?: boolean;
  preventOpenViaDetails?: boolean;
  onHover?: (listing: ListingDetails) => void;
};

export const ListingTile = memo(function ListingTileMemo({
  onHover,
  className,
  listingDetail,
  dataQA = 'listing-tile',
  preventOpenViaPreview = false,
  preventOpenViaDetails = false,
}: Props): JSX.Element {
  const mlsIdMap = useMlsIdMap();

  if (!mlsIdMap[listingDetail.MlsInfo.mlsId]) {
    return <></>;
  }

  return (
    <ListingSessionProvider listing={listingDetail}>
      <ListingTileBase
        className={className}
        dataQA={dataQA}
        listingDetail={listingDetail}
        onHover={() => onHover?.(listingDetail)}
        preventOpen={preventOpenViaDetails}
        Preview={
          <ListingTilePreview
            preventOpen={preventOpenViaPreview}
            listingDetail={listingDetail}
          />
        }
      />
    </ListingSessionProvider>
  );
});
