import { useListingSnapshot } from './useListingSnapshot';
import { ListingSnapshotQuery, TSaleType } from '../../../types';
import { useSearchParams } from '../../../providers/SearchParamsProvider';
import { getPropTypesBasedOnSaleType } from '../../../services/getPropTypesBasedOnSaleType';

const MAX_RESULTS = 5000;

export function useSearchListingSnapshot(): ListingSnapshotQuery {
  const { get } = useSearchParams();

  const sale = get('sale');
  const polygon = get('polygon');
  const filterBy = get('filterBy');
  const polygonId = get('polygonId');
  const userCoordinates = get('userCoordinates');

  return useListingSnapshot(
    {
      polygon,
      filterBy,
      polygonId,
      userCoordinates,
      maxResults: MAX_RESULTS,
      propTypeIds: getPropTypesBasedOnSaleType(
        (sale?.type as TSaleType) ?? 'sale',
      ),
    },
    sale?.type,
  );
}
