import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  className?: string;
}>;

export function JourneySection({ children, className = '' }: Props) {
  return <Container className={className}>{children}</Container>;
}

const Container = styled.div`
  width: 100%;
  padding: 0 1rem;
  margin: 0 auto;
  position: relative;

  @media (min-width: ${({ theme }) => theme?.breakpoint?.md?.max}) {
    max-width: 965px;
    padding: 0;
  }
`;
