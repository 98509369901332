export default `<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 26.2.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 98.1 86.5" style="enable-background:new 0 0 98.1 86.5;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#1E4E8C;}
</style>
<g id="Group_6580">
	<path id="Path_2287" class="st0" d="M48.9,79.9c-5.6-0.2-11.2-1-16.6-2.5c-0.6-0.1-1.3,0-1.8,0.4c-5.7,3.5-11.6,6.5-18.4,7.4
		c-2.2,0.3-4.5,0.5-6.8,0.6c-1.7,0.2-3.3-0.7-4-2.3c-0.8-1.9-0.9-3.7,0.6-5.4c3.4-3.7,6.2-7.9,8.3-12.5c0.6-0.9,0.4-2.1-0.4-2.7
		c-6-6.3-9.2-14.8-9-23.5c0.1-8.6,3.5-16,9.1-22.3C17.5,8.5,27.2,3.7,38.3,1.7c11.1-2.1,22.5-0.9,33,3.5c7.3,3,13.8,7.8,18.7,14.1
		c10.7,13.8,9.8,31.6-2,44.5c-7.6,8.3-17.2,13-28.1,14.9C56.2,79.3,52.5,79.5,48.9,79.9 M8.7,79.5c0.1,0,0.3,0.1,0.4,0.1
		c0.3,0,0.5-0.1,0.8-0.1c6.5-0.9,12.8-3.4,18.1-7.2c1.5-1.2,3.6-1.5,5.4-0.9c7.9,2.5,16.2,3,24.4,1.7c10-1.4,19.2-6.2,26-13.6
		c9.7-10.5,9.9-26.6,0.6-37.4c-4-4.7-9-8.3-14.7-10.8C59.9,6.9,49,5.8,38.5,8c-9.7,1.9-18.1,6.2-24.6,13.7C7.5,29,5,37.5,7.5,47.2
		c1.3,4.7,3.7,8.9,7,12.4c2.2,2.4,2.7,5.1,1.2,8c-1,2-2.1,4-3.2,6C11.3,75.6,10,77.5,8.7,79.5"/>
	<path id="Path_2288" class="st0" d="M48.7,19.3c1.9-0.1,3.8-0.1,5.7,0c5.4,0.7,8.8,3.9,9.7,9.1c0.7,4.2-0.9,8.9-6.1,11.6
		c-1.6,0.9-3.3,1.7-5,2.5c-0.7,0.2-1.1,0.9-0.9,1.6c0,0.8,0,1.7,0,2.5c0,1.5-1.2,2.7-2.7,2.8c-1.5,0.2-2.8-0.7-3.1-2.2
		c0,0,0-0.1,0-0.1c-0.2-2.1-0.2-4.3,0-6.4c0.3-0.9,0.9-1.7,1.8-2.2c2.3-1.4,4.8-2.6,7.2-3.9s3.4-3.2,2.8-5.6c-0.5-2.3-2.7-3.9-5-3.8
		c-2.9,0-5.7,0-8.6,0c-2.4-0.1-4.4,1.7-4.5,4c0,0,0,0.1,0,0.1c-0.2,2-1.6,3.2-3.3,3c-1.6-0.2-2.8-1.7-2.6-3.3c0-0.1,0-0.2,0-0.2
		c0.4-5.5,5-9.7,10.5-9.5c1.4,0,2.8,0,4.2,0C48.7,19.2,48.7,19.2,48.7,19.3"/>
	<path id="Path_2289" class="st0" d="M52.8,57.8c0,2-1.6,3.7-3.7,3.7c0,0,0,0,0,0c-2,0-3.7-1.6-3.8-3.6c0-2.1,1.7-3.7,3.7-3.9
		C51.1,54,52.8,55.7,52.8,57.8C52.8,57.7,52.8,57.8,52.8,57.8"/>
</g>
</svg>`;
