import styled from '@emotion/styled';
import { Layer } from '../../../types';

export const TileActionsBar = styled('div')({
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  padding: '0.5rem 0.863rem',
  justifyContent: 'space-between',
  top: '0',
  zIndex: Layer.Default + 2,
});

export const Label = styled('span')(({ theme }) => ({
  fontSize: '0.9rem',
  lineHeight: '1.1rem',
  height: 'fit-content',
  borderRadius: '0.3rem',
  padding: '0.2rem 0.3rem',
  textTransform: 'capitalize',
  fontWeight: theme.fontWeight.medium,
  backgroundColor: theme.colors.white,
}));

export const MapContainer = styled('div')({
  height: '17.6rem',
  borderRadius: '0.275rem',
  '> div': {
    borderRadius: '0.275rem',
  },
});

export const Image = styled('div')<{ src?: string; withGradient?: boolean }>(
  ({ src, withGradient }) => ({
    width: '100%',
    display: 'block',
    height: '17.6rem',
    position: 'relative',
    borderRadius: '0.275rem',
    background: `${
      withGradient
        ? 'linear-gradient(180deg, #00000000 65%, #000000 100%), '
        : ''
    }url('${src}')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }),
);
