import React, { FC, useCallback, useMemo } from 'react';

import { routes } from '../constants';
import { getAgentFullName } from '../utils/getAgentFullName';
import { useAgentDetails } from '../hooks/query/useAgentDetails';
import { useStorageState } from '../hooks/useStorage/useStorageState';
import { useCompanyBranding } from '../hooks/query/useCompanyBranding';
import { useDownloadAppUrl } from '../pages/MobileAppDeeplink/hooks/useDownloadAppUrl';

interface BannerContextStructure {
  companyName: string;
  companyLogo?: string | null;
  appUrl?: string;
  agent: {
    name: string;
    photo?: string;
  };
  show: boolean;
  isClosed: boolean;
  onClose: () => void;
  goToApp: () => void;
  goToStore: () => void;
}

const BannerContext = React.createContext<BannerContextStructure | undefined>(
  undefined,
);

export const useBanner = (): BannerContextStructure => {
  const context = React.useContext(BannerContext);

  if (context === undefined) {
    throw new Error('useBanner cannot be used outside BannerProvider!');
  }

  return context;
};

export const BannerProvider: FC = ({ children }) => {
  const { data: agentDetails } = useAgentDetails();
  const { data: companyDetails } = useCompanyBranding();

  const [isClosed, setIsClosed] = useStorageState('bannerClosed', false);

  const appUrlOnStore = useDownloadAppUrl();

  const agentPhoto = agentDetails?.Bio?.agentPhoto;
  const agentName = getAgentFullName(agentDetails);

  const onClose = useCallback(() => {
    setIsClosed(true);
  }, [setIsClosed]);

  const goToHopAppPage = useCallback(() => {
    const hopDownloadPage = routes.downloadApp();
    if (!hopDownloadPage) return;

    window.open(hopDownloadPage, '_blank');
  }, []);

  const goToStore = useCallback(() => {
    if (!appUrlOnStore) return;

    window.open(appUrlOnStore, '_blank');
  }, [appUrlOnStore]);

  const value = useMemo(
    () => ({
      companyName: companyDetails?.clientName || '',
      companyLogo: companyDetails?.defaultLogo || '',
      appUrl: appUrlOnStore,
      agent: {
        name: agentName,
        photo: agentPhoto,
      },
      show: true,
      isClosed,
      onClose,
      goToApp: goToHopAppPage,
      goToStore,
    }),
    [
      companyDetails?.clientName,
      companyDetails?.defaultLogo,
      appUrlOnStore,
      agentName,
      agentPhoto,
      isClosed,
      onClose,
      goToHopAppPage,
      goToStore,
    ],
  );

  return (
    <BannerContext.Provider value={value}>{children}</BannerContext.Provider>
  );
};
