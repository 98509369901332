import { CoordinatesPair, Polygon, TAreaCoordinates } from '../types';

type TLatLng = {
  lng: number;
  lat: number;
};

export const getPolygonCoordinates = (
  polygonDetails: Polygon,
): TAreaCoordinates => {
  const { coordinates, type } = polygonDetails;

  if (type === 'MultiPolygon' || type === 'Multipolygon') {
    return (coordinates || [])
      .flatMap((coordinate) => coordinate as any) //eslint-disable-line
      .map((coordinate) =>
        coordinate
          .map(([lng, lat]: CoordinatesPair) => ({ lng, lat }) as TLatLng)
          .map(
            (coordinate: TLatLng) => new window.google.maps.LatLng(coordinate),
          ),
      );
  }

  return coordinates?.map((coordinate) =>
    coordinate
      .map(([lng, lat]) => ({ lng, lat }))
      .map((coordinate) => new window.google.maps.LatLng(coordinate)),
  );
};
