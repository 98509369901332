import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  faArrowAltCircleRight,
  faCheckCircle,
  faCircleNotch,
  faListUl,
  faLocationArrow,
  faChevronUp as faChevronUpRegular,
  faChevronDown as faChevronDownRegular,
  faFileContract,
  faLockKeyhole,
  faAngleUp,
  faAngleRight,
  faLocationDot,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faCaretDown,
  faCaretUp,
  faCaretRight,
  faHeart,
  faPen as faPenSolid,
  faLongArrowAltDown,
  faLongArrowAltRight,
  faLongArrowAltUp,
  faMapPin,
  faEllipsisV,
  faUser,
  faThLarge,
  faCheckCircle as faSolidCheckCircle,
  faHouseUser as faHouseUserSolid,
} from '@fortawesome/free-solid-svg-icons';

import {
  faBars,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faComments,
  faCommentsAlt,
  faExclamationCircle,
  faHeart as faHeartOutline,
  faHouse,
  faHouseUser,
  faImage,
  faInfoCircle,
  faLongArrowLeft,
  faLongArrowRight,
  faLongArrowDown,
  faMinus,
  faMinusCircle,
  faPen,
  faPhoneAlt,
  faPhoneRotary,
  faPlus,
  faPlusCircle,
  faSearch,
  faTimes,
  faTrash,
  faUserCircle,
  faBell,
  faDoorOpen,
  faPencilAlt,
  faBedAlt,
  faBath,
  faVectorSquare,
  faDrawSquare,
  faCalendarAlt,
  faClock,
  faUndo,
  faTag,
  faFilter,
  faMap,
  faCameraAlt,
  faVideo,
  faCube,
  faShare,
  faCalendarPlus,
  faTimesCircle,
  faMapMarked,
  faPencil,
  faAirConditioner,
  faFireplace,
  faTools,
  faCouch,
  faGarageCar,
  faGolfBall,
  faLeaf,
  faHorse,
  faDigging,
  faPaw,
  faSwimmer,
  faBinoculars,
  faSign,
  faEnvelope,
  faStar,
  faCircleQuestion,
  faTrashCan,
  faFileArrowDown,
  faLightbulb,
} from '@fortawesome/pro-light-svg-icons';

import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faInstagram,
  faPinterest,
  faYoutube,
  faApple,
  faGooglePlay,
  faPinterestP,
  faFacebookF,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons';
import { onClickProps } from '../../utils/ui-event-helper';

import Styled from './icon.style';

export const IconType = {
  alert: faExclamationCircle,
  arrowCircleRight: faArrowAltCircleRight,
  arrowDown: faLongArrowAltDown,
  arrowLeft: faLongArrowLeft,
  arrowRight: faLongArrowAltRight,
  arrowUp: faLongArrowAltUp,
  bars: faBars,
  caretDown: faCaretDown,
  caretRight: faCaretRight,
  caretUp: faCaretUp,
  chat: faCommentsAlt,
  check: faCheck,
  checkCircle: faCheckCircle,
  chevronLeft: faChevronLeft,
  chevronRight: faChevronRight,
  chevronUp: faChevronUp,
  chevronDown: faChevronDown,
  comments: faComments,
  door: faDoorOpen,
  ellipsisV: faEllipsisV,
  facebook: faFacebook,
  heart: faHeart,
  heartOutline: faHeartOutline,
  house: faHouse,
  houseUser: faHouseUser,
  imageUpload: faImage,
  instagram: faInstagram,
  linkedin: faLinkedin,
  longArrowRight: faLongArrowRight,
  mapPin: faMapPin,
  locationPin: faLocationDot,
  minus: faMinus,
  minusCircle: faMinusCircle,
  pen: faPen,
  phone: faPhoneAlt,
  pinterest: faPinterest,
  plusCircle: faPlusCircle,
  plus: faPlus,
  x: faTimes,
  search: faSearch,
  times: faTimes,
  twitter: faTwitter,
  infoCircle: faInfoCircle,
  phoneRotary: faPhoneRotary,
  trash: faTrash,
  trashCan: faTrashCan,
  user: faUser,
  userCircle: faUserCircle,
  youtube: faYoutube,
  iconSpinner: faCircleNotch,
  bell: faBell,
  // reuse from here again, will be ts enum later
  LongArrowRight: faLongArrowRight,
  LongArrowLeft: faLongArrowLeft,
  LongArrowDown: faLongArrowDown,
  FileArrowDown: faFileArrowDown,
  PencilAlt: faPencilAlt,
  Heart: faHeart,
  HeartOutlined: faHeartOutline,
  Search: faSearch,
  Bed: faBedAlt,
  Bath: faBath,
  Sqft: faVectorSquare,
  sqft2: faDrawSquare,
  ImageUpload: faImage,
  Spinner: faCircleNotch,
  Apple: faApple,
  GooglePlay: faGooglePlay,
  Calendar: faCalendarAlt,
  Clock: faClock,
  Locked: faLockKeyhole,
  Tile: faThLarge,
  ListUl: faListUl,
  Undo: faUndo,
  AngleUp: faAngleUp,
  AngleRight: faAngleRight,
  Filter: faFilter,
  TermsOfUse: faFileContract,
  Tag: faTag,
  Map: faMap,
  Camera: faCameraAlt,
  VideoCamera: faVideo,
  Cube: faCube,
  ChevronUp: faChevronUp,
  Share: faShare,
  CalendarPlus: faCalendarPlus,
  LocationArrow: faLocationArrow,
  CloseOutlined: faTimesCircle,
  MapFolded: faMapMarked,
  PencilPro: faPencil,
  ChevronUpRegular: faChevronUpRegular,
  CHevronDownRegular: faChevronDownRegular,
  airConditioner: faAirConditioner,
  fireplace: faFireplace,
  tools: faTools,
  couch: faCouch,
  garage: faGarageCar,
  golf: faGolfBall,
  leaf: faLeaf,
  horse: faHorse,
  digging: faDigging,
  paw: faPaw,
  swimmer: faSwimmer,
  binoculars: faBinoculars,
  pinterestP: faPinterestP,
  facebookF: faFacebookF,
  linkedinIn: faLinkedinIn,
  sign: faSign,
  mail: faEnvelope,
  faSolidCheckCircle: faSolidCheckCircle,
  Star: faStar,
  circleQuestion: faCircleQuestion,
  penSolid: faPenSolid,
  houseUserSolid: faHouseUserSolid,
  lightBulb: faLightbulb,
};

const Icon = (props) => {
  const {
    dataQa,
    type,
    onClick,
    disabled,
    title,
    typeOnHover,
    pillText,
    style = {},
    className = '',
    ariaHidden,
  } = props;

  const [currType, setCurrType] = useState(type);

  let iconPropList = {};
  if (!disabled && onClick) {
    iconPropList = onClickProps({ onClick });

    // add additional properties specific to icon
    iconPropList.role = 'button';
    iconPropList.tabIndex = '0';
    iconPropList['aria-label'] = title;
    iconPropList['aria-hidden'] = ariaHidden;
  }

  const onHover = () => setCurrType(typeOnHover || type);
  const onLeave = () => setCurrType(type);

  useEffect(() => {
    setCurrType(type);
  }, [type]);

  return (
    <span className={`fa-layers fa-fw fal ${className}`} data-qa={dataQa}>
      <Styled.Icon
        onMouseEnter={onHover}
        onFocus={onHover}
        onMouseLeave={onLeave}
        onBlur={onLeave}
        icon={currType}
        className="icon"
        title={title}
        disabled={disabled}
        style={style}
        {...iconPropList}
      />
      {pillText && (
        <Styled.Pill className="fa-layers-counter">{pillText}</Styled.Pill>
      )}
    </span>
  );
};

Icon.propTypes = {
  dataQa: PropTypes.string,
  type: PropTypes.shape({}).isRequired,
  title: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  pillText: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.any,
};

Icon.defaultProps = {
  dataQa: '',
  pillText: '',
  onClick: null,
  disabled: false,
};

export default Icon;
