import { TFunction } from 'i18next';

// eslint-disable-next-line
type ListContent = {
  question: string;
  answer: string;
  exclude?: boolean;
};

type SectionDescription = {
  id: string;
  title: string;
  text?: string;
  list?: ListContent[];
  groups?: SectionDescription[];
};

export default (t: TFunction<'translation'>): SectionDescription[] => {
  return [
    {
      title: t('faq.general.title'),
      list: [
        {
          question: t('faq.general.q2'),
          answer: t('faq.general.a2'),
          exclude: false,
        },
      ],
      id: 'general',
    },
    // {
    //   title: 'Profile',
    //   list: [{ question: t('faq.general.q1'), answer: t('faq.general.a1') }],
    //   id: 'profile',
    // },

    {
      title: t('faq.myHome.title'),
      groups: [
        {
          title: 'My Home Details',
          text: 'Some text text',
          id: 'my-home-details',
          list: [
            {
              question: t('faq.myHomeDetails.q1'),
              answer: t('faq.myHomeDetails.a1'),
            },
            {
              question: t('faq.myHomeDetails.q2'),
              answer: t('faq.myHomeDetails.a2'),
            },
            {
              question: t('faq.myHomeDetails.q3'),
              answer: t('faq.myHomeDetails.a3'),
            },
            {
              question: t('faq.myHomeDetails.q4'),
              answer: t('faq.myHomeDetails.a4'),
              exclude: true,
            },
            {
              question: t('faq.myHomeDetails.q5'),
              answer: t('faq.myHomeDetails.a5'),
              exclude: true,
            },
            {
              question: t('faq.myHomeDetails.q6'),
              answer: t('faq.myHomeDetails.a6'),
            },
          ],
        },
        {
          title: t('faq.myHomeEstimate.title'),
          list: [
            {
              question: t('faq.myHomeEstimate.q1'),
              answer: t('faq.myHomeEstimate.a1'),
            },
            {
              question: t('faq.myHomeEstimate.q2'),
              answer: t('faq.myHomeEstimate.a2'),
            },
            {
              question: t('faq.myHomeEstimate.q3'),
              answer: t('faq.myHomeEstimate.a3'),
            },
            {
              question: t('faq.myHomeEstimate.q4'),
              answer: t('faq.myHomeEstimate.a4'),
            },
            {
              question: t('faq.myHomeEstimate.q5'),
              answer: t('faq.myHomeEstimate.a5'),
            },
          ],

          id: 'my-home-estimate',
        },
        {
          title: t('faq.myHomeMortgage.title'),
          list: [
            {
              question: t('faq.myHomeMortgage.q1'),
              answer: t('faq.myHomeMortgage.a1'),
            },
            {
              question: t('faq.myHomeMortgage.q2'),
              answer: t('faq.myHomeMortgage.a2'),
            },
            {
              question: t('faq.myHomeMortgage.q3'),
              answer: t('faq.myHomeMortgage.a3'),
              exclude: true,
            },
            {
              question: t('faq.myHomeMortgage.q4'),
              answer: t('faq.myHomeMortgage.a4'),
            },
            {
              question: t('faq.myHomeMortgage.q5'),
              answer: t('faq.myHomeMortgage.a5'),
            },
          ],

          id: 'my-home-mortgage',
        },
        {
          title: t('faq.myInsurance.title'),
          list: [
            {
              question: t('faq.myInsurance.q1'),
              answer: t('faq.myInsurance.a1'),
            },
            {
              question: t('faq.myInsurance.q2'),
              answer: t('faq.myInsurance.a2'),
              exclude: true,
            },
            {
              question: t('faq.myInsurance.q3'),
              answer: t('faq.myInsurance.a3'),
            },
            {
              question: t('faq.myInsurance.q4'),
              answer: t('faq.myInsurance.a4'),
              exclude: true,
            },
          ],
          id: 'my-home-insurance',
        },
      ],
      id: 'my-home',
    },
    {
      title: t('faq.mySearch.title'),
      list: [
        {
          question: t('faq.mySearch.q1'),
          answer: t('faq.mySearch.a1'),
        },
        {
          question: t('faq.mySearch.q2'),
          answer: t('faq.mySearch.a2'),
        },
        {
          question: t('faq.mySearch.q3'),
          answer: t('faq.mySearch.a3'),
        },
        {
          question: t('faq.mySearch.q4'),
          answer: t('faq.mySearch.a4'),
        },
        {
          question: t('faq.mySearch.q5'),
          answer: t('faq.mySearch.a5'),
        },
        {
          question: t('faq.mySearch.q6'),
          answer: t('faq.mySearch.a6'),
        },
        {
          question: t('faq.mySearch.q7'),
          answer: t('faq.mySearch.a7'),
        },
        {
          question: t('faq.mySearch.q8'),
          answer: t('faq.mySearch.a8'),
        },
        {
          question: t('faq.mySearch.q9'),
          answer: t('faq.mySearch.a9'),
        },
      ],

      id: 'my-search',
    },
    {
      title: t('faq.myMove.title'),
      list: [
        {
          question: t('faq.myMove.q1'),
          answer: t('faq.myMove.a1'),
          exclude: false,
        },
        {
          question: t('faq.myMove.q2'),
          answer: t('faq.myMove.a2'),
          exclude: false,
        },
        {
          question: t('faq.myMove.q3'),
          answer: t('faq.myMove.a3'),
          exclude: false,
        },
        {
          question: t('faq.myMove.q4'),
          answer: t('faq.myMove.a4'),
          exclude: false,
        },
        {
          question: t('faq.myMove.q5'),
          answer: t('faq.myMove.a5'),
          exclude: false,
        },
        {
          question: t('faq.myMove.q6'),
          answer: t('faq.myMove.a6'),
          exclude: false,
        },
        {
          question: t('faq.myMove.q7'),
          answer: t('faq.myMove.a7'),
          exclude: false,
        },
      ],
      id: 'myMove',
    },
    {
      title: t('faq.myTransaction.title'),
      list: [
        {
          question: t('faq.myTransaction.q1'),
          answer: t('faq.myTransaction.a1'),
          exclude: false,
        },
        {
          question: t('faq.myTransaction.q2'),
          answer: t('faq.myTransaction.a2'),
          exclude: false,
        },
        {
          question: t('faq.myTransaction.q3'),
          answer: t('faq.myTransaction.a3'),
          exclude: false,
        },
        {
          question: t('faq.myTransaction.q4'),
          answer: t('faq.myTransaction.a4'),
          exclude: false,
        },
        {
          question: t('faq.myTransaction.q5'),
          answer: t('faq.myTransaction.a5'),
          exclude: false,
        },
        {
          question: t('faq.myTransaction.q6'),
          answer: t('faq.myTransaction.a6'),
          exclude: false,
        },
        {
          question: t('faq.myTransaction.q7'),
          answer: t('faq.myTransaction.a7'),
          exclude: false,
        },
      ],
      id: 'myTransaction',
    },
    {
      title: t('faq.myMaintenance.title'),
      list: [
        {
          question: t('faq.myMaintenance.q1'),
          answer: t('faq.myMaintenance.a1'),
          exclude: false,
        },
        {
          question: t('faq.myMaintenance.q2'),
          answer: t('faq.myMaintenance.a2'),
          exclude: false,
        },
        {
          question: t('faq.myMaintenance.q3'),
          answer: t('faq.myMaintenance.a3'),
          exclude: false,
        },
        {
          question: t('faq.myMaintenance.q4'),
          answer: t('faq.myMaintenance.a4'),
          exclude: false,
        },
        {
          question: t('faq.myMaintenance.q5'),
          answer: t('faq.myMaintenance.a5'),
          exclude: false,
        },
      ],
      id: 'myMaintenance',
    },
  ];
};
