import { useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { svgStringToDataUrl } from '../../../services/svgStringToDataUrl';

export const YieldSignIcon = (): JSX.Element => {
  const theme = useTheme();

  const imgSrc = useMemo(
    () =>
      svgStringToDataUrl(
        icon,
        theme.colors.branding.main,
        '#004467',
        theme.colors.branding.main,
        '#003552',
      ),
    [theme],
  );

  return <img src={imgSrc} />;
};

const icon = `<svg width="606" height="98" viewBox="0 0 606 98" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M262 97C244 96.9999 238 45.5 0 44" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linejoin="round"/>
<path d="M348 97C366 96.9999 368 45.5 606 44" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linejoin="round"/>
<path d="M198.5 65C257 53.4999 348.5 55.5 407.5 64.9999" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linejoin="round"/>
<path d="M547.734 27.2092H483.574C484.333 24.4799 486.091 22.1383 488.497 20.6523C489.617 19.9571 490.886 19.5405 492.199 19.4374C493.512 19.3344 494.831 19.5478 496.045 20.0598C496.045 20.0598 496.15 8.66769 509.544 8.0179C519.206 7.54807 522.77 16.4844 522.77 16.4844C522.77 16.4844 524.552 14.6974 529.769 15.4985C531.288 15.7245 532.676 16.4893 533.681 17.6541C534.686 18.8189 535.242 20.3065 535.246 21.8468C535.246 21.8468 538.013 19.3352 542.869 21.4837C547.726 23.6321 547.734 27.2092 547.734 27.2092Z" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linejoin="round"/>
<path d="M482.419 8.1676L485.983 11.7431C485.983 11.7431 487.611 8.29183 490.362 8.22972" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M471 15.2116L473.507 17.1036L476.962 14.9854" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M92.2466 47.628C93.1133 43.9396 97.9694 43.7564 100.676 45.6522C100.676 45.6522 101.444 41.8146 104.931 41.5679C108.418 41.3213 110.587 44.6323 110.587 44.6323C110.587 44.6323 112.65 40.9741 115.242 40.8177C117.834 40.6613 120.017 43.5229 120.017 43.5229C120.017 43.5229 120.069 41.1495 121.999 41.2822C123.93 41.4149 123.809 42.8655 123.809 42.8655C123.809 42.8655 126.842 38.0942 130.323 38.5698C135.716 39.3071 135.246 46.0831 135.246 46.0831C135.246 46.0831 138.277 44.3462 142.482 46.6437C143.259 47.0556 143.939 47.6292 144.476 48.3262C145.013 49.0232 145.395 49.8274 145.597 50.685C148.832 47.1103 155.688 50.9055 153.629 55.4231" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M67.5218 43.3897C67.5218 43.3897 70.0823 44.6737 71.3627 43.3897C72.0934 42.7235 72.5499 41.8078 72.643 40.8218C72.643 40.8218 80.0431 41.0962 81.4826 36.4709C81.818 35.6717 81.9871 34.8123 81.9803 33.9453C81.9734 33.0782 81.7905 32.2216 81.4426 31.4279C81.0946 30.6341 80.589 29.9198 79.9566 29.3285C79.3242 28.7372 78.5784 28.2813 77.7645 27.9888C77.7645 27.9888 79.2568 23.1755 75.8437 20.9269C74.8012 20.2766 73.6215 19.8798 72.3988 19.7683C71.1761 19.6569 69.9445 19.8338 68.8021 20.285C68.8021 20.285 63.2858 12.6577 57.7217 17.7553C56.9712 18.3647 56.3586 19.127 55.924 19.9918C55.4894 20.8566 55.2429 21.8041 55.2008 22.7716C55.1588 23.7391 55.3222 24.7045 55.6802 25.6039C56.0382 26.5034 56.5827 27.3162 57.2776 27.9888C57.2776 27.9888 52.1559 31.8407 54.7166 36.9699C55.371 38.3914 56.4885 39.5471 57.8849 40.2472C59.2813 40.9472 60.8738 41.1499 62.4003 40.8218C62.4003 40.8218 61.707 42.4218 63.9741 43.5477C64.5069 43.8988 65.1366 44.0724 65.7735 44.0441C66.4103 44.0157 67.0222 43.7867 67.5218 43.3897Z" fill="white" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linejoin="round"/>
<path d="M67.3691 55.9998V31.1903" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M70.7222 36.017L67.5479 39.6061" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M63.4422 32.4078L67.0112 35.7541" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M355.626 69.5796C356.006 70.3193 356.578 70.9384 357.28 71.3691C357.982 71.7999 358.787 72.0257 359.606 72.0219C362.634 72.1764 363.271 70.2288 363.271 70.2288C363.271 70.2288 367.795 70.1366 368.401 66.6121C368.572 65.629 368.409 64.6161 367.94 63.7397C367.471 62.8633 366.723 62.1755 365.82 61.7891C365.82 61.7891 365.947 57.2713 363.016 56.2838C362.199 55.9888 361.318 55.9223 360.467 56.0913C359.615 56.2602 358.824 56.6585 358.175 57.2447C358.175 57.2447 354.963 56.4227 353.383 58.1321C351.804 59.8415 352.441 62.4383 352.441 62.4383C351.781 63.0189 351.318 63.7956 351.115 64.659C350.912 65.5224 350.98 66.429 351.309 67.2509C352.44 70.2288 355.626 69.5796 355.626 69.5796Z" fill="white" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M359.745 80V63.6504" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M357.729 67.7438L359.684 69.8687" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M362.047 64.6046L359.956 66.5159" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M253.285 60.7074C253.473 61.0728 253.755 61.3785 254.102 61.5913C254.449 61.804 254.846 61.9156 255.251 61.9137C256.746 61.99 257.061 61.028 257.061 61.028C257.061 61.028 259.295 60.9825 259.595 59.2416C259.679 58.756 259.599 58.2558 259.367 57.8229C259.135 57.39 258.766 57.0503 258.32 56.8594C258.32 56.8594 258.383 54.6279 256.935 54.1402C256.531 53.9945 256.097 53.9616 255.676 54.0451C255.255 54.1285 254.864 54.3253 254.544 54.6148C254.544 54.6148 252.957 54.2088 252.177 55.0531C251.397 55.8974 251.712 57.1801 251.712 57.1801C251.386 57.4668 251.157 57.8505 251.057 58.2769C250.956 58.7034 250.99 59.1512 251.153 59.5572C251.712 61.028 253.285 60.7074 253.285 60.7074Z" fill="white" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M255.319 65.8543V57.7787" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M254.323 59.8007L255.289 60.8502" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M256.456 58.2501L255.423 59.1941" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M447.999 61.7865C447.999 54.3407 441.921 48.3047 434.423 48.3047C426.925 48.3047 420.847 54.3407 420.847 61.7865C420.847 69.2323 426.925 75.2683 434.423 75.2683C441.921 75.2683 447.999 69.2323 447.999 61.7865Z" fill="white" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M434.369 83.6196L434.273 59.5181L434.369 83.6196Z" fill="white"/>
<path d="M434.369 83.6196L434.273 59.5181" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M429.452 66.2937L433.946 69.397L429.452 66.2937Z" fill="white"/>
<path d="M429.452 66.2937L433.946 69.397" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M439.395 63.1184L434.52 65.9766L439.395 63.1184Z" fill="white"/>
<path d="M439.395 63.1184L434.52 65.9766" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linejoin="round"/>
<path d="M414.392 72.3372C411.171 72.3372 408.192 71.3079 405.77 69.5631C396.183 61.1849 400.296 49.7476 405.525 46.1259C405.065 45.0127 404.807 43.7949 404.807 42.5166C404.807 37.2609 409.098 33 414.392 33C419.685 33 423.977 37.2609 423.977 42.5166C423.977 43.7949 423.719 45.0127 423.259 46.1259C426.795 48.7906 429.089 52.9965 429.089 57.7444C429.089 65.8032 422.509 72.3372 414.392 72.3372Z" fill="white" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M411.025 48.3831L414.252 52.1893L417.387 48.3831" fill="white"/>
<path d="M411.025 48.3831L414.252 52.1893L417.387 48.3831" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M408.04 54.683L414.253 59.7955L420.222 54.9191" fill="white"/>
<path d="M408.04 54.683L414.253 59.7955L420.222 54.9191" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M404.67 62.2724L414.253 68.5906L423.743 62.2724" fill="white"/>
<path d="M404.67 62.2724L414.253 68.5906L423.743 62.2724" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M414.368 83.7782V46.4789V83.7782Z" fill="white"/>
<path d="M414.368 83.7782V46.4789" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M177.001 63.9247C177.001 58.4434 181.475 54 186.995 54C192.514 54 196.989 58.4434 196.989 63.9247C196.989 69.4059 192.514 73.8493 186.995 73.8493C181.475 73.8493 177.001 69.4059 177.001 63.9247Z" fill="white" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M187.035 79.9971L187.105 62.2548L187.035 79.9971Z" fill="white"/>
<path d="M187.035 79.9971L187.105 62.2548" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M190.654 67.2426L187.345 69.5271L190.654 67.2426Z" fill="white"/>
<path d="M190.654 67.2426L187.345 69.5271" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M183.334 64.9052L186.923 67.0093L183.334 64.9052Z" fill="white"/>
<path d="M183.334 64.9052L186.923 67.0093" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linejoin="round"/>
<path d="M215.445 61.5092C215.445 64.4941 214.263 67.3567 212.158 69.4674C210.054 71.578 207.199 72.7637 204.223 72.7637C201.246 72.7637 198.392 71.578 196.287 69.4674C194.182 67.3567 193 64.4941 193 61.5092C193 55.2934 198.025 39 204.223 39C210.421 39 215.445 55.2934 215.445 61.5092Z" fill="white" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linejoin="round"/>
<path d="M204.058 79.3931L203.971 58.5274" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M199.838 64.3905L203.689 67.0752" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M208.364 61.6387L204.18 64.1188" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M298.5 97C278.5 89 256.5 70 307.5 64.5" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linejoin="round"/>
<path d="M277.5 97C267 90 246 71.5 296 64" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linejoin="round" stroke-dasharray="3 4"/>
<path d="M262 97C262 97 226.5 71 290 63" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linejoin="round"/>
<path d="M310 61.0002C310 61.0002 339.5 61.0002 333 58" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linejoin="round"/>
<path d="M316 63.5C316 63.5 343 62 335 58" stroke="#003552" stroke-opacity="0.4" stroke-width="0.5" stroke-linejoin="round"/>
<rect x="300" width="10" height="97" fill="#004467" fill-opacity="0.2"/>
<circle cx="305" cy="4" r="1" fill="white"/>
<circle cx="305" cy="84" r="1" fill="white"/>
<circle cx="305" cy="91" r="1" fill="white"/>
<circle cx="305" cy="97" r="1" fill="white"/>
<mask id="path-57-outside-1_3597_4539" maskUnits="userSpaceOnUse" x="260.41" y="6.10419" width="88" height="78" fill="black">
<rect fill="white" x="260.41" y="6.10419" width="88" height="78"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M346.417 18.054L310.73 79.8654C307.92 84.732 300.896 84.732 298.086 79.8654L262.399 18.054C259.59 13.1874 263.102 7.10419 268.721 7.10419H340.095C345.714 7.10419 349.226 13.1874 346.417 18.054ZM343.651 13.821C343.376 12.1841 341.974 10.8041 340.095 10.8041H268.721C267.336 10.8041 266.21 11.5541 265.604 12.6041C266.21 11.5542 267.336 10.8042 268.721 10.8042H340.095C341.974 10.8042 343.376 12.1842 343.651 13.821Z"/>
</mask>
<path fill-rule="evenodd" clip-rule="evenodd" d="M346.417 18.054L310.73 79.8654C307.92 84.732 300.896 84.732 298.086 79.8654L262.399 18.054C259.59 13.1874 263.102 7.10419 268.721 7.10419H340.095C345.714 7.10419 349.226 13.1874 346.417 18.054ZM343.651 13.821C343.376 12.1841 341.974 10.8041 340.095 10.8041H268.721C267.336 10.8041 266.21 11.5541 265.604 12.6041C266.21 11.5542 267.336 10.8042 268.721 10.8042H340.095C341.974 10.8042 343.376 12.1842 343.651 13.821Z" fill="white"/>
<path d="M310.73 79.8654L310.99 80.0154L310.73 79.8654ZM346.417 18.054L346.157 17.904L346.417 18.054ZM298.086 79.8654L298.346 79.7154L298.086 79.8654ZM262.399 18.054L262.139 18.204L262.399 18.054ZM343.651 13.821L343.355 13.8707C343.383 14.0341 343.537 14.1443 343.701 14.1168C343.864 14.0893 343.974 13.9346 343.947 13.7712L343.651 13.821ZM265.604 12.6041L265.344 12.4541C265.261 12.5976 265.31 12.781 265.454 12.8639C265.597 12.9467 265.78 12.8976 265.863 12.7541L265.604 12.6041ZM310.99 80.0154L346.676 18.204L346.157 17.904L310.47 79.7154L310.99 80.0154ZM297.826 80.0154C300.751 85.082 308.064 85.082 310.99 80.0154L310.47 79.7154C307.776 84.382 301.04 84.382 298.346 79.7154L297.826 80.0154ZM262.139 18.204L297.826 80.0154L298.346 79.7154L262.659 17.904L262.139 18.204ZM268.721 6.80419C262.871 6.80419 259.214 13.1374 262.139 18.204L262.659 17.904C259.965 13.2374 263.333 7.40418 268.721 7.40418V6.80419ZM340.095 6.80419H268.721V7.40418H340.095V6.80419ZM346.676 18.204C349.602 13.1374 345.945 6.80419 340.095 6.80419V7.40418C345.483 7.40418 348.851 13.2374 346.157 17.904L346.676 18.204ZM340.095 11.1041C341.816 11.1041 343.102 12.3661 343.355 13.8707L343.947 13.7712C343.65 12.0022 342.133 10.5041 340.095 10.5041V11.1041ZM268.721 11.1041H340.095V10.5041H268.721V11.1041ZM265.863 12.7541C266.42 11.7898 267.451 11.1041 268.721 11.1041V10.5041C267.22 10.5041 265.999 11.3184 265.344 12.4541L265.863 12.7541ZM268.721 10.5042C267.22 10.5042 265.999 11.3184 265.344 12.4541L265.863 12.7541C266.42 11.7899 267.451 11.1042 268.721 11.1042V10.5042ZM340.095 10.5042H268.721V11.1042H340.095V10.5042ZM343.947 13.7712C343.649 12.0022 342.133 10.5042 340.095 10.5042V11.1042C341.816 11.1042 343.102 12.3661 343.355 13.8707L343.947 13.7712Z" fill="white" mask="url(#path-57-outside-1_3597_4539)"/>
<path d="M310.73 79.8654L310.99 80.0154L310.73 79.8654ZM346.417 18.054L346.157 17.904L346.417 18.054ZM298.086 79.8654L298.346 79.7154L298.086 79.8654ZM262.399 18.054L262.139 18.204L262.399 18.054ZM343.651 13.821L343.355 13.8707C343.383 14.0341 343.537 14.1443 343.701 14.1168C343.864 14.0893 343.974 13.9346 343.947 13.7712L343.651 13.821ZM265.604 12.6041L265.344 12.4541C265.261 12.5976 265.31 12.781 265.454 12.8639C265.597 12.9467 265.78 12.8976 265.863 12.7541L265.604 12.6041ZM310.99 80.0154L346.676 18.204L346.157 17.904L310.47 79.7154L310.99 80.0154ZM297.826 80.0154C300.751 85.082 308.064 85.082 310.99 80.0154L310.47 79.7154C307.776 84.382 301.04 84.382 298.346 79.7154L297.826 80.0154ZM262.139 18.204L297.826 80.0154L298.346 79.7154L262.659 17.904L262.139 18.204ZM268.721 6.80419C262.871 6.80419 259.214 13.1374 262.139 18.204L262.659 17.904C259.965 13.2374 263.333 7.40418 268.721 7.40418V6.80419ZM340.095 6.80419H268.721V7.40418H340.095V6.80419ZM346.676 18.204C349.602 13.1374 345.945 6.80419 340.095 6.80419V7.40418C345.483 7.40418 348.851 13.2374 346.157 17.904L346.676 18.204ZM340.095 11.1041C341.816 11.1041 343.102 12.3661 343.355 13.8707L343.947 13.7712C343.65 12.0022 342.133 10.5041 340.095 10.5041V11.1041ZM268.721 11.1041H340.095V10.5041H268.721V11.1041ZM265.863 12.7541C266.42 11.7898 267.451 11.1041 268.721 11.1041V10.5041C267.22 10.5041 265.999 11.3184 265.344 12.4541L265.863 12.7541ZM268.721 10.5042C267.22 10.5042 265.999 11.3184 265.344 12.4541L265.863 12.7541C266.42 11.7899 267.451 11.1042 268.721 11.1042V10.5042ZM340.095 10.5042H268.721V11.1042H340.095V10.5042ZM343.947 13.7712C343.649 12.0022 342.133 10.5042 340.095 10.5042V11.1042C341.816 11.1042 343.102 12.3661 343.355 13.8707L343.947 13.7712Z" fill="#004467" fill-opacity="0.4" mask="url(#path-57-outside-1_3597_4539)"/>
<path d="M301.291 78.0155C302.676 80.4154 306.14 80.4154 307.526 78.0155L343.213 16.2041C344.598 13.8041 342.866 10.8042 340.095 10.8042H268.722C265.95 10.8042 264.218 13.8041 265.604 16.2041L301.291 78.0155Z" fill="#004467"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M310.73 79.8654L346.416 18.054C349.226 13.1875 345.714 7.10424 340.095 7.10424H268.721C263.101 7.10424 259.589 13.1875 262.399 18.054L298.086 79.8654C300.896 84.732 307.92 84.732 310.73 79.8654ZM307.525 78.0155C306.14 80.4154 302.676 80.4154 301.29 78.0155L265.603 16.2041C264.218 13.8041 265.95 10.8042 268.721 10.8042H340.095C342.866 10.8042 344.598 13.8041 343.212 16.2041L307.525 78.0155Z" fill="white"/>
<path d="M301.902 53.5001C303.057 55.5001 305.943 55.5 307.098 53.5001L322.254 27.2499C323.408 25.25 321.965 22.75 319.656 22.75H289.344C287.035 22.75 285.592 25.25 286.746 27.2499L301.902 53.5001Z" fill="white"/>
<path d="M295.927 32.642L294.657 32.38L296.491 29.2687H297.99L295.927 32.642ZM294.657 35V32.249H295.976V35H294.657ZM294.698 32.642L292.635 29.2687H294.133L295.967 32.38L294.698 32.642ZM298.596 35V29.2687H299.914V35H298.596ZM301.027 35V29.2687H304.76V30.4804H302.345V31.5284H304.367V32.6993H302.345V33.7882H304.801V35H301.027ZM305.832 35V29.2687H307.15V33.7882H309.59V35H305.832ZM310.294 35V29.2687H312.169C312.774 29.2687 313.309 29.3915 313.773 29.6371C314.243 29.8827 314.608 30.2212 314.87 30.6524C315.132 31.0781 315.263 31.5721 315.263 32.1343C315.263 32.6911 315.132 33.1851 314.87 33.6163C314.608 34.0475 314.243 34.3859 313.773 34.6316C313.309 34.8772 312.774 35 312.169 35H310.294ZM311.612 33.7555H312.169C312.42 33.7555 312.652 33.7173 312.864 33.6409C313.077 33.559 313.263 33.4471 313.421 33.3052C313.58 33.1578 313.702 32.9858 313.79 32.7893C313.877 32.5874 313.921 32.369 313.921 32.1343C313.921 31.8996 313.877 31.684 313.79 31.4875C313.702 31.2855 313.58 31.1136 313.421 30.9717C313.263 30.8243 313.077 30.7124 312.864 30.636C312.652 30.5541 312.42 30.5132 312.169 30.5132H311.612V33.7555Z" fill="#004467" fill-opacity="0.3"/>
</svg>
`;
