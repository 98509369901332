import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useBanner } from '../../../../providers/BannerProvider';

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const Image = styled('div')<{ src?: string }>(({ src }) => ({
  borderRadius: '50%',
  height: 40,
  width: 40,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundImage: `url(${src})`,
  flexShrink: 0,
  marginRight: 12,
}));

const TextWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const ProvidedBy = styled('span')({
  fontSize: 9,
  fontWeight: 300,
  textTransform: 'uppercase',
  marginBottom: 2,
  color: 'inherit',
});

const AgentName = styled('span')({
  fontWeight: 600,
  fontSize: 12,
  color: 'inherit',
});

export const Agent: React.FC = (props) => {
  const { t } = useTranslation();

  const {
    agent: { name, photo },
  } = useBanner();

  return (
    <Container {...props}>
      <Image src={photo} />
      <TextWrapper>
        <ProvidedBy>{t('navigation.providedBy')}</ProvidedBy>
        <AgentName data-qa="agent-name">{name}</AgentName>
      </TextWrapper>
    </Container>
  );
};
