import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export function useSetDocumentTitle(translationKey) {
  const { t } = useTranslation();

  useEffect(
    function onTitleChange() {
      document.title = t(translationKey);
    },
    [translationKey, t],
  );
}
