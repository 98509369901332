import styled from '@emotion/styled';

const Page = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
});

const Main = styled('div')(({ theme }) => ({
  width: '90%',
  maxWidth: '965px',
  margin: '0 auto',
  border: `1px solid ${theme.colors.grey.main}`,
  borderRadius: theme.borderRadius.lgRem,
  backgroundColor: theme.colors.white,
  padding: '5rem 8rem 51rem 8rem',
  '@media all and (max-width: 980px)': {
    padding: '3rem 4rem 51rem 4rem',
  },
}));

const Title = styled('div')(({ theme }) => ({
  fontSize: '4.8rem',
  fontWeight: theme.fontWeight.bold,
  color: theme.colors.black,
  lineHeight: '5rem',
  width: '90%',
  textAlign: 'center',
  margin: '2.8rem auto 0 auto',
  maxWidth: '70rem',
}));

const GetAppWrapper = styled('div')({
  marginTop: '3.3rem',
  marginBottom: '2.8rem',
});

const FooterWrapper = styled('div')({
  marginTop: '-51rem',
  flexGrow: 1,
  position: 'relative',
});

const ImagesContainer = styled('div')({
  marginBottom: '-36rem',
  position: 'absolute',
  left: '50%',
  top: '0%',
  transform: 'translateX(-50%)',
});

export default {
  Page,
  Main,
  Title,
  GetAppWrapper,
  FooterWrapper,
  ImagesContainer,
};
