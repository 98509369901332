import { AgentDetails } from '../types';
import { useAgentDetails } from './query/useAgentDetails';

export const useAgentPhoneNumber = () => {
  const { data: agent } = useAgentDetails();

  return getAgentPhoneNumber(agent);
};

export function getAgentPhoneNumber(agent?: AgentDetails) {
  const contact = agent?.AgentCommunication;

  if (!contact) return;

  if (contact.showWork && contact.phoneWork) return contact.phoneWork;

  if (contact.showMobile && contact.phoneMobile) return contact.phoneMobile;

  return undefined;
}
