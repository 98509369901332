import { ListingDetails, TAdditionalFilter } from '../../../../../types';

export const UUID = 'more';

type FiltersMap = Record<TAdditionalFilter, boolean>;

export const getAdditionalFiltersIndicators = (
  listing: ListingDetails,
): FiltersMap => {
  return {
    'New Construction Only': Boolean(
      listing.AdditionalDetails.newConsructionInd,
    ),
    'No HOA Fees': Boolean(listing?.AdditionalDetails?.hoaInd),
    View: Boolean(listing?.AdditionalDetails?.viewInd),
    Pool: Boolean(listing?.AdditionalDetails?.poolInd),
    Waterfront: Boolean(listing?.AdditionalDetails?.waterFrontInd),
    Furnished: Boolean(listing?.AdditionalDetails?.furnishedInd),
    Basement: Boolean(listing?.ListingSummary?.basementInd),
    'Golf Course': Boolean(listing?.AdditionalDetails?.golfCourseInd),
  };
};

export function filter(
  listing: ListingDetails,
  filter: { preferences: 'true' | 'false'; value: TAdditionalFilter[] },
): boolean {
  if (!filter) return true;

  const isPreferences = filter.preferences
    ? JSON.parse(filter.preferences)
    : false;

  const listingFeatures = getAdditionalFiltersIndicators(listing);

  if (isPreferences) {
    return filter.value.some((feature) => listingFeatures[feature]);
  }

  return filter.value.every((feature) => listingFeatures[feature]);
}
