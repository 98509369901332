import React, { useCallback } from 'react';
import { useInView } from 'react-intersection-observer';
import { GoogleMap as GoogleMapComponent } from '@react-google-maps/api';

import LoadingIndicator from '../../loading-indicator';
import { useGoogleMap } from '../../../utils/google-map-provider';

interface MapProps {
  center?: {
    lat: number;
    lng: number;
  };
  mapOptions: {
    fullscreenControl?: boolean;
    mapTypeControl?: boolean;
    streetViewControl?: boolean;
    gestureHandling?: string;
    disableDefaultUI?: boolean;
    draggable?: boolean;
    mapTypeControlOptions?: {
      mapTypeIds?: string[];
    };
  };
  zoom?: number;
  handleOnLoad?: (map: google.maps.Map) => void;
  onClick?: () => void;
  loader?: boolean;
  handleBoundsChanged?: () => void;
  onDragEnd?: () => void;
  onCenterChanged?: () => void;
}

export const GoogleMap: React.FC<MapProps> = ({
  children,
  center,
  mapOptions,
  zoom,
  handleOnLoad,
  onClick,
  loader = true,
  handleBoundsChanged,
  onDragEnd,
  onCenterChanged,
}) => {
  const { mapIsLoaded, setLastZoomLevel } = useGoogleMap();

  const onMapLoad = React.useCallback(
    (map) => {
      handleOnLoad && handleOnLoad(map);
    },
    [handleOnLoad],
  );

  const onZoomChanged = useCallback(
    function () {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const mapRef = this;

      if (!mapRef) return;
      setLastZoomLevel(mapRef.zoom);
    },
    [setLastZoomLevel],
  );

  const { ref, inView } = useInView({
    fallbackInView: true,
    triggerOnce: true,
    rootMargin: '500px',
  });

  if (!mapIsLoaded || !inView) {
    return <div ref={ref}>{loader && <LoadingIndicator />}</div>;
  }

  return (
    <GoogleMapComponent
      mapContainerStyle={{
        height: '100%',
        width: '100%',
      }}
      center={center}
      zoom={zoom}
      options={mapOptions}
      onLoad={onMapLoad}
      onClick={onClick}
      onBoundsChanged={handleBoundsChanged}
      onDragEnd={onDragEnd}
      onCenterChanged={onCenterChanged}
      onZoomChanged={onZoomChanged}
    >
      {children}
    </GoogleMapComponent>
  );
};
