import styled from '@emotion/styled';

import { WithActive } from '../../../../types';
import { FlexRow } from '../../../../components/V2/Flex/Flex';

export const StyledRow = styled(FlexRow)({
  width: '100%',
});

export const NotificationWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  cursor: 'pointer',
});

export const NotificationContentWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const NotificationCategoryIcon = styled('div')({
  width: '5.5rem',
  height: '5.5rem',
  marginRight: '1.4rem',
  '.agentImage': {
    borderRadius: 0,
    width: '5.5rem',
    height: '5.5rem',
  },
});

export const TypeIcon = styled('div')({
  left: '3.3rem',
  bottom: '2rem',
  width: 'fit-content',
  position: 'relative',
});

export const NotificationTitle = styled('div')<WithActive>(
  ({ theme, active }) => ({
    width: '100%',
    display: 'flex',
    fontSize: '1.2rem',
    lineHeight: '1.4rem',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    fontWeight: theme.fontWeight.bold,
    color: active ? theme.colors.black : theme.colors.grey.dark15,
  }),
);

export const NotificationContent = styled('div')<WithActive>(
  ({ theme, active }) => ({
    width: '100%',
    fontSize: '1.2rem',
    lineHeight: '1.4rem',
    color: active ? theme.colors.black : theme.colors.grey.dark15,
  }),
);

export const TimeLabel = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  fontSize: '1rem',
  lineHeight: '1.2rem',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: theme.colors.grey.dark15,
}));

export const UnreadIndicator = styled('div')({
  display: 'flex',
  width: '1.2rem',
  marginLeft: '0.8rem',
  alignItems: 'center',
});
