import {
  ListingDetails,
  TSaleListingStatusValue,
  TSaleType,
} from '../../../../../types';
import { statusOptionValuesMap } from './statusOptionValuesMap';

type TSoldListingStatusValue =
  | 'last7Days'
  | 'last1Month'
  | 'last3Months'
  | 'last6Months'
  | 'last1Year'
  | 'last2Years';

type TSaleOptionValue =
  | TSoldListingStatusValue
  | TSaleListingStatusValue
  | undefined;

export const UUID = 'sale';

const defaultFilterConfig: Record<TSaleType, TSaleOptionValue> = {
  sale: 'comingSoonAndActive',
  rent: undefined,
  sold: 'last3Months',
};

function getOption(saleType: TSaleType, option: TSaleOptionValue | string) {
  if (saleType === 'sale') {
    const key = (option as TSaleListingStatusValue) ?? defaultFilterConfig.sale;

    return statusOptionValuesMap[key];
  }

  if (saleType === 'sold') {
    return (option as TSoldListingStatusValue) ?? defaultFilterConfig.sold;
  }

  return undefined;
}

function subPeriodFromNow(
  value: number,
  period: 'day' | 'month' | 'year',
): number {
  if (period === 'year') {
    return new Date(
      new Date().setFullYear(new Date().getFullYear() - value),
    ).getTime();
  }

  if (period === 'month') {
    return new Date(
      new Date().setMonth(new Date().getMonth() - value),
    ).getTime();
  }

  return new Date(new Date().setDate(new Date().getDate() - value)).getTime();
}

const soldDateValues = {
  last7Days: subPeriodFromNow(7, 'day'),
  last1Month: subPeriodFromNow(1, 'month'),
  last3Months: subPeriodFromNow(3, 'month'),
  last6Months: subPeriodFromNow(6, 'month'),
  last1Year: subPeriodFromNow(1, 'year'),
  last2Years: subPeriodFromNow(2, 'year'),
};

export function filter(
  listing: ListingDetails,
  sale?: { type: TSaleType; option?: TSaleListingStatusValue | string },
): boolean {
  const saleType = sale?.type ?? 'sale';
  const option = getOption(saleType, sale?.option);

  if (saleType === 'rent' || !option) return true;

  if (option?.includes('all')) {
    return listing?.ListingSummary?.listingStatus !== 'Sold';
  }

  if (saleType === 'sale') {
    return option.includes(listing?.ListingSummary.listingStatus.toLowerCase());
  }

  if (!listing.SalePrice.saleDate) return false;

  return (
    new Date(listing.SalePrice.saleDate).getTime() >
    soldDateValues[option as TSoldListingStatusValue]
  );
}
