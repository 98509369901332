import styled from '@emotion/styled';

import { colorOpacityApplier } from '../../../../services/colorOpacityApplier';

export const SubNav = styled.div`
  display: flex;
  padding-top: 0.6rem;
  flex-direction: row;
  align-items: center;

  transform: translateY(0);
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
  -moz-transform: translateY(0);
  -webkit-transform: translateY(0);

  transition: 0.5s all ease;
  justify-content: space-around;
  box-shadow: 0 -0.4rem 1.2rem #0000000b;
  background: ${({ theme }) =>
    `linear-gradient(0deg, ${colorOpacityApplier(
      theme.colors.branding.main,
      '0.05',
    )}, ${colorOpacityApplier(theme.colors.branding.main, '0.05')}), #FFFFFF`};
  &.collapsed {
    transform: translateY(5.5rem);
    -o-transform: translateY(5.5rem);
    -ms-transform: translateY(5.5rem);
    -moz-transform: translateY(5.5rem);
    -webkit-transform: translateY(5.5rem);
  }

  @media (min-width: 769px) {
    &.collapsed {
      transform: 'none';
      -o-transform: 'none';
      -ms-transform: 'none';
      -moz-transform: 'none';
      -webkit-transform: 'none';
    }
    gap: 4.8rem;
    align-items: center;
    mix-blend-mode: normal;
    padding: 2.14rem 3.4rem;
    justify-content: flex-end;
    box-shadow: 0 0.4rem 1.2rem #0000000b;
    background: ${({ theme }) =>
      `linear-gradient(0deg, ${colorOpacityApplier(
        theme.colors.branding.main,
        '0.05',
      )}, ${colorOpacityApplier(
        theme.colors.branding.main,
        '0.05',
      )}), #FFFFFF`};
  }
`;
