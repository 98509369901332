import { useQuery, UseQueryResult } from 'react-query';

import { hopApi } from '../../../api/hop';
import { apiRoutes } from '../../../constants';
import { useDomainWithSubdomain } from '../../../hooks/useDomain';
export interface BrandingAndAgent {
  brandingColor: string;
  logo: string;
  agentName: string;
  agentOffice: string;
  agentHeadshot: string;
  agentContactLink: string;
  iosLink: string;
  androidLink: string;
}

const fetchAgentBranding = async (
  domain: string,
): Promise<BrandingAndAgent> => {
  const response = await hopApi.get<BrandingAndAgent>(
    apiRoutes.branding.initialBrandingDetails(),
    {
      params: {
        domain,
      },
    },
  );

  const data = response.data;

  return data;
};

export function useBrandingAndAgent(): UseQueryResult<BrandingAndAgent> {
  const url = useDomainWithSubdomain();
  return useQuery(['branding-and-agent', url], () => fetchAgentBranding(url));
}
