import { hsla } from 'polished';
import { useQuery, UseQueryResult } from 'react-query';

import { hopApi } from '../../api/hop';
import { apiRoutes } from '../../constants';

import { BrandingDetails, BrandingDetailsExtended } from '../../types';
import { useTokenData } from '../../providers/Authentication/useTokenData';

function getThemeColor(companyDetails: BrandingDetails) {
  const fallbackColor =
    companyDetails?.AdditionalColors?.brandColor || '#0065A8';
  if (!companyDetails.themeColor) {
    return fallbackColor;
  }

  try {
    const themeColorHsla = JSON.parse(companyDetails.themeColor);

    return hsla(
      themeColorHsla.h,
      themeColorHsla.s,
      themeColorHsla.l,
      themeColorHsla.a,
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(
      'ThemeColor has invalid JSON structure! ',
      companyDetails.themeColor,
    );

    return fallbackColor;
  }
}

const fetchBrandingDetails = async (
  agentBranding: boolean,
): Promise<BrandingDetailsExtended> => {
  const response = await hopApi.get(apiRoutes.branding.getByDomain(), {
    params: { agentBranding: agentBranding ? 1 : undefined },
  });

  response.data.themeColorHex = getThemeColor(response.data);
  // response.data.themeColorHex = '#ff0000';

  return response.data;
};

export function useBrandingDetails(
  agentBranding?: true,
): UseQueryResult<BrandingDetailsExtended> {
  const { data: tokenData } = useTokenData();
  return useQuery(
    ['brandingDetails', agentBranding],
    () => fetchBrandingDetails(Boolean(agentBranding)),
    {
      enabled: Boolean(tokenData?.accountId),
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    },
  );
}
