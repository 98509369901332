import { useTheme } from '@emotion/react';

type Props = {
  color?: string;
};

export const ReducedPriceIcon = ({ color }: Props): JSX.Element => {
  const theme = useTheme();
  const fillColor = color || theme.colors.branding.main;
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="1.5rem"
      height="1.5rem"
      viewBox="0 0 91.1 88.5"
      xmlSpace="preserve"
      fill={fillColor}
    >
      <g>
        <g>
          <path
            d="M26.4,11.9c0-2.4,0-4.9,0-7.5c0-2.2,1.6-3.3,3.6-2.7c1,0.3,1.6,1.2,1.6,2.5c0,2.3,0,4.5,0,6.8c0,0.4,0,0.8,0,1.3
			c1.9,0.3,3.7,0.4,5.5,0.8c2.8,0.5,5.5,1,8.3,1.6c1.1,0.2,2,0.8,2.2,2.1c0.2,1.1-0.1,2.1-1,2.5c-0.8,0.4-1.9,0.5-2.7,0.4
			c-3.5-0.5-6.9-1.4-10.4-1.8C28,17.2,22.4,17,17,19.1c-5.5,2.1-7.2,7.3-6.5,11.7c0.3,2,1.4,3.4,2.9,4.5c2.9,2.2,6.2,3.4,9.6,4.5
			c4.8,1.4,9.7,2.6,14.5,4.1c2.7,0.8,5.3,1.9,7.7,3.3c5.1,2.8,8,7.2,7.6,13.1c-0.3,5.9-3.3,10.3-8.6,12.9c-3.6,1.8-7.5,2.6-11.6,2.8
			c-0.9,0-1.1,0.3-1.1,1.2c0,2.3,0,4.6,0,6.9c0,1.8-1,2.8-2.6,2.8c-1.6,0-2.6-1.1-2.6-2.8c0-2.3-0.1-4.6,0-6.9c0-1-0.4-1.3-1.3-1.4
			c-5.9-0.7-11.7-1.9-17.3-4.2c-0.7-0.3-1.4-0.8-2-1.3c-0.8-0.7-0.9-2-0.4-2.9c0.6-1,1.6-1.5,2.7-1.2c2.5,0.7,4.9,1.7,7.4,2.4
			c6.1,1.7,12.3,2.8,18.6,2.1c3.2-0.3,6.3-1.1,9-2.9c4.2-2.8,5.1-6.4,4.4-10.4c-0.4-2.7-2.3-4.4-4.5-5.6c-4.7-2.5-9.7-3.9-14.8-5.2
			c-5.6-1.4-11.2-2.8-16.3-5.9C5.7,37,3.4,29.8,6.5,22.5c2.2-5.4,6.5-8,11.8-9.3C20.8,12.5,23.5,12.3,26.4,11.9z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M65.6,44.7c0-0.5,0-0.9,0-1.4c0-20.2,0-8.4,0-28.7c0-2.1,0.6-3,2.2-3.3c1.5-0.3,2.8,0.7,3,2.2c0,0.4,0,0.9,0,1.3
			c0,20.2,0,8.3,0,28.5c0,0.4,0,0.9,0,1.6c0.5-0.4,0.8-0.7,1-0.9c3.5-3.4,6.9-6.8,10.4-10.3c1.3-1.2,2.7-1.3,3.8-0.2
			c1,1,1.1,2.3,0.1,3.4c-0.5,0.6-1.1,1.2-1.7,1.8c-4.7,4.7-9.3,9.4-14,14c-1.7,1.7-2.8,1.7-4.5,0c-5.1-5.1-10.2-10.2-15.3-15.3
			c-0.8-0.8-1.4-1.6-1-2.8c0.3-1,1.1-1.7,2.1-1.9c1-0.2,1.8,0.3,2.5,1c3.4,3.3,6.8,6.7,10.2,10c0.3,0.3,0.5,0.7,0.7,1
			C65.3,44.9,65.4,44.8,65.6,44.7z"
          />
        </g>
      </g>
    </svg>
  );
};
