import { isArray, isString } from 'lodash';

export const getChildText = (children) => {
  if (isString(children)) {
    return children;
  }

  if (isArray(children)) {
    const childrenText = [];
    children.forEach((c) => {
      if (isString(c)) {
        childrenText.push(c);
      }
    });

    if (childrenText.length > 0) {
      return childrenText.join(' ');
    }
  }

  return null;
};

export const onClickWrapper = (props) => {
  const { event, onClick } = props;

  let executeOnClick = false;
  if (event.type === 'click') {
    executeOnClick = true;
  }
  if (event.type === 'keypress') {
    const code = event.charCode || event.keyCode;
    if (code === 13 /* enter */ || code === 32 /* spacebar */) {
      executeOnClick = true;
    }
  }

  if (executeOnClick) {
    onClick(event);
  }
};

export const onClickProps = (props) => {
  const { onClick, ariaLabelOverride, children } = props;

  const clickProps = {};
  if (onClick) {
    clickProps.onClick = (event) => onClickWrapper({ event, onClick });
    clickProps.onKeyPress = (event) => onClickWrapper({ event, onClick });
    clickProps.tabIndex = 0;
  }

  if (children) {
    clickProps['aria-label'] = ariaLabelOverride || getChildText(children);
  }

  return clickProps;
};
