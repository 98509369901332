import { useQuery, UseQueryResult } from 'react-query';

import { useKey } from './KeyProvider';
import { hopApi, setHopAccountIdHeader } from '../../api/hop';
import { apiRoutes } from '../../constants';
import { setAccountIdHeader } from '../../api';

export interface TokenData {
  contactId?: number;
  accountId?: number;
  accountIdRaw?: number;
  agentId?: number | string;
}

const dummyStructure = {
  contactId: undefined,
  accountId: undefined,
  agentId: undefined,
};

const fetchDecodedToken = async (token?: string): Promise<TokenData> => {
  if (!token) {
    return dummyStructure;
  }

  const { data } = await hopApi.post(`${apiRoutes.auth.keyDecode()}/${token}`);

  const accountId = Number.parseInt(data?.accountId, 10);
  setHopAccountIdHeader(accountId);
  setAccountIdHeader(accountId);

  return {
    accountId,
    contactId: Number.parseInt(data?.contactId, 10),
    accountIdRaw: Number.parseInt(data?.accountId, 10),
    agentId: Number.parseInt(data?.userId, 10),
  };
};

export function useTokenData(): UseQueryResult<TokenData> {
  const { key } = useKey();

  return useQuery(['decodeToken', key], () => fetchDecodedToken(key), {
    staleTime: Infinity,
  });
}
