import { useQuery, useQueryClient, UseQueryResult } from 'react-query';

import { Season } from '../type';
import api from '../../../api';
import { seasonsKeyLS } from './data';
import { apiRoutes } from '../../../constants';

async function fetchSeasons(): Promise<Season[]> {
  const response = await api.get(
    apiRoutes.myJourney.myMaintenance.getSeasons(),
  );

  return response.data;
}

const useSeasons = (): UseQueryResult<Season[]> => {
  return useQuery(seasonsKeyLS, () => fetchSeasons(), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
};

type InvalidateFn = () => void;

export function useInvalidateSeasons(): InvalidateFn {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries([seasonsKeyLS], {
      refetchActive: true,
      refetchInactive: true,
    });
  };
}

export default useSeasons;
