import { useHomeownerPropertyDetails } from '../../../hooks/query/useHomeownerPropertyDetails';

type TCoordinates = {
  lat: number;
  lon: number;
};

export function useHomeownerPropertyCoordinates(): TCoordinates {
  const { data: homeownerProperty } = useHomeownerPropertyDetails();

  return {
    lat: Number(homeownerProperty?.Location?.latitude) || 0,
    lon: Number(homeownerProperty?.Location?.longitude) || 0,
  };
}
