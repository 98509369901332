import { useEffect } from 'react';

import { useAgentDetails } from '../../hooks/query/useAgentDetails';
import { useTokenData } from '../../providers/Authentication/useTokenData';

export function useGAInit(): void {
  const { data: agentData, isLoading: agentDataLoading } = useAgentDetails();

  const { data: tokenData, isLoading: tokenLoading } = useTokenData();

  useEffect(() => {
    if (tokenLoading) return;

    window.dataLayer?.push?.({
      event: 'accountNamePageLoad',
      accountName: tokenData?.accountId,
    });
  }, [tokenData?.accountId, tokenLoading]);

  useEffect(() => {
    if (agentDataLoading) return;

    const agentFullName = `${agentData?.Name.firstName} ${agentData?.Name.lastName}`;

    window.dataLayer?.push?.({
      event: 'agentNamePageLoad',
      agentName: agentFullName,
    });
  }, [agentData?.Name.firstName, agentData?.Name.lastName, agentDataLoading]);
}
