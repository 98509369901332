import { toast, ToastContainer, ToastOptions } from 'react-toastify';

const initSetup: ToastOptions = {
  position: 'bottom-left',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

function ToastComponent(): JSX.Element {
  return (
    <ToastContainer
      position="bottom-left"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
}

class ToastActions {
  success(message: string) {
    toast.success(message, initSetup);
  }

  error(message: string) {
    toast.error(message, initSetup);
  }
}

export const toastActions = new ToastActions();

export default ToastComponent;
