import { useMemo } from 'react';
import { useListingDetailsByParams } from '../../../../../../hooks/query/useListingDetailsByParams';
import { ListingDetails } from '../../../../../../types';

const videoDomains = [
  'dropbox.com',
  'google.com',
  'immoviewer.com',
  'paradym.com',
  'show.tours',
  'swift-pix.com',
  'vht.com',
  'view.property',
  'vistabee.com',
  'youtube.com',
  'vimeo.com',
  'listing.realestateshows.com',
  'hshprodmls2.com',
  'tours.gregfrostphotos.com',
  'jumpvisualtours.com',
  'vhtbucket.s3.amazonaws.com',
  'gallery.onefinedayrealestate.com',
];

const vTourDomains = [
  'linktr.ee',
  'matterport.com',
  'miamirealestatephotographers.com',
  'nodalview.com',
  'propertypanorama.com',
  'zillow.com',
  'lohorealty.com',
  '3dapartment.com',
];

type TVideo3DTourLinks = {
  videoLink?: string | null;
  vTourLink?: string | null;
};

export const hasListing3DTour = (listing?: ListingDetails): boolean => {
  const { brandedVirtualTour, unBrandedVirtualTour } =
    listing?.AdditionalDetails || {};

  return Boolean(brandedVirtualTour || unBrandedVirtualTour);
};

export const use3DTourLink = (listing?: ListingDetails): string => {
  if (!hasListing3DTour(listing)) return '';

  const { brandedVirtualTour, unBrandedVirtualTour } =
    listing?.AdditionalDetails || {};

  return brandedVirtualTour || unBrandedVirtualTour || '';
};

export const useVideo3DTourLinks = (): TVideo3DTourLinks => {
  const { data: listingDetails } = useListingDetailsByParams();

  const link = use3DTourLink(listingDetails);

  const vTourLink = useMemo(() => {
    const isVTLink = !!vTourDomains.find((domain) => link?.includes(domain));

    return isVTLink ? link : null;
  }, [link]);

  const videoLink = useMemo(() => {
    const isVideoLink = !!videoDomains.find((domain) => link?.includes(domain));

    return isVideoLink ? link : null;
  }, [link]);

  return {
    videoLink,
    vTourLink,
  };
};
