import styled from '@emotion/styled';
import GlobalStyle from '../global-style/global-style.style';

const Container = styled(GlobalStyle.Container)`
  position: relative;
`;

const Card = styled(GlobalStyle.Card)`
  flex: 1;
  position: relative;
  padding-bottom: 16px;
`;

const Row = styled(GlobalStyle.Row)``;

const HeadingRow = styled(Row)`
  padding-bottom: ${(props) => (props.title ? '12px' : '')};
`;

const Col = styled(GlobalStyle.Col)``;

Col.defaultProps = {
  xs: '12',
  sm: 'auto',
};

const HeadingCol = styled(GlobalStyle.Col)`
  padding-right: ${({ theme }) => theme?.spacings?.ul || '2rem'};
  padding-bottom: 16px;
`;

const ExtraCol = styled(HeadingCol)`
  justify-content: flex-end;
  display: flex;
  padding-top: 16px;
`;

/*****
 * This style will ensure that every element in the card has the correct padding.
 * The padding is not the responsibility of given element's parent container.
 */
const CardElement = styled.div`
  padding-left: ${({ theme }) => theme?.spacings?.ul || `2rem`};
  padding-right: ${({ theme }) => theme?.spacings?.ul || `2rem`};
`;

const Title = styled(CardElement)`
  color: ${(props) => props?.theme?.colorPrimary};
  font-weight: ${({ theme }) => theme?.fontWeight?.bold};
  padding-top: 16px;

  font-size: 16px;
  @media (min-width: ${({ theme }) => theme.breakpoint?.lg?.min}) {
    font-size: 24px;
  }

  text-transform: capitalize;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const Subtitle = styled(CardElement)`
  font-size: 1.2rem;
  line-height: 1.4rem;
  @media all and (min-width: 769px) {
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
`;

const CardFooter = styled(CardElement)`
  background-color: ${({ theme }) => theme.colors.white};
  border-top: 1px solid ${({ theme }) => theme.colors.grey.ultraLight};
  border-radius: 0px 0px 4px 4px;
`;

const Children = styled(CardElement)``;

/****
 * Some cards require that they are stripped of their styling in order for
 * and image or other element to fill up the entire card.
 */
const CardNoStyling = styled(Card)`
  border-style: none;
  padding: 0px;
  margin-bottom: 0;
  box-shadow: none;
`;

const ChildrenNoStyling = styled(Children)`
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
`;

const Styled = {
  Card,
  Container,
  Title,
  Subtitle,
  Children,
  HeadingCol,
  ExtraCol,
  HeadingRow,
  Col,
  Row,
  CardNoStyling,
  ChildrenNoStyling,
  CardFooter,
  CardElement,
};

export default Styled;
