import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { BrowserStorage, StorageType } from '../../services/BrowserStorage';

const storage = new BrowserStorage(StorageType.SESSION);
const storageKey = 'isEmbedded';

export function useIsEmbedded(): boolean {
  const location = useLocation();

  const hasEmbedParam = location.search.includes('embed=true');

  useEffect(() => {
    if (!hasEmbedParam) return;

    storage.setItem(storageKey, true);
  }, [hasEmbedParam]);

  return hasEmbedParam || storage.getItem(storageKey) || false;
}
