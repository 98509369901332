import styled from '@emotion/styled';

const Header = styled('header')({
  width: '100%',
  position: 'relative',
});

const Container = styled('div')(({ theme }) => ({
  padding: '2rem 0',
  margin: '0',
  backgroundColor: theme.colors.branding.main,
  clipPath: 'polygon(50% 100%, 100% 65%, 100% 0, 0 0, 0% 65%);',
  position: 'absolute',
  top: '70px',
  left: 0,
  right: 0,
  height: '396px',
  '@media all and (min-width: 768px)': {
    top: 0,
  },
  '@media all and (min-width: 360px)': {
    height: '416px',
  },
}));

const HeaderWrapper = styled('div')({
  background: 'white',
  display: 'flex',
  justifyContent: 'center',
  '@media all and (min-width: 768px)': {
    display: 'none',
  },
});

const HeaderContainer = styled.div`
  width: 90%;
  max-width: 965px;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0;
`;

const LogoWrapper = styled('div')(() => ({
  width: 'max-content',
  display: 'flex',
  '@media all and (max-width: 768px)': {
    width: '6.9rem',
  },
}));

const Logo = styled('img')({
  height: '4rem',
  marginRight: '2rem',
  '&:last-child': {
    marginRight: 0,
  },
});

const Title = styled('h1')(({ theme }) => ({
  fontSize: '4.8rem',
  fontFamily: theme.fontFamily.primaryBold,
  color: theme.colors.white,
  fontWeight: theme.fontWeight.bold,
  textAlign: 'center',
  width: '468px',
  margin: '1.5rem auto 0 auto',
  '@media all and (max-width: 768px)': {
    fontSize: '2.8rem',
    lineHeight: '3.2rem',
    width: '28.8rem',
    marginTop: '2.7rem',
  },
}));

const MobileDescription = styled('p')(({ theme }) => ({
  display: 'none',
  '@media all and (max-width: 768px)': {
    display: 'block',
    fontSize: theme.fontSize.xlRem,
    lineHeight: '2rem',
    color: theme.colors.white,
    textAlign: 'center',
    width: '28.8rem',
    margin: '1.4rem auto 0 auto',
    fontFamily: theme.fontFamily.primary,
  },
}));

const MobileDownloadBtn = styled('button')(({ theme }) => ({
  display: 'none',

  '@media all and (max-width: 768px)': {
    display: 'block',
    border: `1px solid ${theme.colors.branding.main}`,
    height: '3rem',
    borderRadius: '100px',
    background: 'transparent',
    color: theme.colors.branding.main,
    textTransform: 'uppercase',
    padding: '0 1.5rem',
    fontSize: '0.8rem',
  },
}));

export default {
  Header,
  Container,
  HeaderWrapper,
  LogoWrapper,
  Logo,
  Title,
  MobileDescription,
  MobileDownloadBtn,
  HeaderContainer,
};
