import { CSSProperties, PropsWithChildren } from 'react';

import { ContentWrapper, PageContainer } from './Page.style';
import MlsDisclaimers from '../../MlsDisclamers/MlsDisclaimers';
import { JourneySection } from '../Section/JourneySection';
import PrivacyPolicyLicensingAgreement from '../../../privacy-policy-licensing-agreement/privacy-policy-licensing-agreement';
import { useIsMobileView } from '../../../../hooks/useIsMobileView';

export type PageProps = {
  hideMlsDisclaimers?: boolean;
  hideLicenceAndPrivacy?: boolean;
  containerStyles?: CSSProperties;
  className?: string;
};

export const Page = ({
  children,
  hideMlsDisclaimers = false,
  hideLicenceAndPrivacy = false,
  containerStyles = {},
  className,
}: PropsWithChildren<PageProps>): JSX.Element => {
  const isMobile = useIsMobileView();

  return (
    <PageContainer
      style={containerStyles}
      className={className}
      isMobile={isMobile}
    >
      <ContentWrapper>
        {children}
        {!hideLicenceAndPrivacy && <PrivacyPolicyLicensingAgreement />}
        {!hideMlsDisclaimers && (
          <JourneySection>
            <MlsDisclaimers />
          </JourneySection>
        )}
      </ContentWrapper>
    </PageContainer>
  );
};
