export default `<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="17.2667" height="17.2667" rx="8.63333" transform="matrix(-1 0 0 1 18.0225 0.426666)" fill="black" fill-opacity="0.3"/>
<g filter="url(#filter0_d_2022_23370)">
<path d="M11.1157 5.03111L7.08683 9.06L11.1157 13.0889" stroke="white" stroke-width="1.15111" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<filter id="filter0_d_2022_23370" x="5.36012" y="3.30445" width="7.4824" height="11.5111" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="0.575556"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2022_23370"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2022_23370" result="shape"/>
</filter>
</defs>
</svg>`;
