import styled from '@emotion/styled';

export const Container = styled.div`
  min-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  inset: 0;
  padding: 3rem 1rem 10rem;
  @media (min-width: ${({ theme }) => theme.breakpoint.lg.min}) {
    position: static;
    padding: 1rem 0;
    min-width: 54rem;
    height: 60vh;
  }
`;

export default { Container };
