import { styled, Switch as MuiSwitch } from '@mui/material';

interface Props {
  textActive?: string;
  textInactive?: string;
  activeBackgroundColor?: string;
}

export const Switch = styled(MuiSwitch, {
  shouldForwardProp: (propName) =>
    !['textInactive', 'textActive'].includes(propName.toString()),
})<Props>(
  ({
    textActive = '',
    textInactive = '',
    activeBackgroundColor = '#4B9344',
  }) => ({
    width: 75,
    height: 34,
    padding: 7,
    marginLeft: -7,
    '& .MuiSwitch-track': {
      borderRadius: 20 / 2,
      height: 20,
      width: 75,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        width: 40,
        height: 16,
        color: '#fff',
        fontSize: 8,
        fontWeight: 700,
        pointerEvents: 'none',
      },
    },

    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(46px)',

        '& + .MuiSwitch-track': {
          backgroundColor: activeBackgroundColor,
          opacity: 1,
          border: 0,
          '&:before': {
            left: 12,
            content: `"${textActive}"`,
            transform: 'translate(3px,-5px)',
            textAlign: 'left',
          },
        },
        '& .MuiSwitch-input': {
          transform: 'translate(-50%, 50%)',
        },
      },
      '&:not(.Mui-checked)': {
        '& + .MuiSwitch-track': {
          '&:after': {
            right: 12,
            content: `"${textInactive}"`,
            textAlign: 'right',
            transform: 'translate(-3px,-5px)',
          },
        },
        '& .MuiSwitch-input': {
          transform: 'translate(50%, 50%)',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      width: 16,
      height: 16,
      transform: 'translate(2px, 8px)',
    },
  }),
);
