import { useQuery, useQueryClient, UseQueryResult } from 'react-query';

import api from '../../api';
import { apiRoutes } from '../../constants';
import { ListingDetails } from '../../types';

export const LISTING_DETAIL_KEY = 'listingDetail';

export const listingDetailApiMapper = (
  listingDetailApi: ListingDetails,
): ListingDetails => {
  const mappedListingDetail = { ...listingDetailApi };

  if (
    mappedListingDetail?.Address &&
    !mappedListingDetail.Address.fullAddress
  ) {
    const {
      Address: { addressLine1, countrySubd, postal1, locality },
    } = mappedListingDetail as ListingDetails;

    mappedListingDetail.Address.fullAddress = `${addressLine1}, ${locality}, ${countrySubd} ${postal1}`;
  }

  return mappedListingDetail;
};

export const fetchListingDetail = async (
  mlsId: string,
  mlsListingId: string,
): Promise<ListingDetails> => {
  let data: ListingDetails | undefined = undefined;

  const response = await api.post(apiRoutes.listing.get(), {
    listings: [{ mlsId, mlsListingId }],
  });

  data = response.data?.[0];

  if (!data) throw new Error('No listing found');

  return listingDetailApiMapper(data);
};

export function useListingDetail(
  mlsId: string,
  mlsListingId: string,
): UseQueryResult<ListingDetails> {
  return useQuery(
    [LISTING_DETAIL_KEY, mlsId, mlsListingId],
    () => fetchListingDetail(mlsId, mlsListingId),
    {
      enabled: Boolean(mlsId) && Boolean(mlsListingId),
      refetchOnWindowFocus: false,
      retry: false,
    },
  );
}

export function useInvalidateListingDetail(): () => void {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries(LISTING_DETAIL_KEY);
  };
}
