import { useCallback, useMemo } from 'react';
import { useKvCoreFeatureFlags } from '../services/FeatureFlagProvider';
import { BrowserStorage } from '../services/BrowserStorage';

export enum FEATURES {
  HopWebUseEmailValidation = 'hop-web-use-email-validation',
  HopWebAds = 'hop-web-ads',
  HopInternalAuth = 'hop-web-internal-auth',
  HopWebNotificationsPanel = 'hop-web-notifications-pannel',
  HopWebNps = 'hop-web-nps',
  HopHideSmartBanner = 'hop-web-hide-app-smart-banner',
}

interface FeatureFlagsInterface {
  hasFeature: (feature: FEATURES) => boolean | undefined;
}

const storage = new BrowserStorage();

export function useFeatureFlags(): FeatureFlagsInterface {
  const { featureFlags, isLoading } = useKvCoreFeatureFlags();

  const hasFeature = useCallback(
    (feature: FEATURES) => {
      const lsFeature = storage.getItem('featureFlag');
      if (feature === lsFeature) return true;

      if (isLoading || !featureFlags) return undefined;

      return featureFlags[feature]?.enabledInd;
    },
    [featureFlags, isLoading],
  );

  return useMemo(
    () => ({
      hasFeature,
    }),
    [hasFeature],
  );
}

export function useHasFeatureFlag(
  feature: FEATURES,
  defaultValue = false,
): boolean {
  const { hasFeature } = useFeatureFlags();

  const hasFF = hasFeature(feature);

  return hasFF ?? defaultValue;
}
