import { useEffect } from 'react';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';

import Paper from '../V2/Paper/Paper';
import CardStyle from '../card/card.style';

export function UnknownUser() {
  const { t } = useTranslation();

  useEffect(() => {
    Sentry.captureMessage(`Info: KvCore key cookie expired.`, 'info');
  }, []);

  return (
    <PaperStyled>
      <Message>{t('auth.linkExpiredMessage')}</Message>
    </PaperStyled>
  );
}

const PaperStyled = styled(Paper)`
  max-width: 350px;
  height: fit-content;
`;

const Message = styled(CardStyle.Title)`
  font-weight: 800;
  font-size: 16px;
  text-transform: capitalize;
  padding: 2rem;
`;
