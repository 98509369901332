import { useMemo } from 'react';
import { useQuery } from 'react-query';

import api from '../../../api';
import { ValuationDetails } from '../../../types';
import { useHomeownerByContact } from '../../../hooks/useHomeownerByContact';
import { apiRoutes } from '../../../constants';

type ValuationDetailsReturnType = {
  isLoading: boolean;
  isInvalidData: boolean;
  valuationDetails: ValuationDetails[];
};

const fetchValuationDetails = async (): Promise<ValuationDetails[]> => {
  const res = await api.get(apiRoutes.valuation.get());

  return res.data;
};

export const useValuationDetails = (): ValuationDetailsReturnType => {
  const { data: homeowner, isLoading: isLoadingHomeowner } =
    useHomeownerByContact();

  const homeownerId = homeowner?.homeownerUuid;

  const { isLoading, data } = useQuery(
    ['valuationDetail', homeownerId],
    () => fetchValuationDetails(),
    {
      enabled: !isLoadingHomeowner && Boolean(homeownerId),
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );

  const valuations = useMemo(
    () =>
      data?.filter(
        (vd) => vd.Address.fullAddress === homeowner?.Address.fullAddress,
      ) ?? [],
    [data, homeowner],
  );

  return useMemo(
    () => ({
      isLoading,
      valuationDetails: valuations,
      isInvalidData: !valuations,
    }),
    [valuations, isLoading],
  );
};
