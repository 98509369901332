import React from 'react';
import { useTranslation } from 'react-i18next';

import { Agent } from './Agent';
import { QRImage } from '../../QRImage/QRImage';
import { useBanner } from '../../../../providers/BannerProvider';

import S from './Banner.style';
import { useIsMobileView } from '../../../../hooks/useIsMobileView';
import { FEATURES, useHasFeatureFlag } from '../../../../hooks/useFeatureFlags';

export const HamburgerBanner: React.FC = () => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();
  const { show, companyLogo, companyName, goToApp, appUrl } = useBanner();

  const hideBanner = useHasFeatureFlag(FEATURES.HopHideSmartBanner);

  if (!show || hideBanner) return null;

  if (isMobileView) return null;

  return (
    <S.Container inSideMenu>
      <S.BrandingSection inSideMenu>
        <S.ContentWrapper>
          {companyLogo && (
            <S.LogoWrapper>
              <S.Image src={companyLogo} />
            </S.LogoWrapper>
          )}
          <S.TextWrapper>
            <S.CompanyName inSideMenu>{companyName}</S.CompanyName>
            <S.Text inSideMenu>{t('banner.text')}</S.Text>
          </S.TextWrapper>
        </S.ContentWrapper>
        <S.AgentWrapper inSideMenu>
          <Agent />
          <S.Button data-qa="open-my-app-btn" size="medium" onClick={goToApp}>
            Open My App Today
          </S.Button>
        </S.AgentWrapper>
        <S.Square inSideMenu />
      </S.BrandingSection>
      <S.QRSection inSideMenu>
        <S.QRContent inSideMenu>
          {appUrl && <QRImage value={appUrl} size={100} />}
          <p>
            Scan the QR code using your
            <br />
            smartphone or tablet to download the app
          </p>
        </S.QRContent>
      </S.QRSection>
    </S.Container>
  );
};
