import styled from '@emotion/styled';

export const ModalHeaderContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});

export const ModalIconWrapper = styled('div')({
  width: 'fit-content',
});

export const ModalTitle = styled('div')({
  fontSize: '3rem',
  textAlign: 'center',
});
