import React from 'react';
import { useBanner } from '../../../../providers/BannerProvider';
import { useIsMobileView } from '../../../../hooks/useIsMobileView';
import { MobileBanner as MobileBannerComponent } from './MobileBanner';
import { FEATURES, useHasFeatureFlag } from '../../../../hooks/useFeatureFlags';

export const Banner: React.FC = () => {
  const isMobileView = useIsMobileView();
  const { show } = useBanner();

  const hideBanner = useHasFeatureFlag(FEATURES.HopHideSmartBanner);

  if (!show || hideBanner) return null;

  // return isMobileView ? <MobileBannerComponent /> : <DesktopBanner />;
  return isMobileView ? <MobileBannerComponent /> : null;
};
