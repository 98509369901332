import { UseQueryResult, useQuery, useQueryClient } from 'react-query';

import api from '../../api';
import { apiRoutes } from '../../constants';
import { useHomeownerByContact } from '../useHomeownerByContact';

export interface MessageCount {
  chatUserId: string;
  chatConversationId: string[];
  sentMessages: number;
  sentMessagesRead: number;
  sentMessagesUnread: number;
  receivedMessages: number;
  receivedMessagesRead: number;
  receivedMessagesUnread: number;
}

const QUERY_KEY = 'messageCount';

async function fetchMessageCount() {
  const response = await api.get(apiRoutes.chat.getMessageCount());

  return response.data;
}

export function useMessageCount(): UseQueryResult<MessageCount> {
  const { data: homeownerDetail } = useHomeownerByContact();

  const chatUserId = homeownerDetail?.chatUserId;

  return useQuery([QUERY_KEY, chatUserId], () => fetchMessageCount(), {
    enabled: Boolean(chatUserId),
    staleTime: Infinity,
    refetchInterval: 30 * 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
  });
}

export function useInvalidateMessageCount() {
  const queryClient = useQueryClient();

  return () => queryClient.invalidateQueries(QUERY_KEY);
}
