import styled from '@emotion/styled';
import { Button } from '../../UI/Button';

const StyledButton = styled(Button)`
  text-transform: capitalize;
  font-size: 1.4rem;
  height: 5.4rem;
  font-weight: 500;
  margin-bottom: 2rem;
`;

const Content = styled.div`
  max-width: 100vw;
  width: 500px;
  padding: 40px;
  & svg {
    height: 85px;
    display: block;
    margin: 1rem auto 0 auto;
  }
  @media all and (max-width: 540px) {
    width: 320px;
    padding: 20px;
    & svg {
      height: 62px;
    }
  }
`;

const Title = styled('h1')({
  fontSize: 32,
  fontWeight: 700,
  textAlign: 'center',
  marginBottom: 26,
  marginTop: 12,
  '@media all and (max-width: 540px)': {
    fontSize: 30,
    marginBottom: 12,
  },
});

const Text = styled('p')({
  fontSize: 18,
  textAlign: 'center',
  fontWeight: 300,
  '@media all and (max-width: 540px)': {
    fontSize: 16,
  },
});

const TextArea = styled('textarea')({
  padding: 20,
  fontFamily: 'inherit',
  color: '#908F8F',
  outline: 'none',
  resize: 'none',
  borderRadius: 5,
  border: '1px solid #e6e6e6',
  width: '100%',
  fontSize: 16,
  margin: '20px 0',
});

const Link = styled('a')(({ theme }) => ({
  color: theme.colors.branding.main,
  textDecoration: 'underline',
}));

const Policy = styled('p')({
  fontSize: 10,
  textAlign: 'center',
});

export default { Content, Title, Text, TextArea, Policy, Link, StyledButton };
