import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { formatNumber } from '../../../../utils/number-util';
import { capitalizeFirstLetter } from '../../../../components/V2/HeadlineText/HeadlineText';

interface ListingDetailsProps {
  dataQa?: string;
  beds: number | null;
  baths: number | null;
  sqft?: number | null;
}

export function ListingDetailsInfo({
  dataQa,
  beds,
  baths,
  sqft,
}: ListingDetailsProps) {
  const { t } = useTranslation();

  const bedsLabel = capitalizeFirstLetter(
    t('myHome.similarHomes.tileSlider.beds').toLowerCase(),
  );
  const bathsLabel = capitalizeFirstLetter(
    t('propertyDetails.baths').toLowerCase(),
  );

  return (
    <Container>
      <ListingDetail data-qa={`${dataQa}-beds`}>
        {beds} {bedsLabel}
      </ListingDetail>
      <ListingDetail data-qa={`${dataQa}-baths`}>
        {baths} {bathsLabel}
      </ListingDetail>
      <ListingDetail data-qa={`${dataQa}-living-space-size`}>
        {formatNumber(sqft)} {t('propertyDetails.livingSpaceSize')}
      </ListingDetail>
    </Container>
  );
}

const Container = styled.div`
  gap: 0.8rem;
  display: flex;
`;

const ListingDetail = styled.div`
  color: #000;
  font-size: 1.1rem;
  line-height: 1.3rem;
`;
