// Can only load the google api once
// https://stackoverflow.com/a/53455474/236380

import React, { useState, useEffect } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';

export const GoogleMapContext = React.createContext();
export const useGoogleMap = () => React.useContext(GoogleMapContext);

const libraries = ['places', 'geometry', 'drawing'];

export const GoogleMapProvider = ({ children }) => {
  const [lastZoomLevel, setLastZoomLevel] = useState(7);

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    libraries: libraries,
  });

  useEffect(() => {
    return () => {
      setLastZoomLevel(7);
    };
  }, []);

  if (!isLoaded) return null;

  return (
    <GoogleMapContext.Provider
      value={{
        lastZoomLevel,
        setLastZoomLevel,
        mapIsLoaded: isLoaded,
        mapLoadError: loadError,
      }}
    >
      {children}
    </GoogleMapContext.Provider>
  );
};
