import Talk from 'talkjs';
import { Chatbox } from 'talkjs/all';
import { useEffect, useState } from 'react';

import { IChatMessage } from './types';
import { ConversationTypes, useAnalytics } from '../../analytics';
import { useHomeownerByContact } from '../../../hooks/useHomeownerByContact';

const useChat = (conversationId?: string) => {
  const { data: homeownerDetail, isError: homeOwnerErrors } =
    useHomeownerByContact();

  const { trackConversation } = useAnalytics();
  const [currConversationId, setCurrConversationId] = useState('');
  const [loading, setLoading] = useState(true);
  const [inbox, setInbox] = useState<Chatbox | null>(null);

  const sendMessage = (message: string) => {
    if (loading && !currConversationId) return;
    const conv = window.talkSession.getOrCreateConversation(currConversationId);

    conv.sendMessage(message);
  };

  useEffect(() => {
    if (conversationId && conversationId !== currConversationId) {
      setCurrConversationId(conversationId);
    }
  }, [conversationId, currConversationId]);

  useEffect(() => {
    if (homeOwnerErrors) {
      setLoading(false);
      return;
    }

    if (homeownerDetail?.chatUserId) {
      Talk.ready.then(() => {
        const local = new Talk.User(homeownerDetail.chatUserId);

        window.talkSession = new Talk.Session({
          appId: import.meta.env.VITE_TALK_JS_APP_ID || '',
          me: local,
        });

        window.talkSession.onMessage((msg: IChatMessage) => {
          if (!msg?.isByMe) return;
        });

        setLoading(false);
        trackConversation(ConversationTypes.Chat);
      });
    }
  }, [homeownerDetail, homeOwnerErrors, trackConversation]);

  useEffect(() => {
    if (!loading && currConversationId) {
      const conv =
        window.talkSession.getOrCreateConversation(currConversationId);

      setInbox(
        window.talkSession.createChatbox(conv, {
          captureKeyboardEvents: true,
        }),
      );
    }
  }, [loading, currConversationId]);

  return {
    inbox,
    loading,
    sendMessage,
    hasErrors: homeOwnerErrors,
  };
};

export default useChat;
