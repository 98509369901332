import { ListingDetails, TListingsSortValue } from '../types';
import { getListingPriceValue } from '../utils/getListingPriceValue';

export const getSortedListings = (
  listings: ListingDetails[],
  sortValue?: TListingsSortValue,
): ListingDetails[] => {
  switch (sortValue) {
    case 'listDateOldest':
      return [...listings].sort(
        (a, b) =>
          new Date(a.ListingSummary.listingDate).getTime() -
          new Date(b.ListingSummary.listingDate).getTime(),
      );
    case 'saleDateOldest':
      return [...listings].sort(
        (a, b) =>
          new Date(a.SalePrice.saleDate as string).getTime() -
          new Date(b.SalePrice.saleDate as string).getTime(),
      );
    case 'saleDateNewest':
      return [...listings].sort(
        (a, b) =>
          new Date(b.SalePrice.saleDate as string).getTime() -
          new Date(a.SalePrice.saleDate as string).getTime(),
      );
    case 'priceHighest':
      return [...listings].sort((a, b) => {
        const aPrice = getListingPriceValue(a) ?? 0;
        const bPrice = getListingPriceValue(b) ?? 0;
        return bPrice - aPrice;
      });
    case 'priceLowest':
      return [...listings].sort((a, b) => {
        const aPrice = getListingPriceValue(a) ?? 0;
        const bPrice = getListingPriceValue(b) ?? 0;
        return aPrice - bPrice;
      });
    case 'bedCountHighest':
      return [...listings].sort((a, b) => {
        const aBedsCount = a?.ListingSummary?.beds || 0;
        const bBedsCount = b?.ListingSummary?.beds || 0;
        return bBedsCount - aBedsCount;
      });
    case 'bathCountHighest':
      return [...listings].sort((a, b) => {
        const aBathsCount = a?.ListingSummary?.bathsTotal || 0;
        const bBathsCount = b?.ListingSummary?.bathsTotal || 0;
        return bBathsCount - aBathsCount;
      });
    case 'sqftHighest':
      return [...listings].sort((a, b) => {
        const aSQFT = a?.ListingSummary?.livingSpaceSize?.[0]?.value;
        const bSQFT = b?.ListingSummary?.livingSpaceSize?.[0]?.value;
        return bSQFT - aSQFT;
      });
    case 'listDateNewest':
    default:
      return [...listings].sort(
        (a, b) =>
          new Date(b.ListingSummary.listingDate).getTime() -
          new Date(a.ListingSummary.listingDate).getTime(),
      );
  }
};
