import { useTranslation } from 'react-i18next';

import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { HeadlineHomeIcon } from './HeadlineHomeIcon';
import { HeadlineTextSkeleton } from './HeadlineTextSkeleton';
import { useIsMobileView } from '../../../hooks/useIsMobileView';
import { useHomeownerByContact } from '../../../hooks/useHomeownerByContact';

export function capitalizeFirstLetter(str: string): string {
  if (!str) return '';
  const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
  return capitalized;
}

export function HeadlineText(): JSX.Element {
  const { t } = useTranslation();
  const { data: homeownerDetail } = useHomeownerByContact();
  const isMobile = useIsMobileView();
  const theme = useTheme();

  const name = homeownerDetail?.Name?.firstName ?? '';

  if (!name) return <HeadlineTextSkeleton />;

  const formattedName = capitalizeFirstLetter(name);

  const address = homeownerDetail?.Address?.addressLine1;

  const brandingColor = theme.colors.branding.main;

  return isMobile ? (
    <Container>
      <MobileTextWrap>
        <Text isBold large color={brandingColor}>{`${formattedName}, `}</Text>
        <Text color={theme.colors.black}>
          {t('myHome.greeting.headlineImprovementsMobile')}
        </Text>
      </MobileTextWrap>
      <MobileHomeWrap>
        <HeadlineHomeIcon />
      </MobileHomeWrap>
    </Container>
  ) : (
    <Container>
      <Text isBold color={theme.colors.black}>
        {t('myHome.greeting.headlineImprovementsFirstPart')}
      </Text>
      <Text isBold color={theme.colors.black}>{` ${address} `}</Text>
      <br />
      <Text isBold color={theme.colors.black}>
        {t('myHome.greeting.headlineImprovementsSecondPart')}
      </Text>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  line-height: 2rem;
  justify-content: space-between;
  background: white;

  margin: 0;
  padding: 2rem 1rem;
  @media (min-width: ${({ theme }) => theme.breakpoint.lg.min}) {
    background: transparent;

    display: block;
    text-align: center;
    line-height: 4rem;
    margin: 2rem 0 1.5rem;
    padding: 0;
  }
`;

const Text = styled.span<{
  isBold?: boolean;
  large?: boolean;
  small?: boolean;
  color?: string;
}>`
  font-size: ${({ large, small }) =>
    large ? '2.1rem' : small ? '1rem' : '1.4rem'};
  color: ${({ color }) => color};
  font-weight: ${({ theme, isBold }) =>
    isBold ? theme.fontWeight.bold : theme.fontWeight.light};
  @media (min-width: ${({ theme }) => theme.breakpoint.lg.min}) {
    font-size: 3.4rem;
  }
`;

const MobileTextWrap = styled.div`
  max-width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  white-space: pre-wrap;
`;

const MobileHomeWrap = styled.div`
  width: 55px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
