import { useQuery, useQueryClient, UseQueryResult } from 'react-query';
import api from '../../../api';
import { apiRoutes } from '../../../constants';
import { IInsuranceDetails } from '../../../types';
import { useHomeownerByContact } from '../../useHomeownerByContact';

const INSURANCE_BY_HOMEOWNER = 'insuranceDetailsByHomeowner';

export const fetchInsuranceDetailByHomeowner = async (): Promise<
  IInsuranceDetails[]
> => {
  const response = await api.get(apiRoutes.insurance.get());
  return response.data;
};

export function useInsuranceByHomeowner(): UseQueryResult<IInsuranceDetails[]> {
  const { data: homeowner } = useHomeownerByContact();
  const propertyUuid = homeowner?.myPropertyUuid;

  return useQuery(
    [INSURANCE_BY_HOMEOWNER, propertyUuid],
    () => fetchInsuranceDetailByHomeowner(),
    {
      enabled: Boolean(propertyUuid),
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  );
}

type InvalidateFn = () => void;

export function useInvalidateInsuranceByHomeowner(): InvalidateFn {
  const queryClient = useQueryClient();

  const { data: homeowner } = useHomeownerByContact();

  const propertyUuid = homeowner?.myPropertyUuid;

  return () => {
    queryClient.invalidateQueries([INSURANCE_BY_HOMEOWNER, propertyUuid]);
  };
}
