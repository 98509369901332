import { useMemo, useEffect, Fragment } from 'react';
import { useRecoilState } from 'recoil';
import { matchPath, useLocation } from 'react-router-dom';

import { IconNavLink } from '../IconNavLink';
import { SubNav } from './SubNavigation.style';
import appNavAtom from '../../../../recoil/appNav';
import { routerPaths } from '../../../../constants';
import { useSubNavItems } from './hooks/useSubNavItems';
import { MainOrigin, SubOrigin, useAnalytics } from '../../../analytics';

const SUBHEADER_SECTION_MAP = Object.freeze({
  ['my-home']: [
    routerPaths.myHome.myHome,
    routerPaths.myHome.myMarkets,
    routerPaths.myHome.myHomeMarket,
    routerPaths.nextHome.marketDetail,
    routerPaths.myJourney.myMaintenance,
  ],
  ['search']: [
    routerPaths.savedHomes,
    routerPaths.myJourney.myMove,
    routerPaths.nextHome.listing,
    routerPaths.nextHome.listingDetail,
    routerPaths.myJourney.myTransactions,
    ...Object.values(routerPaths.search),
  ],
});

const getSubheaderSectionByPathname = (pathname: string) =>
  Object.keys(SUBHEADER_SECTION_MAP).find((section) =>
    SUBHEADER_SECTION_MAP[section as keyof typeof SUBHEADER_SECTION_MAP].some(
      (path) =>
        pathname.includes(path) ||
        matchPath(pathname, {
          path,
          exact: true,
          strict: true,
        }),
    ),
  );

export function SubNavigation(): JSX.Element {
  const { pathname } = useLocation();
  const { trackPageSwitch } = useAnalytics();
  const [isSubNavHidden, setIsSubNavHidden] = useRecoilState(appNavAtom);
  const subNavItems = useSubNavItems();

  const page = useMemo(
    () => getSubheaderSectionByPathname(pathname),
    [pathname],
  );

  const onSubNavLinkClick = (title: string) => {
    if (title === SubOrigin.myHome)
      return trackPageSwitch(MainOrigin.mainNav, title);

    if (title === SubOrigin.listings)
      return trackPageSwitch(MainOrigin.mainNav, title);

    return trackPageSwitch(MainOrigin.subNav, title);
  };

  useEffect(() => {
    if (
      pathname === routerPaths.savedHomes ||
      pathname === routerPaths.auth.logout
    )
      return;
    setIsSubNavHidden(!page);
  }, [page, setIsSubNavHidden, isSubNavHidden, pathname]);

  if (!page || !Object.keys(subNavItems).includes(page) || isSubNavHidden) {
    return <></>;
  }

  return (
    <SubNav>
      {subNavItems[page as keyof typeof subNavItems].map((item) =>
        item.isHidden ? (
          <Fragment key={item.label}></Fragment>
        ) : (
          <IconNavLink
            onClick={() => onSubNavLinkClick(item.origin)}
            isSubNav
            to={item.route}
            key={item.label}
            label={item.label}
            isGated={item.isGated}
            navGroupPaths={item.navGroupPaths}
          />
        ),
      )}
    </SubNav>
  );
}
