import { FC } from 'react';
import styled from '@emotion/styled';
import { PlacesType } from 'react-tooltip';

import { Icon, IconType } from '../../icon';

interface Props {
  fontSize?: string;
  placement?: string;
  tooltipText: string;
  questionIcon?: boolean;
  dataQa?: string;
  place?: PlacesType;
}

export const InfoTooltip: FC<Props> = ({
  fontSize,
  tooltipText,
  dataQa = '',
  questionIcon = false,
  place = 'bottom',
}) => {
  const TypeOfIcon = questionIcon
    ? IconType.circleQuestion
    : IconType.infoCircle;

  return (
    <Container>
      <TooltipIcon
        data-qa={dataQa}
        fontSize={fontSize}
        data-tooltip-id="root-tooltip"
        data-tooltip-place={place}
        data-tooltip-content={tooltipText}
      >
        <Icon type={TypeOfIcon} />
      </TooltipIcon>
    </Container>
  );
};

interface WithFontSize {
  fontSize?: string;
}

const TooltipIcon = styled('div')<WithFontSize>(({ theme, fontSize }) => ({
  fontSize: fontSize || '2rem',
  color: theme.colors.grey.dark2,
  marginLeft: '.9rem',
  cursor: 'pointer',
}));

const Container = styled.div`
  position: relative;
`;
