import PropTypes from 'prop-types';

import {
  Row,
  StaticIcon,
  Text,
  StyledIcon,
} from './call-to-action-footer.style';
import Icon, { IconType } from '../icon/icon';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { onClickProps } from '../../utils/ui-event-helper';

/****
 * This does not have a width and height of its own.
 * It will most likely be placed in the footer section of a card,
 * passed through as a footer prop. It also does not have a left or right padding since
 * that will vary greatly from component to component
 */
function CallToActionFooter(props) {
  const {
    text,
    link,
    color,
    onClick,
    noPadding,
    linkState,
    href,
    newWindow,
    showIcon,
    customIcon,
    staticIcon,
  } = props;
  const { t } = useTranslation();

  const clickProps = onClickProps({
    onClick,
  });

  const renderIcon = () => {
    if (!showIcon) {
      return <></>;
    }

    if (customIcon) {
      return customIcon;
    }

    return (
      <Icon type={IconType.longArrowRight} title={t('navigation.continue')} />
    );
  };

  return (
    <LinkWrapper
      href={href}
      newWindow={newWindow}
      link={link}
      linkState={linkState}
    >
      <Row {...clickProps} $nopadding={noPadding}>
        <Text color={color}>{text}</Text>
        {staticIcon ? (
          <StaticIcon color={color}>{renderIcon()}</StaticIcon>
        ) : (
          <StyledIcon color={color}>{renderIcon()}</StyledIcon>
        )}
      </Row>
    </LinkWrapper>
  );
}

CallToActionFooter.propTypes = {
  // Color used for the arrow and the text
  color: PropTypes.string,
  link: PropTypes.string,

  showIcon: PropTypes.bool.isRequired,

  // The message displayed
  text: PropTypes.string,

  // An icon to be displayed other than the default icon
  customIcon: PropTypes.object,

  // If true, icon will not animate on hover
  staticIcon: PropTypes.bool,

  onClick: PropTypes.func,
  noPadding: PropTypes.bool,
};

CallToActionFooter.defaultProps = {
  showIcon: true,
  noPadding: false,
};

const LinkWrapper = (props) => {
  const { children, href, newWindow, link, linkState } = props;

  if (href) {
    return (
      <a href={href} target={newWindow ? '_blank' : ''} rel="noreferrer">
        {children}
      </a>
    );
  }

  if (link) {
    return <Link to={{ pathname: link, state: linkState }}>{children}</Link>;
  }

  return <>{children}</>;
};

export default CallToActionFooter;
