import styled from '@emotion/styled';

import { WithIsMobile } from '../../../types';
import { useIsMobileView } from '../../../hooks/useIsMobileView';

interface Props {
  count: string | number;
}

function NumbericBadgeIcon(): JSX.Element {
  return (
    <svg
      data-qa={'notification_number_badge'}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_3054_270338)">
        <rect
          x="3"
          y="2"
          width="14"
          height="14"
          rx="7"
          fill="#43E135"
          shapeRendering="crispEdges"
        />
        <rect
          x="3"
          y="2"
          width="14"
          height="14"
          rx="7"
          fill="url(#paint0_linear_3054_270338)"
          fillOpacity="0.2"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3054_270338"
          x="0"
          y="0"
          width="20"
          height="20"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3054_270338"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3054_270338"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3054_270338"
          x1="8.04"
          y1="2"
          x2="11.96"
          y2="16.84"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function CounterBadge({ count }: Props): JSX.Element {
  const isMobile = useIsMobileView();

  if (!count) return <></>;

  return (
    <Container isMobile={isMobile}>
      <NumbericBadgeIcon />
      <Number isMobile={isMobile}>{count}</Number>
    </Container>
  );
}

const Container = styled('div')<WithIsMobile>(({ isMobile }) => ({
  position: 'relative',
  right: '1rem',
  bottom: isMobile ? '1rem' : '1.8rem',
  svg: {
    position: 'absolute',
    inset: '0px 0px 0px 0px',
  },
}));

const Number = styled('div')<WithIsMobile>(({ theme }) => ({
  width: '2rem',
  height: '1.7rem',
  left: '0.6rem',
  display: 'flex',
  fontSize: '0.9rem',
  position: 'absolute',
  lineHeight: '1.1rem',
  alignItems: 'center',
  justifyContent: 'center',
  inset: '0px 0px 0px 0px',
  color: theme.colors.white,
  fontWeight: theme.fontWeight.regular,
}));
