import { useCallback } from 'react';

import dayjs from 'dayjs';
import { useResetRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import relativeTime from 'dayjs/plugin/relativeTime';

import {
  Notification,
  NotificationBadge,
  NotificationCategory,
} from '../../types';
import {
  TypeIcon,
  StyledRow,
  TimeLabel,
  UnreadIndicator,
  NotificationTitle,
  NotificationWrapper,
  NotificationContent,
  NotificationCategoryIcon,
  NotificationContentWrapper,
} from './NotificationItem.style';
import { NewIcon } from '../../icons/NewIcon';
import { TaskIcon } from '../../icons/TaskIcon';
import { OptionsDropdown } from '../OptionsDropdown';
import { useMarkAsRead } from '../../hooks/useMarkAsRead';
import { ChatNumericIcon } from '../../icons/ChatNumericIcon';
import { UnreadIndicatorIcon } from '../../icons/UnreadIndicatorIcon';
import notificationsPanelAtom from '../../state/notificationsPanelAtom';
import { useNotificationConfig } from '../../hooks/useNotificationConfig';
import { useContactAgent } from '../../../../providers/ContactAgentProvider';

dayjs.extend(relativeTime);

const notificationsIconsTypeMap = {
  [NotificationBadge.New]: () => <NewIcon />,
  [NotificationBadge.Pencil]: () => <TaskIcon />,
  [NotificationBadge.Counter]: (count?: number) => (
    <ChatNumericIcon count={count} />
  ),
};

export function NotificationItem({
  isLast,
  notification,
  dataQa,
}: {
  isLast: boolean;
  notification: Notification;
  dataQa?: string;
}): JSX.Element {
  const NOTIFICATION_CONFIG = useNotificationConfig(notification.category);

  const history = useHistory();
  const { onChat } = useContactAgent();
  const { mutate: markAsRead } = useMarkAsRead();
  const closeNotificationsPanel = useResetRecoilState(notificationsPanelAtom);

  const onNotificationClick = useCallback(
    (e) => {
      if (!notification.isRead) {
        markAsRead(notification.id);
      }

      if (notification.category === NotificationCategory.Chat) {
        onChat(e);
        return;
      }

      history.push(NOTIFICATION_CONFIG.LANDING_PAGE, {
        action: notification?.params?.click_action,
      });

      closeNotificationsPanel();
    },
    [
      onChat,
      history,
      markAsRead,
      notification.id,
      notification.isRead,
      notification.category,
      closeNotificationsPanel,
      NOTIFICATION_CONFIG.LANDING_PAGE,
      notification?.params?.click_action,
    ],
  );

  return (
    <NotificationWrapper data-qa={dataQa} onClick={onNotificationClick}>
      <StyledRow>
        <NotificationCategoryIcon data-qa={'notification_truck_icon'}>
          {NOTIFICATION_CONFIG.ICON}
          <TypeIcon>
            {notificationsIconsTypeMap[
              notification.badge as keyof typeof notificationsIconsTypeMap
            ](notification?.params?.messageCount)}
          </TypeIcon>
        </NotificationCategoryIcon>
        <NotificationContentWrapper>
          <NotificationTitle active={!notification.isRead}>
            {notification.subText ? notification.text : ''}
          </NotificationTitle>
          <NotificationContent
            data-qa={'notification_text'}
            active={!notification.isRead}
          >
            {notification.subText ? notification.subText : notification.text}
          </NotificationContent>
          <TimeLabel>
            <span data-qa={'notification_days_ago'}>
              {dayjs(notification.createdAt).fromNow()}
            </span>
            <OptionsDropdown
              data-qa={'notification_dropdown_button'}
              notification={notification}
              isLast={isLast}
            />
          </TimeLabel>
        </NotificationContentWrapper>
        <UnreadIndicator>
          {!notification.isRead && <UnreadIndicatorIcon />}
        </UnreadIndicator>
      </StyledRow>
    </NotificationWrapper>
  );
}
