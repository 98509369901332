import { FavoriteListingPayload } from '../../../types';
import { formatLongYearMonthDay } from '../../../utils/date-helper';

type PayloadFormattedType = {
  mlsId?: number;
  mlsListingId?: string;
  mobileInd?: boolean;
  favoriteStatusDate?: string;
  favoriteStatus?: string;
  listingComment?: string | null;
};

export const formatFavoriteListingPayload = ({
  status,
  listingDetail,
  listingComments,
}: FavoriteListingPayload): PayloadFormattedType => {
  const payload = {
    mlsId: listingDetail?.MlsInfo.mlsId,
    mlsListingId: listingDetail?.mlsListingId,
    mobileInd: false,
    favoriteStatusDate: formatLongYearMonthDay(Date.now()),
    favoriteStatus: status,
    listingComments,
    updateKvCoreInd: true,
  };

  return payload;
};
