import styled from '@emotion/styled';
import ContentLoader from 'react-content-loader';

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 4rem 0;
`;

function SkeletonContent(): JSX.Element {
  return (
    <ContentLoader
      viewBox="0 0 680 75"
      backgroundColor="#f5f5f5"
      foregroundColor="#dbdbdb"
    >
      <rect x="0" y="10" rx="3" ry="3" width="680" height="60" />
    </ContentLoader>
  );
}

export function HeadlineTextSkeleton(): JSX.Element {
  return (
    <Container>
      <SkeletonContent />
    </Container>
  );
}
