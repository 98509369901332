import { useMemo, useCallback } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { NotificationsWithMeta } from '../types';

export function usePaginatedNotifications(
  useNotifications: () => UseInfiniteQueryResult<
    NotificationsWithMeta,
    unknown
  >,
) {
  const {
    data,
    error,
    isError,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useNotifications();

  const onLoadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  const notifications = useMemo(
    () => data?.pages?.flatMap((page) => page?.data),
    [data?.pages],
  );

  return {
    isError,
    isLoading,
    onLoadMore,
    isFetching,
    isFetchingNextPage,
    data: notifications,
    hasMore: hasNextPage,
    error: error as Error,
  };
}
