import { useQuery, UseQueryResult } from 'react-query';

import { MlsDetails } from '../../types';
import { useDomainWithSubdomain } from '../useDomain';
import { MlsDetailsAPI } from '../../api/hop/MlsDetailsAPI';

export function useMlsDetails(): UseQueryResult<MlsDetails[]> {
  const agentWebsite = useDomainWithSubdomain();

  return useQuery(
    ['mlsDetails', agentWebsite],
    () => MlsDetailsAPI.get(agentWebsite),
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    },
  );
}
