import { useCallback, useMemo } from 'react';
import { useSetRecoilState } from 'recoil';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { svgStringToDataUrl } from '../../../services/svgStringToDataUrl';
import chatBubbleIcon from './icons/chatBubbleIcon';
import { AgentImage } from '../../../components/V2/AgentImage/AgentImage';
import { useContactAgent } from '../../../providers/ContactAgentProvider';
import UnreadMessagesCount from './UnreadMessagesCount';
import sideMenuAtom from '../../../recoil/sideMenu/sideMenuAtom';

interface ChatBubbleProps {
  showUnreadCount?: boolean;
  size?: string;
  onClick?: () => void;
  dataQa?: string;
}

export function ChatBubble({
  size,
  onClick,
  dataQa = 'chat-bubble',
  showUnreadCount = false,
}: ChatBubbleProps) {
  const theme = useTheme();
  const setIsOpen = useSetRecoilState(sideMenuAtom);
  const closeMenu = useCallback(() => setIsOpen(false), [setIsOpen]);
  const { onChat } = useContactAgent();

  const widgetImgSrc = useMemo(
    () =>
      svgStringToDataUrl(chatBubbleIcon, theme.colors.branding.main, '#004467'),
    [theme],
  );
  return (
    <Container
      data-qa={dataQa}
      size={size}
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        onChat(e);
        closeMenu();
        onClick?.();
      }}
    >
      {showUnreadCount && <UnreadMessagesCount />}
      <WidgetImg src={widgetImgSrc} />
      <StyledAgentImg />
    </Container>
  );
}

const Container = styled.div<{ size?: string }>`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => size || '25px'};
  height: ${({ size }) => size || '25px'};
`;

const WidgetImg = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
`;

const StyledAgentImg = styled(AgentImage)`
  overflow: none;
  object-fit: cover;
  background-color: white;
  width: 77%;
  height: 77%;
  position: absolute;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 0;
`;
