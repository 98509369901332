import React, { FC, useMemo, useState } from 'react';

interface RangeIsDirtyProviderContextStructure {
  isDirtyMin: boolean;
  isDirtyMax: boolean;
  setIsDirtyMin: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDirtyMax: React.Dispatch<React.SetStateAction<boolean>>;
}
const RangeIsDirtyProviderContext = React.createContext<
  RangeIsDirtyProviderContextStructure | undefined
>(undefined);

export const useRangeIsDirty = () => {
  const context = React.useContext(RangeIsDirtyProviderContext);

  if (context === undefined) {
    throw new Error('useDirty cannot be used outside RangeIsDirtyProvider!');
  }
  return context;
};

export const RangeIsDirtyProvider: FC = ({ children }) => {
  const [isDirtyMin, setIsDirtyMin] = useState<boolean>(false);
  const [isDirtyMax, setIsDirtyMax] = useState<boolean>(false);
  const value = useMemo(
    () => ({
      isDirtyMin,
      isDirtyMax,
      setIsDirtyMin,
      setIsDirtyMax,
    }),
    [isDirtyMax, isDirtyMin],
  );

  return (
    <RangeIsDirtyProviderContext.Provider value={value}>
      {children}
    </RangeIsDirtyProviderContext.Provider>
  );
};
