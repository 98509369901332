export function MarkAsReadIcon(): JSX.Element {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3135_349255)">
        <path
          d="M11.4201 11.9187L11.4187 11.9201C10.8392 12.5011 10.1506 12.9619 9.39244 13.2759C8.6343 13.5899 7.82158 13.7511 7.00097 13.75L6.99904 13.75C6.17844 13.751 5.36571 13.5899 4.60757 13.2759C3.84943 12.9618 3.16083 12.5011 2.58132 11.9201L2.57993 11.9187C1.99891 11.3392 1.53814 10.6506 1.2241 9.89244C0.910061 9.1343 0.748942 8.32158 0.750008 7.50097L0.750005 7.49904C0.748959 6.67844 0.910087 5.86571 1.22413 5.10757C1.53817 4.34943 1.99894 3.66083 2.57995 3.08132L2.58132 3.07995C3.16083 2.49894 3.84943 2.03817 4.60757 1.72413C5.36571 1.41009 6.17844 1.24896 6.99904 1.25001L7.00097 1.25001C7.82158 1.24894 8.6343 1.41006 9.39244 1.7241C10.1506 2.03814 10.8392 2.49891 11.4187 3.07994L11.4201 3.08132C12.0011 3.66083 12.4618 4.34943 12.7759 5.10757C13.0899 5.86571 13.251 6.67844 13.25 7.49904L13.25 7.50097C13.2511 8.32158 13.0899 9.1343 12.7759 9.89244C12.4619 10.6506 12.0011 11.3392 11.4201 11.9187Z"
          stroke="#888888"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          d="M3.7334 7.50008L5.91118 9.83341L10.2667 5.16675"
          stroke="#888888"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3135_349255">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
