import { CircularProgress } from '@mui/material';
import styled from '@emotion/styled';

type Props = {
  className?: string;
};

export function Loader({ className = '' }: Props): JSX.Element {
  return (
    <LoaderWrapper className={className}>
      <MySpinner color="inherit" />
    </LoaderWrapper>
  );
}

const MySpinner = styled(CircularProgress)`
  width: 4rem;
  height: 4rem;
`;

const LoaderWrapper = styled.div`
  margin: 1rem;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-around;
`;
