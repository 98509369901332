import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import IconNew from './icons/new.svg';
import IconTour3D from './icons/3d-tour.svg';
import IconReduction from './icons/reduction.svg';
import IconOpenHouse from './icons/open-house.svg';

import { ListingDetails } from '../../../../types';
import { ListingFocusableFeature } from '../../types';
import { listingFeatureInFocusAtom } from '../../state/listingFeatureInFocusAtom';
import { use3DTourLink } from '../../../../pages/ListingDetails/components/ListingDetailsContent/MultiViewsCarousel/hooks/useVideo3DTourLinks';
import useReducedPrice from '../../../../pages/Search/components/SectionContent/ListingTile/ListingInfo/useReducedPrice';
import { formatRawCurrency } from '../../../../utils/number-util';
import useFormatOpenHouseTime from '../../hooks/useFormatOpenHouseTime';

export function useApplicableFeatureConfigs(listing?: ListingDetails) {
  const featureInFocuse = useRecoilValue(listingFeatureInFocusAtom);
  const reducedPrice = useReducedPrice({ listingDetails: listing });
  const formattedOpenHouseTime = useFormatOpenHouseTime(listing);

  const has3DTour = Boolean(use3DTourLink(listing));

  return useMemo(() => {
    const configs = [
      {
        id: ListingFocusableFeature.New,
        icon: <IconNew />,
        hasFeature: listing?.ListingSummary?.listingMessage === 'new',
        inFocus: featureInFocuse === ListingFocusableFeature.New,
      },
      {
        id: ListingFocusableFeature.VirtualTour,
        icon: <IconTour3D />,
        hasFeature: has3DTour,
        inFocus: featureInFocuse === ListingFocusableFeature.VirtualTour,
      },
      {
        id: ListingFocusableFeature.PriceReduce,
        icon: <IconReduction />,
        hasFeature: listing?.ListingSummary?.listingMessage === 'reduced',
        inFocus: featureInFocuse === ListingFocusableFeature.PriceReduce,
        details: formatRawCurrency(reducedPrice)?.replace('-', ''),
      },
      {
        id: ListingFocusableFeature.OpenHouse,
        icon: <IconOpenHouse />,
        hasFeature: listing?.AdditionalDetails?.openHouseInd,
        inFocus: featureInFocuse === ListingFocusableFeature.OpenHouse,
        details: formattedOpenHouseTime,
      },
    ];

    return configs.filter(({ hasFeature }) => hasFeature);
  }, [
    has3DTour,
    featureInFocuse,
    listing?.AdditionalDetails?.openHouseInd,
    listing?.ListingSummary?.listingMessage,
    reducedPrice,
    formattedOpenHouseTime,
  ]);
}
