import { useQuery, UseQueryResult } from 'react-query';

import api from '../../api';
import { apiRoutes } from '../../constants';
import { useAuthentication } from '../../providers/Authentication/AuthenticationProvider';
import {
  useHomeownerByContact,
  useInvalidateHomeownerByContact,
} from '../useHomeownerByContact';
import {
  TokenData,
  useTokenData,
} from '../../providers/Authentication/useTokenData';

interface ChatContextStruct {
  LatestMessage: {
    readBy: string[];
    sender: string;
    text: string;
    timestamp: string;
    type: string;
  };
  agentChatUserId: string;
  agentId: string | number;
  chatConversationId: string;
  contactId: number;
  homeownerChatUserId: string;
  unreadMessages: number;
}

const postConversation = async ({
  agentId,
  contactId,
  accountId,
}: TokenData): Promise<ChatContextStruct | null> => {
  if (!contactId || !accountId || !agentId) return null;

  const response = await api.post(apiRoutes.chat.postConversation(), {
    contactId,
    accountId,
    agentId,
  });

  return response.data;
};

export function useChatConversation(): UseQueryResult<ChatContextStruct | null> {
  const { data } = useTokenData();
  const { contactId, accountId, agentId } = data ?? {};
  const { isAuthenticated } = useAuthentication();
  const { data: homeowner } = useHomeownerByContact();
  const invalidateHomeowner = useInvalidateHomeownerByContact();

  return useQuery(
    ['chat', contactId, accountId, agentId],
    () => postConversation({ contactId, accountId, agentId }),
    {
      enabled:
        Boolean(isAuthenticated) &&
        Boolean(contactId) &&
        Boolean(accountId) &&
        Boolean(agentId) &&
        Boolean(homeowner?.homeownerUuid),
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onSuccess: invalidateHomeowner,
    },
  );
}
