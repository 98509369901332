import {
  Theme as MUITheme,
  ComponentsProps,
  ComponentsVariants,
  ComponentsOverrides,
} from '@mui/material';
import { Theme } from '@emotion/react';

type MuiButton = {
  defaultProps?: ComponentsProps['MuiButton'];
  styleOverrides?: ComponentsOverrides<MUITheme>['MuiButton'];
  variants?: ComponentsVariants['MuiButton'];
};

export const buttonStyles = (theme: Theme): MuiButton => ({
  styleOverrides: {
    root: {
      height: '4.5rem',
      maxHeight: '4.5rem',
      width: 'fit-content',
      minWidth: '90px',
      lineHeight: '1.7rem',
      cursor: 'pointer',

      border: 'none',
      outline: 'none',
      boxShadow: 'none',
      textTransform: 'none',

      fontWeight: 400,
      fontSize: '1.4rem',
      fontFamily: theme.fontFamily.primary,

      '&:hover': {
        boxShadow: 'none',
      },

      '&:focus': {
        outline: 'none',
      },

      '&.MuiButton-sizeSmall': {
        height: '3.4rem',
        fontSize: '1.2rem',
      },

      '&.Mui-disabled': {
        cursor: 'not-allowed',
        color: theme.colors.grey.dark5,
        backgroundColor: theme.colors.white,

        border: '1px solid',
        borderColor: theme.colors.grey.dark11,
      },
    },
    contained: {
      color: theme.colors.white,
      backgroundColor: theme.colors.branding.main,

      '&:hover': {
        backgroundColor: theme.colors.black,
      },

      '&:focus': {
        backgroundColor: theme.colors.branding.main,
      },
    },
    outlined: {
      color: theme.colors.branding.main,
      backgroundColor: theme.colors.white,

      border: '1px solid',
      borderColor: theme.colors.branding.main,

      '&:hover': {
        borderColor: theme.colors.branding.main,
        backgroundColor: theme.colors.grey.dark11,
      },

      '&:focus': {
        backgroundColor: theme.colors.white,
      },

      '&.disabled': {
        border: '1px solid red',
      },
    },
    text: {
      color: theme.colors.branding.main,
      '&:hover': {
        backgroundColor: theme.colors.branding.extraLight,
      },
    },
  },
});
