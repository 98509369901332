import { FC } from 'react';
import MlsDisclaimer from '../MlsDisclaimer/MlsDisclaimer';
import { useMlsDetails } from '../../../hooks/query/useMlsDetails';

interface Props {
  showList?: number[];
}

const MlsDisclaimers: FC<Props> = ({ showList }) => {
  const { isLoading, data } = useMlsDetails();

  if (isLoading || !data) return null;

  let mlsList = data;

  if (showList) mlsList = mlsList.filter((mls) => showList.includes(mls.mlsId));

  return (
    <div style={{ margin: '0 2rem' }}>
      {mlsList.map((mls) => {
        return <MlsDisclaimer mls={mls} key={mls.mlsId}></MlsDisclaimer>;
      })}
    </div>
  );
};

export default MlsDisclaimers;
