import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { FormikValues, useFormikContext } from 'formik';

import { Input } from '../../../Input/Input';
import { useOptionalProps } from '../hooks/useOptionalProps';
import { useWindowWidth } from '../../../../../hooks/useWindowSize';

interface Props {
  hasInitialValue: boolean;
}

export const LastName: FC<Props> = ({ hasInitialValue }) => {
  const { t } = useTranslation();
  const screenSize = useWindowWidth();

  const { errors, values, handleBlur, handleChange } =
    useFormikContext<FormikValues>();

  const optionalProps = useOptionalProps(hasInitialValue);

  return (
    <Input
      type="text"
      focusOnIconClick
      name="lastName"
      onBlur={handleBlur}
      style={{
        width: screenSize < 577 ? '30rem' : '35rem',
      }}
      onChange={handleChange}
      value={values.lastName}
      error={String(errors?.lastName ?? '')}
      placeholder={t('auth.profileCreation.inputLabels.lastName')}
      {...optionalProps}
    />
  );
};
