import React, { FC, useCallback, useMemo, useReducer, useState } from 'react';
import { useSearchParams } from '../../../providers/SearchParamsProvider';

import { ListingDetails } from '../../../types';
import { useNewListingInteraction } from '../components/ListingInteractionTracker/ListingInteractionTracker';

interface State {
  active: ListingDetails | null;
  scrollTo: ListingDetails | null;
  filteredListings: ListingDetails[];
}

const TYPES = {
  ACTIVE_SET: 'active_set',
  FILTERED_SET: 'filtered_set',
} as const;

type Action =
  | { type: typeof TYPES.ACTIVE_SET; payload: ListingDetails | null }
  | { type: typeof TYPES.FILTERED_SET; payload: ListingDetails[] };

interface ListingsContextInterface extends State {
  setScrollTo: (listing: ListingDetails) => void;
  setActive: (activeListing: ListingDetails | null) => void;
  setFilteredListings: (filteredListings: ListingDetails[]) => void;
  newListingInteraction: (listing: ListingDetails) => void;
}

const ListingsContext = React.createContext<
  ListingsContextInterface | undefined
>(undefined);

const reducer = (state: State, action: Action) => {
  if (action.type === TYPES.ACTIVE_SET) {
    return { ...state, active: action.payload };
  }

  if (action.type === TYPES.FILTERED_SET) {
    return { ...state, filteredListings: action.payload };
  }

  return state;
};

const initialState: State = {
  active: null,
  scrollTo: null,
  filteredListings: [],
};

export const ListingsProvider: FC = ({ children }) => {
  const { set } = useSearchParams();

  const [scrollTo, setScrollTo] = useState<ListingDetails | null>(null);

  const [{ active, filteredListings }, dispatch] = useReducer(
    reducer,
    initialState,
  );
  const newListingInteraction = useNewListingInteraction();

  const setActive = useCallback(
    (active: ListingDetails | null) => {
      set('activeListing', active?.mlsListingId);

      return dispatch({ type: TYPES.ACTIVE_SET, payload: active });
    },
    [set],
  );

  const setFilteredListings = useCallback((filtered: ListingDetails[]) => {
    dispatch({ type: TYPES.FILTERED_SET, payload: filtered });
  }, []);

  const value = useMemo(
    () => ({
      active,
      scrollTo,
      setScrollTo,
      setActive,
      filteredListings,
      setFilteredListings,
      newListingInteraction,
    }),
    [
      active,
      scrollTo,
      setActive,
      filteredListings,
      setFilteredListings,
      newListingInteraction,
    ],
  );

  return (
    <ListingsContext.Provider value={value}>
      {children}
    </ListingsContext.Provider>
  );
};

export const useListings = (): ListingsContextInterface => {
  const context = React.useContext(ListingsContext);

  if (context === undefined) {
    throw new Error('useListings cannot be used outside ListingsProvider!');
  }

  return context;
};
