import { AdConfig } from '../types';
import Crown from '../icons/Crown.webp';
import SuccessLending from '../icons/SuccessLending.webp';
import House from '../icons/House.webp';
import Credible from '../icons/Credible.svg?url';
import LifetimeHomeLoans from '../icons/LifetimeHomeLoans.webp';

export const accountAdsConfig: Record<number, AdConfig> = {
  377: {
    name: 'Success Lending',
    hideForStates: ['NY'],
    hasEqualHousing: true,
    logo: SuccessLending,
    color: '#50A782',
    disclaimerUrl: 'https://successlending.com/licensing-disclosures/',
    url: 'https://successlending.sf3.tomnx.com/permalink/contactus',
    nmls: '#2232431',
    boxAd: {
      text: 'We have the programs\nyou need to get the\nmortgage you want',
      btn: 'Get Pre-Approved',
    },
    rectangleAd: {
      text: 'Tell us what you’re looking for so we\ncan match you with the perfect mortgage',
      btn: 'Get Pre-Approved',
    },
    fullAd: {
      text: 'Improve the accuracy of your monthly payment quote\nby contacting one of our home loan professionals',
      btn: 'Click Here To Learn More',
    },
    mobileBoxAd: {
      text: 'We have the programs\nyou need to get the\nmortgage you want',
      btn: 'Get Pre-Approved',
    },
  },
  3990: {
    name: 'Crown',
    hasEqualHousing: true,
    logo: Crown,
    color: '#0065a8',
    disclaimerUrl: 'https://www.insiderealestate.com/about-us',
    url: 'https://www.insiderealestate.com/core-home',
    nmls: '#1234567',
    boxAd: {
      text: 'Get a $1,500 credit\nwhen you work with\nyour eXp Realty agent',
      btn: 'Click For More Info',
    },
    rectangleAd: {
      text: 'Worried about closing costs?\nYour $1,500 lender credit is waiting for you.',
      btn: 'Get Pre-Approved',
    },
    fullAd: {
      text: 'Thinking of refinancing? Talk to a lender to compare rates\nagainst your current mortgage and see how much equity you can access.',
      btn: 'Click Here To Learn More',
    },
    mobileBoxAd: {
      text: 'Get a $1,500 credit\nwhen you work with\nyour eXp Realty agent',
      btn: 'Click For More Info',
    },
    mobileBoxAdImage: {
      text: 'Get a $1,500\n credit when\n you wo',
      btn: 'Click For More Info',
      image: House,
    },
  },
  369: {
    name: 'Lifetime Home Loans',
    hasEqualHousing: false,
    logo: LifetimeHomeLoans,
    color: '#03253d',
    disclaimerUrl: 'https://lifetimehomeloans.com/disclosure/',
    url: 'https://lifetimehomeloans.com/pre-approved-corehome/',
    nmls: '#1861406',
    boxAd: {
      text: 'Shop Rates, You Save\nFast & Easy Approval\nSoft Credit Pull',
      btn: 'CLICK TO LIVE CHAT!',
    },
    rectangleAd: {
      text: 'GOT EQUITY IN YOUR CURRENT HOUSE?\nREMODEL, INVEST, PAY OFF DEBT.',
      btn: 'FAST & EASY CASH OUT!',
    },
    fullAd: {
      text: 'EASY LIVE CHAT TO ANSWER ALL YOUR MORTGAGE QUESTIONS\nQUICK RESPONSE WITH OUR SENIOR LOAN OFFICERS',
      btn: 'CLICK TO LIVE CHAT!',
    },
    mobileBoxAd: {
      text: 'SHOP RATES, YOU SAVE\nFAST & EASY APPROVAL\nSOFT CREDIT PULL',
      btn: 'CLICK TO LIVE CHAT!',
    },
  },
};

export const credibleAdsConfig: AdConfig = {
  disclaimerText: `Credible Insurance, Inc. d/b/a Credible Insurance Agency (CA Lic. `,
  name: 'Credible',
  hasEqualHousing: false,
  logo: Credible,
  color: '#2755A2',
  disclaimerUrl:
    'https://www.credible.com/insurance/partner/inside-real-estate?p=311&slug=inside-real-estate',
  url: 'https://www.credible.com/insurance/partner/inside-real-estate?p=311&slug=inside-real-estate',
  nmls: '#0M90597',
  boxAd: {
    text: 'Compare\nyour home insurance\ninsurance',
    btn: 'GET YOUR FREE QUOTE NOW',
  },
  rectangleAd: {
    text: 'How does your home\ninsurance coverage compare?',
    btn: 'GET YOUR FREE QUOTE NOW',
  },
  fullAd: {
    text: 'How does your home insurance coverage compare?',
    btn: 'GET YOUR FREE QUOTE NOW',
  },
  mobileBoxAd: {
    text: 'How does your\nhome insurance\ncoverage compare?',
    btn: 'GET YOUR FREE QUOTE',
  },
  mobileBoxAdImage: {
    text: 'Compare\nyour home insurance\ninsurance',
    btn: 'GET YOUR FREE QUOTE NOW',
    image: House,
  },
};
