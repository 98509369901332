import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { ErrorMsg, InputBox } from '../../../SideMenu.style';

type Props = {
  placeholder: string;
  type?: string;
  handleChange?: (e: ChangeEvent) => void;
  handleBlur?: (e: ChangeEvent) => void;
  value: string;
  error?: string | undefined | boolean;
  name: string;
  hasMt?: boolean;
  className?: string;
};

function InputField({
  placeholder,
  type = 'text',
  handleChange,
  handleBlur,
  value,
  error,
  name,
  hasMt,
  className,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <InputBox hasMt={hasMt}>
        <Input
          placeholder={t(placeholder)}
          type={type}
          onChange={handleChange}
          name={name}
          onBlur={handleBlur}
          value={value}
          className={className}
        />
      </InputBox>
      {error && <ErrorMsg>{error}</ErrorMsg>}
    </>
  );
}

export default InputField;

const Input = styled.input`
  border: none;
  outline: none;
  margin-left: 0.5rem;
  flex-grow: 1;
  min-width: 0;
`;
