import { colorOpacityApplier } from '../services/colorOpacityApplier';
import { BrandingDetailsExtended } from '../types';

const baseColors = {
  white: '#FFFFFF',
  black: '#151515',
  red: '#DF1726',
  green: '#45CE12',
  greenDark: '#55A047',
  greenChecked: '#2B9638',
  yellow: '#F8B11D',
  cinnabar: '#EE4036 ',
  grey: {
    main: '#E6E6E6',
    light: '#E7E7E7',
    ultraLight: '#F5F5F5',
    navIconInverted: '#E6EBEE',
    dark: '#989797',
    dark2: '#979696',
    dark3: '#2E2606',
    dark4: '#070504',
    dark5: '#888C8F',
    dark6: '#F2F2F2',
    dark7: '#F6F6F6',
    dark8: '#D9D9D9',
    dark9: '#888888',
    dark10: '#FAFAFA',
    dark11: '#DADADA',
    dark12: '#666666',
    dark13: '#E0E0E0',
    dark14: '#DDDDDD',
    dark15: '#999999',
  },
  linkText: '#007AFF',
};
export type BaseColorsType = typeof baseColors;

const backgroundColors = {
  main: baseColors.grey.ultraLight,
  light: baseColors.white,
};

const statusColors = {
  error: {
    main: baseColors.red,
  },
  success: {
    main: baseColors.green,
  },
};

const inputColors = {
  lightGrey: '#f0f0f0',
  mediumGrey: '#E0E0E0',
  darkGrey: '#989797',
};
const borderColors = {
  main: baseColors.grey.light,
};

const colors = {
  ...baseColors,
  secondary: {
    main: baseColors.black,
  },
  background: backgroundColors,
  border: borderColors,
  status: statusColors,
  input: inputColors,
};

const borderRadius = {
  xs: '1px',
  sm: '2px',
  md: '3px',
  lg: '4px',
  xl: '6px',
  ul: '8px',
  xsRem: '0.1rem',
  smRem: '0.2rem',
  mdRem: '0.3rem',
  lgRem: '0.4rem',
  xlRem: '0.6rem',
  ulRem: '0.8rem',
  rounded: '100%',
};

const spacings = {
  xs: '2px',
  sm: '4px',
  md: '8px',
  lg: '12px',
  xl: '16px',
  ul: '20px',
  xul: '32px',
  xsRem: '0.2rem',
  smRem: '0.4rem',
  mdRem: '0.8rem',
  lgRem: '1.2rem',
  xlRem: '1.6rem',
  ulRem: '2rem',
  xulRem: '3.2rem',
};

const fontSize = {
  xs: '10px',
  sm: '12px',
  md: '14px',
  lg: '16px',
  xl: '18px',
  ul: '22px',
  xsRem: '1rem',
  smRem: '1.2rem',
  mdRem: '1.4rem',
  lgRem: '1.6rem',
  xlRem: '1.8rem',
  ulRem: '2.2rem',
};

const fontWeight = {
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

const fontFamily = {
  primary: '"proxima-nova", sans-serif',
  primaryBold: '"proxima-nova", sans-serif',
  button: '"work-sans", sans-serif',
};

const boxShadow = {
  main: `0px 2px 2px #0000001A`,
  navBar: '0px -4px 12px #0000000b',
};

export const breakpoint = {
  sm: {
    min: '0',
    max: '576px',
  },
  md: {
    min: '577px',
    max: '768px',
  },
  lg: {
    min: '769px',
    max: '1280px',
  },
};

const theme = (brandingDetail?: BrandingDetailsExtended) => ({
  colorPrimary: brandingDetail?.themeColorHex ?? colors.grey.dark, // TODO: Replace everywhere with colors.primary.main
  colorSecondary: colors.secondary.main, // TODO: Replace everywhere with colors.secondary.main
  borderRadius,
  spacings,
  fontWeight,
  fontSize,
  fontFamily,
  breakpoint,
  colors: {
    ...colors,
    branding: {
      dark: colorOpacityApplier(
        brandingDetail?.themeColorHex ?? colors.grey.dark,
        '1.2',
      ),
      main: brandingDetail?.themeColorHex ?? colors.grey.dark,
      light: colorOpacityApplier(
        brandingDetail?.themeColorHex ?? colors.grey.dark,
        '0.7',
      ),
      mediumLight: colorOpacityApplier(
        brandingDetail?.themeColorHex ?? colors.grey.dark,
        '0.5',
      ),
      extraLight: colorOpacityApplier(
        brandingDetail?.themeColorHex ?? colors.grey.dark,
        '0.1',
      ),
    },
    primary: {
      main: '#3DA4E7',
    },
    font: {
      primary: baseColors.black,
    },
    background: {
      main: baseColors.grey.ultraLight,
      light: baseColors.white,
      gradient: `linear-gradient(rgba(
        ${baseColors.black},
        0,
      ), rgba(${baseColors.black}, 0.88))`,
    },
  },
  boxShadow,
  min: (breakpoint: number, vertical = false) =>
    `@media (min-${
      vertical ? 'height' : 'width'
    }: calc(${breakpoint}px + 0.02px))`,
  max: (breakpoint: number, vertical = false) =>
    `@media all and (max-${vertical ? 'height' : 'width'}: ${breakpoint}px)`,
  between: (breakpointMin: number, breakpointMax: number, vertical = false) =>
    `@media (max-${
      vertical ? 'height' : 'width'
    }: ${breakpointMax}px) and (min-${
      vertical ? 'height' : 'width'
    }: calc(${breakpointMin}px + 0.02px))`,
});

export type Theme = ReturnType<typeof theme>;

export default theme;
