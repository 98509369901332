import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import useBreakpoint from '../../../../utils/useBreakpoint';
import useHasProfile from '../../../../hooks/query/useHasProfile';
import { useAuthModal } from '../../../../providers/AuthModalProvider';
import { Button } from '../../../../components/UI/Button';

interface Props {
  isLoading?: boolean;
}

export function LoginButton({ isLoading = false }: Props): JSX.Element {
  const { t } = useTranslation();
  const hasProfile = useHasProfile();
  const { onOpenModal } = useAuthModal();
  const { isLargeScreen } = useBreakpoint();

  const dataQa = hasProfile ? 'login-button' : 'sign-up-button';
  const btnText = hasProfile ? t('header.login') : t('header.signUp');

  return (
    <LoginContainer borderLeft={isLargeScreen}>
      <StyledButton
        data-qa={dataQa}
        disabled={isLoading}
        onClick={() => onOpenModal()}
        variant={isLargeScreen ? 'contained' : 'outlined'}
      >
        {btnText}
      </StyledButton>
    </LoginContainer>
  );
}

interface WithBorderLeft {
  borderLeft: boolean;
}

const LoginContainer = styled('div')<WithBorderLeft>(
  ({ borderLeft, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: borderLeft ? '3.2rem' : 0,
    borderLeft: borderLeft ? `.1rem solid ${theme.colors.grey.main}` : 'none',
  }),
);

const StyledButton = styled(Button)(({ theme, variant }) => ({
  padding: 0,
  fontWeight: 500,
  minWidth: '8rem',
  height: '3.2rem',
  fontSize: '1.2rem',
  lineHeight: '1.2rem',
  textTransform: 'uppercase',
  borderColor: theme.colors.branding.main,
  color:
    variant === 'outlined' ? theme.colors.branding.main : theme.colors.white,
}));
