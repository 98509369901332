import { useValuationDetails } from '../pages/MyHome/hooks/useValuationDetails';
import { useHomeownerByContact } from './useHomeownerByContact';

export const useHomeownerAddressSuggestion = () => {
  const { valuationDetails } = useValuationDetails();
  const { data: homeownerDetails } = useHomeownerByContact();

  if (valuationDetails[0]?.Address) return valuationDetails[0].Address;

  return homeownerDetails?.Address;
};
