import { ListingDetails } from '../types';

export function getListingDate(listing: ListingDetails): number {
  const status = listing?.ListingSummary?.listingStatus;

  const saleDate = listing?.SalePrice?.saleDate ?? '';
  const listingDate = listing?.ListingSummary?.listingDate ?? '';

  const dateValue = status === 'Sold' ? saleDate : listingDate;

  return new Date(dateValue).getTime();
}
