import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { Icon, IconType } from '../../../../icon';
import { AccordionPanel } from '../../SideMenu.style';

type Props = {
  label: string;
  setChildPanelOpenInfo: (childName: string) => void;
  PanelContent: JSX.Element;
  childPanelOpen: string;
};

function PanelComponent({
  label,
  PanelContent,
  setChildPanelOpenInfo,
  childPanelOpen,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [panelIsOpen, setPanelIsOpen] = useState(false);

  const OpenCloseIcon = panelIsOpen
    ? IconType.minusCircle
    : IconType.plusCircle;

  useEffect(() => {
    if (label !== childPanelOpen) {
      setPanelIsOpen(false);
    }
  }, [childPanelOpen, label]);

  const dataQa = t(label).toLowerCase().replace(' ', '-');
  return (
    <Container data-qa={dataQa}>
      <PanelItem
        onClick={() => {
          setPanelIsOpen((prev) => !prev);
          setChildPanelOpenInfo(label);
        }}
      >
        <PanelItemLabel>{t(label)}</PanelItemLabel>
        <PanelItemIconWrap>
          <Icon type={OpenCloseIcon} />
        </PanelItemIconWrap>
      </PanelItem>

      <AccordionPanel show={panelIsOpen}>
        <PanelContentWrap>{panelIsOpen ? PanelContent : null}</PanelContentWrap>
      </AccordionPanel>
    </Container>
  );
}
export default PanelComponent;

const Container = styled.div<{ hasBorderTop?: boolean }>`
  display: flex;
  flex-direction: column;
  // padding-left: 1.4rem;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
`;

const PanelItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 1rem 0 1rem;
`;

const PanelItemIconWrap = styled.div`
  padding-right: 2.4rem;
  svg {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey.dark};
  }
  cursor: pointer;
`;

const PanelItemLabel = styled.div`
  padding-left: 4rem;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const PanelContentWrap = styled.div`
  padding: 1rem 2rem;
  background: #f0f0f0;
`;
