import React, { useContext, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import visitedRoutesAtom from '../state/visitedRoutesAtom';
import { useHistory } from 'react-router-dom';

type VisitedRoutesType = {
  visitedRoutes: string[];
};
const VisitedRoutesContext = React.createContext<VisitedRoutesType | undefined>(
  undefined,
);

export const useVisitedRoutes = (): VisitedRoutesType => {
  const context = useContext(VisitedRoutesContext);

  if (!context) {
    throw new Error(
      'useVisitedRoutes cannot be used without VisitedRoutesProvider',
    );
  }

  return context;
};

type ProviderProps = {
  children: React.ReactNode;
};

export function VisitedRoutesProvider({ children }: ProviderProps) {
  const history = useHistory();

  const [visitedRoutes, setVisitedRoutes] = useRecoilState(visitedRoutesAtom);

  useEffect(() => {
    const currentPath = `${history.location.pathname}${history.location.search}`;

    if (currentPath == visitedRoutes[visitedRoutes.length - 1]) return;

    setVisitedRoutes((prev) => [...prev, currentPath]);
  }, [
    history.location.pathname,
    history.location.search,
    setVisitedRoutes,
    visitedRoutes,
  ]);

  const value = useMemo(
    () => ({
      visitedRoutes,
    }),
    [visitedRoutes],
  );

  return (
    <VisitedRoutesContext.Provider value={value}>
      {children}
    </VisitedRoutesContext.Provider>
  );
}
