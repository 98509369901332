export const constructUrl = (baseUrl, parameters) => {
  if (!parameters) {
    return baseUrl;
  }

  const params = Object.keys(parameters).map(
    (key) => `${key}=${parameters[key]}`,
  );

  return `${baseUrl}?${params.join('&')}`;
};
