import QRious from 'qrious';
import { FC, useEffect, useRef } from 'react';

interface Props {
  value?: string;
  size?: number;
}
export const QRImage: FC<Props> = ({ value, size = 100 }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (!canvasRef.current) return;

    const qr = new QRious({ element: canvasRef.current, value, size });
    qr.backgroundAlpha = 0;
  }, [value, size]);

  if (!value) return null;

  return <canvas data-qa="qr-image" ref={canvasRef} />;
};
