import styled from '@emotion/styled';
import { Layer } from '../../../types';

type WithOpen = {
  isOpen: boolean;
};

type WithRelativePosition = {
  isRelativePosition?: boolean;
};

type WithDisabled = {
  isDisabled?: boolean;
  isOpen?: boolean;
};

type ArrowWrapperProps = WithOpen & {
  customColor?: string;
};

export const DropdownButton = styled('div')<WithDisabled>(
  ({ isDisabled, isOpen }) => ({
    position: 'relative',
    zIndex: isOpen ? Layer.Enhanced : Layer.Default + 1,
    filter: `grayscale(${isDisabled ? 1 : 0})`,
  }),
);

export const DropdownButtonContent = styled('div')<WithOpen & WithDisabled>(
  ({ theme, isOpen, isDisabled }) => ({
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    backgroundColor: theme.colors.white,
    boxShadow: '2px 2px 4px #03050426',
    borderRadius: isOpen
      ? `${theme.borderRadius.xlRem} ${theme.borderRadius.xlRem} 0 0`
      : theme.borderRadius.xlRem,
    display: 'flex',
    alignItems: 'center',
  }),
);

export const ContentWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
});

export const ArrowWrapper = styled('div')<ArrowWrapperProps>(
  ({ theme, isOpen, customColor }) => ({
    fontSize: '12px',
    color: customColor || theme.colors.grey.dark2,
    marginRight: '0.8rem',
    transform: isOpen ? 'rotate(180deg)' : 'none',
    transition: 'all .2s ease-in-out',
    marginLeft: 'auto',
  }),
);

export const DropdownMenu = styled('div')<WithOpen & WithRelativePosition>(
  ({ theme, isOpen, isRelativePosition }) => ({
    position: isRelativePosition ? 'relative' : 'absolute',
    top: isRelativePosition ? 0 : '100%',
    left: 0,
    width: '100%',
    backgroundColor: theme.colors.white,
    boxShadow: '2px 2px 4px #03050426',
    borderRadius: `0 0 ${theme.borderRadius.xlRem} ${theme.borderRadius.xlRem}`,

    height: isOpen ? 'auto' : 0,
    overflow: 'hidden',
    transition: 'all .2s linear',
    zIndex: isOpen ? Layer.Enhanced : Layer.Default + 1,
  }),
);
