import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import Styled from './MlsDisclaimer.style';
import { MlsDetails } from '../../../types';
import { formatLongDate } from '../../../utils/date-helper';

interface Props {
  mls: MlsDetails;
}

const MlsDisclaimer: FC<Props> = ({ mls }) => {
  const { t } = useTranslation();

  return (
    <Styled.Container>
      {mls.refreshDate && (
        <Styled.Date>
          {t('mlsDisclaimer.updatedOn', {
            date: formatLongDate(mls.refreshDate),
          })}
        </Styled.Date>
      )}
      <Styled.Disclaimer
        dangerouslySetInnerHTML={{ __html: mls.mlsDisclaimer }}
      ></Styled.Disclaimer>
      <Styled.Logos>
        <img src={mls.mlsLogo} alt={mls.mlsName} />
      </Styled.Logos>
    </Styled.Container>
  );
};

export default MlsDisclaimer;
