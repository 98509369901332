import { FC, useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { PatternFormat } from 'react-number-format';
import { FormikValues, useFormikContext } from 'formik';

import { Input } from '../../../Input/Input';
import { useOptionalProps } from '../hooks/useOptionalProps';
import { useWindowWidth } from '../../../../../hooks/useWindowSize';

interface Props {
  hasInitialValue: boolean;
}

const CustomInput = (props: Record<string, unknown>) => {
  const { t } = useTranslation();
  const screenSize = useWindowWidth();

  const [hasInitialValue, setHasInitialValue] = useState<boolean>(false);

  const { errors, values, handleChange } = useFormikContext<FormikValues>();

  const optionalProps = useOptionalProps(hasInitialValue);

  useEffect(() => {
    if (!values.phoneNumber) return;
    setHasInitialValue(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Input
      type="text"
      focusOnIconClick
      name="phoneNumber"
      style={{
        marginTop: '1rem',
        width: screenSize < 577 ? '30rem' : '35rem',
      }}
      onChange={handleChange}
      value={values.phoneNumber}
      error={String(errors.phoneNumber ?? '')}
      placeholder={t('auth.profileCreation.inputLabels.phoneNumber')}
      {...props}
      {...optionalProps}
    />
  );
};

export const PhoneNumber: FC<Props> = () => {
  const { values, handleChange } = useFormikContext<FormikValues>();

  return (
    <PatternFormat
      format="### ### ####"
      onChange={handleChange}
      value={values.phoneNumber}
      customInput={CustomInput}
    />
  );
};
