import { useMemo } from 'react';
import { useBrandingAndAgent } from './useBrandingAndAgent';

export const useDownloadAppUrl = (): string => {
  const { isLoading, data } = useBrandingAndAgent();

  return useMemo(() => {
    if (isLoading || (!data?.iosLink && !data?.androidLink)) return '';

    return data?.androidLink || data?.iosLink || '';
  }, [data, isLoading]);
};
