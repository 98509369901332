import React from 'react';
import { useTranslation } from 'react-i18next';

import S from './AgentDetails.style';
import { useBrandingAndAgent } from '../../hooks/useBrandingAndAgent';

export const AgentDetails: React.FC = () => {
  const { t } = useTranslation();
  const { data } = useBrandingAndAgent();

  if (!data?.agentName) return null;

  return (
    <S.Container>
      <S.Logo src={data?.logo} />

      <S.AgentInfo>
        <S.ImageContainer>
          <S.Image src={data?.agentHeadshot} alt="agent-avatar" />
        </S.ImageContainer>
        <S.AgentDetails>
          <S.AgentName>{data?.agentName}</S.AgentName>
          <S.Button
            onClick={() => window.open(data?.agentContactLink, '_blank')}
          >
            {t('mobileAppDeepLink.contactMe')}
          </S.Button>
        </S.AgentDetails>
      </S.AgentInfo>
    </S.Container>
  );
};
