import styled from '@emotion/styled';

const Page = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.colors.grey.ultraLight,
  paddingBottom: '3rem',
  position: 'relative',
  fontFamily: theme.fontFamily.primary,
}));

const Main = styled('main')(({ theme }) => ({
  width: '90%',
  maxWidth: '965px',
  margin: '0 auto',
  border: `1px solid ${theme.colors.grey.main}`,
  borderRadius: theme.borderRadius.lgRem,
  backgroundColor: theme.colors.white,
  '@media all and (max-width: 768px)': {
    height: 'auto',
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const ImagesContainer = styled('div')({
  margin: '230px auto 0 auto',
  width: '72%',
  minWidth: '56.6rem',
  '@media all and (max-width: 768px)': {
    width: '92%',
    minWidth: '27rem',
    margin: '230px auto 0 auto',
  },
});

const Description = styled('p')(({ theme }) => ({
  fontSize: '2.5rem',
  lineHeight: '3rem',
  color: theme.colors.black,
  fontFamily: theme.fontFamily.primary,
  fontWeight: theme.fontWeight.semibold,
  textAlign: 'center',
  width: '78%',
  margin: '1.2rem auto 0  auto',
  '@media all and (max-width: 768px)': {
    display: 'none',
    paddingBottom: '2rem',
  },
}));

const GetAppContainer = styled('div')({
  width: '80%',
  margin: '4.5rem auto 0 auto',
});

export default {
  Page,
  Main,
  ImagesContainer,
  Description,
  GetAppContainer,
};
