import { FC } from 'react';

import { useTheme } from '@emotion/react';

import { Icon, IconType } from '../../../../icon';

export const Pencil: FC = () => {
  const theme = useTheme();
  return (
    <Icon
      type={IconType.PencilAlt}
      style={{
        color: theme.colors.grey.dark,
      }}
    />
  );
};
