import { useMemo } from 'react';
import { PropTypeCategory } from '../../../types';
import { useHomeownerPropertyDetails } from '../../../hooks/query/useHomeownerPropertyDetails';

export function useHomeownerBedsSqFtAndCategory(): {
  sqFt: number;
  beds: number;
  category: PropTypeCategory;
} {
  const { data } = useHomeownerPropertyDetails();

  const sqFt = useMemo(
    () =>
      data?.PropertySummary?.livingSpaceSize?.reduce(
        (sum, part) => (part.unit === 'sqft' ? sum + part.value : 0),
        0,
      ) ?? 0,
    [data?.PropertySummary?.livingSpaceSize],
  );

  const beds = data?.PropertySummary?.beds || 0;

  const category =
    data?.PropertySummary?.propertyTypeCategory ||
    PropTypeCategory.SINGLE_FAMILY;

  return useMemo(
    () => ({
      beds,
      sqFt,
      category,
    }),
    [beds, sqFt, category],
  );
}
