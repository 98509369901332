import styled from '@emotion/styled';

import { useWindowWidth } from '../../../../hooks/useWindowSize';
import { FormContainerColumn } from '../RegistrationSteps.style';
import PasswordInputGeneric from '../../PasswordInput/PasswordInput';

interface WithMarginTop {
  marginTop?: string;
}

export const PasswordInputWrapper = styled(FormContainerColumn)(() => {
  const windowWidth = useWindowWidth();
  return {
    width: '100%',
    padding: windowWidth < 577 ? '0' : '0 3rem',
  };
});

export const PasswordInput = styled(PasswordInputGeneric)<WithMarginTop>(
  ({ marginTop }) => ({
    marginTop: marginTop ?? '0',
    '&.password-creation': { width: '100%' },
  }),
);
