import { useTheme } from '@emotion/react';

export function MyHomeIcon(): JSX.Element {
  const theme = useTheme();
  const brandingColor = theme.colors.branding.main;

  return (
    <svg
      width="55"
      height="55"
      fill="none"
      viewBox="0 0 55 55"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="55" height="55" rx="3" fill="white" />
      <rect width="55" height="55" rx="3" fill={brandingColor} />
      <path
        fill="white"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.3333 23.0306V39.2186C40.3333 41.1314 38.8381 42.6874 37 42.6874H17.0002C15.1621 42.6874 13.6669 41.1314 13.6669 39.2186V23.0306C13.6669 23.0035 13.6539 22.9809 13.6522 22.9544L27.0001 10.6976L40.348 22.9544C40.3462 22.9809 40.3333 23.0035 40.3333 23.0306H40.3333ZM35.8888 12.624H40.3333V19.8555L35.8888 15.7744V12.624ZM47 26.4997C47 26.1794 46.8731 25.8606 46.6243 25.6322L42.5518 21.8927C42.552 21.8862 42.5554 21.8808 42.5554 21.8743V11.4677C42.5554 10.8286 42.0584 10.3114 41.4442 10.3114H34.7777C34.1635 10.3114 33.6666 10.8286 33.6666 11.4677V13.7341L27.7357 8.28796C27.5251 8.09591 27.2626 8 27 8C26.7374 8 26.4749 8.09591 26.2643 8.28796L7.3757 25.6322C7.12695 25.8605 7 26.1793 7 26.4996C7 27.1377 7.49561 27.6552 8.11193 27.6552C8.37282 27.6552 8.63501 27.5603 8.84699 27.3667L11.4447 24.9815V39.2186C11.4447 42.4075 13.9359 45 17.0001 45H36.9999C40.0641 45 42.5553 42.4075 42.5553 39.2186V24.9815L45.153 27.3667C45.3656 27.5609 45.626 27.6557 45.8887 27.6557C46.5025 27.6557 47 27.1388 47 26.4996L47 26.4997ZM29.2223 26.4995C29.2223 27.7746 28.2253 28.812 27.0001 28.812C25.7749 28.812 24.7779 27.7746 24.7779 26.4995C24.7779 25.2242 25.7749 24.1869 27.0001 24.1869C28.2253 24.1869 29.2223 25.2243 29.2223 26.4995ZM22.5557 26.4995C22.5557 29.0538 24.5456 31.1246 27.0001 31.1246C29.4546 31.1246 31.4445 29.0538 31.4445 26.4995C31.4445 23.9451 29.4546 21.8743 27.0001 21.8743C24.5456 21.8743 22.5557 23.9451 22.5557 26.4995ZM24.7779 33.4372C21.7096 33.4372 19.2224 36.0255 19.2224 39.2186C19.2224 39.8572 19.7199 40.3749 20.3335 40.3749C20.9471 40.3749 21.4446 39.8572 21.4446 39.2186C21.4446 37.3028 22.937 35.7498 24.7779 35.7498H29.2223C31.0632 35.7498 32.5556 37.3028 32.5556 39.2186C32.5556 39.8572 33.0531 40.3749 33.6667 40.3749C34.2803 40.3749 34.7778 39.8572 34.7778 39.2186C34.7778 36.0255 32.2906 33.4372 29.2223 33.4372H24.7779Z"
      />
    </svg>
  );
}
