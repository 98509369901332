import styled from '@emotion/styled';

import { Text } from '../RegistrationSteps.style';

export const Skip = styled(Text)(({ theme }) => ({
  fontSize: '1.4rem',
  color: theme.colors.grey.dark,
  textDecoration: 'underline',
  textAlign: 'center',
  cursor: 'pointer',
  marginRight: '7.4rem',
}));
