import styled from '@emotion/styled';
import { useMessageCount } from '../../../hooks/query/useMessageCount';

export default function UnreadMessagesCount() {
  const { data } = useMessageCount();

  if (!data?.receivedMessagesUnread) return null;

  return (
    <Wrapper>
      {data.receivedMessagesUnread > 9 ? '9+' : data.receivedMessagesUnread}
    </Wrapper>
  );
}

const Wrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.cinnabar,
  borderRadius: '50%',
  position: 'absolute',
  top: 6,
  left: 3,
  fontSize: 8,
  height: '14px',
  width: '14px',
  padding: '3px 2px 2px 3px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  color: '#fff',
  textAlign: 'center',
  boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.25)',
}));
