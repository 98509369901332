import styled from '@emotion/styled';

const Footer = styled('footer')({
  width: '100%',
  position: 'relative',
  height: '100%',
  minHeight: '550px',
});

const Container = styled('div')(({ theme }) => ({
  margin: '0',
  backgroundColor: theme.colors.branding.main,
  clipPath: 'polygon(50% 0, 100% 300px, 100% 100%, 0 100%, 0 300px)',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: '100%',
  padding: '42rem 0 0 0',
}));

const Description = styled('p')(({ theme }) => ({
  color: theme.colors.white,
  fontSize: '2.5rem',
  fontWeight: theme.fontWeight.semibold,
  width: '80%',
  margin: '0 auto',
  textAlign: 'center',
  maxWidth: '74rem',
}));

export default { Footer, Container, Description };
