import { useQuery, UseQueryResult } from 'react-query';

import { hopApi } from '../../api/hop';
import { apiRoutes } from '../../constants';
import { FeatureFlags, FeatureFlag } from '../../types';

const fetchFeatureFlags = async (): Promise<FeatureFlags> => {
  const { data } = await hopApi.get(apiRoutes.kvCoreFeatures.byAgentId());

  return data.reduce(
    (acc: FeatureFlags, curr: FeatureFlag) => ({
      ...acc,
      [curr.feature]: { ...curr },
    }),
    {},
  );
};

export const FEATURE_SERVICE_QUERY_KEY = 'agentFeatureFlags';

export function useAgentFeatureFlags(
  agentId?: number | string,
): UseQueryResult<FeatureFlags> {
  return useQuery(
    [FEATURE_SERVICE_QUERY_KEY, agentId],
    () => fetchFeatureFlags(),
    {
      enabled: Boolean(agentId),
      refetchInterval: 10 * 60 * 1000,
      refetchIntervalInBackground: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: 60 * 60 * 1000,
    },
  );
}
