import { useMemo } from 'react';

import { useNotificationsCount } from '../../NotificationPanel';

export function useFormattedCount(): string {
  const { data } = useNotificationsCount();

  return useMemo(() => {
    if (!data?.unread) return '';
    return data?.unread > 9 ? '9+' : String(data?.unread);
  }, [data]);
}
