export type PixesOffset = {
  x: number;
  y: number;
};

export const getPixelPositionOffset = (
  width: number,
  height: number,
): PixesOffset => ({
  x: -(width / 2),
  y: -height,
});
