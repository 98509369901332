import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from 'react';

import {
  ListingDetails,
  SimilarHomesListing,
  TSimilarHomesCriteria,
} from '../../../types';
import { useInitSimilarHomes } from './useInitSimilarHomes';
import { useSimilarHomesQuery } from '../hooks/useSimilarHomesQuery';
import { useExcludeSimilarHomes } from './ExcludedSimilarHomesProvider';
import { useHomeownerByContact } from '../../../hooks/useHomeownerByContact';
import { useHomeownerBedsSqFtAndCategory } from '../hooks/useHomeownerBedsAndSqFt';
import { useHomeownerPropertyCoordinates } from '../hooks/useHomeownerPropertyCoordinates';

export enum LessThenMinSHtrigger {
  FILTER = 'filter',
  EXCLUDE = 'exclude',
}

export enum ShNumberLimit {
  INIT = 3,
  EXTENDED = 6,
}

type SHContext = {
  isLoading: boolean;
  isFetching: boolean;
  hadLessThanMin: boolean;
  data?: SimilarHomesListing[];
  resetHadLessThanMin: () => void;
  criteria?: TSimilarHomesCriteria;
  setBeds: (value: number) => void;
  setRange: (value: number) => void;
  lessThanMinTrigger: string | null;
  setHadLessThanMinReached: () => void;
  setShNumberLimit: (value: number) => void;
  setSqFt: (min: number, max: number) => void;
  handleLessThanMinTrigger: (trigger: string | null) => void;
  setSortedSimilarHomes: (sortedHomes: ListingDetails[]) => void;
};

function appendExcludedProp(
  excludedMap: Record<string, string>,
  listings?: ListingDetails[],
): SimilarHomesListing[] | undefined {
  return listings?.map((sh) => ({
    ...sh,
    isExcluded: Boolean(excludedMap[sh.uuid]),
  }));
}

const SimilarHomesContext = React.createContext<SHContext | undefined>(
  undefined,
);

export const SimilarHomesProvider: React.FC = ({ children }) => {
  const [similarHomes, setSimilarHomes] = useState<
    SimilarHomesListing[] | undefined
  >();

  const lastValidCriteria = useRef<TSimilarHomesCriteria | undefined>(
    undefined,
  );
  const [criteria, setCriteria] = useState<TSimilarHomesCriteria | undefined>(
    undefined,
  );

  const [lessThanMinTrigger, setLessThanMinTrigger] = useState<
    LessThenMinSHtrigger.EXCLUDE | LessThenMinSHtrigger.FILTER | null
  >(null);

  const [hadLessThanMin, setHadLessThanMin] = useState(false);
  const [shNumberLimit, setShNumberLimit] = useState(ShNumberLimit.INIT);

  const { excludedMap } = useExcludeSimilarHomes();
  const { lat, lon } = useHomeownerPropertyCoordinates();
  const { beds, sqFt, category } = useHomeownerBedsSqFtAndCategory();

  const { data: initSimilarHomes, isLoading: isLoadingInit } =
    useInitSimilarHomes(shNumberLimit);

  useEffect(
    function onHomeEdit() {
      setSimilarHomes(undefined);
      setShNumberLimit(ShNumberLimit.INIT);
      lastValidCriteria.current = undefined;
    },
    [lat, lon, beds, sqFt, category],
  );

  const { data: similarHomesRaw, isLoading: isLoadingSH } =
    useSimilarHomesQuery(criteria);

  const validSimilarHomes = useMemo(() => {
    if (similarHomesRaw) return similarHomesRaw;

    return initSimilarHomes;
  }, [initSimilarHomes, similarHomesRaw]);

  const handleLessThanMinTrigger = useCallback((trigger) => {
    setLessThanMinTrigger(trigger);
  }, []);

  useEffect(() => {
    if (!validSimilarHomes) return;

    const markedSimilarHomes = appendExcludedProp(
      excludedMap,
      validSimilarHomes,
    );

    const notExcluded = markedSimilarHomes?.filter((sh) => !sh.isExcluded);
    const hasLessThenThree = (notExcluded?.length ?? 0) < ShNumberLimit.INIT;

    if (hasLessThenThree && !lastValidCriteria.current) return;

    if (hasLessThenThree) {
      setHadLessThanMin(true);
      setCriteria(lastValidCriteria.current);
      return;
    }

    setSimilarHomes(markedSimilarHomes);
    lastValidCriteria.current = criteria;
  }, [validSimilarHomes, shNumberLimit]); // eslint-disable-line

  useEffect(() => {
    setSimilarHomes((prev) => appendExcludedProp(excludedMap, prev));
  }, [excludedMap]);

  const resetHadLessThanMin = useCallback(() => {
    setHadLessThanMin(false);
  }, []);

  const setHadLessThanMinReached = useCallback(() => {
    setHadLessThanMin(true);
  }, []);

  const setBeds = useCallback((beds: number): void => {
    setCriteria((prev) => {
      if (!prev) return prev;

      return { ...prev, minBeds: beds };
    });
  }, []);

  const setRange = useCallback((range: number): void => {
    setCriteria((prev) => {
      if (!prev) return prev;

      return { ...prev, range };
    });
  }, []);

  const setSqFt = useCallback((min: number, max: number): void => {
    setCriteria((prev) => {
      if (!prev || !min || !max) return prev;

      return { ...prev, minPropertySize: min, maxPropertySize: max };
    });
  }, []);

  const setSortedSimilarHomes = useCallback((sortedSimilarHomes): void => {
    setSimilarHomes(sortedSimilarHomes);
  }, []);

  const isLoading = isLoadingInit;
  const isFetching = isLoadingInit || isLoadingSH;

  const value: SHContext = useMemo(
    () => ({
      setBeds,
      setSqFt,
      setRange,
      criteria,
      isLoading,
      isFetching,
      hadLessThanMin,
      setShNumberLimit,
      lessThanMinTrigger,
      data: similarHomes,
      resetHadLessThanMin,
      setSortedSimilarHomes,
      setHadLessThanMinReached,
      handleLessThanMinTrigger,
    }),
    [
      setBeds,
      setSqFt,
      setRange,
      criteria,
      isLoading,
      isFetching,
      similarHomes,
      hadLessThanMin,
      lessThanMinTrigger,
      resetHadLessThanMin,
      setSortedSimilarHomes,
      setHadLessThanMinReached,
      handleLessThanMinTrigger,
    ],
  );

  return (
    <SimilarHomesContext.Provider value={value}>
      {children}
    </SimilarHomesContext.Provider>
  );
};

export const useSimilarHomes = (): SHContext => {
  const similarHomesContext = useSimilarHomesWithExcluded();

  const { data: homeowner } = useHomeownerByContact();

  return useMemo(
    () => ({
      ...similarHomesContext,
      data: similarHomesContext.data?.filter(
        (sh) =>
          !sh.isExcluded &&
          homeowner?.Address?.fullAddress !== sh.Address.fullAddress,
      ),
    }),
    [homeowner?.Address?.fullAddress, similarHomesContext],
  );
};

export const useSimilarHomesWithExcluded = (): SHContext => {
  const context = useContext(SimilarHomesContext);

  if (!context) {
    throw new Error(
      'useSimilarHomesWithExcluded cannot be used without SimilarHomesProvider',
    );
  }

  return context;
};
