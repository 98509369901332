import { BrowserStorage } from '../../../services/BrowserStorage';

const storage = new BrowserStorage();

export class ForcedAdState {
  static accountId(): number {
    return (storage.getItem('force_ads_account') as number) || 0;
  }

  static office(): string {
    return (storage.getItem('force_agent_office_state') as string) || '';
  }
}
