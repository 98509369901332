import { useMemo } from 'react';

import { ListingDetails, PriceScale } from '../../../types';
import { useSearchParams } from '../../../providers/SearchParamsProvider';
import { useRangeFilterSteps } from './useRangeFilterSteps';

export function usePrices(listings?: ListingDetails[]): number[] {
  return useMemo(
    () =>
      listings?.reduce((prices: number[], listing) => {
        const price =
          listing.SalePrice.saleAmount ||
          listing.ListingPrice.listingPrice ||
          0;

        return price ? [...prices, price] : prices;
      }, []) ?? [],
    [listings],
  );
}

export function usePriceScale(prices: number[]): PriceScale {
  const { get } = useSearchParams();
  const searchType = get('sale')?.type;

  const { steps, getNoMinNoMaxSteps, findClosestStep } =
    useRangeFilterSteps(searchType);

  return useMemo(() => {
    prices.forEach((price) => {
      const step = findClosestStep(price);
      if (!step) return;

      steps[step] = (steps[step] ?? 0) + 1;
    });

    let orderedPrices = Object.keys(steps)
      .map((p) => Number(p))
      .sort((a, b) => a - b);

    let distributions = orderedPrices.map((step) => steps[step]);

    const { noMin, noMax } = getNoMinNoMaxSteps(orderedPrices);
    orderedPrices = [noMin, ...orderedPrices, noMax];
    distributions = !distributions.length
      ? []
      : [
          distributions[0],
          ...distributions,
          distributions[distributions.length - 1],
        ];

    return { prices: orderedPrices, distributions };
  }, [findClosestStep, getNoMinNoMaxSteps, prices, steps]);
}
