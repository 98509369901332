import styled from '@emotion/styled';
import { Global, css, useTheme } from '@emotion/react';
import { Container as BsContainer, Card as BsCard } from '@mui/material';
import { Layer } from '../../types';

export const GlobalStyling = () => {
  const theme = useTheme();
  return <Global styles={getGlobalStyling(theme)} />;
};

export const getGlobalStyling = (theme) =>
  css({
    html: {
      fontSize: '62.5%',
    },
    root: {
      '--amplify-primary-color': theme?.colors?.primary?.main,
      '--amplify-primary-shade': theme?.colors?.primary?.main,
      '--amplify-font-family': theme?.fontFamily?.primary,
    },
    '*': {
      margin: 0,
      padding: 0,
    },
    p: {
      margin: 0,
      padding: 0,
    },
    'html, body, #root': {
      fontFamily: theme?.fontFamily?.primary,
      color: theme?.colors?.fonts?.primary,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      flex: 1,
      lineHeight: 1.5,
      height: '100%',
      overflowX: 'hidden',
    },
    body: {
      margin: 0,
      touchAction: 'pan-x pax-y',

      fontSize: '1.2rem',
      backgroundColor: theme?.colors.branding?.extraLight,
    },

    a: {
      color: theme?.colors?.branding?.main,
      '&:hover': {
        textDecoration: 'none',
        color: theme?.colors?.branding?.main,
      },
    },
    '.navbar-expand > .container': {
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
    '.bm-overlay': {
      background: 'rgba(0, 0, 0, 0.5)',
    },
    '.pac-container': {
      zIndex: Layer.MustSee - 700,
    },
  });

const Center = styled.span`
  display: flex;
  align-items: center;
`;

const Headline = styled.div`
  color: ${(props) => props?.theme?.colors?.secondary?.main};
  font-size: 20px;
  font-weight: ${(props) => props?.theme?.fontWeights?.bold};
`;

const PageTitleLight = styled.div`
  color: ${(props) => props?.theme?.colorSecondary};
  font-size: 16px;
  font-weight: ${(props) => props?.theme?.fontWeights?.regular};
`;

const BodyBold = styled(Headline)`
  font-size: 14px;
`;

const BodyLight = styled.div`
  font-weight: ${(props) => props?.theme?.fontWeights?.light};
  font-size: 14px;
`;

const Metric = styled.div`
  display: flex;
  color: ${(props) => props.theme.colorPrimary};
  font-size: 3.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 3.8rem;
`;

const TrendNum = styled.div`
  color: ${({ theme, warning }) =>
    warning ? theme?.colors?.red : theme?.colors?.green};
  font-size: 16px;
  font-weight: ${(props) => props?.theme?.fontWeights?.bold};
  white-space: nowrap;
`;

const LabelLight = styled.span`
  text-transform: uppercase;
  font-size: 10px;
  color: ${(props) => props?.theme?.colors?.greyMid};
  font-weight: ${(props) => props?.theme?.fontWeights?.light};
`;

const LabelBold = styled(LabelLight)`
  font-weight: ${(props) => props?.theme?.fontWeights?.bold};
`;

const Label = styled.div`
  text-transform: uppercase;
  font-size: 1.15rem;
  color: ${(props) => props?.theme?.colors?.grey?.dark2};
  white-space: nowrap;
  font-weight: ${(props) => props?.theme?.fontWeight?.bold};
`;

const Container = styled(BsContainer)``;

const CardContainer = styled(Container)`
  padding-left: 2rem;
  padding-right: 2rem;

  &:not(.no-margin) {
    margin-bottom: 1.2rem;
  }
`;

/****
 * These styles are for bigger cards (that will contain other things,
 * such as listings or graphs)
 */
const Card = styled(BsCard)`
  background: ${({ theme }) => theme?.colors?.white};
  border: 0.1rem solid ${({ theme }) => theme?.colors?.border?.main};
  border-radius: 0.4rem;
  box-shadow: 0px 2px 2px #0000001a;
`;

const CardItemWrapper = styled.div`
  padding-bottom: 1.2rem;
`;

const CTA = styled('div')(({ theme }) => ({
  color: theme?.colorPrimary,
  fontSize: theme?.fontSize?.lgRem,
  fontWeight: theme?.fontWeight?.medium,
  lineHeight: '1.9rem',
}));

const Regular = styled.span`
  font-weight: ${(props) => props?.theme?.fontWeights?.regular};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Col = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const Styled = {
  LabelBold,
  Center,
  LabelLight,
  Label,
  Container,
  Headline,
  Metric,
  TrendNum,
  CTA,
  Row,
  Col,
  CardItemWrapper,
  CardContainer,
  Card,
  BodyBold,
  PageTitleLight,
  BodyLight,
  Regular,
};

export default Styled;
