import { useMemo } from 'react';
import styled from '@emotion/styled';

import { formatRawCurrency } from '../../../../utils/number-util';
import { ReducedPriceIcon } from '../../../../components/V2/Icons/ReducedPriceIcon';

interface ListingPriceProps {
  price?: number;
  reducedPrice?: number | null;
  dataQa?: string;
}

export function ListingPrice({
  price,
  reducedPrice,
  dataQa,
}: ListingPriceProps) {
  const isPriceReduced = useMemo(() => {
    return !!reducedPrice && reducedPrice < 0;
  }, [reducedPrice]);

  return (
    <Container data-qa={`${dataQa}-price`}>
      <Price>{formatRawCurrency(price)}</Price>
      {isPriceReduced && (
        <PriceReductionContainer>
          <IconWrapper>
            <ReducedPriceIcon color="#000" />
          </IconWrapper>
          <PriceReduction>
            ({formatRawCurrency(reducedPrice)?.replace('-', '')?.split('$')})
          </PriceReduction>
        </PriceReductionContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Price = styled.div`
  font-size: 1.7rem;
  color: #000;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 2.1rem;
`;

const PriceReductionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding-left: 1rem;
`;

const PriceReduction = styled.div`
  font-size: 1.1rem;
  color: #000;
  line-height: 1.3rem;
`;

const IconWrapper = styled.div`
  display: flex;
  width: 9px;
  height: 9px;
  margin-top: 2px;
`;
