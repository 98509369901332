import { FC, useCallback, useEffect } from 'react';
import EngagementPrompt from '../../../../components/V2/EngagementPrompt/EngagementPrompt';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { numberOfSinglePropertiesViewedAtom } from '../../../../features/Tile/state/numberOfSinglePropertiesViewedAtom';
import { ListingDetails } from '../../../../types';
import { useSearchParams } from '../../../../providers/SearchParamsProvider';
import { useSavedListings } from '../../../../hooks/query/saveListing/useSavedListings';

export const ListingInteractionTracker: FC = () => {
  const [propertiesViewed, setPropertiesViewed] = useRecoilState(
    numberOfSinglePropertiesViewedAtom,
  );
  const { getParams } = useSearchParams();
  const { areaType, areaId, countrySubd, polygon } = getParams();
  const { data: savedListings } = useSavedListings();

  useEffect(() => {
    setPropertiesViewed({});
  }, [polygon?.length, areaId, areaType, countrySubd, setPropertiesViewed]);

  const interactedWith = Object.keys(propertiesViewed).length;

  if (interactedWith !== 3 && savedListings?.length !== 0) return <></>;

  return (
    <EngagementPrompt
      showWhen={interactedWith === 3 && savedListings?.length === 0}
      title="See anything you might like?"
      confirmButtonText="Got it"
      description="Save listings you are interested in to receive updates on price changes and status."
    />
  );
};

export const useNewListingInteraction = () => {
  const setTimesOpened = useSetRecoilState(numberOfSinglePropertiesViewedAtom);

  return useCallback(
    (listing: ListingDetails) => {
      setTimesOpened((prev) => ({
        ...prev,
        [listing.MlsInfo.mlsId + '' + listing.mlsListingId]: true,
      }));
    },
    [setTimesOpened],
  );
};
