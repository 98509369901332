import { useCallback } from 'react';

import {
  InfiniteData,
  useQueryClient,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from 'react-query';

import { hopApi } from '../../../api/hop';
import { NotificationsWithMeta } from '../types';
import { apiRoutes } from '../../../constants/apiRoutes';

const NOTIFICATIONS_KEY = 'allNotifications';

async function fetchAllNotifications(
  page: number,
): Promise<NotificationsWithMeta> {
  const response = await hopApi.get<NotificationsWithMeta>(
    apiRoutes.notifications.get,
    {
      raw: true,
      params: {
        page,
      },
    },
  );

  return response.data;
}

export function useAllNotifications(): UseInfiniteQueryResult<NotificationsWithMeta> {
  return useInfiniteQuery(
    [NOTIFICATIONS_KEY],
    async ({ pageParam = 1 }) => fetchAllNotifications(Number(pageParam)),
    {
      staleTime: Infinity,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      getNextPageParam: ({ meta }) => {
        if (!meta?.next_page_url) return undefined;

        return meta.current_page + 1;
      },
    },
  );
}

export function useInvalidateAllNotifications(): () => Promise<void> {
  const queryClient = useQueryClient();

  return useCallback(
    () =>
      queryClient.invalidateQueries(NOTIFICATIONS_KEY, {
        refetchActive: true,
        refetchInactive: true,
      }),
    [queryClient],
  );
}

export function useAllNotificationsOptimistic() {
  const queryClient = useQueryClient();

  const update = (
    updaterFn: (
      oldData: InfiniteData<NotificationsWithMeta>,
    ) => InfiniteData<NotificationsWithMeta>,
  ) => {
    queryClient.cancelQueries(NOTIFICATIONS_KEY);

    const prevAllNotifications = queryClient.getQueryData(
      NOTIFICATIONS_KEY,
    ) as InfiniteData<NotificationsWithMeta>;

    queryClient.setQueryData(NOTIFICATIONS_KEY, () => {
      if (!prevAllNotifications?.pages?.length) return prevAllNotifications;

      return updaterFn(prevAllNotifications);
    });

    return prevAllNotifications;
  };

  const revert = (prev: InfiniteData<NotificationsWithMeta>) => {
    queryClient.setQueryData(NOTIFICATIONS_KEY, prev);
  };

  return { update, revert };
}
