import { useEffect, useMemo } from 'react';
import { useAccountDetails } from '../providers/Authentication/useAccountDetails';

export function useCHDisabledFlow(signOut: () => void) {
  const { data: accountDetails } = useAccountDetails();

  const isCHDisabled = useMemo(
    () => accountDetails?.isCoreHomeWebDisabled,
    [accountDetails?.isCoreHomeWebDisabled],
  );

  useEffect(() => {
    if (!isCHDisabled) return;

    async function logoutAndRedirect() {
      await signOut();
      window.location.assign('/');
    }

    logoutAndRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCHDisabled]);
}
