import { useTheme } from '@emotion/react';

import {
  INavGroupPaths,
  NavGroupSearchPath,
  NavGroupJourneyPath,
  NavGroupFavoritesPath,
  NavGroupeNotificationsPath,
} from '../../../types';
import { useNavActive } from '../../../utils/useNavActive';

type Props = {
  width?: number;
  height?: number;
  navGroupPaths?:
    | INavGroupPaths
    | NavGroupSearchPath
    | NavGroupJourneyPath
    | NavGroupFavoritesPath
    | NavGroupeNotificationsPath;
};

export function FavoritesMobileNavIcon({
  width,
  height,
  navGroupPaths,
}: Props): JSX.Element {
  const theme = useTheme();
  const { isActiveNavGroup } = useNavActive(navGroupPaths);

  const svgWidth = width || 28;
  const svgHeight = height || 28;

  const backgroundColor = isActiveNavGroup
    ? theme.colors.branding.main
    : theme.colors.white;

  const outlineColor = isActiveNavGroup
    ? theme.colors.branding.main
    : theme.colors.grey.dark2;

  const iconPropList: Record<string, string> = {};
  iconPropList['aria-label'] = 'Search';

  return (
    <svg
      fill="none"
      width={svgWidth}
      height={svgHeight}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${svgWidth} ${svgHeight + 3}`}
    >
      <path
        fill="white"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.2955 13.5027C29.2118 10.4899 29.0511 5.50773 25.8211 2.71225C23.0005 0.270922 18.6842 0.711457 16.0885 3.39127L15.0653 4.44751L14.0422 3.39127C11.4463 0.711403 7.12998 0.270922 4.30928 2.71241C1.07962 5.50794 0.918841 10.49 3.83028 13.5027L13.8683 23.8816C14.5253 24.5609 15.6004 24.5609 16.2575 23.8816L26.2955 13.5027Z"
      />
      <path
        fill={backgroundColor}
        fillOpacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.2955 13.5027C29.2118 10.4899 29.0511 5.50773 25.8211 2.71225C23.0005 0.270922 18.6842 0.711457 16.0885 3.39127L15.0653 4.44751L14.0422 3.39127C11.4463 0.711403 7.12998 0.270922 4.30928 2.71241C1.07962 5.50794 0.918841 10.49 3.83028 13.5027L13.8683 23.8816C14.5253 24.5609 15.6004 24.5609 16.2575 23.8816L26.2955 13.5027Z"
      />
      <path
        stroke={outlineColor}
        fillRule="evenodd"
        clipRule="evenodd"
        strokeWidth="1.91612"
        d="M26.2956 13.5027C29.212 10.4899 29.0512 5.50773 25.8213 2.71225C23.0006 0.270922 18.6843 0.711457 16.0886 3.39127L15.0654 4.44751L14.0423 3.39127C11.4465 0.711403 7.1301 0.270922 4.3094 2.71241C1.07975 5.50794 0.918963 10.49 3.8304 13.5027L13.8684 23.8816C14.5254 24.5609 15.6006 24.5609 16.2576 23.8816L26.2956 13.5027Z"
      />
    </svg>
  );
}
