import { ListingDetails } from '../../../../../../types';

export function filter(
  listing: ListingDetails,
  filter: { exact: 'true' | 'false'; value: string },
): boolean {
  if (!filter) return true;

  const isExact = filter.exact ? JSON.parse(filter.exact) : false;

  if (!isExact && parseInt(filter.value) === 1) return true;

  if (isExact) {
    return listing.ListingSummary.bathsTotal === parseInt(filter.value);
  }

  if (!listing?.ListingSummary?.bathsTotal) return false;

  return listing.ListingSummary.bathsTotal >= parseInt(filter.value);
}

export function bathroomPredictionFilter(
  listing: ListingDetails,
  filterValue?: { exact: 'true' | 'false'; value: string },
): boolean {
  if (!filterValue) return true;

  const value = Number(filterValue.value) || 0;
  const predictionFilter = {
    ...(filterValue ?? {}),
    value: value < 1 ? String(value) : String(value - 1),
  };

  return filter(listing, predictionFilter);
}

export function bathroomPredictionMoreFilter(
  listing: ListingDetails,
  filterValue?: { exact: 'true' | 'false'; value: string },
): boolean {
  if (!filterValue) return true;

  const value = Number(filterValue.value) || 0;
  const predictionFilter = {
    ...(filterValue ?? {}),
    value: value === 5 ? String(value) : String(value + 1),
  };

  return filter(listing, predictionFilter);
}
