export default {
  activeListingTrendChart: {
    activeListings: 'Active Listings',
  },
  addressCapture: {
    confirmAddressHeadline: 'Welcome Home,',
    confirmAddressDescription:
      'And find out how your home compares to the market in {{areaName}} now and in the future.',
    iconTitle: 'Map Pin',
    placeholder: 'Enter your address',
    verify: 'Verify',
    hiName: 'Hi, {{name}}!',
    addressConfirm: 'Add your home address to see your home value',
  },
  agentWidget: {
    provided: 'PROVIDED BY',
    question: 'Questions? Lets chat.',
  },
  agentDetail: {
    languages: 'Languages: ',
    primaryLanguage: 'Primary',
    secondaryLanguage: 'Secondary',
    office: 'Office',
    mobile: 'Mobile',
    chatWithAgentButton: 'Send {{agentName}} a Message',
    detailHeadline:
      'Contact {{agentName}} to find your property’s current top dollar value.',
    yourAgent: 'Your Agent',
    yourBrokerageAgent: 'Your {{brokerage}} Agent',
    bio: 'Agent Bio',
    dre: 'DRE#: ',
    accomplishments: 'Accomplishments',
    community: 'Community Involvement',
    mySalesAndListings: 'VIEW MY SALES & LISTINGS',
    ourSalesAndListings: 'VIEW OUR SALES & LISTINGS',
  },
  appHeader: {
    profile: 'Profile',
    logout: 'Logout',
  },
  app: {
    documentTitle: 'HOLP ',
    loading: 'Loading...',
  },
  appMessage: {
    remove: 'Remove',
  },
  auth: {
    nextButton: 'Next',
    skipButton: 'Skip',
    profileCreation: {
      title: 'Profile Creation',
      subtitle: 'Enter your information below to begin your profile.',
      inputLabels: {
        firstName: 'First Name',
        lastName: 'Last Name',
        phoneNumber: 'Phone Number',
      },
    },
    verifyAccount: {
      title: 'Verify Account',
      subtitle: 'We sent you a code to verify your account, check your email:',
      invalidCode: 'The pin is incorrect, try again',
      resend: {
        line: `Didn’t receive a code?`,
        cta: 'Resend',
        note: 'Friendly reminder to check your spam/junk folders',
        error: 'Resending failed, try again!',
        success: 'Verification code resent.',
      },
    },
    alreadyHaveAnAccountSignIn: 'Already have an account? Sign In.',
    backToSignIn: 'Back to Sign In',
    confirm: 'Confirm',
    welcomeHome: 'Welcome home',
    createAccount: 'Create Account',
    skip: 'Skip',
    fields: {
      confirmPassword: 'Confirm Password',
      confirmationCode: 'Confirmation Code',
      email: 'Email',
      password: 'Password',
      verificationCode: 'Verification Code',
      currentPassword: 'Current Password',
      newPassword: 'New Password',
    },
    forgotPassword: 'Forgot Password',
    forgotPasswordLink: 'Forgot Password?',

    formErrors: {
      CodeMismatchException:
        'Invalid verification code provided, please try again.',
      InvalidPasswordException: 'Invalid password',
      NotAuthorizedException: 'Invalid email or password',
      UsernameExistsException: 'Email address already exists',
      email: 'Invalid email',
      passwordMustMatchCriteria: 'Password must match all criteria',
      passwordsMustMatch: 'Passwords must match',
    },
    createPasswordStep: {
      passwordCreation: 'Password Creation',
      createAndConfirm: 'Create and confirm a password to secure your account.',
      createAndConfirmTooltip: '',
      passwordPlaceholder: 'Enter password',
      confirmPasswordPlaceholder: 'Confirm Password',
    },
    propertyInformationStep: {
      propertyInformation: 'Property Information',
      doYouOwnHome: 'Do you own home?',
      enterHomeAddress: "Manage & track your home's value, enter your address:",
      addressPlaceholder: 'Address',
      agentHasAddressOnFile: '{{aganetName}} has an address on file for you:',
    },
    next: 'Next',
    linkExpiredMessage:
      'This link has expired. Please use the link provided in your email or contact your agent.',
    lostYourCodeResendCode: 'Lost your code? Resend code',
    needAnAccountSignUp: 'Need an account? Sign Up.',
    sendCode: 'Send Code',
    signIn: 'Sign In',
    signUp: 'Sign Up',
    submit: 'Submit',
    resendCodeShort: 'Resend',
    sendingCode: 'Sending',
    codeWasSentShort: 'Code was sent',
    codeWasSent:
      'Verification code has been sent. Please check your inbox and spam folders.',
  },
  averageListingPriceTrendChart: {
    averageListingPrice: 'Average Listing Price',
  },
  banner: {
    text: 'Stay connected on-the-go throughout your homeownership journey by using my fully integrated app.',
  },
  backCta: {
    back: 'Back',
    return: 'Return to previous page',
  },
  chatModal: {
    chatWarningTitle: 'You have an unsent message!',
    viewProfile: 'View my listings & profile',
    chatWarningSubtitle: 'Would you like to send your message before you go?',
    yes: 'Yes',
    no: 'No',
  },
  charts: {
    ctaFooter: 'See more info about the market in {{city}}',
  },
  collapsibleSection: {
    collapse: 'Collapse',
    expand: 'Expand',
  },
  common: {
    cancel: 'Cancel',
    close: 'Close',
    confirm: 'Confirm',
    continue: 'Continue',
    decrease: 'Decrease',
    invalid: 'Not valid',
    number: 'Must be an number',
    edit: 'Edit',
    increase: 'Increase',
    na: 'N/A',
    noData: '-',
    ok: 'OK',
    save: 'Save',
    delete: 'Delete',
    deleted: 'Deleted',
    deleting: 'Deleting',
    howsTheMarket: 'Here’s how the market is trending',
    dataLoadingError: 'There was an error when trying to load the data',
    search: 'Search',
    saveSearch: 'Save Search',
    yes: 'Yes',
    no: 'No',
    required: 'Required',
    select: 'Select',
    buy: 'Buy',
    rent: 'Rent',
    today: 'Today',
    noMarketData: 'There is no data for this market',
    somethingWentWrong: 'Something went wrong',
  },
  collapsibleHomeDetails: {
    whatDoesThisMean: 'What does this mean?',
    calculateHomeEstimateMoreInfo:
      'We calculate your home’s estimated value using your local market’s latest pricing data. This is not a formal appraisal or substitute for the in-person expertise of your real estate agent.',
  },
  contactAgent: {
    contactAgentButton: 'Contact {{agentFirstName}}',
  },
  formEdit: {
    changesYouMade: 'Changes you made will not be saved.',
    continueWithoutSaving: 'Do you want to continue without saving?',
    quitEditing: 'Quit Editing?',
    yesReturnHome: 'Yes, return home',
    changesMade: 'Changes Made',
    theChangesYouHaveMade:
      'The changes you made will not be saved. Are you sure you want to continue?',
    yesContinue: 'Yes, continue',
  },
  dashboardPage: {
    title: 'Dashboard',
  },
  callToActionFooter: {
    myCurrentHome: 'Request a home valuation',
  },
  errorPage: {
    goToHomepage: 'Go to homepage',
  },
  footer: {
    copyright: '©{{year}} HOLP. All Rights Reserved.',
    test: 'Test',
    websiteName: 'HOLP',
  },
  listingsPage: {
    listingsAlertHeadline: 'Listing Alert Headling',
    listingsAlertDescription:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  },
  pageHeadline: {
    title: "How's the market in {{ area }}",
  },
  passwordRules: {
    0: 'Must be 8 characters or more in length',
    1: 'Must contain at least one number',
    2: 'Must contain at least one upper case letter',
    3: 'Must contain at least one lower case letter',
    4: 'Must contain at least one special character',
  },
  productValueInterstitial: {
    wouldYouLikeToCreateAnAccount: 'Would you like to create an account?',
    byCreatingAnAccountYouCan: 'By creating an account you can...',
    chatWithYourRealtor: 'Chat with your realtor',
    monitorYourMarketsConditions: 'Monitor your market’s current conditions',
    accessDetailedDataAboutYourHome: 'Access detailed data about your home',
    createAccount: 'Yes, Create Account',
    alreadyHaveAnAccount: 'Already have an account. Log in.',
    cancel: 'Cancel',
  },
  propertyImageUpload: {
    uploadImage: 'Upload Image',
  },
  privacyPolicyLicensingAgreement: {
    mainText: 'Updated on {{date}} {{time}}',
    termsAndConditions: 'Terms & Conditions',
    privacyPolicy: 'Privacy Policy',
    subText:
      'Cras mattis consectetur purus sit amet fermentum. Curabitur blandit tempus porttitor.',
  },
  privacyPolicyLicensingAgreementModal: {
    headerText: ' T&C and Privacy Policy',
    bodyText:
      'By using this website, you are agreeing to our Terms & Conditions and Privacy Policy.',
    termsAndConditions: 'Terms & Conditions',
    privacyPolicy: 'Privacy Policy',
    accept: 'Aceept',
  },
  header: {
    login: 'Login',
    signUp: 'Sign Up',
    logout: 'Logout',
    providedBy: 'Provided By',
  },
  listingDetails: {
    of: 'of',
    marketName: '{{locality}}, {{countrySubd}}',
    propertyOverview: 'Property Overview',
    listedBy: 'Listed By: ',
    coListedBy: 'Co Listed By: ',
    mlsNumber: 'MLS #: ',
    mlsNumberLabel: 'MLS #',
    dataSource: 'Data Source: ',
    daysOnSite: 'Days on Site',
    views: 'Views',
    contactAgent: 'Contact {{name}}',
    tax: 'Tax',
    assessedValue: 'Assessed Value',
    chatWithAgentMessage: `I'm interested in seeing the following property *{{propertyFullAddress}} (ID: {{propertyId}}, MLS {{mlsId}})*`,
    notFoundMessage: 'Oops! Looks like this property is no longer available.',
    saveListingError: 'Failed to save {{address}}',
    unsaveListingError: 'Failed to unsave {{address}}',
    expandableSections: {
      overview: 'Overview',
      propertyDetails: 'Property Details',
      // salesAndTaxHistory: 'Sales & Tax History',
      salesAndTaxHistory: 'Sales History',
      schools: 'Schools',
      interiorFeatures: 'Interior Features',
      exteriorFeatures: 'Exterior Features',
      utilities: 'Utilities',
    },
    hitsAndMisses: {
      hitsTitle: 'The Hits',
      hitsDescriptionPt1: 'Rare features that',
      hitsDescriptionPt2: 'less than 20%',
      hitsDescriptionPt3: 'of homes in {{city}} have.',
      missesTitle: 'The Misses',
      missesDescriptionPt1: 'Missing features that ',
      missesDescriptionPt2: 'more than 80%',
      missesDescriptionPt3: 'of homes in {{city}} have.',
    },
    propertyDetailsDescription:
      '(Below are features that separate this {{propertyType}} from others in {{city}})',
    carousel: {
      viewAll: 'View All',
    },
    contactCard: {
      startChat: 'Start Chat',
      askAQuestion: 'Ask a question',
      save: 'Save',
      share: 'Share',
      contactInfo: {
        interested: 'Interested?',
        questions: 'Questions?',
        talkTo: 'Talk to {{name}}',
        firstLineNo: 'Talk to {{name}} to',
        firstLineYes: 'Interested? Talk to',
        secondLine: 'Request a Showing',
        viewFullDetails: 'View full details and all {{total}} photos',
      },
      openHouse: {
        yes: 'Open House Schedule',
        no: 'No Open Houses',
        save: 'Save Open House',
        modal: {
          title: 'Add to your calendar',
          text: `We'll send you a reminder and other details regarding your in-person open house on:`,
          google: 'Google Calendar',
          outlook: 'Outlook Calendar',
          apple: 'Apple Calendar',
        },
      },
    },
    schoolsSectionLabels: {
      ElementarySchool: 'Elementary School',
      MiddleSchool: 'Middle School',
      HighSchool: 'High School',
      district: 'District',
    },
    marketBanner: {
      title: 'See what homes are going for in your market',
      ctaText: 'Check out the trends',
    },

    notes: {
      title: 'Notes',
      placeholder: 'Write a note...',
      save: 'Save',
      delete: 'Delete',
    },
    propertyDetailsSection: {
      unitNumber: 'Unit Number',
      hideAllFeatures: 'Hide all features',
      showAllFeatures: 'Show all features',
      roomInformation: 'Room Information',
      homeDetails: 'Home Details',
      appliances: 'Appliances',
      outdoorFeatures: 'Outdoor Features',
      parkingGarage: 'Parking/Garage',
      utilities: 'Utilities',
      listingDetails: 'Listing Details',
      propertyInfo: 'Property Information',
      fullBaths: 'Full Baths',
      bathsHalf: 'Half Baths',
      beds: 'Beds',
      rooms: 'Rooms',
      basement: 'Basement',
      airConditioning: 'Air Conditioning',
      firePlaces: 'Number of Fireplaces',
      masterDownstairs: 'Master Downstairs',
      masterBath: 'Master Bath',
      dishwasher: 'Dishwasher',
      washer: 'Washer',
      dryer: 'Dryer',
      refrigerator: 'Refrigerator',
      oven: 'Oven',
      microwave: 'Microwave',
      pool: 'Pool',
      deck: 'Deck',
      patio: 'Patio',
      attachedGarage: 'Attached Garage',
      parking: 'Parking',
      parkingSpaces: 'Parking Spaces',
      garage: 'Garage',
      lotFeatures: 'Lot Features',
      coolingType: 'Cooling Type',
      heatingType: 'Heating Type',
      heatingZones: 'Heating Zones',
      waterType: 'Water Type',
      hotWater: 'Hot Water',
      sewerType: 'Sewer Type',
      yearBuilt: 'Year Built',
      archStyle: 'Architecture Style',
      subType: 'Sub Type',
      attachedProperty: 'Attached Property',
      propertyClass: 'Property Class',
      constructionType: 'Construction Type',
      fixerUpper: 'Fixer Upper',
      golf: 'Golf Course',
      hoa: 'HOA',
      leaseToOwn: 'Lease to Own',
      shortSale: 'Short Sale',
      pets: 'Pets',
    },
  },
  salesAndTaxHistorySection: {
    salesHistory: 'Sales History',
    taxHistory: 'Tax History',
    year: 'Year',
    propertyTax: 'Property Tax',
    AssessedValue: 'Assessed Value',
    seeCompleteTaxHistory: 'See Complete Tax History',
    seeLessTaxHistory: 'See Less Tax History',
    seeCompletePriceHistory: 'See Complete Price History',
    seeLessPriceHistory: 'See Less Price History',
    today: 'Today',
    date: 'Date',
    price: 'Price',
    status: 'Status',
    mlsNumber: 'MLS Number',
  },
  marketData: {
    activeListings: 'Active Listings',
    avgDaysOnSite: 'Avg Days On Site',
    avgListPrice: 'Avg List Price',
    avgListingPrice: 'Average Listing Price',
    headline: "This month's market in {{area}}",
    homesSold: 'Homes Sold',
    newListings: 'New Listings',
    offMarketListings: 'Off Market Listings',
    perSqFt: 'Per SqFt',
    priceReductions: 'Price Reductions',
    soldPlusMinus: 'Sold +/-',
    avgSalesPrice: 'Avg Sales Price',
  },
  marketTrends: {
    checkOutMarketTrendsInYourArea: 'Check out market trends in your area',
    marketDetailsFor: 'Market Details for {{area}}',
    marketTrendsIn: 'Market trends in {{area}}',
  },
  marketReport: {
    activeListings: 'Active Listings',
    infoTitle: 'View All Markets',
    mapTitle: 'Market Report',
    marketReportPageTitle: 'Markets I Follow',
    newListings: 'New Listings',
    salesRange: 'Market Price Range',
    averageSalePrice: 'Average Sale Price',
    averageListPrice: 'Average List Price',
    priceReductions: 'Price Reductions',
  },
  marketDetail: {
    areaMap: 'Area Map',
    whatsOnTheMarket: "What's On The Market in {{area}}",
    whatsSoldIn: "What's Sold in {{area}}",
    snapShotLast30Days: 'Last 30 Day Snapshot',
  },
  navigation: {
    back: 'Back',
    chatWith: 'Chat with {{name}}',
    askQuestion: 'Ask {{name}} a question',
    continue: 'Continue',
    myHome: 'My Home',
    myHomeDetails: 'My Home | Property Details & Value',
    myMarkets: 'My Markets',
    myJourney: 'My Journey',
    next: 'Next',
    openMenu: 'Open Menu',
    nextHome: 'Next Home',
    previous: 'Previous',
    providedBy: 'Provided By',
    search: 'My Search',
    myFavorites: 'My Favorites',
    favorites: 'Favorites',
    notifications: 'Notifications',
  },
  mlsDisclaimer: {
    updatedOn: 'Updated on {{date}}',
  },
  myHome: {
    subheaders: {
      maintenance: 'Maintenance',
      valueAndEquity: 'Value & Equity',
      myMarket: { desktop: 'Market Trends', mobile: 'Market' },
    },
    greeting: {
      headline: 'Hi {{name}},',
      subheading: 'Welcome Home!',
      headlineImprovementsFirstPart: 'Curious how much',
      headlineImprovementsSecondPart: "would sell for in today's market?",
      headlineImprovementsMobile:
        "curious how much your home\nwould sell for in today's market?",
    },
    detailsAndMap: {
      todaysEstimate: "Today's Estimate",
      detailsCardFooter: 'View / Edit your home details',
      larger: 'larger',
      smaller: 'smaller',
      thanYourHome: 'than your home',
      infoTooltip:
        'The home estimate range is based on active, pending and sold prices of properties with similar home details to your property.',
    },
    homeDetailsModal: {
      typeLabel: 'Type: ',
      bedsLabel: 'Beds: ',
      sqFtLabel: 'Sq Ft: ',
      sqFtShort: 'SQFT',
      lot: 'Lot',
      bathsLabel: 'Baths: ',
      separator: 'Optional',
      lotSizeLabel: 'Lot Size: ',
      acre: 'ACRE',
      saveButton: 'Save & Close',
      title: 'View & Edit Your Home Details',
      error: 'Your home details update failed. Please try again.',
      bedsError:
        'Bed count is a required entry field. Please enter a number value between 0-99 (0 = Studio).',
      sqftError:
        'Living area square footage is a required entry field. Please enter a number value over 100.',
      lotSizeError:
        'Lot Size {{type}} must have a greater value then {{value}}.',
      propertyTypeError: 'Property type is a required entry field',
    },
    homeCompares: {
      firstPart: 'See how your home',
      mobileFirstPart: 'See how your home',
      compares: 'compares',
      comp: 'comp',
      ares: 'ares...',
      extensionFirstPart:
        '...and determining your home’s highest potential value',
      toYourPacked: 'to your pocket',
      extensionSecondPart:
        'ends with factoring in your loan status & insurance coverage.',
    },
    similarHomes: {
      headline: 'We found {{homesNumber}} similar homes that match',
      mobileLinkText: {
        firstText: 'Similar homes are based on your',
        link: 'home details.',
      },
      requestSent: 'Request sent',
      requestAnalysis: 'Request Analysis',
      listed: 'Listed: ',
      distance: 'Distance',
      mile: 'Mile',
      mostRecent: 'Most Recent',
      highest: 'Highest',
      lowest: 'Lowest',
      smallest: 'Smallest',
      largest: 'Largest',
      nearest: 'Nearest',
      addMoreCompsCTA: {
        title: 'Need more matches?',
        cta: 'View more homes',
      },
      comparableValueReport: 'Comparable Value Report',
      status: {
        active: 'Active',
        sold: 'Sold',
        pending: 'Pending',
        comingSoon: 'Coming Soon',
      },
      agentCommentFirstPart:
        'Your home estimate range is a snapshot view based on market trends of similar properties near your home.',
      agentCommentSecondPart:
        'Interested in the more detailed home valuation analysis?',
      agentCommentLinked: 'Review & update',
      tileSlider: {
        comparisonConfirmMessage: 'Is this a good comparison?',
        yes: 'Yes',
        no: 'No',
        beds: 'BEDS',
        sqft: 'Sq Feet',
        milesAway: 'Miles Away',
        smaller: 'smaller then your home',
        larger: 'larger than your home',
      },
      minListingsModal: {
        title: 'My Apologies',
        body: 'Estimating your home’s value requires a minimum of 3 comparable properties.',
        body1:
          ' Removing this listing would drop requirement below this threshold.',
        body2:
          'Making this filter adjustment would drop the requirement below this threshold.',
        confirmButton: 'OK, GOT IT!',
      },
    },
    marketDetails: {
      sectionTitleRegular: '{{name}}, keep tabs on ',
      sectionTitleBold: 'market trends',
    },
    stayCurrentOnYourMarketTrends: 'Stay current on your market trends.',
    viewPropertyDetails: "View your home's property details & market value",
    marketTrendsTooltipMessage:
      'The percent change of homes that sold this month compared to the previous month.',
    showAllSoldHomes: 'Show all sold homes',
    hideAllSoldHomes: 'Hide all sold homes',
    trendsFooter: {
      text: `You're up to speed on what's happened, now check out what's <i>happening</i>.`,
      cta: 'CHECK THE MARKET',
    },
    areaListings: {
      title: 'View all active listings in the {{area}} area',
      button: 'View results',
    },
    listHomeCard: {
      headline: 'Update your home details',
      link: 'View/Edit your home details for a more accurate estimate',
    },
    addHomeAddressCard: {
      title: 'Own a home? Add your home address',
      subtitle:
        "Instantly see & stay updated on your home's value and activity of similar listings near you",
      placeholder: 'Enter your address here to see your home’s value and more',
      add: 'Add',
    },
    noHomeAddressUI: {
      title: "Hi {{homeownerName}}! Here's how the market is trending.",
    },
  },
  adBanners: {
    headerText: 'ADVERTISEMENT',
    disclaimers: 'DISCLAIMERS',
    equalLender: 'Equal Housing Lender',
    nmls: 'NMLS',
  },
  myJourney: {
    myJourney: 'My Journey',
    myShowings: 'My Showings',
    myTransactions: 'My Transaction',
    myMove: 'My Move',
    myMaintenance: 'My Maintenance',
    comingSoon: 'Coming Soon!',
    manageYourMove: 'Manage your move',
    lastUpdated: 'Updated',
    completed: 'Completed',
    areYouSureDeactivateTracker:
      'Are you sure you want to deactivate your {{type}} tracker?',
    contactAgentCardText: 'How can I help keep your move stress free?',
    createMove: {
      title: 'Moving?',
      subtitle: {
        desktop: 'Activate your move.',
        mobile: 'Activate your move tracker',
      },
      addressLabel: 'NEW ADDRESS',
      addressPlaceholder: 'Enter new address',
      dateLabel: 'MOVE DATE',
      datePlaceholder: 'November 1, 2022',
      error: 'Something went wrong',
      success: 'Move created successfully',
      cancelAndStay: 'Cancel & stay on my path',
      selectDate: 'Select date',
      selectDateMobile: 'Select a move date',
      buttonText: {
        desktop: 'Go',
        mobile: 'Activate Move',
      },
      form: {
        dateCantBeInPast: 'Date must be in future.',
      },
      features: {
        title: '“When it’s time to move I can help.” - {{name}}',
        subtitle: 'Click each tile to see how',
        tracker: 'Tracker',
        trackerText:
          'There are a lot of moving pieces (and boxes!) when planning your big move. Stay on target with the “My Move Tracker.” A simple way to visualize how many days are left until the big moving day.',
        checklist: 'Checklist',
        checklistText: `What to do when to do it and why. The moving checklist adjusts to your move timeline to help keep your move organized with itemized tasks scheduled around the big move date. Check off completed items and get notified on upcoming tasks; my Checklist keeps you on track. All that's left to do is plan the housewarming party (I have fun ideas for that, too!).`,
        calculator: 'Calculator',
        calculatorText: `How many boxes will your move need? What sizes? Plan for packing accordingly with moving calculators. Simply start by inputting either the square footage of your home or the number of bedrooms. The calculator will guide you on what types of boxes you’ll need and how many of each.`,
        referrals: 'Referrals',
        referralsText: `I have helped support many moves and worked with many professionals to help a move run stress-free. Moving company? Painters? Storage? Rest assured, if I don’t have a referral that I’m comfortable sharing, my company does.`,
        documents: 'Documents',
        documentsText: `My inventory checklist helps erase the worry of wondering if all your boxes successfully made it from one home to the next. Using a moving company? You’ll want to check out my essential list of questions for movers.`,
        expertise: 'Expertise',
        expertiseText: `Receive a bid that sounds a tad high? Local, trusted, personal expertise built-in to help with questions and perspective on quotes. Enter the quote and what it’s for, and I’ll provide my honest, objective perspective to help you make your decision.`,
        download: {
          text: 'Stay connected with mobile notifications.\nScan my QR code to',
          link: ' download the app.',
        },
      },
    },
    moveInfo: {
      title: '{{name}}, tackle your move one checkbox at a time',
      tackleMove: {
        error: 'Something went wrong',
      },
      steps: {
        section1: {
          first: {
            headline: 'Give notice to your landlord',
            subheadline:
              'Landlords typically require 30-60 day written notice.',
          },
          second: {
            headline: 'Get estimates from professional movers',
            subheadline:
              'Contact 3 moving companies to compare options & pricing plans.',
          },
          third: {
            headline: 'Schedule movers and/or book a truck rental',
            subheadline: 'Weekend & summer moves typically cost more.',
          },
          fourth: {
            headline: 'Reserve a storage unit',
            subheadline: 'Store belongings before, during, or after your move.',
          },
          fifth: {
            headline: 'Order all moving supplies',
            subheadline:
              'Plan for packing to find out how many boxes you will need.',
          },
        },
        section2: {
          first: {
            headline: 'Make travel arrangements',
            subheadline:
              'Research & book travel for you, your family, and your pets.',
          },
          second: {
            headline: 'Notify schools of your move',
            subheadline:
              'Complete paperwork, records, and immunization requirements for new schools.',
          },
          third: {
            headline: 'Make note of all tax deductible moving expenses',
            subheadline:
              'Keep track of receipts for charitable donations and/or job relocation-related costs.',
          },
          fourth: {
            headline: 'Update monthly memberships with new address',
            subheadline: 'Store belongings before, during, or after your move.',
          },
          fifth: {
            headline: 'Begin packing',
            subheadline:
              'Start with non-essential items, leave the kitchen for last.',
          },
        },
        section3: {
          first: {
            headline: 'Confirm date & time with moving company',
            subheadline: 'Get written confirmation.',
          },
          second: {
            headline: 'Schedule car shipping service',
            subheadline:
              'Long-distance move? Avoid extra wear and tear on your vehicle.',
          },
          third: {
            headline: 'Notify service providers',
            subheadline:
              'Update monthly maintenance providers (landscaper, housekeeper, etc).',
          },
          fourth: {
            headline: 'Research vendors for projects',
            subheadline: 'Keep me in mind for referrals.',
          },
          fifth: {
            headline: 'Forward mail',
            subheadline: 'Update your address with USPS here.',
          },
        },
        section4: {
          first: {
            headline: 'Transfer, set-up, schedule shut off of utilities',
            subheadline:
              'Set up water, electricity, gas & sewer for move-in day.',
          },
          second: {
            headline: 'Arrange for child and pet care on moving day',
            subheadline:
              'Keep children and pets safe & stress-free by arranging move-in day care.',
          },
          third: {
            headline: 'Change social security and medicare mailing address',
            subheadline:
              'Update your address to avoid gaps in benefits/communication.',
          },
          fourth: {
            headline: 'Forward medical records',
            subheadline: 'Fill out and submit medical record release forms.',
          },
          fifth: {
            headline: 'Transfer prescriptions',
            subheadline: `Order 1 month's supply of medications, then transfer them to a local pharmacy.`,
          },
        },
        section5: {
          first: {
            headline: 'Schedule painters',
            subheadline:
              'Determine SQFT & how many rooms need to be painted to get accurate quotes.',
          },
          second: {
            headline: 'Update bank account & credit card billing addresses',
            subheadline:
              'Before you make the trip to the bank, this can usually be done online or by phone.',
          },
          third: {
            headline: 'Pack kitchenware',
            subheadline:
              'Minimize broken dishes by using dish barrel boxes & specialty packing materials.',
          },
          fourth: {
            headline: 'Clean old home',
            subheadline:
              'Make sure your old home is clean & empty to avoid delays with closing.',
          },
          fifth: {
            headline: 'Inspect old home with landlord',
            subheadline: 'Discuss & document potential charges for damages.',
          },
        },
        section6: {
          first: {
            headline: 'Make arrangements for final trash & recycling pickup',
            subheadline:
              'Leaving behind a mess can delay closing, make sure any trash is hauled away.',
          },
          second: {
            headline: 'Pack 1st week essentials separately',
            subheadline:
              'Clothing, bedding, toiletries, pet/child necessities, documents, valuables, etc.',
          },
          third: {
            headline: 'Take inventory of all moving items',
            subheadline:
              'Use my inventory list to check that all of your boxes and furniture made it.',
          },
          fourth: {
            headline: 'Change address with DMV',
            subheadline:
              'Each state has different steps for transferring your license & registration',
          },
          fifth: {
            headline: 'Change address with IRS',
            subheadline:
              'Update your employer and complete required tax forms.',
          },
        },
      },
    },
    movingCompany: {
      header: {
        title: 'Using a moving company?',
        subtitle: 'Track important details about the moving company',
      },
      modal: {
        fmcsa: 'FMCSA Registered Mover Look-up',
        avoidFraud: 'Avoid fraud by loooking up past history and claims',
        lookUp: 'Look-up Movers',
        close: 'Close',
      },
      form: {
        formErrNumber: 'Must be an positive number',
        nameLabel: 'NAME OF MOVING COMPANY',
        licenseLabel: 'USDOT LICENSE',
        contactLabel: 'CONTACT NAME',
        quoteLabel: 'QUOTE',
        submitBtn: 'Save',
        success: 'Successfully updated moving company',
        error: 'Something went wrong',
      },
      linksSection: {
        sectionOne: {
          title: 'FMCSA Registered Mover Look-up',
          subtitle: 'Avoid fraud by looking up past \nhistory and claims.',
          btn: 'Look-up Movers',
        },
        sectionTwo: {
          title: 'USPS Address Change',
          subtitle: 'Change your address within 2 \nweeks of your move.',
          btn: 'Change Address',
        },
      },
      footer: {
        agent: {
          title: 'How can I help?',
          subtitle:
            "I've supported many client moves, how can I support yours?",
        },
        cards: {
          first: 'My list of questions \nfor movers',
          second: 'Request moving & \nstorage referrals',
          third: 'Need an objective \nperspective?',
        },
      },
    },
    packing: {
      headline: 'Moving Inventory',
      description:
        'Track how many boxes are used to pack each room to ensure that all possesions are accounted for during the move.',
      livingRoomLabel: 'Living Room',
      table: {
        roomName: 'Room Name',
        numberOfBoxes: 'Number Of Boxes',
        selectRoomNameLabel: 'Select a room name or enter a custom room name',
        large: 'Large',
        medium: 'Medium',
        small: 'Small',
        dishBarrel: 'Dish Barrel',
        wardrobe: 'Wardrobe',
        addRow: 'Add Row',
        selectRoomPlaceholder: 'Select room name',
        enterCustomName: 'Enter custom name',
        totalRow: 'Total',
        other: 'Other',
      },
      download: 'Download',
      page: 'page',
    },
    planForPacking: {
      header: {
        planForPacking: 'Plan for packing',
        calculateBoxes:
          ' Calculate boxes needed based on the size of the current home or number of rooms',
      },
      footer: {
        packingTipTitle: 'Packing tip',
        packingTip:
          ' Label each box with the room its for and avoid mixing items from different rooms.',
        agentText: ' I created a document to help you track your inventory',
        open: 'Open',
      },
      sqftLabel: 'SQFT',
      bedroomsLabel: 'BEDROOMS',
    },
    myMovePage: {
      daysUntilMove: 'days until your move to:',
      movingDate: 'Moving date',
      successEdit: 'Move updated successfully',
      movingTo: 'Moving to: ',
      daysToMoving: '{{daysToMove}} days to moving day',
      updateAddress: {
        title: 'Update address',
        save: 'Save',
        cancel: 'Cancel',
        invalidAddress: 'Invalid address',
        addressRequired: 'Address is required',
      },
      updateDate: {
        title: 'Select a moving date',
      },
      footerCTA: {
        inTransaction: 'In Transaction?',
        startYourTracker: 'Start your tracker',
      },
      carousel: {
        planForPacking: {
          title: 'Plan for packing',
          desc: 'Calculate boxes needed based on home size or number of bedrooms',
          btn: 'View Plans',
        },
        movingCompany: {
          title: 'Moving company?',
          desc: 'Track important details about the moving company for easy quick reference.',
          btn: 'Track',
        },
        movingDay: {
          title: 'Moving day',
          desc: 'Ensure all your possessions make it safely from point a to point b.',
          btn: 'Track Inventory',
        },
      },
      contactAgent: {
        agentMessage:
          'My commitment to your journey extends beyond the transaction to keep your move stress-free.',
        helpText: 'How can I help keep your move stress free?',
        referrals: {
          question: 'Need \na referral?',
          label: 'Referrals',
          heading: 'Please send me referrals for:',
          note: 'Note about referral',
          button: 'Send referral request to',
          error: 'Something went wrong.',
          success: 'Sent message will appear in inbox',
        },
        questions: {
          label: 'Questions',
          question: 'How can I help?',
          question2: 'Have any \nquestions?',
          button: 'Send question to',
          error: 'Something went wrong.',
          success: 'Sent message will appear in inbox',
          questionModalPlaceholder: 'Enter questions or concerns',
        },
        quote: {
          label: 'Quotes',
          question: 'Perspective on \nQuotes?',
          heading: 'I need your objective advice on moving quotes',
          amountPlaceholder: 'Enter dollar amount of quote',
          contentPlaceholder: 'What is the quote for?',
          button: 'does this seem fair?',
          error: 'Something went wrong.',
          success: 'Sent message will appear in inbox',
        },
      },
      pdfQuestions: {
        heading: 'Essential Questions for Movers',
        subheading:
          "Choosing a moving company is hard. You need a mover you can trust with your belongings, a mover that's dependable, a mover that will give you accurate and fair pricing. We'll help you find that mover with our Essential Questions list.",

        question1: {
          q: 'Are you properly licensed & insured?',
          a: "Local registration is typically required as well as registering with the federal Department of Transportation (DOT). Requirements for licensing and insurance can vary from state to state. Movers typically carry moving insurance to cover a portion of damage or loss to your item(s) while in their possession. It's also fairly standard that their insurance will cover auto liability, cargo coverage, and workers compensation.",
        },
        question2: {
          q: 'Do you have experience with my specific type of move?',
          a: 'Just as no home purchase is exactly the same, the same can be said for moving. Review the specifics of your move in detail with movers to ensure that they have the right equipment and experience for your situation.',
        },
        question3: {
          q: 'Do you have references?',
          a: "Although the occasional bad review is to be expected, it's a red flag when bad reviews are plentiful. Check the dates of reviews, pay attention to the details and get the other perspective by inquiring with the moving company. No references? Another red flag and possible indication that they are not a service-oriented mover.",
        },
        question4: {
          q: 'Can you provide a binding quote or a not-to-exceed estimate?',
          a: 'Be diligent with the estimate you receive and ask for a binding or not-to-exceed estimate so as not to incur unexpected costs. These types of estimates ensure that the cost of your move will not end up exceeding the original cost estimate. The alternatives may be lower but they carry the potential for an unexpected high bill in the end.',
        },
        question5: {
          q: 'Ask about the move details.',
          a: `•  Does the company provide any packing or unpacking services? 
•  Is there anything your company won't move? 
•  Are your pickup and delivery dates guaranteed? 
•  How long do you expect the full move (from packing to unpacking or loading to unloading) to be? 
•  What are your policies if something is damaged?`,
        },
        question6: {
          q: 'Ask about the cost details.',
          a: `•  What are your payment options? 
•  What is your cancellation policy? 
•  Are there options to purchase additional insurance coverage? 
•  Is this a binding quote? 
•  Are there scenarios that could add to my cost (travel, parking, etc)?`,
        },
      },
      tackleMove: {
        mobile: {
          title: 'Tackle your move',
          subtitle: 'One checkbox at a time',
        },
      },
      manageMove: {
        mobile: {
          title:
            'Choosing a mover is hard. You need a mover you can trust that is dependable with accurate and fair pricing.',
          btn: 'My questions for movers',
        },
      },
    },

    myMaintenancePage: {
      title: 'My Maintenance',
      header: {
        titleFirstPart: 'Home sweet ',
        titleFirstPartDesktop: 'Home sweet',
        titleSecondPart: 'home maintenance',
        subtitle: 'Servicing your home value one checkbox at a time',
        mobileTitle: 'Seasonal Maintenance',
        mobileSubtitle: 'Servicing your home value one checkbox at a time',
      },

      maintenanceCosts: {
        heading:
          'For every $1 spent on maintenance, up to $100 of repairs are avoided',
        card: {
          heading: 'Average annual maintenance costs',
          currencyLabel: 'per year',
          footer:
            'Consider a home warranty to cover service, repair or replacement of home systems & appliances.',
        },
      },
      maintenanceSeasons: {
        tabHeading: 'MAINTENANCE',
        footer: {
          qrDescription:
            'Conveniently track maintenance \nfrom your mobile device',
        },
      },
      contactAgent: {
        helpText: 'How can I help you maintain your home value?',
      },
    },
    essentialQuestionsPage: {
      header: {
        title: ' Essential Questions for Movers',
        subtitle:
          'You need a mover you can trust, thats dependable with accurate and fair pricing.',
      },
      questions: {
        first: {
          title: 'Are you properly licensed & insured?',
          value:
            'Local registration is typically required as well as registering with the federal Department of Transportation (DOT). Requirements for licensing and insurance can vary from state to state. Movers typically carry moving insurance to cover a portion of damage or loss to your item(s) while in their possession. Its also fairly standard that their insurance will cover auto liability, cargo coverage, and workers compensation.',
        },
        second: {
          title: 'Do you have experience with my specific type of move?',
          value:
            'Just as no home purchase is exactly the same, the same can be said for moving. Review the specifics of your move in detail with movers to ensure that they have the right equipment and experience for your situation.',
        },
        third: {
          title: 'Do you have references?',
          value:
            "Although the occasional bad review is to be expected, it's a red flag when bad reviews are plentiful. Check the dates of reviews, pay attention to the details and get the other perspective by inquiring with the moving company. No references? Another red flag and possible indication that they are not a service-oriented mover.",
        },
        fourth: {
          title: 'Can you provide a binding quote or a not-to-exceed estimate?',
          value:
            'Be diligent with the estimate you receive and ask for a binding or not-to-exceed estimate so as not to incur unexpected costs. These types of estimates ensure that the cost of your move will not end up exceeding the original cost estimate. The alternatives may be lower but they carry the potential for an unexpected high bill in the end.',
        },
        fifth: {
          title: 'Ask about the move details.',
          value: {
            first:
              'Does the company provide any packing or unpacking services?',
            second: " Is there anything your company won't move? ",
            third: ' Are your pickup and delivery dates guaranteed? ',
            fourth:
              'How long do you expect the full move (from packing to unpacking or loading to unloading) to be? ',
            fifth: 'What are your policies if something is damaged?',
          },
        },
        sixth: {
          title: 'Ask about the cost details.',
          value: {
            first: 'What are your payment options?',

            second: 'What is your cancellation policy?',
            third:
              'Are there options to purchase additional insurance coverage?',
            fourth: 'Is this a binding quote?',
            fifth:
              'Are there scenarios that could add to my cost (travel, parking, etc)?',
          },
        },
      },
    },
    myTransactionPage: {
      transaction: 'Transaction',
      mobileHeading: 'Closing in\non homeownership',
      transactionSubheader: {
        line1: `{{name}}, the team is focused on`,
        line2: `the following transaction goals this week:`,
      },
      dateEdit: {
        startDateLabel: 'Offer accepted',
        endDateLabel: 'Estimated closing date',
        startDateTitle: 'Change offer accepted date',
        endDateTitle: 'Change estimated closing date',
      },
      weeklyGoals: {
        heading: ', the team is focused on the following transaction goals',
        goalsInFocus: 'Goals in Focus',
        success: 'Request update sent successfully',
        error: 'Something went wrong with sending update request',
        requestUpdate: 'Request update',
        agentText:
          "I coordinate daily with the Loan, Title and Escrow team and share updated transaction goals every few days so you're aware of where the team's focus is.",
      },
      carousel: {
        title: {
          title: 'Title',
          desc: '{{goals}} goals aimed at approving, funding, and registration of your mortgage',
          btn: 'View Goals',
        },
        dueDiligence: {
          title: 'Due Diligence',
          desc: '{{goals}} goals aimed at protecting you in identifying pitfalls in property condition',
          btn: 'View Goals',
        },
        financing: {
          title: 'Financing',
          desc: '{{goals}} goals aimed at clearing issues & transferring homeownership to your name',
          btn: 'View Goals',
        },
      },
      activateHeader: {
        title: 'My Transaction',
        subtitle: 'Activate your tracker',
      },
      error: 'Something went wrong',
      info: {
        daysToKeys: ' days to keys',
        subtitle:
          'Your transaction closes in on homeownership as progress is made across 3 key areas:',
        title: 'TITLE',
        titleContent:
          ' goals aimed at clearing issues & transferring homeownership to your name',
        dueDiligence: 'DUE DILIGENCE',
        dueDiligenceContent:
          ' goals aimed at protecting you in identifying pitfalls in property condition',
        financing: 'FINANCING',
        financingContent:
          ' goals aimed at approving, funding, and registration of your mortgage',
      },
      activateForm: {
        addressRequired: 'Address is required',
        addressPlaceholder: 'New address',
        addressPlaceholderMobile: 'Enter new address',
        addressLabel: 'Address',
        openedEscrowLabel: 'Opened Escrow',
        selectOpenedEscrow: 'Select Opened Escrow',
        selectClosingEstimatedDate: 'Select Estimated Closing Date',
        estimatedClosingDate: 'Estimated Closing Date',
        submitLabel: 'Go',
        submitLabelMobile: 'Activate Transaction',
        estimatedClosingDateValidationMsg:
          'End time cannot be before start time',
        successMessage: 'Transaction is activated',
        selectDate: 'Select date',
      },

      features: {
        featuresTitle: `“I’m your key to a smooth transaction” - {{name}}`,
        subtitle: 'Click each tile to see how',
        tracker: 'Tracker',
        trackerText:
          "There's a lot going on, keep your sanity intact with the 'My Transaction Tracker'. Get a bird's eye view of your timeline to close and zoom in to track the progress of 20+ goals across Due Diligence, Finance & Title.",

        finance: 'Finance',
        financeText: `Show me the money! Finance does exactly that. From underwriting to approval, see the key steps (and dates) in funding your home loan.`,
        dueDiligence: 'Due Diligence',
        dueDiligenceText:
          "From inspections to final walk-thru, Due Diligence will track the process of assessing your property's condition.",
        title: 'Title',
        titleText: `Keep tabs on all things Title. From the preliminary title report to insurance and transfer, the tracker narrows in on all title tasks to will keep you up to date.`,
        checklist: 'Checklist',
        checklistText: `Scrap the sticky-note system. With Checklist, get insight into relevant goals along your transaction timeline. Questions on specific items? Request an update from your source of truth (me - your local expert) for all things real estate.`,
        contingencies: 'Contingencies',
        contingenciesText: `Tracking contingency removal dates are critical in the transaction process - the Contingencies tracker will keep you up to date on which clauses are removed and by when.`,
      },
      contingencyDates: {
        title: 'Contractual dates can\n get lost in the big picture.',
        linkText: 'Add & track contingency removal dates.',
        modalTitle: 'Contractual dates can get lost in the big picture',
        modalSubtitle: 'Enter & track contingency removal dates',
        inspectionContingency: {
          title: 'Inspection Contingency',
          description:
            'Also called “due diligence”, gives you the right to have inspections during a specified time period.',
        },
        appraisalContingency: {
          title: 'Appraisal Contingency',
          description:
            'Protects your intent to buy, if the appraised home value differs from agreed purchase terms.',
        },
        loanContingency: {
          title: 'Loan Contingency',
          description:
            'Protects you from purchasing a home if the loan is not approved.',
        },
        select: 'Select {{contingencyDate}} date',
        selectDate: 'Select Date',
        trackContingencyText:
          'With 20+ goals to track, contractual dates can get lost. ',
        trackContingencyLink: 'Track contingency dates',
      },
      progressBar: {
        contingencyRangeLabel: 'Contingency Removals Due',
      },
      questions: {
        questionsText: `Questions on your transaction? Let's chat.`,
        inputPlaceholder: 'Type a message',
        sentMessageTitle: 'Message Sent',
        sentMessageSubtitle: 'Responses will show in chat',
        contentLabel: `Have a question? I'm happy to help.`,
      },
      requestUpdate: {
        description: `I coordinate daily with the Loan, Title and Escrow team and share
        updated transaction goals every few days so you're aware of where the
        team's focus is.`,
        ctaBtn: 'Request update',
        questionMessage: `{{name}} has requested an update on their transaction.`,
      },
      updateTransactionAddress: {
        title: 'New home ahead',
        updateSuccess: 'Transaction is successfully updated.',
        updateError: 'Something went wrong.',
        mobileTitle: 'Stay connected to your transaction',
        mobileSubtitle: '{{daysRemaining}} days to keys',
      },
      finishTransactionModal: {
        title: 'Hello Homeowner!',
        text: `My commitment to your homeownership journey doesn't end at closing. From tracking your home's value, maintaining your investment and being a resource to anything and everything homeownership-related - I'm always a chat away.`,
        bottomText: 'Ready to unlock \nyour new "My Home" page?',
        cta: `Let's go`,
      },
    },
  },

  myHomeSummaryHeadline: {
    name: 'Hi, {{name}}!',
  },
  myHomeSummaryNoAtomData: {
    message: 'Sorry, we couldn’t find information associated with your home.',
    callToActionFooter: 'Add details to get started',
  },
  noHomeSummaryValuation: {
    noHomeDataMessage:
      'Unfortunately, we don’t have enough data to estimate your home’s value.',
    contactAgentInfo:
      'Contact {{ firstName }} to learn more about how the local market is changing and how your home compares to recently sold homes nearby.',
  },

  pageNotFoundPage: {
    message: 'Unfortunately, we can’t find the page you are looking for.',
    subtitle: ' Page Not Found',
    title: '404',
  },
  recentlySoldListings: {
    title: 'Recently Sold',
    subtitle: 'Here are some of the properties in your area that recently sold',
  },
  hotListings: {
    subtitle: 'Here are some of the most popular listings based on views',
    title: 'Hot Listings',
  },
  mostExpensiveListing: {
    title: 'Most Expensive',
    subtitle: 'Here’s top of the market in {{ area }}',
  },
  highestSoldListings: {
    title: 'Highest Sold',
    subtitle: 'Sold on {{ date }}',
  },
  errors: {
    blankField: 'This field cannot be left blank',
    invalidDimensions:
      'Invalid Image Dimensions! Max supported dimension is {{maxWidth}}x{{maxHeight}} (width x height).',
    invalidFileSize: 'File Too Large! Max supported file size is {{ size}}MB.',
    invalidFileType: 'Invalid File Type! Supported file types are {{ types }}.',
    somethingWentWrong: 'Something went wrong. Try again.',
  },
  propertyDetails: {
    documentTitle: 'Property Details',
    homeDetails: {
      title: 'Home Details',
      subtitle: 'At a glance ',
      chatDisclaimer:
        'Contact {{agentName}} to find your properties current top dollar value',
    },
    bathrooms: 'Bathrooms',
    bathsTotal: 'Bath',
    baths: 'BATHS',
    livingSpaceSize: 'SQFT',
    lotSize: 'Lot Size',
    lot: 'Lot',
    county: 'County',
    neighborhood: 'Neighborhood',
    lotSizeUnit: 'Lot Size ({{unit}})',
    lotSize2: 'Lot size (ft)',
    lotSizeAcre: 'Lot size (acre)',
    yes: 'Yes',
    no: 'No',
    bedrooms: 'Bedrooms',
    beds: 'Beds',
    sqft: 'Square Feet',
    collapsibleTitles: {
      exterior: 'Exterior',
      interior: 'Interior',
      propertyInfo: 'Property Information',
      salesAndTax: 'Sales & Tax Information',
      priceHistoryAndTax: 'Price History & Tax Information',
      schools: 'Schools',
      utilities: 'Utilities',
    },
    detailTitles: {
      waterType: 'Water Type',
      hotWater: 'Hot Water',
      airConditioningInd: 'Air Conditioning',
      heatingZonesCount: 'Heating Zones',
      sewerType: 'Sewer Type',
      heatingType: 'Heating Type',
      coolingType: 'Cooling Type',
      areaName: 'Area',
      atticDesc: 'Attic',
      appliancesIncluded: 'Appliances Included',
      attachedGarageInd: 'Attached Garage',
      garageInd: 'Garage',
      propClass: 'Property Class',
      propType: 'Property Type',
      yearBuilt: 'Year Built',
      effectiveYearBuilt: 'Effective Year Built',
      levelsCount: 'Levels',
      archStyle: 'Architecture Style',
      sidingType: 'Siding',
      viewType: 'View Type',
      lotSize: 'Lot Size',
      livingSpaceSize: 'Living Space Size',
      assessedImprovementsValue: 'Assessed Improvement Value',
      assessedLandValue: 'Assessed Land Value',
      assessedValue: 'Assessed Value',
      basementInd: 'Basement',
      basementSize: 'Basement Size',
      basementType: 'Basement Type',
      bathsFull: 'Full Bath',
      bathsPartial: 'Partial Bath',
      energyType: 'Energy Type',
      flooringType: 'Flooring Type',
      fireplaceInd: 'Fireplace',
      fireplaceCount: 'Number of Fireplaces',
      fireplaceType: 'Fireplace Type',
      interiorFeatures: 'Interior Features',
      lotFeatures: 'Lot Features',
      parkingType: 'Parking',
      heatingFuel: 'Heating',
      marketSize: 'Market Value Per Sqft',
      marketValue: 'Assessed Property Value',
      parkingSize: 'Parking Space Size',
      parkingSpaces: 'Parking Spaces',
      poolInd: 'Pool',
      poolType: 'Pool Type',
      outdoorLiving: 'Outdoor',
      masterOnMainInd: 'Master on Main',
      saleDate: 'Last Sold Date',
      saleCashMort: 'Cash or Mortgage Purchase',
      propSubtype: 'Subtype',
      roomsTotal: 'Rooms',
      saleAmount: 'Total Sale Amount',
      saleSize: 'Sale Price Per Sqft',
      seniorCommunityInd: 'Senior Community',
      taxSize: 'Tax Amount Per Sqft',
      taxYear: 'Tax Year',
      attachedPropertyInd: 'Attached Property',
      leaseConsideredInd: 'Lease Considered',
      fixerUpperInd: 'Fixer Upper',
      foreclosureInd: 'Forclosure',
      furnishedInd: 'Furnished',
      golfCourseInd: 'Golf Course',
      greenEnergyInd: 'Green Energy',
      hoaInd: 'HOA',
      horsePropertyInd: 'Horse Property',
      leaseToOwnInd: 'Lease to Own',
      newConsructionInd: 'New Construction',
      petInd: 'Pets',
      sellerFinanceInd: 'Seller Finance',
      shortSaleInd: 'Short Sale',
      walkScore: 'Walk Score',
      windowFeatures: 'Window Features',
      constructionType: 'Construction Type',
      exteriorFeatures: 'Exterior',
      priceHistory: 'Price History',
      percentChange: 'Percent Change',
      priceChangeDate: 'Price Change Date',
      mlsListingId: 'MLS Listing ID',
      priceChange: 'Price Change',
      roadType: 'Road Type',
      schoolDistrict: 'District',
      highSchool: 'High School',
      middleSchool: 'Middle School',
      elementarySchool: 'Elementary School',
      taxAmount: 'Tax Amount',
    },
    editPropertyDetails: 'Edit Property Details',
    footerText: 'Something look off? Edit this info',
  },
  myPropertyDetailsFooter: {
    edit: 'Edit {{section}}',
  },
  homeValueDetail: {
    confidenceScore: 'Confidence Score',
    footerText: 'Request a home valuation',
    lastEstimatedValue: 'Last Estimated Value',
    learnMore: 'Learn more about your home value',
    perSqft: 'Per SQFT',
    requestAHomeValuation: 'Request a Home Valuation',
    requestAHomeValuationMessage:
      'I’d like more information about the value of my home {{address}}',
    targetPropertyValue: 'Target Property Value',
  },
  errorMessages: {
    noDataAvailable: 'No Data Available',
  },
  propertyImage: {
    myProperty: 'My Property',
    remove: 'Remove',
  },
  propertyEstimations: {
    title: 'Updated: {{ date }}',
    changeThisMonth: 'Change This Month',
    estimate: 'Estimate',
    estimateLast: 'LAST ESTIMATE',
    confidenceScore: 'CONFIDENCE SCORE',
    confidenceScoreInfo:
      'We calculate your home’s estimated value using your local market’s latest pricing data. This is not a formal appraisal or substitute for the in-person expertise of your real estate agent.',
    rangeTitle: 'Estimated Value Range',
  },
  propertyEstimateRange: {
    title: 'Estimated Value Range',
  },
  areaEstimateRange: {
    marketSalesRange: 'Market Price Range',
  },
  recentlySoldHomes: {
    subtitle: 'Here are some of the homes that were recently sold in your area',
    title: 'Recently Sold Homes',
  },
  sideMenu: {
    userProfile: 'User Profile',
    notificationSettings: 'Notification Settings',
    allMarkets: 'All Markets',
    myJourney: 'My Journey',
    savedHomes: 'Saved Homes & Searches',
    logOut: 'Logout',
    faq: 'FAQ',
    termsOfUse: 'Terms of use',
    new: {
      myProfile: {
        label: 'My Profile',
        loginInfo: 'Login Information',
        personalInfo: {
          name: 'Personal Information',
          invalidPhone: 'Phone number cannot start with 0 or 1',
          phoneLength: 'Phone number must be 10 digits',
        },
        homeInfo: 'Home Information',
        homeInfoDialog: {
          title:
            'Your home address has changed. Would you like to reset you seasonal maintenance checklists?',
          text: 'This action cannot be undone',
          confirmText: 'No, keep my checklists',
          cancelText: 'Yes, reset my checklists',
        },
        notifications: 'Notifications',
        securityMsg: {
          first: 'Your security is important to us,',
          second: 'A verification code is required to change your password.',
        },
        doYouOwnHome: 'Do you own a home?',
        areYouLookingTo: 'Are you looking to:',
        sendCode: 'Send Code',
        form: {
          passwordLengthInfo: 'Must include 8 characters',
          enterCurrentPassword: 'Enter Old Password',
          enterVerificationCode: 'Enter Verification Code',
          enterNewPassword: 'Enter New Password',
          confirmPassword: 'Confirm Password',
          didntReceiveCode: "Didn't receive code?",
          enterFirstName: 'Enter First Name',
          enterLastName: 'Enter Last Name',
          enterPhoneNumber: 'Enter Your Phone Number',
          personalInfoUpdateMsg: 'Personal information successfully updated',
          notificationUpdateMsg: 'Notification settings successfully updated',
          homeInfoUpdateMsg: 'Home information successfully updated',
          resetMaintenanceMsg: 'Maintenance checklists successfully reset',
        },
        notificationHeadline:
          'You will automatically receive email notifications. Below, you can opt in to receive text notifications.',
        receiveTextNotificationsLabel: 'Receive text notifications:',
        receivePushNotificationsLabel:
          'Do you want to receive push notifications:',
        goToNotificationPage: 'Go to notification page',
        address: 'Address',
      },
    },
  },
  sort: {
    asc: 'Ascending',
    desc: 'Descending',
  },
  currency: {
    symbol: '$',
  },
  favoriteProperty: {
    save: 'Save',
    unsave: 'Unsave',
    listingRemoved: '{{address}} has been removed',
  },
  mortgage: {
    at: 'at {{rate}}%',
    editLoans: 'Edit Your Loans',
    estimatedBalance: 'Estimated Balance',
    infoTooltip:
      'This information is pulled from public records. Details can be added and/or adjusted here without impacting what is captured on public records.',
    myMortgageSubtitle: "Track your home's highest potential return value",
    formError: {
      lenderCompany: 'Enter a valid company',
      lenderName: 'Enter a valid lender name',
      loanAmount: 'Enter a valid amount',
      loanBalance: 'Enter a valid amount',
      loanInterest: 'Enter a valid interest rate',
      loanTerm: 'Enter a valid term',
      startDate: 'Enter a valid start date',
      loanType: 'Enter a valid loan type',
    },
    addNewLoan: 'Add New Loan',
    loanInterest: 'Interest Rate',
    lenderName: 'Lender Name',
    lenderCompany: 'Lender Name',
    loanBalance: 'Estimated Balance',
    loanStartDate: 'Loan Start Date',
    loanTerm: 'Loan Term (years)',
    loanType: 'Loan Type',
    loanDetails: 'Loan Details',
    viewAddDetails: 'View/Add Details',
    loanDetailsOpen: 'View Loan Details',
    loanDetailsClose: 'Hide Loan Details',
    myMortgage: 'My Mortgage',
    loanAmount: 'Original Loan Amount',
    totalLoanAmount: 'Total Loan Amount',
    totalEstimatedBalance: 'Total Estimated Balance',
    startedOn: 'Started on {{date}}',
    yearFixed: '{{term}} Year Fixed',
    selectDate: 'mm/dd/yyyy',
    deleteLoan: 'Delete Loan',
    areYouSureDelete: 'Are you sure you want to delete this loan?',
    loanTypes: {
      HOME_EQUITY: 'Home Equity',
      REFINANCE: 'Refinance',
      PRIMARY: 'Primary',
    },
    typicalRatesAre: 'Typical mortgage rates',
    thirtyYearFixed: '30 Year Fixed',
    considerGettingQuote: 'Consider getting a quote from our partner,',
    introlend: 'Introlend',
    prompt: {
      title: 'Have an active mortgage on your home?',
      description: 'Add & track alongside your home value',
      confirmButtonText: 'Add Mortgage',
      closeButtonText: "I'll add it later",
    },
  },
  manuallyAddedInfoIsKeptPrivate:
    'Manually added information is kept private and not shared',

  settings: {
    notificationSettings: 'Notification Settings',
    newMarketReport: 'New Market Report',
    newMarketReports: 'New Market Reports',
    viewAll: 'View All',
    areas: 'Areas',
  },
  savedProperties: {
    forSale: 'For Sale',
    noSavedPropertiesMessage:
      'Looks like you don’t have any properties saved yet! Start browsing for houses that may be perfect for you!',
    savedPropertiesHeading: 'Saved Properties',
    savedPropertiesSubHeading:
      'Here are some of the properties you’ve recently saved.',
    viewAllSavedProperties: 'View All Saved Properties',
  },
  valuations: {
    sectionTitle: 'Independent valuations',
    sectionSubheading: 'Estimate your home would sell for:',
    infoTooltip:
      'Independent valuations are based on statistical models that aggregate available data to form an estimate of market value.',
    agentNoteFirstLine:
      " Independent valuations are NOT local experts, don't walk through your home,",
    agentNoteFirstLineNoData:
      ' Independent valuations are not available for this address at this time.',
    agentNoteSecondLine: {
      regular: 'verify their data or account for condition. ',
      bold: "I do, ask me how I'd price your home in today's market.",
    },
    agentNoteSecondLineNoData:
      ' Ask me how I’d price your home in today’s market.',
    chatLabel: "Let's Chat!",
    mobileChatLabel: 'Chat with {{ agentName }}',
  },
  insurance: {
    addYourInformation: 'Add your property insurance information',
    carrier: 'Carrier',
    carrierName: 'Carrier name',
    considerGettingQuote: 'Consider getting a quote from our partner,',
    dataNotAvailable: 'Data Not Available',
    expirationDate: 'Expiration Date',
    myInsuranceSubtitle: "Track your home's coverage details",
    infoTooltip:
      'This information pulled from public records and is only available for you. It is not shared with anyone else.',
    formError: {
      currentRate: 'Enter a valid rate',
      premiumAmount: 'Enter a valid premium amount',
      carrierName: 'Enter a valid carrier name',
      expirationDate: 'Enter a valid expiration date',
      carrier: 'Enter a valid carrier name',
    },
    getAQuote: 'Get your free quote now',
    insurance: 'Insurance',
    myInsurance: 'My Insurance',
    perYear: 'Per Year',
    policyDetails: 'Policy Details',
    viewPolicyDetails: 'View Policy Details',
    viewAddDetails: 'View/Add Details',
    hidePolicyDetails: 'Hide Policy Details',
    policyExpirationDate: 'Policy Expiration Date',
    policyGoodThrough: 'Policy good through ',
    premiumAmount: 'Premium Amount',
    typicalRatesAre: 'Typical rates are',
    addNewPolicy: 'Add new policy',
    editPolicies: 'Edit Your Policies',
    deletePolicy: 'Delete Policy',
    areYouSureDelete: 'Are you sure you want to delete this policy?',
    prompt: {
      title: 'Have an active homeowners insurance policy?',
      description: 'Add & track alongside your home value',
      confirmButtonText: 'Add Insurance',
      closeButtonText: "I'll add it later",
    },
  },
  unauthorizedAccessPage: {
    message: 'Unfortunately, you are not authorized to access this page.',
    subtitle: ' Access Denied',
  },
  unexpectedErrorPage: {
    message:
      'The server encountered an internal error or misconfiguration and was unable to complete your request.',
    subtitle: 'Internal Error',
    title: '500',
  },
  appNotAvailablePage: {
    title: 'There was an issue while loading page!',
    subtitle:
      'Sorry for the inconvenience, we are working on this! In the meantime try reloading the page.',
  },
  listingDetailsNotFound: {
    title: 'Listing not found',
    subtitle: 'Listing for that mlsId do not exist.',
  },
  verifyAddress: {
    confirm: 'Confirm',
    cancel: 'Cancel',
    verifyAddressContent:
      'Below is the home address we have on file for you. Please confirm your address in order to receive the most accurate data.',
    verifyAddressTitle: 'Verify your home address',
  },
  userPersonalInformation: {
    personal: 'Personal',
    fullName: 'Full Name',
    homeAddress: 'Home Address',
    emailAddress: 'Email Address',
    security: 'Security',
    password: 'Password',
    social: 'Social Login',
  },
  editProfile: {
    headline: 'Edit',
    firstName: 'First Name',
    phoneNumber: 'Phone Number',
    lastName: 'Last Name',
    address: 'Home Address',
    addressShort: 'Address',
    email: 'Email Address',
    cancel: 'Cancel',
    save: 'Save',
    editAddressPlaceholder: 'Enter Home Address',
    invalidAddress: 'Invalid address',
  },
  youngAlfred: {
    youngAlfred: 'Young Alfred',
  },
  myHomeDetails: {
    greeting: {
      headline: 'Property Details & Value',
      subheading: '3102 Whitehorse Rd, Mesa AZ 28480',
    },
  },
  myMarkets: {
    greeting: {
      headline:
        'Track updates and trends \nall starting with your home market.',
      mobile: {
        headline: 'My Markets',
        subheadline:
          'Receive updates on properties in these \nmarkets, and follow hot areas and trends.',
      },
    },
    marketReport: 'Market Report',
    marketReport_my: 'My Home Market',
    seeMoreDetailsAboutTheMarket:
      'See more details about the market in {{area}}',
    otherMarketsDivider: 'Other markets you follow',
    checkOutMarketTrends: 'Check out market trends',
    prompt: {
      title: 'How does your home fit into the bigger picture?',
      description:
        "Add your address to see how your home compares in today's market.",
      confirmBtnText: 'Add',
      closeBtnText: "I'll add it later",
      inputPlaceholder: 'Enter your address',
      invalidAddress: 'Invalid address',
    },
  },
  nextHome: {
    greeting: {
      headline: '{{name}},',
      description: 'There are {{homes}} new homes just for you!',
    },
    propertySearch: {
      headline: 'Property Search',
      description: 'Properties you want to keep tabs on',
      cta: 'Search More Homes',
    },
    savedProperties: {
      headline: 'Saved Properties',
      description:
        'Updates on these properties are shared as prices change or they go on/off market.',
      cta: 'VIEW PHOTOS & PROPERTY DETAILS',
    },
    noSavedProperties: {
      description:
        'Updates on these properties are shared as prices change or they go on/off market.',
      title: 'Looks like you don’t have any properties saved yet.',
      text1: `Start by searching homes that may be a perfect fit for you!`,
      text2:
        'Then, click the heart to indicate which properties you want to keep tabs on.',
      cta: 'SEARCH MORE HOMES',
    },
    listed: 'Listed',
    listPrice: 'List Price',
    market: {
      cta: 'View details on all markets I follow',
    },
    recommendedProperties: {
      viewPhotos: 'View photos & property details',
      noRecommendedProperties:
        'Looks like you don’t have any recommended properties!',
    },
    noData: 'No properties found',
  },
  documentTitles: {
    marketDetailsPage: 'Market Review',
    myHomeMarket: 'My Market',
    chatPage: 'Chat',
    agentPage: 'About Agent',
    userProfile: 'User Profile',
    notificationSettings: 'Notification Settings',
  },
  pageTitles: {
    myHome: {
      valueAndEquity: 'My Home: Value & Equity',
      maintenance: 'My Home: Maintenance',
      marketTrends: 'My Home: Market Trends',
    },
    mySearch: {
      listings: 'My Search: Listings',
      favorites: 'My Favorites',
      transaction: 'My Search: Transaction',
      move: 'My Search: Move',
    },
    myAgent: 'My Agent',
    faq: 'FAQ',
  },
  noResultsMessage: {
    common: 'No results to show',
  },
  search: {
    header: {
      title: 'Good homes come to those who search with',
      subtitle: ', filter recommendation just for you',
    },
    suggestedListings: {
      noResults: 'No results found',
    },
    subheaders: {
      favorites: 'Favorites',
      listings: 'Listings',
      move: 'Move',
      transaction: 'Transaction',
    },
    listHomeCard: {
      headline: 'List your home here',
      link: 'Review a todays estimated sale price of your home',
    },
    greeting: {
      first: `Good homes come to `,
      second: {
        prefix: `those who`,
        bold: 'search with {{ agentName }}',
      },
    },
    savedHomes: {
      headline: 'Your Saved Homes',
    },

    newListingsNear: {
      headline: 'New Listings Near You',
    },
    savedSearches: {
      headline: 'Your Saved Searches',
      rename: 'Rename',
      searchNow: 'Search Now',
      moreFilters: 'more filters',
      noMin: 'No Min',
      noMax: 'No Max',
      confirmDeleteTitle: 'Are you sure you want to delete this search?',
      confirmDeleteSubtitle:
        'This saved search will be permanently deleted if you continue.',
      prompt: {
        headline:
          'Before you go, would you like to save your search for later?',
      },
    },
    footer: {
      title: 'Search for homes on your phone',
      form: {
        cta: 'TEXT ME THE LINK',
        placeholder: 'Enter your cell phone number',
        info: 'US mobile numbers only. Message & data rates may apply.',
      },
      cta: {
        appleStore: 'Apple Store',
        googlePlayStore: 'Google Play',
      },
    },
    filter: {
      toggleButton: {
        active: 'Filters: ',
        price: 'Price: ',
      },
      size: {
        title: 'Size (SQFT)',
        subtitle: 'Select the minumum and maximum size (sqft).',
      },
      lotSize: {
        title: 'Lot Size',
        subtitle: 'Select the minumum and maximum lot size (sqft).',
      },
      yearBuilt: {
        title: 'Year Built',
        subtitle: 'Select the minumum and maximum year.',
      },
      footer: {
        reset: 'Reset',
        viewMore: 'Show {{matches}} results',
        saveSearch: 'Save Search',
        viewAllMatches: 'View {{matches}} Matches in {{address}}',
        viewMatch: 'View {{matches}} Match in {{address}}',
        noMatch: 'No Match',
      },
      propertyType: {
        title: 'Property Type',
      },
      moreFilters: {
        title: 'More Filters',
        subtitle: 'Choose any and all that apply',
        tip: 'TIP',
        tipTextPreferences:
          'Results are guaranteed to have 1+ but not necessarily all selected features',
        tipTextRequirements:
          'Results are guaranteed to have all selected features',
      },
      numberOfRoomsSelect: {
        studio: 'Studio',
        minBedrooms: 'Minimum Bedrooms',
        minBathrooms: 'Minimum Bathrooms',
        filterType: {
          min: 'minimum',
          exact: 'exact',
        },
        prediction: {
          expand: 'Expanding to {{expand}} ',
          diff: ' will show you another {{diff}} listings.',
        },
        percentage:
          '{{percentage}}% of {{propertyType}} have {{num}} {{roomType}}',
        subtitle: {
          first: 'Select the {{ filterType }} number of',
          boldBedrooms: 'bedrooms',
          boldBathrooms: 'bathrooms',
          second: 'you need.',
        },
      },
      priceRange: {
        title: 'Price Range',
        subtitle: 'Select your budget range to see what matches.',
        predictiveSubtitleMoreListings:
          'Increase your range to {{predictiveMax}} to show another {{predictiveListingsNumber}} listings',
        subtitleListingsPercentage:
          'Represents {{listingsPercentage}}% of listings in area',
        min: 'MIN',
        max: 'MAX',
        noMin: 'No Min',
        noMax: 'No Max',
        subtitleStatic:
          'Click to show the days on site & average for that price range',
        error: `'Min' value needs to be lower then the 'Max' value`,
      },
    },
    listingTable: {
      address: 'Address',
      price: 'Price',
      beds: 'Beds',
      bath: 'Bath',
      sqft: 'SQFT',
      dom: 'DOM',
    },
    resultDropdown: {
      listingAddresses: 'Active Listings',
      savedSearches: 'Saved Searches',
      areas: 'Areas',
      recentlyViewed: 'Recently Viewed',
      noResult: {
        message: 'No results found based on your search',
        suggestion: 'Try searching in another area or changing your filters',
      },
    },
    listingsPreview: {
      viewDetailsCTA: 'View Details',
      openHouse: 'Open House {{date}}',
      priceRangeTitle: 'Price Range Of All Results',
    },
    actionButtons: {
      all: 'All',
      newListings: 'New Listings',
      recentlySold: 'Recently Sold',
      priceReduced: 'Price Reduced',
      priceChanged: 'Price Changed',
      openHouse: 'Open House',
      rental: 'Rental',
      tour3D: '3D Tour',
      active: 'Active',
      allSavedProperties: 'All Saved Properties',
      allSuggestedProperties: 'All Suggested Properties',
    },
    prompt: {
      title: 'Save your search',
      description:
        'Pick up where you left off later and be alerted to new matches as more homes come on the market.',
      confirmButtonText: 'Save my search',
      closeButtonText: 'Close',
    },
  },
  searchResult: {
    noResult: {
      title: 'No results found based on your search.',

      text: "This location might be outside of your agent's coverage area, the address is not currently active on the market, or no results meet your criteria.",

      suggestion:
        'Try: checking your spelling, searching in another area, or adjusting your filters.',
      buttonLabel: 'Retry Search',
    },
    sortDropdown: {
      title: 'Sort Map',
      title2: 'Sort',
      allMatchingListings: 'All Matching Listings',
      listDateNewest: 'List Date (newest)',
      newest: 'Newest',
      lowToHighPrice: 'Price (Low to High)',
      highToLowPrice: 'Price (High to Low)',
      sqft: 'Square Feet',
      allListings: 'All Listings',
      bedrooms: 'Bedrooms',
      listDateOldest: 'List Date (oldest)',
      priceHighest: 'Price (highest)',
      priceLowest: 'Price (lowest)',
      bedCountHighest: 'Bed Count (highest)',
      sqftHighest: 'Square Feet (highest)',
      saleDateNewest: 'Sale Date (newest)',
      saleDateOldest: 'Sale Date (oldest)',
    },
    saveSearch: {
      successMessage:
        ' Now that this search has been saved, you will receive notifications on properties that meet the criteria you’ve selected.',
      viewMatchesCTAText: 'View {{numberOfListings}} Matches in {{areaName}}',
      manageSavedSearchesCTAText: 'Manage Your Saved Searches',
      saveSearchSuccessTitle: 'This search has been saved!',
      saveSearchDescription: 'Save the search criteria you have selected.',
      saveSearchReplaceTitle: 'Almost there!',
      saveSearchReplaceDescription:
        "You are limited to five saved searches. To save this search select which existing search you'd like to replace.",
      replaceSearchCTAText: 'Replace',
      alreadySavedDescription:
        'You already saved this search. Do you want to replace it?',
    },
    header: {
      criteriaFitText: {
        primary: '{{ matches }} matches fit your criteria!',
        secondary:
          'Represents {{ percent }}% of {{ criteria }} listings in area',
        tertiary:
          'Represents {{ percent }}% of listings in area that are sold in {{ criteria }}',
      },
      features: 'Features:',
    },
    info: {
      perfectMatches: '{{number}} perfect matches',
      closeMatch: 'close match',
      closeMatches: 'close matches',
    },
    filterDropdown: {
      title: 'Filter',
      savedHomes: 'Saved Homes',
      suggestedHomes: 'Suggested Homes',
      newListings: 'New Listings',
      upcomingOpenHouse: 'Open House',
      priceReduced: 'Price Reduced',
      tour3DAvailable: '3D Tour ',
      allListings: 'All',
      recentlySold: 'Recently Sold ',
      priceChange: 'Price Chnage ',
    },
  },
  mobileAppDeepLink: {
    pageTitle: 'Download Mobile App',
    title: 'Download Your Homeownership App',
    description:
      'FREE access to all local listings, track your home’s estimate, equity & chat directly on homes of interest.',
    sendMe: "Send me {{agentName}}'s app",
    qrDescription:
      'Scan the QR code using your smartphone or tablet to download the app',
    downloadApp: "Download {{agentName}}'s app now!",
    contactMe: 'Contact Me',
  },
  savedHomes: {
    heading: "'s Favorites",
    savedProperties: 'Saved Properties',
    savedHomesCard: {
      text: 'Receive notification on properties that meet your saved search criteria',
      reviewSavedSearches: 'Review {{count}} saved searches',
      startANewSearch: 'Start a new search',
    },
    noResults: 'No results to show',
    viewSavedSearches: 'View and manage your saved searches',
    infoPanel: {
      savedSearches: 'Saved Searches',
      noSavedSearches: 'No Saved Searches',
      text: 'Receive notifications on properties that meet your search criteria.',
      ctaSavedSearches: 'REVIEW {{count}} SAVED SEARCHES',
      ctaNoSavedSearches: 'START A SEARCH',
    },
    sold: 'sold',
    pending: 'pending',
    daysOnTheSite: 'days on the site',
    daysOnSite: 'Days on Site',
    dayOnSite: 'Day on Site',
    comingSoon: 'coming soon',
    modal: {
      title: 'Your Saved Searches',
      description:
        'You receive notifications on properties that meet the criteria you’ve selected in these searches.',
      runSearch: 'Run Search',
      deleteSearch: 'Delete Search',
    },
    sortDropdown: {
      title: 'Sort',
      allMatchingListings: 'All Matching Listings',
      listDateNewest: 'Newest Listings',
      priceHighest: 'Price High to Low',
      priceLowest: 'Price Low to High',
      bedCountHighest: 'Beds High to Low',
      bathCountHighest: 'Baths High to Low',
      sqftHighest: 'SQ FT High to Low',
    },
  },
  aboutAgent: {
    sidePanel: {
      askQuestion: 'Ask a question',
      startChat: 'Start a Chat',
      emailMe: 'Email Me',
      callMe: 'Call Me',
    },
    brokerage: {
      address: 'Address',
      hoursOfOperation: 'Hours of Operation',
      mondayToFriday: 'Monday - Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',
    },
  },
  errorPages: {
    locationService: {
      heading: 'Marco...Marco...',
      subheading: 'Bummer, we’re not able to locate you.',
      text: 'Make sure your location services are enabled through your device settings, then try again.',
      buttonLabel: 'Try Again',
    },
    pageNotFound: {
      heading: 'How’d you get in the basement?',
      subheading:
        'We’re not even sure how you got here, maybe you should head home…',
      text: 'We try to not make the same mistake twice. This issue has been logged and reported to our development team.',
      goHomeButton: 'Take Me Home',
      refreshButton: 'Refresh',
      reportButton: 'Report',
    },
    listingNotFound: {
      heading: 'We can’t find your matching sock.',
      text: 'Reach out to {{name}} and he/she can help you make it reappear.',
      buttonLabel: 'Chat With {{name}}',
    },
    unexpectedError: {
      heading: 'We’re remodeling the kitchen.',
      subheading: 'We’re almost done, try refreshing.',
      note: 'We try to not make the same mistake twice. This issue has been logged and reported to our development team.',
      refreshButton: 'Refresh',
    },
  },
  faq: {
    title: 'FAQ',
    subtitle:
      'The most frequently asked questions (FAQs) about your homeownership app.',
    contact: {
      title: 'Contact',
      text: 'Reach out to {{agent}} for tips on ',
      boldText: 'getting the most out of your homeownership App experience',
    },

    general: {
      title: 'General',
      q2: 'How do I share the app with other people?',
      a2: 'From the expanded menu, navigate to the “share” button. This will pre-populate a text message with an app download link specific to your Agent for you to easily send.',
    },
    account: {
      title: 'Account',
      q1: 'Account Deletion?',
      a1: 'Users can submit a request to delete their entire account record here. Account deletions are manual and can take up to 7 days to process. Submit your request for account deletion',
    },
    myHomeDetails: {
      title: 'My Home Details',
      q1: 'Where do the home details come from?',
      a1: 'Home details come from Multiple Listing Service (MLS) data as well as public records.',
      q2: 'My home details look wrong, how can I update?',
      a2: 'Select your Home Address. This will take you to the Home Details page. Details can be edited by clicking “something looks off” from the “property value & details” page. Once you’ve made the relevant changes, hit “save”.',
      q3: 'How is square footage determined?',
      a3: 'The square footage of the home reflects the last recorded public county/title record.',
      q4: `This isn't my home, how do I update the address?`,
      a4: 'From the expanded menu, navigate to “Profile” and click into “home address” to update.',
      q5: `Why can't my home address be found?`,
      a5: `It's likely that the address was entered incorrectly or that the coordinates for the map location couldn't be found in public records to reference.`,
      q6: `I don't own a home, why am I being asked for my address?`,
      a6: 'The My Home section has several features designed to help you with different parts of home ownership such as tracking how much your home is worth, or how much equity you have. If you do not have an address, you may still use the majority of the features in the app without that information.',
    },
    myHome: {
      title: 'My Home',
    },
    myHomeEstimate: {
      title: 'My Home Estimate',
      q1: 'How is the estimate determined?',
      a1: 'We analyze a variety of data points, such as public records, home features, market information, etc to determine the Home Estimate. Edit your home details and confirm or remove similar homes to improve accuracy.',
      q2: 'Why is this estimate different from other sites?',
      a2: 'Other sites might use different algorithms and sources to determine their estimates. Reaching out to your agent is always the best source for getting to the most accurate home value.',
      q3: 'What is a confidence score?',
      a3: 'The confidence score is our level of comfort with the accuracy of the estimate. This is based on several factors, such as how many data points were analyzed and how recently the data was collected.',
      q4: 'My estimate seems off, why would that be?',
      a4: 'The estimate is not meant to replace a full appraisal, and may not fully reflect the condition or recent changes to your home or market. For an in-depth analysis, contact your Agent.',
      q5: 'What are comps and how are they determined?',
      a5: "“Comps” are comparable properties in a specific area that you're looking to buy or sell in that are used to determine the value of the home, usually sold in the same neighborhood or in an area as close as possible to the house being valued.",
    },
    myHomeMortgage: {
      title: 'My Home Mortgage',
      q1: 'Where did this information come from?',
      a1: 'This information is provided from publicly available property records. Alternatively, you may also add this information into the app directly.',
      q2: 'I have multiple mortgage options, can I track each?',
      a2: 'Yes, select the “View/Add Mortgage Details” button to expand the details, then select the “Add New Loan” button to add another loan and hit “save” when the relevant information has been entered.',
      q3: 'Who is this shared with?',
      a3: 'Mortgage information is not shared - this is for your reference only.',
      q4: 'How do I edit this information?',
      a4: 'Select the “View/Add Mortgage Details” button to view the Loan Details. Select the Pencil symbol to change the loan information, and hit Save when the changes have been entered.',
      q5: 'How do I remove this information?',
      a5: 'Select the View/Add Mortgage Details” button to view the Loan Details. Select the Pencil symbol and then select “Delete Loan.” A prompt will appear to confirm or cancel, select Confirm to continue deleting your loan information.',
    },
    myInsurance: {
      title: 'My Insurance',
      q1: `Why isn’t my insurance information showing?`,
      a1: `Insurance data is not publicly available, so cannot automatically be added like mortgage data. However, we've offered the ability to add your insurance information so you can track that inside the My Home section.`,
      q2: 'My insurance is bundled, can I add that?',
      a2: 'Yes, insurance - bundled or not - can be manually added and tracked.',
      q3: 'I have multiple insurance options, can I track each?',
      a3: 'Yes, select the “View/Add Insurance Details” button to expand the menu. Select the “Add New Policy” button to add another policy, or “Edit Policy” to edit or delete an existing policy, and then hit Save when the relevant information has been entered.',
      q4: 'Who is this information shared with?',
      a4: 'Insurance information is not shared - this is for your reference only.',
    },
    mySearch: {
      title: 'My Search',
      q1: 'How are suggested homes determined?',
      a1: 'Suggestions are based on your activity in the app. This includes locations you have provided in your profile, saved searches and saved homes.',
      q2: 'How do I remove a saved home?',
      a2: 'Click the white heart-shape symbol at the top right of the listing image to unsave the home. The heart symbol will change back to an outline.',
      q3: 'What areas can I search?',
      a3: 'The search experience is provided by your agent and reflects their coverage area. Not sure what coverage area this includes? Reach out to your agent.',
      q4: `I'm searching for a specific address and not seeing results`,
      a4: `It's possible that the address is not within your agent's licensed coverage area. The address could also be missing from the MLS data. Check with your agent to be sure. If they think this is an error they can report it.`,
      q5: `There are features in a home that are important to me that I don't see.`,
      a5: `Not all features are provided in the MLS data. Reach out to your agent if you don't see a detail you have a question about.`,
      q6: 'What are “hits” and “misses”?',
      a6: 'Hits are features the listing has that are hard to find in your market and price range. Misses are common features that most homes in your market and price range have, but the listing does not.',
      q7: 'How do I save a listing?',
      a7: 'Save a listing by clicking on the heart icon in the lower right corner of the listing.',
      q8: `What's the difference between 'Preferences' and 'Requirements'`,
      a8: `Preferences are “wish list” items that it would be great to have, but aren't a deal breaker for you. Requirements are your “must haves” for your ideal home.`,
      q9: 'Map key',
      a9: `Grey = Close Match \nGreen = Active\nRed = Sold\nYellow = Pending\nStar = Suggested Home\nHeart = Saved Home`,
    },
    myMove: {
      title: 'Move',
      q1: 'How do I start a new move?',
      a1: 'Moves are automatically started when you enter a new transaction to track. Moves can also be started without opening a transaction by navigating to the “Move” page and entering the address and move date for the move.',
      q2: `Can I see previous moves? `,
      a2: `Yes, past moves can be reviewed in the Move drop-down from the navigation bar.  You can also delete them in the same place if you choose.`,
      q3: `Does my move need to be planned a certain time in advance to use the tracker? `,
      a3: `No, you can use the tracker to plan a move no matter how soon or far away it is! The tracker will adjust the timeline of your checklist accordingly. `,
      q4: `Do I have to complete the steps in the order they are presented on the tracker? `,
      a4: `No, you can skip ahead and check items off no matter the order you do them in. The dates are suggestions based on the average move.`,
      q5: `Why should I reach out to my agent about quotes, questions, and referrals? `,
      a5: `Your agent is your local expert regarding all things homeownership. They are an excellent resource for any questions you have when working with local offices, vendors, and other resources you might need during this transitional period. `,
      q6: `Can I have multiple moving inventory documents? `,
      a6: `Only one moving inventory document can be tied to a move. If you have multiple moves, you can have a moving inventory document for each. `,
      q7: `How do I end a move?`,
      a7: `Moves will automatically be turned off once the “Move Date” has been reached. Moves can be manually turned off by clicking the “Open” toggle and deactivating the tracker. Once the move is turned off automatically/manually, it will show in your “Past moves.”`,
    },
    myTransaction: {
      title: 'Transaction',
      q1: 'How do I start a new transaction?',
      a1: `Start your transaction by entering the address of your new home, opened escrow date, and estimated closing date at the top of the page. Tip: Activating a new transaction also activates a new Move! 
      `,
      q2: `What are“ contingency removal dates” and why should I add them?
      `,
      a2: `Contingency removal dates are contractual dates outlined in the purchase agreement specifying when the buyer will remove contingencies and commit to closing. These are critical to track closely because if the buyer backs out of a deal after contingencies are removed, they can lose their deposit. In addition, the seller has rights to submit a “notice to perform” and potentially cancel escrow if these dates aren’t met when outlined. 

      Three common contingencies can be tracked here: inspection contingency, appraisal contingency, and loan contingency. The contractual due dates for each can be added & edited for your transaction by clicking “Add & track contingency removal dates” under the tracker. 
      `,
      q3: `Can I change my address or dates after I started tracking my transaction? `,
      a3: `Yes, you can edit your address, offer accepted date, and estimated closing date by clicking on the pencil icons next to each item on the Transaction tracker. `,
      q4: `Can I add or remove steps to the tracker? `,
      a4: `No, the steps in the tracker are based on typical transaction milestones to help highlight where the team is currently focused.. You might have more or fewer steps depending on your unique transaction experience. The option to reach out to your Agent for an update is always present.`,
      q5: `I have a question about the steps on the tracker. Where do I find the answer? `,
      a5: `Request an update from your agent by clicking the “request update” from the Transaction page.`,
      q6: `What happens on/after my transaction’s estimated closing date?`,
      a6: `You will be taken to your updated My Home page, and the home address will automatically update to the one from your transaction. The Transaction page will reset for future visits. `,
      q7: `How do I cancel my transaction?`,
      a7: `You can cancel your transaction tracker at any time by toggling “OPEN” at the top of the page to off. You will be asked to confirm if you would like to deactivate the tracker. `,
    },
    myMaintenance: {
      title: 'Maintenance',
      q1: `Do I need to complete a transaction or move to track my home maintenance? `,
      a1: `No, the maintenance tracker is always available to use and is based on seasonal needs and the current month. If you are a future homeowner this page can help you plan for what to expect.`,
      q2: `Can I add items to the checklist? `,
      a2: `Not at this time. The provided list is based on common items that should be done during each season.`,
      q3: `Why are some items on the list in more than one season? What happens if I check these items off? `,
      a3: `Some items, like changing the filters on your HVAC system or changing the batteries in your smoke detectors, are recommended to be done more than once per year. If you check the item off in one season, the previous date completed will show in the next so that you can keep track of the last time it was done. `,
      q4: `Why should I reach out to my agent about quotes, questions, and referrals?`,
      a4: `Your agent is your local expert regarding all things homeownership. They are an excellent resource for any questions you have when working with local offices, vendors, and other resources you might need while completing maintenance on your home.`,
      q5: `How is my average annual maintenance cost calculated?`,
      a5: `Your home estimate (if available) is used to calculate how much you can expect to spend on home maintenance annually based on the fact that the average homeowner spends 1-4% of their home’s value each year.`,
    },
  },
  notifications: {
    loadMore: 'Load more',
    markAsRead: 'Mark as read',
    markAllAsRead: 'Mark all as read',
    remove: 'Remove this notification',
    emptyTabMessage: 'No messages at this time',
  },
  askQuestionModal: {
    title: 'Ask a Question',
    text: 'All questions are texted in real time to our agents to ensure the fastest response possible.',
    cta: 'Send Question To Your Agent',
    policyText:
      'We keep your info safe & never share it. View our privacy policy or terms of use.',
  },
  landscapeNotSupported:
    'Please switch to portrait mode for the best experience.',
};
