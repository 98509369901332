export const getListingSizeSQFT = (
  sizes?: { value: number; unit: 'acre' | 'sqft' }[],
): number => {
  const sqftSize = sizes?.find((s) => s.unit === 'sqft')?.value;
  if (sqftSize !== undefined) {
    return sqftSize;
  }
  const acresSize = sizes?.find((s) => s.unit === 'acre')?.value;
  if (acresSize !== undefined) {
    return acresSize * 43560;
  }
  return 0;
};

export const getLotSize = (
  sizes: { value: number; unit: 'acre' | 'sqft' }[] = [],
): { label: string; value: number } => {
  const sqft = sizes.find((item) => item.unit === 'sqft');
  const acre = sizes.find((item) => item.unit === 'acre');

  const sqftValue = sqft?.value || 0;
  const acreValue =
    typeof acre?.value === 'number' ? acre.value : (sqftValue || 1) / 43560;

  const label = acreValue >= 0.25 ? 'LOT ACRE' : 'LOT SQFT';

  const value =
    acreValue >= 0.25
      ? parseFloat(acreValue.toFixed(2))
      : Math.round(sqftValue);

  return {
    label,
    value,
  };
};
