import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

const messages = [
  'passwordRules.0',
  'passwordRules.1',
  'passwordRules.2',
  'passwordRules.3',
  'passwordRules.4',
];
function PasswordRulesInfo(): JSX.Element {
  const { t } = useTranslation();
  return (
    <Text>
      <ul>
        {messages.map((message: string, index: number) => (
          <li key={index}>{t(message)}</li>
        ))}
      </ul>
    </Text>
  );
}

export default PasswordRulesInfo;

const Text = styled.div`
  color: ${({ theme }) => theme.colors.grey.dark};
  font-size: 0.8rem;
  font-style: italic;
  margin-top: 0.5rem;
  ul {
    list-style-type: none;
  }
`;
