import React, {
  useCallback,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
  useEffect,
} from 'react';

import { ListingDetails } from '../types';
import { ChatWarningProvider } from './ChatWarnignProvider';
import { useAgentDetails } from '../hooks/query/useAgentDetails';
import { ChatModal } from '../features/Chat/ChatModal/ChatModal';
import { useAuthorization } from './Authentication/useAuthorization';
import { useAnalytics, ConversationTypes } from '../features/analytics';
import { AskQuestionModal } from '../components/V2/AskQuestionModal/AskQuestionModal';
import { useHistory, useLocation } from 'react-router-dom';
import { STATIC_CONFIG } from '../constants/config';
import { useAgentPhoneNumber } from '../hooks/useAgentPhoneNumber';

interface ContactAgentContext {
  isLoading: boolean;
  chatEnabled: boolean;
  onEmail: (e: Event) => void;
  onPhone: (e: Event) => void;
  setAskQuestion: Dispatch<SetStateAction<string>>;
  onChat: (e: Event | React.MouseEvent<HTMLElement> | null) => boolean;
  setMlsAskQuestionValue: (listingDetails?: ListingDetails | null) => void;
}

const ContactAgentContext = React.createContext<
  ContactAgentContext | undefined
>(undefined);

export const useContactAgent = (): ContactAgentContext => {
  const context = useContext(ContactAgentContext);

  if (!context) {
    throw new Error(
      'useContactAgentContext cannot be used without ContactAgentContext',
    );
  }

  return context;
};

export const ContactAgentProvider: React.FC = ({ children }) => {
  const { push } = useHistory();
  const location = useLocation();
  const { data: agent } = useAgentDetails();
  const { trackConversation } = useAnalytics();
  const { gatedOnOpenLoginModal } = useAuthorization();
  const [askQuestion, setAskQuestion] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mlsId, setMlsId] = useState<number | null>(null);

  const phone = useAgentPhoneNumber();

  const contact = useMemo(() => {
    return agent?.AgentCommunication;
  }, [agent]);

  const { chatEnabled } = contact || {};

  const onOpenModal = useCallback(() => {
    if (!agent) return;
    setIsModalOpen(true);
  }, [agent]);

  const onCloseModal = useCallback(() => setIsModalOpen(false), []);

  const Component = useMemo(() => {
    if (!isModalOpen) return null;

    return chatEnabled ? (
      <ChatModal isOpen={true} onClose={onCloseModal} />
    ) : (
      <AskQuestionModal
        isOpen={true}
        mlsId={mlsId}
        onClose={onCloseModal}
        defaultValue={askQuestion}
      />
    );
  }, [isModalOpen, chatEnabled, onCloseModal, mlsId, askQuestion]);

  const onEmailClick = useCallback(
    (e: Event) => {
      e?.preventDefault?.();

      trackConversation(ConversationTypes.Email);

      if (!agent) return;

      const agentEmail = contact?.useEmailSecondary
        ? contact?.emailSecondary
        : contact?.emailPrimary;

      window.location.assign(`mailto:${agentEmail}`);
    },
    [
      agent,
      contact?.emailPrimary,
      contact?.emailSecondary,
      contact?.useEmailSecondary,
      trackConversation,
    ],
  );

  const setMlsAskQuestionValue = useCallback(
    (listingDetails?: ListingDetails | null) => {
      if (!listingDetails) {
        setMlsId(null);
        setAskQuestion('');
        return;
      }

      setMlsId(listingDetails.MlsInfo.mlsId);
      setAskQuestion(
        `I have a question about MLS#: ${listingDetails?.mlsListingId} at ${listingDetails?.Address.addressLine1}`,
      );
    },
    [],
  );

  const onPhoneClick = useCallback(
    (e: Event) => {
      e?.preventDefault?.();
      trackConversation(ConversationTypes.Call);

      if (!agent || !phone) return;

      window.location.assign(`tel:${phone}`);
    },
    [agent, phone, trackConversation],
  );

  const onChatClick = useCallback(() => {
    if (chatEnabled) {
      return onOpenModal();
    }

    onOpenModal();
  }, [chatEnabled, onOpenModal]);

  const onClickItem = useCallback(
    (
      e: Event | React.MouseEvent<HTMLElement> | null,
      onChatClick: () => void,
    ) => gatedOnOpenLoginModal(e, onChatClick),
    [gatedOnOpenLoginModal],
  );

  useEffect(() => {
    const openChat = location.search.includes(
      STATIC_CONFIG.MY_AGENT_CHAT_REDIRECT_PARAM,
    );

    if (openChat) {
      gatedOnOpenLoginModal(null, onChatClick);
    }

    if (openChat && isModalOpen) {
      push(location.pathname);
    }
  }, [
    push,
    isModalOpen,
    onChatClick,
    onClickItem,
    location.search,
    location.pathname,
    gatedOnOpenLoginModal,
  ]);

  const value: ContactAgentContext = useMemo(
    () => ({
      setAskQuestion,
      isLoading: !agent,
      onPhone: onPhoneClick,
      onEmail: onEmailClick,
      setMlsAskQuestionValue,
      chatEnabled: !!chatEnabled,
      onChat: (e: Event | React.MouseEvent<HTMLElement> | null) =>
        onClickItem(e, onChatClick),
    }),
    [
      agent,
      chatEnabled,
      onClickItem,
      onChatClick,
      onEmailClick,
      onPhoneClick,
      setMlsAskQuestionValue,
    ],
  );

  return (
    <ChatWarningProvider>
      <ContactAgentContext.Provider value={value}>
        {Component}
        {children}
      </ContactAgentContext.Provider>
    </ChatWarningProvider>
  );
};
