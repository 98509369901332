import { useTheme } from '@emotion/react';

import { colorOpacityApplier } from '../../../services/colorOpacityApplier';

export function MoveIcon(): JSX.Element {
  const theme = useTheme();
  const brandingColor = theme.colors.branding.main;
  const brandingColorWithOpacity = colorOpacityApplier(brandingColor, '0.2');

  return (
    <svg
      width="55"
      height="55"
      fill="none"
      viewBox="0 0 55 55"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="55" height="55" rx="3" fill="white" />
      <path
        stroke={brandingColor}
        strokeLinejoin="round"
        d="M33.6129 39.4315H21.5781"
      />
      <path
        stroke={brandingColor}
        strokeLinejoin="round"
        d="M9.56216 36.4517L9.4773 11.6935C9.47444 10.7669 10.2174 10.0112 11.1421 10L43.081 10.0187C43.5044 10.0188 43.9093 10.1919 44.2023 10.4981C44.4953 10.8043 44.6508 11.217 44.633 11.6407L44.3411 18.6449C44.3002 19.6233 43.4967 20.3953 42.5193 20.3953H36.0136C35.6011 20.3951 35.2055 20.5591 34.9138 20.8512C34.6221 21.1433 34.4582 21.5396 34.4582 21.9528V37.7864"
      />
      <path
        stroke={brandingColor}
        strokeLinejoin="round"
        d="M13.8719 36.4904H9.46797C8.65723 36.4904 8 37.1489 8 37.9612C8 38.7734 8.65723 39.4319 9.46797 39.4319H12.7705"
      />
      <ellipse
        cx="17.1948"
        cy="40.1671"
        rx="4.77047"
        ry="4.77941"
        stroke={brandingColor}
        strokeLinejoin="round"
      />
      <ellipse
        cx="17.1947"
        cy="40.1668"
        rx="2.12049"
        ry="2.12446"
        stroke={brandingColor}
        strokeLinejoin="round"
      />
      <path
        stroke={brandingColor}
        strokeLinejoin="round"
        d="M41.7546 36.4904H46.5319C47.3426 36.4904 47.9999 37.1489 47.9999 37.9612C47.9999 38.7734 47.3426 39.4319 46.5319 39.4319H43.2396"
      />
      <path
        stroke={brandingColor}
        strokeLinejoin="round"
        d="M47.2659 36.8578V29.8094C47.2659 28.8291 46.4727 28.0344 45.4942 28.0344H34.7891"
      />
      <path
        stroke={brandingColor}
        strokeLinejoin="round"
        d="M47.265 31.7112H44.6973C44.4048 31.7112 44.1242 31.5947 43.9173 31.3875C43.7105 31.1802 43.5942 30.8991 43.5942 30.606V28.0344"
      />
      <path
        stroke={brandingColor}
        strokeLinejoin="round"
        d="M39.9856 20.2638L42.8307 28.1419"
      />
      <ellipse
        cx="38.6484"
        cy="40.1671"
        rx="4.77047"
        ry="4.77941"
        stroke={brandingColor}
        strokeLinejoin="round"
      />
      <ellipse
        cx="38.6483"
        cy="40.1668"
        rx="2.12049"
        ry="2.12446"
        stroke={brandingColor}
        strokeLinejoin="round"
      />
      <path
        stroke={brandingColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M41.3942 12.5931H34.0552"
      />
      <path
        fill="#F5F5F5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8396 23.3503V28.9404C15.8396 29.3312 16.0673 29.6862 16.4225 29.8493L18.8569 30.9666C19.5194 31.2706 20.274 30.7866 20.274 30.0577V20.953"
      />
      <path
        fillOpacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        fill={brandingColorWithOpacity}
        d="M15.8396 23.3503V28.9404C15.8396 29.3312 16.0673 29.6862 16.4225 29.8493L18.8569 30.9666C19.5194 31.2706 20.274 30.7866 20.274 30.0577V20.953"
      />
      <path
        stroke={brandingColor}
        strokeWidth="0.885714"
        strokeLinejoin="round"
        d="M15.8396 23.3503V28.9404C15.8396 29.3312 16.0673 29.6863 16.4225 29.8493L18.8569 30.9666C19.5194 31.2706 20.274 30.7866 20.274 30.0577V20.953"
      />
      <path
        fill="#F5F5F5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.4211 23.3291V29.2623C27.4211 29.733 27.0929 30.14 26.6328 30.2396L21.4856 31.3545C20.8625 31.4895 20.2739 31.0147 20.2739 30.3772V20.953"
      />
      <path
        fillOpacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        fill={brandingColorWithOpacity}
        d="M27.4211 23.3291V29.2623C27.4211 29.733 27.0929 30.14 26.6328 30.2396L21.4856 31.3545C20.8625 31.4895 20.2739 31.0147 20.2739 30.3772V20.953"
      />
      <path
        stroke={brandingColor}
        strokeLinejoin="round"
        strokeWidth="0.885714"
        d="M27.4211 23.3291V29.2623C27.4211 29.733 27.0929 30.14 26.6328 30.2396L21.4856 31.3545C20.8625 31.4895 20.2739 31.0147 20.2739 30.3772V20.953"
      />
      <path
        fill="white"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.4254 20.0166L29.3168 23.0065L21.6324 24.3216L20.2748 20.9543L18.6694 24.3216L14.0491 22.737L15.8403 19.742"
      />
      <path
        fillOpacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        fill={brandingColorWithOpacity}
        d="M27.4254 20.0166L29.3168 23.0065L21.6324 24.3216L20.2748 20.9543L18.6694 24.3216L14.0491 22.737L15.8403 19.742"
      />
      <path
        stroke={brandingColor}
        strokeLinejoin="round"
        d="M27.4254 20.0166L29.3168 23.0065L21.6324 24.3216L20.2748 20.9543L18.6694 24.3216L14.0491 22.737L15.8403 19.742"
      />
      <path
        fill="white"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3025 19.0238L27.4264 20.0151L20.274 20.9502L15.8396 19.7379L23.3025 19.0238Z"
      />
      <path
        fillOpacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        fill={brandingColorWithOpacity}
        d="M23.3025 19.0238L27.4264 20.0151L20.274 20.9502L15.8396 19.7379L23.3025 19.0238Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        stroke={brandingColor}
        strokeLinejoin="round"
        d="M23.3025 19.0238L27.4264 20.0151L20.274 20.9502L15.8396 19.7379L23.3025 19.0238Z"
      />
    </svg>
  );
}
