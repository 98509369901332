import styled from '@emotion/styled';
import { Layer, WithIsMobile } from '../../../types';
import { FlexRow } from '../../../components/V2/Flex/Flex';

export const Header = styled.header`
  width: 100%;
  position: sticky;
  top: 0;
  z-index: ${Layer.Default + 1};
  background-color: ${({ theme }) => theme.colors.white};
`;

export const NavBar = styled('nav')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '2.4rem 3.4rem 2.4rem 2.4rem',
  '@media (max-width: 320px)': {
    padding: '2.4rem 1rem',
  },
});

export const BrandContainer = styled(FlexRow)<WithIsMobile>(({ isMobile }) => ({
  width: isMobile ? '60%' : '20%',
  '@media (max-width: 576px)': {
    width: '65%',
  },
}));

export const NavbarBrand = styled('span')<WithIsMobile>(
  ({ theme, isMobile }) => ({
    maxHeight: '3.2rem',
    marginLeft: isMobile ? 'auto' : '8.2rem',
    '&:not(:last-of-type):before': {
      backgroundColor: theme.colors.white,
    },
    '@media (max-width: 860px)': {
      marginLeft: isMobile ? 'auto' : '1rem',
    },
  }),
);

export const Logo = styled('img')({
  widht: '100%',
  height: '100%',
  objectFit: 'cover',
});

export const Nav = styled('ul')<WithIsMobile>(({ isMobile }) => ({
  margin: 0,
  gap: '3.2rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  width: isMobile ? '20%' : 'fit-content',
}));
