import { FC, ReactElement } from 'react';

import {
  useAuthentication,
  AuthenticationProvider,
} from './AuthenticationProvider';
import { KeyProvider } from './KeyProvider';
import { HolpTokensProvider } from './HolpTokensProvider';
import { AuthProvider } from './AuthProvider';
import { useTokenData } from './useTokenData';
import { FeatureFlagProvider } from '../../services/FeatureFlagProvider';

interface Props {
  loader: ReactElement | null;
}

const AuthPendingBlocker: FC<Props> = ({ children, loader = null }) => {
  const { isLoading } = useAuthentication();

  if (isLoading) return loader ?? null;

  return <>{children}</>;
};

const AccountIdBlocker: FC<Props> = ({ children, loader }) => {
  const { isLoading } = useTokenData();

  if (isLoading) return loader ?? null;

  return <>{children}</>;
};

export const Authentication: FC<Props> = ({ children, loader = null }) => {
  return (
    <KeyProvider>
      <AccountIdBlocker loader={loader}>
        <HolpTokensProvider loader={loader}>
          <AuthProvider>
            <AuthenticationProvider>
              <FeatureFlagProvider loader={loader}>
                <AuthPendingBlocker loader={loader}>
                  {children}
                </AuthPendingBlocker>
              </FeatureFlagProvider>
            </AuthenticationProvider>
          </AuthProvider>
        </HolpTokensProvider>
      </AccountIdBlocker>
    </KeyProvider>
  );
};
