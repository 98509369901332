import styled from '@emotion/styled';

export const NavigationItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NavigationItem = styled.div<{
  hasBorderBottom?: boolean;
  isGray?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 1.6rem 2.4rem;
  position: relative;
  color: ${({ isGray, theme }) =>
    isGray ? theme.colors.grey.dark : theme.colors.black};
  border-bottom: ${({ hasBorderBottom }) =>
    hasBorderBottom && '1px solid #f0f0f0'};
  cursor: pointer;
`;

export const NavigationItemLabel = styled.div<{
  isBold?: boolean;
}>`
  font-weight: ${({ theme, isBold }) =>
    isBold ? theme.fontWeight.bold : theme.fontWeight.medium};
`;

export const NavigationItemIconWrap = styled.div<{ isGray?: boolean }>`
  svg {
    transform: scale(1.6);
    color: ${({ isGray, theme }) =>
      isGray ? theme.colors.grey.dark : theme.colors.branding.main};
    // width: 22px;
    // height: 22px;
  }
`;

export const AccordionPanel = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  opacity: ${({ show }) => (show ? '1' : '0')};
  transition: opacity 0.3s linear;
`;

export const InputBox = styled.div<{ hasMt?: boolean }>`
  display: flex;
  position: relative;
  border: 1px solid #f5f5f5;
  background: #ffffff;
  box-shadow: 0px 2px 2px #00000014;
  border: 1px solid #f5f5f5;
  padding: 1rem;
  margin-top: ${({ hasMt }) => hasMt && '1rem'};
`;

export const InputBoxLabel = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const InputBoxText = styled.div`
  padding-left: 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
`;

export const ErrorMsg = styled.div<{ hasMt?: boolean }>`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.red};
  margin-top: ${({ hasMt }) => hasMt && '1rem'};
  min-height: 15px;
`;

export const Divider = styled.div`
  background: #989797;
  width: 90%;
  height: 1px;
  margin: 0 auto;
  opacity: 0.2;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
export const IconWrap = styled.div`
  display: flex;
  svg {
    color: ${({ theme }) => theme.colors.grey.dark};
  }
  cursor: pointer;
`;
