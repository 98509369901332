import styled from '@emotion/styled';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  paddingBottom: '4rem',
  flexShrink: 0,
});

const PageContainer = styled('div')({
  width: '95%',
  maxWidth: 1280,
  margin: '0 auto',
});

const BG = styled('div')(({ theme }) => ({
  background: theme.colors.branding.main,
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: 256,
  '@media (max-width: 630px)': {
    height: '300px',
  },
}));

const GreetingContainer = styled(PageContainer)(() => ({
  height: 256,
  display: 'flex',
  alignItems: 'center',
  paddingBottom: '2.3rem',
}));

const ContentContainer = styled(PageContainer)({
  display: 'grid',
  gridTemplateColumns: '1fr 4fr',
  gridGap: 25,
  marginTop: -60,
  '@media (max-width: 820px)': {
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr',
  },
  '@media (max-width: 630px)': {
    marginTop: -20,
  },
});

const Title = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  fontSize: 48,
  paddingLeft: 0,
  color: '#fff',
  fontWeight: 700,
  lineHeight: 1,
  '& span:first-of-type': {
    position: 'absolute',
    fontSize: 80,
    left: 0,
    fontWeight: 400,
    display: 'none',
  },
  '& span:last-child': {
    fontSize: 28,
    fontWeight: 300,
  },
  '@media (max-width: 630px)': {
    paddingLeft: 0,
  },
});

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  &:first-of-type {
    width: 330px;
    flex-shrink: 0;
    @media (max-width: 820px) {
      flex-direction: row;
      width: 100%;
    }
    @media (max-width: 630px) {
      flex-direction: column;
    }
  }
  &:last-child {
    width: 100%;
  }
`;

interface P {
  open?: boolean;
}

const ContentGroup = styled('div')<P>(({ theme, open }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  background: '#fff',
  height: open ? 82.5 : 'initial',
  borderRadius: theme.borderRadius.xlRem,
  boxShadow: '0px 2px 2px #00000033',
  overflow: 'hidden',
  border: '1px solid #e6e6e6',
}));

const ContentGroupListItem = styled('div')(() => ({
  lineHeight: 1,
  padding: '2.7rem',
  '& h5': {
    fontWeight: '700',
    color: '#000',
    marginBottom: 2,
    fontSize: 18,
  },
  '& p': {
    color: '#979696',
    fontWeight: 300,
    fontSize: 16,
  },
  '&:not(:last-child)': {
    borderBottom: '1px solid #E0E0E0',
  },
  '@media (max-width: 600px)': {
    padding: '2rem',
    '& h5': {
      fontSize: 14,
    },
    '& p': {
      fontSize: 12,
    },
  },
}));

export default {
  Container,
  BG,
  ContentGroup,
  GreetingContainer,
  ContentContainer,
  Title,
  ContentSection,
  ContentGroupListItem,
};
