import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneNumberInput from '../../../../PhoneNumberInput/PhoneNumberInput';

type Props = {
  handleChange: (e: ChangeEvent) => void;
  phoneNumber: string;
  error?: string | boolean;
};

const NotificationPhoneInput = ({
  handleChange,
  error,
  phoneNumber,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  return (
    <PhoneNumberInput
      label="Phone Number"
      name="phoneNumber"
      placeholder={t('sideMenu.new.myProfile.form.enterPhoneNumber')}
      handleChange={handleChange}
      value={phoneNumber}
      error={error}
    />
  );
};

export default NotificationPhoneInput;
