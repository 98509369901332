import React, { CSSProperties } from 'react';

import { Icon } from '../../../icon';
import { TDropdownOption } from '../../../../types';
import { Container, Option, OptionLabel, IconWrapper } from './Options.style';

interface OptionsListProps {
  options: TDropdownOption[];
  selectedOption?: TDropdownOption | null;
  setSelectedOption: (option: TDropdownOption) => void;
  optionStyles?: CSSProperties;
  maxHeight?: number;
  isOpen?: boolean;
  dataQa?: string;
}

export const Options: React.FC<OptionsListProps> = ({
  dataQa,
  options,
  selectedOption,
  setSelectedOption,
  optionStyles,
  maxHeight,
  isOpen,
}) => {
  return (
    <Container isOpen={isOpen} maxHeight={maxHeight} data-qa={dataQa}>
      {options.map((option) => {
        const { label, icon, value } = option;
        const dataQa = 'item-' + value.toLowerCase();
        return (
          <Option
            data-qa={dataQa}
            key={`${label}${value}`}
            hasIcon={!!icon}
            onClick={() => setSelectedOption(option)}
            style={optionStyles}
          >
            {icon && (
              <IconWrapper isSelected={selectedOption?.value === value}>
                <Icon type={icon} />
              </IconWrapper>
            )}
            <OptionLabel
              isSelected={selectedOption?.value === value}
              className="sort-option-label"
            >
              {label}
            </OptionLabel>
          </Option>
        );
      })}
    </Container>
  );
};
