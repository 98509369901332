import { useRef, useMemo, useCallback, useState, useEffect } from 'react';

import { map, sortBy } from 'lodash';

import { getStageCriteria } from './getStageCriteria';
import { getListingDate } from '../../../utils/getListingDate';
import { useSimilarHomesQuery } from '../hooks/useSimilarHomesQuery';
import { ListingDetails, TSimilarHomesCriteria } from '../../../types';
import { useHomeownerBedsSqFtAndCategory } from '../hooks/useHomeownerBedsAndSqFt';
import { useHomeownerPropertyDetails } from '../../../hooks/query/useHomeownerPropertyDetails';
import { useExcludeSimilarHomes } from './ExcludedSimilarHomesProvider';

type TInitSimilarHomes = {
  isLoading: boolean;
  data?: ListingDetails[];
  initCriteria?: TSimilarHomesCriteria;
};

const sortCriteria = (sh: ListingDetails) => getListingDate(sh);

export function useInitSimilarHomes(shNumberLimit: number): TInitSimilarHomes {
  const stage = useRef(1);

  const { addExcluded } = useExcludeSimilarHomes();

  const [processedSimilarHomes, setProcessedSimilarHomes] = useState<
    ListingDetails[]
  >([]);
  const [criteria, setCriteria] = useState<TSimilarHomesCriteria | undefined>(
    undefined,
  );

  const { data: homeownerProperty, isLoading: isLoadingHP } =
    useHomeownerPropertyDetails();

  const {
    beds: homeownerBeds,
    sqFt: homeownerSqft,
    category: homeownerPropertyCategory,
  } = useHomeownerBedsSqFtAndCategory();

  const { data, isLoading: isLoadingSH } = useSimilarHomesQuery(criteria);

  useEffect(
    function onHomeownerFetched() {
      if (!homeownerProperty?.propertyUuid) return;

      stage.current = 1;
      const initC = getStageCriteria(
        stage.current,
        homeownerBeds,
        homeownerSqft,
      );

      setCriteria(initC);
    },
    [
      homeownerBeds,
      homeownerSqft,
      homeownerProperty?.propertyUuid,
      homeownerPropertyCategory,
    ],
  );

  const processSimilarHomes = useCallback(
    (similarHomes: ListingDetails[], processedSH: ListingDetails[]) => {
      if (!similarHomes) return;

      if (processedSH?.length >= shNumberLimit) return;

      const processedSHUuids = map(processedSH, 'uuid');

      const similarHomesExtention = sortBy(
        similarHomes.filter(
          (sh: ListingDetails) => !processedSHUuids.includes(sh.uuid),
        ),
        sortCriteria,
      );

      if (stage.current > 1 && processedSH.length >= 3) {
        similarHomesExtention.forEach((sh, index) => {
          if (index < 3) {
            sh.isExcluded = true;
            addExcluded(sh.uuid);
          }
        });
      }

      setProcessedSimilarHomes(
        [...processedSH, ...similarHomesExtention].slice(0, shNumberLimit),
      );
    },
    [addExcluded, shNumberLimit],
  );

  useEffect(() => {
    if (stage.current === 1) {
      setProcessedSimilarHomes(data ?? []);
    } else {
      processSimilarHomes(data ?? [], processedSimilarHomes);
    }
  }, [data, shNumberLimit]); // eslint-disable-line

  useEffect(() => {
    if (isLoadingSH || !criteria || stage.current === 6) return;

    if (processedSimilarHomes?.length >= shNumberLimit) return;

    stage.current += 1;
    let nextCriteria = getStageCriteria(
      stage.current,
      homeownerBeds,
      homeownerSqft,
    );

    const serializedCriteria = JSON.stringify(criteria);
    const serializedNextCriteria = JSON.stringify(nextCriteria);

    if (serializedCriteria === serializedNextCriteria) {
      stage.current += 1;
      nextCriteria = getStageCriteria(
        stage.current,
        homeownerBeds,
        homeownerSqft,
      );
    }

    setCriteria(nextCriteria);
  }, [processedSimilarHomes]); // eslint-disable-line

  const isInLoadingStage =
    stage.current < 6 &&
    !!processedSimilarHomes &&
    (processedSimilarHomes?.length ?? 0) < shNumberLimit;
  const isLoading = isLoadingSH || isLoadingHP || isInLoadingStage;

  return useMemo(
    () => ({
      isLoading,
      data: isLoading ? undefined : processedSimilarHomes,
    }),
    [isLoading, processedSimilarHomes],
  );
}
