import { useQuery, UseQueryResult } from 'react-query';

import { hopApi } from '../../api/hop';
import { apiRoutes } from '../../constants';
import { AccountDetails } from '../../types';
import { useTokenData } from './useTokenData';
import { useHolpTokens } from './HolpTokensProvider';

interface Headers {
  accountId: number;
  authToken: () => Promise<string>;
  userPoolId: string;
}

async function fetchAccountDetails(
  headersConfig: Headers,
): Promise<AccountDetails> {
  const headers = {
    'account-id': headersConfig.accountId,
    Authorization: `Bearer ${await headersConfig.authToken()}`,
  };

  const response = await hopApi.get(apiRoutes.account.getDetails(), {
    headers,
  });

  return response.data;
}

export function useAccountDetails(): UseQueryResult<AccountDetails> {
  const { isLoading: isLoadingToken, getUnauthToken } = useHolpTokens();
  const { data: tokenData } = useTokenData();

  const headers = {
    accountId: tokenData?.accountId || 0,
    authToken: getUnauthToken,
    userPoolId: 'unauthenticated',
  };

  return useQuery(
    ['accountDetails', tokenData?.accountId],
    () => fetchAccountDetails(headers),
    {
      enabled: Boolean(tokenData?.accountId) && !isLoadingToken,
      staleTime: Infinity,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  );
}
