import { MouseEventHandler } from 'react';

import styled from '@emotion/styled';

import { Image } from '../../common.style';
import { TCarouselItem } from '../../../types';
import { CarouselDescription } from '../CarouselDescription';

type Props = {
  data: TCarouselItem;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export function ImageWithDescription({ data, onClick }: Props): JSX.Element {
  return (
    <Container>
      <Image src={data?.src} />
      <CarouselDescription
        onClick={onClick}
        total={data.total}
        description={data.description || ''}
      />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  borderradius: '0.275rem';
`;
