import styled from '@emotion/styled';

interface SeparatorProps {
  width?: string;
  marginTop?: string;
  marginBottom?: string;
}

export const Separator = styled('div')<SeparatorProps>(
  ({ theme, width, marginTop, marginBottom }) => ({
    width: width ?? '100%',
    marginTop: marginTop ?? '0',
    marginBottom: marginBottom ?? '0',
    borderTop: `0.1rem solid ${theme.colors.grey.main}`,
  }),
);
