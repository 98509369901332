import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Styled from './error-page.style';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { routes } from '../../constants';

const ErrorPage = (props) => {
  const { t } = useTranslation();
  let history = useHistory();

  const { title, subtitle, message, resetErrorBoundary, cta } = props;

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (resetErrorBoundary) {
        resetErrorBoundary();
      }
    });
    return () => {
      unlisten();
    };
  }, [history, resetErrorBoundary]);

  const onClick = () => {
    history.push(routes.myHome());
  };

  return (
    <Styled.BackgroundContainer>
      <Styled.Container>
        <Styled.Content>
          <Styled.Title>{title}</Styled.Title>
          <Styled.Subtitle>{subtitle}</Styled.Subtitle>
          <Styled.Message>{message}</Styled.Message>

          {!cta ? null : (
            <Styled.Button
              shape="square"
              fullWidth
              variant="outlined"
              size="small"
              block={false}
              onClick={onClick}
            >
              {t('errorPage.goToHomepage')}
            </Styled.Button>
          )}
        </Styled.Content>
      </Styled.Container>
    </Styled.BackgroundContainer>
  );
};

ErrorPage.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  cta: PropTypes.bool,
};

ErrorPage.defaultProps = {
  title: null,
  subtitle: null,
  message: null,
  cta: true,
};

export default ErrorPage;
