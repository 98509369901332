import { FC } from 'react';
import styled from '@emotion/styled';
import { Tooltip } from 'react-tooltip';
import { Layer } from '../../../types';

export const TooltipRoot: FC = () => {
  return <TooltipStyled disableStyleInjection id="root-tooltip" />;
};

const TooltipStyled = styled(Tooltip)`
  width: 200px;
  padding: 1rem;
  white-space: pre-wrap;
  background-color: white;
  border: 1px solid;
  text-align: center;
  font-size: 1rem;
  line-height: normal;
  color: ${({ theme }) => theme.colors?.black};
  border-color: ${({ theme }) => theme.colors?.branding?.main};
  z-index: ${Layer.MustSee - 1};
  border-radius: 0.4rem;
`;
