import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';

import { useKvCoreCookieToken } from './useKvCoreCookieToken';
import { useSearchParams } from '../SearchParamsProvider';
import { UnknownUserPage } from '../../pages/UnknownUserPage/UnknownUserPage';

interface KeyContextStruct {
  key: string;
  reset: () => void;
  isNew: boolean;
}

const KeyContext = createContext<KeyContextStruct | undefined>(undefined);
KeyContext.displayName = 'KeyContext';

export function useKey(): KeyContextStruct {
  const context = useContext(KeyContext);

  if (!context) {
    throw new Error('useKey cannot be used outside KeyProvider');
  }

  return context;
}

export const KeyProvider: FC = ({ children }) => {
  const { getToken, setToken, removeToken } = useKvCoreCookieToken();

  const { get } = useSearchParams();
  // "+" is replaced with " " in the url and we need to replace it back
  const keyParam = get('key')?.replace(/ /g, '+');

  const [key, setKey] = useState(keyParam || getToken());
  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    if (!keyParam) return;

    const oldKey = getToken();
    if (keyParam !== oldKey) {
      setIsNew(true);
    }

    setKey(keyParam);
    setToken(keyParam);
  }, [getToken, keyParam, setToken]);

  const reset = useCallback(
    function resetToken() {
      removeToken();
      setKey('');
    },
    [removeToken],
  );

  const value = useMemo(
    () => ({
      key,
      reset,
      isNew,
    }),
    [isNew, key, reset],
  );

  return (
    <KeyContext.Provider value={value}>
      {!key ? <UnknownUserPage /> : children}
    </KeyContext.Provider>
  );
};
