import { Ref, useEffect, useRef } from 'react';
import { ListingDetails } from '../../../types';
import useIsInViewport from '../../../hooks/useIsInViewport';
import useViewedListingSession from '../../../hooks/useViewedListingSession';

type ReturnType = {
  ref: Ref<HTMLDivElement>;
  hasSession: boolean;
  setSessionStarted: () => void;
  resetViewedSession: () => void;
};

export function useTrackViewedSession(listing: ListingDetails): ReturnType {
  const { viewedSessionActivated, setViewedSessionActive, resetViewedSession } =
    useViewedListingSession({ listingDetail: listing });

  const tileRef = useRef<HTMLDivElement>(null);
  const isTileInViewport = useIsInViewport(tileRef);

  useEffect(() => {
    if (!isTileInViewport && viewedSessionActivated) {
      resetViewedSession();
    }
  }, [isTileInViewport, resetViewedSession, viewedSessionActivated]);

  return {
    ref: tileRef,
    setSessionStarted: setViewedSessionActive,
    hasSession: viewedSessionActivated,
    resetViewedSession,
  };
}
