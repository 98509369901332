import { useCallback, useState } from 'react';

import styled from '@emotion/styled';

import { ListingMap } from './ListingMap';
import { TTileModeSwitchOptions } from '../../types';
import { useOpenListingDetailPage } from '../../hooks';
import { ComparisonConfirm } from './ComparisonConfirm';
import { useListingSession } from '../../providers/ListingSessionProvider';
import { Label, TileActionsBar } from '../../components/common.style';
import { useListingDateLabel } from '../../hooks/useListingDateLabel';
import { TwoOptionsSwitch } from '../../../../components/V2/TwoOptionsSwitch/TwoOptionsSwitch';
import { ListingImageCarousel } from '../../components/ListingImageCarousel/ListingImageCarousel';

export function SimilarListingPreview({
  isExcluded,
}: {
  isExcluded?: boolean;
}): JSX.Element {
  const { listing } = useListingSession();

  const isSwitchDisabled = Boolean(!listing?.Media?.length);

  const [imageOrMap, setImageOrMap] = useState<TTileModeSwitchOptions>(
    isSwitchDisabled ? 'map' : 'image',
  );

  const listingLabel = useListingDateLabel(listing);
  const onClick = useOpenListingDetailPage(listing);

  const onSetSwitchStatus = useCallback((status: string) => {
    setImageOrMap(status as TTileModeSwitchOptions);
  }, []);

  return (
    <>
      <OpacityContainer hasOpacity={isExcluded}>
        {imageOrMap === 'map' ? (
          <ListingMap listingDetail={listing} />
        ) : (
          <ListingImageCarousel
            withFooter
            onClick={onClick}
            withListingRemarksSlide={false}
          />
        )}
        <TileActionsBar>
          <Label>{listingLabel}</Label>
          <TwoOptionsSwitch
            width={51}
            height={18}
            labelInside
            preventClickPropagation
            preventTouchPropagation
            switchStatus={imageOrMap}
            isDisabled={isSwitchDisabled}
            changeOpacity={isSwitchDisabled}
            setSwitchStatus={onSetSwitchStatus}
            firstOption={{ label: 'Map', value: 'image' }}
            secondOption={{ label: 'Image', value: 'map' }}
          />
        </TileActionsBar>
      </OpacityContainer>
      <ComparisonConfirm listingDetail={listing} />
    </>
  );
}

const OpacityContainer = styled('div')<{ hasOpacity?: boolean }>(
  ({ hasOpacity }) => ({
    opacity: hasOpacity ? '0.3' : '1',
    position: 'relative',
  }),
);
