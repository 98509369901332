import { useCookies } from 'react-cookie';
import { useCallback, useEffect, useState } from 'react';

import cookieNames from './cookie-names';
import { STATIC_CONFIG } from '../../constants/config';

export function useKvCoreCookieToken() {
  const cookieName = cookieNames.kvcoreToken;
  const [hasToken, setHasToken] = useState(undefined);
  const [tokenInterval, setTokenInterval] = useState(undefined);
  const [cookies, setCookie, removeCookie] = useCookies([cookieName]);

  const removeToken = useCallback(() => {
    removeCookie(cookieName);
    setHasToken(false);
  }, [cookieName, removeCookie]);

  const setToken = useCallback(
    (token) => {
      setCookie(cookieName, token, {
        maxAge: STATIC_CONFIG.VITE_COOKIE_MAX_AGE_SEC,
        path: '/',
      });
    },
    [cookieName, setCookie],
  );

  const getToken = useCallback(() => {
    return cookies[cookieName];
  }, [cookieName, cookies]);

  const updateTokenRefresh = useCallback(() => {
    if (hasToken && !tokenInterval) {
      setTokenInterval(
        setInterval(() => {
          setToken(getToken());
        }, STATIC_CONFIG.VITE_COOKIE_REFRESH_SEC),
      );
    } else if (!hasToken && tokenInterval) {
      clearInterval(tokenInterval);
    }

    return tokenInterval;
  }, [getToken, hasToken, setToken, tokenInterval]);

  useEffect(() => {
    setHasToken(!!cookies[cookieName]);
  }, [cookieName, cookies]);

  useEffect(() => {
    const tokenInterval = updateTokenRefresh();
    return () => clearInterval(tokenInterval);
  }, [updateTokenRefresh]);

  return {
    hasToken,
    setToken,
    getToken,
    removeToken,
    updateTokenRefresh,
  };
}
