import { useQuery, UseQueryResult } from 'react-query';

import api from '../../api';
import { apiRoutes } from '../../constants';
import { SearchArea, TPolygonDetails } from '../../types';

const fetchPolygonDetails = async (
  area?: SearchArea | null,
): Promise<TPolygonDetails | null> => {
  if (!area?.areaId) return null;
  const response = await api.get(apiRoutes.polygonDetails.byArea(), {
    params: {
      ...area,
    },
  });

  return response.data;
};

export function usePolygonDetailsByArea(
  area?: SearchArea | null,
): UseQueryResult<TPolygonDetails | null> {
  const areaString = '' + area?.areaId + area?.areaType + area?.countrySubd;

  return useQuery(
    ['polygonDetails', areaString],
    () => fetchPolygonDetails(area),
    {
      staleTime: Infinity,
      enabled: Boolean(area?.areaId),
      refetchOnWindowFocus: false,
      retry: 1,
    },
  );
}
