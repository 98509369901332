import React, { Ref, useContext, useEffect, useMemo } from 'react';

import { ListingDetails } from '../../../types';
import { useOpenListingDetailPage } from '../hooks';
import { useTrackViewedSession } from '../hooks/useTrackViewedSession';
import { useNewListingInteraction } from '../../../pages/SearchResults/components/ListingInteractionTracker/ListingInteractionTracker';

type ListingSessionType = {
  ref: Ref<HTMLDivElement>;
  listing: ListingDetails;
  hasSession: boolean;
  onOpenListingPage: (
    e: React.MouseEvent<HTMLElement>,
    forceSkip?: boolean,
  ) => void;
  setSessionStarted: () => void;
  resetViewedSession: () => void;
  newListingInteraction: (listing: ListingDetails) => void;
};

const ListingSessionContext = React.createContext<
  ListingSessionType | undefined
>(undefined);

export const useListingSession = (): ListingSessionType => {
  const context = useContext(ListingSessionContext);

  if (!context) {
    throw new Error(
      'useListingSession cannot be used without ListingTileContext',
    );
  }

  return context;
};

type ProviderProps = {
  children: React.ReactNode;
  listing: ListingDetails;
};

export function ListingSessionProvider({ listing, children }: ProviderProps) {
  const { ref, hasSession, setSessionStarted, resetViewedSession } =
    useTrackViewedSession(listing);
  const onOpenListingPage = useOpenListingDetailPage(listing, hasSession);
  const newListingInteraction = useNewListingInteraction();

  useEffect(() => {
    if (hasSession) newListingInteraction(listing);
  }, [hasSession, listing, newListingInteraction]);

  const value = useMemo(
    () => ({
      ref,
      setSessionStarted,
      listing,
      onOpenListingPage,
      hasSession,
      resetViewedSession,
      newListingInteraction,
    }),
    [
      ref,
      setSessionStarted,
      listing,
      onOpenListingPage,
      hasSession,
      resetViewedSession,
      newListingInteraction,
    ],
  );

  return (
    <ListingSessionContext.Provider value={value}>
      {children}
    </ListingSessionContext.Provider>
  );
}
