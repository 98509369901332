import { Property } from 'csstype';
import styled from '@emotion/styled';

type WithActive = {
  isActive?: boolean;
};

type WithLabelInside = {
  labelInside?: boolean;
};

type WithIsDisabled = {
  isDisabled?: boolean;
};

export const Label = styled('div')<
  WithActive & WithLabelInside & WithIsDisabled
>(({ theme, isActive, labelInside, isDisabled }) => {
  let visibility = 'visible' as Property.Visibility;
  let color = isActive ? theme.colorPrimary : theme.colors.grey.dark2;

  if (labelInside) {
    color = theme.colors.white;
    visibility = isActive ? 'hidden' : 'visible';
  }

  return {
    color,
    visibility,
    margin: '0 1rem',
    textTransform: 'uppercase',
    fontWeight: theme.fontWeight.bold,
    top: labelInside ? '0.4rem' : '0',
    position: labelInside ? 'absolute' : 'static',
    fontSize: labelInside ? '0.7rem' : theme.fontSize.smRem,
    '&:first-of-type': {
      right: labelInside ? '0.8rem' : '0',
    },
    '&:last-of-type': {
      left: labelInside ? '0.7rem' : '0',
    },
    '&:hover': {
      pointerEvents: isDisabled ? 'none' : 'all',
      // cursor: isDisabled ? 'not-allowed' : 'pointer',
    },
  };
});
