import { AgentDetails } from '../types';

export const getAgentFullName = (agentDetails?: AgentDetails): string => {
  if (!agentDetails) return '';

  const firstName = agentDetails?.Name?.firstName;
  const lastName = agentDetails?.Name?.lastName;

  return `${firstName} ${lastName}`;
};
