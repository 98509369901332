import styled from '@emotion/styled';

const Container = styled('div')(({ theme }) => ({
  marginTop: theme.spacings.xlRem,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const To = styled('span')(({ theme }) => ({
  fontSize: theme.fontSize.mdRem,
  color: theme.colors.grey.dark2,
}));

export default { Container, To };
