import React from 'react';
import styled from '@emotion/styled';
import { Icon, IconType } from '../../icon';

interface Props {
  color?: string;
  size?: number;
}

const Container = styled.div<Props>`
  font-size: ${({ size = 1 }) => `${12 * size}px`};
  color: ${({ theme, color = 'primary' }) =>
    color === 'primary' ? theme.colors.branding.main : color};
  animation: spin 2s infinite linear;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Spinner: React.FC<Props> = ({ color = 'primary', size = 1 }) => {
  return (
    <Container color={color} size={size}>
      <Icon type={IconType.Spinner} />
    </Container>
  );
};
