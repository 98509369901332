export default `
<svg id="Group_9471" data-name="Group 9471" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="46.389" height="46.372" viewBox="0 0 46.389 46.372">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_3431" data-name="Rectangle 3431" width="46.389" height="46.372" fill="#1F4D8C"/>
    </clipPath>
  </defs>
  <g id="Group_9166" data-name="Group 9166" clip-path="url(#clip-path)">
    <path id="Path_3314" data-name="Path 3314" d="M23.946,46.355a23.187,23.187,0,1,1,22.33-25.478,23.153,23.153,0,0,1-22.33,25.478m13.4-8.6a20.3,20.3,0,1,0-28.313-.022c.061-.162.107-.311.172-.45A22.029,22.029,0,0,1,10.645,34.3a10.887,10.887,0,0,1,8.576-5.171c2.329-.154,4.673-.092,7.01-.1a8.54,8.54,0,0,1,1.625.137,11.578,11.578,0,0,1,9.25,7.92c.075.2.147.408.242.671m-13.7-5.981a36.478,36.478,0,0,0-5.087.33c-3.818.814-6.037,3.3-6.837,7.1a.9.9,0,0,0,.479,1.083,19.636,19.636,0,0,0,9.514,3.171,19.854,19.854,0,0,0,12.619-3.276.724.724,0,0,0,.355-.8,8.482,8.482,0,0,0-6.224-7.114,35.456,35.456,0,0,0-4.818-.495" transform="translate(0 0.001)" fill="#1F4D8C"/>
    <path id="Path_3315" data-name="Path 3315" d="M80.465,55.233a7.114,7.114,0,0,1-7.236,7.193,7.219,7.219,0,1,1,7.236-7.193M77.594,55.2A4.37,4.37,0,1,0,73.2,59.573,4.408,4.408,0,0,0,77.594,55.2" transform="translate(-50.032 -36.378)" fill="#1F4D8C"/>
  </g>
</svg>`;
