import * as yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  ErrorMsg,
  IconWrap,
  InputBox,
  SectionWrap,
  InputBoxText,
  InputBoxLabel,
  SectionContainer,
} from '../../../SideMenu.style';
import ActionButtons from '../ActionButtons';
import { FlexRow } from '../../../../Flex/Flex';
import InputField from '../LoginInfo/InputField';
import { Icon, IconType } from '../../../../../icon';
import { toastActions } from '../../../../ToastComponent/ToastComponent';
import PhoneNumberInput from '../../../../PhoneNumberInput/PhoneNumberInput';
import { useHomeownerByContact } from '../../../../../../hooks/useHomeownerByContact';
import { useUpdateHomeownerDetails } from '../../../../../../hooks/useUpdateHomeownerDetails';

import { phoneValidationSchema } from '../../../../../../utils/formValidations';

type Props = {
  label: string;
  childPanelOpen: string;
  setChildPanelOpenInfo: (childName: string) => void;
};

interface FormValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

function PersonalInfo({
  label,
  childPanelOpen,
  setChildPanelOpenInfo,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const {
    isError,
    isSuccess,
    isLoading,
    mutateAsync: onUpdate,
    error: editError,
  } = useUpdateHomeownerDetails();

  const [editFormActive, setEditFormActive] = useState({
    editFullName: false,
    editPhoneNumber: false,
  });
  const { data: homeownerDetail } = useHomeownerByContact();
  const [errorMessage, setErrorMessage] = useState('');

  const communication = homeownerDetail?.Communication;
  const firstName = homeownerDetail?.Name?.firstName || '';
  const lastName = homeownerDetail?.Name?.lastName || '';
  const fullName = `${firstName} ${lastName}`;

  const phoneNumber = communication?.phoneMobile || '';

  const initialValues = useMemo(() => {
    return {
      firstName,
      lastName,
      phoneNumber: phoneNumber?.toString(),
    };
  }, [firstName, lastName, phoneNumber]);

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      firstName: yup
        .string()
        .min(2, 'First name must be at least 2 characters')
        .required(t('common.invalid')),
      lastName: yup.string().min(2, 'Last name must be at least 2 characters'),
      phoneNumber: phoneValidationSchema(),
    });
  }, [t]);

  const disableEditForm = useCallback(() => {
    setEditFormActive({
      editFullName: false,
      editPhoneNumber: false,
    });
  }, []);

  const onSubmit = async ({ firstName, lastName, phoneNumber }: FormValues) => {
    setErrorMessage('');

    await onUpdate({
      payload: {
        Name: {
          firstName,
          lastName,
        },
        Communication: {
          phoneMobile: phoneNumber.trim() || '',
        },
      },
    });
  };

  useEffect(() => {
    if (label !== childPanelOpen) {
      setEditFormActive({ editFullName: false, editPhoneNumber: false });
    }
  }, [childPanelOpen, label]);

  useEffect(
    function onEditFinished() {
      if (isLoading) return;

      if (isSuccess) {
        setEditFormActive({ editFullName: false, editPhoneNumber: false });
        setChildPanelOpenInfo('');
        return;
      }

      if (isError) {
        toastActions.error(editError?.message);
      }
    },
    [editError, isError, isLoading, isSuccess, setChildPanelOpenInfo, t],
  );

  return (
    <SectionContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {(props) => {
          const {
            values,
            handleSubmit,
            handleChange,
            handleBlur,
            errors,
            resetForm,
          } = props;

          return (
            <form onSubmit={handleSubmit}>
              {editFormActive.editFullName ? (
                <>
                  <InputField
                    placeholder={t(
                      'sideMenu.new.myProfile.form.enterFirstName',
                    )}
                    type={'text'}
                    handleChange={handleChange}
                    name="firstName"
                    handleBlur={handleBlur}
                    value={values.firstName}
                    error={errors.firstName}
                  />
                  <InputField
                    hasMt
                    placeholder={t('sideMenu.new.myProfile.form.enterLastName')}
                    type={'text'}
                    handleChange={handleChange}
                    name="lastName"
                    handleBlur={handleBlur}
                    value={values.lastName}
                    error={errors.lastName}
                  />
                </>
              ) : (
                <InputBox>
                  <SectionWrap>
                    <FlexRow style={{ width: '95%' }}>
                      <InputBoxLabel data-qa="full-name-label">
                        {t('userPersonalInformation.fullName')}:
                      </InputBoxLabel>
                      <InputBoxText data-qa="full-name-value">
                        {fullName}
                      </InputBoxText>
                    </FlexRow>
                    <IconWrap
                      data-qa="full-name-edit-icon"
                      onClick={() => {
                        setEditFormActive((prev) => ({
                          ...prev,
                          editFullName: !prev.editFullName,
                        }));
                      }}
                    >
                      <Icon type={IconType.PencilAlt} />
                    </IconWrap>
                  </SectionWrap>
                </InputBox>
              )}
              {editFormActive.editPhoneNumber ? (
                <PhoneNumberInput
                  hasMt
                  name="phoneNumber"
                  placeholder={t(
                    'sideMenu.new.myProfile.form.enterPhoneNumber',
                  )}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.phoneNumber}
                  error={errors.phoneNumber}
                />
              ) : (
                <InputBox hasMt>
                  <SectionWrap>
                    <FlexRow>
                      <InputBoxLabel data-qa="phone-number-label">
                        {t('editProfile.phoneNumber')}:
                      </InputBoxLabel>
                      <InputBoxText data-qa="phone-number-value">
                        {phoneNumber}
                      </InputBoxText>
                    </FlexRow>
                    <IconWrap
                      data-qa="phone-number-edit-icon"
                      onClick={() => {
                        setEditFormActive((prev) => ({
                          ...prev,
                          editPhoneNumber: !prev.editPhoneNumber,
                        }));
                      }}
                    >
                      <Icon type={IconType.PencilAlt} />
                    </IconWrap>
                  </SectionWrap>
                </InputBox>
              )}
              {errorMessage && <ErrorMsg hasMt>{errorMessage}</ErrorMsg>}

              <ActionButtons
                handleCancel={() => {
                  resetForm();
                  disableEditForm();
                }}
                sectionVisible={
                  editFormActive.editPhoneNumber || editFormActive.editFullName
                }
                submitDisabled={isLoading}
              />
            </form>
          );
        }}
      </Formik>
    </SectionContainer>
  );
}

export default PersonalInfo;
