import { useTheme } from '@emotion/react';

import {
  INavGroupPaths,
  NavGroupSearchPath,
  NavGroupJourneyPath,
  NavGroupFavoritesPath,
  NavGroupeNotificationsPath,
} from '../../../types';
import { useNavActive } from '../../../utils/useNavActive';

type Props = {
  width?: string;
  height?: string;
  navGroupPaths?:
    | INavGroupPaths
    | NavGroupSearchPath
    | NavGroupJourneyPath
    | NavGroupFavoritesPath
    | NavGroupeNotificationsPath;
};

export function SearchMobileNavIcon({
  width,
  height,
  navGroupPaths,
}: Props): JSX.Element {
  const theme = useTheme();
  const { isActiveNavGroup } = useNavActive(navGroupPaths);

  const svgWidth = width || '28';
  const svgHeight = height || '28';

  const backgroundColor = isActiveNavGroup
    ? theme.colors.branding.main
    : theme.colors.white;
  const outilneColor = isActiveNavGroup
    ? theme.colors.branding.main
    : theme.colors.grey.dark2;

  const iconPropList: Record<string, string> = {};
  iconPropList['aria-label'] = 'Search';

  return (
    <svg
      fill="none"
      width={svgWidth}
      height={svgHeight}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${svgWidth} ${svgHeight}`}
    >
      <path
        fill="white"
        d="M21.8915 10.1375C21.8915 12.8115 20.7495 15.214 18.935 16.8672C17.3658 18.2969 15.2936 19.1663 13.0219 19.1663C8.12338 19.1663 4.15234 15.1239 4.15234 10.1375C4.15234 5.15098 8.12338 1.10864 13.0219 1.10864C17.9204 1.10864 21.8915 5.15098 21.8915 10.1375Z"
      />
      <path
        fillOpacity="0.1"
        fill={backgroundColor}
        d="M21.8915 10.1375C21.8915 12.8115 20.7495 15.214 18.935 16.8672C17.3658 18.2969 15.2936 19.1663 13.0219 19.1663C8.12338 19.1663 4.15234 15.1239 4.15234 10.1375C4.15234 5.15098 8.12338 1.10864 13.0219 1.10864C17.9204 1.10864 21.8915 5.15098 21.8915 10.1375Z"
      />
      <path
        strokeWidth="1.9125"
        strokeLinecap="round"
        stroke={outilneColor}
        strokeLinejoin="round"
        d="M18.935 16.8672C20.7495 15.214 21.8915 12.8115 21.8915 10.1375C21.8915 5.15098 17.9204 1.10864 13.0219 1.10864C8.12338 1.10864 4.15234 5.15098 4.15234 10.1375C4.15234 15.1239 8.12338 19.1663 13.0219 19.1663C15.2936 19.1663 17.3658 18.2969 18.935 16.8672ZM18.935 16.8672L26.3263 24.3912"
      />
    </svg>
  );
}
