import { Office } from '../types';

export const extractOfficeWithValidAddress = (
  offices: Office[],
): Office | null => {
  const office = offices.find((office) =>
    Boolean(office.Address.locality || office.Address.countrySubd),
  );

  if (!office?.Address) {
    return null;
  }

  return office;
};
