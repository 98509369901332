import { ChangeEvent } from 'react';
import { PatternFormat } from 'react-number-format';
import styled from '@emotion/styled';
import { ErrorMsg, InputBox } from '../SideMenu/SideMenu.style';
import { Input as newInput } from '../../UI';
type Props = {
  placeholder: string;
  handleChange: (e: ChangeEvent) => void;
  handleBlur?: (e: ChangeEvent) => void;
  value: string;
  error?: string | boolean;
  name: string;
  hasMt?: boolean;
  label?: string;
};

const PhoneNumberInput = ({
  placeholder,
  handleChange,
  handleBlur,
  value,
  hasMt,
  error,
  name,
  label,
}: Props): JSX.Element => {
  if (label) {
    return (
      <>
        <PatternFormat
          customInput={newInput}
          inputStyles={{
            backgroundColor: '#fff',
            fontWeight: '700',
            padding: '1.2rem',
            borderRadius: '0.4rem',
          }}
          label={label}
          name={name}
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          format="### ### ####"
          allowEmptyFormatting
          style={{ marginTop: '1rem' }}
        />
        {error && <ErrorMsg hasMt>{error}</ErrorMsg>}
      </>
    );
  }
  return (
    <>
      <InputBox hasMt={hasMt}>
        <PatternFormat
          customInput={Input}
          name={name}
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          format="### ### ####"
          allowEmptyFormatting
        />
      </InputBox>
      {error && <ErrorMsg hasMt>{error}</ErrorMsg>}
    </>
  );
};

const Input = styled.input`
  border: none;
  outline: none;
  margin-left: 0.5rem;
  flex-grow: 1;
  min-width: 0;
`;

export default PhoneNumberInput;
