import styled from '@emotion/styled';

const Range = styled('div')({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
});

const MetricWrapper = styled('div')(({ theme }) => ({
  '& > div': {
    fontSize: theme.fontSize.xlRem,
    lineHeight: 'normal',
    '@media all and (max-width: 398px)': {
      fontSize: theme.fontSize.mdRem,
    },
  },
}));

const Dash = styled('div')(({ theme }) => ({
  color: theme.colorPrimary,
  margin: '0 1.2rem',
  fontSize: theme.fontSize.xlRem,
  lineHeight: 'normal',
}));

const IconWrapper = styled('div')(({ theme }) => ({
  marginLeft: '1rem',
  color: theme.colorPrimary,
  cursor: 'pointer',
  '& > span': {
    fontSize: '1.6rem',
  },
}));

const InputsContainer = styled('div')({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  '@media all and (max-width: 467px)': {
    flexDirection: 'column',
  },
});

const InputsWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '@media all and (max-width: 467px)': {
    marginBottom: '1rem',
  },
});

const Input = styled.input`
  width: 12rem;
  height: 3rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius.lgRem};
  box-shadow: 2px 2px 4px #03050426;
  border: none;
  color: ${({ theme }) => theme.colorPrimary};
  font-size: ${({ theme }) => theme.fontSize.smRem};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  padding: 0 1rem;
  margin: 0 1.5rem;
  ::placeholder {
    color: ${({ theme }) => theme.colorPrimary};
    font-size: ${({ theme }) => theme.fontSize.smRem};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
  &:focus {
    outline: 'none';
  }
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  @media all and (max-width: 348px) {
    margin: 0 0.5rem;
  }
`;

const To = styled('div')(({ theme }) => ({
  fontSize: theme.fontSize.smRem,
  color: theme.colors.grey.dark2,
}));

const ErrorMessage = styled('div')({
  color: 'red',
  width: '100%',
  fontSize: '1rem',
  marginTop: '1rem',
  textAlign: 'center',
});

export default {
  Range,
  MetricWrapper,
  Dash,
  IconWrapper,
  InputsContainer,
  Input,
  InputsWrapper,
  To,
  ErrorMessage,
};
