import { useRef } from 'react';

import { AddressWithCoordinates } from '../types';
import { useHomeownerByContact } from './useHomeownerByContact';
import { useUpdateHomeownerDetails } from './useUpdateHomeownerDetails';
import { useInvalidateHomeownerPropertyDetails } from './query/useHomeownerPropertyDetails';
import { useInvalidateInsuranceByHomeowner } from './query/insurance/useInsuranceByHomeowner';
import { useInvalidateMortgageDetails } from './query/mortgageDetails/useMortgageDetails';
import { useInvalidateMarketDetails } from './useMarket';
import { getPropertyUuidPayload } from '../utils/getPropertyUuidPayload';

import { ErrorMessageExtractor } from '../services/ErrorMessageExtractor';
import { getAddressAndMetaPayload } from '../utils/getAddressAndMetaPayload';

type Params = {
  buyHome: boolean;
  ownHome: boolean;
  onSuccess?: () => void;
  onError?: (error: string) => void;
  address: AddressWithCoordinates | null;
  onSettled?: () => void;
  resetMaintenance?: boolean;
};

type AddressUpdate = {
  isLoading: boolean;
  updateAddress: (props: Params) => Promise<void>;
};

export const useAddressUpdate = (): AddressUpdate => {
  const { data: homeownerDetails } = useHomeownerByContact();
  const { isLoading: isLoadingHomeowner, mutateAsync: onUpdate } =
    useUpdateHomeownerDetails();

  const invalidateHomeownerPropertyDetails =
    useInvalidateHomeownerPropertyDetails();

  const invalidateInsurance = useInvalidateInsuranceByHomeowner();
  const invalidateMortgage = useInvalidateMortgageDetails();
  const invalidateMarket = useInvalidateMarketDetails();

  const isLoadingProperty = useRef(false);

  const updateAddress = async ({
    ownHome,
    buyHome,
    onError,
    onSuccess,
    address,
    onSettled,
    resetMaintenance,
  }: Params) => {
    if (!address?.fullAddress && ownHome) {
      onError && onError('No Address');
      return;
    }

    try {
      isLoadingProperty.current = true;
      const addressAndMetaPayload = getAddressAndMetaPayload({
        ownHome,
        buyHome,
        address,
        oldAddress: homeownerDetails?.Address.fullAddress,
      });

      const propertyUuidPayload = await getPropertyUuidPayload(
        addressAndMetaPayload,
        address?.coordinates,
      );

      const homeownerId = homeownerDetails?.homeownerId || undefined;
      const homeownerUuid = homeownerDetails?.homeownerUuid || '';

      await onUpdate({
        payload: {
          ...addressAndMetaPayload,
          ...propertyUuidPayload,
          homeownerId,
          homeownerUuid,
          resetMaintenance,
        },
      });

      invalidateHomeownerPropertyDetails();
      invalidateInsurance();
      invalidateMortgage();
      invalidateMarket();

      onSuccess?.();
    } catch (err) {
      onError?.(ErrorMessageExtractor.extract(err));
    } finally {
      isLoadingProperty.current = false;
      onSettled?.();
    }
  };

  return {
    updateAddress,
    isLoading: isLoadingHomeowner || isLoadingProperty.current,
  };
};
