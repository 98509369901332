import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogTitle as MuiDialogTitle,
  IconButton as MuiIconButton,
  styled,
} from '@mui/material';

import CloseIconSvg from './CloseIcon.svg';
import { Layer } from '../../../types';

type CloseReason = 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick';

export interface IDialogProps extends MuiDialogProps {
  onClose?: (reason: CloseReason) => void;
}

interface Props {
  title?: string;
  children?: React.ReactNode;
  hideX?: boolean;
}

export default function Dialog({
  title,
  children,
  hideX,
  ...props
}: IDialogProps & Props) {
  const { onClose } = props;
  return (
    <MuiDialog {...props} disableEnforceFocus disableRestoreFocus>
      <DialogContent>
        {!hideX && onClose ? (
          <IconButton
            aria-label="close"
            onClick={() => onClose('closeButtonClick')}
          >
            <CloseIconSvg />
          </IconButton>
        ) : null}
        {title && <DialogTitle>{title}</DialogTitle>}
        {children}
      </DialogContent>
    </MuiDialog>
  );
}

const DialogContent = styled('div')(({ theme }) => ({
  padding: '24px 20px 20px 20px',

  position: 'relative',
  [theme.breakpoints.up(768)]: {
    padding: '48px 30px',
  },
}));

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  position: 'absolute',
  right: 16,
  top: 16,
  zIndex: Layer.Default + 5,
  [theme.breakpoints.up(768)]: {
    top: 20,
    right: 20,
  },
}));

export const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 700,
  lineHeight: 1.1,
  textAlign: 'center',
  [theme.breakpoints.up(768)]: {
    fontSize: 24,
  },
}));
