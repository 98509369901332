import { useTheme } from '@emotion/react';
import React, { useCallback, useEffect, useState } from 'react';

import {
  SaleParam,
  TDropdownOption,
  TSaleListingStatusValue,
  TSaleType,
  TSaleTypeOption,
} from '../../../../../types';
import { UUID } from './filter';
import { saleTypesMap } from '../../../saleTypesMap';
import * as Dropdown from '../../../../../components/V2/Dropdown';
import { useListings } from '../../../providers/ListingsProvider';
import { useIsWithComingSoon } from '../../../hooks/useIsWithComingSoon';
import { Button, ButtonsContainer, Container } from './SaleTypeFilter.style';
import { useIsSoldDataHidden } from '../../../../../hooks/useIsSoldDataHidden';
import { useSearchParams } from '../../../../../providers/SearchParamsProvider';
import { useSearchListingSnapshot } from '../../../hooks/useSearchListingSnapshot';

const filtersToExclude = ['comingSoon', 'comingSoonAndActive'];

const getSaleTypeConfig = (
  saleType: TSaleType | null,
  isWithCommingSoon: boolean,
) => {
  const saleTypeConfig = saleTypesMap[saleType ?? 'sale'] ?? saleTypesMap.sale;

  if (isWithCommingSoon) return saleTypeConfig;
  const options = saleTypeConfig?.options?.filter(
    (option) => !filtersToExclude.includes(option.value),
  );
  return {
    ...saleTypeConfig,
    options,
  };
};

export const defaultSaleFilter = {
  type: 'sale',
  option: 'comingSoonAndActive',
} as const;

export const defaultWithoutComingSoonSaleFilter = {
  type: 'sale',
  option: 'active',
} as const;

export const SaleTypeFilter: React.FC = () => {
  const theme = useTheme();
  const { setActive } = useListings();
  const isSoldDataHidden = useIsSoldDataHidden();
  const { data: listings } = useSearchListingSnapshot();
  const isWithComingSoon = useIsWithComingSoon(listings ?? null);

  const [isOpen, setIsOpen] = useState(false);

  const defaultFilter = isWithComingSoon
    ? defaultSaleFilter
    : defaultWithoutComingSoonSaleFilter;

  const { set, get } = useSearchParams();
  const parameterSale = (get(UUID) as SaleParam) || defaultFilter;

  const getSaleTypeOption = useCallback(
    (
      saleTypeConfig: TSaleTypeOption,
      saleTypeOption?: TSaleListingStatusValue | string,
    ) => {
      return (
        saleTypeConfig.options?.find((o) =>
          saleTypeOption ? o.value === saleTypeOption : o.isDefault,
        ) ?? null
      );
    },
    [],
  );

  const [selectedType, setSelectedType] = useState(
    getSaleTypeConfig(parameterSale?.type, isWithComingSoon),
  );

  const [selectedOption, setSelectedOption] = useState<TDropdownOption | null>(
    getSaleTypeOption(selectedType, parameterSale.option),
  );

  const onChangeType = (type: TSaleTypeOption) => {
    const typeConfig = getSaleTypeConfig(type.value, isWithComingSoon);
    setSelectedType(typeConfig);

    const selectedOption =
      type?.options?.find((option) => !!option.isDefault) ?? null;

    setSelectedOption(selectedOption);
    const option = selectedOption?.value;

    set(UUID, { type: type?.value, option });
    setActive(null);
  };

  const onSaleTypeOptionChange = useCallback(
    (selectedOption: TDropdownOption) => {
      setSelectedOption(selectedOption);

      set(UUID, { type: selectedType.value, option: selectedOption.value });
    },
    [set, selectedType],
  );

  useEffect(
    function syncLocalStateWithParams() {
      setSelectedType((prev) =>
        prev?.value === parameterSale?.type
          ? prev
          : getSaleTypeConfig(parameterSale.type, isWithComingSoon),
      );

      setSelectedOption((prev) => {
        const type = parameterSale?.type;
        if (!type) return prev;

        if (!parameterSale.option) {
          return (
            getSaleTypeConfig(type, isWithComingSoon)?.options?.find(
              (option) => !!option.isDefault,
            ) ?? null
          );
        }

        if (prev?.value && prev.value === parameterSale.option) return prev;

        return (
          getSaleTypeConfig(type, isWithComingSoon)?.options?.find(
            (option) => option.value === parameterSale.option,
          ) ?? null
        );
      });
    },
    [isWithComingSoon, parameterSale.type, parameterSale?.option, set],
  );

  useEffect(() => {
    const hasCSinUrl = filtersToExclude.includes(String(parameterSale.option));

    if (!hasCSinUrl) return;

    if (isWithComingSoon) return;

    set(UUID, defaultWithoutComingSoonSaleFilter);
  }, [isWithComingSoon, parameterSale.option, set]);

  return (
    <Container>
      <ButtonsContainer>
        {Object.values(saleTypesMap)
          .filter((type) => !(type.value === 'sold' && isSoldDataHidden))
          .map((type) => (
            <Button
              data-qa={`sale-type-${type.value}`}
              key={type.value}
              isSelected={type.value === selectedType.value}
              onClick={() => onChangeType(type)}
            >
              {type.label}
            </Button>
          ))}
      </ButtonsContainer>
      {selectedType?.options && (
        <Dropdown.Button
          isOpen={isOpen}
          toggleOpen={() => setIsOpen((prev) => !prev)}
          isRelativePosition
          dropdownButtonStyle={{
            borderRadius: isOpen
              ? 0
              : `0 0 ${theme.borderRadius.xlRem} ${theme.borderRadius.xlRem}`,
          }}
          content={
            <Dropdown.Content>
              <Dropdown.ContentItem
                label={`Status:`}
                selectedValue={selectedOption as TDropdownOption}
              />
            </Dropdown.Content>
          }
        >
          <Dropdown.Options
            options={selectedType.options as TDropdownOption[]}
            selectedOption={selectedOption as TDropdownOption}
            setSelectedOption={onSaleTypeOptionChange}
            optionStyles={{ paddingLeft: '5.6rem' }}
          />
        </Dropdown.Button>
      )}
    </Container>
  );
};
