import styled from '@emotion/styled';
import { colorOpacityApplier } from '../../../../../services/colorOpacityApplier';

type WithOpen = {
  isOpen: boolean;
};

type WithContentHeight = {
  contentHeight?: number;
};

type ContainerProps = {
  isGreyBg?: boolean;
};

type BodyContentProps = {
  noContentPadding?: boolean;
};
export const Container = styled('div')<ContainerProps>(
  ({ theme, isGreyBg }) => ({
    position: 'relative',
    border: `1px solid ${theme.colors.grey.main}`,
    borderRadius: theme.borderRadius.xlRem,
    backgroundColor: isGreyBg
      ? theme.colors.grey.ultraLight
      : theme.colors.white,
    boxShadow: '0px 2px 2px #00000033',
    overflow: 'hidden',
  }),
);

export const HeadWrapper = styled('div')<WithOpen>(({ theme, isOpen }) => ({
  borderRadius: isOpen ? '0 0 20px 0' : 0,
  backgroundColor: theme.colors.white,
}));

export const Head = styled('div')<WithOpen>(({ theme, isOpen }) => ({
  backgroundColor: isOpen
    ? colorOpacityApplier(theme.colorPrimary, '0.2')
    : theme.colors.white,
  borderRadius: isOpen ? '0 0 20px 0' : 0,
  padding: '1.8rem 2.3rem',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  transition: 'all .3s ease-in-out',
}));

export const IconWrapper = styled('div')(({ theme }) => ({
  color: theme.colorPrimary,
  fontSize: '3.1rem',
  marginRight: '1rem',
}));

export const Title = styled('div')(({ theme }) => ({
  color: theme.colors.black,
  fontSize: theme.fontSize.ulRem,
  fontWeight: theme.fontWeight.bold,
}));

export const Body = styled('div')<WithOpen & WithContentHeight>(
  ({ contentHeight, isOpen }) => ({
    width: '100%',
    height: isOpen ? contentHeight : 0,
    overflow: 'hidden',
    transition: 'all .2s linear',
  }),
);

export const BodyContent = styled('div')<BodyContentProps>(
  ({ noContentPadding }) => ({
    padding: noContentPadding ? '0' : '2.7rem',
  }),
);

export const Description = styled('div')(({ theme }) => ({
  fontSize: theme.fontSize.mdRem,
  fontStyle: 'italic',
  color: theme.colors.black,
  marginLeft: '0.6rem',
}));
