import styled from '@emotion/styled';
import Card from '../../../../../components/V2/Card/Card';

const FilterCard = styled(Card)({
  boxShadow: '1px 1px 2px #07060626',
});

const Title = styled('h5')(({ theme }) => ({
  fontSize: theme.fontSize.lgRem,
  color: theme.colors.black,
  lineHeight: '2rem',
  fontWeight: theme.fontWeight.bold,
}));

const Subtitle = styled('div')(({ theme }) => ({
  fontSize: theme.fontSize.smRem,
  color: theme.colors.black,
  lineHeight: '1.5rem',
  fontWeight: theme.fontWeight.regular,
}));

const Content = styled('div')(() => ({
  padding: '1.4rem 1.6rem',
  position: 'relative',
}));

const InnerContentWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const TitleWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const OuterContentWrapper = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.grey.light}`,
  marginBottom: '1.7rem',
  paddingBottom: '1.7rem',
}));

const FilterCardStyle = {
  Title,
  Content,
  Subtitle,
  FilterCard,
  TitleWrapper,
  InnerContentWrapper,
  OuterContentWrapper,
};

export default FilterCardStyle;
