import styled from '@emotion/styled';

const Container = styled('div')({
  margin: '4rem auto',
  display: 'flex',
  justifyContent: 'space-between',
  '@media all and (min-width: 768px)': {
    margin: 0,
  },
});

const Logo = styled('img')({
  height: '8rem',
  marginRight: '3rem',
  display: 'none',
  '@media all and (min-width: 768px)': {
    display: 'block',
  },
});

const AgentInfo = styled('div')({
  width: 'fit-content',
  display: 'flex',
});

const ImageContainer = styled('div')({
  width: '9.5rem',
  height: '9.5rem',
  marginRight: '1.9rem',
});

const Image = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  borderRadius: theme.borderRadius.rounded,
  boxShadow: theme.boxShadow.main,
  border: `5px solid ${theme.colors.white}`,
  objectFit: 'cover',
}));

const AgentDetails = styled('div')({
  fontSize: '1.6rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const AgentName = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
const AgentOffice = styled.p`
  color: ${({ theme }) => theme.colors.grey.dark};
`;

const Button = styled('button')(({ theme }) => ({
  width: 'max-content',
  height: '2rem',
  backgroundColor: theme.colors.branding.main,
  color: theme.colors.white,
  textTransform: 'uppercase',
  fontSize: '1.2rem',
  border: 'none',
  borderRadius: '1rem',
  marginTop: '0.5rem',
  padding: '0 1.2rem',
  ':hover': {
    backgroundColor: theme.colors.black,
  },
}));

export default {
  Container,
  Logo,
  Image,
  AgentInfo,
  AgentDetails,
  ImageContainer,
  AgentName,
  AgentOffice,
  Button,
};
