import { useState, useEffect } from 'react';
import { breakpoint } from '../constants/theme';

export const BreakPoints = {
  sm: parseInt(breakpoint.sm.max.split('p')[0], 10),
  md: parseInt(breakpoint.md.max.split('p')[0], 10),
  lg: parseInt(breakpoint.lg.max.split('p')[0], 10),
};

// Returns the current breakpoint as the window is resized.
const getBreakpoint = () => {
  if (window.innerWidth <= BreakPoints.sm) {
    return BreakPoints.sm;
  }
  if (
    window.innerWidth >= BreakPoints.sm &&
    window.innerWidth <= BreakPoints.md
  ) {
    return BreakPoints.md;
  }

  return BreakPoints.lg;
};

const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState(getBreakpoint());
  const [isLargeScreen, setIsLargeScreen] = useState(
    getBreakpoint() === BreakPoints.lg,
  );

  const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    setIsLargeScreen(breakpoint >= BreakPoints.lg);
  }, [breakpoint]);

  window.addEventListener('resize', () => {
    const newBreakpoint = getBreakpoint();
    if (newBreakpoint !== breakpoint) {
      setBreakpoint(newBreakpoint);
    }
    setWindowInnerWidth(window.innerWidth);
  });

  return {
    windowInnerWidth,
    breakpoint,
    isLargeScreen,
  };
};

export default useBreakpoint;
