import { FC, useMemo, createContext, useContext } from 'react';

import { FeatureFlags } from '../types';
import { useCombinedFeatureFlags } from '../hooks/useCombinedFeatureFlags';

interface FeatureFlagContextStruct {
  isLoading: boolean;
  featureFlags: FeatureFlags | undefined;
}

const FeatureFlagContext = createContext<FeatureFlagContextStruct | undefined>(
  undefined,
);

export const useKvCoreFeatureFlags = (): FeatureFlagContextStruct => {
  const context = useContext(FeatureFlagContext);

  if (context === undefined) {
    throw new Error(
      'useKvCoreFeatureFlags cannot be used outside FeatureFlagProvider!',
    );
  }

  return context;
};

type Props = {
  loader: React.ReactElement | null;
};

export const FeatureFlagProvider: FC<Props> = ({ children, loader = null }) => {
  const { featureFlags, isLoading } = useCombinedFeatureFlags();

  const value = useMemo(
    () => ({
      isLoading,
      featureFlags,
    }),
    [featureFlags, isLoading],
  );
  return (
    <FeatureFlagContext.Provider value={value}>
      {isLoading ? loader : children}
    </FeatureFlagContext.Provider>
  );
};
