import { TSaleType, TSaleTypeOption } from '../../types';

export const saleTypesMap: Record<TSaleType, TSaleTypeOption> = {
  sale: {
    label: 'For Sale',
    value: 'sale',
    options: [
      {
        label: 'All',
        value: 'all',
      },
      {
        label: 'Coming Soon & Active',
        value: 'comingSoonAndActive',
        isDefault: true,
      },
      {
        label: 'Active',
        value: 'active',
        isDefault: true,
      },
      {
        label: 'Coming Soon',
        value: 'comingSoon',
      },
      {
        label: 'Active & Under Contract / Pending',
        value: 'activeAndUnderContractPending',
      },
      {
        label: 'Under Contract / Pending',
        value: 'underContractPending',
      },
    ],
  },
  rent: {
    label: 'For Rent',
    value: 'rent',
  },
  sold: {
    label: 'Recently Sold',
    value: 'sold',
    options: [
      {
        label: 'last 7 Days',
        value: 'last7Days',
      },
      {
        label: 'last 1 Month',
        value: 'last1Month',
      },
      {
        label: 'last 3 Months',
        value: 'last3Months',
        isDefault: true,
      },
      {
        label: 'last 6 Months',
        value: 'last6Months',
      },
      {
        label: 'last 1 Year',
        value: 'last1Year',
      },
      {
        label: 'last 2 Years',
        value: 'last2Years',
      },
    ],
  },
};
