import styled from '@emotion/styled';

import { UnknownUser } from '../../components/sign-in-form/UnknownUser';

const Page = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

export function UnknownUserPage(): JSX.Element {
  return (
    <Page>
      <UnknownUser />
    </Page>
  );
}
