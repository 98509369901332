import React from 'react';
import { useTranslation } from 'react-i18next';

import S from './Header.styles';
import { useDownloadAppUrl } from '../../hooks/useDownloadAppUrl';
import { useBrandingAndAgent } from '../../hooks/useBrandingAndAgent';

export const Header: React.FC = () => {
  const { t } = useTranslation();
  const { data } = useBrandingAndAgent();

  const proxyUrl = useDownloadAppUrl();

  return (
    <S.Header>
      <S.HeaderWrapper>
        <S.HeaderContainer>
          <S.LogoWrapper>
            {!data?.logo ? null : <S.Logo src={data?.logo} />}
          </S.LogoWrapper>

          <S.MobileDownloadBtn
            onClick={() => window.location.replace(proxyUrl)}
          >
            Download now
          </S.MobileDownloadBtn>
        </S.HeaderContainer>
      </S.HeaderWrapper>

      <S.Container>
        <S.Title>{t('mobileAppDeepLink.title')}</S.Title>
        <S.MobileDescription>
          {t('mobileAppDeepLink.description')}
        </S.MobileDescription>
      </S.Container>
    </S.Header>
  );
};
