import { PropsWithChildren, useMemo } from 'react';
import { Button as MUIButton, ButtonProps, styled } from '@mui/material';

import { Spinner } from '../../V2/Loaders/Spinner';
import { CssStyleClass } from '@fortawesome/fontawesome-common-types';

type Props = {
  loading?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  endIcon?: JSX.Element;
  startIcon?: JSX.Element;
  loadingIndicator?: string;
  className?: CssStyleClass | string;
  shape?: 'square' | 'rounded';
};

export function Button({
  loading,
  endIcon,
  children,
  startIcon,
  fullWidth,
  className,
  loadingIndicator,
  disabled = false,
  shape = 'rounded',
  variant = 'contained',
  ...rest
}: PropsWithChildren<Props & ButtonProps>) {
  const loaderColor =
    shape == 'square' || variant == 'outlined' ? 'primary' : 'white';

  const hasAnimation = Boolean(loading && loadingIndicator);

  const renderBtnContent = useMemo(() => {
    if (loading && !loadingIndicator) {
      return <Spinner size={1.2} color={loaderColor} />;
    }

    if (hasAnimation) {
      return loadingIndicator;
    }

    return children;
  }, [children, hasAnimation, loaderColor, loading, loadingIndicator]);

  return (
    <StyledButton
      shape={shape}
      variant={variant}
      disabled={disabled}
      className={className}
      fullWidth={fullWidth}
      hasAnimation={hasAnimation}
      endIcon={loading ? undefined : endIcon}
      startIcon={loading ? undefined : startIcon}
      {...rest}
    >
      {renderBtnContent}
    </StyledButton>
  );
}

const StyledButton = styled(MUIButton, {
  shouldForwardProp: (propName) =>
    !['hasAnimation', 'focus', 'customColor', 'fullWidth', 'isMobile'].includes(
      propName.toString(),
    ),
})<ButtonProps & Props & { hasAnimation: boolean }>(({
  shape,
  fullWidth,
  hasAnimation,
}) => {
  return {
    width: fullWidth ? '100%' : 'fit-content',
    borderRadius: shape == 'rounded' ? '50px' : undefined,
    backgroundImage: hasAnimation
      ? `linear-gradient(to right, #6a6a6a91 50%, transparent 50%)`
      : '',
    backgroundSize: '200% 100%',
    transition: hasAnimation ? 'all .5s ease-out' : '',
    '@keyframes loading': {
      from: {
        backgroundPosition: '100%',
      },
      to: {
        backgroundPosition: '0%',
      },
    },
    animation: hasAnimation ? 'loading 2s infinite ease' : '',
  };
});
