const OpenEyeIcon = (): JSX.Element => {
  return (
    <svg
      id="Group_9384"
      data-name="Group 9384"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="18.779"
      height="9.693"
      viewBox="0 0 18.779 9.693"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_3456"
            data-name="Rectangle 3456"
            width="18.779"
            height="9.693"
            fill="#989797"
          />
        </clipPath>
      </defs>
      <g id="Group_9383" data-name="Group 9383" clipPath="url(#clip-path)">
        <path
          id="Path_3460"
          data-name="Path 3460"
          d="M16.306,3.289c.594.689,1.176,1.362,1.756,2.036a.619.619,0,0,1-.2,1.017.613.613,0,0,1-.708-.192q-.892-1.031-1.781-2.064c-.025-.029-.052-.055-.071-.074l-1.88.95.659,1.364c.225.466.456.929.674,1.4a.612.612,0,0,1-.984.7,1.085,1.085,0,0,1-.171-.274q-.652-1.335-1.3-2.674c-.043-.09-.075-.141-.2-.12-.635.111-1.271.21-1.908.313-.062.01-.124.016-.2.026,0,.079,0,.142,0,.2q0,1.566,0,3.131a.6.6,0,0,1-.611.662.616.616,0,0,1-.611-.668q0-1.556,0-3.112c0-.069,0-.137,0-.188L6.532,5.37c-.195.4-.4.829-.612,1.259C5.661,7.163,5.4,7.7,5.144,8.234a.627.627,0,0,1-.636.369.6.6,0,0,1-.535-.544.744.744,0,0,1,.072-.368c.274-.577.563-1.147.842-1.722.164-.338.319-.68.469-1l-1.9-.972c-.288.282-.59.576-.891.871-.356.35-.711.7-1.068,1.051a.612.612,0,0,1-.891.024.6.6,0,0,1,.022-.888c.563-.56,1.131-1.114,1.7-1.671.035-.035.068-.073.086-.092-.495-.459-.993-.9-1.465-1.366A10.156,10.156,0,0,1,.15,1.009.6.6,0,0,1,.23.136a.616.616,0,0,1,.884.121A11.989,11.989,0,0,0,5.04,3.433a9.4,9.4,0,0,0,5.144,1.02,10.325,10.325,0,0,0,5.659-2.384A11.537,11.537,0,0,0,17.664.259a.614.614,0,1,1,.958.764A12.629,12.629,0,0,1,16.428,3.19l-.122.1"
          transform="translate(0 0)"
          fill="#989797"
        />
      </g>
    </svg>
  );
};

export default OpenEyeIcon;
