export enum ActiveTab {
  All = 'all',
  Unread = 'unread',
}

export enum NotificationCategory {
  Chat = 'chat',
  Move = 'move',
  Maintenance = 'maintenance',
  Transaction = 'transaction',
  Home = 'home',
}

export enum NotificationBadge {
  New = 'new',
  Pencil = 'pencil',
  Counter = 'counter',
}

export enum ClickAction {
  mortgage = 'mortgage',
  insurance = 'insurance',
  addAddress = 'property',
}

interface NotificationParams {
  chatConversationId?: string;
  messageCount?: number;
  senderFirstName?: string;
  senderPhoto?: string;
  click_action?: ClickAction;
}

export interface Notification {
  id: number;
  badge: NotificationBadge;
  isRead: boolean;
  priority: number;
  category: NotificationCategory;
  createdAt: string;
  text: string | null;
  readAt: string | null;
  subText: string | null;
  params: NotificationParams;
}

export interface NotificationsWithMeta {
  data: Notification[];
  links: [];
  meta: {
    current_page: number;
    first_page_url: string;
    from: number;
    next_page_url: string | null;
    path: string;
    per_page: number;
    prev_page_url: string | null;
    to: number | null;
  };
}

export interface NotificationsCount {
  total: number;
  unread: number;
}

export type NotificationsCountExtended = {
  previous?: NotificationsCount;
  current: NotificationsCount;
};
