import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, FC, createRef, RefObject } from 'react';

import Styled from './Chat.styles';
import { routes } from '../../../constants';
import { chatMessageAtom } from '../chatMessageAtom';
import { TrackPageTypes, useTrackPage } from '../../../features/analytics';

import useChat from './useChat';
import { useIsMobileView } from '../../../hooks/useIsMobileView';
import { useChatWarning } from '../../../providers/ChatWarnignProvider';
import { useReadConversation } from './useReadConversation';
import { BrowserStorage } from '../../../services/BrowserStorage';

interface ChatProps {
  conversationId?: string;
  onLinkClick?: () => void;
}

export const Chat: FC<ChatProps> = ({ conversationId, onLinkClick }) => {
  const talkJsContainer = createRef() as RefObject<HTMLDivElement>;

  const { t } = useTranslation();

  const storage = new BrowserStorage();

  useTrackPage(TrackPageTypes.chat);

  const readConversation = useReadConversation();

  const [initChatMessage, setInitChatMessage] = useRecoilState(chatMessageAtom);

  const [inboxIsMounted, setInboxIsMounted] = useState(false);

  useEffect(() => {
    if (
      !inboxIsMounted ||
      !conversationId ||
      readConversation.isSuccess ||
      readConversation.isLoading
    )
      return;

    readConversation.mutate(conversationId);
  }, [conversationId, inboxIsMounted]); // eslint-disable-line

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { inbox } = useChat(conversationId) as Chatbox;

  const { message, setMessage } = useChatWarning();

  const isMobile = useIsMobileView();

  useEffect(() => {
    if (!inboxIsMounted && inbox) {
      inbox.mount(talkJsContainer.current);
      setInboxIsMounted(true);

      inbox.onKeyup(() => {
        inbox.messageField.getText().then((msg: string) => {
          setMessage(msg);
        });
      });

      inbox.onSendMessage(() => {
        storage.setItem('visitCount', 1);
        setMessage('');
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inboxIsMounted, inbox, talkJsContainer, setMessage, message]);

  useEffect(() => {
    if (inboxIsMounted && initChatMessage) {
      inbox.messageField.setText(initChatMessage);
      setMessage(initChatMessage);
      setInitChatMessage('');
    }
  }, [inbox, inboxIsMounted, initChatMessage, setInitChatMessage, setMessage]);

  if (!conversationId) {
    return (
      <Styled.Container data-qa="chatbox-wrapper" isMobile={isMobile}>
        <Styled.LoadingIndicator data-qa="chat-loader" />
      </Styled.Container>
    );
  }

  return (
    <Styled.Container data-qa="chatbox-wrapper" isMobile={isMobile}>
      <Styled.AgentLinkText
        isMobile={isMobile}
        onClick={() => onLinkClick && onLinkClick()}
      >
        <Link to={routes.myAgent()}>{t('chatModal.viewProfile')}</Link>
      </Styled.AgentLinkText>
      <Styled.ChatBox
        ref={talkJsContainer}
        className="chat-box"
        data-qa="chatbox"
      />
    </Styled.Container>
  );
};
