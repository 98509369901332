import { useCallback } from 'react';
import { ListingDetails } from '../../../types';
import { useListingDetailsRoute } from '../../../hooks/useListingDetailsRoute';
import { useNewListingInteraction } from '../../../pages/SearchResults/components/ListingInteractionTracker/ListingInteractionTracker';

export function useOpenListingDetailPage(
  listingDetail: ListingDetails,
  skipViewedSession = false,
) {
  const { getListingRoute } = useListingDetailsRoute();
  const newListingInteraction = useNewListingInteraction();

  const goToDetails = useCallback(
    (e, forceSkip = false) => {
      e.stopPropagation();

      const generatedPath = `/holp${getListingRoute(listingDetail)}`;

      const url = new URL(generatedPath, 'http://dummyaddress.com');
      const path = url.pathname;
      const params = url.searchParams;
      newListingInteraction(listingDetail);
      if (forceSkip || skipViewedSession) {
        params.append('viewed', 'skip');
      }

      window.open(path + '?' + params, '_blank');
    },
    [getListingRoute, listingDetail, newListingInteraction, skipViewedSession],
  );

  return goToDetails;
}
