import { AddressAndMetaPayload, Coordinates } from '../types';
import { fetchPropertyDetailsByAddress } from '../hooks/fetchPropertyDetailsByAddress';

type GetPropertyUuidPayload = {
  myPropertyUuid?: string | null;
};
export const getPropertyUuidPayload = async (
  addressAndMetadata: AddressAndMetaPayload,
  coordinates?: Coordinates,
): Promise<GetPropertyUuidPayload> => {
  if (!addressAndMetadata?.Address?.fullAddress) {
    return {
      myPropertyUuid: null,
    };
  }

  const propertyByAddress = await fetchPropertyDetailsByAddress(
    addressAndMetadata?.Address,
    coordinates,
  );

  const property = Array.isArray(propertyByAddress)
    ? propertyByAddress[0]
    : propertyByAddress;

  return {
    myPropertyUuid: property?.propertyUuid || null,
  };
};
