import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { listingFeatureInFocusAtom } from '../state/listingFeatureInFocusAtom';

export function useResetFeatureInFocusOnPageChange(): void {
  const location = useLocation();
  const setFeatureInFocus = useSetRecoilState(listingFeatureInFocusAtom);

  useEffect(() => {
    setFeatureInFocus(null);
  }, [location.pathname, setFeatureInFocus]);
}
