import React from 'react';
import Styled from './loading-indicator.style';

const LoadingIndicator = () => {
  return (
    <Styled.Container>
      <Styled.LoadingIndicator></Styled.LoadingIndicator>
    </Styled.Container>
  );
};

export default LoadingIndicator;
