import { extractOfficeWithValidAddress } from '../../utils/extractOfficeWithValidAddress';
import { useAgentDetails } from './useAgentDetails';
import { usePolygonDetailsByArea } from './usePolygonDetailsByArea';

const useDefaultSearchArea = () => {
  const { data: agentDetails } = useAgentDetails();

  const validOffice = extractOfficeWithValidAddress(agentDetails?.Office || []);

  const areaId = validOffice?.Address.locality || 'Miami';
  const countrySubd = validOffice?.Address.countrySubd || 'FL';

  const { data: polygonDetails } = usePolygonDetailsByArea({
    areaType: 'locality',
    areaId,
    countrySubd,
  });

  return {
    areaId,
    countrySubd,
    polygonId: polygonDetails?.polygonId,
  };
};

export default useDefaultSearchArea;
