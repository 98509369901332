import { useTheme } from '@emotion/react';
import { useWindowWidth } from './useWindowSize';

export function useIsMobileView(): boolean {
  const theme = useTheme();
  const windowWidth = useWindowWidth();

  if (windowWidth || windowWidth === 0)
    return (
      windowWidth <= Number.parseInt(theme?.breakpoint?.md?.max || '768', 10)
    );

  return false;
}
