export enum StorageType {
  LOCAL = 'local',
  SESSION = 'session',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const moduleStorage: Record<StorageType, Record<string, any>> = {
  [StorageType.LOCAL]: {},
  [StorageType.SESSION]: {},
};

export class BrowserStorage {
  static LOCAL = StorageType.LOCAL;
  static SESSION = StorageType.SESSION;

  type: StorageType;
  storage: Storage | undefined;

  constructor(type: StorageType = StorageType.LOCAL) {
    this.type = type;

    try {
      this.storage =
        type === StorageType.LOCAL
          ? window?.localStorage
          : window?.sessionStorage;
    } catch (e) {
      this.storage = undefined;
    }
  }

  getItem<T>(key: string): T | null {
    if (this.storage) {
      const value = this.storage.getItem(key) ?? 'null';
      return JSON.parse(value);
    }

    return moduleStorage[this.type][key] ?? null;
  }

  setItem<T>(key: string, value: T): void {
    if (this.storage) {
      this.storage.setItem(key, JSON.stringify(value));
      return;
    }

    moduleStorage[this.type][key] = value;
  }
}
