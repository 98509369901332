import numbro from 'numbro';

export const isMillionOrLarger = (num) => {
  return num >= 1000000;
};

export const isNearMillion = (num) => {
  return num >= 999500 && num <= 1000000;
};
export const formatCurrency = (num) => {
  if (num === undefined || num === null) {
    return null;
  }

  const config = {
    spaceSeparated: false,
    average: true,
  };

  // if number is between 999500 and 1 000 000, numbro will display 1000k instead of 1m (even with rounding true), this is quick fix
  if (isNearMillion(num)) {
    num += 500;
  }

  if (isMillionOrLarger(num)) {
    // If its in the millions, give it 2 decimal places
    config.mantissa = 2;
    config.totalLength = 3;
  }

  return numbro(num).formatCurrency(config).toUpperCase();
};

// Will give us a raw, unrounded number.
export const formatRawCurrency = (num, decimalCount = 0) => {
  if (num === undefined || num === null) {
    return null;
  }

  return numbro(num).formatCurrency({
    mantissa: decimalCount,
    thousandSeparated: true,
  });
};

export const formatPercentage = (num, decimalCount = 0) => {
  if (num === undefined || num === null) {
    return null;
  }

  return numbro(num).format({
    output: 'percent',
    mantissa: decimalCount,
    spaceSeparated: false,
  });
};

export const formatNumber = (num, decimalCount) => {
  if (num === undefined || num === null) {
    return null;
  }

  let config = {
    thousandSeparated: true,
  };

  if (num < 10000) {
    config = {
      thousandSeparated: true,
    };
  } else {
    config = {
      thousandSeparated: true,
      mantissa: 1,
      average: true,
    };
  }

  if (decimalCount) {
    config.mantissa = decimalCount;
  }

  return numbro(num).format(config);
};

const getNumDecimal = (num) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];

  return lookup
    .slice()
    .reverse()
    ?.find(function (item) {
      return num >= item.value;
    });
};

export function nFormatter(num, digits) {
  var item = getNumDecimal(num);
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
}

export const toFixedDown = (num, digits) => {
  var item = getNumDecimal(num);
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

  const number = item ? num / item.value : 0;

  const re = new RegExp('(\\d+\\.\\d{' + digits + '})(\\d)'),
    m = number.toString().match(re);
  const truncated = m ? parseFloat(m[1]) : number;
  return truncated ? String(truncated).replace(rx, '$1') + item.symbol : '0';
};

export const formatRawNumber = (num) => {
  if (!num) {
    return null;
  }

  let config = {
    thousandSeparated: true,
  };

  return numbro(num).format(config);
};
