import { FC, useMemo, useCallback, useEffect, useState } from 'react';

import { useTheme } from '@emotion/react';
import { OverlayView, Polygon } from '@react-google-maps/api';

import { SvgMarker } from './SvgMarker';
import { GoogleMap } from '../GoogleMap/GoogleMap';
import { Markers, Polygon as TPolygon } from '../../../types';
import { getPolygonCoordinates } from '../../../utils/getPolygonCoordinates';
import { getPixelPositionOffset } from '../../../utils/getPixelPositionOffset';

export const DISABLED_MAP_CONTROLS_OPTIONS = {
  fullscreenControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  disableDefaultUI: true,
  maxZoom: 18,
  draggable: false,
  zoomControl: false,
};

interface StaticMapProps {
  zoom?: number;
  markers?: Markers[];
  polygonDetails?: TPolygon | null;
  cursor?: string;
}

export const StaticMap: FC<StaticMapProps> = ({
  markers,
  polygonDetails,
  cursor = 'pointer',
}) => {
  const { colors } = useTheme();
  const polygonOptions = useMemo(
    () => ({
      fillColor: colors.black,
      fillOpacity: 0.2,
      strokeWeight: 2,
      strokeColor: colors.black,
      cursor,
    }),
    [colors, cursor],
  );

  const [map, setMap] = useState<null | google.maps.Map>(null);
  const [polygon, setPolygon] = useState<null | google.maps.Polygon>(null);

  const fitBounds = useCallback(
    (markers: Markers[]) => {
      if (!markers.length || !map) return;
      const bounds = new window.google.maps.LatLngBounds();
      markers.map((item) => {
        bounds.extend(item.position);
        return item;
      });
      map.fitBounds(bounds);
    },
    [map],
  );

  const fitBoundsByPolygon = useCallback(
    (polygonData: google.maps.Polygon) => {
      if (!map || !polygonData) return;

      const bounds = new window.google.maps.LatLngBounds();

      const paths = polygonData.getPaths();
      paths.forEach(function (path) {
        const ar = path.getArray();
        for (let i = 0, l = ar.length; i < l; i++) {
          bounds.extend(ar[i]);
        }
      });

      map.fitBounds(bounds);
    },
    [map],
  );

  useEffect(() => {
    if (!markers && !polygon) return;

    if (polygon) {
      fitBoundsByPolygon(polygon);
      return;
    }

    return fitBounds(markers ?? []);
  }, [fitBounds, fitBoundsByPolygon, markers, polygon]);

  const centeredCoordinates = useMemo(
    function getCenteredMarker() {
      if (!markers && !polygon) return;

      if (polygon) {
        return {
          lat: (polygon.getMap()?.getBounds()?.getCenter().lat() ?? 0) + 0.0015,
          lng: polygon.getMap()?.getBounds()?.getCenter().lng() ?? 0,
        };
      }

      const centeredMarker = markers?.find((marker) => marker.isCentered) ?? {
        position: { lat: 0, lng: 0 },
      };

      return {
        ...centeredMarker?.position,
        lat: centeredMarker?.position?.lat + 0.0015 ?? 0,
      };
    },
    [markers, polygon],
  );

  const renderMarker = useCallback(
    (marker, key) => (
      <OverlayView
        key={key}
        position={marker.position}
        mapPaneName={marker.mapPaneName}
        getPixelPositionOffset={getPixelPositionOffset}
      >
        <SvgMarker
          svgString={marker.svgString}
          customColor={marker.markerColor}
          markerStyle={marker.markerStyle}
        />
      </OverlayView>
    ),
    [],
  );

  return (
    <GoogleMap
      handleOnLoad={(map) => {
        setMap(map);
      }}
      center={centeredCoordinates}
      mapOptions={DISABLED_MAP_CONTROLS_OPTIONS}
    >
      {markers && markers.map(renderMarker)}
      {polygonDetails && (
        <Polygon
          onLoad={(polygon) => {
            setPolygon(polygon);
          }}
          options={polygonOptions}
          paths={getPolygonCoordinates(polygonDetails)}
        />
      )}
    </GoogleMap>
  );
};
