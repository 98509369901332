import styled from '@emotion/styled';

const PrivacyPolicyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
`;

const MainText = styled.span`
  font-size: 12px;
  font-weight: ${({ theme }) => theme.fontWeight.light};
  margin-bottom: 18px;
`;

const LinkContainer = styled.div``;

const Link = styled.a`
  margin: 20px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.branding.main};
`;

const TC = styled(Link)``;

const PrivacyPolicy = styled(Link)``;

const SubText = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey.dark};
  margin-top: 20px;
`;

const LogoContainer = styled.div``;

const Logo = styled.img`
  margin: 20px;
`;

const Styled = {
  PrivacyPolicyContainer,
  MainText,
  LinkContainer,
  TC,
  PrivacyPolicy,
  SubText,
  LogoContainer,
  Logo,
};

export default Styled;
