import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import React, {
  useRef,
  useMemo,
  useEffect,
  useContext,
  createContext,
  useState,
} from 'react';

const NavigationHistoryContext = createContext({});

export const useNavigationHistory = () => {
  const context = useContext(NavigationHistoryContext);

  if (!context)
    throw new Error('useLocationState can not be used without LocationContext');

  return context;
};

export const NavigationHistoryProvider = ({ children }) => {
  const location = useLocation();
  const prevLocationRef = useRef();
  const currentLocationRef = useRef();
  const [locationKeys, setLocationKeys] = useState([location.key]);

  const history = useHistory();

  useEffect(() => (currentLocationRef.current = null), []);

  useEffect(() => {
    if (currentLocationRef.current !== location.pathname) {
      prevLocationRef.current = currentLocationRef.current;
    }
    currentLocationRef.current = location.pathname;

    return history.listen((location) => {
      if (history.action === 'PUSH') {
        setLocationKeys((prev) => [location.key, ...prev]);
      }

      if (history.action === 'POP') {
        setLocationKeys(([_, ...keys]) => keys); // eslint-disable-line
      }
    });
  }, [locationKeys, history, location]);

  const onBack = React.useCallback(() => {
    if (locationKeys.length > 1) {
      history.goBack();
      return;
    }
    history.push('/');
  }, [history, locationKeys]);

  const value = useMemo(
    () => ({
      prevLocation: prevLocationRef.current,
      currentLocation: location.pathname,
      onBack,
    }),
    [prevLocationRef.current, location.pathname], // eslint-disable-line
  );

  return (
    <NavigationHistoryContext.Provider value={value}>
      {children}
    </NavigationHistoryContext.Provider>
  );
};

NavigationHistoryProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};
NavigationHistoryProvider.defaultProps = {
  children: [],
};
