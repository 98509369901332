import { useCallback, useState } from 'react';
import { BrowserStorage } from '../../services/BrowserStorage';

export function useStorageState<T>(
  key: string,
  fallbackValue: T,
  storage: BrowserStorage = new BrowserStorage(),
): [T, (value: T) => void] {
  const [value, setState] = useState(() => {
    const valueInStorage = storage.getItem<T>(key);

    if (valueInStorage !== null && valueInStorage !== undefined)
      return valueInStorage;

    return fallbackValue;
  });

  const setValue = useCallback(
    (newValue: T) => {
      setState(newValue);

      storage.setItem(key, newValue);
    },
    [key, storage],
  );

  return [value, setValue];
}
