import { ChangeEvent, FC, useState } from 'react';
import ClosedEyeIcon from '../Icons/OpenEyeIcon';
import OpenEyeIcon from '../Icons/ClosedEyeIcon';
import { Input } from '../Input/Input';

type Props = {
  name: string;
  value: string;
  error?: string;
  className?: string;
  required?: boolean;
  placeholder: string;
  style?: React.CSSProperties;
  onBlur?: (e: ChangeEvent) => void;
  onChange: (e: ChangeEvent) => void;
};

const PasswordInput: FC<Props> = ({
  name,
  value,
  error,
  style,
  onBlur,
  onChange,
  required,
  className,
  placeholder,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const inputType = isVisible ? 'text' : 'password';

  const handleVisibilityChange = () => {
    setIsVisible((prev) => !prev);
  };
  return (
    <Input
      name={name}
      style={style}
      value={value}
      error={error}
      onBlur={onBlur}
      type={inputType}
      required={required}
      onChange={onChange}
      className={className}
      placeholder={placeholder}
      endAdornment={
        <PasswordVisibilityIcon
          onToggle={handleVisibilityChange}
          isVisible={isVisible}
        />
      }
    />
  );
};

type PasswordVisibilityIconProps = {
  onToggle: () => void;
  isVisible: boolean;
};

const PasswordVisibilityIcon = ({
  onToggle,
  isVisible,
}: PasswordVisibilityIconProps): JSX.Element => {
  return (
    <div onClick={onToggle}>
      {isVisible ? <OpenEyeIcon /> : <ClosedEyeIcon />}
    </div>
  );
};

export default PasswordInput;
