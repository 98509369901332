import React from 'react';
import Styled from './app-loading.style';

import LoadingIndicator from '../loading-indicator/loading-indicator';
import { useTranslation } from 'react-i18next';

function AppLoading() {
  const { t } = useTranslation();

  return (
    <Styled.Container title={t('app.loading')}>
      <LoadingIndicator />
    </Styled.Container>
  );
}

export default AppLoading;
