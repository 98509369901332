import { useCallback, useState } from 'react';

import { ChatWidget } from './ChatWidget';
import MobileChatBubble from './MobileChatBubble';
import DesktopChatBubble from './DesktopChatBubble';
import { useIsMobileView } from '../../../hooks/useIsMobileView';
import { useChatConversation } from '../../../hooks/query/useChatConversation';

export function CornerChat() {
  const [expanded, setExpanded] = useState(false);
  const isMobile = useIsMobileView();

  const { isLoading: conversationIsLoading, isError: conversationError } =
    useChatConversation();

  const handleMouseOver = useCallback(() => {
    setExpanded(true);
  }, []);

  const handleMouseOut = useCallback(() => {
    setExpanded(false);
  }, []);

  if (isMobile) {
    return <MobileChatBubble />;
  }

  if (conversationIsLoading || conversationError) {
    return <></>;
  }

  return expanded ? (
    <ChatWidget handleMouseOut={handleMouseOut} showUnreadMessages />
  ) : (
    <DesktopChatBubble handleMouseOver={handleMouseOver} showUnreadMessages />
  );
}
