import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useEffect, useMemo } from 'react';
import styled from '@emotion/styled';

import { useTranslation } from 'react-i18next';
import { useHomeownerByContact } from '../../../../../../hooks/useHomeownerByContact';
import { useUpdateHomeownerDetails } from '../../../../../../hooks/useUpdateHomeownerDetails';

import { RadioButton } from '../../../../RadioButton/RadioButton';
import { Button } from '../../../../../UI/Button';
import { toastActions } from '../../../../ToastComponent/ToastComponent';
import { SectionContainer } from '../../../SideMenu.style';
import NotificationPhoneInput from './NotificationPhoneInput';

type Props = {
  setChildPanelOpenInfo: (childName: string) => void;
};

type FormValues = {
  hasTextNotifications: boolean;
  phoneNumber: string;
};

function NotificationSettings({ setChildPanelOpenInfo }: Props): JSX.Element {
  const { data: homeownerDetails } = useHomeownerByContact();

  const {
    isError,
    isSuccess,
    isLoading,
    mutate: onUpdate,
    error: editError,
  } = useUpdateHomeownerDetails();

  const hasTextNotifications =
    homeownerDetails?.OptInPreferences.find(
      (item) => item.optInType === 'smsText',
    )?.optIn || false;

  const phoneNumber = homeownerDetails?.Communication?.phoneMobile ?? '';

  const { t } = useTranslation();

  const initialNotificationValues = useMemo(() => {
    return {
      phoneNumber,
      hasTextNotifications,
    };
  }, [phoneNumber, hasTextNotifications]);

  const validationSchema = useMemo(() => {
    return yup.object({
      hasTextNotifications: yup.boolean(),
      phoneNumber: yup
        .string()
        .when('hasTextNotifications', ([hasTextNotifications]) => {
          return hasTextNotifications
            ? yup
                .string()
                .ensure()
                .transform((phoneNumber) => phoneNumber.replace(/\s/g, ''))
                .required(t('common.required'))
                .min(10, t('sideMenu.new.myProfile.personalInfo.phoneLength'))
                .test(
                  'starts with 0 or 1',
                  t('sideMenu.new.myProfile.personalInfo.invalidPhone'),
                  (phoneNumber) => {
                    if (phoneNumber[0] === '0') return false;
                    if (phoneNumber[0] === '1') return false;
                    return true;
                  },
                )
            : yup.string().notRequired();
        }),
    });
  }, [t]);

  const handleSubmit = async ({
    hasTextNotifications,
    phoneNumber,
  }: FormValues) => {
    onUpdate({
      payload: {
        Communication: {
          phoneMobile: phoneNumber,
          textOptIn: hasTextNotifications,
        },
        OptInPreferences: [
          {
            optInType: 'smsText',
            optIn: hasTextNotifications,
            optInDate: dayjs().toISOString(),
          },
        ],
      },
    });
  };

  useEffect(
    function onEditFinished() {
      if (isLoading) return;

      if (isSuccess) {
        setChildPanelOpenInfo('');
        return;
      }

      if (isError) {
        toastActions.error(editError?.message);
      }
    },
    [
      editError?.message,
      isError,
      isLoading,
      isSuccess,
      setChildPanelOpenInfo,
      t,
    ],
  );

  return (
    <SectionContainer>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={initialNotificationValues}
        onSubmit={handleSubmit}
      >
        {({ values, dirty, handleChange, setFieldValue, errors, isValid }) => {
          return (
            <Form>
              <Text>{t('sideMenu.new.myProfile.notificationHeadline')}</Text>
              <Wrap>
                <Text bold width="65%">
                  {t('sideMenu.new.myProfile.receiveTextNotificationsLabel')}
                </Text>
                <RadioGroup data-qa="notification-radio-group">
                  <RadioButton
                    dataQa="notification-yes-radio"
                    onChangeValue={() => {
                      setFieldValue('hasTextNotifications', true);
                    }}
                    value={'yes'}
                    label={t('common.yes')}
                    isSelected={values.hasTextNotifications}
                  />
                  <RadioButton
                    dataQa="notification-no-radio"
                    onChangeValue={() => {
                      setFieldValue('hasTextNotifications', false);
                    }}
                    value={'no'}
                    label={t('common.no')}
                    isSelected={!values.hasTextNotifications}
                  />
                </RadioGroup>
              </Wrap>
              {values.hasTextNotifications && (
                <NotificationPhoneInput
                  handleChange={handleChange}
                  phoneNumber={values.phoneNumber}
                  error={errors.phoneNumber}
                />
              )}

              <ButtonContainer>
                <Button
                  size="small"
                  shape="square"
                  data-qa="notification-button"
                  type="submit"
                  onClick={() => handleSubmit(values)}
                  disabled={!dirty || isLoading || !isValid}
                >
                  {t('auth.submit')}
                </Button>
              </ButtonContainer>
            </Form>
          );
        }}
      </Formik>
    </SectionContainer>
  );
}

export default NotificationSettings;

const ButtonContainer = styled.div`
  width: fit-content;
  margin-left: auto;
  margin-top: 1.5rem;
`;
const Text = styled.div<{ bold?: boolean; width?: string }>`
  width: ${({ width }) => (width ? width : '100%')};
  font-weight: ${({ theme, bold }) =>
    bold ? theme.fontWeight.bold : theme.fontWeight.regular};
`;
const Wrap = styled.div<{ bold?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
`;

const RadioGroup = styled.div`
  display: flex;
  width: 35%;
  justify-content: space-between;
`;
