import {
  FC,
  useMemo,
  useState,
  useContext,
  useCallback,
  createContext,
} from 'react';

interface ChatWarningStruct {
  message: string;
  showWarning: boolean;
  onChatClose: () => void;
  setMessage: (message: string) => void;
  setShowWarning: (state: boolean) => void;
}

const ChatWarningContext = createContext<ChatWarningStruct | undefined>(
  undefined,
);

export const useChatWarning = (): ChatWarningStruct => {
  const context = useContext(ChatWarningContext);

  if (!context) {
    throw new Error('useChatWarning cannot be used without ChatWarningContext');
  }

  return context;
};

export const ChatWarningProvider: FC = ({ children }) => {
  const [message, setMessage] = useState<string>('');
  const [showWarning, setShowWarning] = useState<boolean>(false);

  const onChatClose = useCallback(() => {
    if (!message) {
      setShowWarning(false);
      return;
    }

    setShowWarning(true);
  }, [message]);

  const value = useMemo(
    () => ({
      message,
      setMessage,
      onChatClose,
      showWarning,
      setShowWarning,
    }),
    [message, onChatClose, showWarning],
  );

  return (
    <ChatWarningContext.Provider value={value}>
      {children}
    </ChatWarningContext.Provider>
  );
};
