export function TaskIcon() {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_3172_497597)">
        <rect
          x="3"
          y="2"
          width="25"
          height="25"
          rx="12.5"
          fill="#43E135"
          shapeRendering="crispEdges"
        />
        <rect
          x="3"
          y="2"
          width="25"
          height="25"
          rx="12.5"
          fill="url(#paint0_linear_3172_497597)"
          fillOpacity="0.2"
          shapeRendering="crispEdges"
        />
        <path
          d="M22.5325 10.28C22.825 9.9875 22.825 9.5 22.5325 9.2225L20.7775 7.4675C20.5 7.175 20.0125 7.175 19.72 7.4675L18.34 8.84L21.1525 11.6525M9.25 17.9375V20.75H12.0625L20.3575 12.4475L17.545 9.635L9.25 17.9375Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3172_497597"
          x="0"
          y="0"
          width="31"
          height="31"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3172_497597"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3172_497597"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3172_497597"
          x1="12"
          y1="2"
          x2="19"
          y2="28.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
      </defs>
    </svg>
  );
}
