import { useEffect } from 'react';

import * as Sentry from '@sentry/browser';

import { Environment } from '../../../services/Environment';
import { useUsername } from '../../../providers/Authentication/useUsername';
import { useDomain, useDomainWithSubdomain } from '../../../hooks/useDomain';
import { useCompanyBranding } from '../../../hooks/query/useCompanyBranding';
import { useHomeownerByContact } from '../../../hooks/useHomeownerByContact';

export function useUserIdentify(): void {
  const domain = useDomain();
  const username = useUsername();
  const { data: homeowner } = useHomeownerByContact();
  const domainWithSubdomain = useDomainWithSubdomain();
  const { data: companyDetails } = useCompanyBranding();

  useEffect(() => {
    if (!username || !homeowner) return;

    const email = homeowner?.Communication?.emailPrimary;

    if (Environment.isTrackable()) {
      Sentry.setUser({ username, email });
    }

    // eslint-disable-next-line
  }, [homeowner, domain, domainWithSubdomain, username, companyDetails]);
}
