import { useTranslation } from 'react-i18next';
import React, { FormEvent, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import S from './GetApp.styles';
import { useSendAppLink } from './useSendAppLink';
import { IconType } from '../../../../components/icon';
import { useDownloadAppUrl } from '../../hooks/useDownloadAppUrl';
import { QRImage } from '../../../../components/V2/QRImage/QRImage';
import { useBrandingAndAgent } from '../../hooks/useBrandingAndAgent';

export const GetApp: React.FC = () => {
  const { t } = useTranslation();

  const proxyUrl = useDownloadAppUrl();
  const { data } = useBrandingAndAgent();
  const sendAppLink = useSendAppLink();

  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const [preventSubmit, setPreventSubmit] = useState(false);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    sendAppLink.mutate({ phone, email });
  };

  useEffect(() => {
    if (sendAppLink.isLoading) {
      return setPreventSubmit(true);
    }

    setTimeout(() => setPreventSubmit(false), 20000);
  }, [sendAppLink.isLoading]);

  if (!proxyUrl) return null;

  return (
    <S.Container>
      <S.SendAppForm onSubmit={onSubmit}>
        <S.SendAppInput
          required
          type="text"
          placeholder="Enter your cell phone number"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />

        <S.SendAppInput
          required
          type="email"
          placeholder="Enter your email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <S.Button disabled={preventSubmit} type="submit">
          {t('mobileAppDeepLink.sendMe', { agentName: data?.agentName })}

          {!sendAppLink.isLoading ? null : (
            <S.ButtonLoader>
              <FontAwesomeIcon
                icon={IconType.iconSpinner}
                className="fa-spin"
              />
            </S.ButtonLoader>
          )}
        </S.Button>

        {!sendAppLink.isError ? null : (
          <S.Error>{sendAppLink.error?.message}</S.Error>
        )}

        {sendAppLink.isSuccess && preventSubmit ? (
          <S.Success>Message Sent</S.Success>
        ) : null}
      </S.SendAppForm>

      <S.Divider>OR</S.Divider>

      <S.QRContainer>
        <S.QRWrapper>
          <QRImage value={proxyUrl} size={100} />
        </S.QRWrapper>
        <S.QRDescription>
          {t('mobileAppDeepLink.qrDescription')}
        </S.QRDescription>
      </S.QRContainer>
    </S.Container>
  );
};
