import React, { FC, useEffect, useMemo, useCallback } from 'react';

import { useKey } from './KeyProvider';
import { useTokenData } from './useTokenData';
import { useHolpTokens } from './HolpTokensProvider';
import { useAuth } from './AuthProvider';
import { useAccountDetails } from './useAccountDetails';
import { unauthorizedEvent } from '../../constants/customEvents';
interface AuthenticationContextStruct {
  isLoading: boolean;
  isAuthenticated: boolean;
  signOut: () => Promise<unknown>;
}

const AuthenticationContext = React.createContext<
  AuthenticationContextStruct | undefined
>(undefined);

export const useAuthentication = (): AuthenticationContextStruct => {
  const context = React.useContext(AuthenticationContext);

  if (context === undefined) {
    throw new Error(
      'useAuthentication cannot be used outside AuthenticationProvider!',
    );
  }

  return context;
};

export const AuthenticationProvider: FC = ({ children }) => {
  const {
    isLoggedIn,
    isLoading: isLoadingCognito,
    signOut: signOutCognito,
  } = useAuth();

  const { reset } = useKey();
  const { isLoading: isLoadingHolp } = useHolpTokens();
  const { isLoading: isLoadingTokenData } = useTokenData();
  const { isLoading: isLoadingAccountDetails } = useAccountDetails();

  const isLoading =
    isLoadingCognito ||
    isLoadingHolp ||
    isLoadingTokenData ||
    isLoadingAccountDetails;

  const resetAuth = useCallback(() => {
    if (!isLoggedIn) {
      reset();
      signOutCognito();
      return;
    }

    signOutCognito();
  }, [reset, signOutCognito, isLoggedIn]);

  useEffect(() => {
    window.addEventListener(unauthorizedEvent.type, resetAuth);
    return () => window.removeEventListener(unauthorizedEvent.type, resetAuth);
  }, [resetAuth]);

  const value = useMemo(
    () => ({
      signOut: signOutCognito,
      isLoading: isLoading,
      isAuthenticated: isLoggedIn,
    }),
    [signOutCognito, isLoading, isLoggedIn],
  );

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
};
