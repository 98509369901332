import { ListingDetails } from '../../../types';

export function useIsWithComingSoon(listings: ListingDetails[] | null) {
  if (!listings) return true;

  const result = listings?.some(
    (listing) =>
      listing.ListingSummary.listingStatus.toLowerCase() === 'coming soon',
  );

  return Boolean(result);
}
