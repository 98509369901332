export function OptionsIcon(): JSX.Element {
  return (
    <svg
      width="18"
      height="4"
      fill="none"
      viewBox="0 0 18 4"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 13836">
        <circle id="Ellipse 373" cx="2" cy="2" r="2" fill="#D9D9D9" />
        <circle id="Ellipse 374" cx="9" cy="2" r="2" fill="#D9D9D9" />
        <circle id="Ellipse 375" cx="16" cy="2" r="2" fill="#D9D9D9" />
      </g>
    </svg>
  );
}
