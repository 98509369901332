import styled from '@emotion/styled';

type Props = {
  icon: JSX.Element;
  details?: string;
  expanded?: boolean;
  dataQa?: string;
};

export function FeatureBadge({
  icon,
  details,
  expanded = false,
  dataQa = 'feature-badge',
}: Props): JSX.Element {
  return (
    <Container data-qa={dataQa}>
      <Icon>{icon}</Icon>
      {!expanded ? null : <Details>{details}</Details>}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Icon = styled.span`
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 0.9rem;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.branding.main};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Details = styled.span`
  height: 1.1rem;
  border-radius: 0 0.3rem 0.3rem 0;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  padding: 0 0.3rem 0 1.1rem;
  font-size: 0.9rem;
  line-height: 1.1rem;
  margin-left: -0.9rem;
`;
