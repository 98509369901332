import styled from '@emotion/styled';

export const FlexRow = styled('div')({ display: 'flex' });

export const FlexCol = styled(FlexRow)({ flexDirection: 'column' });

export const FlexCenter = styled(FlexRow)({
  alignItems: 'center',
  justifyContent: 'center',
});
