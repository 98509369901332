import styled from '@emotion/styled';

import { FlexCol } from '../Flex/Flex';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Card: any = styled(FlexCol)<{
  noBorder: boolean;
}>(({ shadow, theme, noBorder }) => ({
  background: theme.colors.white,
  borderRadius: theme.borderRadius.xlRem,
  position: 'relative',
  boxShadow: shadow ? theme.boxShadow.main : 'none',
  border: noBorder ? 'none' : `1px solid ${theme.colors.grey.main}`,
  padding: 0,
}));

const CardInnerWrapper = styled('div')(({ theme }) => ({
  padding: '1.1rem 2rem',
  [`@media (min-width: ${theme.breakpoint.md.min})`]: {
    padding: '1.1rem 2.9rem',
  },
}));

const Section = styled('div')(({ theme }) => ({
  padding: theme.spacings.md,
}));

const Divider = styled('div')<{ width: string }>(({ width, theme }) => ({
  display: 'block',
  content: '" "',
  height: 1,
  backgroundColor: theme.colors.grey.main,
  width: width || '100%',
  margin: '0 auto',
}));

const Headline = styled('h4')(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: theme.fontWeight.bold,
  // color: theme.colors.branding.main,
  color: theme.colors.black,
  lineHeight: '2.8rem',
  '@media all and (min-width: 769px)': {
    fontSize: '2.4rem',
  },
}));

const Subheading = styled('h5')(({ theme }) => ({
  fontSize: '1.2rem',
  lineHeight: '1.4rem',
  color: theme.colors.black,
  fontWeight: theme.fontWeight.regular,
}));

const Header = styled('header')(() => ({
  // borderBottom: `1px solid ${theme.colors.grey.main}`,
}));

const Footer = styled('footer')<{ hideBorder: boolean }>(
  ({ theme, hideBorder = false }) => ({
    borderTop: hideBorder ? 'none' : `1px solid ${theme.colors.grey.main}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: `center`,
  }),
);

Card.Section = Section;
Card.Divider = Divider;
Card.Header = Header;
Card.Footer = Footer;
Card.Headline = Headline;
Card.Subheading = Subheading;
Card.CardInnerWrapper = CardInnerWrapper;

export default Card;
