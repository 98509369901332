import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHomeownerByContact } from '../../../../../../hooks/useHomeownerByContact';
import { Icon, IconType } from '../../../../../icon';
import { FlexRow } from '../../../../Flex/Flex';
import {
  IconWrap,
  InputBox,
  InputBoxLabel,
  InputBoxText,
  SectionContainer,
  SectionWrap,
} from '../../../SideMenu.style';
import UpdatePasswordForm from './UpdatePasswordForm';

type Props = {
  label: string;
  childPanelOpen: string;
  setChildPanelOpenInfo: (childName: string) => void;
};

function LoginInfo({
  label,
  childPanelOpen,
  setChildPanelOpenInfo,
}: Props): JSX.Element {
  const { data: homeownerDetails } = useHomeownerByContact();
  const emailAddress = homeownerDetails?.Communication?.emailPrimary;
  const [formOpen, setFormOpen] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (label !== childPanelOpen) {
      setFormOpen(false);
    }
  }, [childPanelOpen, label]);

  return (
    <SectionContainer>
      <>
        <InputBox>
          <InputBoxLabel data-qa="email-label">
            {t('auth.fields.email')}:
          </InputBoxLabel>
          <InputBoxText data-qa="email-value">{emailAddress}</InputBoxText>
        </InputBox>

        {!formOpen && (
          <InputBox hasMt>
            <SectionWrap>
              <FlexRow>
                <InputBoxLabel data-qa="password-label">
                  {t('auth.fields.password')}:
                </InputBoxLabel>
                <InputBoxText data-qa="password-value">
                  <b>********</b>
                </InputBoxText>
              </FlexRow>
              <IconWrap
                data-qa="password-edit-icon"
                onClick={() => {
                  setFormOpen((prev) => !prev);
                }}
              >
                <Icon type={IconType.PencilAlt} />
              </IconWrap>
            </SectionWrap>
          </InputBox>
        )}
      </>
      {formOpen && (
        <UpdatePasswordForm
          show={formOpen}
          emailAddress={emailAddress}
          setChildPanelOpenInfo={setChildPanelOpenInfo}
        />
      )}
    </SectionContainer>
  );
}

export default LoginInfo;
