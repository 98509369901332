import { useAgentBranding } from './query/useAgentBranding';
import { useCompanyBranding } from './query/useCompanyBranding';

export function useLogo(): string {
  const { data: companyBranding } = useCompanyBranding();

  const { data: agentBranding } = useAgentBranding();

  const agentLogo = agentBranding?.defaultLogo || agentBranding?.additionalLogo;
  const companyLogo =
    companyBranding?.defaultLogo || companyBranding?.additionalLogo;

  return agentLogo || companyLogo || '';
}
