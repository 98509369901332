import styled from '@emotion/styled';
import { useRecoilValue } from 'recoil';

import { FeatureBadge } from './FeatureBadge';
import { ListingDetails } from '../../../../types';
import { listingFeatureInFocusAtom } from '../../state/listingFeatureInFocusAtom';
import { useApplicableFeatureConfigs } from './useApplicableFeatureConfigs';

type Props = {
  listing?: ListingDetails;
  className?: string;
};

export function ListingFeatures({
  listing,
  className = '',
}: Props): JSX.Element {
  const featureInFocuse = useRecoilValue(listingFeatureInFocusAtom);

  const featureConfigs = useApplicableFeatureConfigs(listing);

  if (!listing) return <></>;

  return (
    <Container className={className}>
      {featureConfigs.map(({ id, icon, details, inFocus }) =>
        !featureInFocuse || inFocus ? (
          <FeatureBadge
            key={id}
            dataQa={`feature-badge-${id}`}
            icon={icon}
            details={details}
            expanded={inFocus && details !== undefined}
          />
        ) : null,
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.3rem;
`;
