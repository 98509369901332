import styled from '@emotion/styled';

export const ErrorMessage = styled('p')(({ theme }) => ({
  margin: 0,
  padding: 0,
  width: '100%',
  fontSize: '0.9rem',
  textAlign: 'center',
  color: theme.colors.red,
}));
