import { useTheme } from '@emotion/react';
import React, { memo, useMemo } from 'react';
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider';

import Styled from './RangeSlider.styled';
import { RangeBarChart } from '../../../common/RangeBarChart/RangeBarChart';
import { SliderRail, Handle, Track } from './components';
import { UUID } from '../filter';
import { useSearchParams } from '../../../../../../providers/SearchParamsProvider';
import { useRangeIsDirty } from '../../../../../../providers/RangeIsDirtyProvider';
interface RangeSliderProps {
  distributions: number[];
  prices: number[];
  setRange: (value: number[]) => void;
  initFrom: number | null;
  initTo: number | null;
}

const sliderStyle = {
  position: 'relative',
  width: 'calc(100% - 24px)',
  marginTop: '0',
  marginLeft: 24,
};

function findClosestPriceIndex(prices: number[], price: number) {
  return prices.findIndex((p, i) => {
    if (i === prices.length - 1) return true;

    return p >= price && p < prices[i + 1];
  });
}

const RangeSlider: React.FC<RangeSliderProps> = memo(function RangeSlider({
  distributions,
  prices,
  initFrom,
  initTo,
  setRange,
}) {
  const theme = useTheme();
  const brandColor = theme.colors.branding?.main ?? theme.colors.branding.main;
  const { get } = useSearchParams();

  const minParam = Number(get(UUID)?.min) || 0;
  const maxParam = Number(get(UUID)?.max) || 0;
  const { isDirtyMax, setIsDirtyMax, isDirtyMin, setIsDirtyMin } =
    useRangeIsDirty();

  const fromPosition =
    initFrom !== null ? findClosestPriceIndex(prices, initFrom) : 0;

  const toPosition =
    initTo !== null ? findClosestPriceIndex(prices, initTo) : prices.length - 1;

  const domain = useMemo(() => [0, prices.length - 1], [prices]);
  const values = useMemo(
    () => [fromPosition, toPosition],
    [fromPosition, toPosition],
  );

  const isEmpty = (values: number[] | readonly number[]) =>
    values.some((val) => Number.isNaN(val));

  const updateRangeDirtyState = (
    values: number[],
    newUpdate: readonly number[],
  ) => {
    const isMinChanged = values[0] !== newUpdate[0];
    const isMaxChanged = values[1] !== newUpdate[1];

    if (isMinChanged) {
      setIsDirtyMin(false);
    }
    if (isMaxChanged) {
      setIsDirtyMax(false);
    }
  };

  const handleUpdate = (newUpdate: readonly number[]) => {
    if (
      isEmpty(newUpdate) ||
      (values[0] === newUpdate[0] && values[1] === newUpdate[1])
    )
      return;

    updateRangeDirtyState(values, newUpdate);

    const from = isDirtyMin ? minParam : prices[newUpdate[0]];
    const to = isDirtyMax ? maxParam : prices[newUpdate[1]];

    setRange([from, to]);
  };

  return (
    <Styled.RangeSliderContainer>
      <Styled.Chart>
        <RangeBarChart range={values} data={distributions} />
      </Styled.Chart>

      <Slider
        rootStyle={sliderStyle}
        mode={2}
        step={1}
        domain={domain}
        values={values}
        onUpdate={handleUpdate}
      >
        <Rail>
          {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map((handle) => (
                <Handle
                  brandColor={brandColor}
                  key={handle.id}
                  handle={handle}
                  domain={domain}
                  getHandleProps={getHandleProps}
                />
              ))}
            </div>
          )}
        </Handles>

        <Tracks left={false} right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  brandColor={brandColor}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
      </Slider>
    </Styled.RangeSliderContainer>
  );
});

export default RangeSlider;
