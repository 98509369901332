import { FC, useCallback } from 'react';
import { Label } from './TwoOptionsSwitch.style';

import Switch from 'react-switch';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

type TOption = {
  label: string;
  value: string;
};

interface TwoOptionsSwitchProps {
  width?: number;
  dataQa?: string;
  height?: number;
  switchStatus: string;
  firstOption: TOption;
  isDisabled?: boolean;
  secondOption: TOption;
  labelInside?: boolean;
  changeOpacity?: boolean;
  preventTouchPropagation?: boolean;
  preventClickPropagation?: boolean;
  setSwitchStatus: (status: string) => void;
}

export const TwoOptionsSwitch: FC<TwoOptionsSwitchProps> = ({
  dataQa,
  width = 48,
  isDisabled,
  height = 24,
  firstOption,
  labelInside,
  switchStatus,
  secondOption,
  changeOpacity,
  setSwitchStatus,
  preventTouchPropagation = false,
  preventClickPropagation = false,
}) => {
  const theme = useTheme();

  const onSetSwitchStatus = (checked: boolean) => {
    if (isDisabled) return;
    const switchStatus = checked ? secondOption.value : firstOption.value;
    setSwitchStatus(switchStatus);
  };

  const stopTuchPropagation = useCallback(
    (e) => {
      if (!preventTouchPropagation) return;
      e.stopPropagation();
    },
    [preventTouchPropagation],
  );

  const stopClickPropagation = useCallback(
    (e) => {
      if (!preventClickPropagation) return;
      e.stopPropagation();
    },
    [preventClickPropagation],
  );

  return (
    <Container
      data-qa={dataQa}
      isDisabled={isDisabled}
      changeOpacity={changeOpacity}
      onClick={stopClickPropagation}
      onTouchStart={stopTuchPropagation}
      checked={switchStatus === secondOption.value}
    >
      {!labelInside && (
        <Label
          isDisabled={isDisabled}
          labelInside={labelInside}
          isActive={switchStatus === firstOption.value}
        >
          {firstOption.label}
        </Label>
      )}
      <Switch
        handleDiameter={height - 4}
        onChange={onSetSwitchStatus}
        width={width}
        height={height}
        checked={switchStatus === secondOption.value}
        className="react-switch"
        uncheckedIcon={
          labelInside ? <LabelInside>{secondOption.label}</LabelInside> : <></>
        }
        checkedIcon={
          labelInside ? <LabelInside>{firstOption.label}</LabelInside> : <></>
        }
        onColor={theme.colors.branding.main}
        offColor={theme.colors.branding.main}
      />
      {!labelInside && (
        <Label
          isDisabled={isDisabled}
          labelInside={labelInside}
          isActive={switchStatus === secondOption.value}
        >
          {secondOption.label}
        </Label>
      )}
    </Container>
  );
};

const Container = styled.div<{
  checked?: boolean;
  isDisabled?: boolean;
  changeOpacity?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  .react-switch-bg {
    & > div {
      padding-right: 3rem;
      width: 50% !important;
    }
    opacity: ${({ checked, changeOpacity, isDisabled }) =>
      !changeOpacity ? 1 : checked && !isDisabled ? 1 : 0.5};
  }
`;

const LabelInside = styled.div<{ checked?: boolean }>`
  height: 100%;
  width: 2.5rem;
  display: flex;
  font-size: 0.9rem;
  line-height: 1.4rem;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
`;
