import { TSaleListingStatusValue } from '../../../../../types';

export const statusOptionValuesMap: Record<TSaleListingStatusValue, string[]> =
  {
    all: ['all'],
    comingSoonAndActive: ['active', 'coming soon'],
    active: ['active'],
    comingSoon: ['coming soon'],
    activeAndUnderContractPending: [
      'active',
      'pending',
      'backup',
      'contingent',
    ],
    underContractPending: ['pending', 'backup', 'contingent'],
  };
