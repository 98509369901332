import { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useForgotPassword from './hooks/useForgotPassword';
import { Text, LinkText, ResendWrapper } from './ForgotPasswordForm.style';

const RESEND_STATUS_STATES = {
  READY_TO_BE_RESEND: 0,
  SENDING: 1,
  SENT: 2,
};

interface Props {
  setFormErrors: (error: string) => void;
}

export function ResendButton({ setFormErrors }: Props): JSX.Element {
  const { t } = useTranslation();
  const { sendVerificationCode } = useForgotPassword();

  const [resendStatus, setResendStatus] = useState(
    RESEND_STATUS_STATES.READY_TO_BE_RESEND,
  );

  const handleResend = useCallback(() => {
    if (resendStatus !== RESEND_STATUS_STATES.READY_TO_BE_RESEND) {
      return;
    }

    setResendStatus(RESEND_STATUS_STATES.SENDING);

    sendVerificationCode({
      onSuccess: () => {
        setResendStatus(RESEND_STATUS_STATES.SENT);
        setTimeout(() => {
          setResendStatus(RESEND_STATUS_STATES.READY_TO_BE_RESEND);
        }, 3000);
      },
      onError: (error) => {
        setFormErrors(error);
        setResendStatus(RESEND_STATUS_STATES.READY_TO_BE_RESEND);
      },
    });
  }, [resendStatus, sendVerificationCode, setFormErrors]);

  const resendButtonConfig = useMemo(
    () => ({
      [RESEND_STATUS_STATES.READY_TO_BE_RESEND]: (
        <LinkText onClick={handleResend}>{t('auth.resendCodeShort')}</LinkText>
      ),
      [RESEND_STATUS_STATES.SENDING]: <Text>{t('auth.sendingCode')}</Text>,
      [RESEND_STATUS_STATES.SENT]: <Text>{t('auth.codeWasSentShort')}</Text>,
    }),
    [handleResend, t],
  );

  return <ResendWrapper>{resendButtonConfig[resendStatus]}</ResendWrapper>;
}
