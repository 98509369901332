import styled from '@emotion/styled';

const Container = styled.span``;

const Currency = styled.span`
  font-size: 55%;
  vertical-align: super;
  line-height: 0;
`;

const Styled = {
  Container,
  Currency,
};
export default Styled;
