import styled from '@emotion/styled';

const RangeSliderContainer = styled.div`
  width: 100%;
`;

const Chart = styled.div`
  margin: 20px 0 0;
`;

export default {
  RangeSliderContainer,
  Chart,
};
