import React, { useCallback } from 'react';
import * as S from './RadioButton.style';

interface RadioButtonProps {
  dataQa?: string;
  label?: string;
  isSelected?: boolean;
  value: string;
  onChangeValue: (value: string) => void;
}

export const RadioButton: React.FC<RadioButtonProps> = ({
  dataQa,
  value,
  label,
  isSelected,
  onChangeValue,
}) => {
  const onToggle = useCallback(() => {
    onChangeValue(value);
  }, [value, onChangeValue]);

  return (
    <S.Container onClick={onToggle} data-qa={dataQa}>
      <S.Button isSelected={isSelected} />
      {!label ? null : <S.Label isSelected={isSelected}>{label}</S.Label>}
    </S.Container>
  );
};
