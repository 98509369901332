import { useMemo } from 'react';

import {
  Nav,
  Logo,
  Header,
  NavBar,
  NavbarBrand,
  BrandContainer,
} from './AppHeader.style';
import { useLogo } from '../../../hooks/useLogo';
import { NavItemList } from './components/NavItemList';
import { LoginButton } from './components/LoginButton';
import { useIsMobileView } from '../../../hooks/useIsMobileView';
import { usePreloadNotifications } from '../../NotificationPanel';
import { SideMenuCloseIcon } from './components/SideMenuCloseIcon';
import { useAgentBranding } from '../../../hooks/query/useAgentBranding';
import { SubNavigation } from '../components/SubNavigation/SubNavigation';
import { useHomeownerByContact } from '../../../hooks/useHomeownerByContact';
import { useAuthentication } from '../../../providers/Authentication/AuthenticationProvider';

export function AppHeaderNav(): JSX.Element {
  usePreloadNotifications();

  const isMobileView = useIsMobileView();
  const { isAuthenticated } = useAuthentication();

  const { isLoading: isLoadingHomeowner, data: homeownerDetail } =
    useHomeownerByContact();
  const { isLoading: isLoadingBrandingDetails } = useAgentBranding();

  const headerLogo = useLogo();

  const isLoading = useMemo(
    () =>
      isLoadingHomeowner ||
      isLoadingBrandingDetails ||
      !homeownerDetail?.homeownerId,
    [
      homeownerDetail?.homeownerId,
      isLoadingBrandingDetails,
      isLoadingHomeowner,
    ],
  );

  if (isLoading) {
    return <Header></Header>;
  }

  return (
    <Header>
      <NavBar>
        <BrandContainer isMobile={isMobileView}>
          <SideMenuCloseIcon />
          {headerLogo && (
            <NavbarBrand isMobile={isMobileView}>
              <Logo src={headerLogo} alt="logo" />
            </NavbarBrand>
          )}
        </BrandContainer>
        <Nav isMobile={isMobileView}>
          {isMobileView ? null : <NavItemList />}
          {!isAuthenticated && <LoginButton isLoading={isLoading} />}
        </Nav>
      </NavBar>
      {!isMobileView && <SubNavigation />}
    </Header>
  );
}
