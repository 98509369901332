import styled from '@emotion/styled';

import { colorOpacityApplier } from '../../../services/colorOpacityApplier';

interface Props {
  text: string;
  isActive: boolean;
  hasUnread: boolean;
  onClick: () => void;
  dataQa?: string;
}

const NotificationTab = ({
  isActive,
  text,
  onClick,
  hasUnread,
  dataQa,
}: Props): JSX.Element => {
  return (
    <Container data-qa={dataQa} isActive={isActive} onClick={onClick}>
      <Text hasUnread={hasUnread}>{text}</Text>
    </Container>
  );
};

export default NotificationTab;

const Container = styled.div<{ isActive: boolean }>`
  gap: 1.2rem;
  display: flex;
  padding: 1rem;
  cursor: pointer;
  min-width: 5.7rem;
  border-radius: 50px;
  justify-content: center;
  background: ${({ isActive, theme }) =>
    isActive
      ? colorOpacityApplier(theme.colors.branding.main, '0.2')
      : 'transparent'};
`;

const Text = styled.div<{ hasUnread: boolean }>`
  font-size: 1.2rem;
  font-weight: ${({ theme, hasUnread }) =>
    hasUnread ? theme.fontWeight.semibold : theme.fontWeight.medium};
`;
