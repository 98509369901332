import { useMemo } from 'react';

import { OverlayView } from '@react-google-maps/api';

import { MapContainer } from '../../common.style';
import listingMarker from '../../../icons/listingMarker';
import { CarouselDescription } from '../CarouselDescription';
import { useListingSession } from '../../../providers/ListingSessionProvider';
import { getCoordinates } from '../../../../../utils/getCoordinates';
import { StaticMap } from '../../../../../components/V2/StaticMap/StaticMap';

type Props = {
  hasLayer?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export function MapItem({ hasLayer, onClick }: Props): JSX.Element {
  const { listing } = useListingSession();

  const marker = useMemo(() => {
    const listingCoordinates = getCoordinates(listing);

    return [
      {
        isCentered: true,
        svgString: listingMarker,
        position: listingCoordinates,
        markerStyle: { width: '4rem' },
        mapPaneName: OverlayView.MARKER_LAYER,
      },
    ];
  }, [listing]);

  return (
    <MapContainer>
      <StaticMap markers={marker} />
      {hasLayer && (
        <>
          <CarouselDescription
            onClick={onClick}
            description={listing?.ListingSummary?.listingRemarks ?? ''}
          />
        </>
      )}
    </MapContainer>
  );
}
