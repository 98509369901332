import { ListingDetails } from '../../../../../../types';
import { getListingSizeSQFT } from '../../../../../../utils/getListingSizeSQFT';

export const UUID = 'size';

export function filter(
  listing: ListingDetails,
  value: { min?: number; max?: number },
): boolean {
  if (!value || (!value.min && !value.max)) {
    return true;
  }

  const min = Number(value?.min);
  const max = Number(value?.max);
  const size = getListingSizeSQFT(listing?.ListingSummary?.livingSpaceSize);

  if (min && max) {
    return size > min && size < max;
  }

  if (min) {
    return size > min;
  }

  return size < (max ?? 0);
}
