import { Api } from '../Api';
import { hopApi } from './configs';
import { AgentDetails } from '../../types';

class AgentAPIImp extends Api {
  async get(id?: number): Promise<AgentDetails> {
    if (!id) throw new Error('There is no Agent Id');

    const response = await this.client.get<AgentDetails>(`/agentDetail`);

    return response.data;
  }
}

export const AgentAPI = new AgentAPIImp(hopApi);
