import { ListingDetails } from '../types';

export const getListingPriceValue = (
  listing: ListingDetails,
): number | null => {
  const status = listing?.ListingSummary?.listingStatus;

  if (status === 'Sold') {
    return listing?.SalePrice?.saleAmount;
  }

  return listing?.ListingPrice?.listingPrice;
};
