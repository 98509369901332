import { useQuery, UseQueryResult } from 'react-query';

import api from '../../api';
import { apiRoutes } from '../../constants';
import { AccountDetails, FeatureFlags } from '../../types';

const fetchFeatureFlags = async (): Promise<FeatureFlags> => {
  const { data } = await api.get(apiRoutes.kvCoreFeatures.byAccountId());

  return data.reduce(
    (acc: FeatureFlags, curr: AccountDetails) => ({
      ...acc,
      [curr.feature]: { feature: curr.feature, enabledInd: curr.enabledInd },
    }),
    {},
  );
};

export const FEATURE_SERVICE_QUERY_KEY = 'accountFeatureFlags';

export function useAccountFeatureFlags(
  accountId: string,
): UseQueryResult<FeatureFlags> {
  return useQuery(
    [FEATURE_SERVICE_QUERY_KEY, accountId],
    () => fetchFeatureFlags(),
    {
      enabled: Boolean(accountId),
      refetchInterval: 10 * 60 * 1000,
      refetchIntervalInBackground: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: 60 * 60 * 1000,
    },
  );
}
