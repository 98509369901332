import styled from '@emotion/styled';

type WithSelected = {
  isSelected?: boolean;
};

export const Container = styled('div')({
  display: 'flex',
  cursor: 'pointer',
});

export const Button = styled('div')<WithSelected>(({ theme, isSelected }) => ({
  width: '23px',
  height: '23px',
  borderRadius: '50%',
  border: `1px solid ${isSelected ? theme.colorPrimary : theme.colors.black}`,
  cursor: 'pointer',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '&:after': {
    content: '""',
    display: isSelected ? 'block' : 'none',
    width: '13px',
    height: '13px',
    backgroundColor: theme.colorPrimary,
    borderRadius: '50%',
  },
}));

export const Label = styled('div')<WithSelected>(({ theme, isSelected }) => ({
  fontSize: theme.fontSize.mdRem,
  color: isSelected ? theme.colorPrimary : theme.colors.black,
  fontWeight: theme.fontWeight.regular,
  marginLeft: '5px',
}));
