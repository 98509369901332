import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Filter, ListingDetails } from '../../../types';
import { getSortedListings } from '../../../services/getSortedListings';
import { SearchFilterService } from './SearchFilterService';
import { useSearchParams } from '../../../providers/SearchParamsProvider';
import * as PropTypeFilter from '../components/FiltersDrawer/PropertyType';
import { useSearchListingSnapshot } from '../hooks/useSearchListingSnapshot';
import * as PriceRangeFilter from '../components/FiltersDrawer/PriceRangeFilter';
import * as LotSize from '../components/FiltersDrawer/SizeAndYearFilters/LotSize';
import * as AdditionalFilters from '../components/FiltersDrawer/AdditionalFilters';
import * as SaleTypeOptionsFilter from '../components/FiltersDrawer/SaleTypeFilter';
import * as YearBuilt from '../components/FiltersDrawer/SizeAndYearFilters/YearBuilt';
import * as SizeFilter from '../components/FiltersDrawer/SizeAndYearFilters/SizeFilter';
import * as MinimumBedrooms from '../components/FiltersDrawer/MinimumRoomsFilter/MinimumBedrooms';
import * as MinimumBathrooms from '../components/FiltersDrawer/MinimumRoomsFilter/MinimumBathrooms';
import { useAdBanners } from '../../../features/AdBanners/hooks/useAdBanner';

interface SearchFilteredListingsProviderInterface {
  listings: ListingDetails[];
  isLoading?: boolean;
  filterWithOverrideFilter: (
    filters: Record<string, Filter>,
  ) => ListingDetails[];
}
interface Loading {
  isLoading: boolean;
  isFirstPageLoaded: boolean;
}

const SearchFilteredListingsLoading = React.createContext<Loading | undefined>(
  undefined,
);

export function useSearchListingsLoading(): Loading {
  const context = React.useContext(SearchFilteredListingsLoading);

  if (context === undefined) {
    throw new Error(
      'useSearchListingsLoading cannot be used outside SearchFilteredListingsProvider!',
    );
  }

  return context;
}

interface Progress {
  percentage: number;
  noOfLoaded: number;
}

const SearchFilteredListingsProgress = React.createContext<
  Progress | undefined
>(undefined);

export function useSearchListingsProgress(): Progress {
  const context = React.useContext(SearchFilteredListingsProgress);

  if (context === undefined) {
    throw new Error(
      'useSearchListingsProgress cannot be used outside SearchFilteredListingsProvider!',
    );
  }

  return context;
}

const SearchCapedFilteredListings = React.createContext<
  SearchFilteredListingsProviderInterface | undefined
>(undefined);

export function useCapedSearchFilteredListings(): SearchFilteredListingsProviderInterface {
  const context = React.useContext(SearchCapedFilteredListings);

  if (context === undefined) {
    throw new Error(
      'useCapedSearchFilteredListings cannot be used outside SearchFilteredListingsProvider!',
    );
  }

  return context;
}

const SearchCapedFilteredListingsLength = React.createContext<
  number | undefined
>(undefined);

export function useCapedSearchFilteredListingsLength(): number {
  const context = React.useContext(SearchCapedFilteredListingsLength);

  if (context === undefined) {
    throw new Error(
      'useCapedSearchFilteredListingsLength cannot be used outside SearchFilteredListingsProvider!',
    );
  }

  return context;
}

const SearchCloseMatchListings = React.createContext<
  ListingDetails[] | undefined
>(undefined);

export function useCloseMatchListings(): ListingDetails[] {
  const context = React.useContext(SearchCloseMatchListings);

  if (context === undefined) {
    throw new Error(
      'useCloseMatchListings cannot be used outside SearchCloseMatchListingsProvider!',
    );
  }

  return context;
}

const SearchFilteredListingsContext = React.createContext<
  SearchFilteredListingsProviderInterface | undefined
>(undefined);

export const SearchFilteredListingsProvider: FC = ({ children }) => {
  const { hasAdBanners } = useAdBanners();
  const { params, get } = useSearchParams();
  const polygonId = get('polygonId');

  const {
    isLoading: isLoadingListings,
    data: rawListings,
    noOfLoaded,
    total,
  } = useSearchListingSnapshot();

  const [listings, setListings] = useState<ListingDetails[]>([]);
  const [closeMatches, setCloseMatches] = useState<ListingDetails[]>([]);

  const FilterService = useRef(new SearchFilterService());

  const setListingsAndSort = useCallback(
    function setListingsAndSort(listings?: ListingDetails[]) {
      if (!listings?.length) {
        return setListings([]);
      }
      const saleType = get('sale');
      const sortBasedOnSaleType =
        !saleType || saleType.type === 'sale'
          ? 'listDateNewest'
          : 'saleDateNewest';

      const sortValue = get('sort') ?? sortBasedOnSaleType;
      const sortedListings = getSortedListings(listings, sortValue);
      setListings(sortedListings);
    },
    [get],
  );

  const filterWithOverrideFilter = useCallback(
    function filterWithOverrideFilter(filters: Record<string, Filter>) {
      if (isLoadingListings || !rawListings?.length) {
        return [];
      }

      return FilterService.current.applyFiltersAndOverride(
        rawListings,
        params,
        filters,
      );
    },
    [isLoadingListings, params, rawListings],
  );

  const applyFilters = useCallback(
    ({ rawListings, params }) => {
      const { perfectMatches, closeMatches } =
        FilterService.current.applyFilters(rawListings ?? [], params);

      setListingsAndSort(perfectMatches);
      setCloseMatches(closeMatches);
    },
    [setListingsAndSort],
  );

  useEffect(() => {
    const FS = FilterService.current;

    FS.subscribe(SaleTypeOptionsFilter.UUID, SaleTypeOptionsFilter.filter);
    FS.subscribe(MinimumBedrooms.UUID, MinimumBedrooms.filter);
    FS.subscribe(AdditionalFilters.UUID, AdditionalFilters.filter);
    FS.subscribe(MinimumBathrooms.UUID, MinimumBathrooms.filter);
    FS.subscribe(SizeFilter.UUID, SizeFilter.filter);
    FS.subscribe(LotSize.UUID, LotSize.filter);
    FS.subscribe(YearBuilt.UUID, YearBuilt.filter);
    FS.subscribe(PropTypeFilter.UUID, PropTypeFilter.filter);
    FS.subscribe(PriceRangeFilter.UUID, PriceRangeFilter.filter);

    return () => {
      FS.unsubscribe(SaleTypeOptionsFilter.UUID);
      FS.unsubscribe(MinimumBedrooms.UUID);
      FS.unsubscribe(AdditionalFilters.UUID);
      FS.unsubscribe(MinimumBathrooms.UUID);
      FS.unsubscribe(SizeFilter.UUID);
      FS.unsubscribe(LotSize.UUID);
      FS.unsubscribe(YearBuilt.UUID);
      FS.unsubscribe(PropTypeFilter.UUID);
      FS.unsubscribe(PriceRangeFilter.UUID);
    };
  }, []);

  useEffect(() => setListings([]), [polygonId, isLoadingListings]);

  useEffect(() => {
    if (isLoadingListings || !rawListings?.length) return;

    applyFilters({
      rawListings,
      params,
    });
  }, [
    rawListings,
    params,
    isLoadingListings,
    setListingsAndSort,
    applyFilters,
  ]);

  useEffect(() => {
    const hasLoadedListings = Boolean(noOfLoaded);

    if (!isLoadingListings || !hasLoadedListings || Boolean(listings?.length)) {
      return;
    }

    applyFilters({
      rawListings,
      params,
    });
  }, [
    applyFilters,
    isLoadingListings,
    listings?.length,
    noOfLoaded,
    params,
    rawListings,
    setListingsAndSort,
  ]);

  const value = useMemo(
    () => ({
      filterWithOverrideFilter,
      listings,
    }),
    [filterWithOverrideFilter, listings],
  );

  const isLoading = isLoadingListings === undefined || isLoadingListings;
  const isFirstPageLoaded = noOfLoaded > 0;

  const loadingValue = useMemo(
    () => ({
      isLoading,
      isFirstPageLoaded,
    }),
    [isLoading, isFirstPageLoaded],
  );

  const progressValue = useMemo(
    () => ({
      percentage: Math.ceil((noOfLoaded / (total ?? 1)) * 100),
      noOfLoaded,
    }),
    [noOfLoaded, total],
  );

  const capedValue = useMemo(() => {
    const capedListings =
      value.listings?.length <= 350
        ? value.listings
        : value.listings.slice(0, 350);

    return {
      ...value,
      listings: capedListings,
      isLoading,
    };
  }, [isLoading, value]);

  const capedValueLength = useMemo(() => {
    let totalLenght = capedValue.listings?.length ?? 0;
    if (hasAdBanners) totalLenght = totalLenght + 1;

    return totalLenght;
  }, [capedValue.listings?.length, hasAdBanners]);

  return (
    <SearchFilteredListingsContext.Provider value={value}>
      <SearchFilteredListingsLoading.Provider value={loadingValue}>
        <SearchFilteredListingsProgress.Provider value={progressValue}>
          <SearchCapedFilteredListings.Provider value={capedValue}>
            <SearchCapedFilteredListingsLength.Provider
              value={capedValueLength}
            >
              <SearchCloseMatchListings.Provider value={closeMatches}>
                {children}
              </SearchCloseMatchListings.Provider>
            </SearchCapedFilteredListingsLength.Provider>
          </SearchCapedFilteredListings.Provider>
        </SearchFilteredListingsProgress.Provider>
      </SearchFilteredListingsLoading.Provider>
    </SearchFilteredListingsContext.Provider>
  );
};

export const useSearchFilteredListings =
  (): SearchFilteredListingsProviderInterface => {
    const context = React.useContext(SearchFilteredListingsContext);

    if (context === undefined) {
      throw new Error(
        'useListingsFilter cannot be used outside ListingsFilterProvider!',
      );
    }

    return context;
  };
