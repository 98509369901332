import styled from '@emotion/styled';
export const Input = styled('input')<{
  error?: boolean;
  disabled?: boolean;
  hasAndAdornment?: boolean;
  isRegularFontWeight?: boolean;
  isPlaceholderItalic?: boolean;
}>(
  ({
    error,
    hasAndAdornment,
    disabled,
    theme,
    isRegularFontWeight,
    isPlaceholderItalic,
  }) => ({
    '&:focus': {
      outline: 'none',
    },
    '::placeholder': {
      color: theme.colors.input.darkGrey,
      fontSize: '1.2rem',
      fontWeight: '400',
      fontStyle: isPlaceholderItalic ? 'italic' : 'normal',
    },
    width: '100%',
    height: '45px',
    padding: '15px 13px',
    background: '#fff',
    margin: '5px 0',
    border: '1px solid',
    borderRadius: '4px',
    fontSize: '1.4rem',
    fontWeight: isRegularFontWeight ? '400' : '800',
    backgroundColor: disabled
      ? theme.colors.input.lightGrey
      : theme.colors.white,
    color: disabled ? theme.colors.input.darkGrey : 'black',
    paddingRight: hasAndAdornment ? '35px' : '0',
    cursor: disabled ? 'not-allowed' : 'default',
    borderColor: error ? theme.colors.red : theme.colors.input.mediumGrey,
  }),
);

export const RequiredText = styled('p')<{ error?: boolean }>(
  ({ error, theme }) => ({
    fontSize: '1rem',
    paddingBottom: '5px',
    color: error ? theme.colors.red : theme.colors.input.darkGrey,
  }),
);

export const StyledInputWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
});

export const EndAdornmentWrapper = styled('div')<{ hasAndAdornment?: boolean }>(
  ({ hasAndAdornment }) => ({
    position: 'absolute',
    cursor: 'pointer',
    right: hasAndAdornment ? '10px' : 0,
  }),
);

export const Label = styled('div')<{ error?: boolean }>(({ error, theme }) => ({
  fontSize: '0.8rem',
  textTransform: 'uppercase',
  position: 'relative',
  color: error ? theme.colors.red : '#000',
}));

export const LabelContainer = styled('div')({
  height: '12px',
  position: 'absolute',
  top: 0,
  left: '13px',
  padding: '0 3px',
});

export const LabelMask = styled('div')({
  height: '6px',
  position: 'absolute',
  bottom: 1,
  left: 0,
  backgroundColor: '#fff',
  width: '100%',
});
