import { CSSProperties, useCallback } from 'react';
import { Location } from 'history';
import { Link, useHistory } from 'react-router-dom';
import styled from '@emotion/styled';

import {
  WithActive,
  INavGroupPaths,
  NavGroupSearchPath,
  NavGroupJourneyPath,
  NavGroupFavoritesPath,
  NavGroupeNotificationsPath,
} from '../../../types';
import { useNavActive } from '../../../utils/useNavActive';
import { useIsMobileView } from '../../../hooks/useIsMobileView';
import { colorOpacityApplier } from '../../../services/colorOpacityApplier';
import { useAuthorization } from '../../../providers/Authentication/useAuthorization';

type Props = {
  to?: string | Location;
  label?: string;
  dataQa?: string;
  active?: boolean;
  style?: CSSProperties;
  isGated?: boolean;
  isSubNav?: boolean;
  onClick?: () => void;
  navGroupPaths?:
    | INavGroupPaths
    | NavGroupSearchPath
    | NavGroupJourneyPath
    | NavGroupFavoritesPath
    | NavGroupeNotificationsPath;
  children?: React.ReactChildren | JSX.Element | JSX.Element[];
};

type WithIsSubNav = {
  isSubNav?: boolean;
};

export function IconNavLink({
  style,
  label,
  active,
  onClick,
  isGated,
  to = '/',
  children,
  isSubNav,
  navGroupPaths,
}: Props): JSX.Element {
  const isMobileView = useIsMobileView();

  const history = useHistory();
  const { gatedOnOpenLoginModal } = useAuthorization();
  const { isActiveNavGroup } = useNavActive(navGroupPaths);

  const onClickCb = useCallback(
    (e) => {
      if (!isGated) {
        onClick && onClick();
        return;
      }

      e.preventDefault();
      gatedOnOpenLoginModal(e, () => {
        onClick && onClick();
        history.push(to);
      });
      return;
    },
    [gatedOnOpenLoginModal, history, isGated, onClick, to],
  );

  return (
    <Container
      isSubNav={isSubNav}
      onClick={onClickCb}
      to={isGated ? '#' : to}
      isMobile={isMobileView}
      active={active || isActiveNavGroup}
    >
      <NavItem isMobile={isMobileView} style={style}>
        {children}
        <Label>{label}</Label>
      </NavItem>
    </Container>
  );
}

interface WithIsMobile {
  isMobile: boolean;
}

export const Container = styled(Link, {
  shouldForwardProp: (prop) =>
    !['active', 'isMobile', 'isSubNav'].includes(prop),
})<WithActive & WithIsMobile & WithIsSubNav>(({
  theme,
  active,
  isMobile,
  isSubNav,
}) => {
  const desktopIconColor = active
    ? theme.colors.grey.navIconInverted
    : theme.colors.black;

  const desktopHoveredIconColor = active
    ? theme.colors.grey.navIconInverted
    : theme.colors.branding.main;

  const desktopTextColor = theme.colors.black;

  const desktopHoverdTextColor = active
    ? theme.colors.black
    : theme.colors.branding.main;

  const desktopFontWeight = active
    ? theme.fontWeight.semibold
    : theme.fontWeight.regular;

  const mobileFontWeight = active
    ? theme.fontWeight.bold
    : theme.fontWeight.regular;

  const inactiveMobileColor = isSubNav
    ? theme.colors.black
    : theme.colors.grey.dark2;

  const activeMobileColor = isSubNav
    ? theme.colors.black
    : theme.colors.branding.main;

  const mobileTextColor = active ? activeMobileColor : inactiveMobileColor;

  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: isMobile ? '1.3rem' : '1.4rem',
    lineHeight: isMobile ? '2.4rem' : '1.92rem',
    color: isMobile ? mobileTextColor : desktopTextColor,
    paddingBottom: isMobile && isSubNav ? '0.5rem' : '0',
    borderBottom:
      isMobile && active && isSubNav
        ? `0.1rem solid ${theme.colors.branding.main}`
        : 'none',
    fontWeight: isMobile ? mobileFontWeight : desktopFontWeight,
    '.nav-icon path': {
      fill: desktopIconColor,
      color: desktopIconColor,
    },
    '&:hover': {
      textDecoration: active ? 'none' : 'underline',
      color: isMobile ? mobileTextColor : desktopHoverdTextColor,
      '.nav-icon': {
        boxShadow: active
          ? 'none'
          : `0rem 0.2rem 0.825rem ${colorOpacityApplier(
              theme.colors.black,
              '0.25',
            )}`,
      },
      '.nav-icon path': {
        fill: desktopHoveredIconColor,
        color: desktopHoveredIconColor,
      },
    },
  };
});

const NavItem = styled('li')<WithIsMobile>(({ isMobile }) => {
  return {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: isMobile ? 'column' : 'row',
  };
});

const Label = styled('span')({
  color: 'inherit',
  textTransform: 'none',
});
