import styled from '@emotion/styled';
import { theme } from '../../constants';

export const Container = styled.div`
  flex: 1 1 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const coloredBorder = (props) => {
  let color = theme(null).colors.font.primary;

  if (props && props.theme && props.theme.colors) {
    color = props.theme.colors.branding.main;
  }

  return `0.5em solid ${color}`;
};

export const LoadingIndicator = styled.div`
  &,
  &:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }

  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: ${coloredBorder};
  border-right: ${coloredBorder};
  border-bottom: ${coloredBorder};
  border-left: 0.5em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export default {
  Container,
  LoadingIndicator,
};
