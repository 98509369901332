import styled from '@emotion/styled';

type WithSelected = {
  isSelected: boolean;
};

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '5px',
  boxShadow: '1px 1px 2px #03050426',
});

export const ButtonsContainer = styled('div')({
  display: 'flex',
  borderRadius: '5px',
  boxShadow: '1px 1px 2px #03050426',
  overflow: 'hidden',
});

export const Button = styled('button')<WithSelected>(
  ({ theme, isSelected }) => ({
    border: 'none',
    backgroundColor: isSelected ? theme.colorPrimary : theme.colors.white,
    height: '4.8rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: theme.fontSize.mdRem,
    color: isSelected ? theme.colors.white : theme.colors.grey.dark2,
    flex: 1,
    '&:not(:last-of-type)': {
      borderRight: `1px solid ${theme.colors.grey.main}`,
    },
  }),
);
