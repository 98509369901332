// import { Auth } from 'aws-amplify';
import { useMutation, UseMutationResult } from 'react-query';

import { AUTH_KEY } from './const';
import { hopApi } from '../../api/hop';
import { useAuth } from './AuthProvider';
import { apiRoutes } from '../../constants';
import { useTokenData } from './useTokenData';
import { BrowserStorage } from '../../services/BrowserStorage';
import { MigrationConfig } from '../../services/MigrationConfig';

interface SignUpLoginPayload {
  email?: string;
  username: string;
  password: string;
  passwordConfirmation?: string;
  attributes: Record<string, string>;
}

const useHasInternalAuth = (): boolean => {
  return MigrationConfig.get().isMigrated;
};

const localStorage = new BrowserStorage();

export function useRegisterUser(): UseMutationResult<
  void,
  Error,
  SignUpLoginPayload
> {
  const hasInternalAuth = useHasInternalAuth();
  const { data } = useTokenData();

  return useMutation(
    async ({
      email,
      username,
      password,
      passwordConfirmation,
      attributes,
    }: SignUpLoginPayload) => {
      if (!hasInternalAuth) {
        // eslint-disable-next-line no-console
        console.log(username, attributes);
        // await Auth.signUp({ username, password, attributes });
        return;
      }

      const agentId = String(data?.agentId);

      await hopApi.post(apiRoutes.auth.register(), {
        email,
        agentId,
        password,
        passwordConfirmation,
      });
    },
  );
}

export function useLoginUser(): UseMutationResult<
  void,
  Error,
  SignUpLoginPayload
> {
  const { loadUser } = useAuth();
  const hasInternalAuth = useHasInternalAuth();

  return useMutation(
    async ({ password, username, attributes }: SignUpLoginPayload) => {
      if (!hasInternalAuth) {
        // eslint-disable-next-line no-console
        console.log(username, attributes);
        // await Auth.signIn(username, password, attributes);

        await loadUser();
        return;
      }

      const { data } = await hopApi.post(apiRoutes.auth.login(), {
        username,
        password,
      });

      localStorage.setItem(AUTH_KEY, data?.accessToken);
      await loadUser();
    },
  );
}

export function useSignOut(): UseMutationResult<void, Error, void> {
  const hasInternalAuth = useHasInternalAuth();

  return useMutation(async () => {
    if (!hasInternalAuth) {
      // await Auth.signOut();
      return;
    }

    localStorage.setItem(AUTH_KEY, null);
  });
}

type VerificationCodePayload = {
  username: string;
  metadata: {
    email: string;
    accountId: string;
    contactId: string;
    agentId: string;
  };
};

export function useSendVerificationCode(): UseMutationResult<
  void,
  Error,
  VerificationCodePayload
> {
  const hasIA = useHasInternalAuth();

  return useMutation(async ({ username }) => {
    if (!hasIA) {
      // await Auth.forgotPassword(username, metadata);
      return;
    }

    await hopApi.post(apiRoutes.auth.forgotPassword(), { username });
  });
}

type ResetPasswordPayload = {
  username: string;
  password: string;
  passwordConfirmation?: string;
  verificationCode: string;
};

export function useResetPassword(): UseMutationResult<
  void,
  Error,
  ResetPasswordPayload
> {
  const hasIA = useHasInternalAuth();

  return useMutation(
    async ({ username, password, passwordConfirmation, verificationCode }) => {
      if (!hasIA) {
        // await Auth.forgotPasswordSubmit(
        //   username,
        //   verificationCode || '',
        //   password || '',
        // );
        return;
      }

      await hopApi.post(apiRoutes.auth.resetPassword(), {
        username,
        password,
        passwordConfirmation,
        token: verificationCode,
      });
    },
  );
}

type UpdatePasswordPayload = {
  currentPassword: string;
  password: string;
  passwordConfirmation?: string;
};

export function useUpdatePassword(): UseMutationResult<
  void,
  Error,
  UpdatePasswordPayload
> {
  const hasIA = useHasInternalAuth();

  return useMutation(
    async ({ currentPassword, password, passwordConfirmation }) => {
      if (!hasIA) {
        // const user = await Auth.currentAuthenticatedUser();

        // await Auth.changePassword(user, currentPassword, password);
        return;
      }

      await hopApi.post(apiRoutes.auth.updatePassword(), {
        currentPassword,
        password,
        passwordConfirmation,
      });
    },
  );
}
