import { FC, useState } from 'react';

import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import {
  ErrorMessage,
  LoginModalTitle,
  LoginFormWrapper,
  LoginModalContentWrapper,
} from './SignInForm.styles';
import useSignIn from './useSignIn';
import { Input } from '../Input/Input';
import { Button } from '../../UI/Button';
import { errorTypes } from '../../../utils/errorTypes';
import PasswordInput from '../PasswordInput/PasswordInput';
import { useIsMobileView } from '../../../hooks/useIsMobileView';
import { useTrackPage, TrackPageTypes } from '../../../features/analytics';
import { useHomeownerByContact } from '../../../hooks/useHomeownerByContact';
import CallToActionFooter from '../../call-to-action-footer/call-to-action-footer';

const validationSchema = Yup.object().shape({
  email: Yup.string().required(errorTypes.REQUIRED).email(errorTypes.INVALID),
  password: Yup.string().required(errorTypes.REQUIRED),
});

type Props = {
  onSignIn: () => void;
  onForgotPass: () => void;
};

const SignInForm: FC<Props> = ({ onSignIn, onForgotPass }) => {
  const [formErrors, setFormErrors] = useState<string | null>(null);

  const { t } = useTranslation();
  const isMobile = useIsMobileView();
  const { onSubmit } = useSignIn({
    onSignIn,
    onError: setFormErrors,
  });

  useTrackPage(TrackPageTypes.login);

  const { data: homeownerDetail } = useHomeownerByContact();

  const initialValues = {
    email: homeownerDetail?.Communication?.emailPrimary,
    password: '',
  };

  return (
    <LoginModalContentWrapper isMobile={isMobile}>
      <LoginModalTitle>{t('auth.welcomeHome')}</LoginModalTitle>
      <LoginFormWrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            initialValues,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Input
                name="email"
                placeholder="Enter your email"
                defaultValue={initialValues.email}
                value={values.email}
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.email}
                disabled
              />
              <PasswordInput
                className="sentry-mask"
                name="password"
                value={values.password}
                placeholder="Enter your password"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.password}
              />

              <div style={{ margin: '50px 0 20px 0', textAlign: 'center' }}>
                {formErrors && <ErrorMessage>{formErrors}</ErrorMessage>}
                <Button
                  disabled={isSubmitting}
                  size="large"
                  type="submit"
                  shape="square"
                  style={{ width: '100%', marginTop: '10px' }}
                >
                  {t('auth.signIn')}
                </Button>
              </div>
            </form>
          )}
        </Formik>

        <CallToActionFooter
          text={t('auth.forgotPasswordLink')}
          onClick={onForgotPass}
        />
      </LoginFormWrapper>
    </LoginModalContentWrapper>
  );
};

export default SignInForm;
