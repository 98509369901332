import { useCallback, useMemo } from 'react';

const soldRange = [
  0, 10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000,
  110000, 120000, 130000, 140000, 150000, 160000, 170000, 180000, 190000,
  200000, 210000, 220000, 230000, 240000, 250000, 260000, 270000, 280000,
  290000, 300000, 310000, 320000, 330000, 340000, 350000, 360000, 370000,
  380000, 390000, 400000, 410000, 420000, 430000, 440000, 450000, 460000,
  470000, 480000, 490000, 500000, 525000, 550000, 575000, 600000, 625000,
  650000, 675000, 700000, 725000, 750000, 775000, 800000, 825000, 850000,
  875000, 900000, 925000, 950000, 975000, 1000000, 1250000, 1500000, 1750000,
  2000000, 2250000, 2500000, 2750000, 3000000, 3250000, 3500000, 3750000,
  4000000, 4250000, 4500000, 4750000, 5000000, 5500000, 6000000, 6500000,
  7000000, 7500000, 8000000, 9000000, 10000000,
];

const rentRange = [
  0, 250, 500, 750, 1000, 1250, 1500, 1750, 2000, 2250, 2500, 2750, 3000, 3250,
  3500, 3750, 4000, 4250, 4500, 4750, 5000,
];

const rangeStepsConfig: { [key: string]: number[] } = {
  sale: soldRange,
  sold: soldRange,
  rent: rentRange,
};

function getPriceRangeFullStepsMap(
  rangeSteps: number[],
): Record<number, number> {
  return rangeSteps.reduce((map, step) => ({ ...map, [step]: 0 }), {});
}

type ReturnType = {
  steps: Record<number, number>;
  getNoMinNoMaxSteps: (orderedPrices: number[]) => {
    noMin: number;
    noMax: number;
  };
  findClosestStep: (price: number, steps?: number[]) => number | undefined;
};

export const useRangeFilterSteps = (
  searchType: string = 'sale',
): ReturnType => {
  const rangeSteps = useMemo(
    () => rangeStepsConfig[searchType] || [],
    [searchType],
  );

  const steps = getPriceRangeFullStepsMap(rangeSteps);

  const findClosestStep = useCallback(
    (price: number, steps?: number[]) => {
      const stepsRange = steps ?? rangeSteps;

      const maxStepPrice = stepsRange[stepsRange.length - 1];

      if (price >= maxStepPrice) return maxStepPrice;

      return stepsRange.find(
        (stepPrice, i) =>
          (price < stepPrice && price > stepsRange[i - 1]) ||
          (price < stepPrice && !stepsRange[i - 1]),
      );
    },
    [rangeSteps],
  );

  const getNoMinNoMaxSteps = useCallback(
    (orderedPrices: number[]) => {
      const minStep = orderedPrices[0] ?? 0;
      const maxStep = orderedPrices[(orderedPrices?.length ?? 0) - 1] ?? 0;

      const minStepIndex = rangeSteps.findIndex((step) => step === minStep);
      const maxStepIndex = rangeSteps.findIndex((step) => step === maxStep);

      const maxScaleStep = rangeSteps[rangeSteps.length - 1];

      const noMin = minStepIndex > 0 ? rangeSteps[minStepIndex - 1] : -1;
      const noMax =
        rangeSteps[maxStepIndex] !== maxScaleStep
          ? rangeSteps[maxStepIndex + 1]
          : maxScaleStep + 1;

      return { noMin, noMax };
    },
    [rangeSteps],
  );

  return {
    steps,
    findClosestStep,
    getNoMinNoMaxSteps,
  };
};
