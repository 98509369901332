import { AdConfig } from '../types';
import {
  accountAdsConfig,
  credibleAdsConfig,
} from '../config/accountAdsConfig';
import { AgentDetails, Office } from '../../../types';
import { ForcedAdState } from '../utility/ForcedAdState';
import { ACCOUNT_GROUP } from '../../../constants/accountGroup';
import { useAgentDetails } from '../../../hooks/query/useAgentDetails';
import { FEATURES, useHasFeatureFlag } from '../../../hooks/useFeatureFlags';
import { useTokenData } from '../../../providers/Authentication/useTokenData';
import { useAccountDetails } from '../../../providers/Authentication/useAccountDetails';

function isVisibleByState(bannersData: AdConfig, agent?: AgentDetails) {
  if (!bannersData || !agent) return false;

  if (!bannersData.showForStates) return true;

  const isShownByAgentState =
    agent?.Office?.some(
      (office: Office) =>
        bannersData.showForStates?.includes(office.Address.countrySubd || '') ||
        bannersData.showForStates?.includes(ForcedAdState.office()),
    ) ?? false;

  return isShownByAgentState;
}

function isHiddenByState(bannersData: AdConfig, agent?: AgentDetails) {
  if (!bannersData || !agent) return false;

  if (!bannersData.hideForStates) return false;

  const isShownByAgentState =
    agent?.Office?.some(
      (office: Office) =>
        bannersData.hideForStates?.includes(office.Address.countrySubd || '') ||
        bannersData.hideForStates?.includes(ForcedAdState.office()),
    ) ?? false;

  return isShownByAgentState;
}

function isBlockedForAccount(account: number): boolean {
  return [...ACCOUNT_GROUP.KEYES, ...ACCOUNT_GROUP.C21EDGE].includes(account);
}

export const useAdBanners = () => {
  const { data: agent } = useAgentDetails();
  const { data: tokenData } = useTokenData();
  const { data: accountData } = useAccountDetails();

  const universalAppUser = accountData?.isGeneric;
  const hasFF = useHasFeatureFlag(FEATURES.HopWebAds);

  const accountId = ForcedAdState.accountId() || tokenData?.accountId || 0;
  const bannersData = accountAdsConfig[accountId];

  const hasCredibleAdBanners =
    !universalAppUser && !isBlockedForAccount(accountId);
  const credibleBannersData = hasCredibleAdBanners ? credibleAdsConfig : null;

  const hiddenByState = isHiddenByState(bannersData, agent);
  const visibleByState = isVisibleByState(bannersData, agent);

  const hasAdBanners =
    hasFF && Boolean(bannersData) && visibleByState && !hiddenByState;

  return {
    hasAdBanners,
    bannersData,
    credibleBannersData,
    hasCredibleAdBanners,
  };
};
