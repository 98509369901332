import styled from '@emotion/styled';

import { Button as BaseButton } from '../../UI/Button';
import { WithIsMobile } from '../../../types';

interface WithMarginTop {
  marginTop?: string;
}

interface WithVisible {
  visible?: boolean;
}

export const Container = styled.div<WithIsMobile>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '55rem')};
  padding: 5.4rem 10rem 0;
  @media (min-width: ${({ theme }) => theme.breakpoint.sm.min}) {
    padding: 5.4rem 2rem 0;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.lg.min}) {
    padding: 5.4rem 6rem 0;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.md.min}) {
    padding: 5.4rem 6rem 0;
  }
`;

export const Text = styled('span')<WithMarginTop>(({ marginTop }) => ({
  display: 'inline-block',
  width: '100%',
  fontSize: '1.6rem',
  textAlign: 'center',
  marginTop: marginTop ?? '1.76rem',
}));

export const ErrorMessage = styled(Text)(({ theme }) => ({
  color: theme.colors.red,
}));

export const RadioGroup = styled.div`
  width: 40%;
  margin: 3rem auto 0;
  display: flex;
  justify-content: space-between;
`;

export const ModalFooter = styled('div')<WithMarginTop>(({ marginTop }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: marginTop ?? '2rem',
  justifyContent: 'space-between',
}));

export const FormContainerColumn = styled('form')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});

export const FormContainerRow = styled('form')({
  display: 'flex',
  alignItems: 'center',
});

export const CenteredRow = styled('div')<WithMarginTop>(({ marginTop }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: marginTop ?? '0',
}));

export const ImgIcon = styled('img')({
  width: '4.6rem',
});

export const Button = styled(BaseButton)<WithVisible>(({ visible = true }) => ({
  width: '12rem',
  height: 'unset',
  paddingTop: '1.4rem',
  paddingBottom: '1.4rem',
  visibility: visible ? 'visible' : 'hidden',
}));
