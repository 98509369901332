import { FC, useMemo, useCallback, useState } from 'react';

import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { FieldArray, Formik, FormikValues } from 'formik';

import {
  Text,
  ImgIcon,
  Container,
  ErrorMessage,
  FormContainerRow,
  FormContainerColumn,
} from '../RegistrationSteps.style';
import { Resend } from './components/Resend';
import { Email } from './VerifyAccount.style';
import { useWizard } from '../../Wizard/Wizard';
import { Spinner } from '../../Loaders/Spinner';
import CheckMarkIcon from './icons/checkMarkIcon';
import { CodeInput } from './components/CodeInput';
import { verifyCode } from './hooks/useVerifyCode';
import ModalHeader from '../../Modal/header/ModalHeader';
import { useIsMobileView } from '../../../../hooks/useIsMobileView';
import { svgStringToDataUrl } from '../../../../services/svgStringToDataUrl';
import { useHomeownerByContact } from '../../../../hooks/useHomeownerByContact';

interface FormValues {
  code: string[];
}

const initialValues = {
  code: [],
};

export const VerifyAccount: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { nextStep } = useWizard();
  const isMobile = useIsMobileView();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { data: homeownerDetail, isLoading: isHomeownerLoading } =
    useHomeownerByContact();

  const imgSrc = useMemo(
    () => svgStringToDataUrl(CheckMarkIcon, theme.colors.branding.main),
    [theme],
  );

  const email = useMemo(() => {
    if (isHomeownerLoading) return '';

    return (
      homeownerDetail?.Communication.emailPrimary ||
      homeownerDetail?.Communication.emailSecondary
    );
  }, [
    isHomeownerLoading,
    homeownerDetail?.Communication.emailPrimary,
    homeownerDetail?.Communication.emailSecondary,
  ]);

  const onSubmit = useCallback(
    async ({ code }: FormValues, { setValues }: FormikValues) => {
      const verificationCode = code.join('');

      try {
        setIsLoading(true);
        setErrorMessage('');

        const status = await verifyCode(verificationCode);

        setIsLoading(false);

        setValues(initialValues);

        if (status.verificationInd) {
          nextStep();
          return;
        }
        setValues(initialValues);
        throw new Error(t('auth.verifyAccount.invalidCode'));
      } catch (e: unknown) {
        setValues(initialValues);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setErrorMessage(e.message);
      }
    },
    [nextStep, t],
  );

  return (
    <Container isMobile={isMobile}>
      {isHomeownerLoading ? (
        <Spinner size={5} />
      ) : (
        <FormContainerColumn>
          <ModalHeader
            icon={<ImgIcon src={imgSrc} />}
            title={t('auth.verifyAccount.title')}
          />

          <Text>{t('auth.verifyAccount.subtitle')}</Text>
          <Email>{email}</Email>
          <Formik
            enableReinitialize
            onSubmit={onSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={initialValues}
          >
            {() => (
              <FormContainerRow>
                <FieldArray name="code">
                  {() => <CodeInput codeLength={6} />}
                </FieldArray>
              </FormContainerRow>
            )}
          </Formik>
          {
            <ErrorMessage>
              {errorMessage}
              {isLoading && <Spinner size={2} />}
            </ErrorMessage>
          }
          <Resend />
        </FormContainerColumn>
      )}
    </Container>
  );
};
