import axios from 'axios';
import { unauthorizedEvent } from '../../constants/customEvents';

declare module 'axios' {
  export interface AxiosRequestConfig {
    raw?: boolean;
  }
}

export const hopApi = axios.create({
  baseURL: import.meta.env.VITE_BASE_API_URL,
});

hopApi.defaults.headers.common['X-Source'] = 'web';

export const setHopApiAuthToken = (token: string): void => {
  hopApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const setHopAccountIdHeader = (accountId: number): void => {
  hopApi.defaults.headers.common['account-id'] = accountId || '';
};

hopApi.interceptors.response.use(
  (response) => {
    if (response.config.raw) return Promise.resolve(response);

    const data = response.data?.data;

    return Promise.resolve({
      ...response,
      data,
    });
  },
  (error) => {
    if (String(error?.response?.status) === '401') {
      window.dispatchEvent(unauthorizedEvent);
    }

    throw error.response?.data?.data;
  },
);
