import styled from '@emotion/styled';

import { Button as BaseButton } from '../UI/Button';
import GlobalStyle from '../global-style/global-style.style';

const BackgroundContainer = styled.div`
  flex: 1 1 100%;
  background: ${(props) => props.theme.colorPrimary};
  margin-bottom: -75px;

  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: ${({ theme }) => theme.breakpoint.md.min}) {
    align-items: flex-start;
    padding-top: 150px;
    padding-left: 80px;
    padding-right: 80px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.lg.max}) {
    padding-left: 150px;
    padding-right: 150px;
  }
`;

const Container = styled(GlobalStyle.CardContainer)`
  color: white;
`;

const Content = styled.div``;

const Title = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.primaryBold};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 32px;
`;

const Subtitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.primaryBold};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 16px;
  margin-bottom: 22px;
`;

const Message = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.light};
  font-size: 10px;
  line-height: 12px;
  max-width: 450px;
  margin-bottom: 34px;
`;

const Button = styled(BaseButton)`
  font-size: 14px !important;
`;

const Styled = {
  BackgroundContainer,
  Container,
  Content,
  Title,
  Subtitle,
  Message,
  Button,
};

export default Styled;
