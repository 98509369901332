import { FC, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { FormikValues, useFormikContext } from 'formik';

import { Input } from '../../../Input/Input';
import { useOptionalProps } from '../hooks/useOptionalProps';
import { useWindowWidth } from '../../../../../hooks/useWindowSize';

interface Props {
  hasInitialValue: boolean;
}

export const FirstName: FC<Props> = ({ hasInitialValue }) => {
  const { t } = useTranslation();
  const screenSize = useWindowWidth();

  const { errors, values, handleBlur, handleChange } =
    useFormikContext<FormikValues>();

  const optionalProps = useOptionalProps(hasInitialValue);

  const error = useMemo(() => {
    const isEmpty =
      !values.firstName && (values.lastName || values.phoneNumber);

    if (isEmpty) return ' ';

    return String(errors?.firstName ?? '');
  }, [values.lastName, values.firstName, errors.firstName, values.phoneNumber]);

  return (
    <Input
      required
      type="text"
      error={error}
      focusOnIconClick
      name="firstName"
      onBlur={handleBlur}
      style={{
        width: screenSize < 577 ? '30rem' : '35rem',
        marginTop: '1.3rem',
      }}
      onChange={handleChange}
      value={values.firstName}
      placeholder={t('auth.profileCreation.inputLabels.firstName')}
      {...optionalProps}
    />
  );
};
