import styled from '@emotion/styled';

import { Button as PrimaryButton } from '../../../UI/Button';
import { Layer } from '../../../../types';

type WithInSideMenu = {
  inSideMenu?: boolean;
};

const Container = styled('div')<WithInSideMenu>(({ inSideMenu }) => {
  let additionalStyle = {};

  if (!inSideMenu) {
    additionalStyle = {
      ...additionalStyle,
      position: 'fixed',
      bottom: 0,
      overflow: 'hidden',
      height: 160,
    };
  }

  return {
    ...additionalStyle,
    display: 'flex',
    flexShrink: 0,
    width: inSideMenu ? '100%' : '100vw',
    flexDirection: inSideMenu ? 'column' : 'row',
    marginTop: '4rem',
  };
});

const BrandingSection = styled('div')<WithInSideMenu>(
  ({ theme, inSideMenu }) => ({
    flex: inSideMenu ? 0 : 1,
    background: theme.colors.branding.main,
    position: 'relative',
    display: 'flex',
    flexDirection: inSideMenu ? 'column' : 'row',
    padding: '2rem',
  }),
);

const ContentWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const LogoWrapper = styled('div')({
  background: '#fff',
  width: 97,
  height: 97,
  flexShrink: 0,
  borderRadius: 8,
  marginRight: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '@media all and (max-width: 910px)': {
    display: 'none',
  },
});

const Image = styled('img')({
  width: '95%',
});

const TextWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  color: '#fff',
  paddingRight: 10,
});

const CompanyName = styled('span')<WithInSideMenu>(({ inSideMenu }) => ({
  fontSize: inSideMenu ? 18 : 30,
  fontWeight: 600,
  marginBottom: 10,
  '@media all and (max-width: 1080px)': {
    fontSize: 24,
  },
  '@media all and (max-width: 1038px)': {
    marginBottom: 0,
  },
}));

const Text = styled('span')<WithInSideMenu>(({ inSideMenu }) => ({
  fontWeight: 300,
  fontSize: inSideMenu ? 14 : 18,
  maxWidth: 500,
  '@media all and (max-width: 1080px)': {
    fontSize: 16,
  },
}));

const Square = styled('div')<WithInSideMenu>(({ theme, inSideMenu }) => {
  const positionParams = inSideMenu
    ? { right: '50%', bottom: 30 }
    : { right: 0, top: -56 };
  return {
    background: theme.colors.branding.main,
    height: inSideMenu ? 110 : 180,
    width: inSideMenu ? 110 : 180,
    transform: 'rotate(45deg) translateX(40%)',
    position: 'absolute',
    zIndex: Layer.Default - 1,
    ...positionParams,
  };
});

const QRSection = styled('div')<WithInSideMenu>(({ inSideMenu }) => ({
  flexShrink: 0,
  marginLeft: 'auto',
  background: '#F4F3F4',
  width: inSideMenu ? '100%' : '25%',
  position: 'relative',
  display: 'flex',
  minWidth: 310,
  padding: inSideMenu ? '3rem' : 0,

  '& canvas': {
    background: 'transparent',
  },
}));

const CloseIcon = styled('div')({
  position: 'absolute',
  top: 12,
  right: 12,
  fontSize: 18,
  cursor: 'pointer',
});

const QRContent = styled('div')<WithInSideMenu>(({ inSideMenu }) => ({
  display: inSideMenu ? 'flex' : 'grid',
  margin: '0 25px 0 auto',
  justifyContent: 'center',
  paddingTop: 8,
  alignItems: 'center',
  flexDirection: 'row',
  '& p': {
    fontSize: 12,
    textAlign: 'center',
    maxWidth: 250,
    lineHeight: 1.1,
    marginTop: -8,
    fontWeight: 300,
  },
  '& canvas': {
    margin: '0 auto',
  },
}));

const AgentWrapper = styled('div')<WithInSideMenu>(({ inSideMenu }) => {
  let margin = {};

  if (!inSideMenu) {
    margin = { marginLeft: 'auto' };
  }

  return {
    ...margin,
    display: 'flex',
    flexDirection: inSideMenu ? 'row' : 'column',
    alignItems: 'center',
    justifyContent: inSideMenu ? 'start' : 'center',
    gap: 20,
    paddingTop: 10,
    color: '#fff',
  };
});

const Button = styled(PrimaryButton)<WithInSideMenu>(({ inSideMenu }) => ({
  height: inSideMenu ? 40 : 50,
  width: inSideMenu ? 150 : 210,
  borderRadius: 100,
  border: '1px solid #fff',
  fontSize: inSideMenu ? 10 : 13,

  '&:hover': {
    border: 'none',
  },
}));

export default {
  Text,
  Image,
  Button,
  Square,
  Container,
  QRSection,
  CloseIcon,
  QRContent,
  LogoWrapper,
  TextWrapper,
  CompanyName,
  AgentWrapper,
  ContentWrapper,
  BrandingSection,
};
