import qs from 'qs';
import { useMemo } from 'react';
import { useQuery, UseQueryResult } from 'react-query';

import {
  GeoPoint,
  ListingDetails,
  MlsDetails,
  PropTypeCategory,
  TSimilarHomesCriteria,
} from '../../../types';
import api from '../../../api';
import { apiRoutes } from '../../../constants';
import { useMlsDetails } from '../../../hooks/query/useMlsDetails';
import { useHomeownerPropertyCoordinates } from './useHomeownerPropertyCoordinates';
import { useHomeownerPropertyDetails } from '../../../hooks/query/useHomeownerPropertyDetails';

type Params = {
  filterBy: 'radius';
  radiusUnit: string;
  geoPoint: GeoPoint;
  radius: number;
  minBeds: number;
  maxBeds: number;
  minPropertySize: number;
  maxPropertySize: number;
  propTypeId: string;
  mlsId?: string;
  minSaleDate: number;
  maxSaleDate: number;
  orderBy?: string;
  order?: string;
};

type SHParams = {
  coordinates: GeoPoint;
  minBeds: number;
  maxBeds: number;
  maxPropertySize: number;
  minPropertySize: number;
  radius: number;
  mlsIds: string[];
  propertyTypeIds: number[];
};

function getPropTypeIdsByCategory(category?: PropTypeCategory): number[] {
  const SF_IDS = [1, 35, 46];
  if (!category) return SF_IDS;

  const propTypeCategoryIds = {
    [PropTypeCategory.SINGLE_FAMILY]: SF_IDS,
    [PropTypeCategory.CONDO]: [2, 11, 38, 43, 31],
    [PropTypeCategory.MULTI_UNIT]: [3, 10, 53],
  };

  return propTypeCategoryIds[category];
}

const KEY = 'similarHomes';

async function fetchSimilarHomes({
  coordinates,
  minBeds,
  maxBeds,
  minPropertySize,
  maxPropertySize,
  radius,
  mlsIds,
  propertyTypeIds,
}: SHParams): Promise<ListingDetails[] | undefined> {
  const { lat, lon } = coordinates;

  if (!lat || !lon) return;

  const params: Params = {
    filterBy: 'radius',
    radiusUnit: 'miles',
    geoPoint: { lat, lon },
    radius,
    minBeds,
    maxBeds,
    minPropertySize,
    maxPropertySize,
    propTypeId: propertyTypeIds?.join(','),
    mlsId: mlsIds?.join(',') ?? '',
    minSaleDate:
      new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getTime() /
      1000,
    maxSaleDate: new Date().getTime() / 1000,
    orderBy: 'listingdate',
    order: 'desc',
  };

  const { data } = await api.get(
    apiRoutes.listingSnapshot.get() + '?' + qs.stringify(params),
  );

  const result = data?.Listing || [];

  // TODO create listing mapper and apply globally
  return (result as ListingDetails[]).map((listing) => ({
    ...listing,
    uuid: `${listing.MlsInfo.mlsId}-${listing.mlsListingId}`,
  }));
}

export function useSimilarHomesQuery(
  similarHomesCriteria?: TSimilarHomesCriteria,
): UseQueryResult<ListingDetails[]> {
  const minBeds = Number(similarHomesCriteria?.minBeds);
  const maxBeds = Number(similarHomesCriteria?.maxBeds);
  const minPropertySize = Number(similarHomesCriteria?.minPropertySize);
  const maxPropertySize = Number(similarHomesCriteria?.maxPropertySize);
  const radius = Number(similarHomesCriteria?.range);
  const { data: homeownerProperty } = useHomeownerPropertyDetails();
  const { data: mlsIdsData, isLoading: isLoadingMlsData } = useMlsDetails();

  const geoPoint = useHomeownerPropertyCoordinates();

  const mlsIds = useMemo(
    () => mlsIdsData?.map((mls: MlsDetails) => String(mls.mlsId)) ?? [],
    [mlsIdsData],
  );

  const propertyTypeIds = getPropTypeIdsByCategory(
    homeownerProperty?.PropertySummary?.propertyTypeCategory ||
      PropTypeCategory.SINGLE_FAMILY,
  );

  return useQuery(
    [
      KEY,
      geoPoint.lat,
      geoPoint.lon,
      minBeds,
      maxBeds,
      minPropertySize,
      maxPropertySize,
      radius,
      homeownerProperty?.PropertySummary?.propertyTypeCategory,
    ],
    () =>
      fetchSimilarHomes({
        coordinates: geoPoint,
        minBeds,
        maxBeds,
        minPropertySize,
        maxPropertySize,
        radius,
        mlsIds,
        propertyTypeIds,
      }),
    {
      enabled:
        !isLoadingMlsData &&
        Boolean(homeownerProperty?.propertyUuid) &&
        Boolean(similarHomesCriteria),
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );
}
