import * as Sentry from '@sentry/react';
import { useQuery, UseQueryResult } from 'react-query';

import { hopApi } from '../../api/hop';
import { apiRoutes } from '../../constants';
import { MigrationConfig } from '../../services/MigrationConfig';

interface TToken {
  expiresIn: string;
  idToken: string;
}

const fetchToken = async (key?: string): Promise<TToken | null> => {
  if (!key) return null;

  const isMigrated = MigrationConfig.get().isMigrated;

  if (!isMigrated) {
    Sentry.captureMessage(`Info: Using OLD anon auth token.`, 'info');
  }

  const url = isMigrated ? apiRoutes.auth.tokenNew() : apiRoutes.auth.token();

  const res = await hopApi.post(url, { key });

  return res.data;
};

export function useUnauthToken(key?: string): UseQueryResult<TToken | null> {
  return useQuery(['token', key], () => fetchToken(key), {
    retry: 2,
    enabled: Boolean(key),
    refetchOnWindowFocus: false,
  });
}
