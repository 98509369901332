import 'core-js/actual/array/flat-map';
import 'core-js/proposals/object-from-entries';

import ReactDOM from 'react-dom';

import { RecoilRoot } from 'recoil';
import * as Sentry from '@sentry/react';
import { CookiesProvider } from 'react-cookie';
import { BrowserTracing, Dedupe } from '@sentry/browser';
import { ReactQueryDevtools } from 'react-query/devtools';

import { QueryClientProvider, QueryClient } from 'react-query';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import App from './components/app';

import './index.css';
import './styles/main.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import './i18n';
import { routerPaths } from './constants';
import { FAQPage } from './pages/FAQ/FAQPage';
import { BASENAME } from './constants/basename';
import { STATIC_CONFIG } from './constants/config';
import { Environment } from './services/Environment';
import AppLoading from './components/app-loading/app-loading.jsx';

import { GoogleMapProvider } from './utils/google-map-provider.jsx';
import { SearchParamsProvider } from './providers/SearchParamsProvider';
import { Authentication } from './providers/Authentication/Authentication';
import MobileAppDeeplink from './pages/MobileAppDeeplink/MobileAppDeeplink';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  },
});

const AppWithAuthFlow = () => {
  return (
    <Authentication loader={<AppLoading />}>
      <App />
    </Authentication>
  );
};

const MobileAppRouter = () => {
  return (
    <Switch>
      <Route path={routerPaths.faqMobile} component={FAQPage} />
      <Route path="*" component={MobileAppDeeplink} />
    </Switch>
  );
};

const version = import.meta.env.VITE_RELEASE;
console.log('Version: ', version);

Environment.init(import.meta.env.VITE_STAGE);

if (Environment.isTrackable()) {
  Sentry.init({
    dsn: STATIC_CONFIG.SENTRY_DNS,
    integrations: [
      new BrowserTracing(),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: true,
      }),
      new Dedupe(),
    ],
    ignoreErrors: [
      /Non-Error promise rejection captured with value: Object Not Found Matching Id:\d+, MethodName:\w+, ParamCount:\d+/,
    ],
    tracesSampleRate: 0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: import.meta.env.VITE_STAGE,
    release: version,
  });
}

ReactDOM.render(
  <RecoilRoot>
    <Router
      basename={BASENAME}
      getUserConfirmation={() => {
        /* Empty callback to block the default browser prompt */
      }}
    >
      <QueryClientProvider client={queryClient}>
        <CookiesProvider>
          <GoogleMapProvider>
            <SearchParamsProvider>
              <Switch>
                <Route
                  path={[
                    routerPaths.mobileAppDeeplinkDot,
                    routerPaths.mobileAppDeeplink,
                    routerPaths.faqMobile,
                  ]}
                  component={MobileAppRouter}
                />
                <Route path="*" component={AppWithAuthFlow} />
              </Switch>
            </SearchParamsProvider>
          </GoogleMapProvider>
        </CookiesProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Router>
  </RecoilRoot>,
  document.getElementById('root'),
);
