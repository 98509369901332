import { useTheme } from '@emotion/react';

export function UnreadIndicatorIcon(): JSX.Element {
  const theme = useTheme();
  const brandingColor = theme.colors.branding.main;

  return (
    <svg
      width="12"
      height="13"
      fill="none"
      viewBox="0 0 12 13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle id="Ellipse 378" cx="6" cy="6.5" r="6" fill={brandingColor} />
    </svg>
  );
}
