import { useState, useEffect, CSSProperties } from 'react';

import { AddressWithCoordinates, Address } from '../../../types';
import { handleAddressValidation } from '../../../utils/addressHelperFn';
import { AddressAutocomplete } from '../AddressAutocomplete/AddressAutocomplete';

import { useGoogleMap } from '../../../utils/google-map-provider';

import { useHomeownerByContact } from '../../../hooks/useHomeownerByContact';

type Props = {
  label?: string;
  error?: string;
  style?: CSSProperties;
  placeholder?: string;
  initialAddress?: Address;
  handleIsValidAddress?: (isValid: boolean) => void;
  handleAddressChange: (value: AddressWithCoordinates | null) => void;
  handleErrorState?: (value: { exist: boolean; msg: string }) => void;
};

export const AddressInput = ({
  style,
  label,
  error,
  placeholder,
  initialAddress,
  handleAddressChange,
  handleIsValidAddress,
  handleErrorState = (f) => f,
}: Props): JSX.Element => {
  const { mapIsLoaded } = useGoogleMap();
  const { data: homeownerDetails } = useHomeownerByContact();

  const [input, setInput] = useState(initialAddress?.fullAddress || '');

  const [place, setPlace] = useState<google.maps.places.PlaceResult | null>(
    null,
  );
  const [address, setAddress] = useState<Address | null>(
    homeownerDetails?.Address || null,
  );

  useEffect(() => {
    if (!place) {
      handleIsValidAddress?.(false);
      if (input !== initialAddress?.fullAddress) {
        handleErrorState({
          exist: true,
          msg: '',
        });
      }
      return;
    }

    handleAddressValidation(place, input, handleErrorState);
  }, [
    input,
    place,
    handleErrorState,
    handleIsValidAddress,
    initialAddress?.fullAddress,
  ]);

  useEffect(() => {
    setInput(homeownerDetails?.Address?.fullAddress || '');
  }, [homeownerDetails?.Address?.fullAddress]);

  useEffect(() => {
    handleAddressChange(address);
    handleIsValidAddress?.(Boolean(address));
  }, [address, handleAddressChange, handleIsValidAddress]);

  if (!mapIsLoaded) {
    return <></>;
  }

  return (
    <AddressAutocomplete
      style={style}
      setAddress={setAddress}
      setPlace={setPlace}
      inputValue={input}
      setInputValue={(value) => {
        setInput(value);
      }}
      initValue={initialAddress?.fullAddress || ''}
      label={label}
      placeholder={placeholder}
      error={error || ''}
      inputStyles={{
        fontWeight: 500,
        height: '2.5rem',
        fontSize: '1.4rem',
        backgroundColor: '#fff',
      }}
      blockScrollFor={['.side-menu-paper', '.registration-form-scroll-lock']}
    />
  );
};
