import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Note,
  Title,
  Subtitle,
  ErrorPage,
  ImageWrapper,
  ButtonWrapper,
} from '../ErrorPageLayout.style';
import { Button } from '../../../components/UI/Button';
import useBreakpoint from '../../../utils/useBreakpoint';
import { breakpoint as bp } from '../../../constants/theme';
import kitchenImage from '/img/kitchen-remodelling.svg?url';

export const UnexpectedError: FC = () => {
  const { t } = useTranslation();
  const { breakpoint } = useBreakpoint();

  const onRefresh = useCallback(() => window.location.reload(), []);

  return (
    <ErrorPage>
      <ImageWrapper width={breakpoint < parseInt(bp.lg.max) ? '50%' : '500px'}>
        <img src={kitchenImage} alt="kitchen" />
      </ImageWrapper>
      <Title>{t('errorPages.unexpectedError.heading')}</Title>
      <Subtitle>{t('errorPages.unexpectedError.subheading')}</Subtitle>
      <ButtonWrapper>
        <Button size="small" variant="outlined" onClick={onRefresh}>
          {t('errorPages.unexpectedError.refreshButton')}
        </Button>
      </ButtonWrapper>
      <Note>{t('errorPages.unexpectedError.note')}</Note>
    </ErrorPage>
  );
};
