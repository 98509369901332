import { useTheme } from '@emotion/react';
import { useMemo } from 'react';

import chatWidget from '../ChatBubble/icons/chatWidget';
import UnreadMessagesCount from '../ChatBubble/UnreadMessagesCount';

import { useAgentDetails } from '../../../hooks/query/useAgentDetails';
import { svgStringToDataUrl } from '../../../services/svgStringToDataUrl';
import {
  AgentChatWrapper,
  AgentImg,
  AgentImgWrapper,
  Container,
  Wrapper,
} from './DesktopChatBubbleStyle';

type Props = {
  handleMouseOver?: () => void;
  showUnreadMessages?: boolean;
  dataQa?: string;
};

function DesktopChatBubble({
  handleMouseOver,
  showUnreadMessages,
  dataQa = 'chat-bubble',
}: Props): JSX.Element {
  const { data } = useAgentDetails();
  const theme = useTheme();

  const widgetImgSrc = useMemo(
    () => svgStringToDataUrl(chatWidget, theme.colors.branding.main),
    [theme],
  );

  const agentLogo = data?.Bio.agentPhoto ? data?.Bio.agentPhoto : '';

  return (
    <Wrapper onMouseOver={handleMouseOver} data-qa={dataQa}>
      <Container>
        {showUnreadMessages && <UnreadMessagesCount />}
        <AgentImgWrapper>
          <AgentImg src={agentLogo} alt="agent-image" />
        </AgentImgWrapper>
        <AgentChatWrapper imgPath={widgetImgSrc} />
      </Container>
    </Wrapper>
  );
}

export default DesktopChatBubble;
