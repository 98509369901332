import React from 'react';

import imageLeft from './images/DeepLink-LeftImage.png';
import imageMiddle from './images/DeepLink-MiddleImage.png';
import imageRight from './images/DeepLink-RightImage.png';

import S from './MobileImages.style';

export const MobileImages: React.FC = () => {
  return (
    <S.Container>
      <S.ImageWrapper>
        <S.Image src={imageLeft} />
      </S.ImageWrapper>
      <S.ImageWrapper>
        <S.Image src={imageMiddle} />
      </S.ImageWrapper>
      <S.ImageWrapper>
        <S.Image src={imageRight} />
      </S.ImageWrapper>
    </S.Container>
  );
};
