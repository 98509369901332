import { useMemo } from 'react';
import { useTheme } from '@emotion/react';

const activeStatusList = ['active', 'active for sale', 'for sale'];

const soldStatusList = ['sold', 'recently sold', 'off market'];

const pendingStatusList = ['contingent', 'under contract', 'pending'];

const comingSoonStatus = 'coming soon';

const config = {
  active: {
    color: '#58AC34',
  },
  sold: {
    color: '#EE4036',
  },
  pending: {
    color: '#EDC007',
  },
  comingSoon: {
    color: '#6ab4e4',
  },
  default: {
    color: '#000000',
  },
};

export function useListingStatusColor(listingStatus?: string): string {
  const theme = useTheme();

  return useMemo(() => {
    if (!listingStatus) return config.default.color;

    const statusLower = listingStatus.toLowerCase();
    if (activeStatusList.includes(statusLower)) {
      return config.active.color;
    }

    if (soldStatusList.includes(statusLower)) {
      return config.sold.color;
    }

    if (pendingStatusList.includes(statusLower)) {
      return config.pending.color;
    }

    if (statusLower === comingSoonStatus) {
      return config.comingSoon.color;
    }
    return theme.colors.branding.main;
  }, [listingStatus, theme.colors.branding.main]);
}

export default useListingStatusColor;
