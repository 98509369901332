import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingBottom: '2rem',
});

const LinkWrapper = styled('div')({
  marginTop: '1rem',
});

const WebsiteLink = styled(Link)(({ theme }) => ({
  fontSize: '1.3rem',
  color: theme.colors.branding.main,
  textDecoration: 'underline',
  ':active': {
    color: theme.colors.branding.main,
  },
}));

export default { Container, WebsiteLink, LinkWrapper };
