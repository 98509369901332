import { FC, useMemo, CSSProperties } from 'react';
import { useTheme } from '@emotion/react';

import { svgStringToDataUrl } from '../../../services/svgStringToDataUrl';

type Props = {
  svgString: string;
  customColor?: string;
  markerStyle?: CSSProperties;
};

export const SvgMarker: FC<Props> = ({
  svgString,
  markerStyle,
  customColor,
}) => {
  const theme = useTheme();

  const imgSrc = useMemo(
    () =>
      svgStringToDataUrl(svgString, customColor || theme.colors.branding.main),
    [customColor, svgString, theme.colors.branding.main],
  );

  return <img src={imgSrc} style={markerStyle} />;
};
