import axios from 'axios';
import { unauthorizedEvent } from '../constants/customEvents';

let authHeaders = {};

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BASE_API_URL,
});

axiosInstance.defaults.headers.common['X-Source'] = 'web';

export const setAccountIdHeader = (accountId) => {
  axiosInstance.defaults.headers.common['account-id'] = accountId || '';
};

axiosInstance.interceptors.request.use(
  async (config) => {
    const authConfig = { ...config };

    const token =
      (await authHeaders?.loadCognitoToken?.()) || authHeaders?.hopAuthToken;

    if (!token) {
      throw new axios.Cancel("Can't make request without a token");
    }

    authConfig.headers.Authorization = `Bearer ${token}`;

    return authConfig;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => {
    const data = response.data?.data;

    return Promise.resolve({
      ...response,
      data,
    });
  },
  (error) => {
    if (String(error?.response?.status) === '401') {
      window.dispatchEvent(unauthorizedEvent);
    }

    return Promise.reject(error);
  },
);

async function execute(func, url, config) {
  try {
    const response = await func(url, config);

    return {
      status: response.status,
      statusText: response.statusText,
      data: response.data,
    };
  } catch (error) {
    if (error.response) {
      const err = {
        status: error?.response?.status,
        statusText: error?.response?.statusText,
        error:
          error?.response?.data?.error || error?.response?.data?.data?.error,
        message:
          error?.response?.data?.message ||
          error?.response?.data?.data?.message ||
          '',
      };

      throw err;
    }

    throw error;
  }
}

const api = {
  setAuthHeaders: (headers) => {
    authHeaders = {
      ...authHeaders,
      ...headers,
    };
  },
  get: async (url, config) => execute(axiosInstance.get, url, config),
  post: async (url, config) => execute(axiosInstance.post, url, config),
  put: async (url, config) => execute(axiosInstance.put, url, config),
  patch: async (url, config) => execute(axiosInstance.patch, url, config),
  delete: async (url, config) => execute(axiosInstance.delete, url, config),
};

export default api;
