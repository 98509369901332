import { ListingDetails } from '../../types';

export type TTileModeSwitchOptions = 'image' | 'map';
export interface WithListingDetails {
  listingDetail: ListingDetails;
}

export interface SimilarListingTileProps extends WithListingDetails {
  isExcluded?: boolean;
}

export enum ListingFocusableFeature {
  New = 'new',
  PriceReduce = 'priceReduce',
  OpenHouse = 'openHouse',
  VirtualTour = 'virtualTour',
}

export type TCarouselItem = {
  src?: string;
  total?: number;
  hasLayer?: boolean;
  description?: string;
  listingDetails?: ListingDetails;
  hasDescriptionSection?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
};
