import { FC, useState, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { toastActions } from '../../../ToastComponent/ToastComponent';

import { Cta, Note } from '../VerifyAccount.style';
import { resendCode } from '../hooks/useResendCode';
import { Text, CenteredRow } from '../../RegistrationSteps.style';

export const Resend: FC = () => {
  const { t } = useTranslation();
  const [withError, setWithError] = useState(false);

  const onResend = useCallback(async () => {
    try {
      setWithError(!withError);
      await resendCode(withError);

      toastActions.success(t('auth.verifyAccount.resend.success'));
    } catch (e) {
      toastActions.error(t('auth.verifyAccount.resend.error'));
    }
  }, [t, withError]);

  return (
    <>
      <CenteredRow marginTop="21.5rem">
        <Text marginTop="0rem">{t('auth.verifyAccount.resend.line')}</Text>
        <Cta onClick={onResend}>{t('auth.verifyAccount.resend.cta')}</Cta>
      </CenteredRow>
      <Note>{t('auth.verifyAccount.resend.note')}</Note>
    </>
  );
};
