import { PropsWithChildren } from 'react';
import { Autocomplete as MapsAutocomplete } from '@react-google-maps/api';
import { useAddressPicker } from './useAddressPicker';
import { Address } from '../../../types';

export type PlaceChangeType = {
  address: google.maps.places.PlaceResult | null;
  formattedAddress: Address | null;
};
type Props = {
  onPlaceChanged: ({ address, formattedAddress }: PlaceChangeType) => void;
};

export const AddressPicker = ({
  children,
  onPlaceChanged,
}: PropsWithChildren<Props>) => {
  const { onLoad, getAddress } = useAddressPicker();
  return (
    <MapsAutocomplete
      onLoad={onLoad}
      types={['address']}
      onPlaceChanged={() => {
        onPlaceChanged(getAddress());
      }}
      restrictions={{ country: 'US' }}
    >
      <>{children ? children : <></>}</>
    </MapsAutocomplete>
  );
};
