import { ReactElement } from 'react';
import styled from '@emotion/styled';

import { WithListingDetails } from '../types';
import { ListingTileDetails } from './ListingTileDetails/ListingTileDetails';
import { useListingSession } from '../providers/ListingSessionProvider';

interface Props extends WithListingDetails {
  dataQA?: string;
  onHover?: () => void;
  preventOpen?: boolean;
  className?: string;
  Preview?: ReactElement<WithListingDetails>;
}

export function ListingTileBase({
  Preview,
  onHover,
  className,
  dataQA = '',
  listingDetail,
  preventOpen = false,
}: Props): JSX.Element {
  const { ref } = useListingSession();

  return (
    <TileContainer
      ref={ref}
      className={className}
      onMouseEnter={onHover}
      data-qa={dataQA}
    >
      {Preview}
      <ListingTileDetails
        preventOpen={preventOpen}
        listingDetail={listingDetail}
        dataQa={dataQA}
      />
    </TileContainer>
  );
}

const TileContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});
