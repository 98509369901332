import {
  Shadows,
  createTheme,
  ThemeProvider,
  TransitionsOptions,
} from '@mui/material/styles';
import { useTheme } from '@emotion/react';
import { PropsWithChildren, useMemo } from 'react';
import { ZIndexOptions } from '@mui/material/styles/zIndex';
import { MixinsOptions } from '@mui/material/styles/createMixins';
import { TypographyOptions } from '@mui/material/styles/createTypography';

import { buttonStyles } from './components/ButtonStyles';
import { BaseColorsType, Theme } from '../constants/theme';

declare module '@mui/material/styles' {
  interface ThemeOptions extends Theme {
    mixins?: MixinsOptions;
    palette?: PaletteOptions;
    shadows?: Shadows;
    transitions?: TransitionsOptions;
    typography?: TypographyOptions | ((palette: Palette) => TypographyOptions);
    zIndex?: ZIndexOptions;
    unstable_strictMode?: boolean;
  }
  interface PaletteOptions {
    branding?: {
      extraLight?: string;
      light?: string;
      main?: string;
      dark?: string;
    };
    colors?: BaseColorsType;
  }
  interface Palette {
    branding?: {
      extraLight?: string;
      light?: string;
      main?: string;
    };
    colors?: BaseColorsType;
  }
  interface TypeBackground {
    main: string;
    light: string;
  }
}
export function MaterialThemeProvider({
  children,
}: PropsWithChildren<unknown>) {
  const theme = useTheme();

  const muiTheme = useMemo(() => {
    const { primary, secondary, branding, background, ...rest } = theme.colors;

    const muiThemeObject = createTheme({
      components: {
        MuiButton: buttonStyles(theme),
      },
      ...theme,
      palette: {
        primary,
        secondary,
        branding,
        background,
        colors: {
          ...rest,
        },
      },
    });
    muiThemeObject.typography.fontFamily = theme.fontFamily.primary;

    muiThemeObject.typography.body2 = {
      fontSize: '1.2rem',
      [muiThemeObject.breakpoints.up(480)]: {
        fontSize: '1.4rem',
      },
      [muiThemeObject.breakpoints.up('md')]: {
        fontSize: '1.6rem',
      },
    };

    return muiThemeObject;
  }, [theme]);

  return <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>;
}
