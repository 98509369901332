import styled from '@emotion/styled';

const Paper = styled.div`
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
  background-color: white;
  border: ${({ theme }) =>
    `1px solid ${theme?.colors?.grey?.main || '#E6E6E6'}`};
`;

export default Paper;
