import { useTranslation } from 'react-i18next';

import { ForgotPasswordFormType } from '../ForgotPasswordForm';
import { useUsername } from '../../../../providers/Authentication/useUsername';
import { useHomeownerByContact } from '../../../../hooks/useHomeownerByContact';
import { useTokenData } from '../../../../providers/Authentication/useTokenData';
import { ErrorMessageExtractor } from '../../../../services/ErrorMessageExtractor';
import {
  useResetPassword,
  useSendVerificationCode,
} from '../../../../providers/Authentication/auth';

type OnForgotPassType = {
  values: ForgotPasswordFormType;
};
type OnSuccessAndError = {
  onSuccess?: () => void;
  onError?: (error: string) => void;
};

type UseForgotPassType = {
  onForgotPassword: (values: OnForgotPassType & OnSuccessAndError) => void;
  sendVerificationCode: (values?: OnSuccessAndError) => void;
};

const useForgotPassword = (): UseForgotPassType => {
  const username = useUsername();
  const { t } = useTranslation();
  const { data: tokenData } = useTokenData();
  const { data: homeownerDetail } = useHomeownerByContact();

  const sendVerfificationCode = useSendVerificationCode();
  const resetPassword = useResetPassword();

  const sendVerificationCode = (values?: OnSuccessAndError) => {
    sendVerfificationCode
      .mutateAsync({
        username,
        metadata: {
          email: homeownerDetail?.Communication?.emailPrimary || '',
          accountId: String(tokenData?.accountIdRaw ?? ''),
          contactId: String(tokenData?.contactId ?? ''),
          agentId: !tokenData?.agentId ? '' : String(tokenData.agentId),
        },
      })
      .then(() => {
        values?.onSuccess && values.onSuccess();
      })
      .catch((err) => {
        const msg = ErrorMessageExtractor.extract(err);
        values?.onError &&
          values.onError(msg || t('errors.somethingWentWrong'));
      });
  };

  const onForgotPassword = ({
    values,
    onSuccess,
    onError,
  }: OnForgotPassType & OnSuccessAndError) => {
    const { verificationCode, password, confirmPassword } = values;
    resetPassword
      .mutateAsync({
        username,
        verificationCode: verificationCode || '',
        password: password || '',
        passwordConfirmation: confirmPassword || '',
      })
      .then(() => {
        onSuccess && onSuccess();
      })
      .catch((err) => {
        const msg = ErrorMessageExtractor.extract(err);
        onError && onError(msg || t('errors.somethingWentWrong'));
      });
  };

  return {
    onForgotPassword,
    sendVerificationCode,
  };
};

export default useForgotPassword;
