import React from 'react';
import { useTranslation } from 'react-i18next';

import S from './PolicyLinks.style';
import { useHostnameWithProtocol } from '../../../../hooks/useDomain';

interface PolicyLinksProps {
  color?: string;
}

export const PolicyLinks: React.FC<PolicyLinksProps> = ({ color }) => {
  const { t } = useTranslation();
  const website = useHostnameWithProtocol();

  return (
    <S.PolicyLinksContainer>
      <S.Link href={`${website}/privacy.php`} target="_blank" color={color}>
        {t('privacyPolicyLicensingAgreement.privacyPolicy')}
      </S.Link>
      <S.Link href={`${website}/terms.php`} target="_blank" color={color}>
        {t('privacyPolicyLicensingAgreement.termsAndConditions')}
      </S.Link>
    </S.PolicyLinksContainer>
  );
};
