import { ChangeEvent, FC, useRef, useCallback, useMemo } from 'react';

import {
  RequiredText,
  StyledInputWrapper,
  EndAdornmentWrapper,
  Input as StyledInput,
  Label,
  LabelContainer,
  LabelMask,
} from './Input.style';
import { ErrorMessage } from '../SignIn/SignInForm.styles';

type Props = {
  id?: string;
  readOnly?: boolean;
  name: string;
  type: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  isAutoFocus?: boolean;
  dataQa?: string;
  required?: boolean;
  error?: string;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  defaultValue?: string;
  endAdornment?: JSX.Element;
  focusOnIconClick?: boolean;
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  onAdornmentClick?: () => void;
  isRegularFontWeight?: boolean;
  isPlaceholderItalic?: boolean;
  label?: string;
};

export const Input: FC<Props> = ({
  id,
  name,
  type,
  value,
  error,
  style,
  onBlur,
  required,
  isAutoFocus,
  readOnly,
  inputStyle,
  disabled,
  onChange,
  placeholder,
  dataQa = 'input',
  defaultValue,
  endAdornment,
  onAdornmentClick,
  focusOnIconClick,
  className,
  isRegularFontWeight,
  isPlaceholderItalic,
  label,
}) => {
  const ref = useRef<HTMLInputElement | null>(null);

  const handleAdornmentClick = useCallback(() => {
    if (onAdornmentClick) onAdornmentClick();

    if (!focusOnIconClick) return;

    ref?.current?.focus();
  }, [onAdornmentClick, focusOnIconClick]);

  const hasError = useMemo(() => !!error, [error]);

  return (
    <div style={style} className={className}>
      {required && <RequiredText error={hasError}>Required</RequiredText>}
      <StyledInputWrapper>
        {label && (
          <LabelContainer>
            <LabelMask />
            <Label error={hasError}>{label}</Label>
          </LabelContainer>
        )}
        <StyledInput
          id={id}
          ref={ref}
          name={name}
          type={type}
          error={hasError}
          data-qa={dataQa}
          readOnly={readOnly}
          value={value}
          style={inputStyle}
          onBlur={onBlur}
          autoFocus={isAutoFocus}
          defaultValue={defaultValue}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          hasAndAdornment={!!endAdornment}
          isRegularFontWeight={isRegularFontWeight}
          isPlaceholderItalic={isPlaceholderItalic}
        />
        {endAdornment && (
          <EndAdornmentWrapper
            onClick={handleAdornmentClick}
            hasAndAdornment={!!endAdornment}
          >
            {endAdornment}
          </EndAdornmentWrapper>
        )}
      </StyledInputWrapper>

      {hasError && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
};
