import { CSSProperties } from 'react';
import { styled, TextField, TextFieldProps } from '@mui/material';

type InputFieldProps = {
  inputStyles?: CSSProperties;
  inputBaseRootStyles?: CSSProperties;
};

const InputField = styled(TextField, {
  shouldForwardProp: (prop) =>
    ![
      'isSubNav',
      'isMobile',
      'active',
      'inputStyles',
      'inputBaseRootStyles',
    ].includes(prop.toString()),
})<InputFieldProps>(({ theme, inputStyles, inputBaseRootStyles }) => ({
  div: {
    fieldset: {
      borderColor: theme.palette.colors?.grey.dark13,
      legend: {
        marginBottom: 0,
        fontSize: '0.9rem',
        textTransform: 'uppercase',
        fontFamily: theme.typography.fontFamily,
      },
    },
    '&.Mui-focused, &.Mui-disabled': {
      fieldset: {
        '&.MuiOutlinedInput-notchedOutline': {
          borderWidth: '.1rem',
          borderColor: theme.palette.colors?.grey.dark13,
        },
      },
    },
  },
  label: {
    fontSize: '0.9rem',
    marginBottom: '0rem',
    lineHeight: '1.45rem',
    textTransform: 'uppercase',
    color: theme.palette.colors?.black,
    fontFamily: theme.typography.fontFamily,
    transform: 'translate(14px, -4.5px) scale(1)',
    '&.Mui-disabled': {
      color: theme.palette.colors?.black,
    },
    '&.Mui-focused': {
      color: theme.palette.colors?.black,
    },
  },
  input: {
    outline: 'none',
    padding: '1rem',
    fontWeight: 400,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    borderRadius: '.3rem',
    borderColor: theme.palette.colors?.grey.dark13,
    fontFamily: theme.typography.fontFamily,
    '&:disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'none',
      borderColor: theme.palette.colors?.grey.dark13,
      WebkitTextFillColor: theme.palette.colors?.black,
    },
    '&:focus': {
      outline: 'none',
    },
    '::placeholder': {
      fontWeight: '400',
      fontSize: '1.4rem',
      lineHeight: '1.7rem',
    },
    ...inputStyles,
  },
  '&.MuiFormControl-root': {
    width: '100%',
  },
  '& .MuiFormHelperText-root': {
    fontFamily: theme.typography.fontFamily,
    fontSize: '0.9rem',
    color: '#DF1726',
  },
  '& .MuiInputBase-root': {
    '&:hover': {
      fieldset: {
        borderColor: theme.palette.colors?.grey.dark13,
      },
    },
    ...inputBaseRootStyles,
  },
}));

type InputProps = TextFieldProps & InputFieldProps & { dataQa?: string };

export function Input({
  type = 'text',
  variant = 'outlined',
  dataQa = '',
  inputStyles = {},
  inputBaseRootStyles = {},
  ...rest
}: InputProps): JSX.Element {
  return (
    <InputField
      type={type}
      data-qa={dataQa}
      variant={variant}
      InputLabelProps={{ shrink: true }}
      inputStyles={inputStyles}
      inputBaseRootStyles={inputBaseRootStyles}
      {...rest}
    />
  );
}
