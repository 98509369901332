import { useCallback, useState } from 'react';
import { useViewedListing } from './query/saveListing/useViewedListing';
import { ListingDetails } from '../types';

type Props = {
  listingDetail: ListingDetails;

  viewEvent?: boolean;
};

type ReturnType = {
  viewedSessionActivated: boolean;
  setViewedSessionActive: () => void;
  resetViewedSession: () => void;
};

const useViewedListingSession = ({
  listingDetail,
  viewEvent = true,
}: Props): ReturnType => {
  const { mutate: onUpdateFavoriteViewed } = useViewedListing();

  const [viewedSessionActivated, setViewedSessionActivated] = useState(false);

  const increaseListingViewsCount = useCallback(() => {
    onUpdateFavoriteViewed({
      listingDetail,
    });
  }, [listingDetail, onUpdateFavoriteViewed]);

  const setViewedSessionActive = useCallback(() => {
    if (viewedSessionActivated || !viewEvent) return;
    setViewedSessionActivated(true);
    increaseListingViewsCount();
  }, [increaseListingViewsCount, viewEvent, viewedSessionActivated]);

  const resetViewedSession = useCallback(() => {
    setViewedSessionActivated(false);
  }, []);

  return {
    viewedSessionActivated,
    setViewedSessionActive,
    resetViewedSession,
  };
};

export default useViewedListingSession;
