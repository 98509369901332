import { useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { svgStringToDataUrl } from '../../../services/svgStringToDataUrl';

type Props = {
  fillColor?: string;
};

function ChatIcon({ fillColor }: Props) {
  const theme = useTheme();

  const imgSrc = useMemo(
    () =>
      svgStringToDataUrl(
        icon,
        fillColor || theme.colors.branding.main,
        '#004467',
      ),
    [fillColor, theme.colors.branding.main],
  );

  return (
    <img
      src={imgSrc}
      style={{
        width: '18px',
        height: '17px',
      }}
    />
  );
}

const icon = `<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 26.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.97789 0.0964844L18.5329 8.01413C18.7002 8.09403 18.8415 8.21966 18.9404 8.37651C19.0393 8.53335 19.0918 8.71499 19.0918 8.90041C19.0918 9.08584 19.0393 9.26748 18.9404 9.42432C18.8415 9.58116 18.7002 9.7068 18.5329 9.7867L1.97789 17.7043C1.80817 17.7856 1.61868 17.8165 1.43196 17.7931C1.24523 17.7698 1.06913 17.6934 0.92461 17.5729C0.780091 17.4523 0.673235 17.2928 0.616756 17.1133C0.560277 16.9338 0.556554 16.7419 0.606028 16.5603L2.26231 10.4883C2.28259 10.4139 2.32432 10.3471 2.3823 10.2963C2.44029 10.2454 2.51196 10.2127 2.58839 10.2023L10.6105 9.11256C10.6441 9.10779 10.6758 9.09443 10.7026 9.07378C10.7295 9.05313 10.7505 9.02589 10.7637 8.9947L10.7779 8.94598C10.7841 8.90212 10.7753 8.85744 10.7529 8.81922C10.7305 8.78099 10.6958 8.75146 10.6545 8.73541L10.6113 8.72363L2.59703 7.63384C2.52075 7.6233 2.44924 7.59057 2.39141 7.53972C2.33358 7.48887 2.29196 7.42215 2.27174 7.34784L0.606028 1.24127C0.556353 1.05966 0.559925 0.867605 0.616319 0.68797C0.672712 0.508336 0.779551 0.348696 0.924109 0.228065C1.06867 0.107435 1.24485 0.0308972 1.43168 0.00756879C1.61851 -0.0157597 1.8081 0.0151043 1.97789 0.0964844Z"
        fill="#004467"
      />
    </svg>
`;

export default ChatIcon;
