import { UseQueryResult, useQuery, useQueryClient } from 'react-query';

import api from '../../api';
import { apiRoutes } from '../../constants';
import { ListingDetails } from '../../types';
import { useHomeownerByContact } from '../useHomeownerByContact';

const HOMEOWNER_DETAILS_KEY = 'homeownerPropertyDetails';

const fetchPropertyDetails = async (): Promise<ListingDetails> => {
  const response = await api.get(apiRoutes.property.getByHomeownerUuid());
  return response.data;
};

type HomeownerProperty = ListingDetails & {
  homeownerUuid: 'string';
  propertyUuid: 'string';
};

export function useHomeownerPropertyDetails(): UseQueryResult<HomeownerProperty> {
  const { data: homeowner } = useHomeownerByContact();

  return useQuery(
    [HOMEOWNER_DETAILS_KEY, homeowner?.homeownerUuid],
    () => fetchPropertyDetails(),
    {
      enabled: Boolean(homeowner?.homeownerUuid),
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );
}

type InvalidateFn = () => void;

export function useInvalidateHomeownerPropertyDetails(): InvalidateFn {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries([HOMEOWNER_DETAILS_KEY], {
      refetchActive: true,
      refetchInactive: true,
    });
  };
}
