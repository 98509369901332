import { UseMutationResult, useMutation, useQueryClient } from 'react-query';

import api from '../../../api';
import { apiRoutes } from '../../../constants';
import { formatFavoriteListingPayload } from './utils';
import {
  IHomeownerDetail,
  ListingDetails,
  TViewedListing,
} from '../../../types';
import { listingFavoriteStatuses } from '../../../constants/listing-favorite-statuses';
import { useInvalidateSavedListings } from './useSavedListings';
import { useHomeownerByContact } from '../../useHomeownerByContact';
import {
  SAVED_LISTINGS_RAW_KEY,
  useInvalidateSavedListingsRaw,
} from './useSavedListingsRaw';

interface FavoriteListingViewedPayload {
  homeownerDetail?: IHomeownerDetail;
  listingDetail?: ListingDetails | null;
  listingComments?: string | null;
}

async function updateFavoriteStatus({
  listingDetail,
  listingComments = null,
}: FavoriteListingViewedPayload): Promise<void> {
  const payload = formatFavoriteListingPayload({
    listingComments,
    listingDetail,
    status: listingFavoriteStatuses.unsaved,
  });

  await api.post(apiRoutes.favoriteListing.post(), payload);
}

function isDiff(
  first?: TViewedListing,
  second?: ListingDetails | null,
): boolean {
  const hasDiffListingId = first?.mlsListingId !== second?.mlsListingId;
  const hasDiffMlsId = first?.mlsId !== second?.MlsInfo?.mlsId;

  return hasDiffListingId || hasDiffMlsId;
}

export function useUnsaveListing(): UseMutationResult<
  void,
  Error,
  FavoriteListingViewedPayload
> {
  const queryClient = useQueryClient();
  const invalidateSL = useInvalidateSavedListings();
  const invalidateSLR = useInvalidateSavedListingsRaw();
  const { data: homeowner } = useHomeownerByContact();

  const key = [SAVED_LISTINGS_RAW_KEY, homeowner?.homeownerUuid];

  return useMutation(updateFavoriteStatus, {
    onMutate: async (updateListing) => {
      await queryClient.cancelQueries(key);

      const prevSavedListings: ListingDetails[] =
        queryClient.getQueryData(key) || [];

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      queryClient.setQueryData(key, (old: any) =>
        old?.filter((listing: TViewedListing) =>
          isDiff(listing, updateListing?.listingDetail),
        ),
      );

      return { prevSavedListings };
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(key, context?.prevSavedListings);
    },
    onSettled: () => {
      invalidateSL();
      invalidateSLR();
    },
  });
}
