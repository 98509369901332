import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

import { useIsEmbedded } from '../../hooks/query/useIsEmbeded';
import Dialog from '../UI/Dialog/Dialog';

function LockScreenOrientationModal() {
  const isEmbedded = useIsEmbedded();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { t } = useTranslation();

  const lockScreenOrientation = async () => {
    const isPortrait = window.matchMedia('(orientation: portrait)').matches;

    const isMobile = window.matchMedia('(pointer:coarse)').matches;

    if (isPortrait || !isMobile) {
      try {
        if (document.fullscreenElement) {
          await document.exitFullscreen();
        }
      } catch (e) {
        Sentry.captureMessage('Info: Orientation Lock UI Shown.', 'info');
      } finally {
        setIsModalOpen(false);
      }
      return;
    }

    try {
      setIsModalOpen(true);

      if (!document.fullscreenElement) {
        await document.documentElement.requestFullscreen();
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await screen?.orientation?.lock?.('portrait');
    } catch (e) {
      setIsModalOpen(true);
      Sentry.captureMessage('Info: Orientation Lock UI Shown.', 'info');
    }
  };

  useEffect(() => {
    lockScreenOrientation();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', lockScreenOrientation);

    return () => {
      window.removeEventListener('resize', lockScreenOrientation);
    };
  });

  if (isEmbedded) return <></>;

  return (
    <ModifiedDialog
      hideX
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
    >
      <Container>
        <Text>{t('landscapeNotSupported')}</Text>
      </Container>
    </ModifiedDialog>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Text = styled.div`
  font-size: 1.8rem;
  font-weight: 700;
`;

const ModifiedDialog = styled(Dialog)`
  .MuiBackdrop-root {
    height: 100vh;
    width: 100vw;
  }

  .MuiPaper-root[role='dialog'] {
    height: 100%;
    width: 100%;
    margin: 0;
    max-width: unset;
    max-height: unset;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default LockScreenOrientationModal;
