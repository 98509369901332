import styled from '@emotion/styled';

type WithColor = {
  color?: string;
};

const PolicyLinksContainer = styled('div')({
  display: 'flex',
  marginTop: '2.6rem',
  justifyContent: 'center',
});

const Link = styled('a')<WithColor>(({ theme, color }) => ({
  fontSize: theme.fontSize.smRem,
  color: color || theme.colors.grey.dark,
  textDecoration: 'underline',
  position: 'relative',
  '&:hover': {
    color: color || theme.colors.grey.dark,
    textDecoration: 'underline',
  },
  '&:not(:last-of-type)': {
    marginRight: '0.5rem',
    paddingRight: '0.5rem',
    '&:after': {
      content: '""',
      position: 'absolute',
      backgroundColor: color || theme.colors.grey.dark,
      width: '1px',
      height: '50%',
      top: '50%',
      right: 0,
      transform: 'translateY(-50%)',
    },
  },
}));

export default { Link, PolicyLinksContainer };
