import { useMemo } from 'react';
import { useMlsDetails } from './query/useMlsDetails';

export function useMlsIds(): string[] {
  const { data: mlsIdsData } = useMlsDetails();

  const mlsIds = useMemo(
    () => mlsIdsData?.map((mls) => String(mls.mlsId)) ?? [],
    [mlsIdsData],
  );

  return useMemo(() => mlsIds, [mlsIds]);
}
