import { useCallback, useEffect, useState, useMemo } from 'react';

import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../../UI/Button';
import { routes } from '../../../../../../constants';
import { AddressWithCoordinates } from '../../../../../../types';
import { RadioButton } from '../../../../RadioButton/RadioButton';
import { AddressInput } from '../../../../AddressInput/AddressInput';
import { ConfirmDialog } from '../../../../../UI';

import { useHomeownerByContact } from '../../../../../../hooks/useHomeownerByContact';
import { useUpdateHomeownerDetails } from '../../../../../../hooks/useUpdateHomeownerDetails';
import { useInvalidateSeasons } from '../../../../../../pages/MyMaintenance/hooks/useSeasons';
import useHasMaintenanceInteraction from '../../../../../../hooks/useHasMaintenanceInteraction';
import { useAddressUpdate } from '../../../../../../hooks/useAddressUpdate';
import { ErrorMessageExtractor } from '../../../../../../services/ErrorMessageExtractor';

const homeMarketStatusOptions = {
  buy: 'buy',
  rent: 'rent',
};

type Props = {
  setChildPanelOpenInfo: (childName: string) => void;
};

function HomeInfo({ setChildPanelOpenInfo }: Props): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();

  const [ownHome, setOwnHome] = useState(false);
  const [isMaintenanceDialogOpen, setIsMaintenanceDialogOpen] = useState(false);

  const invalidateMaintenanceSeasons = useInvalidateSeasons();

  const [homeMarketStatus, setHomeMarketStatus] = useState(
    homeMarketStatusOptions.buy,
  );
  const { data: homeownerDetails } = useHomeownerByContact();
  const [error, setError] = useState<string>('');

  const { isLoading } = useUpdateHomeownerDetails();

  const [address, setAddress] = useState<AddressWithCoordinates | null>(null);

  const hasMaintenanceInteraction = useHasMaintenanceInteraction();

  const { updateAddress } = useAddressUpdate();

  const handleHomeOwnership = (value: string) => {
    const isYesOption = value === 'yes';
    setOwnHome(isYesOption);
    setError('');
  };

  const handleAddressChange = useCallback(
    (value: AddressWithCoordinates | null) => {
      setAddress(value);
      setError('');
    },
    [],
  );

  const handleHomeMarketStatusChange = useCallback((value: string) => {
    setHomeMarketStatus(value);
    setError('');
  }, []);

  const finishAddressSwitching = () => {
    setIsMaintenanceDialogOpen(false);
    history.push(routes.myHome());
    setChildPanelOpenInfo('');
  };

  useEffect(() => {
    const marketStatus = homeownerDetails?.Meta.lookingToRentInd
      ? homeMarketStatusOptions.rent
      : homeMarketStatusOptions.buy;

    setHomeMarketStatus(marketStatus);
    setAddress(homeownerDetails?.Address ?? null);
    setOwnHome(Boolean(homeownerDetails?.Meta.ownsPropertyInd));

    return () => setError('');
  }, [homeownerDetails]);

  const handleErrorState = useCallback((message) => {
    setError((oldState) => {
      if (!oldState) {
        return message;
      }

      return `${oldState} | ${message}`;
    });
  }, []);

  const setUiError = (error: unknown) => {
    const errorMessage = ErrorMessageExtractor.extract(error);
    handleErrorState(errorMessage);
  };

  const handleSubmit = (resetMaintenance: boolean) => {
    updateAddress({
      onSuccess: () => {
        if (resetMaintenance) {
          invalidateMaintenanceSeasons();
        }
        finishAddressSwitching();
      },
      onError: (e) => {
        setUiError(e);
        setIsMaintenanceDialogOpen(false);
      },
      onSettled: () => {
        setIsMaintenanceDialogOpen(false);
      },
      buyHome: homeMarketStatus !== homeMarketStatusOptions.rent,
      ownHome,
      address,
      resetMaintenance,
    });
  };

  const buttonDisabled = useMemo(() => {
    const status = homeownerDetails?.Meta?.lookingToRentInd
      ? homeMarketStatusOptions.rent
      : homeMarketStatusOptions.buy;

    const statusChanged = status !== homeMarketStatus;
    const ownHomeChanged = homeownerDetails?.Meta?.ownsPropertyInd !== ownHome;

    const isOldAddress =
      address?.fullAddress === homeownerDetails?.Address?.fullAddress;

    const ownHomeAndEmptyAddress =
      ownHome &&
      !address?.fullAddress &&
      !homeownerDetails?.Address.fullAddress;

    return (
      (!ownHomeChanged &&
        !statusChanged &&
        address?.fullAddress &&
        isOldAddress) ||
      ownHomeAndEmptyAddress
    );
  }, [address, homeMarketStatus, homeownerDetails, ownHome]);

  return (
    <div>
      <Wrap>
        <Text bold width="65%">
          {t('sideMenu.new.myProfile.doYouOwnHome')}
        </Text>
        <RadioGroup data-qa="own-home-radio-group">
          <RadioButton
            dataQa="own-home-yes-radio"
            onChangeValue={handleHomeOwnership}
            value={'yes'}
            label={t('common.yes')}
            isSelected={ownHome}
          />
          <RadioButton
            dataQa="own-home-no-radio"
            onChangeValue={handleHomeOwnership}
            value={'no'}
            label={t('common.no')}
            isSelected={!ownHome}
          />
        </RadioGroup>
      </Wrap>
      {ownHome && (
        <AddressInput
          style={{
            margin: '10px 0',
          }}
          label={t('editProfile.addressShort')}
          initialAddress={homeownerDetails?.Address}
          handleAddressChange={handleAddressChange}
          placeholder={t('addressCapture.placeholder')}
        />
      )}
      <Wrap hasMargin>
        <Text bold width="65%">
          {t('sideMenu.new.myProfile.areYouLookingTo')}
        </Text>
        <RadioGroup data-qa="looking-to-radio-group">
          <RadioButton
            dataQa="looking-to-buy-radio"
            onChangeValue={handleHomeMarketStatusChange}
            value={'buy'}
            label={t('common.buy')}
            isSelected={homeMarketStatus === homeMarketStatusOptions.buy}
          />
          <RadioButton
            dataQa="looking-to-rent-radio"
            onChangeValue={handleHomeMarketStatusChange}
            value={'rent'}
            label={t('common.rent')}
            isSelected={homeMarketStatus === homeMarketStatusOptions.rent}
          />
        </RadioGroup>
      </Wrap>
      <ErrorMsg>{error ? error : null}</ErrorMsg>

      <ButtonContainer>
        <Button
          size="small"
          type="submit"
          shape="square"
          data-qa="home-info-button"
          onClick={() => {
            if (!hasMaintenanceInteraction) {
              return handleSubmit(false);
            }
            return setIsMaintenanceDialogOpen(true);
          }}
          disabled={buttonDisabled || isLoading}
        >
          {t('auth.submit')}
        </Button>
      </ButtonContainer>

      <ConfirmDialog
        title={t('sideMenu.new.myProfile.homeInfoDialog.title')}
        open={isMaintenanceDialogOpen}
        onClose={() => handleSubmit(false)}
        text={t('sideMenu.new.myProfile.homeInfoDialog.text')}
        confirmText={t('sideMenu.new.myProfile.homeInfoDialog.confirmText')}
        cancelText={t('sideMenu.new.myProfile.homeInfoDialog.cancelText')}
        onConfirm={() => handleSubmit(false)}
        onCancel={() => handleSubmit(true)}
        isLoading={isLoading}
      />
    </div>
  );
}

export default HomeInfo;

const ButtonContainer = styled.div`
  width: fit-content;
  margin-left: auto;
  margin-top: 1.5rem;
`;
const Text = styled.div<{ bold?: boolean; width?: string }>`
  width: ${({ width }) => (width ? width : '100%')};
  font-weight: ${({ theme, bold }) =>
    bold ? theme.fontWeight.bold : theme.fontWeight.regular};
`;
const Wrap = styled.div<{ hasMargin?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${({ hasMargin }) => (hasMargin ? '2rem 0 1rem' : '0')};
`;

const RadioGroup = styled.div`
  display: flex;
  width: 40%;
  justify-content: space-between;
`;

const ErrorMsg = styled.span`
  font-size: 10px;
  height: 2rem;
  color: ${({ theme }) => theme.colors.red};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.red};
`;
