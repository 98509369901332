import styled from '@emotion/styled';

import { ChatBubble, chatMessageAtom } from '../../../Chat';
import { ListingPrice } from './ListingPrice';
import { WithListingDetails } from '../../types';
import { ListingDetailsInfo } from './ListingDetailsInfo';
import { getListingPriceValue } from '../../../../utils/getListingPriceValue';
import { FavoriteIcon } from '../../../../components/V2/FavoriteIcon/FavoriteIcon';
import { getAddressWithoutSuffix } from '../../../../utils/getAddressWithoutSuffix';
import useReducedPrice from '../../../../pages/Search/components/SectionContent/ListingTile/ListingInfo/useReducedPrice';
import { useListingSession } from '../../providers/ListingSessionProvider';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

interface ListingTileDetailsProps extends WithListingDetails {
  preventOpen?: boolean;
  dataQa?: string;
}

export function ListingTileDetails({
  dataQa,
  listingDetail,
  preventOpen = false,
}: ListingTileDetailsProps) {
  const { setSessionStarted, onOpenListingPage } = useListingSession();
  const { t } = useTranslation();

  const price = getListingPriceValue(listingDetail);
  const reducedPrice = useReducedPrice({ listingDetails: listingDetail });
  const setInitMessage = useSetRecoilState(chatMessageAtom);

  const {
    Address: { addressLine1, locality, countrySubd },
    ListingSummary: { beds, bathsTotal, livingSpaceSize },
  } = listingDetail || {};

  const formattedAddress = getAddressWithoutSuffix(addressLine1);

  const onOpen = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (preventOpen) {
        return;
      }

      setSessionStarted();
      onOpenListingPage(e, true);
    },
    [onOpenListingPage, preventOpen, setSessionStarted],
  );

  const onChat = () => {
    setSessionStarted();

    const initMessage = t('listingDetails.chatWithAgentMessage', {
      propertyFullAddress: listingDetail?.Address?.fullAddress,
      propertyId: listingDetail?.mlsListingId,
      mlsId: listingDetail?.MlsInfo.mlsId,
    });
    setInitMessage(initMessage);
  };

  return (
    <Container>
      <DetailsContainer>
        <DetailsContainerInner onClick={onOpen}>
          {price && (
            <ListingPrice
              price={price}
              dataQa={dataQa}
              reducedPrice={reducedPrice}
            />
          )}
          <DetailsInfoWrapper>
            <ListingDetailsInfo
              beds={beds}
              baths={bathsTotal}
              sqft={livingSpaceSize.find((item) => item.unit === 'sqft')?.value}
              dataQa={dataQa}
            />
          </DetailsInfoWrapper>
          <Address data-qa={`${dataQa}-address`}>
            {formattedAddress} {`${locality}, ${countrySubd}`}
          </Address>
        </DetailsContainerInner>
      </DetailsContainer>

      <ActionContainer>
        <ChatBubbleWrapper>
          <ChatBubble dataQa={`${dataQa}-chat-bubble`} onClick={onChat} />
        </ChatBubbleWrapper>

        <FavoriteIcon
          dataQa={dataQa}
          noLabel
          data={listingDetail}
          setViewedSessionActive={setSessionStarted}
        />
      </ActionContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  cursor: pointer;
  padding: 0.75rem 0;
  align-items: flex-start;
  justify-content: space-between;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

const DetailsContainerInner = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 5.8rem;
`;

const ChatBubbleWrapper = styled.div`
  margin-right: 5px;
`;

const DetailsInfoWrapper = styled.div`
  margin-top: 8px;
`;

const Address = styled.div`
  font-size: 0.9rem;
  font-weight: ${({ theme }) => theme.fontWeight.light};
  line-height: 1.1rem;
  margin-top: 0.3rem;
`;
