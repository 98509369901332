import styled from '@emotion/styled';
import { Route, RouteComponentProps } from 'react-router-dom';

import ErrorBoundary from '../error-boundary';
import { UnexpectedError } from '../../pages/Error/UnexpectedError/UnexpectedError';

type Props = {
  component: React.FC<RouteComponentProps>;
  path: string;
  exact?: boolean;
};

export default function PublicRoute({
  component: Component,
  path,
  exact,
}: Props) {
  const render = (props: RouteComponentProps) => (
    <ErrorBoundary fallback={<UnexpectedError />}>
      <Main role="main" aria-label="main">
        <Component {...props} />
      </Main>
    </ErrorBoundary>
  );
  return <Route path={path} render={render} exact={exact} />;
}

const Main = styled.main`
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100%;
`;
