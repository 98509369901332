import { MlsDetails } from '../../types';
import { Api } from '../Api';
import { hopApi } from './configs';
import { apiRoutes } from '../../constants';

class MlsDetailsImp extends Api {
  async get(domain: string): Promise<MlsDetails[]> {
    if (!domain) throw new Error('There is no domain');

    const response = await this.client.get<MlsDetails[]>(
      `${apiRoutes.mls.getByDomain(domain)}`,
    );

    return response.data;
  }
}

export const MlsDetailsAPI = new MlsDetailsImp(hopApi);
