import styled from '@emotion/styled';
import { Button as ButtonCore } from '../Button/Button.styles';

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const SendAppForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '43%',
});

const SendAppInput = styled.input`
  width: 28rem;
  height: 3rem;
  background-color: ${({ theme }) => theme.colors.grey.ultraLight};
  border-radius: ${({ theme }) => theme.borderRadius.lgRem};
  box-shadow: 2px 2px 4px #03050426;
  border: none;
  color: #707071;
  font-size: ${({ theme }) => theme.fontSize.smRem};
  font-family: ${({ theme }) => theme.fontFamily.primary};
  padding: 0 1rem;
  margin-bottom: 1.5rem;
  ::placeholder {
    font-style: italic;
  }
  &:focus {
    outline: none;
  }
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const Button = styled(ButtonCore)`
  height: 3rem;
  margin-top: 0;
  position: relative;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
`;

const ButtonLoader = styled.div`
  position: absolute;
  right: 100%;
  top: 0;
  bottom: 0;
  display: flex;
  padding-right: 1rem;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.branding.main};
`;

const Divider = styled('div')(({ theme }) => ({
  position: 'relative',
  fontSize: '2.3rem',
  color: '#D0D0D0',
  fontWeight: theme.fontWeight.bold,
  textTransform: 'uppercase',
  '&:before, &:after': {
    content: '""',
    position: 'absolute',
    width: '1px',
    height: '4.3rem',
    backgroundColor: '#D0D0D0',
    left: '50%',
    transform: 'translateY(-50%)',
  },
  '&:before': {
    top: '-20px',
  },
  '&:after': {
    bottom: '-65px',
  },
}));

const Error = styled.div`
  color: red;
  margin-top: 1rem;
`;

const Success = styled.div`
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.branding.main};
`;

const QRContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '43%',
});

const QRWrapper = styled('div')({
  marginRight: '2rem',
  '@media all and (max-width: 928px)': {
    marginRight: '0.5rem',
  },
});

const QRDescription = styled('p')(({ theme }) => ({
  fontSize: theme.fontSize.lgRem,
  color: '#2F2600',
  lineHeight: '2rem',
  flex: 1,
  '@media all and (max-width: 928px)': {
    fontSize: theme.fontSize.mdRem,
  },
}));

export default {
  Container,
  SendAppForm,
  SendAppInput,
  Divider,
  QRContainer,
  QRWrapper,
  QRDescription,
  Button,
  ButtonLoader,
  Error,
  Success,
};
