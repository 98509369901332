import styled from '@emotion/styled';

type WithBold = {
  isBold?: boolean;
};

type WithDisabled = {
  isDisabled?: boolean;
};

type WithBrandingColor = {
  isBrandingColor?: boolean;
};

type WithOneLine = {
  isOneLine?: boolean;
};

export const ContentWrapper = styled('div')<WithDisabled>(
  ({ theme, isDisabled }) => ({
    display: 'flex',
    cursor: isDisabled ? 'not-allowed' : 'auto',
    filter: `grayscale(${isDisabled ? 1 : 0})`,
    '&:not(:last-of-type)': {
      marginRight: '1rem',
      paddingRight: '1rem',
      borderRight: `1px solid ${theme.colors.grey.main}`,
    },
  }),
);

export const IconWrapper = styled('div')(({ theme }) => ({
  fontSize: theme.fontSize.mdRem,
  marginRight: '0.5rem',
  width: '2rem',
  color: theme.colorPrimary,
}));

export const Label = styled('div')(({ theme }) => ({
  fontSize: theme.fontSize.smRem,
  marginRight: '0.5rem',
  fontWeight: theme.fontWeight.bold,
}));

export const SelectedValue = styled('div')<
  WithBold & WithBrandingColor & WithOneLine
>(({ theme, isBold, isBrandingColor, isOneLine }) => ({
  fontSize: theme.fontSize.smRem,
  overflow: isOneLine ? 'hidden' : 'show',
  height: isOneLine ? '1.6rem' : 'initial',
  lineBreak: isOneLine ? 'anywhere' : 'initial',
  color: isBrandingColor ? theme.colorPrimary : theme.colors.black,
  fontWeight: isBold ? theme.fontWeight.bold : theme.fontWeight.regular,
}));
