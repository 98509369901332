import { useCallback } from 'react';
import { routes } from '../constants';
import { ListingDetails } from '../types';
import { useIsMobileView } from './useIsMobileView';
import { useListingHelper } from '../utils/useListingHelper';

type TListingDetailsRoute = {
  getListingRoute: (listingDetails: ListingDetails) => string;
};

export const useListingDetailsRoute = (): TListingDetailsRoute => {
  const isMobile = useIsMobileView();
  const { isListingFavorite } = useListingHelper();

  const getListingRoute = useCallback(
    (listingDetails: ListingDetails) => {
      const isFavorite = isListingFavorite(listingDetails);

      if (isFavorite && !isMobile) {
        return routes.savedHomes(
          listingDetails?.mlsListingId,
          listingDetails.MlsInfo.mlsId,
        );
      }

      return routes.listing(
        listingDetails.MlsInfo.mlsId,
        listingDetails?.mlsListingId,
      );
    },
    [isListingFavorite, isMobile],
  );

  return { getListingRoute };
};
