import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import HomeIcon from './HomeIcon';
import {
  Text,
  Button,
  Container,
  RadioGroup,
  ModalFooter,
  ErrorMessage,
  FormContainerColumn,
} from '../RegistrationSteps.style';
import { routerPaths } from '../../../../constants';
import { Skip } from './PropertyInformationStep.style';
import ModalHeader from '../../Modal/header/ModalHeader';
import { AddressWithCoordinates } from '../../../../types';
import { RadioButton } from '../../RadioButton/RadioButton';
import { Separator } from '../../Separator/Separator.style';
import { AddressInput } from '../../AddressInput/AddressInput';
import { useWindowWidth } from '../../../../hooks/useWindowSize';
import { useIsMobileView } from '../../../../hooks/useIsMobileView';
import { useValuationDetails } from '../../../../pages/MyHome/hooks/useValuationDetails';
import { useHomeownerAddressSuggestion } from '../../../../hooks/useHomeownerAddressSuggestion';
import { useAddressUpdate } from '../../../../hooks/useAddressUpdate';
import { useAuthModal } from '../../../../providers/AuthModalProvider';
import { useHomeownerByContact } from '../../../../hooks/useHomeownerByContact';

export function PropertyInformationStep(): JSX.Element {
  const [ownHome, setOwnHome] = useState<boolean>(false);
  const [buyHome, setBuyHome] = useState<boolean>(false);
  const [formError, setFormErrors] = useState<string | undefined>();
  const [address, setAddress] = useState<AddressWithCoordinates | null>();

  const { push } = useHistory();
  const { t } = useTranslation();
  const isMobile = useIsMobileView();
  const screenSize = useWindowWidth();
  const { onCloseModal } = useAuthModal();
  const { valuationDetails } = useValuationDetails();
  const { isLoading, updateAddress } = useAddressUpdate();
  const { data: homeownerDetails } = useHomeownerByContact();

  const homeownerAddress = useHomeownerAddressSuggestion();

  useEffect(() => {
    setBuyHome(!homeownerDetails?.Meta.lookingToRentInd);
    setOwnHome(Boolean(homeownerDetails?.Meta.ownsPropertyInd));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeownerDetails?.Address?.fullAddress]);

  useEffect(() => {
    if (!address) {
      setAddress(homeownerAddress);
    }
  }, [address, homeownerAddress, homeownerDetails?.Address, valuationDetails]);

  useEffect(() => {
    if (homeownerAddress?.fullAddress) {
      setOwnHome(true);
    }
  }, [homeownerAddress]);

  const handleOwnHome = useCallback((value: string) => {
    setOwnHome(value === 'yes');
  }, []);

  const handleBuyHome = useCallback((value: string) => {
    setBuyHome(value === 'yes');
  }, []);

  const handleAddressChange = useCallback(
    (value: AddressWithCoordinates | null) => {
      if (!value) return;

      setAddress(value);
      setFormErrors('');
    },
    [],
  );

  const buttonDisabled = useMemo(() => {
    const status = homeownerDetails?.Meta?.lookingToRentInd;

    const statusChanged = status !== buyHome;

    const ownHomeChanged = homeownerDetails?.Meta?.ownsPropertyInd !== ownHome;

    const isOldAddress =
      address?.fullAddress === homeownerDetails?.Address.fullAddress;

    return Boolean(
      !ownHomeChanged && !statusChanged && address?.fullAddress && isOldAddress,
    );
  }, [
    address?.fullAddress,
    buyHome,
    homeownerDetails?.Address.fullAddress,
    homeownerDetails?.Meta?.lookingToRentInd,
    homeownerDetails?.Meta?.ownsPropertyInd,
    ownHome,
  ]);

  const handleError = (error: string) => {
    setFormErrors(error);
  };

  const handleModalClose = () => {
    address?.fullAddress
      ? push(routerPaths.myHome.myHome)
      : push(routerPaths.myHome.myMarkets);

    onCloseModal();
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (!address) return;

    updateAddress({
      ownHome,
      buyHome,
      address,
      onError: handleError,
      onSuccess: handleModalClose,
    });
  };

  const handleSkip = () => {
    if (
      address?.fullAddress &&
      homeownerDetails?.Address.fullAddress === address?.fullAddress
    ) {
      updateAddress({
        ownHome: true,
        buyHome: Boolean(!homeownerDetails?.Meta.lookingToRentInd),
        address,
      });
    }

    handleModalClose();
  };

  return (
    <Container isMobile={isMobile}>
      <ModalHeader
        icon={<HomeIcon />}
        title={t('auth.propertyInformationStep.propertyInformation')}
      />
      <Text>{t('auth.propertyInformationStep.doYouOwnHome')}</Text>

      <FormContainerColumn onSubmit={handleSubmit}>
        <RadioGroup>
          <RadioButton
            value={'yes'}
            isSelected={ownHome}
            label={t('common.yes')}
            onChangeValue={handleOwnHome}
          />
          <RadioButton
            value={'no'}
            isSelected={!ownHome}
            label={t('common.no')}
            onChangeValue={handleOwnHome}
          />
        </RadioGroup>
        <Separator marginTop="5rem" marginBottom={ownHome ? '5rem' : '2rem'} />

        {ownHome && (
          <>
            <Text marginTop="0">
              {t('auth.propertyInformationStep.enterHomeAddress')}
            </Text>

            <AddressInput
              style={{
                margin: '3rem auto 0',
                width: screenSize < 577 ? '30rem' : '35rem',
              }}
              label="address"
              error={formError && !address ? 'Address is required' : undefined}
              initialAddress={homeownerDetails?.Address}
              handleAddressChange={handleAddressChange}
            />
          </>
        )}

        <RadioGroup>
          <RadioButton
            value={'yes'}
            isSelected={buyHome}
            label={t('common.buy')}
            onChangeValue={handleBuyHome}
          />
          <RadioButton
            value={'no'}
            isSelected={!buyHome}
            label={t('common.rent')}
            onChangeValue={handleBuyHome}
          />
        </RadioGroup>

        {formError && <ErrorMessage>{formError}</ErrorMessage>}

        <ModalFooter marginTop={ownHome ? '3.3rem' : '17.3rem'}>
          <Skip
            onClick={() => {
              !isLoading && handleSkip();
            }}
          >
            {t('auth.skip')}
          </Skip>
          <Button
            shape="square"
            type="submit"
            disabled={buttonDisabled || isLoading}
          >
            {t('auth.next')}
          </Button>
        </ModalFooter>
      </FormContainerColumn>
    </Container>
  );
}
