import { RefObject } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import { NavIcon } from './NavIcon';
import { IconNavLink } from '../../components/IconNavLink';
import NotificationsPanel from '../../../NotificationPanel';
import { CounterBadge } from '../../components/CounterBadge';
import { useFormattedCount } from '../../hooks/useFormattedCount';
import { NotificationIcon } from '../../../../components/V2/Icons/NotificationIcon';
import { Layer } from '../../../../types';

type Props = {
  onClick: () => void;
  isPanelOpen: boolean;
  notificationsRef: RefObject<HTMLDivElement>;
};
const NotificationsNavItem = ({
  onClick,
  isPanelOpen,
  notificationsRef,
}: Props) => {
  const formattedCount = useFormattedCount();
  const location = useLocation();

  return (
    <Container ref={notificationsRef} className="nav-icon">
      <IconNavLink
        to={location}
        onClick={onClick}
        active={isPanelOpen}
        dataQa="notification-nav-item"
      >
        {formattedCount ? <CounterBadge count={formattedCount} /> : <></>}
        <NavIcon active={isPanelOpen}>
          <NotificationIcon />
        </NavIcon>
      </IconNavLink>
      {!!isPanelOpen && (
        <PanelWrap>
          <NotificationsPanel />
        </PanelWrap>
      )}
    </Container>
  );
};

export default NotificationsNavItem;

const Container = styled.div`
  position: relative;
  cursor: pointer;
`;

const PanelWrap = styled.div`
  position: absolute;
  top: 5.8rem;
  right: 2rem;
  z-index: ${Layer.Default + 1};
`;
