import React from 'react';
import { useTranslation } from 'react-i18next';
import { PolicyLinks } from '../../components/PolicyLinks/PolicyLinks';

import S from './Footer.style';

export const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <S.Footer>
      <S.Container>
        <S.Description>{t('mobileAppDeepLink.description')}</S.Description>
        <PolicyLinks color="white" />
      </S.Container>
    </S.Footer>
  );
};
