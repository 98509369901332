import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import api from '../../api';
import { useKey } from './KeyProvider';
import * as Sentry from '@sentry/react';
import { SeverityLevel } from '@sentry/react';
import { useUnauthToken } from '../../hooks/query/useUnauthToken';
import { setHopApiAuthToken } from '../../api/hop/configs';

interface HolpTokensContextStruct {
  token: string;
  isLoading: boolean;
  getUnauthToken: () => Promise<string>;
}

const HolpTokensContext = React.createContext<
  HolpTokensContextStruct | undefined
>(undefined);

export const useHolpTokens = (): HolpTokensContextStruct => {
  const context = React.useContext(HolpTokensContext);

  if (context === undefined) {
    throw new Error('useHolpTokens cannot be used outside HolpTokensProvider!');
  }

  return context;
};

type Props = {
  loader: ReactElement | null;
};

export const HolpTokensProvider: FC<Props> = ({ children, loader = null }) => {
  const { key } = useKey();
  const {
    data,
    isLoading: isTokenLoading,
    isError,
    error,
  } = useUnauthToken(key);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getUnauthToken = useCallback(
    async () => data?.idToken ?? '',
    [data?.idToken],
  );

  useEffect(() => {
    if (isTokenLoading) {
      setIsLoading(true);
      return;
    }

    if (!isTokenLoading && isError) {
      Sentry.captureMessage(
        (error as string) ?? 'Failed to fetch hop token!',
        'info' as SeverityLevel,
      );

      setIsLoading(false);
      return;
    }

    if (!data?.idToken) {
      setIsLoading(true);
      return;
    }

    api.setAuthHeaders({
      hopAuthToken: data?.idToken,
    });

    setHopApiAuthToken(data?.idToken);

    setIsLoading(false);
  }, [data?.idToken, isTokenLoading, isError, error, getUnauthToken]);

  const value = useMemo(
    () => ({
      token: data?.idToken ?? '',
      isLoading,
      getUnauthToken,
    }),
    [data?.idToken, isLoading, getUnauthToken],
  );

  return (
    <HolpTokensContext.Provider value={value}>
      {isLoading ? loader : children}
    </HolpTokensContext.Provider>
  );
};
